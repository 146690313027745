export function debounce(
  func: any,
  timer: number
): (...args: Parameters<any>) => void {
  let timeout: ReturnType<typeof setTimeout> | null;

  return (...args: Parameters<any>): void => {
    if (typeof timeout === "number") {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func(...args), timer);
  };
}
