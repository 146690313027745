import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCertificate } from '../../hooks/useCertificate'
import { CertificateImageFile } from '../../tabs/Certificate'
import { CertificateImageSelection } from '../CertificateImageSelection'

/**
 * Renders the certificate company logo selection form. This component is composed of a group of components from CertificateImageSelection.
 */
export function CertificateCompanyLogoSelection() {
  const [t] = useTranslation()
  const { handleCompanyLogo, companyLogo } = useCertificate()
  const [includesCompanyLogoOnCertificate, setIncludesCompanyLogoOnCertificate] = useState(
    companyLogo.shouldIncludeLogo,
  )
  const [localFile, setLocalFile] = useState<CertificateImageFile>({ file: companyLogo.logoImage, error: undefined })

  useEffect(() => {
    if (!includesCompanyLogoOnCertificate) {
      setLocalFile({ file: undefined, error: undefined })
      handleCompanyLogo({ logoImage: undefined, shouldIncludeLogo: false })
    } else {
      handleCompanyLogo({ shouldIncludeLogo: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includesCompanyLogoOnCertificate])

  useEffect(() => {
    if (typeof localFile.file !== 'string') {
      handleCompanyLogo({ logoImage: !localFile.error ? localFile.file : undefined })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localFile.file])

  useEffect(() => {
    if (companyLogo) {
      setIncludesCompanyLogoOnCertificate(companyLogo.shouldIncludeLogo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyLogo])

  return (
    <Flex flexDir='column' gap='24px'>
      <CertificateImageSelection.SectionTitle sectionTitle={t('includeCompanyLogoOnCertificate')} />
      <CertificateImageSelection.FileForm
        fileAndError={localFile}
        onFileChange={setLocalFile}
        inputFilelabel={t('companyLogo')}
        isDisabled={!includesCompanyLogoOnCertificate}
      />
      <CertificateImageSelection.LabeledCheckbox
        isChecked={includesCompanyLogoOnCertificate}
        onCheck={setIncludesCompanyLogoOnCertificate}
        label={`${t('companyLogoShouldBeIncludeOnCertificate')}?`}
      />
      <CertificateImageSelection.FileFormPreview
        fileAndError={localFile}
        isDisabled={!includesCompanyLogoOnCertificate}
        previewLabel='450 x 150px'
      />
    </Flex>
  )
}
