import { Box, Icon, Text } from '@chakra-ui/react'
import React, { memo } from 'react'
import { useHistory } from 'react-router-dom'
import { useExploreContext } from 'src/context/exploreContext'
import { SliderChevron } from 'src/theme/Icons'

type Skill = {
  name: string
  id: string
}

type Props = {
  name: string
  subCategoryId: string
  skills: Skill[] | undefined
  rootId: string
  visibilityHandler: React.Dispatch<React.SetStateAction<boolean>>
}

const Subcategory: React.FC<Props> = ({ name, subCategoryId, skills, rootId, visibilityHandler }) => {
  const history = useHistory()
  const { setSkillTreeData, skillTreeData } = useExploreContext()

  const handleClick = () => {
    if (skills?.length !== 0) return

    visibilityHandler(false)
    setSkillTreeData((prevState: any) => ({ ...prevState, subCategoryId: subCategoryId }))
    history.push(`/categories/${rootId}/${subCategoryId}`)
  }

  return (
    <Box
      w='380px'
      h='39px'
      borderRadius='16px'
      _hover={{
        backgroundColor: 'gray.60',
      }}
      d='flex'
      alignItems='center'
      justifyContent='space-between'
      color={skillTreeData.subCategoryId === subCategoryId ? 'primaryColors.400' : 'gray.800'}
      paddingX='13px'
      paddingY='10px'
      cursor={skills?.length !== 0 ? 'default' : 'pointer'}
      onClick={handleClick}
      onMouseEnter={() => {
        if (skills?.length === 0) return
        setSkillTreeData((prevState: any) => ({
          ...prevState,
          subCategoryId: subCategoryId,
          rootId,
          skillList: skills,
        }))
      }}
    >
      <Box d='flex' alignItems='center'>
        <Text
          color={skillTreeData.subCategoryId === subCategoryId ? 'primaryColors.400' : 'gray.800'}
          fontWeight={skillTreeData.subCategoryId === subCategoryId ? '700' : '400'}
          transition='all .2s'
          textDecoration='none'
          textAlign='left'
          fontSize='16px'
        >
          {name}
        </Text>
      </Box>
      {skills?.length !== 0 && <Icon as={SliderChevron} />}
    </Box>
  )
}

export default memo(Subcategory)
