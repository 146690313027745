import { useLazyQuery } from '@apollo/client'
import { cmsQueryContext } from 'src/services/apollo/ApolloClient'
import { GET_CMS_SKILLS } from '../../../graphql/queries/GET_CMS_SKILLS'
import { getOrderSkillsFilterField } from '../utils/getOrderSkillsFilterField'
import { useCorporateUniversityStore } from './useCorporateUniversityStore'

export const useListSkills = () => {
  const { limitPerPage, skip, updateSkip, totalSkills, updateTotalSkills, updateCmsSkills, searchValue, filters } =
    useCorporateUniversityStore()

  const [listSkills, { fetchMore, refetch, loading }] = useLazyQuery(GET_CMS_SKILLS, {
    context: cmsQueryContext,
    notifyOnNetworkStatusChange: true,
    variables: {
      skip,
      limit: limitPerPage,
      order: getOrderSkillsFilterField(filters.order),
      ...(filters.skill ? { id: filters.skill } : {}),
      ...(searchValue ? { name: searchValue } : {}),
    },
    onCompleted: (data) => {
      updateCmsSkills(data?.skillList?.items ?? [])
      updateTotalSkills(data?.skillList?.total ?? 0)
    },
  })

  const handlePrevPageSkills = () => {
    const newSkip = skip - limitPerPage <= 0 ? 0 : skip - limitPerPage
    updateSkip(newSkip)
  }

  const handleNextPageSkills = () => {
    const newSkip = skip + limitPerPage >= totalSkills ? skip : skip + limitPerPage
    updateSkip(newSkip)
  }

  return {
    listSkills,
    refetch,
    isLoadingSkills: loading,
    fetchMore,
    handlePrevPageSkills,
    handleNextPageSkills,
  }
}
