import { Box, Flex, Text } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import DraggableItems from './CustomDraggableItems'
import ReactSelect from 'react-select'
import { MultiSelectStyles } from 'src/components/CustomPlaylistQuiz/utils'
import { SelectedOptions, useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { useWindowSize } from 'src/hooks/useWindowSize'
import ButtonsFooter from '../ButtonsFooter'
import StepHeader from '../StepHeader'

type ParsedContentTypes = {
  id: string
  title: string
}

const StepFive = () => {
  const [t] = useTranslation()
  const { EventsImplementation, track } = useMixpanel()
  const { quizValues, updateQuiz } = useCustomPlaylistQuiz()
  const { contentTypes } = quizValues
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions[]>([])
  const [parsedContentTypes, setParsedContentTypes] = useState<ParsedContentTypes[]>([])

  const { width } = useWindowSize()
  const isMobile = useMemo(() => !!width && width < 992, [width])
  const isContentTypesValid = useMemo(() => !!contentTypes && contentTypes?.length > 0, [contentTypes])

  const handleNextButton = async () => {
    if (isContentTypesValid) {
      track(EventsImplementation.PERSONALIZED_PLAYLIST_QUIZ_FORMAT_ORDER(contentTypes))
    }
  }

  const handleSelectOption = (props: any) => {
    setSelectedOptions(props.map((prop: any) => ({ value: prop.value, label: prop.label })))
    updateQuiz(
      'selectedContentTypes',
      props.map((prop: any, index: number) => ({ code: prop.value, description: prop.label, order: index })),
    )
  }

  useEffect(() => {
    setParsedContentTypes(contentTypes?.map((item) => ({ id: item.code, title: item.description })))
  }, [contentTypes])

  return (
    <Flex h='100%' direction='column'>
      <Box>
        <StepHeader
          step={5}
          title={t('customPlaylistQuiz.stepFive.title')}
          description={
            isMobile ? t('customPlaylistQuiz.stepFive.mobileDescription') : t('customPlaylistQuiz.stepFive.description')
          }
        />

        {isContentTypesValid && (
          <Box mt='16px'>
            <ReactSelect
              id='custom-playlist-react-select-skills'
              isSearchable
              isClearable={false}
              isMulti
              styles={MultiSelectStyles()}
              value={selectedOptions}
              placeholder={t('selectTheContentTypeToReceiveInYourPlaylist')}
              components={{
                IndicatorSeparator: () => null,
              }}
              options={parsedContentTypes.map((item) => ({
                value: item.id,
                label: (t(item.id as any) || item.title)
              }))}
              onChange={handleSelectOption}
              isLoading={false}
            />
          </Box>
        )}
      </Box>

      {!isContentTypesValid && (
        <Box mt='24px' py='24px' borderTopWidth='1px' borderBottomWidth='1px'>
          <Text>{t('customPlaylistQuiz.stepFive.contentTypesInValid.title')}</Text>
          <Text>{t('customPlaylistQuiz.stepFive.contentTypesInValid.description')}</Text>
        </Box>
      )}

      <ButtonsFooter
        nextButtonCallback={handleNextButton}
        showTooltip={!selectedOptions?.length}
        disabledButton={!isContentTypesValid || !parsedContentTypes?.length || !selectedOptions?.length}
        {...(!isContentTypesValid
          ? { tooltipLabel: t('customPlaylistQuiz.stepFive.tooltip')}
          : {})}
      />
    </Flex>
  )
}

export default StepFive
