import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  useClipboard,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoOpenOutline } from 'react-icons/io5'
import { Partner } from 'src/types/Partner'
import { UserCredentials } from '..'
import AddExtension from '../Components/AddExtension'
import ItemInBetween from '../Components/ItemInBetween'

interface Props {
  partner: Partial<Partner>
  credentials: UserCredentials
  redirectLink: string
}

const HasCredentials: React.FC<Props> = ({ partner, credentials, redirectLink }: Props) => {
  const [t] = useTranslation()
  const [loginValue, setLoginValue] = useState(credentials.login)
  const [passwordValue, setPasswordValue] = useState(credentials.password)
  const { hasCopied: hasCopiedLogin, onCopy: onCopyLogin } = useClipboard(loginValue!)
  const { hasCopied: hasCopiedPassword, onCopy: onCopyPassword } = useClipboard(passwordValue!)

  useEffect(() => {
    if (credentials.login && credentials.password) {
      setLoginValue(credentials.login)
      setPasswordValue(credentials.password)
    }
  }, [credentials])

  return (
    <Stack
      w='100%'
      h='100%'
      flexDirection={['column', 'column', 'column', 'row', 'row']}
      justifyContent='center'
      bg='gray.25'
    >
      {/* Extension Side */}
      <AddExtension />
      <ItemInBetween />
      {/* Credentials Side */}
      <Stack
        display='flex'
        justifyContent='flex-start'
        w={['100%', '100%', '100%', '50%', '50%']}
        flexDirection='column'
        alignItems='center'
        paddingTop={['10px', '10px', '20px', '62px', '62px']}
        spacing='5'
      >
        <Image src={partner.squareLogo} h='54px' display={['flex', 'flex', 'flex', 'none', 'none']} />
        <Text
          fontFamily='Poppins'
          fontSize={['18px', '24px', '24px', '28px', '28px']}
          fontWeight='extrabold'
          lineHeight='20px'
        >
          {' '}
          {t('activatePartner.edtechs.hasCredentials.Your account is ready to be used')}
        </Text>

        <Box
          display={['none', 'none', 'none', 'flex', 'flex']}
          bg='primaryColors.400'
          w='137px'
          h='2px'
          alignSelf='center'
          marginBottom='20px'
        />
        <Text
          fontFamily='Mulish'
          w={['80%', '70%', '80%', '70%', '70%']}
          textAlign='center'
          fontSize='14px'
          fontWeight='regular'
        >
          {' '}
          {t('activatePartner.edtechs.hasCredentials.description')}
        </Text>
        <Image
          src={partner.squareLogo}
          h={['60px', '60px', '60px', '75px', '75px']}
          display={['none', 'none', 'none', 'flex', 'flex']}
        />

        <Flex w={['90%', '80%', '70%', '90%', '70%']} flexDirection='column'>
          <Text
            color='gray.600'
            fontFamily='Mulish'
            fontWeight='regular'
            fontSize='16px'
            marginLeft='14px'
            marginBottom='6px'
          >
            {t('activatePartner.edtechs.hasCredentials.Login')}
          </Text>
          <InputGroup>
            <Input value={loginValue} h='55px' isReadOnly placeholder={credentials.login} />
            <InputRightElement width='120px'>
              <Button
                h='36px'
                fontSize={['14px', '14px', '14px', '16px', '16px']}
                w={['70%', '70%', '70%', '90%', '90%']}
                onClick={onCopyLogin}
                marginLeft={['20px', '20px', '20px', '0px', '0px']}
              >
                {hasCopiedLogin ? t('activatePartner.Copied') : t('activatePartner.Copy')}
              </Button>
            </InputRightElement>
          </InputGroup>
        </Flex>
        <Flex w={['90%', '80%', '70%', '90%', '70%']} flexDirection='column' marginTop='-20px'>
          <Text
            color='gray.600'
            fontFamily='Mulish'
            fontWeight='regular'
            fontSize='16px'
            marginLeft='14px'
            marginBottom='6px'
          >
            {t('activatePartner.edtechs.hasCredentials.Password')}
          </Text>
          <InputGroup>
            <Input value={passwordValue} h='55px' isReadOnly placeholder={credentials.password} />
            <InputRightElement width='120px'>
              <Button
                h='36px'
                fontSize={['14px', '14px', '14px', '16px', '16px']}
                w={['70%', '70%', '70%', '90%', '90%']}
                onClick={onCopyPassword}
                marginLeft={['20px', '20px', '20px', '0px', '0px']}
              >
                {hasCopiedPassword ? t('activatePartner.Copied') : t('activatePartner.Copy')}
              </Button>
            </InputRightElement>
          </InputGroup>
        </Flex>
        <Link
          fontFamily='Mulish'
          fontWeight='bold'
          fontSize='16px'
          color='white'
          isExternal={true}
          bg='gray.800'
          href={redirectLink}
          textDecoration='none'
          display='flex'
          justifyContent='center'
          alignItems='center'
          w={['90%', '80%', '70%', '90%', '70%']}
          marginTop='30px'
          paddingY={['4px', '4px', '12px', '12px', '12px']}
          borderRadius='40px'
          _hover={{ textDecoration: 'none' }}
        >
          {t('activatePartner.edtechs.hasCredentials.Access the course')}

          <Icon fontSize='22px' marginLeft='10px' as={IoOpenOutline} />
        </Link>
      </Stack>
    </Stack>
  )
}

export default HasCredentials
