import { Flex, Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { getContentData } from 'src/helpers/contentInfo'
import Content from 'src/modules/cms/domain/Content'
import { ContentType } from 'src/modules/cms/domain/LearningItem'

interface Props {
  content: Content
}

const Description: React.FC<Props> = ({ content }: Props) => {
  const [t] = useTranslation()
  const contentData = getContentData(content ? content.type : '', t)

  return (
    <>
      <Flex alignItems='center' justifyContent='space-between'>
        <Text
          fontSize='20px'
          fontFamily='Poppins'
          fontWeight='600'
          color='#767F89'
          marginBottom='16px'
          textTransform='capitalize'
        >
          {contentData?.type}
        </Text>

        {content?.languages ? (
          <Flex alignItems='center' justifyContent='center'>
            <Text marginLeft='8px' textTransform='capitalize'>
              {content.languages?.join(', ')}
            </Text>
          </Flex>
        ) : null}
      </Flex>

      <Heading size='md' fontWeight='700' marginBottom='16px'>
        {content.title}
      </Heading>

      {content.type !== ContentType.COURSE && (
        <Text color='#767F89' fontWeight='600' mb='16px'>
          {content.description}
        </Text>
      )}
    </>
  )
}

export default Description
