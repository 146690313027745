import { Divider, Flex, Stack } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import PaginatedCourses from 'src/components/PaginatedCourses/PaginatedCourses'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { useWindowSize } from 'src/hooks/useWindowSize'
import Content from 'src/modules/cms/domain/Content'
import { useContents } from 'src/modules/cms/hooks/useContents'
import '../../services/i18n/i18n'
import NotFound from './components/NotFound'
import SearchHeader from './components/SearchHeader'
import Skeleton from './components/Skeleton'
import { autoCorrectQuery, calculateNumberOfCardsRow } from './search.service'
import { Params } from './search.types'

const Search: React.FC = () => {
  const { query } = useParams<Params>()
  const [contents, setContents] = useState<Content[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const [skip, setSkip] = useState(0)
  const [totalItems, setTotalItems] = useState(0)

  const windowSize = useWindowSize()

  const numberOfCardsRow = calculateNumberOfCardsRow(windowSize.width)
  const SEARCH_LIMIT = numberOfCardsRow * 5
  const currentPage = useMemo(() => Math.floor(skip / SEARCH_LIMIT), [skip, SEARCH_LIMIT])
  const notFound = useMemo(() => contents.length <= 0 && !isLoading, [contents, isLoading])

  const { searchContentsByQuery } = useContents()
  const { EventsImplementation, track } = useMixpanel()
  const [t] = useTranslation()

  const autoCorrected = autoCorrectQuery(query)

  useEffect(() => {
    setIsLoading(true)

    searchContentsByQuery(query, { limit: SEARCH_LIMIT, skip: skip })
      .then((data) => {
        setContents(data?.items ?? [])
        setTotalItems(data.total)
        track(EventsImplementation.SEARCH(query))
      })
      .finally(() => setIsLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, skip])

  if (notFound) return <NotFound query={query} />

  return (
    <Stack width='100%' padding={{ base: '16px 20px', lg: '40px' }} spacing={6}>
      <Helmet>
        <title>
          {t('search.Results for')} {query} | Education Journey
        </title>
      </Helmet>

      <SearchHeader query={query} autoCorrected={autoCorrected} notFound={notFound} />

      <Divider marginBottom='40px' />

      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <Flex flex='1' flexDirection='column'>
            {contents.length ? (
              <PaginatedCourses
                courses={contents}
                pageInfo={{ currentStart: skip, totalEntries: totalItems }}
                currentPage={currentPage}
                onChangeCurrentPage={(page) => setSkip(page * SEARCH_LIMIT)}
                numberOfCardsRow={numberOfCardsRow}
              />
            ) : null}
          </Flex>

          {notFound && <NotFound query={query} />}
        </>
      )}
    </Stack>
  )
}

export default Search
