import { gql } from '@apollo/client'

const UPDATE_ROLE = gql`
  mutation updateRole($id: String!, $roles: [Role]) {
    updateRole(id: $id, roles: $roles) {
      id
      name
    }
  }
`

export default UPDATE_ROLE
