import { UseCase } from 'src/types/UseCase'
import {
  AddNewUsersToExistingAreaInput,
  AddNewUsersToExistingAreaOutput,
  AreaRepository,
} from '../repository/AreaRepository'

export class AddNewUsersToArea implements UseCase<AddNewUsersToExistingAreaInput, AddNewUsersToExistingAreaOutput[]> {
  constructor(private areaRepository: AreaRepository) {}

  async execute(args: AddNewUsersToExistingAreaInput): Promise<AddNewUsersToExistingAreaOutput[]> {
    const failedUsers = await this.areaRepository.addNewUsersToExistingArea({
      areaId: args?.areaId,
      ...(args.usersIds?.length !== 0 ? { usersIds: args?.usersIds } : {}),
      ...(args.usersEmails?.length !== 0 ? { usersEmails: args.usersEmails } : {}),
    })
    return failedUsers
  }
}
