import { Flex, FormControl, Select, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { teamWithEmptyValues, useHandleTeam } from 'src/context-admin/useHandleTeam'
import { NavigationButtons } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/NavigationButtons'
import { StepTitle } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTitle'
import { StepTransition } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTransition'

export function EditExistingTeamSelectTeam() {
  const [t] = useTranslation()
  const { navigateToStep, handleSetTeamValues, teams, team } = useHandleTeam()

  const handleSelectArea = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const existingTeam = teams?.find((item) => String(item.id) === e.target.value)
    if (!existingTeam) return

    handleSetTeamValues({
      id: e.target.value,
      name: existingTeam.name,
      managers: existingTeam.members.filter((item) => item.type === 'MANAGER').map(item => ({ id: item.id, name: item.name })) as any,
      users: existingTeam.members.filter((item) => item.type === 'COLAB').map(item => ({ id: item.id, name: item.name })) as any,
    })
  }

  useEffect(() => {
    handleSetTeamValues(teamWithEmptyValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StepTransition>
      <Flex flexDir='column'>
        <StepTitle title={t('whichTeamDoYouWantToEdit')} />
        <FormControl mt='21px'>
          <Text fontWeight='700' fontSize='1rem'>
            {t('team')}
          </Text>
          <Select
            defaultValue='empty'
            borderWidth='2px'
            borderColor='#E5E5E5'
            mt='6px'
            fontWeight={600}
            fontSize='1rem'
            w='auto'
            height='auto'
            onChange={handleSelectArea}
            sx={{
              '&': {
                padding: '4px 16px',
                borderRadius: '12px',
              },
            }}
          >
            <option value='empty' disabled>
              {t('selectATeam')}
            </option>
            {teams.map((item) => (
              <option value={item.id}>{item.name}</option>
            ))}
          </Select>
        </FormControl>
        <NavigationButtons
          isDisabled={!team?.id}
          showBackButton={false}
          onContinueClick={() => {
            navigateToStep('edit-existing-team--select-options')
          }}
        />
      </Flex>
    </StepTransition>
  )
}
