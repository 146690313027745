import { Box, Flex } from "@chakra-ui/react";

interface Props {
  children: React.ReactNode;
}

const OnboardCard: React.FC<Props> = ({ children }: Props) => {
  return (
    <Flex justifyContent="start" flexDirection="column">
      <Box
        bg="white"
        padding="0 75px 60px 75px"
        width="612px"
        borderRadius="19px"
        boxShadow="0 4px 4px rgba(0,0,0,0.1)"
      >
        {children}
      </Box>
    </Flex>
  );
};

export default OnboardCard;
