import { Button, Flex, Heading, Input, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { SkillFormatted } from 'src/components/CustomPlaylistQuiz/StepFour'
import { Skill, useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'

type Props = {
  handleStepChange: (step: number) => void
}

const SkillsDescriptions: React.FC<Props> = ({ handleStepChange }) => {
  const [t] = useTranslation()
  const { skillsToAddOnEdit, setSkillsToAddOnEdit } = useCustomPlaylistQuiz()
  const [skills, setSkills] = useState<Skill[]>(skillsToAddOnEdit.map((item) => ({ ...item, context: item.context })))

  const handleContinueButton = () => {
    handleStepChange(2)
  }

  const handleStepBack = () => {
    handleStepChange(0)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, item: Skill) => {
    const formattedSkills = skills.map((skill) => {
      if (skill.id === item.id) {
        return {
          ...skill,
          context: e.target.value,
        }
      }

      return skill
    })

    setSkills(formattedSkills)
    setSkillsToAddOnEdit(formattedSkills)
  }

  const placeholders = [
    t('customPlaylistQuiz.stepFour.placeholderOne'),
    t('customPlaylistQuiz.stepFour.placeholderTwo'),
    t('customPlaylistQuiz.stepFour.placeholderThree'),
  ]

  let isDisabled = false

  return (
    <>
      <Heading size='xs' fontWeight='600' marginBottom='8px'>
        {t('home.Custom Playlist.How will you use this skill in your daily life?')}
      </Heading>
      <Text fontWeight='600' fontSize='18px' color='bluishGray.500'>
        {t('home.Custom Playlist.Describe activities that will be present')}
      </Text>
      <Flex direction='column' gap='24px' mt='24px'>
        {skills.map((item, index) => {
          if (!item.context) isDisabled = true

          return (
            <Flex key={item.id} direction='column' gap='4px'>
              <Text fontSize='18px' fontWeight={700}>
                {item.name}
              </Text>
              <Input
                // @ts-ignore
                value={item.context}
                // placeholder={item.hasError ? t('customPlaylistQuiz.stepFour.error') : placeholders[index]}
                placeholder={placeholders[index]}
                onChange={(e) => handleChange(e, item)}
                // _placeholder={{
                //   color: item.hasError ? 'red.300' : 'bluishGray.500',
                // }}
                _placeholder={{
                  color: 'bluishGray.500',
                }}
                data-testid={`edit-skills-context-${index}`}
              />
            </Flex>
          )
        })}
      </Flex>
      <Flex justify='space-between' mt='32px'>
        <Button
          variant='startCourse'
          color='bluishGray.500'
          border='2px solid #EFEFEF'
          height='44px'
          onClick={handleStepBack}
        >
          {t('quiz.footer.back')}
        </Button>
        <Button
          variant='startCourseDark'
          height='44px'
          padding='12px 24px'
          data-testid='edit-skills-context-button'
          onClick={handleContinueButton}
          isDisabled={isDisabled}
        >
          {t('quiz.footer.continue')}
        </Button>
      </Flex>
    </>
  )
}

export default SkillsDescriptions
