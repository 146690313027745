import { Box, Flex, Skeleton, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Footer from 'src/components/Footer'
import StepHeader from 'src/components/QuizModal/StepHeader'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { useUser } from 'src/context/userContext'
import Confirmation from './Confirmation'
import SelectLanguage from './SelectLanguage'
import StepFive from './StepFive'
import StepFour from './StepFour'
import StepOne from './StepOne'
import StepThree from './StepThree'
import StepTwo from './StepTwo'
import StepZero from './StepZero'

const CustomPlaylistQuiz = () => {
  const [t] = useTranslation()
  const [isUserAuthorized, setIsUserAuthorized] = useState(false)
  const { step, alreadyRedirected } = useCustomPlaylistQuiz()
  const { user } = useUser()

  const history = useHistory()
  const toast = useToast()

  useEffect(() => {
    alreadyRedirected()
  }, [alreadyRedirected])

  useEffect(() => {
    setIsUserAuthorized(false)

    if (user) {
      if (!user.hasCustomPlaylistAssigned) {
        toast({
          description: t('noCustomPlaylistAssigned'),
          duration: 5000,
          status: 'error',
          isClosable: true,
        })
        history.push('/home')
      } else {
        setIsUserAuthorized(true)
      }
    }
  }, [user, history, toast])

  const steps = [
    <StepZero />,
    <StepOne />,
    <StepTwo />,
    <StepThree />,
    <StepFour />,
    <StepFive />,
    <SelectLanguage />,
    <Confirmation />,
  ]

  if (!isUserAuthorized)
    return (
      <Flex w='100%' h='100%' maxW='800px' gap='16px' align='center' justify='center' mx='auto'>
        <Flex flexDir='column' gap='16px' w='100%'>
          <Skeleton height='48px' maxW='100px' w='100%' borderRadius='16px' />
          <Flex flexDir='column' gap='4px' w='100%'>
            <Skeleton height='48px' maxW='350px' w='100%' borderRadius='16px' />
            <Skeleton height='48px' maxW='350px' w='100%' borderRadius='16px' />
          </Flex>
          <Skeleton height='30px' w='100%' maxW='300px' borderRadius='16px' />
          <Skeleton height='30px' w='100%' maxW='300px' borderRadius='16px' />
          <Skeleton height='30px' w='100%' maxW='300px' borderRadius='16px' />
          <Skeleton height='48px' mt='32px' w='100%' maxW='220px' borderRadius='16px' />
        </Flex>
        <Skeleton h='426px' w='100%' maxW='333px' borderRadius='16px' />
      </Flex>
    )

  return (
    <Flex width='100%' minH='calc(100vh - 96px)' flexDirection='column' position='relative'>
      <Flex flexDirection='column' padding='0 20px' w='100%' maxW='1100px' margin='0 auto' flex={1}>
        {step !== 0 && step <= 6 && (
          <Box maxW='500px'>
            <StepHeader step={step} steps={6} />
          </Box>
        )}

        <Box flex={1}>{steps[step]}</Box>
      </Flex>

      <Footer />
    </Flex>
  )
}

export default CustomPlaylistQuiz
