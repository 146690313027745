import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCertificate } from '../../hooks/useCertificate'
import { CertificateImageFile } from '../../tabs/Certificate'
import { CertificateImageSelection } from '../CertificateImageSelection'

/**
 * Renders the certificate signature selection form. This component is composed of a group of components from CertificateImageSelection.
 */
export function CertificateSignatureSelection() {
  const [t] = useTranslation()
  const { handleSignature, signature } = useCertificate()
  const [includeSignatureOnCertificate, setIncludeSignatureOnCertificate] = useState(signature.shouldIncludeSignature)
  const [localFile, setLocalFile] = useState<CertificateImageFile>({ file: signature.signatureImage, error: undefined })

  const handleChangeResponsableName = (responsableName?: string) => {
    handleSignature({ responsableName })
  }

  useEffect(() => {
    if (!includeSignatureOnCertificate) {
      setLocalFile({ file: undefined, error: undefined })
      handleSignature({ signatureImage: undefined, shouldIncludeSignature: false, responsableName: undefined })
    } else {
      handleSignature({ shouldIncludeSignature: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeSignatureOnCertificate])

  useEffect(() => {
    if (typeof localFile.file !== 'string') {
      handleSignature({ signatureImage: !localFile?.error ? localFile.file : undefined })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localFile.file])

  useEffect(() => {
    if (signature) {
      setIncludeSignatureOnCertificate(signature.shouldIncludeSignature)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signature])

  return (
    <Flex flexDir='column' gap='24px' data-testid='cms-certificate--signature-selection'>
      <CertificateImageSelection.SectionTitle sectionTitle={t('includeSignatureOnCertificate')} />
      <CertificateImageSelection.TextInput
        value={signature.responsableName ?? ''}
        label={t('responsableForSignature')}
        placeholder='Nome Completo'
        isDisabled={!includeSignatureOnCertificate}
        onChange={handleChangeResponsableName}
      />
      <CertificateImageSelection.FileForm
        fileAndError={localFile}
        onFileChange={setLocalFile}
        inputFilelabel={t('signature')}
        isDisabled={!includeSignatureOnCertificate}
        isSignature
      />
      <CertificateImageSelection.LabeledCheckbox
        isChecked={includeSignatureOnCertificate}
        onCheck={setIncludeSignatureOnCertificate}
        label={`${t('thisSignatureShouldBeIncludeOnCertificate')}?`}
      />
      <CertificateImageSelection.FileFormPreview
        fileAndError={localFile}
        isDisabled={!includeSignatureOnCertificate}
        previewLabel='360 x 40px'
      />
    </Flex>
  )
}
