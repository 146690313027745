import { useIamStore } from 'src/modules/iam/apis/store'

const TOKEN_NAME = 'authToken'

// custom hook to handle authToken - we use compositon to decouple the auth system and it's storage
export const useAuthToken = () => {
  const { getAccessToken } = useIamStore((store) => ({ getAccessToken: store.getAccessToken }))
  const token = getAccessToken()
  return {
    token
  }
}

export const setAuthToken = (authToken: string) => localStorage.setItem(TOKEN_NAME, authToken)

export const removeAuthToken = () => localStorage.removeItem(TOKEN_NAME)

export const getAuthToken = () => localStorage.getItem(TOKEN_NAME) as string
