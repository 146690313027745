import { QuizValues } from 'src/context/CustomPlaylistQuizContext'

export const initialQuizData = () => {
  const data: QuizValues = {
    skills: [],
    skillsSnapshot: [],
    selectedSkills: [],
    contentTypes: [],
    selectedContentTypes: [],
    languages: [{ value: 'pt-BR', label: 'Português' }],
    subtitles: [{ value: 'pt-BR', label: 'Português' }],
    noContentForSkills: [],
  }

  return data
}

export const reorderItems = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const selectStyles = (height = '48px') => ({
  control: (base: any, { isDisabled }: any) => ({
    ...base,
    height,
    borderRadius: '16px',
    border: '2px solid #E5E5E5',
    backgroundColor: isDisabled ? '#F7F9FA' : '#FFFFFF',
    '&:hover': {
      border: '2px solid #313E4C',
    },
    boxShadow: 'none',
    fontSize: '.875rem',
  }),
  option: (base: any, { isFocused }: any) => ({
    ...base,
    backgroundColor: isFocused ? '#E5F4EB' : null,
    color: 'black',
    '&:active': {
      backgroundColor: '#E5F4EB',
    },
  }),
})

export const MultiSelectStyles = () => ({
  container: (base: any) => ({
    ...base,
    zIndex: 500,
    minHeight: '48px',
    height: 'auto',
  }),
  control: (base: any, { isDisabled }: any) => ({
    ...base,
    minHeight: '48px',
    height: 'auto',
    borderRadius: '16px',
    border: '2px solid #E5E5E5',
    backgroundColor: isDisabled ? '#F7F9FA' : '#FFFFFF',
    '&:hover': {
      border: '2px solid #313E4C',
    },
    boxShadow: 'none',
    fontSize: '.875rem',
  }),
  option: (base: any, { isFocused }: any) => ({
    ...base,
    backgroundColor: isFocused ? '#E5F4EB' : null,
    color: 'black',
    '&:active': {
      backgroundColor: '#E5F4EB',
    },
  }),
  multiValue: (styles: any) => ({
    ...styles,
    backgroundColor: '#E5F4EB',
    borderRadius: '12px',
    border: '2px solid #7ECD9D',
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingRight: '10px',
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: '#313E4C',
    fontWeight: 'bold',
    flexGrow: 1,
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: '#009028',
    cursor: 'pointer',
    backgroundColor: '#7ECD9D',
    borderRadius: '0',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    ':hover': {
      backgroundColor: '#7ECD9D',
      color: '#009028',
    },
  }),
  menuPortal: (styles: any) => ({ ...styles, zIndex: 9999 }),
})
