import cmsClient from "src/services/http/cms-client"
import { getAccessToken } from 'src/modules/iam/apis/store'
import { ReportRepository } from '../repository/ReportRepository'
import { GetPlaylistsUseCase } from '../use-case/GetPlaylistsUseCase'

export function makeGetPlaylists() {
  const token = getAccessToken()

  const repository = new ReportRepository(token, cmsClient)
  return new GetPlaylistsUseCase(repository)
}