import { LoadableComponent } from '@loadable/component'
import { FC } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useIamStore } from 'src/modules/iam/apis/store'

export interface RouteProps {
  isPrivate?: boolean
  component: FC<any> | LoadableComponent<any>
  path: string
  exact?: boolean
}

const RouteWrapper: FC<RouteProps> = ({ component: Component, isPrivate, ...rest }) => {
  const { isLoggedIn } = useIamStore((store) => ({ isLoggedIn: store.isLogged }))

  if (isPrivate && !isLoggedIn()) {
    return <Redirect to='/login' />
  }

  if (!isPrivate && isLoggedIn()) {
    return <Redirect to='/home' />
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />
}

export default RouteWrapper
