import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  useClipboard,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoOpenOutline } from 'react-icons/io5'
import { useUser } from 'src/context/userContext'
import AddExtension from '../Components/AddExtension'
import ItemInBetween from '../Components/ItemInBetween'
const RocketSeat: React.FC = () => {
  const [t] = useTranslation()
  const { user } = useUser()
  const [value, setValue] = useState(user?.email!)
  const { hasCopied, onCopy } = useClipboard(value)

  useEffect(() => {
    if (user) {
      setValue(user?.email!)
    }
  }, [user])

  return (
    <Stack
      w='100%'
      h='100%'
      flexDirection={['column', 'column', 'column', 'row', 'row']}
      justifyContent='center'
      bg='gray.25'
    >
      {/* Extension Side */}
      <AddExtension />
      <ItemInBetween />
      {/* Credentials Side */}
      <Stack
        display='flex'
        justifyContent='flex-start'
        w={['100%', '100%', '100%', '50%', '50%']}
        flexDirection='column'
        alignItems='center'
        paddingTop={['10px', '10px', '20px', '62px', '62px']}
      >
        <Image src='/assets/images/rocketseat-logo.svg' h='38px' display={['flex', 'flex', 'flex', 'none', 'none']} />
        <Text fontFamily='Poppins' fontSize={['18px', '20px', '22px', '28px', '28px']} fontWeight='extrabold'>
          {' '}
          {t('activatePartner.edtechs.rocketseat.Your account is ready to be used')}
        </Text>
        <Box bg='primaryColors.400' w='137px' h='2px' display={['none', 'none', 'none', 'flex', 'flex']} />

        <Text
          fontFamily='Mulish'
          w={['90%', '80%', '70%', '90%', '90%']}
          textAlign='center'
          fontSize='14px'
          paddingY='16px'
          fontWeight='bold'
        >
          {t('activatePartner.edtechs.rocketseat.description')}
        </Text>
        <Image src='/assets/images/rocketseat-logo.svg' h='38px' display={['none', 'none', 'none', 'flex', 'flex']} />

        <Flex w={['90%', '80%', '70%', '90%', '80%']} paddingY='21px'>
          <InputGroup>
            <Input value={value} h='55px' isReadOnly placeholder={user?.email!} />
            <InputRightElement width='120px'>
              <Button
                h='36px'
                fontSize={['14px', '14px', '14px', '16px', '16px']}
                w={['70%', '70%', '70%', '90%', '90%']}
                onClick={onCopy}
                marginLeft={['20px', '20px', '20px', '0px', '0px']}
              >
                {hasCopied ? t('activatePartner.Copied') : t('activatePartner.Copy')}
              </Button>
            </InputRightElement>
          </InputGroup>
        </Flex>
        <Link
          fontFamily='Mulish'
          fontWeight='bold'
          fontSize='16px'
          color='white'
          isExternal={true}
          bg='gray.800'
          href='https://app.rocketseat.com.br/signup'
          textDecoration='none'
          display='flex'
          justifyContent='center'
          alignItems='center'
          w={['90%', '80%', '70%', '90%', '80%']}
          paddingY={['4px', '4px', '12px', '12px', '12px']}
          borderRadius='40px'
          _hover={{ textDecoration: 'none' }}
        >
          {t('activatePartner.edtechs.rocketseat.register')}
          <Icon fontSize='22px' marginLeft='10px' as={IoOpenOutline} />
        </Link>
      </Stack>
    </Stack>
  )
}

export default RocketSeat
