import { createContext, useCallback, useContext, useState } from 'react'
import { useUser } from 'src/context/userContext'
import Content from 'src/modules/cms/domain/Content'
import { useContents } from 'src/modules/cms/hooks/useContents'

interface Value {
  loading: boolean
  content: Content | undefined
  itemToLoad: Content | undefined
  loadContent: (item: Content) => void
  loadContentById: (itemId: string) => void
  resetItemToLoad: () => void
  updateContent: (content: Content) => void
}

interface ProviderProps {
  children: React.ReactNode[] | React.ReactNode
}

const LearningItemModalContext = createContext({} as Value)

export function LearningItemModalProvider({ children }: ProviderProps) {
  const { user } = useUser()
  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState<Content | undefined>(undefined)
  const [itemToLoad, setItemToLoad] = useState<Content | undefined>(undefined)

  const { getContentById } = useContents()

  const changeLearningItemURL = (item: Content) => {
    window.history.pushState(null, '', `/content/${item.id}`)
  }

  const loadContent = useCallback(
    async (item: Content) => {
      if (!user) return

      changeLearningItemURL(item)
      setItemToLoad(item)
      setLoading(true)
      const newContent = await getContentById(item!.id, user)
      setContent(newContent)
      setLoading(false)
    },
    [getContentById, user],
  )

  const loadContentById = async (contentId: string) => {
    if (!user) return

    setLoading(true)
    const newContent = await getContentById(contentId, user)
    setContent(newContent)
    setLoading(false)
  }

  const updateContent = useCallback((newContent: Content) => {
    setContent(newContent)
  }, [])

  const resetItemToLoad = () => setItemToLoad(undefined)

  return (
    <LearningItemModalContext.Provider
      value={{
        loading,
        content,
        itemToLoad,
        resetItemToLoad,
        loadContent,
        loadContentById,
        updateContent,
      }}
    >
      {children}
    </LearningItemModalContext.Provider>
  )
}

export const useLearningItemModal = () => useContext(LearningItemModalContext)
