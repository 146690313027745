import { Box } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useLocation } from 'react-router'

interface Props {
  children?: React.ReactNode
}

const ScrollToTop: React.FC<Props> = ({ children }: Props) => {
  const location = useLocation()

  useEffect(() => {
    document.querySelector('#layout-screen')?.scrollTo(0, 0)
  }, [location])

  return <Box>{children}</Box>
}

export default ScrollToTop
