import { useQuery } from '@apollo/client'
import { Button, Flex, Skeleton, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import LIST_USERS from 'src/graphql/queries/LIST_USERS'
import { ReturnDashInfo } from 'src/pages-admin/Home/home.types'
import { usePermissions } from '../../context-admin/usePermissions'
import { useTeamManagement } from '../../context-admin/useTeamManagement'

type Props = {
  area?: number
  filter?: string
}

const first = 25

const UserList: React.FC<Props> = ({ area, filter }) => {
  const { selectedCollab, collabsList, pageInfo, updateCollabsList, updatePageInfo, incrementCollabsList } =
    usePermissions()
  const { selectedCollabs, handleCollabsChange } = useTeamManagement()

  const { refetch, loading } = useQuery<ReturnDashInfo>(LIST_USERS, {
    variables: { limit: first, areaId: area, name: filter },
    onCompleted: (data) => {
      updateCollabsList(data.listUsers.data)
      updatePageInfo(data.listUsers.paginateInfo)
    },
  })

  const filteredCollaborators = useMemo(() => {
    const newCollabsList = collabsList.filter((item) => item.id !== selectedCollab.id)

    return newCollabsList
  }, [collabsList, selectedCollab.id])

  const fetchMore = async () => {
    if (!pageInfo?.next) return

    const response = await refetch({
      limit: first,
      cursor: Number(pageInfo?.next),
      name: filter,
    })

    incrementCollabsList(response.data.listUsers.data)
    updatePageInfo(response.data.listUsers.paginateInfo)
  }

  return (
    <>
      {loading ? (
        <Flex direction='column' w='100%' mt='16px' gridGap='16px'>
          <Skeleton w='100%' h='43px' borderRadius='8px' />
          <Skeleton w='100%' h='43px' borderRadius='8px' />
          <Skeleton w='100%' h='43px' borderRadius='8px' />
          <Skeleton w='100%' h='43px' borderRadius='8px' />
          <Skeleton w='100%' h='43px' borderRadius='8px' />
        </Flex>
      ) : (
        <Flex id='scrollableDiv' direction='column' mt='16px' w='100%' maxH='calc(100vh - 550px)' overflowY='auto'>
          <InfiniteScroll
            dataLength={filteredCollaborators.length}
            next={fetchMore}
            hasMore={pageInfo?.hasNextPage!}
            loader={
              <Text textAlign='center' display={filteredCollaborators.length === 0 && filter ? 'none' : 'block'}>
                Carregando mais...
              </Text>
            }
            scrollThreshold={0.9}
            scrollableTarget='scrollableDiv'
          >
            {filteredCollaborators?.map((item) => {
              const isSelected = selectedCollabs.some((collab) => collab.id === item.id)

              return (
                <Flex
                  key={item.id}
                  h='43px'
                  align='center'
                  bgColor='#F7F9FA'
                  justify='space-between'
                  borderRadius='8px'
                  p='0 16px'
                  mb='16px'
                  boxShadow='0px 1px 6px rgba(0, 0, 0, 0.1)'
                >
                  <Text ml='8px' fontWeight='700' fontSize='12px' color='#009639'>
                    {item.name}
                  </Text>
                  <Button
                    variant='unstyled'
                    fontWeight='600'
                    fontSize='12px'
                    textDecoration='underline'
                    color={isSelected ? '#009639' : '#313E4C'}
                    onClick={() => handleCollabsChange(item)}
                  >
                    {isSelected ? 'Selecionado' : 'Selecionar'}
                  </Button>
                </Flex>
              )
            })}
          </InfiniteScroll>
        </Flex>
      )}
    </>
  )
}

export default UserList
