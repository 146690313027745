import { UseCase } from 'src/types/UseCase'
import { ReportRepository } from '../repository/ReportRepository'

export type GetPlaylistsUseCaseOutput = {
  id: number
  title: string
}[]

export type GetPlaylistsUseCaseInput = any

export class GetPlaylistsUseCase implements UseCase<GetPlaylistsUseCaseInput, GetPlaylistsUseCaseOutput> {
  constructor(private repository: ReportRepository) {}

  async execute(): Promise<GetPlaylistsUseCaseOutput> {
    const report = await this.repository.getPlaylists()
    return report
  }
}
