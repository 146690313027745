import { orderBy, uniqBy } from 'lodash'
import { useCallback } from 'react'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { makeGetPlaylistById } from 'src/modules/cms/factories/makeGetPlaylistById'
import { makeListPlaylists } from 'src/modules/cms/factories/makeListPlaylists'
import { makeLogPlaylistAccess } from 'src/modules/cms/factories/makeLogPlaylistAccess'
import { useContents } from 'src/modules/cms/hooks/useContents'
import { CertificateDto, CertificateTypeEnum } from 'src/modules/iam/use-case/GetUserCertificatesUseCase'
import { User } from 'src/types/User'
import { makeListPrivatePlaylists } from '../factories/makeListPrivatePlaylists'
import { makeUpdatePlaylistModuleContent } from '../factories/makeUpdatePlaylistModuleContent'
import {
  createEmptyPlaylistWithDefaultCertificate,
  getPlaylistIdsFromCertificates,
  getUserCertificatesListByType,
} from '../utils/helpersForUsePlaylist'

export function usePlaylists() {
  const { formatContentWithProgressAndFavorited } = useContents()
  const playlistList = makeListPlaylists()
  const playlistById = makeGetPlaylistById()

  const findCertificateOnPlaylist = (
    playlist: NewPlaylist,
    certificates?: CertificateDto[],
    type?: 'module',
    moduleId?: string,
  ) => {
    if (!certificates) return undefined

    // eslint-disable-next-line no-extra-boolean-cast
    if (!!moduleId) {
      const findedCertificate = certificates?.find((cert) => cert?.moduleId === moduleId)
      return findedCertificate
    }

    if (type === 'module') {
      const moduleCertificates = certificates?.filter((cert) => cert?.moduleId !== undefined)
      const findedCertificate = moduleCertificates?.find((cert) =>
        playlist?.moduleList?.items?.some((mod) => mod?.id === cert?.moduleId),
      )
      return findedCertificate
    }

    const findedPlaylistCertificate = certificates?.find((cert) => cert?.playlistId === playlist?.id)
    return findedPlaylistCertificate
  }

  const formatPlaylist = useCallback(
    (playlist: NewPlaylist, user: User, certificates?: CertificateDto[], type?: 'module', moduleId?: string) => {
      if (playlist?.moduleList && playlist?.moduleList?.items && Array.isArray(playlist?.moduleList?.items)) {
        playlist.moduleList.items = playlist?.moduleList?.items?.map((item) => ({
          ...item,
          contentList: {
            ...item.contentList,
            items: item.contentList?.items?.map((content) => formatContentWithProgressAndFavorited(content, user)),
          },
        }))

        return {
          ...playlist,
          certificate: findCertificateOnPlaylist(playlist, certificates, type, moduleId),
        }
      }
    },
    [formatContentWithProgressAndFavorited],
  )

  const registerPlaylistAccess = useCallback(async (playlistId: string) => {
    const logPlaylistAccess = makeLogPlaylistAccess()

    const hasLogged = await logPlaylistAccess.execute({ playlistId })
    return hasLogged
  }, [])

  const getCustomPlaylist = useCallback(
    async (user: User): Promise<NewPlaylist | undefined> => {
      const playlist = await playlistById.execute({ id: user.customPlaylistId ?? '' })
      const formattedPlaylist = formatPlaylist(playlist, user)

      return formattedPlaylist
    },
    [playlistById, formatPlaylist],
  )

  const getPlaylist = useCallback(
    async (playlistId: string): Promise<NewPlaylist | undefined> => {
      const playlist = await playlistById.execute({ id: playlistId ?? '' })

      return playlist
    },
    [playlistById],
  )

  const listPlaylists = useCallback(
    async (user: User, type?: 'FEATURED' | 'CUSTOM'): Promise<NewPlaylist[] | undefined> => {
      const userCompanyId = user?.companyId ?? user?.company?.id ?? -1
      const data = await playlistList.execute({
        limit: 20,
        skip: 0,
        companies: userCompanyId ? [userCompanyId, -1] : [-1],
        order: { field: 'title', direction: 'asc' },
        ...(type ? { type } : {}),
      })
      return data.items
    },
    [playlistList],
  )

  const listPlaylistsByCompanies = useCallback(
    async (companies: number[], type?: 'FEATURED' | 'CUSTOM', limit = 20, skip = 0): Promise<{
      items: NewPlaylist[]
      total: number
    } | undefined> => {
      const playlist = await playlistList.execute({
        limit,
        skip,
        companies,
        order: { field: 'title', direction: 'asc' },
        ...(type ? { type } : {}),
      })

      // const sortedPlaylists = data.items.sort((a, b) => {
      //   const aHasCompanyId = a.allowedCompanyIds!.includes(user?.companyId ?? user?.company?.id ?? -1)
      //   const bHasCompanyId = b.allowedCompanyIds!.includes(user?.companyId ?? user?.company?.id ?? -1)

      //   if (aHasCompanyId && !bHasCompanyId) {
      //     return -1
      //   } else if (!aHasCompanyId && bHasCompanyId) {
      //     return 1
      //   } else {
      //     return 0
      //   }
      // })

      return {
        items: playlist.items,
        total: playlist.total,
      }
    },
    [playlistList],
  )

  const getPlaylistById = useCallback(
    async (playlistId: string, user: User): Promise<NewPlaylist | undefined> => {
      const playlist = await playlistById.execute({ id: playlistId })
      const formattedPlaylist = formatPlaylist(playlist, user)

      registerPlaylistAccess(playlist.id)

      return formattedPlaylist
    },
    [playlistById, registerPlaylistAccess, formatPlaylist],
  )

  const getLastAccessedPlaylists = useCallback(
    async (user: User) => {
      const userCompanyId = user?.companyId ?? user?.company?.id ?? -1

      const accessedPlaylistsIds = user?.playlistInfo?.accessed?.map((item) => item.id)
      if (!accessedPlaylistsIds || accessedPlaylistsIds.length <= 0) return []

      const withoutDuplicatedIds = uniqBy(accessedPlaylistsIds, (e) => e)
      const playlists = await playlistList.execute({
        ids: withoutDuplicatedIds,
        companies: userCompanyId ? [userCompanyId, -1] : [-1],
      })
      return playlists.items
    },
    [playlistList],
  )

  const getUsersCertifiedPlaylist = async (user: User, certificates: CertificateDto[]) => {
    const { playlistCertificates } = getUserCertificatesListByType(certificates)
    const playlistIdsFromCertificates = getPlaylistIdsFromCertificates(playlistCertificates)

    const userCompanyId = user?.companyId ?? user?.company?.id ?? -1
    const featuredPlaylistsList = await playlistList.execute({
      ids: playlistIdsFromCertificates,
      companies: userCompanyId ? [userCompanyId, -1] : [-1],
    })

    let customPlaylist: NewPlaylist | undefined = {} as NewPlaylist
    if (user?.customPlaylistId) {
      try {
        customPlaylist = await getPlaylistById(user.customPlaylistId ?? '', user)
      } catch {
        console.error('Ocorreu um erro ao buscar a trilha personalizada do usuário!')
      }
    }

    const mixedPlaylists = customPlaylist
      ? [customPlaylist, ...featuredPlaylistsList.items]
      : featuredPlaylistsList.items

    const playlistsWithCertificate = certificates.map((cert) => {
      const emptyPlaylist = createEmptyPlaylistWithDefaultCertificate(cert)
      // Se houver moduleId no certificado e o tipo da trilha for custom_playlist_module
      if (cert.type === CertificateTypeEnum.CustomPlaylistModule && !!cert.moduleId) {
        if (!customPlaylist) return emptyPlaylist
        const isModuleInsideCustomPlaylist = customPlaylist?.moduleList?.items?.some(
          (module) => module.id === cert.moduleId,
        )
        if (!isModuleInsideCustomPlaylist) return emptyPlaylist
        return (formatPlaylist(customPlaylist, user, certificates, 'module', cert.moduleId) as NewPlaylist) ?? {}
      }
      // Se houver playlistId no certificado e o tipo da trilha for custom_playlist
      if (cert.type === CertificateTypeEnum.CustomPlaylist && !!cert.playlistId) {
        if (!customPlaylist) return emptyPlaylist
        const thisCustomPlaylistHasCertificate = customPlaylist?.moduleList?.items?.some(
          (module) => module.id === cert.moduleId,
        )
        if (!thisCustomPlaylistHasCertificate) return emptyPlaylist
        return (formatPlaylist(customPlaylist, user, certificates, 'module', cert.moduleId) as NewPlaylist) ?? {}
      }
      // O tipo da trilha é features_playlist
      const findedPlaylist = mixedPlaylists.find((playlist) => playlist.id === cert.playlistId)
      if (!findedPlaylist) return emptyPlaylist
      return formatPlaylist(findedPlaylist, user, playlistCertificates) as NewPlaylist
    })

    return orderBy(playlistsWithCertificate, (e) => e.certificate?.createdAt, 'desc') as NewPlaylist[]
  }

  const getUserFinishedPlaylists = useCallback(
    async (user: User) => {
      const userCompanyId = user?.companyId ?? user?.company?.id ?? -1

      const finishedPlaylists = user?.playlistInfo?.completed
      if (finishedPlaylists.length <= 0) return []

      const withoutDuplicatedIds = uniqBy(finishedPlaylists, (e) => e)

      const playlists = await playlistList.execute({
        ids: withoutDuplicatedIds,
        companies: userCompanyId ? [userCompanyId, -1] : [-1],
      })

      const formattedPlaylists = playlists.items.map((item) => formatPlaylist(item, user)) as NewPlaylist[]
      return formattedPlaylists
    },
    [playlistList],
  )

  const updatePlaylistModuleContent = useCallback(
    async (moduleId: string, oldContentId: string, newContentId: string) => {
      const playlistModuleContent = makeUpdatePlaylistModuleContent()

      const hasUpdated = await playlistModuleContent.execute({ moduleId, oldContentId, newContentId })
      return hasUpdated
    },
    [],
  )

  const listPrivatePlaylists = useCallback(async ({ limit, skip } = {
    limit: 20, skip: 0
  }) => {
    const listPrivatePlaylists = makeListPrivatePlaylists()

    const playlists = await listPrivatePlaylists.execute({ limit, skip, status: 'PUBLISHED', })
    return playlists
  }, [])

  return {
    getCustomPlaylist,
    getPlaylist,
    listPlaylists,
    listPlaylistsByCompanies,
    getPlaylistById,
    getUsersCertifiedPlaylist,
    getLastAccessedPlaylists,
    getUserFinishedPlaylists,
    registerPlaylistAccess,
    formatPlaylist,
    updatePlaylistModuleContent,
    listPrivatePlaylists,
  }
}
