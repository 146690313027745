import { Button, Flex, Input, Text, VStack } from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import { useTeams } from '../../context-admin/useTeams'

type Props = {
  handleComponentChange: (value: string) => void
}

const RemoveCollabs: React.FC<Props> = ({ handleComponentChange }) => {
  const { selectedTeam, removeCollabs, handleCollabsRemove } = useTeams()
  const [area] = useState('')
  const [filter, setFilter] = useState('')

  const filteredCollaborators = useMemo(() => {
    const newCollabsList = selectedTeam.members
    const collabsByArea = newCollabsList

    /*const collabsByArea = area
      ? newCollabsList.filter((item) => item.area?.name?.includes(area))
      : newCollabsList;
    */

    if (filter) {
      return collabsByArea.filter((item) => item.name.toLowerCase().includes(filter.toLowerCase()))
    }

    return area ? collabsByArea : newCollabsList
  }, [filter, selectedTeam, area])

  const handleBack = () => {
    handleComponentChange('0')
  }

  const handleNextStep = () => {
    handleComponentChange('REMOVE_COLLABS_STEP_TWO')
  }

  /*
  const handleAreaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setArea(e.target.value);
  };
  */

  return (
    <>
      <Text
        color='#767F89'
        textDecoration='underline'
        fontFamily='Poppins'
        fontWeight='600'
        marginBottom='22px'
        cursor='pointer'
        onClick={handleBack}
      >
        Voltar
      </Text>

      <VStack align='flex-start'>
        <Text fontSize='20px' fontWeight={600} fontFamily='Poppins' color='gray.31'>
          Removendo integrantes de "{selectedTeam.name}"
        </Text>
        <Text color='#767F89' mb='24px'>
          Selecione os integrantes que você deseja remover de "{selectedTeam.name}"
        </Text>

        <Flex direction='column' gridGap='4px' w='100%' mt='24px !important'>
          <Text fontWeight='700' color='#313E4C'>
            Busque colaboradores
          </Text>

          <Input
            variant='newFilled'
            placeholder='Busque por um colaborador'
            w='100%'
            height='36px'
            borderRadius='12px'
            onChange={(e) => setFilter(e.target.value)}
          />
        </Flex>

        {/*
        <Box m="16px 0 !important">
          <Text color="#767F89" fontSize="14px" fontWeight={600} mb="4px">
            Filtros
          </Text>

          <Select
            value={area}
            bgColor="#F7F9FA"
            border="none"
            _hover={{ bgColor: "#F7F9FA" }}
            _focus={{ bgColor: "#F7F9FA" }}
            color="#009639"
            fontWeight={600}
            onChange={handleAreaChange}
            fontSize="12px"
            height="22px"
            width="fit-content"
            className="area-select"
          >
            <option value="">Todas as áreas</option>
            {areas.map((item) => (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            ))}
          </Select>
            </Box> */}

        <Flex id='scrollableDiv' direction='column' mt='16px' w='100%' maxH='calc(100vh - 550px)' overflowY='auto'>
          {filteredCollaborators?.map((item) => {
            const isSelected = removeCollabs.some((collab) => collab.name === item.name)

            return (
              <Flex
                key={Math.random()}
                h='43px'
                align='center'
                bgColor='#F7F9FA'
                justify='space-between'
                borderRadius='8px'
                p='0 16px'
                mb='16px'
                boxShadow='0px 1px 6px rgba(0, 0, 0, 0.1)'
              >
                <Text ml='8px' fontWeight='700' fontSize='12px' color='#009639'>
                  {item.name}
                </Text>
                <Button
                  variant='unstyled'
                  fontWeight='600'
                  fontSize='12px'
                  textDecoration='underline'
                  color={isSelected ? '#FF4949' : '#313E4C'}
                  onClick={() => handleCollabsRemove(item)}
                >
                  {isSelected ? 'Removido' : 'Remover'}
                </Button>
              </Flex>
            )
          })}
        </Flex>

        <Flex w='100%' justify='space-between' mt='24px !important'>
          <Button variant='whiteOutline' fontSize='12px' onClick={handleBack}>
            Voltar
          </Button>

          <Button variant='solidGray' fontSize='12px' disabled={removeCollabs.length === 0} onClick={handleNextStep}>
            Continuar
          </Button>
        </Flex>
      </VStack>
    </>
  )
}

export default RemoveCollabs
