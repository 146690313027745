import { Button, Flex, Spinner } from '@chakra-ui/react'
import { useChangeCompanyArea } from '../../contexts/ChangeCompanyAreaContext'
import { CompanySelection } from '../shared/CompanySelection'
import { TabTitle } from '../shared/TabTitle'
import { UploadCompanyFile } from './UploadCompanyFile'

export const ChangeOfArea = () => {
  const { isLoading, submit, selectedCompany, handleSelectCompany, usersToUpdate } = useChangeCompanyArea()

  return (
    <>
      <Flex flexDir='column' w='100%'>
        <TabTitle label='Trocar Área' />
        <form onSubmit={submit}>
          <Flex flexDir={'column'} gap={'16px'}>
            <CompanySelection
              name='arquivo-csv'
              label='Empresa'
              placeholder='Selecione uma empresa'
              selectedCompany={selectedCompany}
              handleSelectCompany={handleSelectCompany}
            />
            <UploadCompanyFile />
            <Button
              type='submit'
              variant='unstyled'
              w='fit-content'
              h='44px'
              bg='gray.800'
              color='white'
              fontSize='1rem'
              padding='8px 16px'
              isDisabled={Object.keys(selectedCompany).length === 0 || usersToUpdate.length === 0 || isLoading}
              _hover={{ _disabled: { bg: '#3F3D561F' } }}
            >
              {isLoading ? <Spinner /> : 'Atualizar'}
            </Button>
          </Flex>
        </form>
      </Flex>
    </>
  )
}
