import { createContext, useContext, useState } from 'react'
import Content from 'src/modules/cms/domain/Content'
import { ContentDrawerInfo } from './KnowMoreDrawerContext'

interface Value {
  swapContentDrawerInfo?: ContentDrawerInfo
  setSwapContentDrawerInfo: (info: ContentDrawerInfo | undefined) => void
  newContents: Content[]
  setNewContents: (newContents: Content[]) => void
  swapDrawerStep: number
  setSwapDrawerStep: (changeDrawerStep: number) => void
  contentToSwap?: Content
  setContentToSwap: (content: Content | undefined) => void
  knowMoreSwapDrawer?: Content
  setKnowMoreSwapDrawer: (knowMoreSwapDrawer: Content | undefined) => void
  recommendedContentsIds: string[]
  setRecommendedContentsIds: (ids: string[]) => void
  closeSwapContentDrawer: () => void
}

interface Props {
  children: React.ReactNode[] | React.ReactNode
}

const SwapContentDrawerContext = createContext({} as Value)

export function SwapContentDrawerProvider({ children }: Props) {
  const [swapContentDrawerInfo, setSwapContentDrawerInfo] = useState<ContentDrawerInfo>()
  const [newContents, setNewContents] = useState<Content[]>([])
  const [swapDrawerStep, setSwapDrawerStep] = useState<number>(0)
  const [contentToSwap, setContentToSwap] = useState<Content | undefined>()
  const [knowMoreSwapDrawer, setKnowMoreSwapDrawer] = useState<Content | undefined>()
  const [recommendedContentsIds, setRecommendedContentsIds] = useState<string[]>([])

  const closeSwapContentDrawer = () => setSwapContentDrawerInfo(undefined)

  return (
    <SwapContentDrawerContext.Provider
      value={{
        swapContentDrawerInfo,
        setSwapContentDrawerInfo,
        newContents,
        setNewContents,
        swapDrawerStep,
        setSwapDrawerStep,
        contentToSwap,
        setContentToSwap,
        knowMoreSwapDrawer,
        setKnowMoreSwapDrawer,
        recommendedContentsIds,
        setRecommendedContentsIds,
        closeSwapContentDrawer,
      }}
    >
      {children}
    </SwapContentDrawerContext.Provider>
  )
}

export const useSwapContentDrawer = () => useContext(SwapContentDrawerContext)
