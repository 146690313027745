import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'

const EditSkills: React.FC = () => {
  const [t] = useTranslation()
  const { EventsImplementation, track } = useMixpanel()
  const { toggleSkillsDrawer } = useCustomPlaylist()

  const handleEditSkills = () => {
    track(EventsImplementation.PLAYLIST_EDIT_SKILLS())
    toggleSkillsDrawer()
  }

  return (
    <Flex mb='16px'>
      <Flex
        bg='#F7F9FA'
        borderRadius='8px'
        h='auto'
        p='8px 12px'
        align='flex-start'
        justify='center'
        gap='8px'
        flexDir={{ base: 'column', md: 'row' }}
      >
        <Text fontSize='0.75rem' fontWeight={700} color='primaryColors.650'>
          {t('toEditSkillsOfThisPlaylist')},
        </Text>
        <Text
          cursor='pointer'
          fontWeight='700'
          color='primaryColors.650'
          textDecoration='underline'
          fontSize='0.75rem'
          height='auto'
          onClick={handleEditSkills}
          data-testid='edit-playlist-button'
          textTransform='lowercase'
        >
          {t('clickHere')}.
        </Text>
      </Flex>
    </Flex>
  )
}

export default EditSkills
