import { Button, Flex, Input, Text, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { makeDeleteExistingArea } from 'src/modules/dashboard/factory/makeDeleteExistingArea'
import { StepTitle } from '../../commonComponents/StepTitle'
import { StepTransition } from '../../commonComponents/StepTransition'

export function EditExistingAreaDeleteArea() {
  const [t] = useTranslation()
  const [phraseInput, setPhraseInput] = useState('')
  const [loading, setLoading] = useState(false)
  const { area, navigateToStep, handleFinalMessage } = useHandleArea()

  const phraseToConfirmExclusion = `Quero excluir a ${area?.name}`.toLowerCase()

  const deleteExistingArea = makeDeleteExistingArea()
  const toast = useToast()

  const handleSubmitConfirmExclusion = async () => {
    setLoading(true)
    try {
      if (!area?.id) throw new Error('A área selecionada é inválida. Por favor, tente novamente!')
      const areaWasDeleted = await deleteExistingArea.execute({
        areaId: Number(area?.id) ?? -1,
      })
      if (!areaWasDeleted) {
        throw new Error(`Ocorreu um erro ao excluir a área "${area?.name}". Por favor, tente novamente!`)
      }
      handleFinalMessage(t('itemDeleted', { itemName: area?.name }))
      navigateToStep('success-screen')
    } catch (err: any) {
      toast({
        title: t('errorDeletingArea'),
        description: String(err?.message),
        status: 'error',
        isClosable: true,
        duration: 7000,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <StepTransition>
      <Flex flexDir='column' gap='16px'>
        <StepTitle title={t('areaDeleteConfirmation', { areaName: area?.name ?? t('invalidArea') })} />
        <Text fontSize='1rem' fontWeight='600' color='#767F89'>
          {t('whenDeletingAnArea')}
        </Text>
        <Text fontSize='1rem' fontWeight='600' color='#767F89'>
          {t('onceTheAreaIsDeleted')}
        </Text>
        <Text fontSize='1rem' fontWeight='600' color='#313E4C'>
          {t('confirmThisActionByTypingThePhraseBelow')}{' '}
          <strong>Quero excluir a {area?.name ?? t('invalidArea')}</strong>
        </Text>
        <Flex gap='8px'>
          <Input
            placeholder=''
            borderRadius='12px'
            borderWidth='2px'
            borderColor='#E5E5E5'
            px='16px'
            height='36px'
            value={phraseInput}
            onChange={(e) => setPhraseInput(e.target.value)}
          />
          <Button
            variant='startCourseDark'
            fontSize='0.75rem'
            fontFamily='Poppins'
            py='4px'
            px='12px'
            h='auto'
            borderRadius='12px'
            isDisabled={area?.name ? phraseInput.toLowerCase() !== phraseToConfirmExclusion.toLowerCase() : true}
            isLoading={loading}
            onClick={() => {
              handleSubmitConfirmExclusion()
            }}
          >
            {t('confirm')}
          </Button>
        </Flex>
        <Flex mt='8px'>
          <Button
            variant='outline'
            fontSize='0.75rem'
            fontFamily='Poppins'
            py='0'
            px='12px'
            h='36px'
            borderRadius='12px'
            borderWidth='2px'
            borderColor='#efefef'
            _hover={{ bg: '#fcfcfc' }}
            isDisabled={loading}
            onClick={() => {
              navigateToStep('edit-existing-area--select-options')
            }}
          >
            {t('back')}
          </Button>
        </Flex>
      </Flex>
    </StepTransition>
  )
}
