import { ApolloProvider } from '@apollo/client/react'
import { QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { ExploreContextProvider } from 'src/context/exploreContext'
import { ConfettiProvider } from 'src/hooks/useConfetti'
import i18n from 'src/services/i18n/i18n'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import { CustomPlaylistQuizProvider } from './context/CustomPlaylistQuizContext'
import { UserProvider } from './context/userContext'
import useAppApolloClient from './services/apollo/ApolloClient'
import { queryClient } from './services/http/react-query'
import { initLogger } from './shared/Logger'

initLogger()

const App: React.FC = ({ children }) => {
  const client = useAppApolloClient()

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client}>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              <ConfettiProvider>
                <ExploreContextProvider>
                  <UserProvider>
                    <CustomPlaylistQuizProvider>{children}</CustomPlaylistQuizProvider>
                  </UserProvider>
                </ExploreContextProvider>
              </ConfettiProvider>
            </BrowserRouter>
          </I18nextProvider>
        </ApolloProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  )
}

export default App
