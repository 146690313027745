import { Select } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useCorporateUniversityStore } from '../../hooks/useCorporateUniversityStore'

export function OrderFilter() {
  const [t] = useTranslation()
  const { filters, applyFilters } = useCorporateUniversityStore()

  useEffect(() => {
    return () => {
      applyFilters('order', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Select
      value={filters.order}
      bgColor='#F7F9FA'
      border='none'
      _hover={{ bgColor: '#F7F9FA' }}
      _focus={{ bgColor: '#F7F9FA' }}
      fontWeight={600}
      fontSize='.875rem'
      paddingRight='12px'
      w='auto'
      height='22px'
      className={`select-default`}
      onChange={(e) => applyFilters('order', e.target.value)}
      data-testid='cms-order-filter-select'
    >
      <option value=''>{t('admin.filter.Sort')}</option>
      <option value='NAME_ASC'>{t('nameFromAtoZ')}</option>
      <option value='NAME_DESC'>{t('nameFromZtoA')}</option>
      <option value='LATEST_UPDATE'>{t('latestUpdate')}</option>
      <option value='OLDER_UPDATE'>{t('olderUpdate')}</option>
    </Select>
  )
}
