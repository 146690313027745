import { Avatar, Box, Flex, Heading, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import NewPlaylist from 'src/modules/cms/domain/Playlist'

interface Props {
  playlist: NewPlaylist
}

const InstructorsSection: React.FC<Props> = ({ playlist }: Props) => {
  const [t] = useTranslation()
  playlist = playlist!

  return (
    <Flex
      marginX={{ base: '0px', lg: '72px' }}
      paddingX={{ base: '32px', lg: '0px' }}
      paddingTop={{ base: '36px', lg: '0px' }}
      flexDirection='column'
    >
      <Heading size='sm' mt={{ base: '0px', lg: '36px' }} mb='22px'>
        {t('playlist.playlistInstructors')} {playlist.title}
      </Heading>

      <Box>
        {playlist.moduleList?.items
          ?.flatMap((module) => module.contentList?.items?.flatMap((item) => item))
          .map(
            (content) =>
              content?.title &&
              content.instructors &&
              content.instructors?.length !== 0 && (
                <Box marginBottom='40px'>
                  <Heading size='sm' mb='16px'>
                    {content.title}
                  </Heading>
                  <Box>
                    {content.instructors?.map((instructor, index) => (
                      <Flex
                        flexDirection='column'
                        padding='24px'
                        gap='8px'
                        borderRadius='16px'
                        border='2px solid #E5E5E5'
                        marginTop={index && '16px'}
                      >
                        <Flex alignItems='center'>
                          {instructor.imageUrl ? (
                            <Image src={instructor.imageUrl} width='56px' height='56px' borderRadius='100px' />
                          ) : (
                            <Avatar
                              size='md'
                              w='56px'
                              h='56px'
                              name={instructor.name}
                              fontWeight='400'
                              bg='primaryColors.650'
                            />
                          )}
                          <Text
                            fontFamily='Poppins'
                            fontWeight='700'
                            fontSize='16px'
                            lineHeight='150%'
                            color='gray.800'
                            marginLeft='16px'
                          >
                            {instructor.name}
                          </Text>
                        </Flex>
                        {instructor.description && (
                          <Text lineHeight='150%' color='gray.800' marginTop='8px'>
                            {instructor.description}
                          </Text>
                        )}
                      </Flex>
                    ))}
                  </Box>
                </Box>
              ),
          )}
      </Box>
    </Flex>
  )
}

export default InstructorsSection
