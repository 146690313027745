import { Box } from '@chakra-ui/react'
import Vimeo from '@u-wave/react-vimeo'
import React from 'react'
import YouTube from 'react-youtube'
import Content from 'src/modules/cms/domain/Content'
import { checkVideoProvider } from 'src/modules/cms/utils/checkVideoProvider'
import { extractIdFromTrailerUrl } from 'src/modules/cms/utils/extractIdFromTrailerUrl'

interface Props {
  content: Content | undefined
}

const youtubeOptions = {
  playerVars: {
    autoplay: 1,
    loop: 1,
    showinfo: 0,
    modestbranding: 1,
  } as const,
}

const TrailerPage: React.FC<Props> = ({ content }: Props) => {
  if (!content) return null

  const provider = checkVideoProvider(content?.trailerUrl)
  const trailerId = extractIdFromTrailerUrl(content?.trailerUrl)

  return (
    <Box>
      {content.trailerUrl &&
        (provider === 'youtube' ? (
          <YouTube
            videoId={trailerId}
            opts={youtubeOptions}
            containerClassName='trailer-youtube-container course-youtube-rounded'
          />
        ) : (
          <Vimeo
            video={trailerId}
            className='vimeo-video vimeo-video-rounded'
            responsive
            autoplay
            volume={0.5}
            loop
          />
        ))}
    </Box>
  )
}

export default TrailerPage
