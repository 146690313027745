import {
  Box,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Content from 'src/modules/cms/domain/Content'
import { ContentType } from 'src/modules/cms/domain/LearningItem'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { LearningItemProgress } from 'src/types/LearningItemProgress'
import { CheckIcon } from '../../theme/Icons'

interface Props {
  content: Content
  index: number
  playlist?: NewPlaylist
  handleMarkAsDone: () => Promise<void>
  toggleModal: () => void
  toggleCompleteModal: () => void
}

enum CheckColors {
  TODO = '#ABABAB',
  DOING = '#FFC82C',
  DONE = '#13CE66',
}

export const HorizontalLearningCardCheck: React.FC<Props> = ({
  index,
  playlist,
  content,
  toggleModal,
  toggleCompleteModal,
  handleMarkAsDone,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const isCourse = [ContentType.COURSE, 'OldCourse', 'CourseDto'].includes(content.type)

  const handleEvaluate = async () => {
    if (content?.progress === LearningItemProgress.DOING && isCourse) {
      toggleCompleteModal()
    } else {
      if (content?.progress === LearningItemProgress.DONE) return
      setLoading(true)
      await handleMarkAsDone()
      setLoading(false)
    }
  }

  return (
    <Flex flexDirection='column' alignItems='center' marginRight='10px' position='relative'>
      {index ? (
        <Box
          w='1px'
          backgroundColor='gray.290'
          height='184px'
          position='absolute'
          bottom='46px'
          display={{ base: 'none', lg: 'flex' }}
        />
      ) : null}

      {/* {index !== playlist.content.length - 1 ? (
        <Box
          w='1px'
          backgroundColor='gray.290'
          height='100px'
          position='absolute'
          bottom='-100px'
          display={{ base: 'none', lg: 'flex' }}
        />
      ) : null} */}

      <Popover trigger='hover' placement='top'>
        <PopoverContent
          backgroundColor='gray.800'
          borderRadius='20px'
          width={'fit-content'}
          border='none'
          zIndex={'popover'}
        >
          <PopoverArrow backgroundColor='gray.800' />
          <PopoverBody textAlign={'center'}>
            <Text fontSize='14px' color='white' fontWeight={700}>
              {content?.progress === LearningItemProgress.DOING ? t('playlist.Course not completed') : ''}
              {content?.progress === LearningItemProgress.TODO ? t('common.card.startCourse') : ''}
              {content?.progress === LearningItemProgress.DONE ? t('common.card.completedCourse') : ''}
            </Text>
          </PopoverBody>
        </PopoverContent>

        <PopoverTrigger>
          <Flex
            minW='46px'
            minH='46px'
            borderRadius='100px'
            // @ts-ignore
            border={`2px solid ${CheckColors[content?.progress]}`}
            onClick={() => {
              handleEvaluate()
              if (content?.progress === LearningItemProgress.TODO) toggleModal()
            }}
            alignItems='center'
            justifyContent='center'
            cursor={content?.progress !== LearningItemProgress.DONE ? 'pointer' : 'default'}
          >
            <Flex
              w='32px'
              h='32px'
              borderRadius='100px'
              backgroundColor={
                // @ts-ignore
                content?.progress !== LearningItemProgress.TODO ? CheckColors[content?.progress] : ''
              }
              border='2px solid #E5E5E5'
              alignItems='center'
              justifyContent='center'
            >
              {loading ? (
                <>
                  <Skeleton w='100%' h='100%' borderRadius='999px' />
                </>
              ) : (
                <>
                  {content?.progress !== LearningItemProgress.TODO ? (
                    <Icon as={CheckIcon} color='white' w='22px' h='18px' />
                  ) : null}
                </>
              )}
            </Flex>
          </Flex>
        </PopoverTrigger>
      </Popover>
    </Flex>
  )
}
