import { Button, Flex, Text } from '@chakra-ui/react'
import { useTeams } from '../../context-admin/useTeams'
import { useMixpanel } from '../../hooks-admin/mixpanel/useMixpanel'

type Props = {
  handleComponentChange: (value: string) => void
}

const Options: React.FC<Props> = ({ handleComponentChange }) => {
  const { selectedTeam, handleToggleTeamsDrawer, handleSelectedOptions, selectedOptions, getNextOption } = useTeams()
  const { EventsImplementation, track } = useMixpanel()

  const options = [
    {
      component: 'ADD_COLLABS',
      text: 'Adicionar novos integrantes',
      backgroundColor: '#F7F9FA',
      color: '#009639',
    },
    {
      component: 'CHANGE_MANAGER',
      text: 'Trocar gestor',
      backgroundColor: '#F5F9FF',
      color: '#2F80ED',
    },
    {
      component: 'REMOVE_COLLABS',
      text: 'Remover integrantes',
      backgroundColor: '#FFF4F4',
      color: '#FF4949',
    },
    {
      component: 'DELETE_TEAM',
      text: 'Excluir equipe',
      backgroundColor: '#F7F9FA',
      color: '#767F89',
    },
  ]

  const onClickContinue = () => {
    track(EventsImplementation.TEAM_EDIT_TYPE_SELECTION(selectedOptions.toString()))
    handleComponentChange(getNextOption())
  }

  return (
    <>
      <Text
        color='#767F89'
        textDecoration='underline'
        fontFamily='Poppins'
        fontWeight='600'
        marginBottom='22px'
        cursor='pointer'
        onClick={handleToggleTeamsDrawer}
      >
        Fechar
      </Text>
      <Flex flexDir='column' align='flex-start'>
        <Text fontSize='20px' fontWeight={600} fontFamily='Poppins' color='gray.31'>
          Você está editando {selectedTeam.name}
        </Text>
        <Text color='#313E4C' mt='24px' mb='16px' fontWeight='700'>
          O que você quer fazer com esta equipe?
        </Text>

        <Flex mt='16px' flexDir='column' w='100%'>
          {options.map((option) => {
            const isSelected = selectedOptions.some((optionS) => optionS === option.component)
            return (
              <Flex
                border={isSelected ? `2px solid ${option.color}` : ''}
                background={option.backgroundColor}
                boxShadow='0px 1px 6px rgba(0, 0, 0, 0.1)'
                borderRadius='8px'
                onClick={() => handleSelectedOptions(option.component)}
                pl='16px'
                pr='16px'
                pt='14px'
                pb='14px'
                mb='16px'
              >
                <Text color={option.color} fontWeight='700' fontSize='12px'>
                  {option.text}
                </Text>
              </Flex>
            )
          })}
        </Flex>

        <Flex w='100%' justify='flex-end' mt='24px'>
          <Button variant='solidGray' fontSize='12px' disabled={!selectedOptions.length} onClick={onClickContinue}>
            Continuar
          </Button>
        </Flex>
      </Flex>
    </>
  )
}

export default Options
