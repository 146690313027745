import { GET_CMS_PARTNER } from 'src/modules/cms/graphql/queries/GET_PARTNER'
import { GET_PARTNERS } from 'src/modules/cms/graphql/queries/GET_PARTNERS'
import Partner from 'src/modules/cms/domain/Partner'
import { apolloClient, cmsQueryContext } from 'src/services/apollo/ApolloClient'
import ApiError, { NetWorkError } from 'src/shared/ApiError'
import { logger } from 'src/shared/Logger'
import { CmsPartnersQuery, PartnerList } from 'src/types/Cms'

interface IPartnerRepository {
  list(): Promise<PartnerList>
  getById(id: string): Promise<Partner>
}

export class PartnerRepository implements IPartnerRepository {
  list(listPrivatePartners = false) {
    const promise = apolloClient
      .query<CmsPartnersQuery>({
        query: GET_PARTNERS,
        context: cmsQueryContext,
        variables: {
          privateList: listPrivatePartners
        },
      })
      .then(async ({ data, errors }) => {
        if (errors?.length) {
          const code = errors[0].message
          const message = ''
          throw new ApiError(message, code)
        }

        return data.partnerList
      })
      .catch((error) => {
        logger.error('list partners request failed', { error })
        if (error instanceof ApiError) throw error
        throw new NetWorkError()
      })

    return promise
  }

  getById(id: string) {
    const promise = apolloClient
      .query<{ partner: Partner }>({
        query: GET_CMS_PARTNER,
        context: cmsQueryContext,
        variables: {
          id,
        },
      })
      .then(async ({ data, errors }) => {
        if (errors?.length) {
          const code = errors[0].message
          const message = ''
          throw new ApiError(message, code)
        }

        return data.partner
      })
      .catch((error) => {
        logger.error('get partner by id request failed', { error })
        if (error instanceof ApiError) throw error
        throw new NetWorkError()
      })

    return promise
  }
}
