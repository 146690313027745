import { Flex, Heading, Image } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { useUser } from 'src/context/userContext'

interface Props {
  close: () => void
}

const SuccessfulMessage: React.FC<Props> = ({ close }) => {
  const history = useHistory()
  const [t] = useTranslation()
  const { user } = useUser()
  const { playlist, fetchCustomPlaylist } = useCustomPlaylist()

  useEffect(() => {
    setTimeout(() => {
      if (!playlist) history.push('/home')
      else {
        fetchCustomPlaylist(user!).then(() => close())
      }
    }, 1500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlist, history])

  return (
    <Flex h='100%' align='center' justify='center' direction='column' gap='48px' data-testid='verify-successful-message'>
      <Image src='/assets/images/floating-balloon.svg' w={357} h={280} />

      <Heading size='xs' fontWeight='600' marginBottom='8px'>
        {t('home.Custom Playlist.The selected content has been added to your playlist')}
      </Heading>
    </Flex>
  )
}

export default SuccessfulMessage
