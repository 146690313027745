import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoPlayCircle, IoTimeOutline } from 'react-icons/io5'
// @ts-ignore
import SemiCircleProgressBar from 'react-progressbar-semicircle'
import { useUser } from 'src/context/userContext'
import { useWindowSize } from 'src/hooks/useWindowSize'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import ShareButton from '../../../../components/ShareButton'
import {
  getNextPlaylistContent,
  getNumberOfContentsDone,
  getPlaylistConclusionTime,
  scrollToPositionY,
} from '../../../../pages/PlaylistPage/utils'
import { CertificateIcon } from '../../../../theme/Icons'
import { usePlaylists } from '../../hooks/usePlaylists'

interface Props {
  playlist: NewPlaylist
}

const BasicInfoSection: React.FC<Props> = ({ playlist }: Props) => {
  const [t] = useTranslation()
  
  const { width } = useWindowSize()
  const { user } = useUser()
  const { formatPlaylist } = usePlaylists()
  const isMobile = useMemo(() => width && width < 992, [width])

  const goToNextContent = () => {
    const courseElement = document?.querySelector(`#id${nextPlaylistContent?.id}`)

    if (courseElement) {
      /* @ts-ignore */
      scrollToPositionY(courseElement?.offsetTop - 250, '#layout-page')
    }
  }

  if (!playlist) return null

  const playlistInfo = formatPlaylist(playlist, user!)

  const numberOfContentsDone = getNumberOfContentsDone(playlistInfo!)
  const nextPlaylistContent = getNextPlaylistContent(playlistInfo!)
  const showStartButton = nextPlaylistContent
    ? nextPlaylistContent?.id === playlistInfo?.moduleList?.items?.[0].contentList?.items?.[0].id
    : false
  const playlistLength = playlistInfo?.moduleList?.items?.reduce((sum, item) => sum + item.contentList?.items?.length!, 0)
  const isPlaylistFinished = numberOfContentsDone ? numberOfContentsDone === playlistLength : false

  const percentage = (numberOfContentsDone! / playlistLength!) * 100
  const percentageColor = percentage >= 80 ? '#009028' : '#FF9900'

  return (
    <Flex
      padding={{ base: '23px 32px', lg: '23px 72px' }}
      background='gray.60'
      borderRadius='0px 0px 0px 54px'
      alignItems='center'
      width='100%'
      flexWrap={{ base: 'wrap', lg: 'unset' }}
      justifyContent={{ base: 'center', lg: 'flex-start' }}
      gap={{ base: '24px', lg: 'unset' }}
    >
      {!isMobile && (
        <>
          <Button
            variant='startCourseDark'
            onClick={() => {
              goToNextContent()
              // track(EventsImplementation.START_PLAYLIST(playlist.title, playlist.recommended))
            }}
          >
            <Icon w='30px' h='30px' as={IoPlayCircle} />
            <Text marginLeft='8px'>
              {showStartButton && !isPlaylistFinished ? t('playlist.Start Playlist') : ''}
              {!showStartButton && !isPlaylistFinished ? t('playlist.Resume Playlist') : ''}
              {isPlaylistFinished ? t('playlist.Access Playlist') : ''}
            </Text>
          </Button>

          <ShareButton playlist={playlist}></ShareButton>
        </>
      )}

      <Box
        marginLeft='26px'
        marginRight='26px'
        opacity='0.3'
        width='2px'
        background='black'
        transform='rotate(180deg)'
        height='44px'
        display={{ base: 'none', lg: 'flex' }}
      />

      <Flex alignItems='flex-end'>
        <SemiCircleProgressBar stroke={percentageColor} strokeWidth={22} percentage={percentage} diameter={110} />
        <Flex fontWeight='600' flexDirection='column'>
          <Text fontSize='20px' lineHeight='20px'>
            {numberOfContentsDone}/{playlistLength}
          </Text>
          <Text fontSize='11px' lineHeight='11px'>
            {t('playlist.Total Contents')}
            <br />
            {t('playlist.In Playlist')}
          </Text>
        </Flex>
      </Flex>

      <Box
        marginLeft='26px'
        marginRight='26px'
        opacity='0.3'
        width='2px'
        background='black'
        transform='rotate(180deg)'
        height='44px'
        display={{ base: 'none', lg: 'flex' }}
      />

      <Flex>
        <Icon as={IoTimeOutline} w='32px' h='32px' marginRight='12px' />
        <Flex fontWeight='600' flexDirection='column'>
          <Text fontSize='20px' lineHeight='20px'>
            {getPlaylistConclusionTime(playlistInfo!)}
          </Text>
          <Text fontSize='11px' lineHeight='11px'>
            {t('playlist.For Conclusion')}
          </Text>
        </Flex>
      </Flex>

      {playlistInfo?.issuesCertificate ? (
        <>
          <Box
            marginLeft='26px'
            marginRight='26px'
            opacity='0.3'
            width='2px'
            background='black'
            transform='rotate(180deg)'
            height='44px'
            display={{ base: 'none', lg: 'flex' }}
          />

          <Flex>
            <Icon as={CertificateIcon} w='32px' h='30px' marginRight='12px' />
            <Flex fontWeight='600' flexDirection='column'>
              <Text fontSize='20px' lineHeight='20px'>
                {t('home.Course Modal.Certificate')}
              </Text>
              <Text fontSize='11px' lineHeight='11px'>
                {t('playlist.Share on Linkedin')}
              </Text>
            </Flex>
          </Flex>
        </>
      ) : null}
    </Flex>
  )
}

export default BasicInfoSection
