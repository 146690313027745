import { gql } from '@apollo/client'

const REMOVE_EMPLOYEES = gql`
  mutation removeUsers($users: [RemoveUserInput]) {
    removeUsers( users: $users ) {
      errors {
        email
        detail
      }
      users {
        id
      }
    }
  }
`

export default REMOVE_EMPLOYEES
