import { ComponentStyleConfig } from '@chakra-ui/theme'

const HeadingStyle: ComponentStyleConfig = {
  baseStyle: {
    fontFamily: 'Poppins',
  },
  sizes: {
    '3xl': {
      fontSize: '4rem',
    },
    '2xl': {
      fontSize: '3.6rem',
    },
    xl: {
      fontSize: '3rem',
    },
    lg: {
      fontSize: '2.5rem',
    },
    md: {
      fontSize: '2rem',
      lineHeight: '48px',
    },
    sm: {
      fontSize: '1.5rem',
    },
    xs: {
      fontSize: '1.25rem',
    },
  },
}

export default HeadingStyle
