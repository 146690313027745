import Loadable from '@loadable/component'
import React from 'react'
import { withRouter } from 'react-router'
import { BrowserRouter as Router, Switch, useHistory, useLocation } from 'react-router-dom'
import CustomPlaylistQuiz from 'src/components/CustomPlaylistQuiz'
import Layout from 'src/components/Layout'
import { DashboardProvider } from 'src/context-admin/useDashboard'
import { HandleAreaProvider } from 'src/context-admin/useHandleArea'
import { HandleTeamProvider } from 'src/context-admin/useHandleTeam'
import { NewTeamManagementProvider } from 'src/context-admin/useNewTeamManagement'
import { PermissionsProvider } from 'src/context-admin/usePermissions'
import { PlaylistDashboardProvider } from 'src/context-admin/usePlaylistDashboard'
import { TeamManagementProvider } from 'src/context-admin/useTeamManagement'
import { TeamsProvider } from 'src/context-admin/useTeams'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { EvaluateProvider } from 'src/context/EvaluateContext'
import { FeedbackProvider } from 'src/context/FeedbackContext'
import { LoginProvider } from 'src/context/loginContext'
import { QuizProvider } from 'src/context/QuizContext'
import { ChangeCompanyAreaProvider } from 'src/modules/backoffice/contexts/ChangeCompanyAreaContext'
import { PartnersContextProvider } from 'src/modules/cms/hooks/usePartners'
import { useIamStore } from 'src/modules/iam/apis/store'
import { DashboardReportsProvider } from 'src/modules/reports/hooks/useDashboardReports'
import { BackofficePage } from 'src/pages-admin/Backoffice'
import { Cms } from 'src/pages-admin/Cms'
import { CertificateContextProvider } from 'src/pages-admin/Cms/hooks/useCertificate'
import { ImportedContentsContextProvider } from 'src/pages-admin/Cms/hooks/useImportedContents'
import { PrivatePlaylistsProvider } from 'src/pages-admin/Cms/hooks/useListPrivatePlaylists'
import { ImportedContentsUseFormContextProvider } from 'src/pages-admin/Cms/tabs/ImportedContents/hooks/useImportedContentsForm'
import HomeAdmin from 'src/pages-admin/Home'
import OnboardAdmin from 'src/pages-admin/onboard'
import VerifyEmail from 'src/pages-admin/onboard/VerifyEmail'
import { DashboardReports } from 'src/pages-admin/Reports'
// import University from 'src/pages-admin/University'
import Categories from 'src/pages/Categories'
import Home from 'src/pages/Home'
import LearningItem from 'src/pages/LearningItem'
import PrivateRoute from 'src/routes/PrivateRoute'
import PublicRoute from 'src/routes/PublicRoute'
import Storage from 'src/services/storage'
import ScrollToTop from '../hooks/useScrollToTop'
import ActivatePartner from '../redirects/ActivatePartner'
import CustomPlaylist from './CustomPlaylist'
import PrivatePlaylists from './PrivatePlaylists'
import InvalidSignUp from './InvalidSignUp'
import MoodleLogin from './MoodleLogin'
import MyCredentials from './MyCredentials'
import PartnerPage from './PartnerPage'
import PlaylistPage from './PlaylistPage'
import Playlists from './Playlists'
import Search from './Search'
import UserCredentialsPage from './UserCredentials'

const Account = Loadable(() => import('./Account'))
const Login = Loadable(() => import('./Login'))
const Partners = Loadable(() => import('./Partners'))
const SignUp = Loadable(() => import('./SignUp'))
const ExtensionLandingPage = Loadable(() => import('./ExtensionLandingPage'))
const Favorites = Loadable(() => import('./Favorites'))

const Routes: React.FC = () => {
  const { isLoggedIn } = useIamStore((store) => ({ isLoggedIn: store.isLogged }))
  const location = useLocation()
  const history = useHistory()
  const { alreadyRedirected } = useCustomPlaylistQuiz()

  const publicRoutes = ['/login', '/signup', '/admin', '/invalid-signup']
  const isPublic = publicRoutes.some((url) => window.location.href.includes(url))

  if (!isLoggedIn() && !isPublic) {
    Storage.path.set(location?.pathname)
  }

  if (Storage.path.get()) {
    alreadyRedirected()
  }

  if (location.pathname === '/') history.push('/home')

  return (
    <ScrollToTop>
      <LoginProvider>
        <Router>
          <Switch>
            <PublicRoute component={Login} path='/login' />
            <PublicRoute component={SignUp} path='/signup' />
            <PublicRoute component={OnboardAdmin} path='/admin/onboard/:stepSlug' />
            <PublicRoute component={VerifyEmail} path='/admin/verify-email' />
            <PublicRoute component={InvalidSignUp} path='/invalid-signup' />
            <PublicRoute component={ExtensionLandingPage} path='/download-extension' />

            <PartnersContextProvider>
              <EvaluateProvider>
                <FeedbackProvider>
                  <QuizProvider>
                    <Layout>
                      <PrivateRoute component={Home} exact path='/' />
                      <PrivateRoute component={Home} exact path='/home' />
                      <PrivateRoute component={Home} path='/course/:courseId' />
                      <PrivateRoute component={Home} path='/content/:learningItemId' />

                      <PrivateRoute component={Playlists} exact path='/playlists' />
                      <PrivateRoute component={PlaylistPage} exact path='/playlist/:id' />
                      <PrivateRoute component={CustomPlaylist} path='/customPlaylist' />
                      <PrivateRoute component={PrivatePlaylists} path='/privatePlaylists' />
                      <PrivateRoute component={CustomPlaylistQuiz} exact path='/customPlaylistQuiz' />

                      <PrivateRoute component={Partners} exact path='/partners' />
                      <PrivateRoute component={PartnerPage} exact path='/partner/:partnerSlug/:partnerId' />
                      <PrivateRoute component={ActivatePartner} path='/activate/:partnerSlug/' />

                      <PrivateRoute component={MyCredentials} exact path='/myCredentials' />
                      <PrivateRoute component={UserCredentialsPage} path='/credentials/:partnerSlug' />
                      <PrivateRoute component={Account} exact path='/account/:section?' />
                      <PrivateRoute component={Favorites} exact path='/favorites' />

                      <PrivateRoute component={Search} exact path='/search/:query' />
                      <PrivateRoute component={Categories} exact path='/categories/:category/:subcategory?' />

                      <PrivateRoute component={LearningItem} path='/learningItem/:learningItemId' />

                      <PrivateRoute component={ExtensionLandingPage} exact path='/extension' />
                      <PrivateRoute component={MoodleLogin} path='/moodle-login' />

                      <PermissionsProvider>
                        <TeamsProvider>
                          <TeamManagementProvider>
                            <DashboardProvider>
                              <HandleTeamProvider>
                                <HandleAreaProvider>
                                  <DashboardReportsProvider>
                                    <CertificateContextProvider>
                                      <ImportedContentsContextProvider>
                                        <ImportedContentsUseFormContextProvider>
                                          <NewTeamManagementProvider>
                                            <PrivateRoute component={HomeAdmin} path='/admin/home' />
                                            <PrivateRoute component={Account} path='/admin/account' />
                                            {/* <PrivateRoute component={Dashboard} path='/admin/dashboard' /> */}
                                            <PlaylistDashboardProvider>
                                              <PrivateRoute component={DashboardReports} path='/admin/dashboard' />
                                              <PrivatePlaylistsProvider>
                                                <PrivateRoute component={Cms} path='/admin/cms' />
                                              </PrivatePlaylistsProvider>
                                              <ChangeCompanyAreaProvider>
                                                <PrivateRoute component={BackofficePage} path='/admin/backoffice' />
                                              </ChangeCompanyAreaProvider>
                                            </PlaylistDashboardProvider>
                                            <PrivateRoute component={VerifyEmail} path='/admin/verify-email' />
                                            {/* <PrivateRoute component={University} path='/admin/university' />
                              <PrivateRoute component={CreateTrainingPlaylist} path='/admin/create-training-playlist' /> */}
                                          </NewTeamManagementProvider>
                                        </ImportedContentsUseFormContextProvider>
                                      </ImportedContentsContextProvider>
                                    </CertificateContextProvider>
                                  </DashboardReportsProvider>
                                </HandleAreaProvider>
                              </HandleTeamProvider>
                            </DashboardProvider>
                          </TeamManagementProvider>
                        </TeamsProvider>
                      </PermissionsProvider>
                    </Layout>
                  </QuizProvider>
                </FeedbackProvider>
              </EvaluateProvider>
            </PartnersContextProvider>
          </Switch>
        </Router>
      </LoginProvider>
    </ScrollToTop>
  )
}

export default withRouter(Routes)
