import { Box, Flex, Icon, Image, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getContentData } from 'src/helpers/contentInfo'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import useRegisterUserEvent from 'src/hooks/useRegisterUserEvent'
import Content from 'src/modules/cms/domain/Content'
import { ContentType } from 'src/modules/cms/domain/LearningItem'
import { renderCorrectContentInfoByLanguage } from 'src/modules/internationalization/helpers/renderCorrectContentInfoByLanguage'
import { renderCorrectSkillInfoByLanguage } from 'src/modules/internationalization/helpers/renderCorrectSkillInfoByLanguage'

interface Props {
  content: Content
  _hover: boolean
  onClick: () => void
  maxWidth?: string
  style?: React.CSSProperties
}

export const LearningItemCardBody: React.FC<Props> = ({ content, _hover, onClick, style, maxWidth = '400px' }) => {
  const [t] = useTranslation()
  const registerUserEvent = useRegisterUserEvent()
  const { EventsImplementation, track } = useMixpanel()
  const contentData = getContentData(content.type, t)
  const isCourse = [ContentType.COURSE].includes(content.type as ContentType)

  return (
    <Box
      d='inline-block'
      gridTemplateColumns='24% 54% 22%'
      alignItems='center'
      w='100%'
      maxW={maxWidth}
      minW={'250px'}
      background='white'
      backgroundImage={
        content?.imageUrl ?? 'https://i.ibb.co/fS8fCDS/dummy-300x300-009136-ffffff-education-journey.png'
      }
      backgroundPosition='center'
      backgroundSize='cover'
      boxShadow='0 4px 20px rgba(0,0,0,0.05)'
      borderRadius='40px'
      transition='all 0.3s linear'
      h='243px'
      position='relative'
      zIndex={1}
      cursor='pointer'
      style={style}
      onClick={() => {
        onClick()
        if (content.skills)
          registerUserEvent(
            'open-course-modal',
            content.skills.map((item) => item.name),
          )

        track(
          EventsImplementation.OPEN_LEARNING_ITEM_MODAL(
            'Card',
            content.title,
            content.partner?.name ?? '',
            content.skills[0] ? content.skills[0].name : t('playlist.courseCard.course'),
            content.type,
          ),
        )
      }}
    >
      {/* {_hover && content?.trailer?.trailerId ? (
        <EmbeddedVideo
          videoId={learningItem.trailer.trailerId} // adicionar validação para extrair o id da url
          provider={learningItem.trailer.provider as VideoProvider}
        />
      ) : null} */}
      <Box
        w='100%'
        h='100%'
        bottom='0px'
        background={
          _hover ? `linear-gradient(0deg, rgba(0,0,0,0.6923144257703081) 0%, rgba(0,0,0,0.48704637096774195) 46%);` : ''
        }
        borderRadius='40px 40px 40px 40px'
        borderEndStartRadius={_hover ? 0 : '40px'}
        borderEndEndRadius={_hover ? 0 : '40px'}
        transition='all 0.2s linear'
        position={'absolute'}
      />
      <Flex
        w='100%'
        h='60%'
        alignItems='center'
        justifyContent='space-between'
        flexDirection='column'
        position='relative'
      >
        {isCourse ? (
          <>
            {content.partner && (
              <Box
                w={42}
                h={42}
                position='absolute'
                top='16px'
                left='16px'
                borderRadius='10px'
                border='5px solid #FFF'
                backgroundColor='#FFF'
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <Image src={content.partner?.logoUrl} />
              </Box>
            )}
          </>
        ) : (
          <Box
            w={42}
            h={42}
            position='absolute'
            top='16px'
            left='16px'
            borderRadius='10px'
            border='5px solid #FFF'
            backgroundColor='#FFF'
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Icon w='24px' h='24px' as={contentData?.icon} />
          </Box>
        )}
      </Flex>
      <Box
        w='100%'
        h='40%'
        bottom={_hover ? 10 : 0}
        borderRadius='0 0 40px 40px'
        borderEndStartRadius={_hover ? 0 : '40px'}
        borderEndEndRadius={_hover ? 0 : '40px'}
        transition='all 0.2s linear'
        background={`linear-gradient(0deg, ${_hover ? '#000' : 'rgba(0,0,0,0.8217592592592593)'} 18%, rgba(0,30,38,0))`}
        zIndex='10'
      >
        <Flex w='100%' h='100%' alignItems='center' justifyContent='space-evenly' flexDirection='column'>
          <Text
            fontFamily='Mulish'
            textTransform='capitalize'
            fontWeight={600}
            fontSize='12px'
            color='#767F89'
            lineHeight='150%'
            textAlign='center'
            zIndex='10'
            paddingX='2.5%'
            backgroundColor='#EAF2F5'
            borderRadius='100px'
          >
            {contentData?.type}
          </Text>
          <Tooltip
            hasArrow
            bg='gray.50'
            color='gray.800'
            p='16px 14px'
            placement='top'
            borderRadius='4px'
            boxShadow='0px 4px 6px rgba(0, 0, 0, 0.15);'
            label={content.title.length >= 60 && renderCorrectContentInfoByLanguage(content, 'title')}
          >
            <Text
              fontFamily='Poppins'
              fontWeight={600}
              fontSize='18px'
              color='white'
              lineHeight='22px'
              textAlign='center'
              marginBottom='16px'
              zIndex='10'
              paddingX='2.5%'
              maxHeight='44px'
              overflow='hidden'
              css={{
                '-webkit-line-clamp': '2',
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
              }}
              textOverflow='ellipsis'
            >
              {renderCorrectContentInfoByLanguage(content, 'title')}
            </Text>
          </Tooltip>
          <Box
            maxW='80%'
            h='8%'
            background='primaryColors.400'
            borderRadius='8px 8px 0 0'
            padding='3px 12px'
            position='absolute'
            marginTop='16px'
            overflow='hidden'
            bottom={0}
            className='course-card-tag'
          >
            <Text
              fontFamily='Poppins'
              fontWeight={600}
              fontSize='12px'
              color='white'
              lineHeight='15px'
              textTransform='uppercase'
              textAlign='center'
              textOverflow='ellipsis'
              whiteSpace='nowrap'
              overflow='hidden'
            >
              {renderCorrectSkillInfoByLanguage(content.skills[0], 'name')}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}
