import { PlaylistList } from 'src/types/Cms'
import { PlaylistRepository } from '../repositories/PlaylistRepository'

type ListPlaylistsUseCaseInput = any

export type ListPlaylistsUseCaseOutput = PlaylistList

export class ListPlaylistsUseCase {
  constructor(private playlistRepository: PlaylistRepository) {}

  async execute(input?: ListPlaylistsUseCaseInput): Promise<ListPlaylistsUseCaseOutput> {
    const params = { ...input }

    const playlistList = await this.playlistRepository.list(params)
    return playlistList
  }
}
