export const IAM_ERRORS = Object.freeze({
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  USER_NOT_CONFIRMED: 'USER_NOT_CONFIRMED',
  INCORRECT_CREDENTIALS: 'INCORRECT_CREDENTIALS',
  INVALID_PASSWORD: 'INVALID_PASSWORD',
  PASSWORD_RESET_REQUIRED: 'PASSWORD_RESET_REQUIRED',
  LIMIT_EXCEED: 'LIMIT_EXCEED',
  CODE_EXPIRED: 'CODE_EXPIRED',
  INVALID_CODE: 'INVALID_CODE',
})

export function mapIamErrors(code: string) {
  return IAM_ERRORS.hasOwnProperty(code) ? IAM_ERRORS[code as keyof typeof IAM_ERRORS] : IAM_ERRORS.INTERNAL_ERROR
}
