import { Flex, Select } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useCorporateUniversityStore } from '../../hooks/useCorporateUniversityStore'

const statusList = [
  {
    id: 'DRAFT',
    name: 'Rascunho',
  },
  {
    id: 'PUBLISHED',
    name: 'Publicado',
  },
  {
    id: 'REJECTED',
    name: 'Rejeitado',
  },
]

interface StatusFilterProps {
  showRejectedStatus?: boolean
}

export function StatusFilter({ showRejectedStatus = false }: StatusFilterProps) {
  const { filters, applyFilters } = useCorporateUniversityStore()

  useEffect(() => {
    return () => {
      applyFilters('status', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex data-testid='cms-skill-filter'>
      <Select
        value={filters.status}
        bgColor='#F7F9FA'
        border='none'
        _hover={{ bgColor: '#F7F9FA' }}
        _focus={{ bgColor: '#F7F9FA' }}
        fontWeight={600}
        fontSize='.875rem'
        minW='127px'
        height='22px'
        onChange={(e) => applyFilters('status', e.target.value)}
        className={`select-default`}
      >
        <option value=''>Status</option>
        {statusList.map((status) => {
          if (status.id === 'REJECTED' && !showRejectedStatus) return null
          return (
            <option key={status.id} value={status.id}>
              {status.name}
            </option>
          )
        })}
      </Select>
    </Flex>
  )
}
