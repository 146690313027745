import { ComponentStyleConfig } from '@chakra-ui/theme'
const SelectStyle: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    field: {
      borderRadius: '15px',
      border: '2px solid #E5E5E5',
      borderColor: '#E5E5E5',
      fontSize: '16px',
      bg: 'white',
      _placeholder: {
        color: 'rgba(0, 0, 0, 0.26)',
      },
      color: 'gray.800',
      transition: 'all 0.3s',
      _hover: {
        borderColor: 'gray.800',
        bg: 'white',
      },
      _focus: {
        borderColor: 'gray.800',
      },
      _active: {
        borderColor: 'gray.800',
      },
    },
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    md: {
      field: {
        height: '48px',
        paddingX: '25px',
      },
    },
  },
  // styles for different visual variants ("outline", "solid")
  variants: {
    filled: {
      field: {
        borderRadius: '15px',
        border: '2px solid #E5E5E5',
        borderColor: '#E5E5E5',
        fontSize: '16px',
        bg: 'white',
        _placeholder: {
          color: 'rgba(0, 0, 0, 0.26)',
        },
        color: 'gray.800',
        transition: 'all 0.3s',
        _hover: {
          borderColor: 'gray.800',
          bg: 'white',
        },
        _focus: {
          borderColor: 'gray.800',
        },
        _active: {
          borderColor: 'gray.800',
        },
      },
    },
  },
  defaultProps: {
    //@ts-ignore
    variant: 'filled',
  },
}

export default SelectStyle
