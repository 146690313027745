import { UseCase } from 'src/types/UseCase'
import { Team } from '../entities/Team'
import { TeamRepository } from '../repository/TeamRepository'

export type ListTeamsInput = {
  name?: string
  orderBy?: string
}

export type ListTeamsOutput = Team[]

export class ListTeamsUseCase implements UseCase<ListTeamsInput, ListTeamsOutput> {
  constructor(private repository: TeamRepository) {}

  async execute(params?: ListTeamsInput): Promise<ListTeamsOutput> {
    const teams = this.repository.listTeams(params)
    return teams
  }
}
