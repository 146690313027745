// Steps
// Create New Area
import { AddNewArea } from 'src/pages-admin/Home/components/DrawerHandleArea/steps/AddNewArea'
import { AddNewAreaConfirmAreaInfo } from 'src/pages-admin/Home/components/DrawerHandleArea/steps/AddNewAreaConfirmAreaInfo'
import { AddNewAreaSelectManager } from 'src/pages-admin/Home/components/DrawerHandleArea/steps/AddNewAreaSelectManager'
import { AddNewAreaSelectUsers } from 'src/pages-admin/Home/components/DrawerHandleArea/steps/AddNewAreaSelectUsers'
import { AddNewAreaSelectUsersFile } from 'src/pages-admin/Home/components/DrawerHandleArea/steps/AddNewAreaSelectUsersFile'
import { ChooseYourAction } from 'src/pages-admin/Home/components/DrawerHandleArea/steps/ChooseYourAction'
// Edit Area
import { EditExistingAreaAddUsers } from 'src/pages-admin/Home/components/DrawerHandleArea/steps/EditExistingAreaAddUsers'
import { EditExistingAreaAddUsersFile } from 'src/pages-admin/Home/components/DrawerHandleArea/steps/EditExistingAreaAddUsersFile'
import { EditExistingAreaChangeManager } from 'src/pages-admin/Home/components/DrawerHandleArea/steps/EditExistingAreaChangeManager'
import { EditExistingAreaConfirmAddUsers } from 'src/pages-admin/Home/components/DrawerHandleArea/steps/EditExistingAreaConfirmAddUsers'
import { EditExistingAreaConfirmAssignmentChangeManager } from 'src/pages-admin/Home/components/DrawerHandleArea/steps/EditExistingAreaConfirmAssignmentChangeManager'
import { EditExistingAreaConfirmChangeManager } from 'src/pages-admin/Home/components/DrawerHandleArea/steps/EditExistingAreaConfirmChangeManager'
import { EditExistingAreaConfirmRemoveUsers } from 'src/pages-admin/Home/components/DrawerHandleArea/steps/EditExistingAreaConfirmRemoveUsers'
import { EditExistingAreaDeleteArea } from 'src/pages-admin/Home/components/DrawerHandleArea/steps/EditExistingAreaDeleteArea'
import { EditExistingAreaRemoveUsers } from 'src/pages-admin/Home/components/DrawerHandleArea/steps/EditExistingAreaRemoveUsers'
import { EditExistingAreaRenamingArea } from 'src/pages-admin/Home/components/DrawerHandleArea/steps/EditExistingAreaRenamingArea'
import { EditExistingAreaSelectArea } from 'src/pages-admin/Home/components/DrawerHandleArea/steps/EditExistingAreaSelectArea'
import { EditExistingAreaSelectOptions } from 'src/pages-admin/Home/components/DrawerHandleArea/steps/EditExistingAreaSelectOptions'
import { SuccessStep } from 'src/pages-admin/Home/components/DrawerHandleArea/steps/Success'
// Types
import { StepWithElement } from 'src/types/HandleAreaDashboard'
import { EditExistingAreaRemoveUsersFile } from './steps/EditExistingAreaRemoveUsersFile'

export const availableSteps: StepWithElement = {
  // main options
  'choose-options': <ChooseYourAction />,
  'success-screen': <SuccessStep />,
  // new area
  'add-new-area': <AddNewArea />,
  'add-new-area--select-manager': <AddNewAreaSelectManager />,
  'add-new-area--select-users': <AddNewAreaSelectUsers />,
  'add-new-area--select-users-file': <AddNewAreaSelectUsersFile />,
  'add-new-area--confirm-area-info': <AddNewAreaConfirmAreaInfo />,
  // edit area main
  'edit-existing-area': <EditExistingAreaSelectArea />,
  'edit-existing-area--select-options': <EditExistingAreaSelectOptions />,
  // edit area - rename
  'edit-existing-area--renaming-area': <EditExistingAreaRenamingArea />,
  // edit area - add user
  'edit-existing-area--add-users': <EditExistingAreaAddUsers />,
  'edit-existing-area--add-users-file': <EditExistingAreaAddUsersFile />,
  'edit-existing-area--confirm-add-users': <EditExistingAreaConfirmAddUsers />,
  // edit area - remove users
  'edit-existing-area--remove-users': <EditExistingAreaRemoveUsers />,
  'edit-existing-area--remove-users-file': <EditExistingAreaRemoveUsersFile />,
  'edit-existing-area--confirm-remove-users': <EditExistingAreaConfirmRemoveUsers />,
  // edit area - change manager
  'edit-existing-area--change-manager': <EditExistingAreaChangeManager />,
  'edit-existing-area--confirm-assignments-change-manager': <EditExistingAreaConfirmAssignmentChangeManager />,
  'edit-existing-area--confirm-change-manager': <EditExistingAreaConfirmChangeManager />,
  // edit area - delete area
  'edit-existing-area--delete-area': <EditExistingAreaDeleteArea />,
}
