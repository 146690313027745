import { Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'

export function HeaderTitle() {
  const { t } = useTranslation()
  const { playlist, isPlaylistCompleted } = useCustomPlaylist()

  const playlistTitle = playlist?.title ?? t('customPlaylist')

  return (
    <>
      <Heading size='sm'>
        {!isPlaylistCompleted
          ? `${t('playlist.See your progress on the Playlist')} ${playlistTitle}`
          : `${t('playlist.Congratulations, you have completed the')} ${playlistTitle}`}
      </Heading>
      <Text opacity='0.7' lineHeight='20px'>
        {!isPlaylistCompleted
          ? `${t('playlist.We hope you are enjoying the content on the Playlist')} ${playlistTitle}`
          : `${t('playlist.We hope you enjoyed your learning journey')}`}
      </Text>
    </>
  )
}
