import { useMutation } from '@apollo/client'
import { Accordion, Flex, Icon, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { useFormik } from 'formik'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdCancel } from 'react-icons/md'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { makeCreatePlaylistTranslations } from 'src/modules/cms/factories/makeCreatePlaylistTranslations'
import { makeUpdatePlaylistTranslations } from 'src/modules/cms/factories/makeUpdatePlaylistTranslations'
import { UPLOAD_FILE } from 'src/modules/cms/graphql/mutations/UPLOAD_FILE'
import { RemoveContentDrawer } from 'src/pages-admin/Cms/components/RemoveContentDrawer'
import { cmsQueryContext } from 'src/services/apollo/ApolloClient'
import { makeCreatePlaylist } from '../../../../modules/cms/factories/makeCreatePlaylist'
import { makeUpdatePlaylist } from '../../../../modules/cms/factories/makeUpdatePlaylist'
import { useCorporateUniversityStore } from '../../hooks/useCorporateUniversityStore'
import useListPlaylists from '../../hooks/useListPlaylists'
import { useListPrivatePlaylists } from '../../hooks/useListPrivatePlaylists'
import { useRegisterNewContent } from '../../hooks/useRegisterNewContent'
import { useRegisterPlaylistStore } from '../../hooks/useRegisterPlaylistStore'
import { ICreatePlaylist } from '../../types/IPlaylist'
import { RegisterPlaylistFormSchema } from '../../utils/registerPlaylistFormSchema'
import { registerPlaylistInitialValues } from '../../utils/registerPlaylistInitialValues'
import { PermissionsAccordion } from '../Accordions/PermissionsAccordion'
import { PlaylistContentAccordion } from '../Accordions/PlaylistContentAccordion'
import { PlaylistInformationAccordion } from '../Accordions/PlaylistInformationAccordion'
import { ContentAddedDrawer } from '../ContentAddedDrawer'
import { PlaylistFormActionButtons } from '../PlaylistFormActionButtons'
import { makeReplacePlaylistPolicies } from 'src/modules/cms/factories/makeReplacePlaylistPolicies'

export function PlaylistRegisterModal() {
  const [t] = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  const { selectedPlaylist, selectedPlaylistPrivacy, updateSelectedPlaylist, updateSelectedContent } =
    useCorporateUniversityStore()
  const { savedSelectedContents } = useRegisterPlaylistStore()
  const { listPrivatePlaylists } = useListPrivatePlaylists()
  const { refetch } = useListPlaylists()
  const { selectedTeamsToRestrict } = useRegisterNewContent()
  const { isOpen, onClose, onOpen: onOpenAddedDrawer } = useDisclosure()
  const { isOpen: isRemoveContentOpen, onClose: onRemoveContentClose, onOpen: onRemoveContentOpen } = useDisclosure()
  const [uploadFile] = useMutation(UPLOAD_FILE, {
    context: cmsQueryContext,
  })

  const updatePlaylist = makeUpdatePlaylist()
  const createPlaylist = makeCreatePlaylist()
  const createPlaylistTranslations = makeCreatePlaylistTranslations()
  const updatePlaylistTranslations = makeUpdatePlaylistTranslations()

  const isEditMode = useMemo(() => Object.keys(selectedPlaylist ?? {}).length !== 0, [selectedPlaylist])
  const form = useFormik<ICreatePlaylist>({
    initialValues: registerPlaylistInitialValues(selectedPlaylist) ?? {},
    validationSchema: RegisterPlaylistFormSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setIsLoading(true)

      let uploadedImagesResponse = {
        coverImageUploadedUrl: '',
        certificateImageUploadedUrl: '',
      }

      try {
        if (typeof values.imageUrl !== 'string') {
          const coverImageUploaded = await uploadFile({ variables: { file: values.imageUrl } })
          uploadedImagesResponse.coverImageUploadedUrl = coverImageUploaded.data?.uploadFile ?? ''
        }
      } catch (err) {
        toast({
          title: t('errorWhileUploadingPlaylistCover'),
          description: t('anErrorOccurredWhileTryToUploadPlaylistCover'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        setIsLoading(false)
      }

      if (values.certificateImageUrl) {
        try {
          if (typeof values.certificateImageUrl !== 'string') {
            const certificateImageUploaded = await uploadFile({ variables: { file: values.certificateImageUrl } })
            uploadedImagesResponse.certificateImageUploadedUrl = certificateImageUploaded.data?.uploadFile ?? ''
          }
        } catch (err) {
          toast({
            title: 'Erro ao fazer o upload da imagem do certificado da trilha',
            description:
              'Ocorreu um erro ao tentar fazer o upload da imagem do certificado da trilha. Por favor, tente novamente!',
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
          setIsLoading(false)
        }
      }

      const { coverImageUploadedUrl, certificateImageUploadedUrl } = uploadedImagesResponse

      // garantir que será enviado a restrição do conteúdo ao publicar / salvar como rascunho
      // está na varíavel 'selectedTeamsToRestrict'

      const newPlaylist: ICreatePlaylist = {
        ...values,
        type: 'FEATURED',
        status: values.status,
        imageUrl: typeof values.imageUrl === 'string' ? values.imageUrl : coverImageUploadedUrl,
        cardImageUrl: typeof values.imageUrl === 'string' ? values.imageUrl : coverImageUploadedUrl,
        certificateImageUrl:
          typeof values.certificateImageUrl === 'string' ? values.certificateImageUrl : certificateImageUploadedUrl,
        modules: [
          {
            title: 'Módulo 1',
            order: 1,
            contents: savedSelectedContents.map((content, index) => ({ id: content.id, order: index + 1 })),
          },
        ],
      }

      delete newPlaylist?.hasCertificate

      const createOrUpdateTranslations = async ({
        shoudlCreate,
        playlistId,
        language,
      }: {
        shoudlCreate: boolean,
        playlistId: string,
        language: 'es'
      }) => {
        if (shoudlCreate) {
          await createPlaylistTranslations.execute({
            playlistId: playlistId,
            language: language,
            title: values.contentTranslation?.es.title ?? '',
            description: values.contentTranslation?.es.description ?? '',
          })
        } else {
          await updatePlaylistTranslations.execute({
            playlistId: playlistId,
            language: language,
            title: values.contentTranslation?.es.title ?? '',
            description: values.contentTranslation?.es.description ?? '',
          })
        }
      }

      try {
        // If is edit mode, update playlist, else create playlist
        let hasPlaylistSuccessfullyHandled = false
        let playlistId = ''

        if (isEditMode) {
          hasPlaylistSuccessfullyHandled = await updatePlaylist.execute({
            id: selectedPlaylist?.id ?? '',
            private: selectedTeamsToRestrict?.length !== 0,
            ..._.cloneDeep(newPlaylist),
          })
        } else {
          playlistId = await createPlaylist.execute({
            private: selectedTeamsToRestrict?.length !== 0,
            ..._.cloneDeep(newPlaylist),
          })
          
          hasPlaylistSuccessfullyHandled = !!playlistId
        }
        
        await createOrUpdateTranslations(
          {
            shoudlCreate: !selectedPlaylist?.translations?.some((item) => item.language === 'es'),
            playlistId: selectedPlaylist?.id ?? playlistId,
            language: 'es',
          }
        )

        const teamsToRestrict = selectedTeamsToRestrict?.map((team) => team.id)

        const replacePlaylistPolicies = makeReplacePlaylistPolicies()

        await replacePlaylistPolicies.execute({
          playlistId: selectedPlaylist?.id ?? playlistId,
          teamIds: teamsToRestrict,
        })

        if (selectedPlaylistPrivacy === 'public') {
          await refetch()
        } else {
          await listPrivatePlaylists()
        }

        if (hasPlaylistSuccessfullyHandled) {
          onOpenAddedDrawer()
        }
      } catch (err) {
        toast({
          title: `Erro ao ${isEditMode ? 'editar' : 'criar'} a nova trilha`,
          description: `Ocorreu um erro ao ${values.status === 'DRAFT' ? 'salvar como rascunho' : 'publicar'} ${isEditMode ? 'as edições da' : 'a'
            } nova trilha. Tente novamente!`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      } finally {
        setIsLoading(false)
      }
    },
  })

  const handleRemoveContent = useCallback(
    (playlist?: NewPlaylist) => {
      if (playlist) {
        updateSelectedPlaylist(playlist)
        updateSelectedContent(undefined)
        onRemoveContentOpen()
      }
    },
    [updateSelectedPlaylist, updateSelectedContent, onRemoveContentOpen],
  )

  const handleCloseRemoveContentDrawer = useCallback(() => {
    onRemoveContentClose()
  }, [onRemoveContentClose])

  return (
    <>
      <Flex bgColor='white' h='90vh' pos='fixed' left={0} right={0} overflowY='auto'>
        <Flex p='48px 215px' flexDir='column' w='100%'>
          <Text color='#313E4C' fontSize='1.5rem' fontWeight={700} fontFamily='Poppins'>
            {isEditMode ? `${t('edition')}: ${selectedPlaylist?.title}` : t('newPlaylist')}
          </Text>
          <Text color='#767F89' fontSize='1rem' mt='4px'>
            {isEditMode
              ? t('hereYouCanEditPlaylistInfo', { contentTitle: selectedPlaylist?.title })
              : t('hereYouCanRegisterPlaylistInfo')}
          </Text>

          {isEditMode ? (
            <Flex
              w='96px'
              h='36px'
              justify='center'
              align='center'
              mt='22px'
              gap='8px'
              border='2px solid'
              borderColor='gray.800'
              borderRadius='12px'
              cursor='pointer'
              onClick={() => handleRemoveContent(selectedPlaylist)}
            >
              <Icon as={MdCancel} w='24px' h='24px' color='gray.800' data-testid='cms-remove-content-button-on-edit' />
              <Text fontFamily='Poppins' fontWeight='600' fontSize='12px' lineHeight='21px' color='gray.800'>
                {t('delete')}
              </Text>
            </Flex>
          ) : null}

          <form onSubmit={form.handleSubmit}>
            <Flex mb='64px'>
              <Accordion allowToggle w='100%'>
                <PermissionsAccordion form={form} />
                <PlaylistInformationAccordion form={form} />
                <PlaylistContentAccordion form={form} />
              </Accordion>
            </Flex>

            <PlaylistFormActionButtons form={form} isLoading={isLoading} />
          </form>

          {isOpen && <ContentAddedDrawer isOpen={isOpen} onClose={onClose} isEdit={isEditMode} isPlaylist />}
        </Flex>
      </Flex>
      <RemoveContentDrawer
        isOpen={isRemoveContentOpen}
        onClose={handleCloseRemoveContentDrawer}
        refetch={refetch}
        isPlaylist
      />
    </>
  )
}
