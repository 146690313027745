import { Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Tabs, { Tab } from 'src/components/Tabs'
import { useUser } from 'src/context/userContext'
import { Mixpanel } from 'src/services-admin/mixpanel'
import { User } from 'src/types/User'
import HomeSkeleton from './components/Skeleton'
import { homeTabItems } from './utils'

const Home: React.FC = () => {
  const history = useHistory()
  const { user } = useUser()
  const [tabIndex, setTabIndex] = useState(0)
  const [loadingCredentials, setLoadingCredentials] = useState(true)
  const [t] = useTranslation()

  const homeTabs: Tab[] = [
    {
      name: t('users'),
      value: '0',
    },
    {
      name: t('teams'),
      value: '1',
    },
  ]

  const verifyIfUserIsManager = (user: User) => {
    if (!user) return
    const isUserManager = user?.roles?.includes('MANAGER') && !user?.roles?.includes('ADMIN')
    if (isUserManager) {
      history.replace('/admin/dashboard')
    }
    setLoadingCredentials(false)
  }

  const identifyUser = (user: User) => {
    Mixpanel.identify(user.email)
    Mixpanel.people.set({
      distinct_id: user.email,
      name: user.name,
      email: user.email,
      'Company Name': user?.company?.name,
      Active: true,
    })
  }

  useEffect(() => {
    if (user) {
      identifyUser(user)
      verifyIfUserIsManager(user)
    }
  }, [user])

  const onTabClick = (_: string, index: number) => setTabIndex(index)

  return (
    <>
      <Stack w='100%' flexDirection='column' padding='30px 56px 58px' spacing={4}>
        <Helmet>
          <title>Dashboard | Education Journey</title>
        </Helmet>
        {loadingCredentials && <HomeSkeleton />}
        {!loadingCredentials && (
          <>
            <Tabs tabIndex={tabIndex} onTabClick={onTabClick} tabs={homeTabs} />
            {homeTabItems[tabIndex]}
          </>
        )}
      </Stack>
    </>
  )
}

export default Home
