import { useQuery } from '@apollo/client'
import moment from 'moment'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useUser } from 'src/context/userContext'
import GET_DASH_USER_INFO from 'src/graphql/queries/GET_DASH_USER_INFO'
import { DashItem, DashUserInfo, GraphInfo, UserInfos } from '../pages-admin/Dashboard/types'
import { useAuthToken } from './auth'

interface Value {
  dashItems: DashItem[]
  dashUserInfo: UserInfo
  graphData: GraphInfo[]
  areas: Area[]
  filters: Filter
  isLoading: boolean
  handleFilterChange: (filter: string, value: any) => void
  resetFilters: () => void
}

interface UserInfo {
  totalStartedCourses: number
  totalFinishedCourses: number
  totalStudiedTime: number
  users: UserInfos[]
}

type ProviderProps = {
  children: React.ReactNode[] | React.ReactNode
}

type Filter = {
  startDate: Date
  endDate: Date
  area: string
  team: string
}

type ReturnDashboard = {
  dashboardUserInfo: DashUserInfo
}

type Area = {
  id: number
  name: string
}

const DashboardContext = createContext({} as Value)

export function DashboardProvider({ children }: ProviderProps) {
  const { user: admin } = useUser()
  const { token } = useAuthToken()
  const [dashItems, setDashItems] = useState<DashItem[]>([])
  const [dashUserInfo, setDashUserInfo] = useState({} as UserInfo)
  const [graphData, setGraphData] = useState<GraphInfo[]>([])
  const [areas, setAreas] = useState<Area[]>([])
  const [filters, setFilters] = useState({} as Filter)

  const { data, loading } = useQuery<ReturnDashboard>(GET_DASH_USER_INFO, {
    skip: !token || !admin,
    variables: {
      where: {
        date: { startDate: filters.startDate, endDate: filters.endDate },
        area: filters.area,
        teamId: filters.team,
      },
    },
  })

  useEffect(() => {
    if (admin?.company) setAreas(admin.company.areas)
  }, [admin?.company])

  useEffect(() => {
    if (data && data.dashboardUserInfo) {
      const { totalStartedCourses, totalFinishedCourses, totalStudiedTime, items } = data.dashboardUserInfo

      setDashItems(items)

      const userInfosTable = items.reduce((acc: UserInfos[], cur) => {
        const item = acc.length > 0 && acc.find(({ name }) => name === cur.userName)
        if (item) {
          item.courseTime += cur.courseWeeklyHours
          item.coursesInProgress += cur.startedCourse ? 1 : 0
          item.finishedCourses += cur.courseTotalProgress === 1 ? 1 : 0
        } else
          acc.push({
            name: cur.userName,
            id: cur.userId,
            area: cur.userArea,
            courseTime: cur.courseWeeklyHours,
            coursesInProgress: cur.startedCourse ? 1 : 0,
            finishedCourses: cur.courseTotalProgress === 1 ? 1 : 0,
          })
        return acc
      }, [])

      const graphInfos = items.reduce((acc: GraphInfo[], cur) => {
        const item = acc.length > 0 && acc.find(({ date }) => date === cur.date)
        if (item) {
          item.courseTime += cur.courseWeeklyHours
          item.coursesInProgress += cur.startedCourse ? 1 : 0
          item.finishedCourses += cur.courseTotalProgress === 1 ? 1 : 0
        } else
          acc.push({
            date: cur.date,
            courseTime: cur.courseWeeklyHours,
            coursesInProgress: cur.startedCourse ? 1 : 0,
            finishedCourses: cur.courseTotalProgress === 1 ? 1 : 0,
          })
        return acc
      }, [])

      if (graphInfos.length > 8) {
        const monthlyGraphInfo = graphInfos.reduce((acc: GraphInfo[], cur) => {
          const formattedDate =
            cur.date &&
            moment(new Date(Number(cur.date)))
              .locale('pt-BR')
              .format('MMM/YYYY')
          const item = acc.length > 0 && acc.find(({ date }) => date === formattedDate)
          if (item) {
            item.courseTime += cur.courseTime
            item.coursesInProgress += cur.coursesInProgress
            item.finishedCourses += cur.finishedCourses
          } else
            acc.push({
              date: formattedDate,
              courseTime: cur.courseTime,
              coursesInProgress: cur.coursesInProgress,
              finishedCourses: cur.finishedCourses,
            })
          return acc
        }, [])

        setGraphData(monthlyGraphInfo.filter((item) => item.date))
      } else {
        setGraphData(
          graphInfos
            .map((item) => ({
              ...item,
              date: item.date && moment(new Date(Number(item.date))).format('D MMM YYYY'),
            }))
            .filter((item) => item.date),
        )
      }

      setDashUserInfo({
        totalStartedCourses,
        totalFinishedCourses,
        totalStudiedTime,
        users: userInfosTable,
      })
    } else {
      setGraphData([])
      setDashUserInfo({
        totalStartedCourses: 0,
        totalFinishedCourses: 0,
        totalStudiedTime: 0,
        users: [],
      })
    }
  }, [data, token])

  const handleFilterChange = useCallback((filter: string, value: any) => {
    setFilters((prevState) => ({
      ...prevState,
      [filter]: value,
    }))
  }, [])

  const resetFilters = useCallback(() => {
    setFilters({} as Filter)
  }, [])

  return (
    <DashboardContext.Provider
      value={{
        dashItems,
        filters,
        areas,
        dashUserInfo,
        graphData,
        handleFilterChange,
        resetFilters,
        isLoading: loading,
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

export const useDashboard = () => useContext(DashboardContext)
