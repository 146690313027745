import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Text } from '@chakra-ui/react'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { ContentForm } from 'src/pages-admin/Cms/components/ContentForm'
import { IContentData } from 'src/pages-admin/Cms/hooks/useRegisterContentStore'

type Props = {
  form: FormikProps<IContentData>
}

export function FormAccordion({ form }: Props) {
  const [t] = useTranslation()
  return (
    <AccordionItem border='none'>
      {({ isExpanded }) => (
        <Flex flexDir='column' borderBottom={isExpanded ? '' : '1px solid #767F89'}>
          <AccordionButton p='32px 0' _focus={{ boxShadow: 'none' }} _hover={{ background: 'white' }}>
            <Flex w='100%' align='center' justify='space-between' pr='8px'>
              <Text color='#313E4C' fontSize='1.25rem' fontWeight={600} fontFamily='Poppins'>
                {t('contentInfo')}
              </Text>
            </Flex>
            <AccordionIcon w='1.2em' h='1.2em' color='#161616' />
          </AccordionButton>

          <AccordionPanel p='0 0 16px 0'>
            <Flex direction='column' gridGap='8px'>
              <ContentForm form={form} />
            </Flex>
          </AccordionPanel>
        </Flex>
      )}
    </AccordionItem>
  )
}
