import { Button, Fade, Flex, Image, Text, useToast } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { useUser } from 'src/context/userContext'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { quizErrorHandler } from '../ErrorHandler'

const Confirmation: React.FC = () => {
  const [loadingPlaylistCreation, setLoadingPlaylistCreation] = useState(false)
  const [playlistCreated, setPlaylistCreated] = useState(false)
  const [error, setError] = useState({
    hasError: false,
    message: '',
    skillsAndContentTypes: [] as string[],
  })

  const [t] = useTranslation()
  const history = useHistory()
  const { EventsImplementation, track } = useMixpanel()
  const { user, getUser } = useUser()
  const { createCustomPlaylist, updateStep, updateQuiz, allSkills } = useCustomPlaylistQuiz()
  const toast = useToast()

  const handleCreateCustomPlaylist = useCallback(async () => {
    setLoadingPlaylistCreation(true)
    setPlaylistCreated(false)
    setError({
      hasError: false,
      message: '',
      skillsAndContentTypes: [],
    })
    if (!user?.id) {
      setLoadingPlaylistCreation(false)
      return
    }
    const wasCreated = await createCustomPlaylist()
    if (wasCreated?.success) {
      setPlaylistCreated(true)
      track(EventsImplementation.PERSONALIZED_PLAYLIST_CREATED())
      await getUser()
      updateQuiz('skillsSnapshot', [])
      updateQuiz('languages', [])
      updateQuiz('subtitles', [])
      toast({
        title: `${t('playlistCreatedSuccessfully')} 🎉`,
        description: t('yourCustomPlaylistWasCreatedSuccessfullyYouWilLBeRedirect'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      const toastOptions = quizErrorHandler({
        t,
        playlistWasCreated: wasCreated?.success,
        errors: wasCreated.data.errors ?? [],
        skills: allSkills,
      })
      updateQuiz('noContentForSkills', toastOptions.skillsAndContentTypes)
      if (toastOptions.showToast) {
        toast(toastOptions.toastOptions)
      }
      setTimeout(() => {
        history.push('/customPlaylist')
      }, 3000)
    } else {
      const toastOptions = quizErrorHandler({
        t,
        errors: wasCreated.data.errors ?? [],
        playlistWasCreated: Object.keys(wasCreated?.data?.playlist || {}).length > 0,
        skills: allSkills,
      })
      updateQuiz('noContentForSkills', toastOptions.skillsAndContentTypes)
      if (toastOptions.showToast) {
        toast(toastOptions.toastOptions)
      }
      setError({
        hasError: true,
        message: toastOptions?.errorMessage ?? '',
        skillsAndContentTypes: toastOptions?.skillsAndContentTypes,
      })
    }

    setLoadingPlaylistCreation(false)
  }, [createCustomPlaylist, history, toast, track, getUser, user, updateQuiz, EventsImplementation])

  useEffect(() => {
    handleCreateCustomPlaylist()

    return () => {
      setLoadingPlaylistCreation(false)
      setPlaylistCreated(false)
      updateStep(0)
      setError({
        hasError: false,
        message: '',
        skillsAndContentTypes: [],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex height='100%' alignItems='center' justifyContent='center' marginBottom='38px'>
      <Fade
        in={true}
        unmountOnExit
        transition={{
          exit: { delay: 0.5 },
          enter: { duration: 1 },
        }}
      >
        <Flex flexDirection='column' alignItems='center' justifyContent='center' maxW='571px'>
          <Image src='/assets/images/loading-custom-playlist.svg' w='458px' h='395px' />
          {/* <LottieAnimation /> */}
          <Flex flexDir='column' alignItems='center' w='100%' mt='24px'>
            <Text
              marginBottom='20px'
              fontWeight='600'
              fontSize='20px'
              fontFamily='Poppins'
              color='gray.800'
              textAlign='center'
              maxW='560px'
            >
              {loadingPlaylistCreation && t('customPlaylistQuiz.confirmation.description')}
              {!loadingPlaylistCreation && playlistCreated && <>{t('playlistCreatedSuccessfully')} 🎉 {t('playlistCreatedSuccessfullyRedirect')}...</>}
              {!playlistCreated && error.hasError && (
                <Flex flexDir='column' gap='1rem'>
                  <Text textAlign='center' fontSize='1rem' fontWeight='bold' gap='1rem'>
                    {error.message.length > 0 ? error.message : t('anErrorOcurredWhileGeneratingYourPlaylist')}
                  </Text>
                  {error.skillsAndContentTypes.length > 0 && (
                    <Flex flexDir='column' gap='8px'>
                      {error.skillsAndContentTypes.map((item, index) => (
                        <Text key={index} textAlign='center' fontSize='1rem' fontWeight='bold'>
                          {item}
                        </Text>
                      ))}
                    </Flex>
                  )}
                </Flex>
              )}
            </Text>
            {!playlistCreated && error.hasError && (
              <Button variant='unstyled' textDecoration='underline' onClick={() => handleCreateCustomPlaylist()}>
                {t('playlistCreationError')}
              </Button>
            )}
          </Flex>
        </Flex>
      </Fade>
    </Flex>
  )
}

export default Confirmation
