import {
  Flex,
  FormControl,
  FormLabel as CUIFormLabel,
  FormLabelProps,
  Input as CUIInput,
  InputGroup,
  InputProps,
  InputRightElement,
  Select as CUISelect,
  SelectProps,
  Textarea as CUITextArea,
  TextareaProps,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCloudUpload } from 'react-icons/io5'
import ReactSelect, { OptionTypeBase, ValueType } from 'react-select'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import {
  availableLanguages,
  availableSubtitles,
  formatInitialLanguagesValue,
} from 'src/pages-admin/Cms/components/ContentForm/availableLanguages'
import { useImportedContents } from 'src/pages-admin/Cms/hooks/useImportedContents'
import { useImportedContentsForm } from 'src/pages-admin/Cms/tabs/ImportedContents/hooks/useImportedContentsForm'
import { MultiSelectStylesForImportedContents } from 'src/pages-admin/Cms/utils/multiSelectStyles'
import { InfoIcon } from '../InfoIcon'
import { stylesForSkillsMultiSelect } from './stylesForMultiSelect'

const FormLabel = (props: FormLabelProps) => (
  <CUIFormLabel fontWeight='600' fontSize='1rem' {...props}>
    {props.children}
  </CUIFormLabel>
)

const Input = (props: InputProps) => (
  <CUIInput height='36px' border='2px solid #e5e5e5' borderRadius='12px' {...props} />
)

const Textarea = (props: TextareaProps) => (
  <CUITextArea height='105px' border='2px solid #e5e5e5' outline='0px solid #fff' borderRadius='12px' {...props} />
)

const Select = (props: SelectProps) => (
  <CUISelect height='36px' {...props}>
    {props.children}
  </CUISelect>
)

export function ContentInfoForm() {
  const [t] = useTranslation()
  const { allSkills, getAllCmsSkills } = useCustomPlaylistQuiz()
  const { selectedImportedContent } = useImportedContents()
  const { hookForm } = useImportedContentsForm()
  const skills = allSkills.map((item) => ({ value: item.id, label: item.name }))

  const [selectedLanguages, setSelectedLanguages] = useState<typeof availableLanguages>(
    formatInitialLanguagesValue(hookForm.watch('languages')),
  )
  const [selectedSubtitles, setSelectedSubtitles] = useState<typeof availableSubtitles>(
    formatInitialLanguagesValue(hookForm.watch('subtitles')),
  )

  const handleSkillsChange = (values: ValueType<OptionTypeBase, true>) => {
    const skillsIds = values.map((skill) => skill.value)
    hookForm.setValue('skills', skillsIds)
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    hookForm.setValue('imageUrl', file)
  }

  const formatDurationFromSecondsToReadableMinutes = (seconds: number) => {
    let hours = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, '0')
    let minutes = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, '0')

    return `${hours}:${minutes}`
  }

  const handleDurationChange = (value: string) => {
    const formattedValue = value.replace(/[^0-9:]/g, '')
    hookForm.setValue('duration', formattedValue)
  }

  const handleLanguagesChange = (values: any) => {
    const languagesIds = values.map((value: any) => value.value)
    hookForm.setValue('languages', languagesIds)
    setSelectedLanguages(values)
  }

  const handleSubtitlesChange = (values: any) => {
    const subtitlesIds = values.map((value: any) => value.value)
    hookForm.setValue('subtitles', subtitlesIds)
    setSelectedSubtitles(values)
  }

  const selectedSubtitleValue = selectedSubtitles.some((option) => option.value === 'no-subtitle')
    ? [{ value: 'no-subtitle', label: 'Sem legenda' }]
    : selectedSubtitles

  useEffect(() => {
    getAllCmsSkills({ variables: { limit: 500, skip: 0 } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedImportedContent) {
      hookForm.setValue('duration', formatDurationFromSecondsToReadableMinutes(selectedImportedContent?.duration ?? 1))
      hookForm.setValue('url', selectedImportedContent?.url)
      hookForm.setValue('type', selectedImportedContent?.type)
      hookForm.setValue('title', selectedImportedContent?.title)
      hookForm.setValue('description', selectedImportedContent?.description ?? '')
      hookForm.setValue(
        'skills',
        selectedImportedContent?.skills?.map((skill) => skill.id),
      )
      hookForm.setValue('language', new Array(selectedImportedContent?.language) ?? ['pt-BR'])
      hookForm.setValue('subtitles', selectedImportedContent?.subtitles ?? ['pt-BR'])
      hookForm.setValue('imageUrl', selectedImportedContent?.imageUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!selectedImportedContent) return <></>

  return (
    <Flex flexDir='column' gap='24px'>
      <FormControl>
        <FormLabel>{t('contentUrl')}</FormLabel>
        <Controller
          name='url'
          control={hookForm.control}
          render={({ field }) => <Input id='url' defaultValue={selectedImportedContent?.url} {...field} />}
        />
      </FormControl>

      <Flex gap='16px'>
        <FormControl>
          <FormLabel>{t('contentType')}</FormLabel>
          <Controller
            name='type'
            control={hookForm.control}
            render={({ field }) => (
              <Select borderRadius='16px' {...field}>
                <option selected hidden disabled value=''></option>
                <option value='COURSE'>{t('course')}</option>
                <option value='ARTICLE'>{t('article')}</option>
                <option value='VIDEO'>{t('video')}</option>
              </Select>
            )}
          />
        </FormControl>
        <FormControl>
          <FormLabel display='flex' alignItems='center' gap='8px'>
            {t('duration')} <InfoIcon message='Horas : Minutos' />
          </FormLabel>
          <Input
            id='duration'
            defaultValue={hookForm.getValues('duration')}
            value={hookForm.watch('duration')}
            placeholder='00h:00m'
            maxLength={6}
            onChange={(e) => handleDurationChange(e.target.value)}
          />
        </FormControl>
      </Flex>

      <FormControl>
        <FormLabel display='flex' alignItems='center' gap='8px'>
          {t('title')} <InfoIcon message='Ideal que tenha no máximo 70 caracteres' />
        </FormLabel>
        <Controller
          name='title'
          control={hookForm.control}
          render={({ field }) => <Input id='title' defaultValue={selectedImportedContent?.title} {...field} />}
        />
      </FormControl>

      <FormControl>
        <FormLabel>{t('description')}</FormLabel>
        <Controller
          name='description'
          control={hookForm.control}
          render={({ field }) => (
            <Textarea id='description' defaultValue={selectedImportedContent?.description} {...field} />
          )}
        />
      </FormControl>

      <FormControl>
        <FormLabel display='flex' alignItems='center' gap='8px'>
          {t('relatedSkills')}
        </FormLabel>
        <ReactSelect
          isMulti
          isClearable={false}
          defaultValue={selectedImportedContent.skills.map((skill) => ({ value: skill.id, label: skill.name }))}
          styles={stylesForSkillsMultiSelect()}
          placeholder=''
          components={{ IndicatorSeparator: () => null }}
          maxMenuHeight={220}
          onChange={handleSkillsChange}
          options={skills}
        />
      </FormControl>

      <Flex gap='16px'>
        <FormControl id='languages'>
          <FormLabel
            htmlFor='languages'
            fontFamily='Mulish'
            fontWeight='700'
            color='gray.800'
            fontSize='16px'
            marginBottom='2px'
          >
            {t('language')}
          </FormLabel>
          <ReactSelect
            isMulti
            isClearable={false}
            styles={MultiSelectStylesForImportedContents(hookForm)}
            value={selectedLanguages}
            defaultValue={availableLanguages.find((item) => item.value === 'pt-BR')}
            placeholder=''
            components={{ IndicatorSeparator: () => null }}
            maxMenuHeight={220}
            onChange={handleLanguagesChange}
            options={availableLanguages}
          />
        </FormControl>

        <FormControl id='subtitles'>
          <FormLabel
            htmlFor='subtitles'
            fontFamily='Mulish'
            fontWeight='700'
            color='gray.800'
            fontSize='16px'
            marginBottom='2px'
          >
            {t('subtitle')}
          </FormLabel>
          <ReactSelect
            isMulti
            isClearable={false}
            styles={MultiSelectStylesForImportedContents(hookForm)}
            value={selectedSubtitleValue}
            defaultValue={availableSubtitles.find((item) => item.value === 'pt-BR')}
            placeholder=''
            components={{ IndicatorSeparator: () => null }}
            maxMenuHeight={220}
            onChange={handleSubtitlesChange}
            options={
              selectedSubtitles.some((option) => option.value === 'no-subtitle')
                ? [{ value: 'no-subtitle', label: 'Sem legenda' }]
                : availableSubtitles
            }
          />
        </FormControl>
      </Flex>

      <FormControl>
        <FormLabel>{t('contentCover')}</FormLabel>
        <Flex align='center' cursor='pointer'>
          <Input
            type='file'
            id='imageUrl'
            accept='.png,.svg,.jpg,.webp'
            {...hookForm.register('imageUrl', {
              onChange(event) {
                handleFileChange(event)
              },
            })}
            style={{ display: 'none' }}
          />
          <InputGroup onClick={() => document.getElementById('imageUrl')?.click()}>
            <Input
              pr='40px'
              cursor='pointer'
              isReadOnly
              type='text'
              value={
                typeof hookForm.watch('imageUrl') === 'string'
                  ? hookForm.watch('imageUrl')
                  : hookForm.watch('imageUrl')?.name
              }
              title={
                typeof hookForm.watch('imageUrl') === 'string'
                  ? hookForm.watch('imageUrl')
                  : hookForm.watch('imageUrl')?.name
              }
            />
            <InputRightElement h='36px'>
              <IoCloudUpload color='#767F89' />
            </InputRightElement>
          </InputGroup>
        </Flex>
      </FormControl>

      {hookForm.watch('type') === 'COURSE' ? (
        <>
          <FormControl>
            <FormLabel display='flex' alignItems='center' gap='8px'>
              {t('trailerLink')} <InfoIcon message='A url do trailer precisa ser do YouTube ou Vimeo' />
            </FormLabel>
            <Controller
              name='trailerUrl'
              control={hookForm.control}
              render={({ field }) => (
                <Input id='trailerUrl' defaultValue={selectedImportedContent?.trailerUrl} {...field} />
              )}
            />
          </FormControl>
        </>
      ) : null}
    </Flex>
  )
}
