import { Flex, FormControl, Select, Skeleton, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { areaWithEmptyValues, useHandleArea } from 'src/context-admin/useHandleArea'
import { makeListAreas } from 'src/modules/dashboard/factory/makeListAreas'
import { makeGetUserById } from 'src/modules/iam/factory/makeGetUserById'
import { NavigationButtons } from '../../commonComponents/NavigationButtons'
import { StepTitle } from '../../commonComponents/StepTitle'
import { StepTransition } from '../../commonComponents/StepTransition'

interface Area {
  id: number
  name: string
  managerId: number
}

export function EditExistingAreaSelectArea() {
  const [t] = useTranslation()
  const [loadingAreas, setLoadingAreas] = useState(false)
  const [areas, setAreas] = useState<Area[]>([])
  const { navigateToStep, handleSetAreaValues, area, definesAreaFilter } = useHandleArea()

  const getAreas = async () => {
    try {
      setLoadingAreas(true)
      const listAreas = makeListAreas()
      const response = await listAreas.execute({ limit: 500 })
      setAreas(response.items)
    } catch (err) {
      console.error(err)
    } finally {
      setLoadingAreas(false)
    }
  }

  const getManagerById = async (id: number) => {
    if (!id) return

    try {
      const getUserById = makeGetUserById()
      const userInfo = await getUserById.execute({ id })
      return userInfo
    } catch (err) {
      console.error(err)
    }
  }

  const handleSelectArea = async (e: React.ChangeEvent<HTMLSelectElement>, localAreas: Area[]) => {
    const existingArea = localAreas?.find((area) => String(area.id) === e.target.value)
    if (!existingArea) return

    if (existingArea.managerId) {
      getManagerById(existingArea.managerId).then((userInfo) => {
        handleSetAreaValues({ manager: userInfo })
      })
    }

    handleSetAreaValues({ id: e.target.value, name: existingArea.name })
  }

  useEffect(() => {
    handleSetAreaValues(areaWithEmptyValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getAreas()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StepTransition>
      <Flex flexDir='column'>
        <StepTitle title={t('chooseTheAreaYouWantToChange')} />
        <FormControl mt='21px'>
          <Text fontWeight='700' fontSize='1rem'>
            {t('area')}
          </Text>
          {loadingAreas ? (
            <Skeleton w='100%' h='32px' borderRadius='12px' mt='6px' />
          ) : (
            <Select
              defaultValue='empty'
              borderWidth='2px'
              borderColor='#E5E5E5'
              mt='6px'
              fontWeight={600}
              fontSize='1rem'
              w='auto'
              height='auto'
              onChange={(e) => handleSelectArea(e, areas)}
              sx={{
                '&': {
                  padding: '4px 16px',
                  borderRadius: '12px',
                },
              }}
            >
              <option value='empty' disabled>
                {t('selectAnArea')}
              </option>
              {areas?.map((area) => (
                <option value={area.id}>{area.name}</option>
              ))}
            </Select>
          )}
        </FormControl>
        <NavigationButtons
          isDisabled={!area?.id}
          onContinueClick={() => {
            // handleSetAreaValues({ manager: {  } })
            definesAreaFilter(Number(area?.id))
            navigateToStep('edit-existing-area--select-options')
          }}
          onBackClick={() => {
            navigateToStep('choose-options')
            handleSetAreaValues(areaWithEmptyValues)
          }}
        />
      </Flex>
    </StepTransition>
  )
}
