import { getAccessToken } from "../apis/store";
import { UserRepository } from "../repository/UserRepository";
import httpClient from "src/services/http/http-client";
import { ListUsersUseCase } from "../use-case/ListUsersUseCase";


export function makeListUsers() {
  const token = getAccessToken()
  const userRepository = new UserRepository(token, httpClient)
  return new ListUsersUseCase(userRepository)
}