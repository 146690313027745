import { AccordionButton as CUIAccordionButton, AccordionIcon, Text } from '@chakra-ui/react'

interface AccordionButtonProps {
  title?: string
}

export function AccordionButton({ title = '' }: AccordionButtonProps) {
  return (
    <h2>
      <CUIAccordionButton py='32px'>
        <Text flex='1' textAlign='left' fontWeight='700' fontSize='1.25rem' fontFamily='Poppins'>
          {title}
        </Text>
        <AccordionIcon />
      </CUIAccordionButton>
    </h2>
  )
}
