import { Box, Flex, Text } from '@chakra-ui/react'
import { Widget } from '@typeform/embed-react'
import { useTranslation } from 'react-i18next'
import { useEvaluate } from 'src/context/EvaluateContext'
import StepHeader from './StepHeader'

const PlaylistStep = () => {
  const [t] = useTranslation()
  const { playlistInfo, evaluateState, updateStep, userInfo } = useEvaluate()

  const handlePlaylistStep = () => {
    updateStep(3)
  }

  return (
    <Flex flex={1} flexDir='column' alignItems='center' justifyContent='start'>
      <Flex flexDir='column' alignItems='center' flex={1}>
        {evaluateState.source === 'lastPlaylistLearningItem' && <StepHeader step={2} />}

        <Text fontFamily='Poppins' fontWeight='semibold' fontSize={20} padding='0 20px' textAlign='center'>
          {t('evaluate.trailThoughts')} {playlistInfo.title}"?
        </Text>

        <Box flex={1} w='100%' mt='8px' pb='10px'>
          <Widget
            id='RiZ4v9t2'
            hidden={{
              name: userInfo.name,
              email: userInfo.email,
              company: userInfo.company!,
              areas: userInfo.area,
              playlist_name: playlistInfo.title,
              evaluation_source: evaluateState.source,
            }}
            style={{ width: '100%', height: '550px' }}
            className='my-form'
            onSubmit={handlePlaylistStep}
          />
        </Box>
      </Flex>
    </Flex>
  )
}

export default PlaylistStep
