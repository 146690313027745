type Relation = {
  [key: string]: {
    default: string[]
    other: string[]
  }
}

const subCategoriesSkillsRelation: Relation = {
  marketing: {
    default: ['innovation', 'storytelling', 'market-intelligence'],
    other: [
      'e-commerce',
      'gamification',
      'brand-management',
      'growth-hacking',
      'pricing',
      'big-data',
      'digital',
      'digital-marketing',
      'neuromarketing',
      'power-bi',
      'social-media',
      'google-adwords',
      'facebook',
      'youtube',
      'canvas',
      'customer-success',
      'instagram',
      'okrs',
    ],
  },
  'finance-and-accounting': {
    default: ['cash-flow', 'cost-management', 'financial-market'],
    other: [
      'excel',
      'swot-analysis',
      'finance',
      'cryptocurrency',
      'financial-mathematics',
      'decision-making',
      'investments',
      'pitch',
    ],
  },
  sales: {
    default: ['negotiation', 'e-commerce', 'swot-analysis'],
    other: [
      'gamification',
      'brand-management',
      'growth-hacking',
      'pricing',
      'crm',
      'excel',
      'pitch',
      'customer-success',
      'presentation',
      'okrs',
    ],
  },
  'leadership-and-management': {
    default: ['innovation', 'nonviolent-communication', 'team-work'],
    other: [
      'swot-analysis',
      'finance',
      'assertiveness',
      'attraction-and-selection',
      'teams',
      'strategy',
      'conflict-management',
      'people-management',
      'time-management',
      'influence',
      'oratory',
      'communication',
      'pdi',
      'strategic-planning',
      'scrum',
      'remote-work',
      'logistics',
      'planning',
      'pitch',
      'presentation',
      'okrs',
    ],
  },
  'project-management': {
    default: ['prototyping', 'agile', 'design-thinking'],
    other: [
      'logistics',
      'negotiation',
      'strategic-planning',
      'planning',
      'scrum',
      'excel',
      'cash-flow',
      'cost-management',
      'digital',
      'power-bi',
      'environmental-audit',
      'data-analytics',
      'management',
      'market-fit',
      'environmental-projects',
      'market-intelligence',
      'pitch',
      'customer-success',
      'presentation',
      'okrs',
    ],
  },
  'human-resources': {
    default: ['innovation', 'team-work', 'attraction-and-selection'],
    other: [
      'office',
      'assertiveness',
      'coaching',
      'teams',
      'pdi',
      'remote-work',
      'storytelling',
      'organizational-culture',
      'talent-management',
      'libras',
      'swot-analysis',
      'okrs',
    ],
  },
  products: {
    default: ['project-management', 'strategic-planning', 'scrum'],
    other: [
      'market-fit',
      'prototyping',
      'storytelling',
      'studio-game',
      'excel',
      'planning',
      'pitch',
      'presentation',
      'ux',
      'mindset',
      'okrs',
    ],
  },
  'leadership-and-influence': {
    default: ['nonviolent-communication', 'negotiation', 'team-work'],
    other: [
      'assertiveness',
      'attraction-and-selection',
      'teams',
      'people-management',
      'pdi',
      'remote-work',
      'conflict-management',
      'influence',
      'oratory',
      'communication',
      'mindset',
    ],
  },
  entrepreneurship: {
    default: ['design-thinking', 'team-work', 'teams'],
    other: [
      'oratory',
      'communication',
      'scrum',
      'negotiation',
      'management',
      'innovation',
      'people-management',
      'e-commerce',
      'pricing',
      'microeconomics',
      'brand',
      'neuromarketing',
      'creativity',
      'productivity',
      'pitch',
      'presentation',
      'okrs',
    ],
  },
  services: { default: ['innovation', 'e-commerce', 'solid-waste'], other: [] },
  design: {
    default: ['digital-design', 'corel-draw', 'photoshop'],
    other: [
      'after-effects',
      'cinema-4d',
      'storytelling',
      'canvas',
      'design-thinking',
      'powerpoint',
      'dreamweaver',
      'premiere',
      'photography',
      'studio-game',
      'illustrator',
      'indesign',
      'lightroom',
      'video-production',
      'ux',
    ],
  },
  'environmental-management': {
    default: ['solid-waste', 'environmental-licensing', 'environmental-audit'],
    other: ['environmental-education', 'geoprocessing', 'environmental-laws', 'environmental-monitoring'],
  },
  productivity: {
    default: ['time-management', 'team-work', 'remote-work'],
    other: ['strategic-planning', 'agile', 'swot-analysis', 'planning', 'mindset', 'okrs'],
  },
  'business-strategy': {
    default: ['market-intelligence', 'strategy', 'strategic-planning'],
    other: [
      'innovation',
      'e-commerce',
      'negotiation',
      'teams',
      'people-management',
      'pricing',
      'brand',
      'neuromarketing',
      'logistics',
      'cost-management',
      'digital',
      'brand-management',
      'english',
      'team-work',
      'okrs',
    ],
  },
  'career-development': {
    default: ['time-management', 'coaching', 'swot-analysis'],
    other: ['productivity', 'organizational-culture', 'talent-management', 'decision-making', 'mindset'],
  },
  happiness: {
    default: ['coaching', 'time-management', 'organizational-culture'],
    other: ['decision-making', 'neuroscience', 'self-care', 'growth-mentality', 'pnl', 'mindset'],
  },
  'personal-marketing': {
    default: ['coaching', 'organizational-culture', 'oratory'],
    other: ['communication', 'linkedin', 'mindset'],
  },
  'self-knowledge': {
    default: ['growth-mentality', 'neuroscience', 'self-care'],
    other: [
      'mindset',
      'decision-making',
      'pnl',
      'productivity',
      'team-work',
      'conflict-management',
      'influence',
      'memorization',
    ],
  },
  'emotional-intelligence': {
    default: ['nonviolent-communication', 'growth-mentality', 'coaching'],
    other: ['self-care', 'pnl', 'conflict-management', 'influence'],
  },
  languages: {
    default: ['english', 'italian', 'spanish'],
    other: ['libras', 'german', 'japanese', 'mandarin'],
  },
  'data-science': {
    default: ['power-bi', 'big-data', 'database'],
    other: [
      'python',
      'data-visualization',
      'storytelling',
      'excel',
      'data-analytics',
      'growth-hacking',
      'social-media',
      'ai',
      'chatbots',
      'dashboard',
      'statistics',
      'probability',
      'programming',
    ],
  },
  'web-development': {
    default: ['php', 'wordpress', 'cloud'],
    other: [
      'web-design',
      'developer',
      'html',
      'man-machine-interaction',
      'python',
      'dreamweaver',
      'flash',
      'mysql',
      'ux',
    ],
  },
  'mobile-development': {
    default: ['ios', 'android', 'chatbots'],
    other: ['man-machine-interaction', 'python', 'ux'],
  },
  'software-development': {
    default: ['python', 'chatbots', 'cloud'],
    other: [
      'developer',
      'ai',
      'programming',
      'studio-game',
      'cyber-security',
      'scripting',
      'man-machine-interaction',
      'ms-visio',
      'mysql',
      'ux',
    ],
  },
  'computer-science-and-engineering': {
    default: ['python', 'programming', 'cloud'],
    other: ['cyber-security', 'scripting', 'html', 'data-visualization', 'linux'],
  },
  computing: {
    default: ['windows', 'word', 'g-suite'],
    other: ['access', 'ms-visio'],
  },
  'work-security': {
    default: [],
    other: [],
  },
  'architecture-and-engineering': {
    default: ['architecture', 'autocad'],
    other: [],
  },
}

export default subCategoriesSkillsRelation
