import { Box, Flex, Text } from '@chakra-ui/react'

type Props = {
  step?: number
  title: string
  description?: string
}

const StepHeader: React.FC<Props> = ({ step, title, description }) => {
  return (
    <Box>
      <Flex mt='4px' mb='8px' gap='4px'>
        {step && (
          <Text color='#009639' d='flex' h='auto' w='auto' fontFamily='Poppins' fontWeight={600} fontSize='1.25rem'>
            {step}.
          </Text>
        )}
        <Text fontFamily='Poppins' fontWeight={600} fontSize='20px'>
          {title}
        </Text>
      </Flex>

      {description && (
        <Text fontFamily='Mulish' fontWeight={600} fontSize='1.125rem' pl='18px'>
          {description}
        </Text>
      )}
    </Box>
  )
}

export default StepHeader
