import { Flex, FormControl, FormLabel, Switch, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { CertificateTypes, useCertificate } from '../../hooks/useCertificate'

interface CheckboxProps {
  isChecked: boolean
  type: keyof CertificateTypes
  label: string
}

export function CertificateType({ isChecked, type, label }: CheckboxProps) {
  const [t] = useTranslation()
  const { handleCertificateTypes } = useCertificate()

  function handleChange() {
    handleCertificateTypes(type)
  }

  return (
    <Flex gap='16px'>
      <FormControl display='flex' alignItems='center'>
        <FormLabel fontSize='1rem' fontWeight='700' fontFamily='Mulish' mb='0'>
          {label}
        </FormLabel>
        <Flex gap='8px' align='center' justifyContent='center'>
          <Switch colorScheme='green' isChecked={isChecked} onChange={handleChange} />
          <Text fontWeight='500' fontSize='1rem'>
            {isChecked ? t('emit') : t('notEmit')}
          </Text>
        </Flex>
      </FormControl>
    </Flex>
  )
}
