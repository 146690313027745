const appConfig = {
  applicationName: 'ui-main',
  env: process.env.NODE_ENV,
  hrAppBaseUrl: process.env.REACT_APP_HR_APP_URL,
  mainAppBaseUrl: process.env.REACT_APP_MAIN_APP_URL || "https://app.education-journey.com",
  datadog: {
    clientToken: process.env.REACT_APP_DATA_DOG || '',
  },
  backend: {
    restBaseUrl: process.env.REACT_APP_REST_API_URI,
    graphqlUrl: process.env.REACT_APP_API_URI || '',
    cmsGraphqlUrl: process.env.REACT_APP_CMS_API_URI || 'https://cms-jmyusmpnnq-uk.a.run.app/graphql',
  },
  tests: {
    interaction: {
      delay: 50,
      loading: 1000,
    },
  },
}

export default appConfig
