import { IconButton, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { useState } from 'react'
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5'
import '../../services/i18n/i18n'
import CopyButton from './CopyButton'

interface Props {
  password: string
}

const PasswordField: React.FC<Props> = ({ password }: Props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  return (
    <InputGroup w='100%' bg='none' alignItems='center'>
      <Input
        padding='0px'
        variant='unstyled'
        fontSize='20px'
        type={showPassword ? 'text' : 'password'}
        value={password}
      />
      <InputRightElement marginRight='40px' width='4.5rem' alignSelf='center'>
        <IconButton
          cursor='pointer'
          icon={showPassword ? <IoEyeOffOutline fontSize='24px' /> : <IoEyeOutline fontSize='24px' />}
          backgroundColor='transparent'
          fontSize='md'
          marginBottom='10px'
          color='black'
          _active={{ bg: 'transparent' }}
          _focus={{ bg: 'transparent' }}
          _hover={{ bg: 'transparent' }}
          alignSelf='center'
          size='sm'
          fontFamily='Poppins'
          fontWeight='bold'
          aria-label='Show/hide password'
          onClick={() => setShowPassword(!showPassword)}
          type='button'
        />
      </InputRightElement>
      <CopyButton value={password} />
    </InputGroup>
  )
}

export default PasswordField
