import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { User } from 'src/types/User'
import { UserListCard } from '../UserListCard'

interface ManagerListViewProps {
  manager?: User
}

export function ManagerListView({ manager }: ManagerListViewProps) {
  const [t] = useTranslation()
  const { area } = useHandleArea()

  return (
    <Flex flexDir='column' gap='8px'>
      <Text fontSize='1rem' fontWeight='700'>
        {t('areaManager')}
      </Text>
      <UserListCard data={manager ?? area?.manager!} />
    </Flex>
  )
}
