import { gql, useMutation, useQuery } from '@apollo/client'
import { Grid } from '@chakra-ui/react'
import QueryString from 'qs'
import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie'
import { useLocation, useParams } from 'react-router-dom'
import { ACTIVATE_PARTNER } from 'src/graphql/mutations/ACTIVATE_PARTNER'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { Partner } from 'src/types/Partner'
import Loader from '../../components/Loader'
import '../../services/i18n/i18n'
import Fluency from './Edgecases/fluency'
import RocketSeat from './Edgecases/rocketseat'
import HasCredentials from './HasCredentials/HasCredentials'

export const GET_PARTNER = gql`
  query GetPartner($slug: String!) {
    GetPartner(slug: $slug) {
      name
      slug
      squareLogo
      accessLink
    }
  }
`

export interface ReturnActivatePartner {
  ActivatePartner: {
    activated: boolean
    credentials: UserCredentials
  }
}

export interface Params {
  partnerSlug: string
}

export interface UserCredentials {
  login: string
  password: string
}

export interface ReturnGetPartner {
  GetPartner: Partial<Partner>
}

const ActivatePartner: React.FC = () => {
  const cookies = new Cookies()
  const { partnerSlug } = useParams<Params>()
  const location = useLocation()
  const { redirectLink } = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const [activated, setActivated] = useState(false)
  const [credentials, setCredentials] = useState<UserCredentials | undefined>(undefined)
  const [edgecase, setEdgeCase] = useState<string | null>(null)
  const [activate, { loading }] = useMutation<ReturnActivatePartner>(ACTIVATE_PARTNER)
  const { data, loading: loadingPartner } = useQuery<ReturnGetPartner>(GET_PARTNER, {
    variables: { slug: partnerSlug },
  })
  const partner = data?.GetPartner
  const { track, EventsImplementation } = useMixpanel()

  useEffect(() => {
    if (activated) return

    activate({
      variables: {
        partnerSlug,
      },
    })
      .then((response) => {
        const userCredentials = response.data?.ActivatePartner.credentials
        const activated = response.data?.ActivatePartner.activated

        setCredentials(userCredentials)

        const isExtensionActive = !document.getElementById('extensionInstallButton')
        track(EventsImplementation.ACCESS_PARTNER(partner?.name as string, isExtensionActive))

        // path 1. user just activated partner, so show instructions
        if (activated) {
          if (activated && !userCredentials) {
            setEdgeCase(partnerSlug)
          }
          return setActivated(true)
        }

        // path 2. user had already activated partner, so go to course link
        if (redirectLink?.length && isExtensionActive) {
          cookies.set('redirectLink', redirectLink, { maxAge: 300 })
          window.location.href = redirectLink as string

          return
        }

        return
      })
      .catch(() => {})
  }, []) // eslint-disable-line

  const renderEdgeCase = () => {
    if (edgecase === 'fluency-academy') return <Fluency />
    if (edgecase === 'rocketseat') return <RocketSeat />
  }

  if (loading || loadingPartner) return <Loader />

  return (
    <Grid placeItems='center' h='100%'>
      {credentials ? (
        <HasCredentials redirectLink={redirectLink as string} partner={partner!} credentials={credentials} />
      ) : (
        renderEdgeCase()
      )}
    </Grid>
  )
}

export default ActivatePartner
