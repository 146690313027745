import { Text } from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Content from 'src/modules/cms/domain/Content'
import { ContentType } from 'src/modules/cms/domain/LearningItem'
import LearningItemsConfirmation from './LearningItemsConfirmation'
import SelectLearningItems from './SelectLearningItems'
import SuccessfulMessage from './SuccessfulMessage'

interface Props {
  items: Content[]
  contentType: ContentType | null
  handleDrawerClose: () => void
}

const Pages: React.FC<Props> = ({ items, contentType, handleDrawerClose }) => {
  const [t] = useTranslation()
  const [pageIndex, setPageIndex] = useState(0)

  const handleStep = useCallback((page: number) => {
    setPageIndex(page)
  }, [])

  const pages = [
    <SelectLearningItems contents={items} handleStep={handleStep} initialContentType={contentType!} />,
    <LearningItemsConfirmation handleStep={handleStep} />,
    <SuccessfulMessage close={handleDrawerClose} />,
  ]

  return (
    <>
      {pageIndex !== 2 && (
        <Text
          color='bluishGray.500'
          textDecorationLine='underline'
          fontFamily='Poppins'
          fontWeight='600'
          marginBottom='22px'
          cursor='pointer'
          onClick={handleDrawerClose}
        >
          {t('common.drawer.close')}
        </Text>
      )}

      {pages[pageIndex]}
    </>
  )
}

export default Pages
