import { UseCase } from "src/types/UseCase";
import { AreaRepository, RemoveUsersFromExistingAreaInput, RemoveUsersFromExistingAreaOutput } from "../repository/AreaRepository";

export class RemoveUsersFromExistingArea implements UseCase<RemoveUsersFromExistingAreaInput, RemoveUsersFromExistingAreaOutput[]>{
  constructor(private areaRepository: AreaRepository){}

  async execute(args: RemoveUsersFromExistingAreaInput): Promise<RemoveUsersFromExistingAreaOutput[]> {
      const failedUsers = await this.areaRepository.removeUsersFromExistingArea({ 
        areaId: args?.areaId,
        usersIds: args?.usersIds
      })
      return failedUsers
    }
}