import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { createChakraAnimatedComponent } from 'src/utils/createChakraAnimatedComponent'

interface SuccessProps {
  handleCloseDrawer: () => Promise<void>
  isPlaylist?: boolean
}

const ChakraImageAnimated = createChakraAnimatedComponent('img')
const ChakraTextAnimated = createChakraAnimatedComponent('p')
const ChakraButtonAnimated = createChakraAnimatedComponent('button')

export function Success({ handleCloseDrawer, isPlaylist }: SuccessProps) {
  const [t] = useTranslation()

  return (
    <Flex w='100%' h='100%' align='center' justify='center' data-testid='cms-remove-drawer-success' overflow='hidden'>
      <Flex flexDir='column' align='center' justify='center' w='100%' h='100%'>
        <ChakraImageAnimated
          src='/assets/images/ballon.png'
          animate={{
            opacity: [0, 1],
            transform: ['translateX(100px)', 'translateX(0px)'],
          }}
          // @ts-ignore
          transition={{
            ease: 'easeInOut',
          }}
        />
        <ChakraTextAnimated
          mt='48px'
          fontWeight={700}
          fontSize='1.25rem'
          animate={{
            opacity: [0, 1],
            transform: ['translateX(100px)', 'translateX(0px)'],
          }}
          // @ts-ignore
          transition={{
            delay: 0.1,
            ease: 'easeInOut',
          }}
        >
          {isPlaylist ? t('playlistDeleted') : t('contentDeleted')}
        </ChakraTextAnimated>
        <ChakraButtonAnimated
          variant='unstyled'
          bg='gray.800'
          color='white'
          fontWeight={900}
          padding='0px 16px'
          h='44px'
          fontSize='1rem'
          mt='16px'
          onClick={handleCloseDrawer}
          borderRadius='16px'
          animate={{
            opacity: [0, 1],
            transform: ['translateX(100px)', 'translateX(0px)'],
          }}
          // @ts-ignore
          transition={{
            delay: 0.2,
            ease: 'easeInOut',
          }}
        >
          {`${t('accessDashboard')} ${isPlaylist ? t('common.Playlists') : t('contents')}`}
        </ChakraButtonAnimated>
      </Flex>
    </Flex>
  )
}
