import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSelect, { OptionTypeBase } from 'react-select'
import { selectStyles } from 'src/components/CustomPlaylistQuiz/utils'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { Skill, useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { useUser } from 'src/context/userContext'
import { useWindowSize } from 'src/hooks/useWindowSize'
import { getModuleTitle } from '../CustomPlaylistModules/modules.services'

type Props = {
  handleStepChange: (step: number) => void
}

const EditSkills: React.FC<Props> = ({ handleStepChange }) => {
  const [t] = useTranslation()
  const { user } = useUser()
  const { playlist } = useCustomPlaylist()
  const {
    quizValues,
    allSkills,
    updateQuiz,
    userSkills,
    skillsToAddOnEdit,
    skillsToRemoveOnEdit,
    setSkillsToAddOnEdit,
    setSkillsToRemoveOnEdit,
    getAllCmsSkills,
  } = useCustomPlaylistQuiz()
  const { skillsSnapshot } = quizValues
  const noSkills = skillsSnapshot?.length === 0
  const { width } = useWindowSize()

  const isMobile = useMemo(() => width && width < 768, [width])

  const handleNewSkill = (item: OptionTypeBase | null) => {
    let newSkill: Skill

    allSkills.forEach((skillFE) => {
      if (skillFE.id === item?.value) newSkill = skillFE
    })

    if (!userSkills.find((skill) => skill.id === newSkill.id)) {
      setSkillsToAddOnEdit((prevState) => [...prevState, newSkill!])
    } else if (userSkills.find((skill) => skill.id === newSkill.id)) {
      setSkillsToRemoveOnEdit((prevState) => prevState.filter((item) => item.id !== newSkill!.id))
    }

    updateQuiz('skillsSnapshot', [...skillsSnapshot, newSkill!])
  }

  const handleSelectSkill = (itemToRemove: Skill) => {
    const filteredSkills = skillsSnapshot.filter((skill) => skill.id !== itemToRemove.id)
    updateQuiz('skillsSnapshot', filteredSkills)

    if (skillsToAddOnEdit.find((skill) => skill.id === itemToRemove.id)) {
      setSkillsToAddOnEdit((prevState) => prevState.filter((item) => item.id !== itemToRemove.id))
    } else if (userSkills.find((skill) => skill.id === itemToRemove.id)) {
      setSkillsToRemoveOnEdit((prevState) => [...prevState, itemToRemove])
    }
  }

  const handleContinueButton = () => {
    if (noSkills) {
      handleStepChange(3)

      return
    }

    if (skillsToRemoveOnEdit.length > 0 && skillsToAddOnEdit.length === 0) {
      handleStepChange(2)
    } else {
      handleStepChange(1)
    }
  }

  const checkDisabledButton = () => {
    const mergedSkills = [...skillsToAddOnEdit, ...skillsToRemoveOnEdit]

    if (mergedSkills.length === 0) return true

    return false
  }

  useEffect(() => {
    getAllCmsSkills({ variables: { limit: 500, skip: 0 } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Heading size='xs' fontWeight='600' marginBottom='8px'>
        {t('home.Custom Playlist.Edit skills to your preference')}
      </Heading>
      <Text fontWeight='600' fontSize='18px' color='bluishGray.500'>
        {t('home.Custom Playlist.Add up to 3 new skills to your track')}
        {/* {noSkills
          ? t('home.Custom Playlist.When removing all skills your track will be deleted')
          : t('home.Custom Playlist.Add up to 3 new skills to your track')} */}
      </Text>
      <Flex align='flex-start' direction='column' gap='12px' mt='24px'>
        {playlist?.moduleList?.items?.map((item) => {
          return (
            <Flex
              alignItems='center'
              justifyContent='space-between'
              key={item.id}
              w='100%'
              h='48px'
              p='8px 24px'
              bg={'primaryColors.30'}
              borderRadius='16px'
              border='2px solid transparent'
              _hover={{ border: '2px solid #009639' }}
            >
              <Text fontWeight={700} fontSize={{ base: '0.875rem', md: '1.125rem' }}>
                {getModuleTitle([item], user?.language)}
              </Text>
            </Flex>
          )
        })}
        {skillsSnapshot.map((item) => {
          return (
            <Flex
              alignItems='center'
              justifyContent='space-between'
              key={item.id}
              w='100%'
              h='48px'
              p='8px 24px'
              bg={'primaryColors.30'}
              borderRadius='16px'
              border='2px solid transparent'
              _hover={{ border: '2px solid #009639' }}
            >
              <Text fontWeight={700} fontSize={{ base: '0.875rem', md: '1.125rem' }}>
                {item.name}
              </Text>
              <Text
                cursor='pointer'
                fontWeight={600}
                fontSize='12px'
                fontFamily='Poppins'
                onClick={() => handleSelectSkill(item)}
                _active={{ color: 'primaryColors.650' }}
                p='4px 8px'
              >
                {isMobile ? 'x' : <>{t('home.Custom Playlist.Remove skill')}</>}
              </Text>
            </Flex>
          )
        })}
        <Box w='100%' data-testid='edit-skills-select-new-skills'>
          <ReactSelect
            isClearable={false}
            styles={selectStyles()}
            value={null}
            placeholder={
              skillsToAddOnEdit.length === 3
                ? t('home.Custom Playlist.You can add up to 3 new skills at a time')
                : t('home.Custom Playlist.Add a new skill')
            }
            components={{ IndicatorSeparator: () => null }}
            isDisabled={skillsToAddOnEdit.length === 3}
            onChange={handleNewSkill}
            options={allSkills
              .filter((item) => !playlist?.moduleList?.items?.some((module) => getModuleTitle([module], user?.language) === item.name))
              .filter((item) => !skillsToAddOnEdit.find((skill) => String(item.id) === String(skill.id)))
              .filter((item) => !skillsSnapshot.find((skill) => String(item.id) === String(skill.id)))
              .map((skill: Skill) => ({ value: skill.id, label: skill.name }))}
          />
        </Box>
      </Flex>
      <Flex justify='flex-end' mt='32px'>
        <Button
          variant='startCourseDark'
          height='48px'
          padding='12px 24px'
          onClick={handleContinueButton}
          isDisabled={checkDisabledButton()}
          data-testid='edit-skills-continue-button'
        >
          {t('quiz.footer.continue')}
        </Button>
      </Flex>
    </>
  )
}

export default EditSkills
