import { Box } from '@chakra-ui/react'
import React from 'react'

interface OptionProps {
  name: string
  activationHandler: () => void
  index: number
  boldReduce?: boolean
}

export default function Option({ name, boldReduce = false, activationHandler, index }: OptionProps) {
  return (
    <Box
      paddingY='10px'
      paddingLeft='8px'
      userSelect={'none'}
      cursor='pointer'
      scrollSnapAlign={'start'}
      borderTop={index === 0 ? 'none' : '1px solid'}
      borderColor='gray.180'
      w='100%'
      onClick={activationHandler}
      _hover={{
        bg: 'primaryColors.45',
      }}
    >
      {boldReduce ? (
        <>
          <b>{name.split(' - ')[0]}</b> - {name.split(' - ')[1]}{' '}
        </>
      ) : (
        name
      )}
    </Box>
  )
}
