import { FormControl, Input, InputProps, Text } from '@chakra-ui/react'

interface InputWithLabelProps extends InputProps {
  label?: string
}

export function InputWithLabel({ label, ...rest }: InputWithLabelProps) {
  return (
    <FormControl display='flex' flexDir='column' gap='4px'>
      {!!label && (
        <Text fontWeight='700' fontSize='1rem'>
          {label}
        </Text>
      )}
      <Input fontSize='1rem' color='#767F89' borderRadius='8px' px='16px' py='4px' height='auto' {...rest} />
    </FormControl>
  )
}
