import { Select } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useImportedContents } from 'src/pages-admin/Cms/hooks/useImportedContents'

export function ImportDateFilter() {
  const { filters, applyFilters } = useImportedContents()

  useEffect(() => {
    return () => {
      applyFilters('order', undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Select
      value={filters.order}
      bgColor='#F7F9FA'
      border='none'
      _hover={{ bgColor: '#F7F9FA' }}
      _focus={{ bgColor: '#F7F9FA' }}
      fontWeight={600}
      fontSize='.875rem'
      paddingRight='12px'
      w='auto'
      height='22px'
      className={`select-default`}
      onChange={(e) => applyFilters('order', e.target.value)}
      data-testid='cms-type-filter-select'
    >
      <option value=''>Data de importação</option>
      <option value='LATEST_UPDATE'>Importação mais recente</option>
      <option value='OLDER_UPDATE'>Importação mais antiga</option>
    </Select>
  )
}
