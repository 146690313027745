import React, { useEffect } from 'react'
import Drawer from 'src/components/Drawer'
import { ContentDrawerInfo } from 'src/context/CustomPlaylist/KnowMoreDrawerContext'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import AdditionalInformation from './components/AdditionalInformation'
import Buttons from './components/Buttons'
import Close from './components/Close'
import Description from './components/Description'

interface Props {
  drawerInfo: ContentDrawerInfo
  closeText: string
  onCloseClick: () => void
}

const ContentDrawer: React.FC<Props> = ({ drawerInfo, closeText, onCloseClick }: Props) => {
  const { EventsImplementation, track } = useMixpanel()

  const isOpen = drawerInfo
  const content = drawerInfo.content
  useEffect(() => {
    if (content) track(EventsImplementation.OPEN_LEARNING_ITEM_DRAWER(content!.title))
  }, [content, track, EventsImplementation])

  if (!isOpen || !content) return null

  return (
    <Drawer onOutsideClick={onCloseClick}>
      <Close onClick={onCloseClick} closeText={closeText} />
      <Description content={content} />
      <Buttons contentDrawerInfo={drawerInfo} />
      <AdditionalInformation content={content} module={drawerInfo.moduleTitle} />
    </Drawer>
  )
}

export default ContentDrawer
