import { useMutation } from '@apollo/client'
import { Flex, Image, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeedback } from 'src/context/FeedbackContext'
import { useUser } from 'src/context/userContext'
import { REGISTER_ACTIVITY_LOG } from 'src/graphql/mutations/REGISTER_ACTIVITY_LOG'
import { useConfetti } from 'src/hooks/useConfetti'

const FeedbackSent = () => {
  const [t] = useTranslation()
  const { updateFeedbackFlag } = useUser()
  const { showConfetti } = useConfetti()
  const { close } = useFeedback()
  const [registerActivity] = useMutation(REGISTER_ACTIVITY_LOG)

  useEffect(() => {
    updateFeedbackFlag(false)
    registerActivity({
      variables: {
        activity: 'answered_feedback',
      },
    })
    showConfetti()

    setTimeout(() => {
      close()
    }, 6000)
  }, [showConfetti, close, registerActivity, updateFeedbackFlag])

  return (
    <Flex flex={1} flexDir='column' alignItems='center' justifyContent='center'>
      <Flex flexDir='column' alignItems='center'>
        <Image src='/assets/images/floating-balloon.svg' w={243} h={191} />
        <Text fontFamily='Poppins' fontWeight='semibold' fontSize={20} mt='15px'>
          {t('evaluate.sentSuccessfully')}
        </Text>
        <Text fontWeight='medium' fontSize={18} mt='8px'>
          {t('evaluate.thanksForTheContribution')}
        </Text>
      </Flex>
    </Flex>
  )
}

export default FeedbackSent
