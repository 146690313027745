export const durationConverter = (durationInSeconds: number) => {
  const totalDurationInMinutes = durationInSeconds / 60
  if (totalDurationInMinutes < 60) return `${Math.ceil(totalDurationInMinutes).toFixed(0)}m`
  
  const totalDurationInHours = totalDurationInMinutes / 60;

  const playlistPeriod = Math.ceil(totalDurationInHours) // 470/60 = Math.floor(7.83...) = 7
  const wholeMinutes = playlistPeriod * 60 // 420
  const restMinutes = totalDurationInMinutes - wholeMinutes // 50

  if (restMinutes === 0) return `${Math.ceil(playlistPeriod).toFixed(0)}h`

  return `${Math.ceil(playlistPeriod).toFixed(0)}h${Math.ceil((totalDurationInHours - playlistPeriod) * 60).toFixed(0)}m`
}
