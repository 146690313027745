import { Flex, FlexProps, Img, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { User } from 'src/types/User'

interface UserListProps extends FlexProps {
  withSelectButton?: boolean
  selectButton?: JSX.Element
  data?: User
}

export function UserListCard({ withSelectButton = false, selectButton, data, ...rest }: UserListProps) {
  const [t] = useTranslation()

  return (
    <Flex
      align='center'
      px='16px'
      py='8px'
      bg='#F7F9FA'
      borderRadius='12px'
      boxShadow='0px 1px 6px 0px rgba(0, 0, 0, 0.10)'
      {...rest}
    >
      <Img src='https://fakeimg.pl/150x150' alt='Colaborator image' w='24px' borderRadius='999px' />
      <Text fontWeight='700' color={rest.color ? rest.color : 'green.500'} fontSize='0.75rem' m='0' p='0' ml='8px'>
        {data?.name ?? t('invalidName')}
      </Text>
      {!withSelectButton && (
        <Text ml='auto' fontWeight='400' color='gray.800' fontSize='0.75rem' p='0'>
          {data?.email ?? 'E-mail inválido'}
        </Text>
      )}
      {withSelectButton && selectButton && <>{selectButton}</>}
    </Flex>
  )
}
