import { useDisclosure } from '@chakra-ui/react'
import _ from 'lodash'
import { createContext, ReactNode, useContext, useState } from 'react'
import { useForm as rhfUseForm } from 'react-hook-form'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { usePartners } from 'src/modules/cms/hooks/usePartners'
import { SaveImportedContentByActionType } from 'src/modules/cms/types/IContentRepository'
import { useImportedContents } from 'src/pages-admin/Cms/hooks/useImportedContents'

interface ImportedContentsUseFormContextProps {
  hookForm: ReturnType<typeof rhfUseForm>
  register: ReturnType<typeof rhfUseForm>['register']
  watch: ReturnType<typeof rhfUseForm>['watch']
  errors: ReturnType<typeof rhfUseForm>['formState']['errors']
  handleSubmit: ReturnType<typeof rhfUseForm>['handleSubmit']
  onSubmit: (data: Inputs, event: any, action: SaveImportedContentByActionType) => Promise<void>
  finalMessage: string
  setFinalMessage: React.Dispatch<React.SetStateAction<string>>
  isSuccessModalOpen: boolean
  onOpenSuccessModal: () => void
  onCloseSuccessModal: () => void
}

interface Inputs {
  url?: string
  type?: string
  duration?: string
  title?: string
  description?: string
  skills?: string[]
  languages?: string[]
  subtitles?: string[]
  imageUrl?: string
  trailerUrl?: string
  partnerId?: string
}

const ImportedContentsUseFormContext = createContext({} as ImportedContentsUseFormContextProps)

const formatDurationInHoursAndMinutosIntoSeconds = (duration: string) => {
  // HH:MM (e.g 145:55)
  const [hours, minutes] = duration.split(':')
  const hoursInSeconds = parseInt(hours) * 3600
  const minutesInSeconds = parseInt(minutes) * 60
  const totalDurationInSeconds = hoursInSeconds + minutesInSeconds
  return totalDurationInSeconds
}

export function ImportedContentsUseFormContextProvider({ children }: { children: ReactNode }) {
  const [finalMessage, setFinalMessage] = useState('')

  const { selectedImportedContent, executeActionOnImportedContents } = useImportedContents()
  const { allSkills } = useCustomPlaylistQuiz()
  const { partnersList } = usePartners()

  const { isOpen: isSuccessModalOpen, onOpen: onOpenSuccessModal, onClose: onCloseSuccessModal } = useDisclosure()

  const hookForm = rhfUseForm<Inputs>({
    mode: 'onChange',
  })
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = hookForm

  const onSubmit = async (data: Inputs, event: any, action: SaveImportedContentByActionType) => {
    event?.preventDefault()
    if (!selectedImportedContent) return

    const copiedSelectedContent = _.cloneDeep(selectedImportedContent)
    copiedSelectedContent.url = data.url ?? ''
    if (data.type) copiedSelectedContent.type = data.type
    if (data.duration) copiedSelectedContent.duration = formatDurationInHoursAndMinutosIntoSeconds(data.duration)
    if (data.title) copiedSelectedContent.title = data.title
    copiedSelectedContent.description = data.description
    if (data.skills) copiedSelectedContent.skills = allSkills.filter((skill) => data.skills?.includes(skill.id))
    if (data.languages) copiedSelectedContent.languages = data.languages
    if (data.subtitles) copiedSelectedContent.subtitles = data.subtitles
    if (data.imageUrl) copiedSelectedContent.imageUrl = data.imageUrl
    if (data.trailerUrl) copiedSelectedContent.trailerUrl = data.trailerUrl
    if (data.partnerId) {
      const partner = partnersList.find((partner) => partner.id === data.partnerId)
      if (partner) copiedSelectedContent.partner = partner
    }

    try {
      await executeActionOnImportedContents(copiedSelectedContent, action)
      setFinalMessage('O conteúdo foi editado com sucesso!')
      onOpenSuccessModal()
    } catch {
      console.error('Erro ao salvar o conteúdo')
    }
  }

  return (
    <ImportedContentsUseFormContext.Provider
      value={{
        hookForm,
        register,
        watch,
        errors,
        handleSubmit,
        onSubmit,
        finalMessage,
        setFinalMessage,
        isSuccessModalOpen,
        onOpenSuccessModal,
        onCloseSuccessModal,
      }}
    >
      {children}
    </ImportedContentsUseFormContext.Provider>
  )
}

export function useImportedContentsForm() {
  const context = useContext(ImportedContentsUseFormContext)
  return context
}
