import { Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useUser } from 'src/context/userContext'
import Content from 'src/modules/cms/domain/Content'
import { useImportedContents } from 'src/pages-admin/Cms/hooks/useImportedContents'
import { formatContentStatus } from 'src/pages-admin/Cms/utils/formatContentStatus'
import { formatDate } from 'src/pages-admin/Cms/utils/formatDate'
import { parseCourseType } from 'src/pages-admin/Cms/utils/parseCourseType'
import { DeleteContent, EditContent } from 'src/theme/Icons'
import { CmsContentType } from 'src/types/Cms'
import { Role } from 'src/types/Role'

interface ImportedContentsTableProps {
  contents: Content[]
}

export function ImportedContentsTable({ contents }: ImportedContentsTableProps) {
  const [t] = useTranslation()
  const { user } = useUser()
  const { handleEditContent, handleRejectContent } = useImportedContents()

  const isUserBackoffice = user?.roles?.includes(Role.BACKOFFICE)

  return (
    <TableContainer
      d='flex'
      position='relative'
      w='100%'
      borderRadius='16px'
      boxShadow='0px 1px 10px rgba(0, 0, 0, 0.1)'
      data-testid='cms-table-wrapper'
    >
      <Table variant='unstyled' size='lg'>
        <Thead bg='#F7F9FA'>
          <Tr padding='16px 34px' w='100%'>
            <Th w='30%' fontWeight={700} textTransform='initial' fontSize='1.125rem'>
              {t('admin.corporateUniversity.contents.tableContentTitle')}
            </Th>
            <Th w='150px' fontWeight={700} textTransform='initial' fontSize='1.125rem'>
              {t('admin.corporateUniversity.contents.tableTypeTitle')}
            </Th>
            <Th w='150px' fontWeight={700} textTransform='initial' fontSize='1.125rem'>
              {t('admin.corporateUniversity.playlists.tableStatusTitle')}
            </Th>
            <Th w='150px' fontWeight={700} textTransform='initial' fontSize='1.125rem'>
              {t('partner')}
            </Th>
            <Th w='150px' fontWeight={700} textTransform='initial' fontSize='1.125rem'>
              {t('importedAt')}
            </Th>
            <Th w='150px' fontWeight={700} textTransform='initial' fontSize='1.125rem'>
              {t('admin.corporateUniversity.contents.tableUpdatedAtTitle')}
            </Th>
            <Th w='80px' />
          </Tr>
        </Thead>

        <Tbody>
          {contents.length <= 0 && (
            <Flex padding='20px'>
              <Text color='bluishGray.500'>{t('noContentFound')}</Text>
            </Flex>
          )}

          {contents?.map((content, index) => (
            <Tr
              key={content.id}
              w='100%'
              borderBottom='1px solid #EFEFEF'
              _hover={{ backgroundColor: '#EFEFEF' }}
              transition='all 300ms ease'
              color='bluishGray.500'
            >
              <Td maxW='300px' overflowX='hidden' textOverflow='ellipsis'>
                {content.title}
              </Td>
              <Td textTransform='capitalize'>{parseCourseType(content.type as CmsContentType)}</Td>
              <Td>{formatContentStatus(content.status)}</Td>
              <Td>{content.partner?.name ?? ''}</Td>
              <Td>{formatDate(content.createdAt)}</Td>
              <Td>{formatDate(content.updatedAt)}</Td>
              <Td>
                <Flex align='center' gap='24px' position='relative'>
                  <Tooltip
                    hasArrow
                    shouldWrapChildren
                    bg='gray.800'
                    color='gray.50'
                    p='8px'
                    placement='top'
                    borderRadius='4px'
                    boxShadow='0px 4px 6px rgba(0, 0, 0, 0.15);'
                    label={t('admin.corporateUniversity.tableEditButton')}
                  >
                    <EditContent
                      width='24px'
                      height='24px'
                      cursor='pointer'
                      onClick={() => handleEditContent(content)}
                      data-testid={`cms-edit-content-button-${index}`}
                      display={
                        isUserBackoffice ||
                        (content.allowedCompanyIds?.length === 1 && content.allowedCompanyIds[0] !== -1)
                          ? 'block'
                          : 'none'
                      }
                    />
                  </Tooltip>
                  <Tooltip
                    hasArrow
                    shouldWrapChildren
                    bg='gray.800'
                    color='gray.50'
                    p='8px'
                    placement='top'
                    borderRadius='4px'
                    boxShadow='0px 4px 6px rgba(0, 0, 0, 0.15);'
                    label={'Rejeitar'}
                  >
                    <DeleteContent
                      width='24px'
                      height='24px'
                      // onClick={() => handleRemoveContent(content)}
                      onClick={() => handleRejectContent(content)}
                      cursor='pointer'
                      data-testid={`cms-remove-content-button-${index}`}
                      display={
                        isUserBackoffice ||
                        (content.allowedCompanyIds?.length === 1 && content.allowedCompanyIds[0] !== -1)
                          ? 'block'
                          : 'none'
                      }
                    />
                  </Tooltip>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
