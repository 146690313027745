import { VStack } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { debounce } from 'src/helpers-admin/debounce'
import { orderUsers } from '../../utils'
import RemoveAccessButton from './components/RemoveAccessButton'
import Filters from '../Filters'
import EmployeesTable from './components/EmployeesTable'
import Title from './components/Title'
import EmptyState from './components/EmptyState'
import { Users } from '../../home.types'
import { useHomeAccess } from 'src/context-admin/Home/AdminHomeAccessContext'

interface Props {
  listUsers: Users | undefined 
}

const EmployeeSection: React.FC<Props> = ({ listUsers }: Props) => {
  const { order, area, team, setArea, setSearch, setTeam, setOrder } = useHomeAccess()

  const debouncedSearchChange = debounce(setSearch, 1000)

  const isListUsersEmpty = !listUsers?.paginateInfo.total

  return (
    <VStack justifyContent='flex-start' alignItems='flex-start' spacing={6}>
      <Title />

      <Filters
        area={area}
        team={team}
        order={order}
        handleAreaChange={setArea}
        handleSearchChange={debouncedSearchChange}
        handleTeamChange={setTeam}
        handleOrderChange={setOrder}
      />

      <RemoveAccessButton />

      {!isListUsersEmpty && <EmployeesTable employees={listUsers.data} />}
      {isListUsersEmpty && <EmptyState />}
    </VStack>
  )
}

export default EmployeeSection
