import { Box, Button, Flex, Icon, Image, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoArrowBack } from 'react-icons/io5'
// @ts-ignore
import ReactPlayer from 'react-player/youtube'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import FavoriteIcon from 'src/components/FavoriteIcon'
import LearningItemInfoCard from 'src/components/LearningItemModal/LearningItemInfoCard'
import ShareButton from 'src/components/LearningItemModal/ShareButton'
import { useEvaluate } from 'src/context/EvaluateContext'
import { useUser } from 'src/context/userContext'
import { getContentData } from 'src/helpers/contentInfo'
import { EventsImplementation } from 'src/hooks/Mixpanel/types'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { useConfetti } from 'src/hooks/useConfetti'
import Content from 'src/modules/cms/domain/Content'
import { makeMarkContent } from 'src/modules/cms/factories/makeMarkContent'
import { useContents } from 'src/modules/cms/hooks/useContents'
import { ContentAction } from 'src/modules/cms/use_cases/MarkContentUseCase'
import { renderCorrectContentInfoByLanguage } from 'src/modules/internationalization/helpers/renderCorrectContentInfoByLanguage'
import LearningItemSkeleton from 'src/pages/LearningItem/LearningItemSkeleton'
import { getFlagIcon } from 'src/pages/LearningItem/utils'
import { Icons8CircleCloseIcon } from 'src/theme/Icons'
import { LearningItemProgress } from 'src/types/LearningItemProgress'
import './styles.scss'

type Params = {
  learningItemId: string
}

const LearningItem: React.FC = () => {
  const { learningItemId } = useParams<Params>()
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const { user, getUser } = useUser()
  const { showConfetti } = useConfetti()
  const { openEvaluation, updateCourseInfo } = useEvaluate()
  const { track } = useMixpanel()
  const markContent = makeMarkContent()
  const { getContentById } = useContents()
  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState<Content>({} as Content)
  const isContentDone = content.progress === LearningItemProgress.DONE
  const contentData = getContentData(content.type, t)

  useEffect(() => {
    if (user) {
      setLoading(true)
      getContentById(learningItemId, user)
        .then((response) => {
          setContent(response ?? ({} as Content))
          setLoading(false)
        })
        .finally(() => setLoading(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const handleMarkAsDone = async () => {
    await markContent.execute({ id: content.id, action: ContentAction.DONE })
    getUser()
    const contentItemCopy = { ...content }
    contentItemCopy.progress = LearningItemProgress.DONE
    showConfetti()
    setContent(contentItemCopy)
    track(EventsImplementation.MARK_AS_DONE(content.title, location.pathname, 'Learning Item Page'))
    updateCourseInfo(content)
    openEvaluation({ step: 1, source: 'playlistLearningItemFinished' })
  }

  const onMarkAsUndone = async () => {
    try {
      const contentItemCopy = { ...content }
      contentItemCopy.progress = LearningItemProgress.DOING
      await markContent.execute({ id: content.id, action: ContentAction.UNDONE })
      getUser()
      setContent(contentItemCopy)
      track(EventsImplementation.MARK_AS_UNDONE(content.title, location.pathname, 'Learning Item Page'))
    } catch (error) {
      const contentItemCopy = { ...content }
      contentItemCopy.progress = LearningItemProgress.DONE
    }
  }

  const handleGoBack = () => {
    if (history.goBack() === undefined) {
      history.push('/home')
    } else {
      history.goBack()
    }
  }

  if (loading) {
    return <LearningItemSkeleton />
  }

  return (
    <Flex
      paddingTop={{ base: '32px', lg: '40px' }}
      paddingBottom={{ base: '0px', lg: '48px' }}
      height='100%'
      direction={{ base: 'column', lg: 'row' }}
    >
      <Flex
        w='100%'
        justifyContent='center'
        paddingRight={{ base: '16px', lg: '64px' }}
        paddingLeft={{ base: '16px', lg: '30px' }}
        borderRight='2px solid #EFEFEF'
      >
        <Box w='100%'>
          <Flex mb='16px' gap='8px' alignItems='center'>
            <Icon w='24px' h='25px' marginRight='8px' as={IoArrowBack} cursor='pointer' onClick={handleGoBack} />

            <Text fontFamily='Poppins' fontSize='20px' fontWeight={600}>
              {renderCorrectContentInfoByLanguage(content, 'title')}
            </Text>
          </Flex>
          <Box className='learning-item-player' w='100%' h='0' float='none' position='relative' pb='56.25%' pt='25px'>
            <ReactPlayer
              style={{ borderRadius: '16px', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              url={content.url}
              controls={true}
              playing={true}
            />
          </Box>
        </Box>
      </Flex>

      <Flex
        flexDir='column'
        maxWidth={{ base: 'unset', lg: '520px' }}
        width='100%'
        paddingX={{ base: '16px', lg: '60px' }}
        paddingBottom={{ base: '24px', lg: '0px' }}
      >
        <Flex justifyContent='space-between' mb='16px'>
          <Text textTransform='capitalize' fontFamily='Poppins' fontSize='20px' fontWeight={600} color='bluishGray.500'>
            {contentData?.type}
          </Text>
          <Flex alignItems='center' gap='8px'>
            {content.languages?.map((language) => (
              <>
                <Flex gap='8px' align='center'>
                  <Image key={content.id} src={getFlagIcon(language)} w='16px' h='16px' />
                </Flex>
                <Flex gap='8px' align='center'>
                  <Text textTransform='uppercase'>{language}</Text>
                </Flex>
              </>
            ))}
          </Flex>
        </Flex>

        <Text mb='8px' fontFamily='Poppins' fontSize='32px' fontWeight={700}>
          {renderCorrectContentInfoByLanguage(content, 'title')}
        </Text>

        <Text mb='24px' lineHeight='28px' color='bluishGray.500'>
          {renderCorrectContentInfoByLanguage(content, 'description')}
        </Text>

        <LearningItemInfoCard content={content} />

        <Flex
          gap='8px'
          flexWrap='wrap'
          mt='24px'
          direction='row'
          justifyContent={{ base: 'flex-start', lg: 'flex-end' }}
          w='100%'
        >
          <Box>
            <ShareButton content={content} />
          </Box>

          <Box>
            <FavoriteIcon content={content} onFavorite={setContent} height='44px' />
          </Box>

          <Button
            variant='startCourse'
            border='2px solid #E5E5E5'
            h='44px'
            maxW={{ base: '285px' }}
            padding={{ base: '0px 8px', lg: '0px 16px' }}
            fontSize={{ base: '.75rem', lg: '1rem' }}
            onClick={() => (isContentDone ? onMarkAsUndone() : handleMarkAsDone())}
          >
            <Icon
              w={{ base: '24px', lg: '30px' }}
              h={{ base: '24px', lg: '30px' }}
              marginRight='8px'
              as={isContentDone ? Icons8CircleCloseIcon : contentData?.icon}
            />
            {isContentDone ? contentData?.markAsNotComplete : contentData?.markAsComplete}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default LearningItem
