import { Flex, Select } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useCorporateUniversityStore } from 'src/pages-admin/Cms/hooks/useCorporateUniversityStore'

export function AvailabilityFilter() {
  const [t] = useTranslation()
  const { partnerList, filters, applyFilters } = useCorporateUniversityStore()

  useEffect(() => {
    return () => {
      applyFilters('partnerId', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex data-testid='cms-company-filter'>
      <Select
        value={filters.partnerId}
        bgColor='#F7F9FA'
        border='none'
        _hover={{ bgColor: '#F7F9FA' }}
        _focus={{ bgColor: '#F7F9FA' }}
        fontWeight={600}
        fontSize='.875rem'
        minW='127px'
        height='22px'
        className={`select-default`}
        onChange={(e) => applyFilters('partnerId', e.target.value)}
      >
        <option value=''>{t('availableTo')}</option>
        {partnerList?.length <= 0 && (
          <option value='loading' disabled>
            {t('loading')}
          </option>
        )}
        {partnerList?.map((partner) => (
          <option key={partner.id} value={partner.id}>
            {partner?.name}
          </option>
        ))}
      </Select>
    </Flex>
  )
}
