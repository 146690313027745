import { PlaylistRepository } from 'src/modules/cms/repositories/PlaylistRepository'

interface DeletePlaylistUseCaseInput {
  playlistId: string
}

export type DeletePlaylistUseCaseOutput = boolean

export class DeletePlaylistUseCase {
  constructor(private playlistRepository: PlaylistRepository) {}

  async execute({ playlistId }: DeletePlaylistUseCaseInput): Promise<DeletePlaylistUseCaseOutput> {
    const hasDeleted = await this.playlistRepository.delete(playlistId)
    return hasDeleted
  }
}
