import { Icon } from '@chakra-ui/icons'
import { Box, Button, Flex, IconProps, Link as LinkChakra, Tooltip } from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { Link, useLocation } from 'react-router-dom'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'

export interface MenuLinkProps {
  href?: string
  icon: IconType | ((props: IconProps) => JSX.Element)
  children?: React.ReactNode
  onClick?: () => void
  active?: boolean
  isExternal?: boolean
  isMenuOpen: boolean
  label?: string
}

const MenuLink: React.FC<MenuLinkProps> = ({
  href,
  icon,
  children,
  onClick,
  active,
  isExternal,
  isMenuOpen,
  label,
}: MenuLinkProps) => {
  const location = useLocation()
  active = active || href === location.pathname
  const { EventsImplementation, track } = useMixpanel()

  const iconButton = (
    <Button variant='menuLink' background={active ? `bluishGray.100` : 'transparent'}>
      <Icon as={icon} w='20px' h='20px' color={active ? 'primaryColors.400' : 'gray.260'} />
    </Button>
  )

  const component = (
    <Flex
      alignItems='center'
      userSelect='none'
      position='relative'
      onClick={() => (onClick ? onClick() : undefined)}
      cursor='pointer'
    >
      <Box
        visibility={active ? 'visible' : 'hidden'}
        height='45px'
        marginRight='30px'
        width='7px'
        borderRadius='0px 5px 5px 0px'
        bg='primaryColors.400'
        position='absolute'
        left='0'
      ></Box>
      <Flex
        background={active && isMenuOpen ? `bluishGray.100` : 'transparent'}
        paddingRight='16px'
        onClick={() => {
          track(EventsImplementation.SIDEBAR_REDIRECT(location.pathname, href!))
        }}
        marginLeft='17px'
        borderRadius='16px'
        alignItems='center'
        width='100%'
        transition='background 0.8s'
        _hover={
          isMenuOpen
            ? {
                background: '#E1E3E4 radial-gradient(circle, transparent 1%, #E1E3E4 1%) center/15000%',
              }
            : {}
        }
      >
        {isMenuOpen ? (
          iconButton
        ) : (
          <Tooltip
            label={label}
            placement='right'
            backgroundColor='primaryColors.400'
            padding='8px 18px'
            color='white'
            fontWeight='700'
            fontSize='14px'
            borderRadius='20px'
          >
            {iconButton}
          </Tooltip>
        )}
        {children}
      </Flex>
    </Flex>
  )

  if (isExternal)
    return (
      <LinkChakra
        href={href || ''}
        isExternal={isExternal || false}
        textDecoration='none'
        _hover={{ textDecoration: 'none' }}
        _focus={{
          outline: 'none',
          boxShadow: 'unset',
        }}
      >
        {component}
      </LinkChakra>
    )

  return <Link to={href ?? location.pathname ?? '/home'}>{component}</Link>
}

export default MenuLink
