import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Tooltip,
} from '@chakra-ui/react'
import { formatToCNPJ, isCNPJ } from 'brazilian-values'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { IoCloudUpload, IoHelpCircle } from 'react-icons/io5'
import { renderRequiredLabel } from 'src/pages-admin/Cms/utils/renderRequiredLabel'
import * as Yup from 'yup'
import { useCompany } from '../../hooks/useCompany'
import { TabTitle } from '../shared/TabTitle'

export const RegisterCompanyFormSchema = Yup.object().shape({
  name: Yup.string().required('Obrigatório'),
  tradeName: Yup.string().required('Obrigatório'),
  cnpj: Yup.string()
    .required('Obrigatório')
    .test('isValidCNPJ', 'CNPJ está inválido', (value: string | undefined) => {
      return isCNPJ(value!)
    }),
  adminEmail: Yup.string().required('Obrigatório'),
  adminName: Yup.string().required('Obrigatório'),
})

const initialValues = {
  name: '',
  tradeName: '',
  cnpj: '',
  adminEmail: '',
  adminName: '',
  logo: '' as File | string,
}

export const Company = () => {
  const { registerCompany } = useCompany()
  const [isLoading, setIsLoading] = useState(false)
  const form = useFormik({
    initialValues,
    validationSchema: RegisterCompanyFormSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setIsLoading(true)
      await registerCompany(values)
      setIsLoading(false)
    },
  })
  const isInvalid = Object.keys(form.errors).length !== 0

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    form.setFieldValue('logo', file)
  }

  const handleChangeCNPJ = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cnpj = formatToCNPJ(e.target.value)
    form.setFieldValue('cnpj', cnpj)
  }

  useEffect(() => {
    const validate = async () => {
      await form.validateForm(initialValues)
    }

    validate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex flexDir='column' w='100%'>
      <TabTitle label='Cadastrar nova empresa' />
      <form onSubmit={form.handleSubmit}>
        <Flex flexDir='column' gap='16px'>
          <FormControl id='name' isInvalid={!!form.errors.name && form.touched.name}>
            <FormLabel
              htmlFor='name'
              fontFamily='Mulish'
              fontWeight='700'
              color='gray.800'
              fontSize='16px'
              marginBottom='2px'
            >
              {renderRequiredLabel('Razão Social')}
            </FormLabel>
            <Input
              type='text'
              name='name'
              borderRadius='16px'
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.name}
            />
          </FormControl>

          <FormControl id='tradeName' isInvalid={!!form.errors.tradeName && form.touched.tradeName}>
            <FormLabel
              htmlFor='tradeName'
              fontFamily='Mulish'
              fontWeight='700'
              color='gray.800'
              fontSize='16px'
              marginBottom='2px'
            >
              {renderRequiredLabel('Nome Fantasia')}
            </FormLabel>
            <Input
              type='text'
              name='tradeName'
              borderRadius='16px'
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.tradeName}
            />
          </FormControl>

          <FormControl id='cnpj' isInvalid={!!form.errors.cnpj && form.touched.cnpj}>
            <FormLabel
              htmlFor='cnpj'
              fontFamily='Mulish'
              fontWeight='700'
              color='gray.800'
              fontSize='16px'
              marginBottom='2px'
            >
              {renderRequiredLabel('CNPJ')}
            </FormLabel>
            <Input
              type='text'
              name='cnpj'
              borderRadius='16px'
              maxLength={18}
              onChange={handleChangeCNPJ}
              onBlur={form.handleBlur}
              value={form.values.cnpj}
            />
          </FormControl>

          <Flex gap='16px'>
            <FormControl id='adminName' isInvalid={!!form.errors.adminName && form.touched.adminName}>
              <FormLabel
                htmlFor='adminName'
                fontFamily='Mulish'
                fontWeight='700'
                color='gray.800'
                fontSize='16px'
                marginBottom='2px'
              >
                {renderRequiredLabel('Nome do Admin')}
              </FormLabel>
              <Input
                type='text'
                name='adminName'
                borderRadius='16px'
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.adminName}
              />
            </FormControl>

            <FormControl id='adminEmail' isInvalid={!!form.errors.adminEmail && form.touched.adminEmail}>
              <Flex align='center'>
                <FormLabel
                  htmlFor='adminEmail'
                  fontFamily='Mulish'
                  fontWeight='700'
                  color='gray.800'
                  fontSize='16px'
                  marginBottom='2px'
                >
                  {renderRequiredLabel('E-mail do Admin')}
                </FormLabel>
                <Tooltip hasArrow placement='right' fontSize='12px' label='O e-mail não deve existir hoje na base.'>
                  <Box mb='2px'>
                    <IoHelpCircle size={20} color='#767F89' />
                  </Box>
                </Tooltip>
              </Flex>
              <Input
                type='text'
                name='adminEmail'
                borderRadius='16px'
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.adminEmail}
              />
            </FormControl>
          </Flex>
          <FormControl id='logo' isInvalid={!!form.errors.logo && form.touched.logo}>
            <FormLabel
              htmlFor='logo'
              fontFamily='Mulish'
              fontWeight='700'
              color='gray.800'
              fontSize='16px'
              marginBottom='2px'
            >
              Logo da empresa
            </FormLabel>
            <Flex align='center'>
              <Input
                type='file'
                id='logo'
                name='logo'
                accept='.png,.svg,.jpg,.webp'
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
              <InputGroup>
                <Input
                  type='text'
                  value={typeof form.values.logo === 'string' ? form.values.logo : form.values.logo?.name}
                  title={typeof form.values.logo === 'string' ? form.values.logo : form.values.logo?.name}
                  readOnly
                  onBlur={form.handleBlur}
                />
                <InputRightElement cursor='pointer' onClick={() => document.getElementById('logo')?.click()}>
                  <IoCloudUpload color='#767F89' />
                </InputRightElement>
              </InputGroup>
            </Flex>
          </FormControl>

          <Flex mt='1rem'>
            <Tooltip
              hasArrow
              placement='top'
              fontSize='12px'
              label='Reveja o preenchimento os campos obrigatórios e possíveis mensagens de erro para publicar'
              isDisabled={!isInvalid}
              shouldWrapChildren
            >
              <Button
                type='submit'
                variant='unstyled'
                w='fit-content'
                h='44px'
                bg='gray.800'
                color='white'
                fontSize='1rem'
                padding='8px 16px'
                isDisabled={isInvalid || isLoading}
                _hover={{ _disabled: { bg: '#3F3D561F' } }}
              >
                {isLoading ? <Spinner /> : 'Cadastrar'}
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
      </form>
    </Flex>
  )
}
