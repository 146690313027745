import { gql } from "@apollo/client";

export const DELETE_TEAM = gql`
  mutation deleteTeam($id: String!) {
    deleteTeam(id: $id) {
      name
    }
  }
`;

export const REMOVE_TEAM_MEMBERS = gql`
  mutation removeTeamMembers($teamId: String!, $teamMemberIds: [Int!]!) {
    removeTeamMembers(teamId: $teamId, teamMemberIds: $teamMemberIds) {
      id
      name
      manager {
        id
        name
      }
      members {
        id
        name
      }
    }
  }
`;

export const ADD_TEAM_MEMBERS = gql`
  mutation addTeamMembers($teamId: String!, $teamMemberIds: [Int!]!) {
    addTeamMembers(teamId: $teamId, teamMemberIds: $teamMemberIds) {
      id
      name
      manager {
        id
        name
      }
      members {
        id
        name
      }
    }
  }
`;

export const CHANGE_TEAM_MANAGER = gql`
  mutation changeTeamManager($teamId: String!, $managerId: Int!) {
    changeTeamManager(teamId: $teamId, managerId: $managerId) {
      id
      name
      manager {
        id
        name
      }
      members {
        id
        name
      }
    }
  }
`;
