import { Flex } from '@chakra-ui/react'
import ReactSelect from "react-select";
import { useQuery } from "@tanstack/react-query";
import { MultiSelectStylesForDashboards } from 'src/pages-admin/Cms/utils/multiSelectStyles';
import { Option } from './ReportContent';
import { makeGetPlaylists } from '../factory/makeGetPlaylists';
import { useTranslation } from 'react-i18next';

interface SelectPlaylistsProps {
  playlistSelected: Option | undefined,
  setPlaylistSelected: (value: Option) => void
}

export const SelectPlaylists = ({ playlistSelected, setPlaylistSelected }: SelectPlaylistsProps) => {
  const [t] = useTranslation()
  const { data: playlists, isLoading: isLoadingPlaylists } = useQuery({
    queryKey: [
      "playlists",
    ],
    queryFn: () => {
      const getReport = makeGetPlaylists()
      return getReport.execute()
    },
    refetchOnWindowFocus: false,
    initialData: [],
  });

  return (
    <Flex direction='column' flex={1} minW="250px" maxW="300px">
      <ReactSelect
        isSearchable
        isClearable
        styles={MultiSelectStylesForDashboards}
        placeholder={t('admin.reports.select_playlist')}
        value={playlistSelected}
        options={playlists
          .map((playlist) => ({ value: playlist.id, label: playlist.title }))
        }
        onChange={(selectedOption) => {
          setPlaylistSelected(selectedOption as unknown as Option)
        }}
        isLoading={isLoadingPlaylists}
      />
    </Flex>
  )
}
