import { useDisclosure } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from 'src/context/userContext'
import { useRegisterNewContent } from 'src/pages-admin/Cms/hooks/useRegisterNewContent'
import { CmsContentTable } from '../components/ContentTable'
import { RemoveContentDrawer } from '../components/RemoveContentDrawer'
import { CmsTabContent } from '../components/TabContent'
import { useCorporateUniversityStore } from '../hooks/useCorporateUniversityStore'
import useListContents from '../hooks/useListContents'
import { useRegisterContentStore } from '../hooks/useRegisterContentStore'

interface ContentTabProps {
  tab: string
}

export function ContentTab({ tab }: ContentTabProps) {
  const { updateRegisterModalState } = useRegisterContentStore()
  const { refetch, listContents, isListContentsLoading } = useListContents()
  const { totalCourses, updateSelectedPlaylist, updateSelectedContent, updateSkip, applyFilters } =
    useCorporateUniversityStore()
  const { updateSelectedPermission, updateSavedSelectedCompanies } = useRegisterNewContent()
  const { isOpen: isRemoveContentOpen, onClose: onRemoveContentClose, onOpen: onRemoveContentOpen } = useDisclosure()
  const [t] = useTranslation()
  const { user } = useUser()
  const isUserBackoffice = user?.roles?.includes('BACKOFFICE')

  const handleOpenContentModal = () => {
    updateSelectedPermission('')
    updateSavedSelectedCompanies([])
    updateSelectedPlaylist(undefined)
    updateSelectedContent(undefined)
    updateRegisterModalState(true)
  }

  useEffect(() => {
    if (!isUserBackoffice) {
      applyFilters('companies', [user?.company?.id ?? user?.companyId ?? -1])
    }
    listContents({ variables: { status: 'PUBLISHED' } })
    updateSelectedContent(undefined)
    updateSelectedPlaylist(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      updateSkip(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <RemoveContentDrawer isOpen={isRemoveContentOpen} onClose={onRemoveContentClose} refetch={refetch} />

      <CmsTabContent
        pageTitle={t('admin.corporateUniversity.contents.pageTitle')}
        registerNewContentTitle={t('admin.corporateUniversity.contents.registerNewContent')}
        handleRegisterNewContentClick={handleOpenContentModal}
        searchSectionTitle={t('admin.corporateUniversity.contents.searchSectionTitle')}
        searchPlaceholder={t('admin.corporateUniversity.contents.searchForContent')}
        tabTable={CmsContentTable}
        tab={tab}
        totalContentLength={totalCourses}
        isLoading={isListContentsLoading}
        refetch={refetch}
        onOpen={onRemoveContentOpen}
      />
    </>
  )
}
