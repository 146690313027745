import { Flex } from '@chakra-ui/react'
import ReactSelect from "react-select";
import { MultiSelectStylesForDashboards } from 'src/pages-admin/Cms/utils/multiSelectStyles';
import { useMemo } from 'react';
import { Option } from './ReportContent';
import { useTranslation } from 'react-i18next';

interface SelectDateFilterProps {
  dateFilterSelected: Option | undefined,
  setDateFilterSelected: (value: Option) => void
}

enum DateFilterEnum {
  last_day = 'last_day',
  last_7_days = 'last_7_days',
  last_30_days = 'last_30_days',
  last_year = 'last_year',
}

export const SelectDateFilter = ({ dateFilterSelected, setDateFilterSelected }: SelectDateFilterProps) => {
  const [t] = useTranslation()
  const options: Option[] = useMemo(() => {
    const options: { [key in DateFilterEnum]: string } = {
      [DateFilterEnum.last_day]: t('admin.reports.last_day'),
      [DateFilterEnum.last_7_days]: t('admin.reports.last_7_days'),
      [DateFilterEnum.last_30_days]: t('admin.reports.last_30_days'),
      [DateFilterEnum.last_year]: t('admin.reports.last_year'),
    }

    return Object.entries(options).map(([key, value]) => ({ value: key, label: value }))
  }, [t])

  return (
    <Flex direction='column' flex={1} minW="250px" maxW="300px">
      <ReactSelect
        isSearchable
        isClearable
        styles={MultiSelectStylesForDashboards}
        placeholder={t('admin.reports.select_date')}
        value={dateFilterSelected}
        options={options}
        onChange={(selectedOption) => {
          setDateFilterSelected(selectedOption as unknown as Option)
        }}
      />
    </Flex>
  )
}
