import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import { FormikProps } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCloudUpload, IoHelpCircle } from 'react-icons/io5'
import { renderRequiredLabel } from '../../utils/renderRequiredLabel'
import { verifyCorrectlyImageType } from '../../utils/verifyCorrectlyImageType'
import { makeGetTranslationByLanguage } from 'src/modules/internationalization/use-case/GetTranslationByLanguageUseCase'

interface PlaylistInformationAccordionProps {
  form: FormikProps<any>
}

interface MultiLanguageInfo {
  [key: string]: {
    title?: string
    description?: string
  }
}

export function PlaylistInformationAccordion({ form }: PlaylistInformationAccordionProps) {
  const [t] = useTranslation()
  const toast = useToast()
  const [hasCertificate, setHasCertificate] = useState(false)

  const [multiLanguageInfo, setMultiLanguageInfo] = useState<MultiLanguageInfo>(form.values.contentTranslation ?? {})
  const [loadingTranslation, setLoadingTranslation] = useState(false)

  const hasPlaylistCoverCorrectFormat = useMemo(
    () => verifyCorrectlyImageType(form?.values?.imageUrl?.type),
    [form?.values?.imageUrl],
  )
  const hasPlaylistCertificateImageCorrectFormat = useMemo(
    () => verifyCorrectlyImageType(form?.values?.certificateImageUrl?.type),
    [form.values.certificateImageUrl],
  )

  const handleUploadPlaylistCover = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    form.setFieldValue('imageUrl', file)
  }

  const handleUploadCertificateImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    form.setFieldValue('certificateImageUrl', file)
  }

  useEffect(() => {
    form.setFieldValue('cardImageUrl', form.values.imageUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.imageUrl])

  useEffect(() => {
    form.setFieldValue('type', 'FEATURED')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    form.setFieldValue('hasCertificate', hasCertificate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCertificate, form.setFieldValue])

  useEffect(() => {
    if (!!form.values.certificateImageUrl && !!form.values.certificateTemplateUrl) {
      setHasCertificate(true)
    }
  }, [form])

  useEffect(() => {
    const parsed = Object.keys(multiLanguageInfo).reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: {
          title: multiLanguageInfo[curr].title,
          description: multiLanguageInfo[curr].description,
        },
      }
    }, {})
    form.setFieldValue('contentTranslation', parsed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiLanguageInfo])

  const handleTranslateText = async (language: string) => {
    if (!form?.values?.title && !form?.values?.description) return
    try {
      setLoadingTranslation(true)
      const translateText = makeGetTranslationByLanguage()
      const [titleTranslated, descriptionTranslated] = await Promise.all([
        translateText.execute(language, form.values.title ?? ''),
        translateText.execute(language, form.values.description ?? ''),
      ])
      setMultiLanguageInfo((prev) => ({
        ...prev,
        [language]: { ...prev[language], title: titleTranslated, description: descriptionTranslated },
      }))
      toast({
        title: t('theTranslationsWasGeneratedSuccessfully'),
        status: 'success',
        duration: 3000,
      })
    } catch {
      console.error('Error translating text')
      toast({
        title: t('anErrorOccuredWhenGeneratingTranslations'),
        status: 'error',
        duration: 3000,
      })
    } finally {
      setLoadingTranslation(false)
    }
  }

  const handleInputMultiLanguage = (language: string, prop: string, value: string) => {
    setMultiLanguageInfo((prev) => ({ ...prev, [language]: { ...prev[language], [prop]: value } }))
  }

  return (
    <AccordionItem border='none' borderBottom='1px solid #767F89'>
      <AccordionButton p='32px 0' _focus={{ boxShadow: 'none' }} _hover={{ background: 'white' }}>
        <Flex w='100%' align='center' justify='space-between' pr='8px'>
          <Text color='#313E4C' fontSize='1.25rem' fontWeight={600} fontFamily='Poppins'>
            {t('playlistInfo')}
          </Text>
        </Flex>
        <AccordionIcon w='1.2em' h='1.2em' color='#161616' />
      </AccordionButton>

      <AccordionPanel display='flex' flexDir='column' gap='16px'>
        <Flex borderWidth='2px' borderRadius='16px' p='8px' borderColor='#e5e5e5  ' w='100%'>
          <Tabs defaultIndex={0} position='relative' w='100%'>
            <TabList>
              <Tab _selected={{ color: 'green.500', fontWeight: 700, borderColor: 'green.500' }}>Português</Tab>
              <Tab _selected={{ color: 'green.500', fontWeight: 700, borderColor: 'green.500' }}>Español</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <FormControl id='title' isInvalid={!!form.errors.title && !!form.touched.title}>
                  <Flex align='center'>
                    <FormLabel
                      htmlFor='title'
                      fontFamily='Mulish'
                      fontWeight='700'
                      color='gray.800'
                      fontSize='16px'
                      marginBottom='2px'
                    >
                      {renderRequiredLabel(t('title'))}
                    </FormLabel>
                    <Tooltip hasArrow placement='right' fontSize='12px' label='Ideal que tenha no máximo 70 caracteres'>
                      <Box mb='2px'>
                        <IoHelpCircle size={20} color='#767F89' />
                      </Box>
                    </Tooltip>
                  </Flex>
                  <Input
                    type='text'
                    name='title'
                    borderRadius='16px'
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.title}
                  />
                </FormControl>
                <FormControl id='description' isInvalid={!!form.errors.description && !!form.touched.description} mt='24px'>
                  <FormLabel
                    htmlFor='description'
                    fontFamily='Mulish'
                    fontWeight='700'
                    color='gray.800'
                    fontSize='16px'
                    marginBottom='2px'
                  >
                    {renderRequiredLabel(t('description'))}
                  </FormLabel>
                  <Textarea
                    borderRadius='16px'
                    border='2px solid'
                    borderColor='#E5E5E5'
                    resize='none'
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.description}
                    boxShadow='none !important'
                    _hover={{ borderColor: 'gray.800' }}
                    _active={{ borderColor: 'gray.800' }}
                    _focus={{ borderColor: 'gray.800' }}
                  />
                  <Button
                    isLoading={loadingTranslation}
                    variant='startCourseDark'
                    height='36px'
                    mt='12px'
                    py='0px'
                    fontSize='0.75rem'
                    ml='auto'
                    display='flex'
                    onClick={() => handleTranslateText('es')}
                  >
                    Traduzir
                  </Button>
                </FormControl>
              </TabPanel>
              <TabPanel>
                <Flex align='center'>
                  <FormLabel
                    htmlFor='title-es'
                    fontFamily='Mulish'
                    fontWeight='700'
                    color='gray.800'
                    fontSize='16px'
                    marginBottom='2px'
                  >
                    {t('title')}
                  </FormLabel>
                  <Tooltip hasArrow placement='right' fontSize='12px' label='Ideal que tenha no máximo 70 caracteres'>
                    <Box mb='2px'>
                      <IoHelpCircle size={20} color='#767F89' />
                    </Box>
                  </Tooltip>
                </Flex>
                <Input
                  type='text'
                  id='title-es'
                  borderRadius='16px'
                  onChange={(e) => handleInputMultiLanguage('es', 'title', e.target.value)}
                  value={multiLanguageInfo?.['es']?.title}
                />
                <FormLabel
                  mt='24px'
                  htmlFor='description-es'
                  fontFamily='Mulish'
                  fontWeight='700'
                  color='gray.800'
                  fontSize='16px'
                  marginBottom='2px'
                >
                  {t('description')}
                </FormLabel>
                <Textarea
                  id='description-es'
                  borderRadius='16px'
                  border='2px solid'
                  borderColor='#E5E5E5'
                  resize='none'
                  onChange={(e) => handleInputMultiLanguage('es', 'description', e.target.value)}
                  value={multiLanguageInfo?.['es']?.description}
                  boxShadow='none !important'
                  _hover={{ borderColor: 'gray.800' }}
                  _active={{ borderColor: 'gray.800' }}
                  _focus={{ borderColor: 'gray.800' }}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>

        <FormControl
          id='imageUrl'
          isInvalid={!!form.errors.imageUrl && !!form.touched.imageUrl && !hasPlaylistCoverCorrectFormat}
        >
          <FormLabel
            htmlFor='imageUrl'
            fontFamily='Mulish'
            fontWeight='700'
            color='gray.800'
            fontSize='16px'
            marginBottom='2px'
          >
            {renderRequiredLabel(t('playlistCover'))}
          </FormLabel>
          <Flex align='center'>
            <Input
              type='file'
              id='imageUrl'
              name='imageUrl'
              accept='.png,.svg,.jpg,.webp'
              onChange={handleUploadPlaylistCover}
              style={{ display: 'none' }}
            />
            <InputGroup>
              <Input
                type='text'
                value={typeof form.values.imageUrl === 'string' ? form.values.imageUrl : form.values.imageUrl?.name}
                title={typeof form.values.imageUrl === 'string' ? form.values.imageUrl : form.values.imageUrl?.name}
                readOnly
                onBlur={form.handleBlur}
              />
              <InputRightElement cursor='pointer' onClick={() => document.getElementById('imageUrl')?.click()}>
                <IoCloudUpload color='#767F89' />
              </InputRightElement>
            </InputGroup>
          </Flex>
          {/* eslint-disable-next-line no-extra-boolean-cast */}
          {hasPlaylistCoverCorrectFormat !== undefined && !hasPlaylistCoverCorrectFormat && (
            <Text color='error' fontSize='.75rem'>
              {t('invalidFormat')} .png, .svg, .jpg ou .webp.
            </Text>
          )}
        </FormControl>

        {hasCertificate ? (
          <Flex w='100%' gap='16px'>
            <FormControl
              id='certificateImageUrl'
              isInvalid={
                !!form.errors.certificateImageUrl &&
                !!form.touched.certificateImageUrl &&
                !hasPlaylistCertificateImageCorrectFormat
              }
            >
              <FormLabel
                htmlFor='certificateImageUrl'
                fontFamily='Mulish'
                fontWeight='700'
                color='gray.800'
                fontSize='16px'
                marginBottom='2px'
              >
                {renderRequiredLabel(t('certificateImage'))}
              </FormLabel>
              <Flex align='center'>
                <Input
                  type='file'
                  id='certificateImageUrl'
                  name='certificateImageUrl'
                  accept='.png,.svg,.jpg,.webp'
                  onChange={handleUploadCertificateImage}
                  style={{ display: 'none' }}
                />
                <InputGroup>
                  <Input
                    type='text'
                    value={
                      typeof form.values.certificateImageUrl === 'string'
                        ? form.values.certificateImageUrl
                        : form.values.certificateImageUrl?.name
                    }
                    title={
                      typeof form.values.certificateImageUrl === 'string'
                        ? form.values.certificateImageUrl
                        : form.values.certificateImageUrl?.name
                    }
                    readOnly
                    onBlur={form.handleBlur}
                  />
                  <InputRightElement
                    cursor='pointer'
                    onClick={() => document.getElementById('certificateImageUrl')?.click()}
                  >
                    <IoCloudUpload color='#767F89' />
                  </InputRightElement>
                </InputGroup>
              </Flex>
              {hasPlaylistCertificateImageCorrectFormat !== undefined && !hasPlaylistCertificateImageCorrectFormat && (
                <Text color='error' fontSize='.75rem'>
                  {t('invalidFormat')} .png, .svg, .jpg ou .webp.
                </Text>
              )}
            </FormControl>

            <FormControl
              id='certificateTemplateUrl'
              isInvalid={!!form.errors.certificateTemplateUrl && !!form.touched.certificateTemplateUrl}
            >
              <FormLabel
                htmlFor='certificateTemplateUrl'
                fontFamily='Mulish'
                fontWeight='700'
                color='gray.800'
                fontSize='16px'
                marginBottom='2px'
              >
                {renderRequiredLabel(t('certificateTemplateUrl'))}
              </FormLabel>
              <Input
                type='text'
                name='certificateTemplateUrl'
                borderRadius='16px'
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.certificateTemplateUrl}
              />
            </FormControl>
          </Flex>
        ) : null}
      </AccordionPanel>
    </AccordionItem>
  )
}
