import { IconButton } from "@chakra-ui/button";
import { Flex } from "@chakra-ui/layout";
import { IoCloseCircle } from "react-icons/io5";

interface AreaTagProps {
  area: string;
  deleteArea: (area: string) => void;
}

const AreaTag: React.FC<AreaTagProps> = ({
  area,
  deleteArea,
}: AreaTagProps) => {
  return (
    <Flex
      bg="gray.100"
      margin="10px 10px 0 0"
      paddingLeft="20px"
      height="40px"
      borderRadius="20px"
      fontWeight="bold"
      alignItems="center"
    >
      {area}
      <IconButton
        title="Delete Button"
        bg="gray.100"
        borderRadius="20px"
        paddingRight="4%"
        _hover={{ bg: "transparent" }}
        fontSize="20px"
        icon={<IoCloseCircle color="black" />}
        as="div"
        onClick={() => deleteArea(area)}
        aria-label="Delete area"
      />
    </Flex>
  );
};

export default AreaTag;
