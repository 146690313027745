import { FormControl, FormLabel } from '@chakra-ui/react'
import ReactSelect, { OptionTypeBase } from 'react-select'
import { selectStyles } from 'src/components/CustomPlaylistQuiz/utils'
import { useCorporateUniversityStore } from 'src/pages-admin/Cms/hooks/useCorporateUniversityStore'
import { renderRequiredLabel } from 'src/pages-admin/Cms/utils/renderRequiredLabel'

type Props = {
  name: string
  label: string
  placeholder?: string
  selectedCompany: OptionTypeBase | null
  handleSelectCompany: (selectedCompany: OptionTypeBase) => void
}

export const CompanySelection: React.FC<Props> = ({
  name,
  label,
  placeholder,
  selectedCompany,
  handleSelectCompany,
}) => {
  const { companyList } = useCorporateUniversityStore()

  return (
    <FormControl id={name} isInvalid={false} display='flex' flexDir='column' gap='4px'>
      <FormLabel
        htmlFor={name}
        fontFamily='Mulish'
        fontWeight='700'
        color='gray.800'
        fontSize='16px'
        marginBottom='2px'
      >
        {renderRequiredLabel(label)}
      </FormLabel>
      <ReactSelect
        styles={selectStyles()}
        value={!selectedCompany || Object.keys(selectedCompany).length === 0 ? null : selectedCompany}
        placeholder={placeholder ?? 'Selecionar empresas'}
        components={{ IndicatorSeparator: () => null }}
        onChange={handleSelectCompany}
        options={companyList.map((company) => ({ value: company.id, label: company.trade_name }))}
      />
    </FormControl>
  )
}
