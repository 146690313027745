import { Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Content from 'src/modules/cms/domain/Content'
import { useRegisterContentStore } from 'src/pages-admin/Cms/hooks/useRegisterContentStore'
import { DeleteContent, EditContent } from 'src/theme/Icons'
import { CmsContentType } from 'src/types/Cms'
import { useCorporateUniversityStore } from '../../hooks/useCorporateUniversityStore'
import { formatDate } from '../../utils/formatDate'
import { parseCourseType } from '../../utils/parseCourseType'
import { useUser } from 'src/context/userContext'

interface CmsContentTableProps {
  openDrawer?: () => void
}

export function CmsContentTable({ openDrawer }: CmsContentTableProps) {
  const { cmsCourses, updateSelectedContent, updateSelectedPlaylist } = useCorporateUniversityStore()
  const { updateRegisterModalState, updateSelectedPermission } = useRegisterContentStore()
  const [t] = useTranslation()
  const { user } = useUser()
  const isUserBackoffice = user?.roles?.includes('BACKOFFICE')

  const handleRemoveContent = useCallback(
    (content: Content) => {
      updateSelectedContent(content)
      updateSelectedPlaylist(undefined)
      if (openDrawer) {
        openDrawer()
      }
    },
    [openDrawer, updateSelectedContent, updateSelectedPlaylist],
  )

  const handleEditContent = async (content: Content) => {
    if (content.allowedCompanyIds?.[0] === -1) {
      updateSelectedPermission('allUsers')
    } else {
      updateSelectedPermission('specificCompanies')
    }
    updateSelectedContent(content)
    updateRegisterModalState(true)
  }

  return (
    <TableContainer
      d='flex'
      position='relative'
      w='100%'
      borderRadius='16px'
      boxShadow='0px 1px 10px rgba(0, 0, 0, 0.1)'
      data-testid='cms-table-wrapper'
    >
      <Table variant='unstyled' size='lg'>
        <Thead bg='#F7F9FA'>
          <Tr padding='16px 34px' w='100%'>
            <Th w='50%' fontWeight={700} textTransform='initial' fontSize='1.125rem'>
              {t('admin.corporateUniversity.contents.tableContentTitle')}
            </Th>
            <Th w='150px' fontWeight={700} textTransform='initial' fontSize='1.125rem'>
              {t('admin.corporateUniversity.contents.tableTypeTitle')}
            </Th>
            <Th w='150px' fontWeight={700} textTransform='initial' fontSize='1.125rem'>
              {t('admin.corporateUniversity.contents.tableUpdatedAtTitle')}
            </Th>
            <Th w='80px' />
          </Tr>
        </Thead>

        <Tbody>
          {!cmsCourses ||
            (cmsCourses.length <= 0 && (
              <Flex padding='20px'>
                <Text color='bluishGray.500'>{t('noContentFound')}.</Text>
              </Flex>
            ))}

          {cmsCourses.length > 0 &&
            cmsCourses?.map((content, index) => (
              <Tr
                key={content.id}
                w='100%'
                borderBottom='1px solid #EFEFEF'
                _hover={{ backgroundColor: '#EFEFEF' }}
                transition='all 300ms ease'
                color='bluishGray.500'
              >
                <Td maxW='300px' overflowX='hidden' textOverflow='ellipsis'>
                  {content.title}
                </Td>
                <Td textTransform='capitalize'>{parseCourseType(content.type as CmsContentType)}</Td>
                <Td>{formatDate(content.updatedAt!)}</Td>
                <Td>
                  <Flex align='center' gap='24px' position='relative'>
                    <Tooltip
                      hasArrow
                      shouldWrapChildren
                      bg='gray.800'
                      color='gray.50'
                      p='8px'
                      placement='top'
                      borderRadius='4px'
                      boxShadow='0px 4px 6px rgba(0, 0, 0, 0.15);'
                      label={t('admin.corporateUniversity.tableEditButton')}
                    >
                      <EditContent
                        width='24px'
                        height='24px'
                        cursor='pointer'
                        onClick={() => handleEditContent(content)}
                        data-testid={`cms-edit-content-button-${index}`}
                        display={isUserBackoffice || (content.allowedCompanyIds?.length === 1 && content.allowedCompanyIds[0] !== -1) ? 'block' : 'none' }
                      />
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      shouldWrapChildren
                      bg='gray.800'
                      color='gray.50'
                      p='8px'
                      placement='top'
                      borderRadius='4px'
                      boxShadow='0px 4px 6px rgba(0, 0, 0, 0.15);'
                      label={t('admin.corporateUniversity.tableDeleteButton')}
                    >
                      <DeleteContent
                        width='24px'
                        height='24px'
                        onClick={() => handleRemoveContent(content)}
                        cursor='pointer'
                        data-testid={`cms-remove-content-button-${index}`}
                        display={isUserBackoffice || (content.allowedCompanyIds?.length === 1 && content.allowedCompanyIds[0] !== -1) ? 'block' : 'none' }
                      />
                    </Tooltip>
                  </Flex>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
