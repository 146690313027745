export const availableLanguages = [
  {
    value: 'pt-BR',
    label: 'Português',
  },
  {
    value: 'en-US',
    label: 'Inglês',
  },
  {
    value: 'es',
    label: 'Espanhol',
  },
  {
    value: 'de',
    label: 'Alemão',
  },
  {
    value: 'zh',
    label: 'Mandarim',
  },
  {
    value: 'ja',
    label: 'Japonês',
  },
  {
    value: 'it',
    label: 'Italiano',
  },
  {
    value: 'fr',
    label: 'Francês',
  },
  // {
  //   value: 'other',
  //   label: 'Outro',
  // },
]

export const availableSubtitles = [
  ...availableLanguages,
  {
    value: 'no-subtitle',
    label: 'Sem legenda',
  },
]

export const formatInitialLanguagesValue = (languages?: string[], isSubtitle?: boolean) => {
  if (isSubtitle && languages?.some(item => item === 'no-subtitle')) {
    return [{ value: 'no-subtitle', label: 'Sem legenda' }]
  }

  return (
    languages
      ?.map((lang) => availableLanguages.find((availableLang) => availableLang.value === lang))
      .filter((lang): lang is { value: string; label: string } => lang !== undefined) ?? []
  )
}
