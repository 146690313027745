import { Text } from '@chakra-ui/react'

interface SectionTitleProps {
  sectionTitle: string
}

/**
 * Render a text with section title style.
 * @param sectionTitle The text to be displayed.
 * @example <SectionTitle sectionTitle={text} />
 */
export function SectionTitle({ sectionTitle }: SectionTitleProps) {
  return (
    <Text fontSize='1.25rem' fontWeight='600' letterSpacing='-0.6px' fontFamily='Poppins'>
      {sectionTitle}
    </Text>
  )
}
