import type { Interpreter, StateMachine } from 'xstate'
import { createMachine, interpret } from 'xstate'
import type { StoreApi } from 'zustand'
import { create } from 'zustand'

interface IamState {
  isLogged: () => boolean
  userEmail: string
  saveAccessToken: (accessToken: string) => void
  setUserEmail: (email: string) => void
  clearToken: () => void
  state: (state: keyof typeof LOGIN_STATES) => boolean
  sendEvent: (input: { type: keyof typeof IAM_ACTION }) => void
  getAccessToken: () => string
}

export type Store<M> = M extends StateMachine<infer Context, infer Schema, infer Event, infer State>
  ?
      | {
          state: Interpreter<Context, Schema, Event, State>['state']
          sendEvent: Interpreter<Context, Schema, Event, State>['send']
          service: Interpreter<Context, Schema, Event, State>
        } & IamState
  : never

export const LOGIN_STATES = Object.freeze({
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  REGISTER: 'REGISTER',
  'REGISTER.VERIFY_EMAIL': 'REGISTER.VERIFY_EMAIL',
  'REGISTER.CTA_REGISTER': 'REGISTER.CTA_REGISTER',
  'REGISTER.REGISTER_FORM': 'REGISTER.REGISTER_FORM',
  'REGISTER.CTA_EXTENSION': 'REGISTER.CTA_EXTENSION',
  USER_CREDENTIALS: 'USER_CREDENTIALS',
  LOGGED_IN: 'LOGGED_IN',
  LOG_OUT: 'LOG_OUT',
  CONFIRM_EMAIL_VERIFICATION: 'CONFIRM_EMAIL_VERIFICATION',
  REGISTER_NEW_PASSWORD: 'REGISTER_NEW_PASSWORD',
})

export const IAM_ACTION = Object.freeze({
  ELEGIBLE_AND_NOT_REGISTERED: 'ELEGIBLE_AND_NOT_REGISTERED',
  VALID_REGISTER: 'VALID_REGISTER',
  INVALID_REGISTER: 'INVALID_REGISTER',
  EMAIL_NOT_VERIFIED: 'EMAIL_NOT_VERIFIED',
  EMAIL_VERIFIED: 'EMAIL_VERIFIED',
  REGISTERED_USER: 'REGISTERED_USER',
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  INVALID_PASSWORD: 'INVALID_PASSWORD',
  VALID_PASSWORD: 'VALID_PASSWORD',
  VALID_CREDENTIALS: 'VALID_CREDENTIALS',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  LOG_OUT: 'LOG_OUT',
  PROCEED: 'PROCEED',
  BACK: 'BACK',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
})

const iamMachine = createMachine({
  id: 'Machine Name',
  initial: 'VERIFY_EMAIL',
  states: {
    VERIFY_EMAIL: {
      on: {
        ELEGIBLE_AND_NOT_REGISTERED: {
          target: 'REGISTER',
        },
        REGISTERED_USER: {
          target: 'USER_CREDENTIALS',
        },
        NEW_PASSWORD_REQUIRED: {
          target: 'REGISTER_NEW_PASSWORD',
        },
        EMAIL_NOT_VERIFIED: {
          target: 'CONFIRM_EMAIL_VERIFICATION',
        },
      },
    },
    REGISTER: {
      initial: 'CTA_REGISTER',
      states: {
        CTA_REGISTER: {
          on: {
            PROCEED: {
              target: 'REGISTER_FORM',
            },
          },
        },
        REGISTER_FORM: {
          on: {
            VALID_REGISTER: {
              target: 'VERIFY_EMAIL',
            },
            INVALID_REGISTER: {},
          },
        },
        VERIFY_EMAIL: {
          on: {
            EMAIL_NOT_VERIFIED: {},
            EMAIL_VERIFIED: {
              target: 'CTA_EXTENSION',
            },
          },
        },
        CTA_EXTENSION: {
          on: {
            PROCEED: {
              target: '#Machine Name.USER_CREDENTIALS',
            },
          },
        },
      },
    },
    USER_CREDENTIALS: {
      on: {
        VALID_CREDENTIALS: {
          target: 'LOGGED_IN',
        },
        INVALID_CREDENTIALS: {},
        BACK: {
          target: 'VERIFY_EMAIL',
        },
        FORGOT_PASSWORD: {
          target: 'REGISTER_NEW_PASSWORD',
        },
      },
    },
    REGISTER_NEW_PASSWORD: {
      on: {
        INVALID_PASSWORD: {},
        VALID_PASSWORD: {
          target: 'USER_CREDENTIALS',
        },
        BACK: {
          target: 'USER_CREDENTIALS',
        },
      },
    },
    LOGGED_IN: {
      on: {
        LOG_OUT: {
          target: 'VERIFY_EMAIL',
        },
      },
    },
    CONFIRM_EMAIL_VERIFICATION: {
      on: {
        PROCEED: {
          target: 'USER_CREDENTIALS',
        },
      },
    },
  },
  schema: {
    context: {} as Record<string, unknown>,
    events: {} as { type: keyof typeof IAM_ACTION },
  },
  context: {},
  predictableActionArguments: true,
  preserveActionOrder: true,
})

export const LOGGED_ACCESS_TOKEN_KEY = 'my_app_logged_in'

const getInitialLoggedIn = () => {
  const loggedIn = Boolean(localStorage.getItem(LOGGED_ACCESS_TOKEN_KEY)) || false
  return loggedIn
}

export const getAccessToken = (): string => {
  return localStorage.getItem(LOGGED_ACCESS_TOKEN_KEY) || ''
}

export const clearToken = () => {
  localStorage.removeItem(LOGGED_ACCESS_TOKEN_KEY)
}

export const useIamStore = create(
  (
    <M extends StateMachine<any, any, any, any, any, any, any>>(machine: M) =>
    (set: StoreApi<Store<M> | IamState>['setState']): Store<M> => {
      const service = interpret(machine)
        .onTransition((state) => {
          const initialStateChanged = state.changed === undefined && Object.keys(state.children).length
          if (state.changed || initialStateChanged) {
            set({ state: state.matches } as Partial<Store<M>>)
          }
        })
        .start()

      return {
        state: service.getSnapshot().matches,
        sendEvent: service.send,
        service,
        isLogged: () => getInitialLoggedIn(),
        userEmail: '',
        setUserEmail: (email: string) => set(() => ({ userEmail: email })),
        saveAccessToken: (accessToken: string) => {
          localStorage.setItem(LOGGED_ACCESS_TOKEN_KEY, accessToken)
        },
        getAccessToken,
        clearToken: clearToken,
      } as Store<M>
    }
  )(iamMachine),
)
