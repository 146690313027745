import { Flex, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import LearningItemDrawer from 'src/components/ContentDrawer'
import ContentSwapDrawer from 'src/components/ContentSwapDrawer'
import { EvaluateCustomPlaylistModal } from 'src/components/EvaluateCustomPlaylistModal'
import { AddContentDrawerProvider } from 'src/context/CustomPlaylist/AddContentDrawerContext'
import { CustomPlaylistProvider, useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { KnowMoreDrawerProvider, useKnowMoreDrawer } from 'src/context/CustomPlaylist/KnowMoreDrawerContext'
import { SwapContentDrawerProvider } from 'src/context/CustomPlaylist/SwapContentDrawerContext'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { useUser } from 'src/context/userContext'
import AddLearningItemDrawer from 'src/modules/cms/components/CustomPlaylist/AddLearningItemDrawer'
import EditSkillsDrawer from 'src/modules/cms/components/CustomPlaylist/EditSkillDrawer'
import Storage from 'src/services/storage'
import Footer from '../../components/Footer'
import Header from '../../modules/cms/components/CustomPlaylist/CustomPlaylistHeader'
import Modules from '../../modules/cms/components/CustomPlaylist/CustomPlaylistModules'
import PlaylistSkeleton from '../../modules/cms/components/FeaturedPlaylist/Skeleton'
import { CertificateSection } from './components/CertificateSection'
import EmptyState from './EmptyState'
import { useTranslation } from 'react-i18next'

const CustomPlaylistPage: React.FC = () => {
  const { t } = useTranslation()
  const [isUserAuthorized, setIsUserAuthorized] = useState(false)
  const { playlist, loading, fetchCustomPlaylist, isEvaluationOpen, setIsEvaluationOpen, setEvaluationSource } =
    useCustomPlaylist()
  const { alreadyRedirected } = useCustomPlaylistQuiz()
  const { user } = useUser()
  const history = useHistory()

  const toast = useToast()

  useEffect(() => {
    if (user) fetchCustomPlaylist(user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id])

  useEffect(() => {
    alreadyRedirected()
  }, [alreadyRedirected])

  useEffect(() => {
    setIsUserAuthorized(false)

    if (user) {
      if (user.hasCustomPlaylistAssigned && !user.hasCompletedCustomPlaylistQuiz) {
        history.push('/customPlaylistQuiz')
      } else if (!user.hasCustomPlaylistAssigned) {
        toast({
          description: 'Entre em contato com seu RH para solicitar uma trilha personalizada.',
          duration: 5000,
          status: 'error',
          isClosable: true,
        })
        history.push('/home')
      } else {
        setIsUserAuthorized(true)
      }
    }
  }, [user, history, toast])

  useEffect(() => {
    const alreadyEvaluated = JSON.parse(Storage.customPlaylistEvaluation.get()) ?? null
    setTimeout(() => {
      if (!alreadyEvaluated) {
        setEvaluationSource('playlist_created')
        setIsEvaluationOpen(true)
      }
    }, 2 * 1000 * 60)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!user || loading || !isUserAuthorized) return <PlaylistSkeleton />

  return (
    <>
      <Flex flex={1} flexDirection='column' w='100%'>
        <Helmet>
          <title>{t('customPlaylist')} | Education Journey</title>
        </Helmet>

        {!playlist && !loading && isUserAuthorized ? (
          <EmptyState />
        ) : (
          <>
            <Header />
            <Flex flex={1} flexDir='column' w='100%'>
              <Modules />
              <Flex w='100%' py='16px'>
                <CertificateSection />
              </Flex>
            </Flex>
          </>
        )}

        <Footer />
      </Flex>

      {isEvaluationOpen && (
        <EvaluateCustomPlaylistModal isOpen={isEvaluationOpen} onClose={() => setIsEvaluationOpen(false)} />
      )}
    </>
  )
}

const CustomPlaylistLearningItemDrawer = () => {
  const { drawerInfo, closeText, closeLearningItemDrawer } = useKnowMoreDrawer()

  if (!drawerInfo) return null

  return <LearningItemDrawer drawerInfo={drawerInfo} closeText={closeText} onCloseClick={closeLearningItemDrawer} />
}

const CustomPlaylistPageWithContext = () => (
  <CustomPlaylistProvider>
    <KnowMoreDrawerProvider>
      <SwapContentDrawerProvider>
        <AddContentDrawerProvider>
          <CustomPlaylistPage />
          <CustomPlaylistLearningItemDrawer />
          <ContentSwapDrawer />
          <AddLearningItemDrawer />
          <EditSkillsDrawer />
        </AddContentDrawerProvider>
      </SwapContentDrawerProvider>
    </KnowMoreDrawerProvider>
  </CustomPlaylistProvider>
)

export default CustomPlaylistPageWithContext
