export const proficiencyLevels = [
  {
    key: 'Nível Iniciante',
    translation: {
      'pt-BR': 'Nível Iniciante',
      es: 'Nivel Principiante',
    },
  },
  {
    key: 'Nível Intermediário',
    translation: {
      'pt-BR': 'Nível Intermediário',
      es: 'Nivel Intermedio',
    },
  },
  {
    key: 'Nível Avançado',
    translation: {
      'pt-BR': 'Nível Avançado',
      es: 'Nivel Avanzado',
    },
  },
  {
    key: 'Todos os Níveis',
    translation: {
      'pt-BR': 'Todos os Níveis',
      es: 'Todos los Niveles',
    },
  },
]

export const getProficiencyLevel = (key: string, language?: string) => {
  const proficiencyLevel = proficiencyLevels.find((level) => level.key === key)

  if (proficiencyLevel && language === 'es') {
    return proficiencyLevel?.translation.es
  }

  return proficiencyLevel?.translation['pt-BR']
}
