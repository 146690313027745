import { Box, Flex, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CertificateTypes, useCertificate } from '../../hooks/useCertificate'

export function CertificateTypeTabs() {
  const [t] = useTranslation()
  const { shouldIncludeTheseCertificateTypes, activeTab, handleUpdateActiveTab } = useCertificate()

  const tabs: {
    id: keyof CertificateTypes
    name: string
  }[] = [
    {
      id: 'customPlaylistModule',
      name: t('modules'),
    },
    {
      id: 'featuredPlaylist',
      name: t('featuredPlaylist'),
    },
    {
      id: 'customPlaylist',
      name: t('customPlaylist'),
    },
  ]

  useEffect(() => {
    if (!shouldIncludeTheseCertificateTypes[activeTab]) {
      const nextTab = tabs.find((tab) => shouldIncludeTheseCertificateTypes[tab.id as keyof CertificateTypes])
      if (nextTab) {
        handleUpdateActiveTab(nextTab.id)
      } else {
        handleUpdateActiveTab(tabs[0].id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldIncludeTheseCertificateTypes])

  const handleUpdateTab = (value: keyof CertificateTypes) => {
    if (shouldIncludeTheseCertificateTypes[value]) {
      handleUpdateActiveTab(value)
    }
  }

  return (
    <Flex gridGap='32px' borderBottom='2px solid #EFEFEF' position='relative' data-testid='cms-home-tabs'>
      {tabs.map((item) => (
        <Box
          key={item.id}
          onClick={() => handleUpdateTab(item.id)}
          cursor={shouldIncludeTheseCertificateTypes[item.id] ? 'pointer' : 'not-allowed'}
          borderBottom={activeTab === item.id ? '2px solid #009639' : ''}
          bottom='-1px'
          width='fit-content'
          data-testid={`certificate-tab-${item.id}`}
        >
          <Text
            color={activeTab === item.id ? 'black' : '#aaaaaa'}
            fontWeight={'700'}
            padding='8px'
            opacity={!shouldIncludeTheseCertificateTypes[item.id] ? 0.6 : 1}
          >
            {item.name}
          </Text>
        </Box>
      ))}
    </Flex>
  )
}
