import { Box, Flex } from '@chakra-ui/react'
import { Widget } from '@typeform/embed-react'
import { useLocation } from 'react-router-dom'
import { useFeedback } from 'src/context/FeedbackContext'

const FeedbackStep = () => {
  const location = useLocation()
  const { updateStep, userInfo } = useFeedback()

  return (
    <Flex flex={1} flexDir='column' alignItems='center' justifyContent='start'>
      <Flex flexDir='column' alignItems='center' flex={1} width='100%'>
        <Box flex={1} w='100%' mt='8px' pb='10px'>
          <Widget
            id={'HmiSVNvI'}
            hidden={{
              name: userInfo.name,
              company: userInfo.company!,
              area: userInfo.area,
              from: location.pathname,
            }}
            style={{ width: '100%', height: '550px' }}
            className='my-form'
            onSubmit={() => updateStep(1)}
          />
        </Box>
      </Flex>
    </Flex>
  )
}

export default FeedbackStep
