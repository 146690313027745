/* eslint-disable no-constant-condition */
import { Flex, Stack, Text } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useUser } from 'src/context/userContext'
import useRegisterUserEvent from 'src/hooks/useRegisterUserEvent'
import Content from 'src/modules/cms/domain/Content'
import Partner from 'src/modules/cms/domain/Partner'
import { useContents } from 'src/modules/cms/hooks/useContents'
import { usePartners } from 'src/modules/cms/hooks/usePartners'
import { CoursesListSkeleton, PartnerHeaderSkeleton } from 'src/pages/PartnerPage/Skeleton'
import { PageInfo } from 'src/types/PageInfo'
import Footer from '../../components/Footer'
import PaginatedCourses from '../../components/PaginatedCourses/PaginatedCourses'
import { useWindowSize } from '../../hooks/useWindowSize'
import PartnerHeader from './PartnerHeader'

const PartnerPage: React.FC = () => {
  const [t] = useTranslation()
  const { partnerSlug, partnerId } = useParams<{ partnerSlug: string; partnerId: string }>()
  const { user } = useUser()
  const windowSize = useWindowSize()
  const registerUserEvent = useRegisterUserEvent()
  const { getContentsByPartner } = useContents()
  const { getPartnerById } = usePartners()

  const [partner, setPartner] = useState<Partner | undefined>(undefined)
  const [courses, setCourses] = useState<Content[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo | undefined>({ currentStart: 0, totalEntries: 25 })
  const [currentPage, setCurrentPage] = useState(0)
  const [loadingPartners, setLoadingPartners] = useState(true)

  const isMobile = useMemo(() => !!windowSize?.width && windowSize.width < 992, [windowSize.width])

  const numberOfCardsRow = windowSize.width ? (windowSize.width >= 1700 ? 4 : 3) : 3
  const numberOfCourses = numberOfCardsRow * 5

  useEffect(() => {
    if (user && partnerId) {
      setLoadingPartners(true)

      getContentsByPartner(
        partnerId,
        {
          skip: currentPage * numberOfCourses,
          limit: isMobile ? 15 : currentPage * numberOfCourses + numberOfCourses,
        },
        user,
      )
        .then((data) => {
          setCourses(data.items)
          setPageInfo({ currentStart: 0, totalEntries: data.total })
        })
        .finally(() => setLoadingPartners(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, partnerId, currentPage, numberOfCourses])

  useEffect(() => {
    setCurrentPage(0)
    // eslint-disable-next-line
  }, [partnerSlug, partnerId])

  useEffect(() => {
    if (partner?.slug) registerUserEvent('access-partner-page', [partner?.slug])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner?.slug])

  useEffect(() => {
    if (partnerId) {
      getPartnerById(partnerId).then((value) => {
        setPartner(value)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerId])

  return (
    <Stack spacing={0} maxWidth='100%' minHeight='100%'>
      <Helmet>
        <title>{partner ? `${partner?.name} | `: ''}Education Journey</title>
      </Helmet>
      {loadingPartners ? (
        <>
          <PartnerHeaderSkeleton />
          <CoursesListSkeleton numberOfCardsRow={numberOfCardsRow} />
        </>
      ) : (
        <>
          <PartnerHeader partner={partner} />
          <Flex flex='1' padding={{ base: '0px 20px 40px', lg: '0px 72px' }}>
            {courses?.length > 0 ? (
              <PaginatedCourses
                courses={courses}
                pageInfo={pageInfo!}
                currentPage={currentPage}
                onChangeCurrentPage={setCurrentPage}
                numberOfCardsRow={numberOfCardsRow}
              />
            ) : (
              <Flex paddingTop='32px'>
                <Text color='bluishGray.500'>
                  {t('thereAreNoCoursesForThisPartner')}. {t('tryAgainLater')}
                </Text>
              </Flex>
            )}
          </Flex>
        </>
      )}
      {/* <Flex w='100%' h='100%'>
          <TryRequestAgain
            errorMessage={t('tryRequestAgain.partner')}
            functionToExecute={() => null}
            imageHeight={300}
          />
        </Flex> */}
      <Footer />
    </Stack>
  )
}

export default PartnerPage
