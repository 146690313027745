import { useQuery } from '@apollo/client'
import { Box, Flex, Skeleton, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useUser } from 'src/context/userContext'
import { GET_COMPANIES } from 'src/graphql/queries/GET_COMPANIES'
import { GET_PARTNERS } from 'src/modules/cms/graphql/queries/GET_PARTNERS'
import { ContentModal } from 'src/pages-admin/Cms/components/ContentModal'
import { useCorporateUniversityStore } from 'src/pages-admin/Cms/hooks/useCorporateUniversityStore'
import { useRegisterContentStore } from 'src/pages-admin/Cms/hooks/useRegisterContentStore'
import { cmsQueryContext } from 'src/services/apollo/ApolloClient'
import { CmsPartnersQuery } from 'src/types/Cms'
import { AllocateSkill } from './components/AllocateSkill'
import { PlaylistRegisterModal } from './components/PlaylistRegisterModal'
import { useAllocateSkillStore } from './hooks/useAllocateSkillStore'
import { useImportedContents } from './hooks/useImportedContents'
import { useRegisterPlaylistStore } from './hooks/useRegisterPlaylistStore'
import { CertificateTab } from './tabs/Certificate'
import { ContentTab } from './tabs/Content'
import { ImportedContentsTab } from './tabs/ImportedContents'
import { EditImportedContentModal } from './tabs/ImportedContents/components/EditImportedContentModal'
import { RejectImportedContentsModal } from './tabs/ImportedContents/components/RejectImportedContentModal'
import { PlaylistTab } from './tabs/Playlist'
import { SkillTab } from './tabs/Skill'
import { TokensTab } from './tabs/Tokens'
import { useCmsTabs } from './utils/cmsTabs'

export function Cms() {
  const [loadingCredentials, setLoadingCredentials] = useState(true)

  const { isRegisterModalOpen } = useRegisterContentStore()
  const { isRegisterPlaylistModalOpen } = useRegisterPlaylistStore()
  const { isAllocateSkillModalOpen } = useAllocateSkillStore()
  const { isEditImportedContentsModalOpen, isRejectImportedContentsModalOpen } = useImportedContents()
  const { updatePartnerList, updateCompanyList, companyList, activeTab, updateActiveTab, updateSearchValue } =
    useCorporateUniversityStore()

  const { role, user } = useUser()
  const history = useHistory()
  const isUserBackoffice = user?.roles?.includes('BACKOFFICE')

  const cmsTabs = useCmsTabs()

  const [t] = useTranslation()

  useQuery<CmsPartnersQuery>(GET_PARTNERS, {
    context: cmsQueryContext,
    onCompleted: (data) => {
      updatePartnerList(data?.partnerList?.items ?? [])
    },
  })

  useQuery(GET_COMPANIES, {
    variables: { limit: 100 },
    skip: companyList.length !== 0,
    onCompleted: (response) => {
      updateCompanyList(response.listCompanies.data)
    },
  })

  const renderTabContent = (value: string) => {
    const component: { [x: string]: JSX.Element } = {
      conteudos: <ContentTab tab={value} />,
      conteudosImportados: <ImportedContentsTab />,
      trilhas: <PlaylistTab tab={value} />,
      habilidades: <SkillTab tab={value} />,
      certificados: <CertificateTab />,
      tokens: <TokensTab />,
    }

    return component[value]
  }

  useEffect(() => {
    if (user) {
      const isUserAdmin = user?.roles?.includes('ADMIN')

      if (user && !isUserBackoffice && !isUserAdmin) {
        history.replace(role === 'MANAGER' ? '/admin/dashboard' : '/admin/home')
      }

      setLoadingCredentials(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, history, user])

  if (loadingCredentials)
    return (
      <Flex flexDir='column' w='100%' h='100%' gap='16px' px='48px' pb='48px'>
        <Flex w='100px' h='80px' />
        <Skeleton w='100%' maxW='500px' h='30px' borderRadius='16px' />
        <Skeleton w='100%' maxW='500px' h='76px' borderRadius='16px' />
        <Skeleton w='100%' maxW='500px' h='64px' borderRadius='16px' />
        <Skeleton w='100%' maxW='500px' h='54px' borderRadius='16px' />
        <Skeleton w='100%' flex='1' h='100%' borderRadius='16px' />
      </Flex>
    )

  return (
    <Flex
      padding='0px 56px'
      flexDir='column'
      flex={1}
      data-testid='cms-home-wrapper'
      pos='relative'
      overflowY={isRegisterModalOpen || isRegisterPlaylistModalOpen ? 'hidden' : 'auto'}
    >
      <Helmet>
        <title>{t('admin.home.dashboard.title')} | Education Journey</title>
      </Helmet>

      <Flex gridGap='32px' mb='24px' borderBottom='2px solid #EFEFEF' position='relative' data-testid='cms-home-tabs'>
        {cmsTabs?.map((item, index) => {
          const couldUserSeeTheTab = user?.roles?.includes(String(item.roleNeeded))
          if (!couldUserSeeTheTab) return null
          return (
            <Box
              key={item.id}
              onClick={() => {
                if (!item.isDisabled) updateActiveTab(item.id)
                updateSearchValue('')
              }}
              cursor={item.isDisabled ? 'not-allowed' : 'pointer'}
              borderBottom={activeTab === item.id ? '2px solid #009639' : ''}
              bottom='-1px'
              width='fit-content'
              data-testid={`cms-home-tab-${index}`}
              display={!isUserBackoffice && item.id === 'habilidades' ? 'none' : 'block'}
            >
              <Text color={activeTab === item.id ? 'black' : '#aaaaaa'} fontWeight={'700'} padding='8px'>
                {item.name}
              </Text>
            </Box>
          )
        })}
      </Flex>
      <Flex flex={1}>{renderTabContent(activeTab)}</Flex>

      {/* Register contents and playlists */}
      {isRegisterModalOpen ? <ContentModal /> : null}
      {isRegisterPlaylistModalOpen ? <PlaylistRegisterModal /> : null}

      {/* Allocate skill */}
      {isAllocateSkillModalOpen ? <AllocateSkill /> : null}

      {/* Imported contents */}
      {isEditImportedContentsModalOpen ? <EditImportedContentModal /> : null}
      {isRejectImportedContentsModalOpen ? <RejectImportedContentsModal /> : null}
    </Flex>
  )
}
