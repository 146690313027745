import { Flex, Heading, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import NewPlaylist from 'src/modules/cms/domain/Playlist'

interface Props {
  playlist: NewPlaylist
}

const LinkedinCertificateSection: React.FC<Props> = ({ playlist }: Props) => {
  const [t] = useTranslation()
  playlist = playlist!

  return (
    <Flex
      padding={{ base: '33px 32px', xl: '33px 104px' }}
      marginTop={{ base: '32px', xl: '46px' }}
      background='gray.45'
      alignItems='center'
      justifyContent='space-between'
      direction={{ base: 'column', xl: 'row' }}
      gap={{ base: '16px', xl: '0px' }}
    >
      <Flex flexDirection='column'>
        <Flex
          alignItems={{ base: 'center' }}
          direction={{ base: 'column', xl: 'row' }}
          gap={{ base: '16px', xl: '0px' }}
        >
          <Heading size='sm'>{t('playlist.Shareable in')}</Heading>
          <Image w='200px' src='/assets/images/linkedin.png' marginLeft='20px' />
        </Flex>
        <Text marginTop='29px' maxWidth='456px' lineHeight='24px'>
          {t('playlist.You can share your Course Certificates in the Certificates section of your LinkedIn profile')}
        </Text>
      </Flex>

      <Image
        w='421px'
        h='300px'
        objectFit={{ base: 'contain', xl: 'unset' }}
        marginLeft={{ base: '0px', xl: '20px' }}
        src={playlist.certificateImageUrl ?? 'https://i.imgur.com/yFhbwRW.png'}
      />
    </Flex>
  )
}

export default LinkedinCertificateSection
