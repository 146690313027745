import { ContentRepository } from 'src/modules/cms/repositories/ContentRepository'

interface DeleteContentUseCaseInput {
  contentId: string
}

export type DeleteContentUseCaseOutput = boolean

export class DeleteContentUseCase {
  constructor(private contentRepository: ContentRepository) {}

  async execute({ contentId }: DeleteContentUseCaseInput): Promise<DeleteContentUseCaseOutput> {
    const hasDeleted = await this.contentRepository.delete(contentId)
    return hasDeleted
  }
}
