import { gql } from '@apollo/client'
import { apolloClient } from 'src/services/apollo/ApolloClient'
import ApiError, { NetWorkError } from 'src/shared/ApiError'
import { logger } from 'src/shared/Logger'
import { UserStatusEnum } from '../domain/user'
import { mapIamErrors } from '../errors'

const USER_STATUS = gql`
  query UserStatus($email: String!) {
    userStatus(email: $email) {
      status
      sso_redirect_url
    }
  }
`

export type Data = {
  statusCode: UserStatusEnum[]
  ssoRedirectUrl: string
}

export type Props = { email: string }

export const verifyUserByEmailApi = (props: Props): Promise<Data> =>
  apolloClient
    .query({
      query: USER_STATUS,
      variables: props,
    })
    .then(async ({ data, errors }) => {
      if (errors?.length) {
        const code = errors[0].message
        const message = ''
        throw new ApiError(message, mapIamErrors(code))
      }
      return {
        statusCode: data.userStatus.status,
        ssoRedirectUrl: data.userStatus?.sso_redirect_url
      }
    })
    .catch((error) => {
      logger.error('verify user api', { error })
      if (error instanceof ApiError) throw error
      throw new NetWorkError()
    })
