export const getFlagIcon = (language: string) => {
  const flag: { [x: string]: string } = {
    'pt-BR': '/assets/images/br_flag.png',
    'en-US': '/assets/images/en_flag.png',
    es: '/assets/images/es_flag.png',
  }

  return flag[language ?? 'pt-BR']
}

export const dateNumberToString = (dateNumber: number) => {
  const hours = Math.floor(dateNumber)
  const minutes = Math.round((dateNumber - hours) * 60)
  return `${hours}h ${minutes}min`
}
