export function convertSecondsToMinutesAndHours(seconds: number) {
  const minutes = Math.floor(seconds / 60)

  if (minutes < 60) {
    return minutes + ' minutos'
  } else {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60
    return hours + 'h ' + remainingMinutes + 'm'
  }
}
