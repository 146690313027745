import { useMutation } from '@apollo/client'
import { REGISTER_USER_EVENT } from 'src/graphql/mutations/REGISTER_USER_EVENT'

interface ReturnRegisterUserEvent {
  RegisterUserEvent: boolean
}

const useRegisterUserEvent = () => {
  const [registerUserEvent] = useMutation<ReturnRegisterUserEvent>(REGISTER_USER_EVENT)

  const register = (event: string, attributes: string[]) => {
    registerUserEvent({ variables: { event, attributes } })
  }

  return register
}

export default useRegisterUserEvent
