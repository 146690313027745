// Steps
// Create New Team
import { StepWithElement } from 'src/types/HandleTeamDrawer'
import { AddNewTeam } from './steps/AddNewTeam'
import { AddNewTeamConfirmTeamInfo } from './steps/AddNewTeamConfirmTeamInfo'
import { AddNewTeamSelectManagers } from './steps/AddNewTeamSelectManagers'
import { AddNewTeamSelectUsers } from './steps/AddNewTeamSelectUsers'
import { EditExistingTeamAddNewManager } from './steps/EditExistingTeamAddNewManager'
import { EditExistingTeamAddNewUsers } from './steps/EditExistingTeamAddNewUsers'
import { EditExistingTeamAddNewUsersConfirm } from './steps/EditExistingTeamAddNewUsersConfirm'
import { EditExistingTeamChangeManager } from './steps/EditExistingTeamChangeManager'
import { EditExistingTeamChangeManagerConfirm } from './steps/EditExistingTeamChangeManagerConfirm'
import { EditExistingTeamChangeManagerSelectNew } from './steps/EditExistingTeamChangeManagerSelectNew'
import { EditExistingTeamConfirmNewManager } from './steps/EditExistingTeamConfirmNewManager'
import { EditExistingTeamDeleteTeam } from './steps/EditExistingTeamDeleteTeam'
import { EditExistingTeamRemoveManager } from './steps/EditExistingTeamRemoveManager'
import { EditExistingTeamRemoveUsers } from './steps/EditExistingTeamRemoveUsers'
import { EditExistingTeamRemoveUsersConfirm } from './steps/EditExistingTeamRemoveUsersConfirm'
import { EditExistingTeamSelectOptions } from './steps/EditExistingTeamSelectOptions'
import { EditExistingTeamSelectTeam } from './steps/EditExistingTeamSelectTeam'
import { SuccessStep } from './steps/Success'

export const availableSteps: StepWithElement = {
  // main options
  'success-screen': <SuccessStep />,
  // new team
  'add-new-team': <AddNewTeam />,
  'add-new-team--select-managers': <AddNewTeamSelectManagers />,
  'add-new-team--select-users': <AddNewTeamSelectUsers />,
  'add-new-team--confirm-team-info': <AddNewTeamConfirmTeamInfo />,
  // edit team
  'edit-existing-team': <EditExistingTeamSelectTeam />,
  'edit-existing-team--select-options': <EditExistingTeamSelectOptions />,
  'edit-existing-team--remove-manager': <EditExistingTeamRemoveManager />,
  'edit-existing-team--add-new-manager': <EditExistingTeamAddNewManager />,
  'edit-existing-team--confirm-new-manager': <EditExistingTeamConfirmNewManager />,
  'edit-existing-team--add-new-users': <EditExistingTeamAddNewUsers />,
  'edit-existing-team--add-new-users-confirm': <EditExistingTeamAddNewUsersConfirm />,
  'edit-existing-team--change-manager': <EditExistingTeamChangeManager />,
  'edit-existing-team--change-manager-select-new': <EditExistingTeamChangeManagerSelectNew />,
  'edit-existing-team--change-manager-confirm': <EditExistingTeamChangeManagerConfirm />,
  'edit-existing-team--remove-users': <EditExistingTeamRemoveUsers />,
  'edit-existing-team--remove-users-confirm': <EditExistingTeamRemoveUsersConfirm />,
  'edit-existing-team--delete-team': <EditExistingTeamDeleteTeam />,
}
