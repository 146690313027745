import { LazyQueryExecFunction, OperationVariables, useLazyQuery } from '@apollo/client'
import { useDisclosure } from '@chakra-ui/react'
import _ from 'lodash'
import { createContext, useContext, useEffect, useState } from 'react'
import LIST_USERS from 'src/graphql/queries/LIST_USERS'
import { makeListAreas } from 'src/modules/dashboard/factory/makeListAreas'
import { ListAreasOutput } from 'src/modules/dashboard/repository/AreaRepository'
import { availableSteps } from 'src/pages-admin/Home/components/DrawerHandleArea/availableSteps'
import { AvailableStepsType, StepWithElement } from 'src/types/HandleAreaDashboard'
import { User } from 'src/types/User'

export type AreaFromApi = {
  id: number
  managerId: number
  name: string
}

export type AreaUser = {
  id?: string
  name?: string
  email?: string
  companyIdentifier?: string
  languageCode?: string
}

export type Area = {
  id?: string
  name?: string
  manager?: User
  newManager?: User
  users?: User[]
  newUsers?: User[]
}

type PageInfo = {
  count: number
  hasNextPage: boolean
  hasPreviousPage: boolean
  next: string
  total: number
}

type ListUsersQuery = {
  listUsers: {
    data: User[]
    paginateInfo: PageInfo
  }
}

type Filters = {
  area?: number
  userName?: string
}

interface HandleAreaContextProps {
  // navigation with steps
  availableSteps: StepWithElement
  activeStep: AvailableStepsType
  navigateToStep: (stepId: AvailableStepsType) => void
  goToFirstStep: () => void
  // Disclosure drawer
  isHandleAreaDrawerOpen: boolean
  onCloseHandleAreaDrawer: () => void
  onOpenHandleAreaDrawer: () => void
  // area
  area?: Area
  areas: AreaFromApi[]
  handleSetAreaValues: (area?: Area) => void
  getAreas: (page?: number, limit?: number) => Promise<ListAreasOutput | undefined>
  loadingAreas: boolean
  // final screen
  finalMessage: string
  handleFinalMessage: (message: string) => void
  // users
  usersList: User[]
  loadingUsers: boolean
  pageInfo?: PageInfo
  getUsersList: LazyQueryExecFunction<ListUsersQuery, OperationVariables>
  fetchMoreUsers: () => Promise<void>
  increaseUsersList: (users: User[]) => void
  definesUsersList: (users?: User[]) => void
  // users filters
  filters: Filters
  definesUserNameFilter: (userName: string) => void
  definesAreaFilter: (area: number) => void
}

export const areaWithEmptyValues: Area = {
  id: undefined,
  manager: undefined,
  newManager: undefined,
  name: undefined,
  users: undefined,
  newUsers: undefined,
}

const HandleAreaContext = createContext({} as HandleAreaContextProps)

export const HandleAreaProvider: React.FC = ({ children }) => {
  // area
  const [loadingAreas, setLoadingAreas] = useState(false)
  const [areas, setAreas] = useState<AreaFromApi[]>([])
  const [activeStep, setActiveStep] = useState<AvailableStepsType>('choose-options')
  const [area, setArea] = useState<Area>(areaWithEmptyValues)
  const [finalMessage, setFinalMessage] = useState<string>('')
  // user
  const [usersList, setUsersList] = useState<User[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo | undefined>(undefined)
  const [filters, setFilters] = useState<Filters>({ area: 0, userName: '' })

  const {
    isOpen: isHandleAreaDrawerOpen,
    onClose: onCloseHandleAreaDrawer,
    onOpen: onOpenHandleAreaDrawer,
  } = useDisclosure()

  const increaseUsersList = (users: User[]) => {
    setUsersList((oldUsers) => {
      const uniqueUsers = _.uniqBy([...oldUsers, ...users], 'id')
      return uniqueUsers
    })
  }

  const definesUsersList = (users?: User[]) => {
    if (!users || users?.length <= 0) return []
    const uniqueUsers = _.uniqBy(users, 'id')
    setUsersList(uniqueUsers ?? [])
  }

  const [getUsersList, { refetch: refetchUsers, loading: loadingUsers }] = useLazyQuery<ListUsersQuery>(LIST_USERS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: 25,
      // eslint-disable-next-line no-extra-boolean-cast
      areaId: !!filters.area ? filters.area : 0,
      userName: filters.userName,
      ...(filters.userName ? { name: filters.userName } : { name: '' }),
    },
    onCompleted: (data) => {
      definesUsersList(data.listUsers.data)
      setPageInfo(data.listUsers.paginateInfo)
    },
  })

  const navigateToStep = (stepId: AvailableStepsType) => {
    setActiveStep(stepId)
  }

  const goToFirstStep = async () => {
    setActiveStep('choose-options')
    setArea(areaWithEmptyValues)
    setFinalMessage('')
    setFilters({ area: 0, userName: '' })
    setPageInfo({ count: 0, hasNextPage: false, hasPreviousPage: false, next: '', total: 0 })
    setUsersList([])
    const areas = await getAreas(undefined, 500)
    setAreas(areas?.items ?? [])
  }

  const handleSetAreaValues = (newAreaValues?: Area) => {
    setArea((oldAreaValues) => ({ ...oldAreaValues, ...newAreaValues }))
  }

  const handleFinalMessage = (message: string) => {
    setFinalMessage(message)
  }

  const fetchMoreUsers = async () => {
    if (!pageInfo?.next) return

    const response = await refetchUsers({
      limit: 25,
      cursor: Number(pageInfo?.next),
    })

    increaseUsersList(response.data.listUsers.data)
    setPageInfo(response.data.listUsers.paginateInfo)
  }

  const definesUserNameFilter = (userName: string) => {
    setFilters((oldFilters) => ({ ...oldFilters, userName }))
  }

  const definesAreaFilter = (area: number) => {
    setFilters((oldFilters) => ({ ...oldFilters, area }))
  }

  const getAreas = async (page?: number, limit?: number) => {
    try {
      const listAreas = makeListAreas()
      setLoadingAreas(true)
      const areas = await listAreas.execute({ 
        ...(page ? { page } : {}),
        ...(limit ? { limit } : {})
      })
      return areas
    } catch (err) {
      console.error('Ocorreu um erro ao buscar as áreas')
    } finally {
      setLoadingAreas(false)
    }
  }

  useEffect(() => {
    refetchUsers({
      limit: 25,
      // eslint-disable-next-line no-extra-boolean-cast
      areaId: !!filters.area ? filters.area : 0,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.area])

  return (
    <HandleAreaContext.Provider
      value={{
        // steps
        availableSteps,
        navigateToStep,
        goToFirstStep,
        activeStep,
        // drawer
        isHandleAreaDrawerOpen,
        onCloseHandleAreaDrawer,
        onOpenHandleAreaDrawer,
        // area
        area,
        areas,
        handleSetAreaValues,
        getAreas,
        loadingAreas,
        // final screen
        finalMessage,
        handleFinalMessage,
        // users
        usersList,
        loadingUsers,
        pageInfo,
        getUsersList,
        fetchMoreUsers,
        increaseUsersList,
        definesUsersList,
        // users filters
        filters,
        definesUserNameFilter,
        definesAreaFilter,
      }}
    >
      {children}
    </HandleAreaContext.Provider>
  )
}

export const useHandleArea = () => {
  const context = useContext(HandleAreaContext)
  if (!context) throw new Error('useHandleArea must be used within a HandleAreaProvider')
  return context
}
