import { gql } from "@apollo/client";

export const GET_PARTNERS = gql`
  query partnerList($privateList: Boolean) {
    partnerList(privateList: $privateList) {
      total
      items {
        id
        name
        slug
        squareLogoUrl
        logoUrl
        description
        coverColor
      }
    }
  }
`;
