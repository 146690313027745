import { Box, Flex, FormControl, FormLabel, Input, Select, Tooltip } from '@chakra-ui/react'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { IoHelpCircle } from 'react-icons/io5'
import { useCorporateUniversityStore } from 'src/pages-admin/Cms/hooks/useCorporateUniversityStore'
import { IContentData } from 'src/pages-admin/Cms/hooks/useRegisterContentStore'
import { CmsContentType } from 'src/types/Cms'

type Props = {
  form: FormikProps<IContentData>
}

export function DynamicFields({ form }: Props) {
  const [t] = useTranslation()
  const { partnerList } = useCorporateUniversityStore()

  const isCourse = form.values.type === CmsContentType.COURSE
  const isCourseOrVideo = form.values.type === CmsContentType.COURSE || form.values.type === CmsContentType.VIDEO

  return (
    <>
      {isCourse && (
        <>
          <FormControl id='trailer' isInvalid={!!form?.errors?.trailer && form?.touched?.trailer}>
            <Flex align='center'>
              <FormLabel
                htmlFor='trailer'
                fontFamily='Mulish'
                fontWeight='700'
                color='gray.800'
                fontSize='16px'
                marginBottom='2px'
              >
                {t('trailerLink')}
              </FormLabel>
              <Tooltip hasArrow placement='right' fontSize='12px' label={t('theUrlMustBeYoutubeOrVimeo')}>
                <Box mb='2px'>
                  <IoHelpCircle size={20} color='#767F89' />
                </Box>
              </Tooltip>
            </Flex>
            <Input
              type='text'
              name='trailer'
              borderRadius='16px'
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.trailer}
            />
          </FormControl>
        </>
      )}

      {isCourseOrVideo && (
        <>
          <FormControl id='partner' w='49%'>
            <FormLabel
              htmlFor='partner'
              fontFamily='Mulish'
              fontWeight='700'
              color='gray.800'
              fontSize='16px'
              marginBottom='2px'
            >
              {t('partner')}
            </FormLabel>

            <Select
              name='partner'
              borderRadius='16px'
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.partner}
            >
              <option selected hidden disabled value=''></option>
              {partnerList.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </Select>
          </FormControl>
        </>
      )}
    </>
  )
}
