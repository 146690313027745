import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Textarea,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import { FormikProps } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCloudUpload, IoHelpCircle } from 'react-icons/io5'
import ReactSelect, { OptionTypeBase, ValueType } from 'react-select'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { makeGetTranslationByLanguage } from 'src/modules/internationalization/use-case/GetTranslationByLanguageUseCase'
import { IContentData } from 'src/pages-admin/Cms/hooks/useRegisterContentStore'
import { formatTime } from 'src/pages-admin/Cms/utils/formatTime'
import { MultiSelectStyles } from 'src/pages-admin/Cms/utils/multiSelectStyles'
import { useCorporateUniversityStore } from '../../hooks/useCorporateUniversityStore'
import { renderRequiredLabel } from '../../utils/renderRequiredLabel'
import { availableLanguages, availableSubtitles, formatInitialLanguagesValue } from './availableLanguages'

type Props = {
  form: FormikProps<IContentData>
}

export interface CmsSkills {
  id: string
  name: string
  __typename: string
}

interface MultiLanguageInfo {
  [key: string]: {
    title?: string
    description?: string
  }
}

export function RequiredFields({ form }: Props) {
  const { selectedContent } = useCorporateUniversityStore()
  const [selectedLanguages, setSelectedLanguages] = useState<typeof availableLanguages>(
    formatInitialLanguagesValue(form.values.languages),
  )
  const [selectedSubtitles, setSelectedSubtitles] = useState<typeof availableSubtitles>(
    formatInitialLanguagesValue(form.values.subtitles, true),
  )
  const [multiLanguageInfo, setMultiLanguageInfo] = useState<MultiLanguageInfo>({})
  const [loadingTranslation, setLoadingTranslation] = useState(false)

  const [t] = useTranslation()
  const toast = useToast()

  const { allSkills, getAllCmsSkills } = useCustomPlaylistQuiz()
  const skills = allSkills.map((item) => ({ value: item.id, label: item.name }))

  const handleDurationFieldBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    const { value } = event.target

    if (!value) form.handleBlur(event)

    const formattedValue = formatTime(value)

    let updatedValue = ''

    if (formattedValue.length === 1) {
      updatedValue = `00:0${formattedValue}`
    } else if (formattedValue.length === 2) {
      updatedValue = `00:${formattedValue}`
    } else if (formattedValue.length >= 3) {
      const hours = formattedValue.slice(0, 2)
      const minutes = formattedValue.slice(2).padEnd(2, '0')
      updatedValue = `${hours}:${minutes}`
    }

    form.setFieldValue('duration', updatedValue.replace(/::+/g, ':'))
  }

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    let formattedValue = formatTime(value)

    if (formattedValue === '00:00') {
      formattedValue = '00:01'
    }

    form.setFieldValue('duration', formattedValue.replace(/::+/g, ':'))
  }

  const handleSkillsChange = (values: ValueType<OptionTypeBase, true>) => {
    form.setFieldValue('skills', values)
  }

  const handleLanguagesChange = (values: ValueType<OptionTypeBase, true>) => {
    form.setFieldValue(
      'languages',
      values.map((value) => value.value),
    )

    const parsedValues = values.map((value) => ({ value: value.value, label: value.label }))
    setSelectedLanguages(parsedValues)
  }

  const handleSubtitlesChange = (values: ValueType<OptionTypeBase, true>) => {
    const subtitles = values
    const subtitlesToSet: any[] = []
    if (subtitles.some((sub) => sub.value === 'no-subtitle')) {
      subtitlesToSet.push(...subtitles.filter((sub) => sub.value === 'no-subtitle'))
    } else {
      subtitlesToSet.push(...subtitles)
    }
    form.setFieldValue(
      'subtitles',
      subtitlesToSet.map((sub) => sub.value),
    )
    setSelectedSubtitles(subtitlesToSet.map((sub) => ({ value: sub.value, label: sub.label })))
  }

  const handleSkillsBlur = () => {
    form.setFieldTouched('skills', true)
  }

  const handleLanguagesBlur = () => {
    form.setFieldTouched('languages', true)
  }

  const handleSubtitlesBlur = () => {
    form.setFieldTouched('languages', true)
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    form.setFieldValue('cover', file)
  }

  const selectedSubtitleValue = selectedSubtitles.some((option) => option.value === 'no-subtitle')
    ? [{ value: 'no-subtitle', label: 'Sem legenda' }]
    : selectedSubtitles

  const handleInputMultiLanguage = (language: string, prop: string, value: string) => {
    setMultiLanguageInfo((prev) => ({ ...prev, [language]: { ...prev[language], [prop]: value } }))
  }

  const handleTranslateText = async (language: string) => {
    if (!form?.values?.title && !form?.values?.description) return
    try {
      setLoadingTranslation(true)
      const translateText = makeGetTranslationByLanguage()
      const [titleTranslated, descriptionTranslated] = await Promise.all([
        translateText.execute(language, form.values.title ?? ''),
        translateText.execute(language, form.values.description ?? ''),
      ])
      setMultiLanguageInfo((prev) => ({
        ...prev,
        [language]: { ...prev[language], title: titleTranslated, description: descriptionTranslated },
      }))
      toast({
        title: t('theTranslationsWasGeneratedSuccessfully'),
        status: 'success',
        duration: 3000,
      })
    } catch {
      console.error('Error translating text')
      toast({
        title: t('anErrorOccuredWhenGeneratingTranslations'),
        status: 'error',
        duration: 3000,
      })
    } finally {
      setLoadingTranslation(false)
    }
  }

  useEffect(() => {
    getAllCmsSkills({ variables: { limit: 500, skip: 0 } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const parsed = Object.keys(multiLanguageInfo).reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: {
          title: multiLanguageInfo[curr].title,
          description: multiLanguageInfo[curr].description,
        },
      }
    }, {})
    form.setFieldValue('contentTranslation', parsed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiLanguageInfo])

  return (
    <>
      <FormControl id='url' isInvalid={!!form.errors.url && form.touched.url}>
        <FormLabel
          htmlFor='url'
          fontFamily='Mulish'
          fontWeight='700'
          color='gray.800'
          fontSize='16px'
          marginBottom='2px'
        >
          {renderRequiredLabel(t('contentUrl'))}
        </FormLabel>
        <Input
          type='text'
          name='url'
          borderRadius='16px'
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.url}
        />
      </FormControl>

      <Flex gap='16px'>
        <FormControl id='type' isInvalid={!!form.errors.type && form.touched.type}>
          <FormLabel
            htmlFor='type'
            fontFamily='Mulish'
            fontWeight='700'
            color='gray.800'
            fontSize='16px'
            marginBottom='2px'
          >
            {renderRequiredLabel(t('contentType'))}
          </FormLabel>
          <Select
            name='type'
            borderRadius='16px'
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.type}
          >
            <option selected hidden disabled value=''></option>
            <option value='COURSE' style={{ textTransform: 'capitalize' }}>
              {t('common.card.course')}
            </option>
            <option value='ARTICLE' style={{ textTransform: 'capitalize' }}>
              {t('common.card.article')}
            </option>
            <option value='VIDEO' style={{ textTransform: 'capitalize' }}>
              {t('common.card.video')}
            </option>
          </Select>
        </FormControl>

        <FormControl id='duration' isInvalid={!!form.errors.duration && form.touched.duration}>
          <Flex align='center'>
            <FormLabel
              htmlFor='duration'
              fontFamily='Mulish'
              fontWeight='700'
              color='gray.800'
              fontSize='16px'
              marginBottom='2px'
            >
              {renderRequiredLabel(t('duration'))}
            </FormLabel>
            <Tooltip hasArrow placement='right' fontSize='12px' label={`${t('theDurationIsInFormat')} (hh:mm)`}>
              <Box mb='2px'>
                <IoHelpCircle size={20} color='#767F89' />
              </Box>
            </Tooltip>
          </Flex>

          <Input
            type='text'
            name='duration'
            borderRadius='16px'
            placeholder='00h:00min'
            onChange={handleTimeChange}
            onBlur={handleDurationFieldBlur}
            value={form.values.duration}
            maxLength={5}
          />
        </FormControl>
      </Flex>

      <Flex borderWidth='2px' borderRadius='16px' p='8px' borderColor='#e5e5e5  ' w='100%'>
        <Tabs defaultIndex={0} position='relative' w='100%'>
          <TabList>
            <Tab _selected={{ color: 'green.500', fontWeight: 700, borderColor: 'green.500' }}>Português</Tab>
            <Tab _selected={{ color: 'green.500', fontWeight: 700, borderColor: 'green.500' }}>Español</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <FormControl id='title' isInvalid={!!form.errors.title && form.touched.title}>
                <Flex align='center'>
                  <FormLabel
                    htmlFor='title'
                    fontFamily='Mulish'
                    fontWeight='700'
                    color='gray.800'
                    fontSize='16px'
                    marginBottom='2px'
                  >
                    {renderRequiredLabel(t('title'))}
                  </FormLabel>
                  <Tooltip hasArrow placement='right' fontSize='12px' label='Ideal que tenha no máximo 70 caracteres'>
                    <Box mb='2px'>
                      <IoHelpCircle size={20} color='#767F89' />
                    </Box>
                  </Tooltip>
                </Flex>
                <Input
                  type='text'
                  name='title'
                  borderRadius='16px'
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.title}
                />
              </FormControl>
              <FormControl id='description' isInvalid={!!form.errors.description && form.touched.description} mt='24px'>
                <FormLabel
                  htmlFor='description'
                  fontFamily='Mulish'
                  fontWeight='700'
                  color='gray.800'
                  fontSize='16px'
                  marginBottom='2px'
                >
                  {renderRequiredLabel(t('description'))}
                </FormLabel>
                <Textarea
                  borderRadius='16px'
                  border='2px solid'
                  borderColor='#E5E5E5'
                  resize='none'
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.description}
                  boxShadow='none !important'
                  _hover={{ borderColor: 'gray.800' }}
                  _active={{ borderColor: 'gray.800' }}
                  _focus={{ borderColor: 'gray.800' }}
                />
                <Button
                  isLoading={loadingTranslation}
                  variant='startCourseDark'
                  height='36px'
                  mt='12px'
                  py='0px'
                  fontSize='0.75rem'
                  ml='auto'
                  display='flex'
                  onClick={() => handleTranslateText('es')}
                >
                  Traduzir
                </Button>
              </FormControl>
            </TabPanel>
            <TabPanel>
              <Flex align='center'>
                <FormLabel
                  htmlFor='title-es'
                  fontFamily='Mulish'
                  fontWeight='700'
                  color='gray.800'
                  fontSize='16px'
                  marginBottom='2px'
                >
                  {t('title')}
                </FormLabel>
                <Tooltip hasArrow placement='right' fontSize='12px' label='Ideal que tenha no máximo 70 caracteres'>
                  <Box mb='2px'>
                    <IoHelpCircle size={20} color='#767F89' />
                  </Box>
                </Tooltip>
              </Flex>
              <Input
                type='text'
                id='title-es'
                defaultValue={selectedContent?.translations?.find((t) => t?.language === 'es')?.title ?? ''}
                borderRadius='16px'
                onChange={(e) => handleInputMultiLanguage('es', 'title', e.target.value)}
                value={multiLanguageInfo?.['es']?.title}
              />
              <FormLabel
                mt='24px'
                htmlFor='description-es'
                fontFamily='Mulish'
                fontWeight='700'
                color='gray.800'
                fontSize='16px'
                marginBottom='2px'
              >
                {t('description')}
              </FormLabel>
              <Textarea
                id='description-es'
                borderRadius='16px'
                border='2px solid'
                borderColor='#E5E5E5'
                resize='none'
                onChange={(e) => handleInputMultiLanguage('es', 'description', e.target.value)}
                defaultValue={selectedContent?.translations?.find((t) => t?.language === 'es')?.description ?? ''}
                value={multiLanguageInfo?.['es']?.description}
                boxShadow='none !important'
                _hover={{ borderColor: 'gray.800' }}
                _active={{ borderColor: 'gray.800' }}
                _focus={{ borderColor: 'gray.800' }}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>

      <FormControl id='skills' isInvalid={!!form.errors.skills && !!form.touched.skills}>
        <FormLabel
          htmlFor='skills'
          fontFamily='Mulish'
          fontWeight='700'
          color='gray.800'
          fontSize='16px'
          marginBottom='2px'
        >
          {renderRequiredLabel(t('relatedSkill'))}
        </FormLabel>
        <ReactSelect
          isMulti
          isClearable={false}
          styles={MultiSelectStyles(form)}
          value={skills.filter((item) => form.values.skills?.some((skill) => skill.value === item.value))}
          placeholder=''
          components={{ IndicatorSeparator: () => null }}
          maxMenuHeight={220}
          onChange={handleSkillsChange}
          onBlur={handleSkillsBlur}
          options={skills}
          menuPortalTarget={document.body}
        />
      </FormControl>

      <Flex gap='16px'>
        <FormControl id='languages' isInvalid={!!form.errors.languages && !!form.touched.languages}>
          <FormLabel
            htmlFor='languages'
            fontFamily='Mulish'
            fontWeight='700'
            color='gray.800'
            fontSize='16px'
            marginBottom='2px'
          >
            {renderRequiredLabel(t('language'))}
          </FormLabel>
          <ReactSelect
            isMulti
            isClearable={false}
            styles={MultiSelectStyles(form)}
            value={selectedLanguages}
            defaultValue={availableLanguages.find((item) => item.value === 'pt-BR')}
            placeholder=''
            components={{ IndicatorSeparator: () => null }}
            maxMenuHeight={220}
            onChange={handleLanguagesChange}
            onBlur={handleLanguagesBlur}
            options={availableLanguages}
          />
        </FormControl>

        <FormControl id='subtitles' isInvalid={!!form.errors.subtitles && !!form.touched.subtitles}>
          <FormLabel
            htmlFor='subtitles'
            fontFamily='Mulish'
            fontWeight='700'
            color='gray.800'
            fontSize='16px'
            marginBottom='2px'
          >
            {renderRequiredLabel(t('subtitle'))}
          </FormLabel>
          <ReactSelect
            isMulti
            isClearable={false}
            styles={MultiSelectStyles(form)}
            value={selectedSubtitleValue}
            defaultValue={availableSubtitles.find((item) => item.value === 'pt-BR')}
            placeholder=''
            components={{ IndicatorSeparator: () => null }}
            maxMenuHeight={220}
            onChange={handleSubtitlesChange}
            onBlur={handleSubtitlesBlur}
            options={
              selectedSubtitles.some((option) => option.value === 'no-subtitle')
                ? [{ value: 'no-subtitle', label: 'Sem legenda' }]
                : availableSubtitles
            }
          />
        </FormControl>
      </Flex>
      <FormControl id='cover' isInvalid={!!form.errors.cover && form.touched.cover}>
        <FormLabel
          htmlFor='cover'
          fontFamily='Mulish'
          fontWeight='700'
          color='gray.800'
          fontSize='16px'
          marginBottom='2px'
        >
          {renderRequiredLabel(t('contentCover'))}
        </FormLabel>
        <Flex align='center'>
          <Input
            type='file'
            id='cover'
            name='cover'
            accept='.png,.svg,.jpg,.webp'
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <InputGroup>
            <Input
              type='text'
              value={typeof form.values.cover === 'string' ? form.values.cover : form.values.cover?.name}
              title={typeof form.values.cover === 'string' ? form.values.cover : form.values.cover?.name}
              readOnly
              onBlur={form.handleBlur}
            />
            <InputRightElement cursor='pointer' onClick={() => document.getElementById('cover')?.click()}>
              <IoCloudUpload color='#767F89' />
            </InputRightElement>
          </InputGroup>
        </Flex>
      </FormControl>
    </>
  )
}
