import { Select } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Team } from 'src/types/Team'

interface Props {
  team: string
  teams: Team[]
  handleTeamChange: (team: string) => void
}

const TeamSelect: React.FC<Props> = ({ team, teams, handleTeamChange }: Props) => {
  const [t] = useTranslation()
  return (
    <Select
      value={team}
      onChange={(e) => handleTeamChange(e.target.value)}
      bgColor='white'
      border='2px solid #767F89'
      _hover={{ borderColor: '#313E4C' }}
      fontWeight={600}
      fontSize='0.75rem'
      height='36px'
      width='fit-content'
      minW='131px'
      sx={{ color: '#767F89' }}
    >
      <option value=''>{t('team')}</option>
      {teams?.map((item) => (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      ))}
    </Select>
  )
}

export default TeamSelect
