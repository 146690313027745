import { useDisclosure } from '@chakra-ui/react'
import { createContext, useContext, useState } from 'react'

interface Value {
  isOpenRedefinePassword: boolean
  onOpenRedefinePassword: () => void
  onCloseRedefinePassword: () => void
  isOpenConfirmEmail: boolean
  onOpenConfirmEmail: () => void
  onCloseConfirmEmail: () => void
  redefinePasswordTitle: string
  setRedefinePasswordTitle: (title: string) => void
  redefinePasswordDescription: string
  setRedefinePasswordDescription: (description: string) => void
  showDidntReceiveButton: boolean
  setShowDidntReceiveButton: (didntReceiveButton: boolean) => void
  isRecoverMutation: boolean
  setIsRecoverMutation: (isRecoverMutation: boolean) => void
  defaultRedefineEmail: string
  setDefaultRedefineEmail: (defaultRedefineEmail: string) => void
}

interface Props {
  children: React.ReactNode[] | React.ReactNode
}

const LoginContext = createContext({} as Value)

export function LoginProvider({ children }: Props) {
  const {
    isOpen: isOpenRedefinePassword,
    onOpen: onOpenRedefinePassword,
    onClose: onCloseRedefinePassword,
  } = useDisclosure()

  const [redefinePasswordTitle, setRedefinePasswordTitle] = useState('')
  const [redefinePasswordDescription, setRedefinePasswordDescription] = useState('')
  const [showDidntReceiveButton, setShowDidntReceiveButton] = useState(false)
  const [isRecoverMutation, setIsRecoverMutation] = useState(false)
  const [defaultRedefineEmail, setDefaultRedefineEmail] = useState('')

  const { isOpen: isOpenConfirmEmail, onOpen: onOpenConfirmEmail, onClose: onCloseConfirmEmail } = useDisclosure()

  return (
    <LoginContext.Provider
      value={{
        isOpenRedefinePassword,
        onOpenRedefinePassword,
        onCloseRedefinePassword,
        isOpenConfirmEmail,
        onOpenConfirmEmail,
        onCloseConfirmEmail,
        redefinePasswordTitle,
        setRedefinePasswordTitle,
        redefinePasswordDescription,
        setRedefinePasswordDescription,
        showDidntReceiveButton,
        setShowDidntReceiveButton,
        isRecoverMutation,
        setIsRecoverMutation,
        defaultRedefineEmail,
        setDefaultRedefineEmail,
      }}
    >
      {children}
    </LoginContext.Provider>
  )
}

export const useLogin = () => useContext(LoginContext)
