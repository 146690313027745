import { Box, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { useExploreContext } from 'src/context/exploreContext'
import { ICategory } from '..'
import { SliderChevron } from '../../../theme/Icons'

type CategoryItemProps = ICategory & {
  handleCategoryClick: (slug: string) => void
}

const CategoryItem: React.FC<CategoryItemProps> = ({ id, name, handleCategoryClick }) => {
  const { skillTreeData } = useExploreContext()

  return (
    <Box
      w='285px'
      h='39px'
      borderRadius='16px'
      _hover={{
        backgroundColor: 'gray.60',
        color: 'primaryColors.400',
      }}
      color={skillTreeData?.rootId === id ? 'primaryColors.400' : 'gray.800'}
      d='flex'
      alignItems='center'
      justifyContent='space-between'
      cursor='pointer'
      paddingX='13px'
      paddingY='10px'
      onMouseEnter={() => handleCategoryClick(id)}
    >
      <Box d='flex' alignItems='center'>
        <Text transition='all .2s' fontWeight={skillTreeData?.rootId === id ? '700' : '400'} textAlign='left'>
          {name}
        </Text>
      </Box>
      <Icon as={SliderChevron} />
    </Box>
  )
}

export default CategoryItem
