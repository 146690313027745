import { useLazyQuery } from '@apollo/client'
import { Box, Button, Flex, Input, Select, Skeleton, Text, VStack } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import LIST_USERS from 'src/graphql/queries/LIST_USERS'
import { debounce } from 'src/helpers-admin/debounce'
import { ReturnDashInfo } from 'src/pages-admin/Home/home.types'
import { User } from 'src/types/User'
import { useDashboard } from '../../context-admin/useDashboard'
import { usePermissions } from '../../context-admin/usePermissions'
import { useTeams } from '../../context-admin/useTeams'

type Props = {
  handleComponentChange: (value: string) => void
}

const first = 50

const AddCollabs: React.FC<Props> = ({ handleComponentChange }) => {
  const { areas } = useDashboard()
  const { selectedTeam, addCollabs, handleCollabsAdd } = useTeams()
  const { collabsList, pageInfo, updateCollabsList, updatePageInfo, incrementCollabsList } = usePermissions()
  const [area, setArea] = useState('')
  const [filteredCollabs, setFilteredCollabs] = useState<User[]>([] as User[])
  const [loadingRefetch, setLoadingRefetch] = useState(false)

  const [getUsersList, { refetch, loading }] = useLazyQuery<ReturnDashInfo>(LIST_USERS, {
    variables: { limit: first },
  })

  const filterCollabs = useCallback(
    (list: User[], termToSearch?: string) => {
      const newCollabsList = list
        .filter((item) => item.id.toString() !== selectedTeam.manager.id.toString())
        .filter((item) => selectedTeam.members.map((member) => member.id.toString()).indexOf(item.id.toString()) === -1)

      const collabsByArea = area ? newCollabsList.filter((item) => item.area?.name?.includes(area)) : newCollabsList

      if (termToSearch) {
        const collabsByFilter = collabsByArea.filter(
          (item) =>
            item.name.toLowerCase().includes(termToSearch.toLowerCase()) ||
            item.email.toLowerCase().includes(termToSearch.toLowerCase()),
        )
        return collabsByFilter
      }

      return area ? collabsByArea : newCollabsList
    },
    [area, selectedTeam.manager.id, selectedTeam.members],
  )

  const fetchMore = async () => {
    if (!pageInfo?.next) return

    setLoadingRefetch(true)

    const response = await refetch({
      limit: first,
      cursor: Number(pageInfo?.next),
      name: undefined,
    })

    if (response?.data && response.data?.listUsers) {
      incrementCollabsList(response.data.listUsers.data)
      updatePageInfo(response.data.listUsers.paginateInfo)
    }

    setLoadingRefetch(false)
  }

  const handleBack = () => {
    handleComponentChange('0')
  }

  const handleNextStep = () => {
    handleComponentChange('ADD_COLLABS_STEP_TWO')
  }

  const handleAreaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setArea(e.target.value)
  }

  const searchCollaborators = useCallback(
    async (value: string) => {
      if (!value || value.length <= 0) {
        setFilteredCollabs(filterCollabs(collabsList))
        return
      }

      setLoadingRefetch(true)

      const response = await refetch({
        limit: first,
        cursor: undefined,
        name: value,
      })

      if (response?.data && response.data?.listUsers) {
        setFilteredCollabs(filterCollabs(response.data.listUsers.data))
      } else {
        setFilteredCollabs(filterCollabs(collabsList))
      }

      setLoadingRefetch(false)
    },
    [refetch, filterCollabs, collabsList],
  )

  const debounceSearchCollab = debounce(searchCollaborators, 1000)

  useEffect(() => {
    async function getList() {
      const response = await getUsersList()

      if (response && response.data && response.data?.listUsers) {
        updateCollabsList(response.data.listUsers.data)
        updatePageInfo(response.data.listUsers.paginateInfo)
      }
    }

    getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUsersList])

  useEffect(() => {
    setFilteredCollabs(filterCollabs(collabsList))
  }, [collabsList, filterCollabs])

  return (
    <>
      <Text
        color='#767F89'
        textDecoration='underline'
        fontFamily='Poppins'
        fontWeight='600'
        marginBottom='22px'
        cursor='pointer'
        onClick={handleBack}
      >
        Voltar
      </Text>

      <VStack align='flex-start'>
        <Text fontSize='20px' fontWeight={600} fontFamily='Poppins' color='gray.31'>
          Adicionando novos integrantes
        </Text>
        <Text color='#767F89' mb='24px'>
          Procure e selecione os colaboradores que você deseja adicionar à Equipe "{selectedTeam.name}".
        </Text>

        <Flex direction='column' gridGap='4px' w='100%' mt='24px !important'>
          <Text fontWeight='700' color='#313E4C'>
            Busque colaboradores
          </Text>

          <Input
            variant='newFilled'
            placeholder='Busque por um colaborador'
            w='100%'
            height='36px'
            borderRadius='12px'
            onChange={(e) => debounceSearchCollab(e.target.value)}
          />
        </Flex>

        <Box m='16px 0 !important'>
          <Text color='#767F89' fontSize='14px' fontWeight={600} mb='4px'>
            Filtros
          </Text>

          <Select
            value={area}
            bgColor='#F7F9FA'
            border='none'
            _hover={{ bgColor: '#F7F9FA' }}
            _focus={{ bgColor: '#F7F9FA' }}
            color='#009639'
            fontWeight={600}
            onChange={handleAreaChange}
            fontSize='12px'
            height='22px'
            width='100%'
            maxW='132px'
            className='area-select'
          >
            <option value=''>Todas as áreas</option>
            {areas.map((item) => (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            ))}
          </Select>
        </Box>

        {(loading && filteredCollabs.length === 0) || loadingRefetch ? (
          <Flex direction='column' w='100%' mt='16px' gridGap='16px'>
            <Skeleton w='100%' h='43px' borderRadius='8px' />
            <Skeleton w='100%' h='43px' borderRadius='8px' />
            <Skeleton w='100%' h='43px' borderRadius='8px' />
            <Skeleton w='100%' h='43px' borderRadius='8px' />
            <Skeleton w='100%' h='43px' borderRadius='8px' />
          </Flex>
        ) : (
          <Flex id='scrollableDiv' direction='column' mt='16px' w='100%' maxH='calc(100vh - 550px)' overflowY='auto'>
            <InfiniteScroll
              dataLength={collabsList.length}
              next={fetchMore}
              hasMore={pageInfo?.hasNextPage ?? false}
              loader={
                <Flex direction='column' w='100%' mt='16px' gridGap='16px' display={loadingRefetch ? 'flex' : 'none'}>
                  <Skeleton w='100%' h='43px' borderRadius='8px' />
                  <Skeleton w='100%' h='43px' borderRadius='8px' />
                  <Skeleton w='100%' h='43px' borderRadius='8px' />
                </Flex>
              }
              scrollThreshold={0.9}
              scrollableTarget='scrollableDiv'
            >
              <>
                {!filteredCollabs.length && <Text textAlign='center'>Nenhum colaborador encontrado</Text>}
                {filteredCollabs?.map((item) => {
                  const isSelected = addCollabs.some((collab) => collab.name === item.name)

                  return (
                    <Flex
                      key={Math.random()}
                      h='43px'
                      align='center'
                      bgColor='#F7F9FA'
                      justify='space-between'
                      borderRadius='8px'
                      p='0 16px'
                      mb='16px'
                      boxShadow='0px 1px 6px rgba(0, 0, 0, 0.1)'
                    >
                      <Text ml='8px' fontWeight='700' fontSize='12px' color='#009639'>
                        {item.name}
                      </Text>
                      <Button
                        variant='unstyled'
                        fontWeight='600'
                        fontSize='12px'
                        textDecoration='underline'
                        color={isSelected ? '#009639' : '#313E4C'}
                        onClick={() => handleCollabsAdd(item)}
                      >
                        {isSelected ? 'Selecionado' : 'Adicionar à equipe'}
                      </Button>
                    </Flex>
                  )
                })}
              </>
            </InfiniteScroll>
          </Flex>
        )}

        <Flex w='100%' justify='space-between' mt='24px !important'>
          <Button variant='whiteOutline' fontSize='12px' onClick={handleBack}>
            Voltar
          </Button>

          <Button variant='solidGray' fontSize='12px' disabled={addCollabs.length === 0} onClick={handleNextStep}>
            Continuar
          </Button>
        </Flex>
      </VStack>
    </>
  )
}

export default AddCollabs
