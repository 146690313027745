import { Select } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useHomeAccess } from 'src/context-admin/Home/AdminHomeAccessContext'

const PermissionSelect: React.FC = () => {
  const [t] = useTranslation()
  const { permission, setPermission } = useHomeAccess()

  return (
    <Select
      value={permission}
      onChange={(e) => setPermission(e.target.value)}
      bgColor='white'
      border='2px solid #767F89'
      _hover={{ borderColor: '#313E4C' }}
      fontWeight={600}
      fontSize='0.75rem'
      height='36px'
      width='fit-content'
      minW='131px'
      sx={{ color: '#767F89' }}
    >
      <option value=''>{t('admin.home.employeeSection.permission')}</option>
      <option value='ADMIN'>Admin</option>
      <option value='MANAGER'>{t('manager')}</option>
      <option value='COLAB'>{t('admin.common.Colab')}</option>
    </Select>
  )
}

export default PermissionSelect
