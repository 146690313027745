import { Button, Flex, Input, Text, useToast, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNewTeamManagement } from 'src/context-admin/useNewTeamManagement'
import { usePermissions } from '../../context-admin/usePermissions'

type Props = {
  handleStepChange: (value: number) => void
}

const ConfirmChanges: React.FC<Props> = ({ handleStepChange }) => {
  const [loading, setLoading] = useState(false)
  const { selectedCollab } = usePermissions()
  const [t] = useTranslation()
  const { selectedTeam, includesMemberToArea } = useNewTeamManagement()
  const toast = useToast()
  const selectedTeamManagers = !selectedTeam ? [] : selectedTeam.members.filter((m) => m.type === 'MANAGER')

  const handleBack = () => {
    handleStepChange(2)
  }

  const handleConfirmChangeManager = async () => {
    try {
      setLoading(true)
      await includesMemberToArea(Number(selectedCollab.id))
      handleStepChange(1)
    } catch (err: any) {
      toast({
        title: t('errorWhenAddingNewManager'),
        description: err.message,
        status: 'error',
        duration: 7000,
        isClosable: true,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Text
        color='#767F89'
        textDecoration='underline'
        fontFamily='Poppins'
        fontWeight='600'
        marginBottom='22px'
        cursor='pointer'
        onClick={handleBack}
      >
        {t('back')}
      </Text>

      <VStack align='flex-start'>
        <Text fontSize='20px' fontWeight={600} fontFamily='Poppins' color='gray.31'>
          {t('attributionConfirmation')}
        </Text>

        <Flex flexDir='column' w='100%' gap='8px'>
          <Text fontWeight='700' color='#313E4C' textTransform='capitalize'>
            {t('selectedTeam')}
          </Text>

          <Input w='100%' readOnly value={selectedTeam?.name} />
        </Flex>

        {selectedTeamManagers?.length > 0 ? (
          <Flex m='16px 0 !important' w='100%' direction='column' gridGap='4px'>
            <Text fontWeight='700' color='#313E4C' textTransform='capitalize'>
              {t('currentManagers')}
            </Text>
            <Flex flexDir='column' gap='8px' maxH='145px' overflowY='auto'>
              {selectedTeamManagers.map((manager) => (
                <Flex
                  key={manager.id}
                  h='43px'
                  align='center'
                  bgColor='#F7F9FA'
                  justify='space-between'
                  borderRadius='8px'
                  p='0 16px'
                  boxShadow='0px 1px 6px rgba(0, 0, 0, 0.1)'
                >
                  <Text fontSize='12px' fontWeight={700} color='#313E4C'>
                    {manager.name}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Flex>
        ) : null}

        <Flex mb='24px !important' w='100%' direction='column' gridGap='4px'>
          <Text fontWeight='700' color='#313E4C'>
            {t('newTeamManager')}
          </Text>
          <Flex
            h='43px'
            align='center'
            bgColor='#F7F9FA'
            justify='space-between'
            borderRadius='8px'
            p='0 16px'
            boxShadow='0px 1px 6px rgba(0, 0, 0, 0.1)'
          >
            <Text fontSize='12px' fontWeight={700} color='#009639'>
              {selectedCollab.name}
            </Text>
          </Flex>
        </Flex>

        <Flex direction='column' gridGap='16px' mt='0 !important' w='100%'>
          <Text fontWeight='700' color='#313E4C'>
            {t('teamMembers')} ({selectedTeam?.members?.length} {t('members')})
          </Text>

          {selectedTeam?.members?.map((m) => (
            <Flex
              key={m.id}
              h='43px'
              align='center'
              bgColor='#F7F9FA'
              borderRadius='8px'
              p='0 16px'
              boxShadow='0px 1px 6px rgba(0, 0, 0, 0.1)'
            >
              <Text fontWeight='700' fontSize='12px'>
                {m.name}
              </Text>
            </Flex>
          ))}
        </Flex>

        <Flex w='100%' justify='space-between' mt='24px !important'>
          <Button variant='whiteOutline' fontSize='12px' onClick={handleBack}>
            {t('back')}
          </Button>

          <Button
            variant='solidGray'
            fontSize='12px'
            isDisabled={loading || !selectedTeam || !selectedCollab.id}
            isLoading={loading}
            onClick={handleConfirmChangeManager}
          >
            {t('confirm')}
          </Button>
        </Flex>
      </VStack>
    </>
  )
}

export default ConfirmChanges
