import { Box, Flex, FormControl, FormLabel, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import ReactSelect, { OptionTypeBase, ValueType } from 'react-select'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import ButtonsFooter from '../ButtonsFooter'
import StepHeader from '../StepHeader'
import { selectStyles } from '../utils'
import { useMemo } from 'react'

const SelectLanguage = () => {
  const [t] = useTranslation()

  const languages = useMemo(() => {
    return [
      { value: 'PT', label: t('languages.PT') },
      { value: 'EN', label: t('languages.EN') },
      { value: 'ES', label: t('languages.ES') },
      { value: 'DE', label: t('languages.DE') },
      { value: 'ZH', label: t('languages.ZH') },
      { value: 'JA', label: t('languages.JA') },
      { value: 'IT', label: t('languages.IT') },
      { value: 'FR', label: t('languages.FR') },
    ]
  }, [t])

  const languageOptions = [{ value: 'all', label: t('commonSelectOptions.noPreferences') }, ...languages]

  const subtitleOptions = [{ value: 'noSubtitle', label: t('commonSelectOptions.noSubtitle') }, ...languages]

  const { quizValues, updateQuiz, createCustomPlaylist } = useCustomPlaylistQuiz()

  const handleLanguageChange = (selectedOptions: ValueType<OptionTypeBase, true>) => {
    if (selectedOptions.some((option) => option.value === 'all')) {
      updateQuiz('languages', languageOptions)
    } else {
      updateQuiz('languages', selectedOptions)
    }
  }

  const handleSubtitleChange = (selectedOptions: ValueType<OptionTypeBase, true>) => {
    updateQuiz('subtitles', selectedOptions)
  }

  const selectLanguageValue = quizValues.languages.some((option) => option.value === 'all')
    ? [{ value: 'all', label: t('commonSelectOptions.noPreferences') }]
    : quizValues.languages

  const selectSubtitleValue = quizValues.subtitles.some((option) => option.value === 'noSubtitle')
    ? [{ value: 'noSubtitle', label: t('commonSelectOptions.noSubtitle') }]
    : quizValues.subtitles

  return (
    <Flex h='100%' direction='column'>
      <Box>
        <StepHeader
          step={6}
          title={t('customPlaylistQuiz.selectLanguage.title')}
          description={t('customPlaylistQuiz.selectLanguage.description')}
        />

        <FormControl mt='24px'>
          <FormLabel fontFamily='Mulish' fontWeight='700' color='gray.800' fontSize='16px' marginBottom='2px'>
            {t('customPlaylistWithAudioLanguages')}
            <Text as='span' color='red'>
              *
            </Text>
          </FormLabel>
          <ReactSelect
            isMulti
            isClearable={false}
            styles={selectStyles()}
            defaultValue={{ value: 'PT', label: t('languages.PT') }}
            value={selectLanguageValue}
            placeholder=''
            components={{ IndicatorSeparator: () => null }}
            maxMenuHeight={220}
            onChange={handleLanguageChange}
            options={
              quizValues.languages.some((option) => option.value === 'all')
                ? [{ value: 'all', label: t('commonSelectOptions.noPreferences') }]
                : languageOptions
            }
          />
        </FormControl>

        <FormControl mt='24px'>
          <FormLabel fontFamily='Mulish' fontWeight='700' color='gray.800' fontSize='16px' marginBottom='2px'>
            {t('customPlaylistWithSubtitleLanguages')}
            <Text as='span' color='red'>
              *
            </Text>
          </FormLabel>
          <ReactSelect
            isMulti
            isClearable={false}
            styles={selectStyles()}
            defaultValue={{ value: 'PT', label: t('languages.PT') }}
            value={selectSubtitleValue}
            placeholder=''
            components={{ IndicatorSeparator: () => null }}
            maxMenuHeight={220}
            onChange={handleSubtitleChange}
            options={
              quizValues.subtitles.some((option) => option.value === 'noSubtitle')
                ? [{ value: 'noSubtitle', label: t('commonSelectOptions.noSubtitle') }]
                : subtitleOptions
            }
          />
        </FormControl>
      </Box>

      <ButtonsFooter disabledButton={quizValues.languages.length === 0 || quizValues.subtitles.length === 0} />
    </Flex>
  )
}

export default SelectLanguage
