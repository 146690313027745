import { Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { AvailableStepsType } from 'src/types/HandleAreaDashboard'
import { NavigationButtons } from '../../commonComponents/NavigationButtons'
import { StepTitle } from '../../commonComponents/StepTitle'
import { StepTransition } from '../../commonComponents/StepTransition'

type Option = {
  id: AvailableStepsType
  label: string
  color?: string
  bg?: string
}

function generateOptions(t: any): Option[] {
  return [
    {
      id: 'edit-existing-area--renaming-area',
      label: t('renameArea'),
    },
    {
      id: 'edit-existing-area--add-users',
      label: t('addNewMembersInArea'),
      color: 'green.500',
    },
    {
      id: 'edit-existing-area--change-manager',
      label: t('changeManager'),
      color: '#2F80ED',
    },
    {
      id: 'edit-existing-area--remove-users',
      label: t('removeMembersFromArea'),
      color: '#FF4949',
      bg: '#FFF4F4',
    },
    {
      id: 'edit-existing-area--delete-area',
      label: t('deleteArea'),
      color: '#767F89',
    },
  ]
}

export function EditExistingAreaSelectOptions() {
  const [t] = useTranslation()
  const [selectedStep, setSelectedStep] = useState<AvailableStepsType | undefined>(undefined)
  const { navigateToStep, area, handleSetAreaValues } = useHandleArea()

  const handleSelectStepToNavigate = (id?: AvailableStepsType) => {
    setSelectedStep((prev) => (prev === id ? undefined : id))
  }

  useEffect(() => {
    handleSetAreaValues({ newManager: undefined, newUsers: undefined })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StepTransition>
      <Flex flexDir='column'>
        <StepTitle title={t('editingArea', { areaName: area?.name ?? t('invalidArea') })} />
        <Flex flexDir='column' mt='24px'>
          <Text>{t('whatDoYouWantToDoWithThisArea')}</Text>
          <Flex flexDir='column' gap='16px' mt='16px'>
            {generateOptions(t).map((option) => (
              <Flex
                key={option.id}
                bg={option.bg ? option.bg : '#F7F9FA'}
                px='16px'
                py='12px'
                cursor='pointer'
                borderRadius='8px'
                borderWidth='2px'
                borderColor={selectedStep === option.id ? option.color ?? 'bluishGray.500' : 'transparent'}
                boxShadow='0px 1px 6px 0px rgba(0, 0, 0, 0.10)'
                onClick={() => handleSelectStepToNavigate(option.id)}
                transition='all 150ms ease'
              >
                <Text fontWeight='700' fontSize='0.75rem' {...(option.color ? { color: option.color } : {})}>
                  {option.label}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
        <NavigationButtons
          onContinueClick={() => navigateToStep(selectedStep!)}
          onBackClick={() => navigateToStep('edit-existing-area')}
          isDisabled={!selectedStep}
        />
      </Flex>
    </StepTransition>
  )
}
