import { Box, Button, Flex, Icon, Image, Skeleton, Text, useDisclosure, useToast } from '@chakra-ui/react'
import Vimeo from '@u-wave/react-vimeo'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCheckmark, IoCloseSharp, IoPlayCircle, IoVolumeHighOutline, IoVolumeMuteOutline } from 'react-icons/io5'
import { useLocation } from 'react-router-dom'
import YouTube from 'react-youtube'
import FavoriteIcon from 'src/components/FavoriteIcon'
import ShareButton from 'src/components/LearningItemModal/ShareButton'
import MarkCompleteModal from 'src/components/MarkCompleteModal'
import { useCourseModal } from 'src/context/CourseModalContext'
import { useEvaluate } from 'src/context/EvaluateContext'
import { useUser } from 'src/context/userContext'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { useConfetti } from 'src/hooks/useConfetti'
import { useWindowSize } from 'src/hooks/useWindowSize'
import Content from 'src/modules/cms/domain/Content'
import { makeGetContentUrl } from 'src/modules/cms/factories/makeGetContentUrl'
import { makeMarkContent } from 'src/modules/cms/factories/makeMarkContent'
import { ContentAction } from 'src/modules/cms/use_cases/MarkContentUseCase'
import { checkVideoProvider } from 'src/modules/cms/utils/checkVideoProvider'
import { extractIdFromTrailerUrl } from 'src/modules/cms/utils/extractIdFromTrailerUrl'
import { renderCorrectContentInfoByLanguage } from 'src/modules/internationalization/helpers/renderCorrectContentInfoByLanguage'
import { Icons8CircleCloseIcon } from 'src/theme/Icons'
import { CourseTrailer } from 'src/types/CourseTrailer'
import { LearningItemProgress } from 'src/types/LearningItemProgress'
import useRegisterAccess from '../../hooks/useRegisterAccess'
import TrailerModal from './TrailerModal'

interface Props {
  course: Content | undefined
  courseUpdated: Content | undefined
  onClose: () => void
  setCourse?: (course: Content) => void
  scrolled: boolean
}

const CourseModalHeader: React.FC<Props> = ({ course, courseUpdated, onClose, setCourse, scrolled }: Props) => {
  const register = useRegisterAccess()
  const location = useLocation()
  const [t] = useTranslation()
  const { showConfetti } = useConfetti()
  const { openEvaluation, updateCourseInfo } = useEvaluate()
  const { updateCourse } = useCourseModal()
  const { getUser } = useUser()
  const markContent = makeMarkContent()

  const [volume, setVolume] = useState(0)
  const [trailer, setTrailer] = useState<CourseTrailer | undefined>({
    provider: checkVideoProvider(course?.trailerUrl),
    trailerId: extractIdFromTrailerUrl(course?.trailerUrl)!,
    url: course?.trailerUrl!,
  })

  const youtubeOptions = {
    playerVars: {
      autoplay: 1,
      controls: 0,
      loop: 1,
      showinfo: 0,
      modestbranding: 1,
      playlist: trailer?.trailerId,
    } as const,
  }

  const [isLoadingGetUrl, setIsLoadingGetUrl] = useState(false)
  const [youtubePlayer, setYoutubePlayer] = useState(undefined)
  const { isOpen: isTrailerOpen, onOpen: onTrailerOpen, onClose: onTrailerClose } = useDisclosure()
  const { isOpen: isCompleteOpen, onClose: toggleCompleteModal, onOpen: openCompleteModal } = useDisclosure()
  const { EventsImplementation, track } = useMixpanel()
  const { width } = useWindowSize()
  const toast = useToast()
  const isMobile = useMemo(() => width && width < 992, [width])

  const onReady = (event: any) => {
    event.target.setVolume(0)
    setYoutubePlayer(event.target)
  }

  const updateVolume = () => {
    if (volume) {
      setVolume(0)
      if (trailer?.provider === 'youtube') {
        /* @ts-ignore */
        youtubePlayer.setVolume(0)
      }
      return
    }

    setVolume(0.5)
    if (trailer?.provider === 'youtube') {
      /* @ts-ignore */
      youtubePlayer.setVolume(50)
    }
  }

  const openTrailer = () => {
    if (trailer?.provider === 'youtube') {
      /* @ts-ignore */
      youtubePlayer.setVolume(0)
    }
    setVolume(0)
    onTrailerOpen()
  }

  const onError = () => {
    setTrailer(undefined)
  }

  const containerRef = useRef(null)

  if (!course || !courseUpdated)
    return (
      <Skeleton
        position='relative'
        w='100%'
        minH={'470px'}
        borderRadius={scrolled ? '0px 0px 0px 0px' : '30px 30px 0px 0px'}
      />
    )

  const onMarkAsUndone = async () => {
    try {
      const courseCopy = { ...course }
      courseCopy.progress = LearningItemProgress.DOING
      await markContent.execute({ id: course.id, action: ContentAction.UNDONE })
      getUser()
      setCourse?.(courseCopy)
      updateCourse(courseCopy)
      track(EventsImplementation.MARK_AS_UNDONE(course.title, location.pathname, 'Modal'))
    } catch (error) {
      const courseCopy = { ...course }
      courseCopy.progress = LearningItemProgress.DONE
      updateCourse(courseCopy)
    }
  }

  const handleMarkAsDone = async () => {
    const courseCopy = { ...course }
    courseCopy.progress = LearningItemProgress.DONE
    showConfetti()
    setCourse?.(courseCopy)
    updateCourse(courseCopy)
    track(EventsImplementation.MARK_AS_DONE(course.title, location.pathname, 'Modal'))

    updateCourseInfo(courseCopy)
  }

  const handleStartCourse = async () => {
    try {
      setIsLoadingGetUrl(true)

      const getUrl = makeGetContentUrl()
      const url = await getUrl.execute({ id: course.id })

      if (!url) {
        toast({
          title: t('errorWhileLoadingContent'),
          isClosable: true,
          duration: 5000,
          status: 'error',
        })

        return
      }

      register(course.id)
      track(
        EventsImplementation.ACCESS_LEARNING_ITEM(
          null,
          course?.favorited!,
          course.type,
          course.title,
          course.id,
          course?.partner?.name!,
          location.pathname,
          'Modal',
        ),
      )

      const courseCopy = { ...course }
      courseCopy.progress = LearningItemProgress.DOING
      updateCourse(courseCopy)
      setCourse?.(courseCopy)
      window.open(url, '_blank')
    } catch (error) {
      toast({
        title: t('errorWhileLoadingContent'),
        isClosable: true,
        duration: 5000,
        status: 'error',
      })
    } finally {
      setIsLoadingGetUrl(false)
    }
  }

  return (
    <Box
      position='relative'
      w='100%'
      ref={containerRef}
      minH={'470px'}
      bg='gray.60'
      borderRadius={scrolled ? '0px 0px 0px 0px' : '30px 30px 0px 0px'}
    >
      <Box
        bg={`url(${course?.imageUrl ?? 'https://i.ibb.co/fS8fCDS/dummy-300x300-009136-ffffff-education-journey.png'})`}
        bgRepeat='no-repeat'
        bgSize='cover'
        //@ts-ignore
        w={'100%'}
        minH={'470px'}
        top={'initial'}
        borderRadius={scrolled ? '0px 0px 0px 0px' : '30px 30px 0px 0px'}
        bgPosition='center'
        position={'relative'}
        h={'auto'}
        zIndex={'initial'}
      >
        {!isMobile && (
          <>
            {!scrolled &&
              trailer &&
              (trailer.provider === 'youtube' ? (
                <Box width='100%' height='100%' overflow='hidden' pos='absolute' borderRadius='30px 30px 0px 0px;'>
                  <YouTube
                    videoId={trailer.trailerId}
                    opts={youtubeOptions}
                    containerClassName='course-youtube-container'
                    onReady={onReady}
                    onError={onError}
                  />
                </Box>
              ) : (
                <Vimeo
                  video={trailer.url}
                  className='vimeo-video'
                  responsive
                  autoplay
                  volume={volume}
                  loop
                  controls={false}
                  onError={onError}
                />
              ))}
          </>
        )}
        <Flex
          w='100%'
          h='100%'
          background={'linear-gradient(0deg, #2F2E2E 3.52%, rgba(47, 46, 46, 0) 100%)'}
          borderRadius={scrolled ? '0px 0px 0px 0px' : '30px 30px 0px 0px'}
          padding='25px 25px'
          flexDirection={'column-reverse'}
          position={'absolute'}
          top='0'
        >
          <Button variant='closeButton' onClick={() => onClose()}>
            <Icon w='28px' h='28px' as={IoCloseSharp} />
          </Button>
          {course.partner && (
            <Flex
              d={'flex'}
              backgroundColor='white'
              borderRadius='10px'
              w='40px'
              h='40px'
              position='absolute'
              top='25px'
              left='25px'
              alignItems='center'
              justifyContent='center'
            >
              <Image w='30px' h='30px' src={course.partner.squareLogoUrl} />
            </Flex>
          )}

          <Flex flexDirection={'column'} alignItems={'initial'}>
            <Box>
              <Box
                borderRadius='50px 100px 9px 50px'
                background={course.partner?.coverColor ? course.partner?.coverColor : 'primaryColors.400'}
                w='fit-content'
                padding='4px 27px'
              >
                <Text color='white' fontWeight='900' fontSize='14px' textTransform='uppercase'>
                  {course.skills[0]?.name ? course.skills[0]?.name : t('playlist.courseCard.course')}
                </Text>
              </Box>

              <Text
                color='white'
                fontFamily='Poppins'
                fontSize={'22px'}
                lineHeight={'28px'}
                maxW='320px'
                marginTop={'8px'}
                fontWeight='600'
              >
                {renderCorrectContentInfoByLanguage(course, 'title')}
              </Text>
            </Box>
            <Flex
              marginTop={'18px'}
              marginLeft={'initial'}
              justifyContent='space-between'
              flexDir={{ base: 'column', lg: 'row' }}
            >
              <Flex flexDir={{ base: 'row' }} flexWrap={{ base: 'wrap' }} gap={{ base: '8px', lg: '14px' }}>
                <Button variant='startCourse' h='48px' w='auto' flexGrow={{ base: '1' }} onClick={handleStartCourse} isLoading={isLoadingGetUrl}>
                  <Icon w='30px' h='30px' color='gray.800' as={IoPlayCircle} />
                  <Text marginLeft='8px' fontSize='16px' color='gray.800'>
                    {course.progress === LearningItemProgress.TODO
                      ? t('home.Course Modal.Start Course')
                      : t('common.card.resume')}
                  </Text>
                </Button>

                {course.progress !== LearningItemProgress.TODO ? (
                  <Button
                    variant='startCourseDark'
                    height='48px'
                    p='0 14px'
                    border='2px solid rgba(229, 229, 229, 0.6)'
                    _hover={{ borderColor: 'white' }}
                    maxW='285px'
                    flexGrow={{ base: '1' }}
                    onClick={() => (course.progress === 'DONE' ? onMarkAsUndone() : openCompleteModal())}
                  >
                    <Icon
                      w='30px'
                      h='30px'
                      marginRight='8px'
                      as={course.progress === 'DONE' ? Icons8CircleCloseIcon : IoCheckmark}
                    />
                    {course.progress === 'DONE'
                      ? t('common.card.markAsNotCompleteCourse')
                      : t('common.card.markAsCompleteCourse')}
                  </Button>
                ) : null}

                <FavoriteIcon content={courseUpdated} onFavorite={setCourse} variant='icon' iconVariant='modal' />

                <ShareButton content={course} height='48px' isDarkButton />
              </Flex>
              {!isMobile && (
                <Flex alignItems='center' marginLeft={{ base: 'auto' }} marginTop={{ base: '12px', lg: '0px' }}>
                  {!scrolled && trailer ? (
                    <Text
                      color='white'
                      fontFamily='Poppins'
                      fontWeight='600'
                      marginRight='10px'
                      cursor='pointer'
                      fontSize='14px'
                      onClick={() => {
                        openTrailer()

                        track(EventsImplementation.CARD_MODAL_WATCH_FULL_TRAILER())
                      }}
                    >
                      {t('home.Course Modal.Watch Trailer')}
                    </Text>
                  ) : null}

                  {!scrolled && trailer ? (
                    <Button
                      variant='iconDisabled'
                      onClick={() => {
                        updateVolume()
                        track(EventsImplementation.CARD_MODAL_UNMUTE_TRAILER())
                      }}
                    >
                      {!volume ? (
                        <Icon w='24px' h='24px' as={IoVolumeMuteOutline} />
                      ) : (
                        <Icon w='24px' h='24px' as={IoVolumeHighOutline} />
                      )}
                    </Button>
                  ) : null}
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <TrailerModal content={course} onClose={onTrailerClose} isOpen={isTrailerOpen} />
      <MarkCompleteModal
        content={course}
        onClose={toggleCompleteModal}
        isOpen={isCompleteOpen}
        onMarkAsDone={handleMarkAsDone}
        openEvaluateModal={() => openEvaluation({ step: 1, source: 'playlistLearningItemFinished' })}
      />
    </Box>
  )
}

export default CourseModalHeader
