import { Box, Flex, Icon, Skeleton, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { IoSchoolOutline, IoTimeOutline } from 'react-icons/io5'
import { useCourseModal } from 'src/context/CourseModalContext'
import { useUser } from 'src/context/userContext'
import { durationConverter } from 'src/helpers/duration'
import { getProficiencyLevel } from 'src/helpers/proeficiencyLevel'
import { LanguageIcon } from '../../theme/Icons'

const CourseModalInfo: React.FC = () => {
  const { course, loading } = useCourseModal()
  const { user } = useUser()
  const [t] = useTranslation()

  if (loading || !course)
    return (
      <Flex borderRadius='0px 0px 30px 30px' background='gray.60' padding='19px 25px 21px'>
        <Skeleton h='27px' w='380px' borderRadius='8px' />
      </Flex>
    )

  return (
    <Flex borderRadius='0px 0px 30px 30px' background='gray.60' padding='19px 25px 21px'>
      <Box
        backgroundColor={course.proficiencyLevel?.color || 'transparent'}
        borderRadius='23px 50px 8.55818px 20px'
        padding='4px 11px'
      >
        <Text color='white' fontWeight='700' fontSize='14px'>
          {getProficiencyLevel(course.proficiencyLevel?.name || '', user?.language)}
        </Text>
      </Box>

      <Flex marginLeft='16px' alignItems='center'>
        <Icon as={IoTimeOutline} w='18px' h='18px' />
        <Text marginLeft='4px' fontWeight='600' fontSize='16px'>
          {durationConverter(course.duration as unknown as number)}
        </Text>
      </Flex>

      {course.hasCertificate && (
        <Flex marginLeft='19px' alignItems='center'>
          <Icon as={IoSchoolOutline} w='18px' h='18px' />
          <Text marginLeft='4px' fontWeight='600' fontSize='16px'>
            {t('home.Course Modal.Certificate')}
          </Text>
        </Flex>
      )}

      {course.language && (
        <Flex marginLeft='19px' alignItems='center'>
          <LanguageIcon w='24px' h='24px' />
          <Text marginLeft='4px' fontWeight='600' fontSize='16px'>
            {/* @ts-ignore */}
            {t(`locale.${course.language}`)}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

export default CourseModalInfo
