import Content from "src/modules/cms/domain/Content";

function getDurationOfContentList(contentList: Content[]) {
  return contentList.reduce((acc, content) => acc + (content?.duration ?? 0), 0)
}

/* Duration in minutes */
export function getReadableContentDuration(contentList: Content[]) {
  const contentListDuration = Math.floor(getDurationOfContentList(contentList) / 60)

  let formatted = `00:00`
  
  if (contentListDuration < 60) {
    formatted = `00:${String(contentListDuration).padStart(2, '0')}`
  } else {
    const hours = Math.floor(contentListDuration / 60)
    const minutes = contentListDuration % 60
    formatted = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
  }
  
  return formatted
}