import * as Yup from 'yup'

export const RegisterContentFormSchema = Yup.object().shape({
  url: Yup.string().required('Obrigatório'),
  type: Yup.string().required('Obrigatório'),
  duration: Yup.string().required('Obrigatório'),
  title: Yup.string().required('Obrigatório'),
  description: Yup.string().required('Obrigatório'),
  skills: Yup.array().min(1, 'Obrigatório'),
  language: Yup.array().of(Yup.string()).min(1, 'Obrigatório'),
  subtitles: Yup.array().of(Yup.string()).min(1, 'Obrigatório'),
  cover: Yup.mixed().test('required', 'Obrigatório', (file) => {
    return file !== undefined && file !== null
  }),
  trailer: Yup.string().url(),
  partner: Yup.string(),
  issuesCertificate: Yup.boolean(),
})
