import { createContext, useContext } from "react";
import { useState } from "react";

export type Company = {
  cnpj: string;
  name: string;
  tradeName: string;
  areas: string[];
};

export type Admin = {
  username: string;
  password: string;
  name: string;
}

interface Value {
  company: Company | null;
  setCompany: (company: Company) => void;
  admin: Admin | null;
  setAdmin: (admin: Admin) => void;  
}

type ProviderProps = {
  children: React.ReactNode[] | React.ReactNode;
};

const OnboardContext = createContext({} as Value);

export function OnboardProvider({ children }: ProviderProps) {
  const [company, setCompany] = useState<Company | null>(null);
  const [admin, setAdmin] = useState<Admin | null>(null);

  return (
    <OnboardContext.Provider
      value={{
        company,
        setCompany,
        admin,
        setAdmin
      }}
    >
      {children}
    </OnboardContext.Provider>
  );
};

export const useOnboard = () => useContext(OnboardContext);
