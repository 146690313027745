import { generateErrorMessage } from "../helpers/generateErrorMessage"
import { HttpClient } from "src/services/http/http-client"

export type ListAreasInput = {
  page?: number
  limit?: number
}

export type ListAreasApiResponse = {
  total: number
  items: {
    id: number; 
    name: string;
    managerId: number;
  }[]
}

export type ListAreasOutput = {
  total: number;
  items: {
    id: number; 
    name: string;
    managerId: number;
  }[]
}

export type CreateNewAreaInput = {
  areaName: string
  managerId: number
}

export type CreateNewAreaOutput = {
  id: number
  name: string
}

export type RenameExistingAreaInput = {
  areaId: number
  newName: string
}

export type AddNewUsersToExistingAreaInput = {
  areaId: number
  usersIds?: number[]
  usersEmails?: string[]
}

export type AddNewUsersToExistingAreaOutput = {
  userId: number
  result: 'success' | 'failed'
  reason?: string
}

export type AddNewUsersToExistingAreaResponse = {
  results: AddNewUsersToExistingAreaOutput[]
}

export type ChangeManagerFromExistingAreaInput = {
  areaId: number
  newManagerId: number
}

export type RemoveUsersFromExistingAreaInput = {
  areaId: number
  usersIds: number[]
}

export type RemoveUsersFromExistingAreaOutput = {
  userId: number
  result: 'success' | 'failed'
  reason?: string
}

export type RemoveUsersFromExistingAreaResponse = {
  results: RemoveUsersFromExistingAreaOutput[]
}

export type DeleteExistingAreaInput = {
  areaId: number
}

interface IAreaRepository {
  listAreasByCompany: (args: ListAreasInput) => Promise<ListAreasOutput>
  createNewArea: (args: CreateNewAreaInput) => Promise<CreateNewAreaOutput>
  renameExistingArea: (args: RenameExistingAreaInput) => Promise<boolean>
  addNewUsersToExistingArea: (args: AddNewUsersToExistingAreaInput) => Promise<AddNewUsersToExistingAreaOutput[]>
  changeManagerFromExistingArea: (args: ChangeManagerFromExistingAreaInput) => Promise<boolean>
  removeUsersFromExistingArea: (args: RemoveUsersFromExistingAreaInput) => Promise<RemoveUsersFromExistingAreaOutput[]>
  deleteExistingArea: (args: DeleteExistingAreaInput) => Promise<boolean>
}

export class AreaRepository implements IAreaRepository {
  private token: string
  private client: HttpClient

  constructor(token: string, client: HttpClient) {
    this.token = token
    this.client = client
  }

  listAreasByCompany = async (args?: ListAreasInput): Promise<ListAreasOutput> => {
    let url = `/v1/company/area`

    if (args?.limit) {
      url += `?limit=${args?.limit}`
    }

    if (args?.page && !args.limit) {
      const limit = 25
      const skip = args.page * limit
      url += `?limit=${limit}&skip=${skip}`
    } 
    try {
      const response = await this.client.request<ListAreasApiResponse>({
        method: 'GET',
        url: url,
        headers: {
          Authorization: this.token,
        }
      })
      return {
        total: response.body.total,
        items: response.body.items
      }
    } catch {
      throw new Error(`Ocorreu um erro ao listar as áreas disponíveis. Por favor, tente novamente!`)
    }
  }
  
  createNewArea = async (args: CreateNewAreaInput): Promise<CreateNewAreaOutput> => { 
    const newArea = {
      name: args.areaName,
      manager_id: args.managerId,
    }
    try {
      const wasCreated = await this.client.request<CreateNewAreaOutput>({
        method: 'POST',
        url: '/v1/company/area',
        data: newArea,
        headers: {
          Authorization: `${this.token}`
        }
      })
      return wasCreated.body
    } catch (err: any) {
      throw new Error(`Ocorreu um erro ao criar a área. Por favor, tente novamente!`)
    }
  }

  renameExistingArea = async (args: RenameExistingAreaInput): Promise<any> => {
    const newArea = {
      name: args.newName,
    }
    try {
      const wasRenaming = await this.client.request<any>({
        method: 'PATCH',
        url: `/v1/company/area/${args.areaId}`,
        data: newArea,
        headers: {
          Authorization: this.token
        }
      })
      return wasRenaming.body
    } catch (err: any) {
      throw new Error(`Ocorreu um erro ao renomear a área. Por favor, tente novamente!`)
    }
  }

  addNewUsersToExistingArea = async (args: AddNewUsersToExistingAreaInput): Promise<AddNewUsersToExistingAreaOutput[]> => {
    const emails = args.usersEmails
    const userIds = args.usersIds?.map(id => Number(id))

    const newAreaUsers = {
      members: emails || userIds
    }

    try {
      const response = await this.client.request<AddNewUsersToExistingAreaResponse>({
        method: 'POST',
        url:
          emails && emails.length > 0
            ? `/v1/company/area/${args.areaId}/members/email`
            : `/v1/company/area/${args.areaId}/members`,
        data: newAreaUsers,
        headers: {
          Authorization: this.token
        }
      })
      const failedUsers = response.body.results.filter(result => result.result === 'failed')
      return failedUsers
    } catch {
      throw new Error(`Ocorreu um erro ao cadastrar os novos usuários na área. Por favor, tente novamente!`)
    }
  }

  changeManagerFromExistingArea = async (args: ChangeManagerFromExistingAreaInput): Promise<any> => {
    const newAreaManager = {
      manager_id: args.newManagerId
    }
    try {
      const wasChanged = await this.client.request<any>({
      method: 'POST',
      url: `/v1/company/area/${args.areaId}/manager`,
      headers: {
        Authorization: this.token,
      },
      data: newAreaManager
    })
    return wasChanged.body
    } catch (err: any) {
      const error = generateErrorMessage({ code: err?.response?.data?.error?.code })
      throw new Error(error)
    }
  }

  removeUsersFromExistingArea = async (args: RemoveUsersFromExistingAreaInput): Promise<RemoveUsersFromExistingAreaOutput[]> => {
    const newAreaUsers = {
      members: args.usersIds.map(id => Number(id))
    }
    try {
      const response = await this.client.request<RemoveUsersFromExistingAreaResponse>({
        method: 'PATCH',
        url: `/v1/company/area/${args.areaId}/members/remove`,
        data: newAreaUsers,
        headers: {
          Authorization: `${this.token}`
        }
      })

      const failedUsers = response.body.results.filter(result => result.result === 'failed')
      return failedUsers
    } catch (err: any) {
      throw new Error('Ocorreu um erro ao remover os usuários da área. Por favor, tente novamente!')
    }
  }

  deleteExistingArea = async (args: DeleteExistingAreaInput): Promise<any> => {
    try {
      const wasDeleted = await this.client.request<any>({
        method: 'DELETE',
        url: `/v1/company/area/${args.areaId}`,
        headers: {
          Authorization: `${this.token}`
        }
      })
      return wasDeleted.body
    } catch (err: any) {
      throw new Error('Ocorreu um erro ao excluir a área. Por favor, tente novamente!')
    }
  }
}