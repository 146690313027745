import { Flex, Heading, Image } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSwapContentDrawer } from 'src/context/CustomPlaylist/SwapContentDrawerContext'
import { EventsImplementation } from 'src/hooks/Mixpanel/types'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'

const StepThree: React.FC = () => {
  const [t] = useTranslation()
  const { track } = useMixpanel()

  const { swapContentDrawerInfo, contentToSwap, setSwapDrawerStep, closeSwapContentDrawer } = useSwapContentDrawer()

  useEffect(() => {
    track(
      EventsImplementation.PERSONALIZED_PLAYLIST_CHANGE_LEARNING_ITEM_CONFIRMATION(
        swapContentDrawerInfo!.content.title,
        contentToSwap!.title,
      ),
    )
    const timeout = setTimeout(() => {
      closeSwapContentDrawer()
      setSwapDrawerStep(0)
    }, 5000)

    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex flexDirection='column' alignItems='center' justifyContent='center'>
      <Image src='/assets/images/floating-balloon.svg' w={357} />
      <Heading size='xs' textAlign='center' fontWeight='600' marginTop='48px' maxW='300px'>
        {t('home.Custom Playlist.The content')} {swapContentDrawerInfo!.content.title}{' '}
        {t('home.Custom Playlist.was successfully replaced')}
      </Heading>
    </Flex>
  )
}

export default StepThree
