import { useMutation } from '@apollo/client'
import { Box, Button, Flex, Text, Tooltip, VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { IoHelpCircle } from 'react-icons/io5'
import { useUser } from 'src/context/userContext'
import REMOVE_ADMIN_ROLE from 'src/graphql/mutations/REMOVE_ADMIN_ROLE'
import UPDATE_ROLE from 'src/graphql/mutations/UPDATE_ROLE'
import { Role } from 'src/types/Role'
import { usePermissions } from '../../context-admin/usePermissions'
import { useMixpanel } from '../../hooks-admin/mixpanel/useMixpanel'
import { permissionsOptions, returnPermissions } from './utils'

type Props = {
  handleStepChange: (value: number) => void
}

const SelectPermissions: React.FC<Props> = ({ handleStepChange }) => {
  const { EventsImplementation, track } = useMixpanel()
  const { selectedPermissions, setSelectedPermissions, selectedCollab, handleTogglePermissionsDrawer } =
    usePermissions()
  const status = returnPermissions(selectedPermissions)
  const [updateRole, { loading }] = useMutation(UPDATE_ROLE)
  const [removeAdmin, { loading: removeAdminLoading }] = useMutation(REMOVE_ADMIN_ROLE)
  const [t] = useTranslation()
  const { user } = useUser()

  const isColabFromSameCompanyOfLoggedUser =
    (user?.company?.id ?? user?.companyId) === (selectedCollab?.company?.id ?? selectedCollab?.companyId)

  const handleSelect = (permission: string) => {
    const isSelected = selectedPermissions.includes(permission)

    if (isSelected) {
      setSelectedPermissions((prevState) => prevState.filter((item) => item !== permission))
    } else {
      setSelectedPermissions((prevState) => [...prevState, permission])
    }
  }

  const handleContinue = () => {
    if (!status) return

    const handleNextStep = {
      onlyAdmin: () => addAdmin(),
      onlyManager: () => handleStepChange(2),
      adminAndManager: () => addAdmin(),
      removingAdmin: () => removeAdminAndAddManager(),
      removingAdminAndAddManager: () => removeAdminAndAddManager(),
    }

    handleNextStep[status]()
  }

  const addAdmin = async () => {
    track(EventsImplementation.EDIT_PERMISSIONS_CONFIMATION('Acesso de administrador'))

    await updateRole({
      variables: {
        id: String(selectedCollab.id),
        roles: [...(selectedCollab.roles || []), Role.ADMIN],
      },
    })

    handleStepChange(1)
  }

  const removeAdminAndAddManager = async () => {
    track(EventsImplementation.EDIT_PERMISSIONS_CONFIMATION('Remoção de administrador'))

    await removeAdmin({
      variables: {
        id: +selectedCollab.id,
      },
    })
    handleStepChange(1)
  }

  return (
    <>
      <Text
        color='#767F89'
        textDecoration='underline'
        fontFamily='Poppins'
        fontWeight='600'
        marginBottom='22px'
        cursor='pointer'
        onClick={handleTogglePermissionsDrawer}
      >
        Fechar
      </Text>
      <VStack align='flex-start'>
        <Text fontSize='20px' fontWeight={600} fontFamily='Poppins' color='gray.31'>
          {t('selectPermissionsToAssignToCollab', { collabName: selectedCollab.name })}
        </Text>
        <Text color='#767F89' mb='24px'>
          {t('selectDesireAssignment')}
        </Text>

        <VStack w='100%' spacing='16px' my='24px !important'>
          {permissionsOptions(selectedCollab, t).map((item) => {
            const shouldDisablePermissionButton = item.value === 'manager' && !isColabFromSameCompanyOfLoggedUser
            return (
              <Tooltip
                isDisabled={!shouldDisablePermissionButton}
                hasArrow
                borderRadius='8px'
                bg='gray.800'
                label={t('thisUserIsNotFromYourCompany')}
              >
                <Flex
                  key={item.value}
                  align='center'
                  w='100%'
                  h='43px'
                  px='14px'
                  borderRadius='8px'
                  gridGap='7px'
                  boxShadow='0px 1px 6px rgba(0, 0, 0, 0.1)'
                  cursor='pointer'
                  opacity={shouldDisablePermissionButton ? 0.5 : 1}
                  onClick={() => (shouldDisablePermissionButton ? null : handleSelect(item.value))}
                  border='2px solid'
                  borderColor={selectedPermissions.includes(item.value) ? 'primaryColors.650' : 'transparent'}
                >
                  <Text fontSize='12px' fontWeight={700} color='#767F89'>
                    {item.label}
                  </Text>
                  {item.info ? (
                    <Tooltip hasArrow placement='right' fontSize='12px' label={item.info}>
                      <Box>
                        <IoHelpCircle color='#767F89' />
                      </Box>
                    </Tooltip>
                  ) : null}
                </Flex>
              </Tooltip>
            )
          })}
        </VStack>

        <Flex w='100%' justify='flex-end' mt='24px'>
          <Button
            variant='solidGray'
            fontSize='12px'
            disabled={selectedPermissions.length === 0 || loading || removeAdminLoading}
            isLoading={loading || removeAdminLoading}
            onClick={handleContinue}
          >
            {t('login.continue')}
          </Button>
        </Flex>
      </VStack>
    </>
  )
}

export default SelectPermissions
