import { Flex } from '@chakra-ui/react'
import { FormikProps } from 'formik'
import { RequiredFields } from 'src/pages-admin/Cms/components/ContentForm/RequiredFields'
import { IContentData } from 'src/pages-admin/Cms/hooks/useRegisterContentStore'
import { DynamicFields } from './DynamicFields'

type Props = {
  form: FormikProps<IContentData>
}

export function ContentForm({ form }: Props) {
  return (
    <Flex flexDir='column' gap='16px'>
      <RequiredFields form={form} />
      <DynamicFields form={form} />
    </Flex>
  )
}
