import { useDisclosure } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRegisterNewContent } from 'src/pages-admin/Cms/hooks/useRegisterNewContent'
import { CmsPlaylistTable } from '../components/PlaylistTable'
import { RemoveContentDrawer } from '../components/RemoveContentDrawer'
import { CmsTabContent } from '../components/TabContent'
import { useCorporateUniversityStore } from '../hooks/useCorporateUniversityStore'
import useListPlaylists from '../hooks/useListPlaylists'
import { useListPrivatePlaylists } from '../hooks/useListPrivatePlaylists'
import { useRegisterPlaylistStore } from '../hooks/useRegisterPlaylistStore'

interface PlaylistTabProps {
  tab: string
}

export function PlaylistTab({ tab }: PlaylistTabProps) {
  const { refetch, listPlaylists, isPlaylistsLoading } = useListPlaylists()
  const { updateRegisterPlaylistModalState } = useRegisterPlaylistStore()
  const { updateSelectedPermission, updateSavedSelectedCompanies } = useRegisterNewContent()
  const {
    totalPlaylists,
    selectedPlaylistPrivacy,
    totalPrivatePlaylists,
    updateSelectedContent,
    updateSelectedPlaylist,
    updateSkip,
  } = useCorporateUniversityStore()
  const { isPrivatePlaylistsLoading } = useListPrivatePlaylists()

  const { isOpen, onClose, onOpen } = useDisclosure()
  const [t] = useTranslation()

  const isPublicPlaylist = selectedPlaylistPrivacy === 'public'
  const totalPlaylistLengthToShow = !isPublicPlaylist ? totalPrivatePlaylists : totalPlaylists

  const handleOpenContentModal = () => {
    updateSelectedContent(undefined)
    updateSelectedPlaylist(undefined)
    updateSelectedPermission('')
    updateSavedSelectedCompanies([])
    updateRegisterPlaylistModalState(true)
  }

  useEffect(() => {
    if (isPublicPlaylist) listPlaylists({ variables: { type: 'FEATURED' } })

    updateSelectedContent(undefined)
    updateSelectedPlaylist(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPublicPlaylist])

  useEffect(() => {
    return () => {
      updateSkip(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <RemoveContentDrawer isOpen={isOpen} onClose={onClose} refetch={refetch} isPlaylist />

      <CmsTabContent
        pageTitle={t('admin.corporateUniversity.playlists.pageTitle')}
        registerNewContentTitle={t('admin.corporateUniversity.playlists.registerNewContent')}
        handleRegisterNewContentClick={handleOpenContentModal}
        searchSectionTitle={t('admin.corporateUniversity.playlists.searchSectionTitle')}
        searchPlaceholder={t('admin.corporateUniversity.playlists.searchForContent')}
        tabTable={CmsPlaylistTable}
        tab={tab}
        totalContentLength={totalPlaylistLengthToShow}
        isLoading={isPublicPlaylist ? isPlaylistsLoading : isPrivatePlaylistsLoading}
        refetch={refetch}
        onOpen={onOpen}
      />
    </>
  )
}
