import {
  Button,
  Icon,
  Link as ChakraLink,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { IoLogoLinkedin } from 'react-icons/io5'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { EventsImplementation } from 'src/hooks/Mixpanel/types'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'

export function ShareButton() {
  const { certificate, playlist } = useCustomPlaylist()
  const { t } = useTranslation()
  const { track } = useMixpanel()

  const certificateDate = new Date(certificate?.createdAt ?? Date.now())

  return (
    <Popover trigger='hover' placement='top'>
      {!certificate ? (
        <PopoverContent
          backgroundColor='gray.800'
          borderRadius='20px'
          width={'fit-content'}
          border='none'
          zIndex={'popover'}
        >
          <PopoverArrow backgroundColor='gray.800' />
          <PopoverBody textAlign={'center'}>
            <Text fontSize='14px' color='white' fontWeight={700}>
              {t('playlist.You need to complete the playlist to share your certificate')}
            </Text>
          </PopoverBody>
        </PopoverContent>
      ) : null}

      <PopoverTrigger>
        {certificate ? (
          <ChakraLink
            textDecoration='none'
            href={`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
              playlist?.title
            }&organizationId=68483898&issueYear=${certificateDate?.getFullYear()}&issueMonth=${
              certificateDate ? certificateDate.getMonth() + 1 : ''
            }&certUrl=${certificate?.url}&certId=${certificate?.playlistId}`}
            target='_blank'
            w={{ base: '100%', lg: 'auto' }}
          >
            <Button
              variant='startCourseDark'
              onClick={() => {
                track(EventsImplementation.SHARE_CERTIFICATE_ON_LINKEDIN(playlist?.title ?? 'Trilha Personalizada'))
              }}
              marginLeft={{ base: '0px', lg: '25px' }}
              disabled={certificate ? false : true}
              w={{ base: '100%', lg: 'auto' }}
            >
              <Icon as={IoLogoLinkedin} w='24px' h='24px' />
              <Text fontFamily='Poppins' fontWeight='600' marginLeft='8px'>
                Linkedin
              </Text>
            </Button>
          </ChakraLink>
        ) : (
          <Button
            variant='startCourseDark'
            marginLeft={{ base: '0px', lg: '25px' }}
            w={{ base: '100%', lg: 'auto' }}
            disabled={certificate ? false : true}
          >
            <Icon as={IoLogoLinkedin} w='24px' h='24px' />
            <Text fontFamily='Poppins' fontWeight='600' marginLeft='8px'>
              Linkedin
            </Text>
          </Button>
        )}
      </PopoverTrigger>
    </Popover>
  )
}
