import { Box, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
const ItemInBetween = () => {
  const [t] = useTranslation()
  return (
    <Flex alignItems='center' justifyContent='center' display={['none', 'none', 'none', 'flex', 'flex']}>
      <Box bg='primaryColors.400' w='59px' h='59px' position='absolute' top='283px' borderRadius='50px'>
        <Text
          h='100%'
          w='100%'
          fontFamily='Mulish'
          fontWeight='bold'
          textAlign='center'
          fontSize='28px'
          paddingTop='6px'
          color='white'
        >
          {t('activatePartner.Or')}
        </Text>
      </Box>
    </Flex>
  )
}

export default ItemInBetween
