import { Flex, Icon, Text } from '@chakra-ui/react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { IoReorderThree } from 'react-icons/io5'

type Item = {
  id: number | string
  title: string
}

type Props = {
  items: Item[]
}

const DraggableItems: React.FC<Props> = ({ items }) => {
  return (
    <Flex align='center' gap='16px' w='100%'>
      <Flex direction='column' mt='24px'>
        {Array.from({ length: items.length }).map((item, index) => (
          <Flex key={index} align='center' h='48px' mb='12px'>
            <Text fontFamily='Poppins' fontWeight={600} fontSize='20px'>
              {index + 1}.
            </Text>
          </Flex>
        ))}
      </Flex>

      <Droppable droppableId={'droppable'}>
        {(provided) => (
          <Flex {...provided.droppableProps} ref={provided.innerRef} mt='24px' direction='column' w='100%'>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                {(provided) => (
                  <Flex
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    w='100%'
                    maxW='90%'
                    h='48px'
                    align='center'
                    gap='10px'
                    p='8px 24px'
                    bg='primaryColors.30'
                    borderRadius='16px'
                    border='2px solid transparent'
                    _hover={{
                      borderColor: 'primaryColors.650',
                    }}
                    _active={{
                      color: 'primaryColors.650',
                      borderColor: 'primaryColors.650',
                    }}
                    userSelect='none'
                    mb='12px'
                    overflowX='auto'
                    overflowY='hidden'
                    css={{
                      '&::-webkit-scrollbar': {
                        height: '0px',
                        display: 'none',
                        cursor: 'regular !important',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#303a4e',
                      },
                    }}
                  >
                    <Icon w='24px' h='24px' color='primaryColors.650' as={IoReorderThree} />

                    <Text
                      fontWeight={700}
                      fontSize='18px'
                      whiteSpace='nowrap'
                      overflow={{ base: 'unset', lg: 'hidden' }}
                      w='100%'
                    >
                      {item.title}
                    </Text>
                  </Flex>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Flex>
        )}
      </Droppable>
    </Flex>
  )
}
export default DraggableItems
