import { UseCase } from 'src/types/UseCase'
import { Team } from '../entities/Team'
import { TeamMember } from '../entities/TeamMember'
import { TeamRepository } from '../repository/TeamRepository'

export type CreateNewTeamInput = {
  teamName: string
  teamMembers: TeamMember[]
}

export type CreateNewTeamOutput = Team

export class CreateNewTeamUseCase implements UseCase<CreateNewTeamInput, CreateNewTeamOutput> {
  constructor(private repository: TeamRepository) {}

  async execute(args: CreateNewTeamInput): Promise<CreateNewTeamOutput> {
    const team = await this.repository.createNewTeam({
      teamName: args?.teamName,
      teamMembers: args?.teamMembers.map(item => ({ id: item.id, type: item.type })),
    })
    return team
  }
}
