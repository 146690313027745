import NewPlaylist, { ModuleList } from "src/modules/cms/domain/Playlist";
import { ICreatePlaylist } from "../types/IPlaylist";

export const getContentsFromModuleList = (values: NewPlaylist) => {
  const modules = values.moduleList ?? [] as ModuleList

  const contentList = modules?.items?.map((module) => {
    return module?.contentList?.items ?? []
  }) ?? []

  const mergedContentList = contentList?.flat().map((content, index) => ({
    ...content,
    order: index + 1
  }))

  return [{
    title: 'Módulo 1',
    order: 1,
    contents: mergedContentList
  }]
}

export const registerPlaylistInitialValues = (values = {} as NewPlaylist): ICreatePlaylist => ({
  title: values.title ?? '',
  type: values.type ?? '',
  cardImageUrl: values.cardImageUrl ?? '',
  allowedCompanyIds: values.allowedCompanyIds ?? [] as number[],
  imageUrl: values.imageUrl ?? '',
  description: values.description ?? '',
  // eslint-disable-next-line no-extra-boolean-cast
  status: !!values.status ? values.status : '',
  color: values.color ?? '',
  certificateImageUrl: values.certificateImageUrl ?? '',
  certificateTemplateUrl: values.certificateTemplateUrl ?? '',
  modules: getContentsFromModuleList(values) ?? [] as any[],
  learningPoints: [] as any[],
  contentTranslation: {
    es: {
      title: values.translations?.find((translation) => translation.language === 'es')?.title ?? '',
      description: values.translations?.find((translation) => translation.language === 'es')?.description ?? ''
    }
  }
})