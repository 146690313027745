import { UseCase } from 'src/types/UseCase'
import { Team } from '../entities/Team'
import { TeamRepository } from '../repository/TeamRepository'

export type RemoveTeamManagersInput = {
  teamId: string
  managersIds: number[]
}

export type RemoveTeamManagersOutput = Team

export class RemoveTeamManagersUseCase implements UseCase<RemoveTeamManagersInput, RemoveTeamManagersOutput> {
  constructor(private repository: TeamRepository) {}

  async execute(args: RemoveTeamManagersInput): Promise<RemoveTeamManagersOutput> {
    const team = await this.repository.removeTeamManagers(args)
    return team
  }
}
