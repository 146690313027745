import { useUser } from 'src/context/userContext'
import { makeMarkContent } from 'src/modules/cms/factories/makeMarkContent'
import { ContentAction } from 'src/modules/cms/use_cases/MarkContentUseCase'

const useRegisterAccess = () => {
  const { getUser } = useUser()
  const markContent = makeMarkContent()

  const register = async (entryId: string) => {
    await markContent.execute({ id: entryId, action: ContentAction.ACCESSED })
    getUser()
  }

  return register
}

export default useRegisterAccess
