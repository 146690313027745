export const isValidEmail = (email?: string) => {
  if (!email) return false
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const isValidPassword = (password: string) => {
  const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]).{8,}$/
  return re.test(password)
}

interface MailToParams {
  email: string
  cc?: string[]
  subject: string
  body: string
}

export const mailTo = (params: MailToParams) => {
  const { email, cc, subject, body } = params

  if (!email) {
    return
  }

  let items: Array<string> = []
  if (Object.keys(params).length > 1) {
    items = []
    if (cc) {
      items = items.concat(cc.map((ccItem) => `cc=${ccItem}`))
    }
    if (subject) {
      items.push(`subject=${encodeURIComponent(subject)}`)
    }
    if (body) {
      items.push(`body=${encodeURIComponent(body)}`)
    }
  }
  const link = `mailto:${email}${items ? `?${items.join('&')}` : ''}`
  window.location.href = link
}
