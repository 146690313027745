import { Box, Flex, Heading, Input, Select, Text } from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuiz } from 'src/context/QuizContext'
import ButtonsFooter from './ButtonsFooter'
import StepHeader from './StepHeader'

interface Props {
  onContinueButtonClick: () => void
  onSkipButtonClick: () => void
}

const StepOne: React.FC<Props> = ({ onContinueButtonClick, onSkipButtonClick }: Props) => {
  const { quizResults, updateQuiz, sendMutationQuiz } = useQuiz()
  const [t] = useTranslation()
  const [otherMotivation, setOtherMotivation] = useState<string | undefined>('')

  const motivation = useMemo(
    () => [
      t('quiz.stepOne.motivation.leadership'),
      t('quiz.stepOne.motivation.developTechnicalSkills'),
      t('quiz.stepOne.motivation.getARaise'),
      t('quiz.stepOne.motivation.other'),
    ],
    [t],
  )

  const motivationEnglish = [
    'I want to occupy leadership positions',
    'I want to develop more technical skills',
    'I want to get a raise',
    'Another motive',
  ]

  /*
  useEffect(() => {
    if (quizResults.motivation && !motivation.includes(quizResults.motivation)) {
      setOtherMotivation(quizResults.motivation)
    }
  }, [quizResults.motivation, motivation])
  */

  const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    updateQuiz('careerStage', event.target.value)
  }

  const handleChangeDevelopmentPlan = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    updateQuiz('developmentPlan', event.target.value)
  }

  const handleNextStep = () => {
    if (isOtherMotivation) {
      updateQuiz('motivation', otherMotivation)
    }
    sendMutationQuiz()
    onContinueButtonClick()
  }

  const isOtherMotivation =
    quizResults.motivation !== undefined ? motivationEnglish.indexOf(quizResults.motivation) === -1 : false

  return (
    <>
      <StepHeader step={1} />
      <Box flex='1'>
        <Box>
          <Heading size='xs'>{t('quiz.stepOne.currentCareerMoment')}</Heading>
          <Select
            type='text'
            maxW='320px'
            h='40px'
            mt='16px'
            borderRadius='15px'
            color={!quizResults.careerStage ? 'gray.400' : 'gray.800'}
            value={quizResults.careerStage}
            onChange={handleChange}
            placeholder={t('quiz.chooseAnOption')}
          >
            <option value='INTERN'>{t('quiz.stepOne.intern')}</option>
            <option value='ASSISTANT'>{t('quiz.stepOne.assistant')}</option>
            <option value='COORDINATOR'>{t('quiz.stepOne.coordinator')}</option>
            <option value='MANAGER'>{t('quiz.stepOne.manager')}</option>
            <option value='DIRECTOR'>{t('quiz.stepOne.director')}</option>
            <option value='PRESIDENT'>{t('quiz.stepOne.president')}</option>
          </Select>
        </Box>
        <Box mt='32px'>
          <Heading size='xs'>{t('quiz.stepOne.skillsToDevelop')}</Heading>
          <Select
            type='text'
            maxW='892px'
            h='40px'
            mt='16px'
            borderRadius='15px'
            value={quizResults.developmentPlan}
            color={!quizResults.developmentPlan ? 'gray.400' : 'gray.800'}
            onChange={handleChangeDevelopmentPlan}
            placeholder={t('quiz.chooseAnOption')}
          >
            <option value='Yes, I have a concrete development plan in line with my manager.'>
              {t('quiz.stepOne.developmentPlan.concretePlan')}
            </option>
            <option value={`I have a development plan but it's not very clear.`}>
              {t('quiz.stepOne.developmentPlan.hasPlanNotVeryClear')}
            </option>
            <option value='I have an idea but not sure.'>
              {t('quiz.stepOne.developmentPlan.notionOfThePlanButNotSure')}
            </option>
            <option value={`I don't know.`}>{t('quiz.stepOne.developmentPlan.dontKnow')}</option>
          </Select>
        </Box>
        <Box mt='32px'>
          <Flex>
            <Heading size='xs'>{t('quiz.stepOne.professionalQualification')}</Heading>
            <Text color='gray.190' fontWeight='600' ml='8px'>
              {t('quiz.optional')}
            </Text>
          </Flex>
          <Text color='bluishGray.500' fontWeight='600'>
            {t('quiz.stepOne.unsharedInformation')}
          </Text>
          <Flex>
            {motivation.map((item: string, index) => {
              const isItemSelected =
                quizResults.motivation === motivationEnglish[index] || (index === 3 ? isOtherMotivation : false)
              return (
                <Box
                  key={item}
                  border='2px solid'
                  borderColor={isItemSelected ? 'primaryColors.650' : 'gray.70'}
                  bgColor={isItemSelected ? 'primaryColors.45' : ''}
                  borderRadius='30px'
                  color='gray.800'
                  padding='14px 24px'
                  minH='76px'
                  marginRight='12px'
                  fontWeight='600'
                  fontSize='16px'
                  fontFamily='Poppins'
                  marginTop='16px'
                  textAlign='center'
                  cursor='pointer'
                  onClick={() => updateQuiz('motivation', index === 3 ? '' : motivationEnglish[index])}
                  _hover={{ backgroundColor: 'gray.43' }}
                >
                  {item}
                </Box>
              )
            })}
          </Flex>
          {isOtherMotivation && (
            <Input
              value={otherMotivation}
              variant='unstyled'
              height='40px'
              margin='16px 0'
              bgColor='white'
              border='2px solid'
              borderColor='gray.70'
              borderRadius='15px'
              onChange={(event) => setOtherMotivation(event.target.value)}
              placeholder={t('quiz.writeTheReason')}
            />
          )}
        </Box>
      </Box>
      <ButtonsFooter
        step={1}
        onContinueButtonClick={handleNextStep}
        onSkipButtonClick={onSkipButtonClick}
        disabledButton={quizResults.careerStage === undefined || quizResults.developmentPlan === ''}
      />
    </>
  )
}

export default StepOne
