import { useMutation } from '@apollo/client'
import { Accordion, Flex, Icon, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MdCancel } from 'react-icons/md'
import { makeCreateContent } from 'src/modules/cms/factories/makeCreateContent'
import { makeUpdateContent } from 'src/modules/cms/factories/makeUpdateContent'
import { UPLOAD_FILE } from 'src/modules/cms/graphql/mutations/UPLOAD_FILE'
import { makeSaveContentTranslations } from 'src/modules/cms/use_cases/SaveContentTranslationUseCase'
import { ContentAddedDrawer } from 'src/pages-admin/Cms/components/ContentAddedDrawer'
import { FormAccordion } from 'src/pages-admin/Cms/components/ContentModal/FormAccordion'
import { PublishContent } from 'src/pages-admin/Cms/components/ContentModal/PublishContent'
import { RemoveContentDrawer } from 'src/pages-admin/Cms/components/RemoveContentDrawer'
import { useCorporateUniversityStore } from 'src/pages-admin/Cms/hooks/useCorporateUniversityStore'
import useListContents from 'src/pages-admin/Cms/hooks/useListContents'
import { IContentData, useRegisterContentStore } from 'src/pages-admin/Cms/hooks/useRegisterContentStore'
import { registerContentInitialValues } from 'src/pages-admin/Cms/utils/registerContentInitialValues'
import { scrollToPositionY } from 'src/pages/PlaylistPage/utils'
import { cmsQueryContext } from 'src/services/apollo/ApolloClient'
import { RegisterContentFormSchema } from '../../utils/registerContentFormSchema'
import { PermissionsAccordion } from '../Accordions/PermissionsAccordion'

interface Props {
  close?: () => void
  padding?: string
}

export function ContentModal({ close, padding }: Props) {
  const [t] = useTranslation()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { isOpen: isDeleteDrawerOpen, onClose: onDeleteDrawerClose, onOpen: onDeleteDrawerOpen } = useDisclosure()
  const { updateLoading } = useRegisterContentStore()
  const { selectedContent } = useCorporateUniversityStore()
  const { refetch } = useListContents()
  const updateContent = makeUpdateContent()
  const createContent = makeCreateContent()
  const isEditMode = Object.keys(selectedContent ?? {}).length !== 0
  const [uploadFile] = useMutation(UPLOAD_FILE, {
    context: cmsQueryContext,
  })
  const toast = useToast()

  useEffect(() => {
    scrollToPositionY(0, '#layout-page')
  }, [])

  const saveTranslations = async (values: IContentData, newContent = false) => {
    try {
      if (!values.id) throw new Error()
      const saveContentTranslations = makeSaveContentTranslations()
      const wasTranslationsSaved = await saveContentTranslations.execute({
        contentId: values.id!,
        isUpdate: newContent ? false : selectedContent?.translations?.findIndex((t) => t.language === 'es') !== -1,
        language: 'es',
        contentInfo: {
          ...(values?.contentTranslation?.es?.title && { title: values.contentTranslation.es.title }),
          ...(values?.contentTranslation?.es?.description && {
            description: values.contentTranslation.es.description,
          }),
        },
      })

      if (!wasTranslationsSaved) {
        toast({
          title: t('theTranslationsOfTitleAndDescriptionWasNotSaved'),
          description: t('anErrorOccuredWhenSavingTranslationsEditContentToTryAgain'),
          status: 'warning',
          duration: 10000,
        })
      } else {
        toast({
          title: t('theTranslationsWasSavedSuccessfully'),
          status: 'success',
          duration: 3000,
        })
      }
    } catch {
      toast({
        title: t('theTranslationsOfTitleAndDescriptionWasNotSaved'),
        description: t('anErrorOccuredWhenSavingTranslationsEditContentToTryAgain'),
        status: 'warning',
        duration: 10000,
      })
    }
  }

  const form = useFormik<IContentData>({
    initialValues: registerContentInitialValues(selectedContent),
    validationSchema: RegisterContentFormSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      let uploadFileResponse: any = {}

      updateLoading(true)

      if (typeof values.cover !== 'string') {
        uploadFileResponse = await uploadFile({
          variables: {
            file: values.cover,
          },
        })
      }

      if (isEditMode) {
        await saveTranslations({ ...values, id: selectedContent.id }, false)
      }

      let createdContentId: string | undefined = undefined

      const hasSuccess = isEditMode
        ? await updateContent.execute({
            ...values,
            id: selectedContent.id,
            imageUrl: typeof values.cover !== 'string' ? uploadFileResponse.data.uploadFile : values.cover,
          })
        : (createdContentId = await createContent.execute({
            ...values,
            imageUrl: uploadFileResponse.data.uploadFile,
          }))

      if (!isEditMode) {
        try {
          await saveTranslations({ ...values, id: createdContentId }, true)
        } catch (err) {
          console.error('An error occur on saving translations', err)
        }
      }

      await refetch()
      updateLoading(false)
      if (hasSuccess) {
        onOpen()
      }
    },
  })

  const handleRemoveContent = useCallback(() => {
    onDeleteDrawerOpen()
  }, [onDeleteDrawerOpen])

  const handleCloseRemoveDrawer = useCallback(() => {
    onDeleteDrawerClose()
  }, [onDeleteDrawerClose])

  const closeDrawers = () => {
    if (close) close()
    onClose()
  }

  return (
    <>
      <Flex bgColor='white' h='100%' pos='fixed' left={0} right={0}>
        <Flex p={padding ? padding : '48px 215px'} flexDir='column' w='100%' overflowY='auto'>
          <Text color='#313E4C' fontSize='1.5rem' fontWeight={700} fontFamily='Poppins'>
            {isEditMode ? `${t('edition')}: ${selectedContent?.title}` : t('newContent')}
          </Text>
          <Text color='#767F89' fontSize='1rem' mt='4px'>
            {isEditMode
              ? t('hereYouCanEditContentInfo', { contentTitle: selectedContent?.title })
              : t('hereYouCanRegisterContentInfo')}
          </Text>

          {isEditMode ? (
            <Flex
              w='96px'
              h='36px'
              justify='center'
              align='center'
              mt='22px'
              gap='8px'
              border='2px solid'
              borderColor='gray.800'
              borderRadius='12px'
              cursor='pointer'
              onClick={handleRemoveContent}
            >
              <Icon as={MdCancel} w='24px' h='24px' color='gray.800' data-testid='cms-remove-content-button-on-edit' />
              <Text fontFamily='Poppins' fontWeight='600' fontSize='12px' lineHeight='21px' color='gray.800'>
                {t('delete')}
              </Text>
            </Flex>
          ) : null}

          <form onSubmit={form.handleSubmit}>
            <Flex mb='64px'>
              <Accordion allowToggle w='100%'>
                <PermissionsAccordion form={form} />
                <FormAccordion form={form} />
              </Accordion>
            </Flex>

            <PublishContent form={form} close={close} />
          </form>
        </Flex>
      </Flex>

      {isOpen && <ContentAddedDrawer isOpen={isOpen} onClose={closeDrawers} isEdit={isEditMode} />}
      <RemoveContentDrawer isOpen={isDeleteDrawerOpen} onClose={handleCloseRemoveDrawer} refetch={refetch} />
    </>
  )
}
