import { Flex, Input, Text } from '@chakra-ui/react'

interface SearchContentProps {
  title?: string
  placeholder?: string
  searchContent: (value: string) => void
}

export function SearchContent({ searchContent, title, placeholder }: SearchContentProps) {
  return (
    <Flex w='100%' flexDir='column'>
      <Text color='bluishGray.500'>{title ?? ''}</Text>

      <Input
        onChange={(e) => searchContent(e.target.value)}
        placeholder={placeholder ?? ''}
        mt='4px'
        style={{ height: 36 }}
        fontSize='.875rem'
        padding='0px 16px'
        data-testid='cms-search-content-input'
      />
    </Flex>
  )
}
