export enum Competences {
  'LEADERSHIP' = 'LEADERSHIP',
  'COOPERATION' = 'COOPERATION',
  'INFLUENCE' = 'INFLUENCE',
  'SCENARIO_ANALYSIS' = 'SCENARIO_ANALYSIS',
  'INOVATION' = 'INOVATION',
  'EXECUTION' = 'EXECUTION',
  'ADAPTATION' = 'ADAPTATION',
  'REALIZATION' = 'REALIZATION',
}

export type QuizResults = {
  careerStage?: 'INTERN' | 'ASSISTANT' | 'COORDINATOR' | 'MANAGER' | 'DIRECTOR' | 'PRESIDENT'
  developmentPlan?: string
  motivation?: string
  competences?: Competences[]
  themes?: string[]
  skillsStartingPoint?: string[]
  skillsEndingPoint?: string[]
  dedicationTime?: 'ONETOFIVE' | 'FIVETOTEN' | 'MORETHANTEN'
  deadline?: number
  languages: string[]
  updatedAt?: string
}
