import { Flex, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AbilitiesSelect from 'src/components/AbilitiesQuiz/components/AbilitiesSelect'
import { useQuiz } from 'src/context/QuizContext'
import useRegisterUserEvent from 'src/hooks/useRegisterUserEvent'
import { GetQuizCompetences } from 'src/values/quiz'
import ButtonsFooter from './ButtonsFooter'
import StepHeader from './StepHeader'
import { convertCompetencesEnumToStrings, convertCompetencesStringsToEnum } from './utils'

interface Props {
  onContinueButtonClick: () => void
  onBackButtonClick: () => void
  onSkipButtonClick: () => void
}

const StepTwo: React.FC<Props> = ({ onContinueButtonClick, onBackButtonClick }: Props) => {
  const [t] = useTranslation()
  const [valid, setValidState] = useState<boolean>(false)

  const { quizResults, updateQuiz, sendMutationQuiz } = useQuiz()
  const registerUserEvent = useRegisterUserEvent()

  const competences = quizResults.competences || []

  const handleNextStep = () => {
    registerUserEvent('quiz-competence', competences)
    sendMutationQuiz()
    onContinueButtonClick()
  }

  return (
    <>
      <StepHeader step={2} />
      <Flex flexDirection='column' flex={1}>
        <Text fontWeight='800' fontSize='20px'>
          {t('quiz.stepTwo.mostImportantSkills')}
        </Text>
        <AbilitiesSelect
          setValidState={setValidState}
          maxItems={4}
          options={GetQuizCompetences()}
          shouldReduceSelectedItems
          activeAbilities={convertCompetencesEnumToStrings(competences, t)}
          onChange={(competences: string[]) =>
            updateQuiz('competences', convertCompetencesStringsToEnum(competences, t))
          }
          shouldAlignOptionsTo={'left'}
        />
      </Flex>
      <ButtonsFooter
        step={3}
        onContinueButtonClick={handleNextStep}
        onBackButtonClick={onBackButtonClick}
        disabledButton={!valid || !competences.length}
      />
    </>
  )
}

export default StepTwo
