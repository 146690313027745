import { Box, Flex } from '@chakra-ui/react'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import Analytics from './Analytics'
import Title from './Title'

const Header: React.FC = () => {
  const { playlist } = useCustomPlaylist()

  if (!playlist) return null

  return (
    <Flex padding={{ base: '23px 20px', lg: '23px 72px' }} flexDir='column'>
      <Flex justifyContent='space-between' flexDir={{ base: 'column', lg: 'row' }}>
        <Title playlist={playlist} />
        <Box marginTop={{ base: '8px', lg: '0px' }}>
          <Analytics playlist={playlist} />
        </Box>
      </Flex>
    </Flex>
  )
}

export default Header
