import Content from 'src/modules/cms/domain/Content'
import { ContentType } from 'src/modules/cms/domain/LearningItem'
import Course from './Course'
import NonCourse from './NonCourse'

interface Props {
  content: Content
  module: string
}

const AdditionalInformation: React.FC<Props> = ({ content, module }: Props) => {
  return (
    <>
      {content.type === ContentType.COURSE ? (
        <Course content={content} />
      ) : (
        <NonCourse content={content} module={module} />
      )}
    </>
  )
}

export default AdditionalInformation
