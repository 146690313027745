import { Flex, Icon, Text } from '@chakra-ui/react'
import { useFeedback } from 'src/context/FeedbackContext'
import { FeedbackIcon } from 'src/theme/Icons'

const Feedback: React.FC = () => {
  const { openFeedback } = useFeedback()
  return (
    <Flex
      backgroundColor='primaryColors.650'
      padding={{ base: '4px 8px', md: '12px 16px' }}
      borderRadius='8px 8px 0px 0px'
      position='fixed'
      right='-46px'
      bottom={{ base: '160px', md: '200px' }}
      zIndex='4'
      transform='rotate(-90deg)'
      cursor='pointer'
      onClick={() => openFeedback({ step: 0 })}
      display={{ base: 'none', lg: 'flex' }}
    >
      <Icon as={FeedbackIcon} color='white' transform='rotate(90deg)' />
      <Text
        fontSize={{ base: '0.875rem', md: '1rem' }}
        fontWeight='500'
        fontFamily='Poppins'
        lineHeight='19px'
        marginLeft='8px'
        color='white'
      >
        Feedback
      </Text>
    </Flex>
  )
}

export default Feedback
