import { gql } from '@apollo/client'

export const GET_PLAYLIST = gql`
  query playlist($id: String!) {
    playlist(id: $id) {
      id
      type
      title
      status
      allowedCompanyIds
      description
      color
      imageUrl
      cardImageUrl
      certificateImageUrl
      certificateTemplateUrl
      learningPointList {
        total
        items {
          id
          title
          description
          iconUrl
        }
      }
      moduleList {
        total
        items {
          id
          title
          order
          contentList {
            total
            items {
              id
              contentfulId
              type
              order
              title
              description
              duration
              url
              imageUrl
              allowsEmbedding
              language
              languages
              subtitles
              status
              updatedAt
              trailerUrl
              proficiencyLevel {
                name
                color
              }
              partner {
                name
                squareLogoUrl
                logoUrl
                description
                coverColor
                id
                slug
              }
              skills {
                id
                name
                translations {
                  name
                  language
                }
              }
              translations {
                content_id
                title
                description
                language
                created_at
                updated_at
                deleted_at
              }
            }
          }
        }
      }
      translations {
        playlist_id
        language
        title
        description
        created_at
        updated_at
      }
    }
  }
`
