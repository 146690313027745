import Content from 'src/modules/cms/domain/Content'
import { ContentType } from 'src/modules/cms/domain/LearningItem'

export const getCoursesList = (contents: Content[]) => {
  return contents.filter((item) => item.type === ContentType.COURSE)
}

export const getVideosList = (contents: Content[]) => {
  return contents.filter((item) => item.type === ContentType.VIDEO)
}

export const getArticlesList = (contents: Content[]) => {
  return contents.filter((item) => item.type === ContentType.ARTICLE)
}
