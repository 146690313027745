import { TFunction } from 'i18next'
import { Role } from 'src/types/Role'
import { User } from 'src/types/User'

export const permissionsOptions = (collab: User, t: TFunction) => {
  const isAdmin = collab.roles?.includes(Role.ADMIN)
  const isManager = collab.roles?.includes(Role.MANAGER)

  return [
    ...(!isManager
      ? [
          {
            value: 'manager',
            label: `Acesso de ${t('admin.common.manager')}`,
            info: 'Podem acompanhar o progresso de suas equipes',
          },
        ]
      : []),
    ...(!isAdmin
      ? [
          {
            value: "admin",
            label: "Acesso de Administrador",
            info: "Acampanham o acesso de toda a empresa e podem atribuir permissões",
          },
        ]
      : []),
    ...(isAdmin
      ? [
          {
            value: "removeAdmin",
            label: "Remover acesso de Administrador",
            info: "",
          },
        ]
      : []),
  ];
};

export const returnPermissions = (selectedPermissions: string[]) => {
  if (selectedPermissions.length === 1 && selectedPermissions.includes("admin"))
    return "onlyAdmin";

  if (
    selectedPermissions.length === 1 &&
    selectedPermissions.includes("manager")
  )
    return "onlyManager";

  if (
    selectedPermissions.length === 1 &&
    selectedPermissions.includes("removeAdmin")
  )
    return "removingAdmin";

  if (
    selectedPermissions.length === 2 &&
    selectedPermissions.includes("manager") &&
    selectedPermissions.includes("removeAdmin")
  )
    return "removingAdminAndAddManager";

  if (
    selectedPermissions.length === 2 &&
    selectedPermissions.includes("admin") &&
    selectedPermissions.includes("manager")
  )
    return "adminAndManager";
};
