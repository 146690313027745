import { Button, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { usePermissions } from '../../../../../context-admin/usePermissions'
import { useMixpanel } from '../../../../../hooks-admin/mixpanel/useMixpanel'

const RemoveAccessButton = () => {
  const [t] = useTranslation()
  const { EventsImplementation, track } = useMixpanel()
  const { collabsToRemoveAccess, toggleRemoveAccessModal } = usePermissions()

  const handleClick = () => {
    track(EventsImplementation.REMOVE_USERS_BUTTON())
    toggleRemoveAccessModal(true)
  }

  return (
    <Button variant='solidGray' mr='9px' disabled={collabsToRemoveAccess.length === 0} onClick={handleClick}>
      <Text fontFamily='Poppins' fontWeight='bold' fontSize='12px'>
        {t('removeAccesses')}
      </Text>
    </Button>
  )
}

export default RemoveAccessButton
