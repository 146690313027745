export interface MixpanelEvent {
  eventName: string;
  properties: Record<string, unknown>;
  callbackToBeExecuted?: () => void;
  beforeTracking?: () => void;
}

export const MixpanelEvents = {
  COURSE_DASH_FILTER: "Admin course dashboard - Apply filter",
  PLAYLIST_DASH_FILTER: "Admin personalized playlist dashboard - apply filter",
  DOWNLOAD_PLAYLIST_DASH:
    "Admin personalized playlist dashboard - Download database",
  OPEN_PLAYLIST_DETAILS:
    "Admin playlist dashboard - Open individual playlist details",
  EDIT_PERMISSIONS: "Admin - Clicked to edit permissions",
  TEAM_CHOSEN: "Admin - Team chosen",
  EDIT_PERMISSIONS_CONFIMATION: "Admin - permission edit confirmation",
  CHANGED_USER_VIEW: "Changed user view",
  CLICKED_TO_EDIT_TEAM: "Admin - Clicked to edit team",
  TEAM_EDIT_TYPE_SELECTION: "Admin - Team Edit type selection",
  TEAM_EDIT_CONFIRMATION: "Admin - Team Edit confirmation",
  EDIT_TEAM: "Admin - Edit team Add/Remove user",
  REMOVE_USERS_BUTTON: "Admin - Click to Remove users from EJ",
  REMOVE_USERS_CONFIRMATION: "Admin - Remove users from EJ Confirmation",
};

interface EventsImplementationType {
  COURSE_DASH_FILTER: (tab: string, filter: string) => MixpanelEvent;
  PLAYLIST_DASH_FILTER: (tab: string, filter: string) => MixpanelEvent;
  DOWNLOAD_PLAYLIST_DASH: (dataType: string) => MixpanelEvent;
  OPEN_PLAYLIST_DETAILS: (
    isFilterApplied: boolean,
    filters: string,
    progress: number,
    amountOfSkills: number
  ) => MixpanelEvent;
  EDIT_PERMISSIONS: (userPermissions: string) => MixpanelEvent;
  TEAM_CHOSEN: (isNewTeam: boolean) => MixpanelEvent;
  EDIT_PERMISSIONS_CONFIMATION: (permissionType: string) => MixpanelEvent;
  CHANGED_USER_VIEW: (from: string, to: string) => MixpanelEvent;
  CLICKED_TO_EDIT_TEAM: (role: string) => MixpanelEvent;
  TEAM_EDIT_TYPE_SELECTION: (types: string) => MixpanelEvent;
  TEAM_EDIT_CONFIRMATION: () => MixpanelEvent;
  EDIT_TEAM: (typeOfEdition: string, numberOfUsers: number) => MixpanelEvent;
  REMOVE_USERS_BUTTON: () => MixpanelEvent;
  REMOVE_USERS_CONFIRMATION: (removedUsers: number) => MixpanelEvent;
}

export const EventsImplementation: EventsImplementationType = {
  COURSE_DASH_FILTER: (tab, filter): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.COURSE_DASH_FILTER,
      properties: {
        "Tab name": tab,
        "Filter name": filter,
      },
    };
  },
  PLAYLIST_DASH_FILTER: (tab, filter): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PLAYLIST_DASH_FILTER,
      properties: {
        "Tab name": tab,
        "Filter name": filter,
      },
    };
  },
  DOWNLOAD_PLAYLIST_DASH: (dataType): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.DOWNLOAD_PLAYLIST_DASH,
      properties: {
        "Database type": dataType,
      },
    };
  },
  OPEN_PLAYLIST_DETAILS: (
    isFilterApplied,
    filters,
    progress,
    amountOfSkills
  ): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.OPEN_PLAYLIST_DETAILS,
      properties: {
        "Filter is applied": isFilterApplied,
        "Applied filter name": filters,
        "Curent Progress": progress,
        "Curent Number of skills": amountOfSkills,
      },
    };
  },
  EDIT_PERMISSIONS: (userPermissions): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.EDIT_PERMISSIONS,
      properties: {
        "Curent user permission": userPermissions,
      },
    };
  },
  TEAM_CHOSEN: (isNewTeam): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.TEAM_CHOSEN,
      properties: {
        "Team creatation": isNewTeam,
      },
    };
  },
  EDIT_PERMISSIONS_CONFIMATION: (permissionType): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.EDIT_PERMISSIONS_CONFIMATION,
      properties: {
        "Type of edition": permissionType,
      },
    };
  },
  CHANGED_USER_VIEW: (from: string, to: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.CHANGED_USER_VIEW,
      properties: {
        From: from,
        To: to,
      },
    };
  },
  CLICKED_TO_EDIT_TEAM: (role: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.CLICKED_TO_EDIT_TEAM,
      properties: {
        "Curent user permission": role,
      },
    };
  },
  TEAM_EDIT_TYPE_SELECTION: (types: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.TEAM_EDIT_TYPE_SELECTION,
      properties: {
        "Team edit type": types,
      },
    };
  },
  TEAM_EDIT_CONFIRMATION: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.TEAM_EDIT_CONFIRMATION,
      properties: {},
    };
  },

  EDIT_TEAM: (typeOfEdition: string, numberOfUsers: number): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.EDIT_TEAM,
      properties: {
        "Type of edition": typeOfEdition,
        "Number of users changed": numberOfUsers,
      },
    };
  },
  REMOVE_USERS_BUTTON: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.REMOVE_USERS_BUTTON,
      properties: {}
    }
  },
  REMOVE_USERS_CONFIRMATION: (removedUsers: number): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.REMOVE_USERS_CONFIRMATION,
      properties: {
        "Number of removed users": removedUsers
      }
    }
  }
};
