import { Flex, Heading, Image } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { useUser } from 'src/context/userContext'

type Props = {
  close: () => void
}

const SuccessfulMessage: React.FC<Props> = ({ close }) => {
  const { user, getUser } = useUser()
  const { fetchCustomPlaylist } = useCustomPlaylist()

  useEffect(() => {
    const timeout = setTimeout(() => updatePlaylistData(), 500)

    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updatePlaylistData = async () => {
    await getUser()
    await fetchCustomPlaylist(user!)
    close()
  }

  const [t] = useTranslation()

  return (
    <Flex
      h='100%'
      align='center'
      justify='center'
      direction='column'
      gap='48px'
      data-testid='edit-skills-successful-message'
    >
      <Image src='/assets/images/floating-balloon.svg' w={357} h={280} />

      <Heading size='xs' fontWeight='600' marginBottom='8px'>
        {t('home.Custom Playlist.Your skills have been successfully edited')}
      </Heading>
    </Flex>
  )
}

export default SuccessfulMessage
