import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import ButtonsFooter from './ButtonsFooter'

interface Props {
  onContinueButtonClick: () => void
  onSkipButtonClick: () => void
  isOnboarding?: boolean
}

const StepZero: React.FC<Props> = ({ isOnboarding, onContinueButtonClick, onSkipButtonClick }: Props) => {
  const [t] = useTranslation()

  return (
    <>
      <Box flex='1' paddingTop='45px' zIndex={1}>
        <Flex width='100%' alignItems='start' flexDirection='column' justifyContent='space-between'>
          <Flex flexDirection='column'>
            {isOnboarding && (
              <Text
                textAlign='start'
                color='gray.300'
                fontSize='20px'
                fontFamily='Poppins'
                fontWeight='600'
                marginBottom='8px'
              >
                {t('quiz.cover.registerOk')}
              </Text>
            )}
            <Text
              fontWeight='700'
              fontFamily='Poppins'
              fontSize='38px'
              lineHeight='56px'
              color='primaryColors.400'
              maxWidth='482px'
              textAlign='start'
            >
              {isOnboarding
                ? t('quiz.cover.onboardingTitle')
                : t('quiz.cover.title')}
            </Text>
            <Text
              marginTop='24px'
              fontWeight='600'
              fontSize='20px'
              fontFamily='Mulish'
              color='gray.800'
              maxWidth='336px'
              textAlign='start'
            >
              {t('quiz.cover.subtitle')}
            </Text>
            <Button
              marginTop='24px'
              bgColor='gray.800'
              width='184px'
              height='60px'
              borderRadius='15px'
              fontSize='24px'
              onClick={onContinueButtonClick}
            >
              {t('quiz.cover.start')}
            </Button>
          </Flex>
        </Flex>
      </Box>
      <Image
        position='absolute'
        src={isOnboarding ? '/assets/images/bg-quiz-onboarding.svg' : '/assets/images/bg-quiz.svg'}
        right='55px'
        bottom='100px'
      />
      <ButtonsFooter step={0} onSkipButtonClick={onSkipButtonClick} />
    </>
  )
}

export default StepZero
