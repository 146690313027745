import { Box, Flex } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'src/hooks/useWindowSize'
import StepHeader from '../StepHeader'
import { SelectSkills } from './SelectSkills'

const StepOne = () => {
  const [t] = useTranslation()

  const { height } = useWindowSize()
  const showBlankSpaceBeforeFooter = useMemo(() => !!height && height < 770, [height])

  return (
    <Flex h='auto' direction='column'>
      <Box>
        <StepHeader
          step={1}
          title={t('customPlaylistQuiz.stepOne.title')}
          description={t('customPlaylistQuiz.stepOne.description')}
        />

        <Box mt='24px'>
          <SelectSkills />
        </Box>
      </Box>
      {showBlankSpaceBeforeFooter && <Box w='100%' h='120px' display={{ base: 'block', md: 'none' }} />}
    </Flex>
  )
}

export default StepOne
