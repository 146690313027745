import { Box, Flex, Text } from '@chakra-ui/react'

export type Tab = {
  name: string
  value: string
}

type Props = {
  tabs: Tab[]
  tabIndex: number
  onTabClick?: (value: string, index: number) => void
}

const Tabs: React.FC<Props> = ({ tabs, onTabClick, tabIndex }) => {
  return (
    <Flex borderBottom='2px solid #EFEFEF' position='relative'>
      {tabs.map((tab, index) => (
        <Box
          key={index}
          onClick={() => (onTabClick ? onTabClick(tab.value, index) : () => {})}
          cursor='pointer'
          borderBottom={index === tabIndex ? '2px solid #009639' : ''}
          bottom='-2px'
          width='fit-content'
        >
          <Text
            color={index === tabIndex ? 'black' : 'gray.260'}
            fontWeight={index === tabIndex ? '700' : ''}
            padding='8px'
          >
            {tab.name}
          </Text>
        </Box>
      ))}
    </Flex>
  )
}

export default Tabs
