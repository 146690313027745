import { gql } from '@apollo/client'

const GET_USER_QUIZ = gql`
  query GetUserQuiz {
    GetUserQuiz {
      careerStage
      developmentPlan
      motivation
      competences
      themes
      skillsStartingPoint
      skillsEndingPoint
      dedicationTime
      deadline
      languages
      updatedAt
    }
  }
`

export default GET_USER_QUIZ
