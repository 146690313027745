import { useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { makeAllocateCollabSkills } from 'src/modules/cms/factories/makeAllocateCollabSkills'
import { useAllocateSkillStore } from '../../hooks/useAllocateSkillStore'

export function useSelectSkillsDrawer() {
  const toast = useToast()
  const { setSelectedCollabs, selectedCollabs } = useAllocateSkillStore()
  const { allSkills, getAllCmsSkills } = useCustomPlaylistQuiz()
  const [selectedSkills, setSelectedSkills] = useState<string[]>([])
  const [numOfFields, setNumOfFields] = useState(5)
  const [isLoading, setIsLoading] = useState(false)

  function getAvailableSkills(index: number) {
    return allSkills
      ?.filter(
        (skill) => !selectedSkills.includes(skill?.id || '') || selectedSkills.indexOf(skill?.id || '') === index,
      )
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
  }

  function handleSkillChange(index: number, skillId: string) {
    const newSkills = [...selectedSkills]
    newSkills[index] = skillId
    setSelectedSkills(newSkills)
  }

  function handleAddField() {
    setNumOfFields((prevState) => prevState + 1)
  }

  function isValid() {
    return selectedSkills.every((skill) => skill === '') || selectedSkills.length === 0 || isLoading
  }

  async function handleSubmit(onClose: () => void) {
    const allocateSkills = makeAllocateCollabSkills()
    const skills = selectedSkills.filter((item) => item)

    try {
      setIsLoading(true)
      const response = await allocateSkills.execute({
        collabIds: selectedCollabs.map((item) => item.id),
        skillIds: skills,
      })
      setIsLoading(false)

      setSelectedCollabs([])

      const hasError = response.errors.length > 0

      toast({
        title: hasError
          ? 'Ocorreu um erro ao alocar as habilidades, tente novamente.'
          : 'As habilidades foram alocadas com sucesso! 🎉',
        status: hasError ? 'error' : 'success',
        duration: 5000,
        isClosable: true,
      })
    } catch (error) {
      toast({
        title: 'Ocorreu um erro ao alocar as habilidades, tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }

    onClose()
  }

  useEffect(() => {
    getAllCmsSkills({ variables: { limit: 500, skip: 0 } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    selectedSkills,
    numOfFields,
    getAvailableSkills,
    handleSkillChange,
    handleAddField,
    isValid,
    handleSubmit,
    isLoading,
  }
}
