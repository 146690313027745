import { Box, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { ChangeOfArea } from 'src/modules/backoffice/components/ChangeOfArea'
import { Company } from 'src/modules/backoffice/components/Company'
import { CompanyDeactivation } from 'src/modules/backoffice/components/CompanyDeactivation'
import { useChangeCompanyArea } from 'src/modules/backoffice/contexts/ChangeCompanyAreaContext'

const tabs = [
  {
    id: 'company',
    name: 'Empresa',
  },
  {
    id: 'change-of-area',
    name: 'Mudança de Área',
  },
  {
    id: 'company-deactivation',
    name: 'Desativação de Empresa',
  },
]

export const BackofficePage = () => {
  const { handleSelectCompany, handleUpdateUsers } = useChangeCompanyArea()
  const [currentTab, setCurrentTab] = useState('company')

  const renderTabContent = (value: string) => {
    const component: { [x: string]: JSX.Element } = {
      company: <Company />,
      'change-of-area': <ChangeOfArea />,
      'company-deactivation': <CompanyDeactivation />,
    }

    return component[value]
  }

  useEffect(() => {
    return () => {
      handleSelectCompany({})
      handleUpdateUsers([])
    }
  }, [])

  return (
    <Flex p='0px 56px' flexDir='column'>
      <Flex gridGap='32px' mb='24px' borderBottom='2px solid #EFEFEF' position='relative' data-testid='cms-home-tabs'>
        {tabs.map((item, index) => (
          <Box
            key={item.id}
            onClick={() => setCurrentTab(item.id)}
            cursor='pointer'
            borderBottom={currentTab === item.id ? '2px solid #009639' : ''}
            bottom='-1px'
            width='fit-content'
            data-testid={`cms-home-tab-${index}`}
          >
            <Text color={currentTab === item.id ? 'black' : '#aaaaaa'} fontWeight={'700'} padding='8px'>
              {item.name}
            </Text>
          </Box>
        ))}
      </Flex>

      <Flex flex={1}>{renderTabContent(currentTab)}</Flex>
    </Flex>
  )
}
