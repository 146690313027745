import { Flex, Image, Text } from '@chakra-ui/react'
import { useCallback, useEffect } from 'react'
import { usePermissions } from '../../context-admin/usePermissions'
import { useTeamManagement } from '../../context-admin/useTeamManagement'
import { useTeams } from '../../context-admin/useTeams'
import { returnPermissions } from './utils'
import { useTranslation } from 'react-i18next'
import { useHomeAccess } from 'src/context-admin/Home/AdminHomeAccessContext'

type Props = {
  handleStepChange: (value: number) => void
}

const ConfirmationStep: React.FC<Props> = ({ handleStepChange }) => {
  const { fetchUsers, first } = useHomeAccess()
  const { selectedPermissions, handleTogglePermissionsDrawer, selectedCollab } = usePermissions()
  const { teamHasBeenCreated, teamName, managerHasChanged, selectTeam } = useTeamManagement()
  const { refetchTeams, setTeams } = useTeams()
  const status = returnPermissions(selectedPermissions)!
  const [t] = useTranslation()

  const redirect = useCallback(() => {
    if (teamHasBeenCreated || managerHasChanged) {
      fetchUsers({ limit: first })
      refetchTeams().then((response) => setTeams(response.data.listTeams))
      handleTogglePermissionsDrawer()

      return
    }

    const handleNextStep = {
      onlyAdmin: () => {
        fetchUsers({ limit: first })
        handleTogglePermissionsDrawer()
      },
      onlyManager: () => handleStepChange(2),
      adminAndManager: () => handleStepChange(2),
      removingAdmin: () => {
        fetchUsers({ limit: first })
        handleTogglePermissionsDrawer()
      },
      removingAdminAndAddManager: () => handleStepChange(2),
    }

    handleNextStep[status]()
  }, [
    handleStepChange,
    handleTogglePermissionsDrawer,
    status,
    teamHasBeenCreated,
    managerHasChanged,
    fetchUsers,
    refetchTeams,
    setTeams,
  ])

  useEffect(() => {
    const timeout = setTimeout(() => redirect(), 1500)

    return () => {
      clearTimeout(timeout)
    }
  }, [redirect])

  const returnMessage = () => {
    if (managerHasChanged) {
      return `O ${t('admin.common.manager')} de “${selectTeam.label}” foi alterado com sucesso!`
    }

    if (teamHasBeenCreated) {
      return `“${teamName}” foi criada com sucesso!`
    }

    if (['onlyAdmin', 'adminAndManager'].includes(status)) {
      return `${selectedCollab.name} é um administrador!`
    }

    if (['removingAdmin', 'removingAdminAndAddManager'].includes(status)) {
      return `As permissões de ${selectedCollab.name} foram removidas`
    }
  }

  return (
    <Flex height='100%' alignItems='center' justifyContent='center' marginBottom='38px'>
      <Flex flexDirection='column' alignItems='center' justifyContent='center' maxW='571px' gridGap='48px'>
        <Image src='/assets/images/floating-balloon.svg' w='357px' h='280px' />
        <Flex flexDir='column' alignItems='center' w='100%'>
          <Text
            marginBottom='20px'
            fontWeight='600'
            fontSize='20px'
            fontFamily='Poppins'
            color='gray.800'
            textAlign='center'
            maxW='460px'
          >
            {returnMessage()}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ConfirmationStep
