import { UseCase } from 'src/types/UseCase'
import { ReportRepository } from '../repository/ReportRepository'

export type GetSubsidiariesUseCaseOutput = {
  id: number
  name: string
}[]

export class GetSubsidiariesUseCase implements UseCase<GetSubsidiariesUseCaseOutput, GetSubsidiariesUseCaseOutput> {
  constructor(private repository: ReportRepository) {}

  async execute(): Promise<GetSubsidiariesUseCaseOutput> {
    const report = await this.repository.getSubsidiaries()
    return report
  }
}
