import { Flex, Select } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { availableLanguages } from 'src/pages-admin/Cms/components/ContentForm/availableLanguages'
import { useImportedContents } from 'src/pages-admin/Cms/hooks/useImportedContents'

export function LanguageFilter() {
  const [t] = useTranslation()
  const { filters, applyFilters, listImportedContents } = useImportedContents()

  useEffect(() => {
    listImportedContents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.languages])

  useEffect(() => {
    return () => {
      applyFilters('languages', [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex data-testid='cms-skill-filter'>
      <Select
        value={filters.languages}
        bgColor='#F7F9FA'
        border='none'
        _hover={{ bgColor: '#F7F9FA' }}
        _focus={{ bgColor: '#F7F9FA' }}
        fontWeight={600}
        fontSize='.875rem'
        minW='127px'
        height='22px'
        onChange={(e) => applyFilters('languages', e.target.value === '' ? undefined : Array(e.target.value))}
        className={`select-default`}
      >
        <option value=''>{t('language')}</option>
        {availableLanguages.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </Select>
    </Flex>
  )
}
