import httpClient from 'src/services/http/http-client'
import { getAccessToken } from '../apis/store'
import { GetUserToEditResponse, UserRepository } from '../repository/UserRepository'

interface Input {
  id: number
}

type Output = GetUserToEditResponse

export class GetUserToEditUseCase {
  constructor(private userRepository: UserRepository) {}

  async execute(params: Input): Promise<Output> {
    const userData = await this.userRepository.getUserToEdit(params.id)
    return userData
  }
}

export function makeGetUserToEdit() {
  const token = getAccessToken()
  const repo = new UserRepository(token, httpClient)
  return new GetUserToEditUseCase(repo)
}
