import { Box, Flex, Icon, Image, Skeleton, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { IoCloseSharp } from 'react-icons/io5'
import { getContentData } from 'src/helpers/contentInfo'
import Content from 'src/modules/cms/domain/Content'
import { renderCorrectContentInfoByLanguage } from 'src/modules/internationalization/helpers/renderCorrectContentInfoByLanguage'
import { getFlagIcon } from 'src/pages/LearningItem/utils'

type Props = {
  onModalClose: () => void
  content: Content | undefined
}

const LearningItemHeader: React.FC<Props> = ({ onModalClose, content }) => {
  const [t] = useTranslation()
  const contentData = getContentData(content?.type ?? 'Course', t)

  if (!content) {
    return (
      <Box>
        <Flex justifyContent='space-between' mb='16px'>
          <Skeleton w='70px' h='30px' borderRadius='15px' />
          <Skeleton w='70px' h='30px' borderRadius='15px' />
        </Flex>

        <Skeleton w='80px' h='48px' mb='8px' borderRadius='15px' />
        <Skeleton w={{ base: '100%', sm: '400px' }} h='60px' mb='16px' borderRadius='15px' />
      </Box>
    )
  }

  return (
    <Box mb='16px'>
      <Icon
        as={IoCloseSharp}
        color='#A6A6A6'
        w='18px'
        h='18px'
        position='absolute'
        top='18px'
        right='18px'
        cursor='pointer'
        onClick={onModalClose}
      />

      <Flex justifyContent='space-between' mb='16px'>
        <Text
          data-cy='learningItemTypeModal'
          textTransform='capitalize'
          color='#767F89'
          fontFamily='Poppins'
          fontSize='20px'
          fontWeight={600}
        >
          {contentData?.type}
        </Text>
        <Flex alignItems='center' gap='8px'>
          {content.languages?.map((language) => (
            <>
              <Flex gap='8px' align='center'>
                <Image key={content.id} src={getFlagIcon(language)} w='16px' h='16px' />
              </Flex>
              <Flex gap='8px' align='center'>
                <Text textTransform='uppercase'>{language}</Text>
              </Flex>
            </>
          ))}
        </Flex>
      </Flex>
      <Text data-cy='learningItemNameModal' fontFamily='Poppins' fontSize='32px' fontWeight={700} color='#313E4C'>
        {renderCorrectContentInfoByLanguage(content, 'title')}
      </Text>
      <Text fontSize='16px' fontWeight={600} color='#313E4C' mt='8px'>
        {renderCorrectContentInfoByLanguage(content, 'description')}
      </Text>
    </Box>
  )
}

export default LearningItemHeader
