import { gql } from '@apollo/client'

const GET_CATEGORIES = gql`
  query categories {
    categories {
      entryId
      name
      slug
      description
      icon {
        url
      }
      subcategories {
        entryId
        name
        slug
      }
    }
  }
`

export { GET_CATEGORIES }
