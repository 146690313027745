import { Box, Flex } from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { reorderItems } from 'src/components/CustomPlaylistQuiz/utils'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { useWindowSize } from 'src/hooks/useWindowSize'
import ButtonsFooter from '../ButtonsFooter'
import DraggableItems from '../DraggableItems'
import StepHeader from '../StepHeader'

const StepThree = () => {
  const [t] = useTranslation()
  const { quizValues, updateQuiz } = useCustomPlaylistQuiz()
  const { skills } = quizValues
  const [orderHasChanged, setOrderHasChanged] = useState(false)

  const { width } = useWindowSize()
  const isMobile = useMemo(() => !!width && width < 992, [width])

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return

    const reorderedItems = reorderItems(skills, result.source.index, result.destination.index)

    updateQuiz('skills', reorderedItems)
    setOrderHasChanged(true)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Flex h='100%' direction='column'>
        <Box>
          <StepHeader
            step={3}
            title={t('customPlaylistQuiz.stepThree.title')}
            description={
              isMobile
                ? t('customPlaylistQuiz.stepThree.mobileDescription')
                : t('customPlaylistQuiz.stepThree.description')
            }
          />

          <DraggableItems items={skills.map((skill) => ({ id: skill.id, title: skill.name })) ?? []} />
        </Box>

        <ButtonsFooter showTooltip={!orderHasChanged} />
      </Flex>
    </DragDropContext>
  )
}

export default StepThree
