import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Select,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { useSelectSkillsDrawer } from './useSelectSkillsDrawer'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const SelectSkillsDrawer: React.FC<Props> = ({ isOpen, onClose }) => {
  const [t] = useTranslation()
  const { isLoadingSkills } = useCustomPlaylistQuiz()
  const {
    getAvailableSkills,
    handleAddField,
    handleSkillChange,
    numOfFields,
    selectedSkills,
    isValid,
    handleSubmit,
    isLoading,
  } = useSelectSkillsDrawer()

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement='right' closeOnOverlayClick={false} size='xl'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <Flex flexDir='column' padding='88px 80px 0px' overflowY='auto' flexGrow='1'>
          <Text color='#313E4C' fontSize='1.375rem' fontWeight={700} fontFamily='Poppins'>
            {t('whatSkillsToAllocate')}
          </Text>
          <Text color='#767F89' fontSize='1rem' mt='4px'>
            {t('selectWhoTheSkillsWillBeAllocated')}
          </Text>

          <Flex flexDirection='column' gap='24px' m='24px 0'>
            {Array.from({ length: numOfFields }, (_, index) => (
              <Flex flexDirection='column' gap='4px'>
                <Flex>
                  <Text fontWeight={700}>{`${t('skill')} ${index + 1}`}</Text>
                  {index === 0 && <Text color='red.300'>*</Text>}
                </Flex>

                <Select
                  key={index}
                  value={selectedSkills[index] || ''}
                  onChange={(e) => handleSkillChange(index, e.target.value)}
                >
                  <option value=''>{t('skills')}</option>
                  {isLoadingSkills && (
                    <option value='loading' disabled>
                      {t('loading')}
                    </option>
                  )}
                  {getAvailableSkills(index)?.map((skill) => (
                    <option key={skill?.id} value={skill?.id}>
                      {skill?.name}
                    </option>
                  ))}
                </Select>
              </Flex>
            ))}
          </Flex>

          <Flex w='276px' h='31px' align='center' justify='center' bgColor='#F7F9FA' gap='16px' borderRadius='8px'>
            <Text fontWeight={700} fontSize='12px' color='primaryColors.650'>
              {t('wantToAllocateMoreSkills')}
            </Text>
            <Text
              as='span'
              textDecor='underline'
              cursor='pointer'
              fontSize='12px'
              color='primaryColors.650'
              onClick={handleAddField}
            >
              {t('clickHere')}
            </Text>
          </Flex>

          <Flex align='center' justify='space-between' pb='91px' mt='28px'>
            <Text
              color='#767F89'
              fontSize='1rem'
              textDecoration='underline'
              fontFamily='Poppins'
              fontWeight='600'
              cursor='pointer'
              onClick={onClose}
            >
              {t('back')}
            </Text>
            <Tooltip
              hasArrow
              placement='top'
              fontSize='12px'
              label='Selecione pelo menos uma habilidade'
              isDisabled={selectedSkills.length !== 0}
              shouldWrapChildren
            >
              <Button
                type='submit'
                variant='unstyled'
                w='176px'
                h='44px'
                bg='gray.800'
                color='white'
                display='flex'
                alignItems='center'
                fontSize='1rem'
                padding='8px 16px'
                isDisabled={isValid()}
                isLoading={isLoading}
                onClick={() => handleSubmit(onClose)}
                _hover={{ _disabled: { bg: '#3F3D561F' } }}
              >
                {t('save')}
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
      </DrawerContent>
    </Drawer>
  )
}

export default SelectSkillsDrawer
