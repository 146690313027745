import { Box, Button, Flex, Image, Input, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { useUser } from 'src/context/userContext'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import Content from 'src/modules/cms/domain/Content'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'

type Props = {
  content: Content
  isLastModuleLearningItem: boolean
  moduleId: string
}

const LastPlaylistLearningItem: React.FC<Props> = ({ content, isLastModuleLearningItem, moduleId }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { updateUserQuizData, getUser } = useUser()
  const { EventsImplementation, track } = useMixpanel()
  const { playlist, setIsPlaylistRemoved, setShowSuccessfulMessage, showSuccessfulMessage,  } = useCustomPlaylist()
  const { removeContentFromPlaylist } = useCustomPlaylistQuiz()
  const [active, setActive] = useState(false)
  const [error, setError] = useState(false)

  const verifyContent = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const content = e.target.value
    setError(
      t('home.Custom Playlist.I want to delete my custom playlist').toLowerCase().indexOf(content.toLowerCase()) === -1,
    )
    setActive(content.toLowerCase() === t('home.Custom Playlist.I want to delete my custom playlist').toLowerCase())
  }

  const handleRemove = async () => {
    track(EventsImplementation.REMOVE_LEARNING_ITEM_CONFIRMATION(content.title, isLastModuleLearningItem))
    setIsPlaylistRemoved(true)
    setShowSuccessfulMessage(true)

    await removeContentFromPlaylist(playlist?.id!, moduleId!, content.id)
    await getUser()
  }

  const handleRedirect = () => {
    updateUserQuizData(false)
    history.push('/home')
  }

  return (
    <Box>
      {showSuccessfulMessage ? (
        <Flex flexDir='column' alignItems='center'>
          <Image src='/assets/images/floating-balloon.svg' w={243} h={191} />
          <Text fontFamily='Poppins' fontWeight='semibold' fontSize={20} m='12px 0' px='70px'>
            {t('home.Custom Playlist.Your playlist has been successfully deleted')}
          </Text>
          <Button variant='startCourseDark' fontSize='14px' py='7.5px' onClick={handleRedirect}>
            <Text fontFamily='Poppins' fontWeight='600'>
              {t('home.Custom Playlist.Go to homepage')}
            </Text>
          </Button>
        </Flex>
      ) : (
        <>
          <Text fontFamily='Poppins' fontWeight={600} fontSize='20px' textAlign='initial'>
            {t('home.Custom Playlist.This is the last module of your custom playlist')}
          </Text>

          <Flex direction='column' gap='16px'>
            <Text fontWeight={600} fontSize='18px' textAlign='initial' mt='16px' color='bluishGray.500'>
              {t('home.Custom Playlist.Removing the content will delete your playlist', {
                contentName: content.title,
              })}
            </Text>
            <Text fontWeight={600} fontSize='18px' textAlign='initial' mt='16px' color='bluishGray.500'>
              {t('home.Custom Playlist.To confirm this action, enter the sentence below in the text field')}
            </Text>
            <Text fontWeight={600} fontSize='18px' textAlign='initial' mt='16px'>
              {t('home.Custom Playlist.I want to delete my custom playlist')}
            </Text>
          </Flex>

          <Flex gap='8px' mt='24px' align='center'>
            <Input
              variant='unstyled'
              border='2px solid'
              borderColor='gray.70'
              borderRadius='16px'
              fontFamily='Poppins'
              fontWeight='600'
              color={error ? 'red.200' : 'gray.800'}
              height='36px'
              onChange={verifyContent}
            />

            <Button
              variant='startCourseDark'
              fontSize='14px'
              py='7.5px'
              disabled={!active || error}
              onClick={handleRemove}
            >
              <Text fontFamily='Poppins' fontWeight='600'>
                {t('home.Custom Playlist.send')}
              </Text>
            </Button>
          </Flex>
        </>
      )}
    </Box>
  )
}

export default LastPlaylistLearningItem
