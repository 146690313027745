import { getAccessToken } from "src/modules/iam/apis/store";
import httpClient from "src/services/http/http-client";
import { AreaRepository } from "../repository/AreaRepository";
import { RenameExistingArea } from "../use-case/RenameExistingAreaUseCase";

export function makeRenameExistingArea() {
  const token = getAccessToken()

  const areaRepository = new AreaRepository(token, httpClient)
  return new RenameExistingArea(areaRepository)
}
