import { Button, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { hideEmail } from 'src/shared/Email'
import useConfirmEmailVerification from '../../hooks/useConfirmEmailVerification'

export const LOGIN_CONFIRM_EMAIL_VERIFICATION_TEST_ID = Object.freeze({
  button: 'confirm-email-verification-testid',
  resend: 'resend-email-verification-testid',
})

const ConfirmEmailVerification: React.FC = () => {
  const { email, timer, isCounting, resendEmail, confirmVerification, isLoading } = useConfirmEmailVerification()
  const [t] = useTranslation()
  return (
    <>
      <Text
        alignSelf='left'
        fontWeight='regular'
        fontFamily='Poppins'
        fontSize={['24px', '24px', '24px', '28px', '36px']}
        color='gray.800'
      >
        {t('onboard.confirmEmail.title')}
      </Text>
      <Text fontFamily='Mulish'>{t('onboard.confirmEmail.body')}</Text>
      <Text fontFamily='Mulish' fontWeight={'medium'}>
        {hideEmail(email)}
      </Text>
      <Button
        w='100%'
        data-testid={LOGIN_CONFIRM_EMAIL_VERIFICATION_TEST_ID.button}
        isLoading={isLoading}
        onClick={() => confirmVerification()}
        marginTop={6}
      >
        {t('onboard.confirmEmail.proceed')}
      </Button>
      <Button
        w='100%'
        disabled={isCounting}
        isLoading={isLoading}
        onClick={resendEmail}
        variant={'outline'}
        data-testid={LOGIN_CONFIRM_EMAIL_VERIFICATION_TEST_ID.resend}
        marginTop={6}
        fontSize={isCounting ? '0.875rem' : '1rem'}
      >
        {isCounting ? `${t('onboard.confirmEmail.resend')} (${String(timer)})` : t('onboard.confirmEmail.resend')}
      </Button>
    </>
  )
}

export default ConfirmEmailVerification
