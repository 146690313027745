import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { useUser } from 'src/context/userContext'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { getLearningItemsAnalytics, getPlaylistTopics } from './header.service'

interface Props {
  playlist: NewPlaylist
}

const HeaderTitle: React.FC<Props> = ({ playlist }: Props) => {
  const { user } = useUser()
  const { quizValues } = useCustomPlaylistQuiz()
  const [t] = useTranslation()

  const { totalLearningItems } = getLearningItemsAnalytics(playlist)
  const topics = getPlaylistTopics(playlist)

  return (
    <Flex flexDirection='column' maxW='677px'>
      <Heading size='xs' color='bluishGray.500'>
        {t('home.Custom Playlist.Study plan of')} {user?.name.split(' ')[0]}
      </Heading>
      <Heading marginTop='16px' size='md' fontWeight='700'>
        {t('customPlaylist')}
      </Heading>
      <Text marginTop='4px' color='bluishGray.500'>
        {t('home.Custom Playlist.This playlist was made especially for you based on your Individual Development Plan')}
        {t('home.Custom Playlist.We selected')} {totalLearningItems}{' '}
        {t('home.Custom Playlist.content for you to delve into topics such as')} {topics}.{' '}
        {t('home.Custom Playlist.Now its your turn to learn')}
      </Text>
      {quizValues.noContentForSkills.length > 0 && (
        <Box mt='16px'>
          <Text marginTop='4px' color='bluishGray.500'>
            {t('weDoNotFoundEnoughNumberOfContentForSomeSkills')} <br />
            <Flex flexDir='column'>
              {quizValues.noContentForSkills.map((skill) => (
                <Text>- {skill}</Text>
              ))}
            </Flex>
          </Text>
          <Text marginTop='8px' color='bluishGray.500'>
            {t('soOtherContentTypesCouldBeAddedToYourPlaylist')}
          </Text>
        </Box>
      )}
    </Flex>
  )
}

export default HeaderTitle
