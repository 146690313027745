type ErrorOptions = 'NOT_AUTHORIZED' | 'user from another company' | 'TRY_AGAIN'

export const getErrorTitle = (error: ErrorOptions | string, t: any): string => {
  switch (error) {
    case 'NOT_AUTHORIZED':
      return t('NOT_AUTHORIZED')

    case 'user from another company':
      return t('userFromAnotherCompany')

    case 'TRY_AGAIN':
      return t('pleaseTryAgain')

    case 'DEFAULT':
      return t('tryAgain.defaultError')

    default:
      return t('tryAgain.defaultError')
  }
}
