import { HStack, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

const Title = () => {
  const [t] = useTranslation()
  return (
    <HStack w='100%' justifyContent='space-between'>
      <Text fontFamily='Poppins' fontWeight='semibold' fontSize='24px'>
        {t('accessControl')}
      </Text>
    </HStack>
  )
}

export default Title
