import { Company } from "src/modules/cms/domain/Company";
import { UseCase } from "src/types/UseCase";
import { UserRepository } from "../repository/UserRepository";

export class GetUserCompanyUseCase implements UseCase<unknown, Company | null> {
  constructor(private userRepository: UserRepository) {}

  async execute(): Promise<Company | null> {
    const company = await this.userRepository.getUserCompany()
    return company
  }
}