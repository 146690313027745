import { Flex } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { debounce } from 'src/helpers-admin/debounce'
import { useImportedContents } from 'src/pages-admin/Cms/hooks/useImportedContents'
import { SearchContent } from '../../../../components/SearchContent'

interface SearchImportedContentsProps {
  searchSectionTitle: string
  searchPlaceholder: string
}

export function SearchImportedContents({ searchSectionTitle, searchPlaceholder }: SearchImportedContentsProps) {
  const { filters, applyFilters, listImportedContents } = useImportedContents()

  const handleSearch = async (value?: string) => {
    applyFilters('title', value)
  }

  const searchDebounce = debounce(handleSearch, 500)

  useEffect(() => {
    listImportedContents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.title])

  return (
    <Flex w='100%' mt='24px'>
      <SearchContent title={searchSectionTitle} placeholder={searchPlaceholder} searchContent={searchDebounce} />
    </Flex>
  )
}
