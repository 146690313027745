import { Image, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import '../../services/i18n/i18n'

const EmptyState = () => {
  const [t] = useTranslation()

  return (
    <Stack spacing={6} alignItems='center' justify='center' padding='50px' flex={1}>
      <Image src='/assets/images/empty.svg' width='240px' />
      <Stack spacing={2} alignItems='center'>
        <Text color='primaryColor' fontWeight='extrabold' fontSize='xl'>
          {t('home.Custom Playlist.unable to load')}
        </Text>
        <Text textAlign='center' fontWeight='bold'>
          {t('home.Custom Playlist.contact support')}
        </Text>
      </Stack>
    </Stack>
  )
}

export default EmptyState
