import { Box, Flex, Skeleton } from '@chakra-ui/react'

const LearningItemSkeleton: React.FC = () => {
  return (
    <Flex
      padding={{ base: '24px', lg: '40px 0px 48px 0px' }}
      justifyContent='center'
      height={{ base: 'auto', lg: '100%' }}
      direction={{ base: 'column', lg: 'row' }}
      gap={{ base: '24px', lg: '0px' }}
    >
      <Flex
        w='100%'
        justifyContent='center'
        paddingRight={{ base: '0px', lg: '64px' }}
        paddingLeft={{ base: '0px', lg: '30px' }}
        borderRight={{ base: 'none', lg: '2px solid #EFEFEF' }}
      >
        <Box w='100%'>
          <Flex mb='16px' gap='8px' alignItems='center'>
            <Skeleton w='70px' h='30px' borderRadius='16px' />
          </Flex>

          <Box w='100%' h='0' float='none' clear='both' position='relative' pb='56.25%' pt='25px'>
            <Skeleton w='100%' h='100%' position='absolute' top={0} left={0} borderRadius='16px' />
          </Box>
        </Box>
      </Flex>

      <Flex flexDir='column' maxWidth='520px' width='100%' paddingX={{ base: '0px', lg: '60px' }}>
        <Flex justifyContent='space-between' mb='16px'>
          <Skeleton w='70px' h='30px' borderRadius='16px' />

          <Skeleton w='70px' h='30px' borderRadius='16px' />
        </Flex>

        <Skeleton w='80px' h='48px' mb='8px' borderRadius='16px' />
        <Skeleton w={{ base: '100%', lg: '400px' }} h='90px' mb='24px' borderRadius='16px' />
        <Skeleton w={{ base: '100%', lg: '400px' }} h='84px' borderRadius='16px' />

        <Flex gap='8px' mt='24px' justifyContent='flex-end'>
          <Skeleton w='48px' h='44px' borderRadius='16px' />
          <Skeleton w='48px' h='44px' borderRadius='16px' />
          <Skeleton
            w={{ base: 'auto', lg: '285px' }}
            flexGrow={{ base: '1', lg: 'unset' }}
            h='44px'
            borderRadius='16px'
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default LearningItemSkeleton
