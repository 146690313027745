import { ContentRepository } from 'src/modules/cms/repositories/ContentRepository'
import { ContentList } from 'src/types/Cms'

type ListContentsUseCaseInput = any

export type ListContentsUseCaseOutput = ContentList

export class ListContentsUseCase {
  constructor(private contentRepository: ContentRepository) {}

  async execute(input: ListContentsUseCaseInput): Promise<ListContentsUseCaseOutput> {
    const params = { ...input }
    const contentList = await this.contentRepository.list(params)
    return contentList
  }
}
