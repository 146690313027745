import {
  Button,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { saveAs } from 'file-saver'
import { useTranslation } from 'react-i18next'
import { IoDownloadOutline } from 'react-icons/io5'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { EventsImplementation } from 'src/hooks/Mixpanel/types'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'

export function DownloadButton() {
  const { certificate, playlist } = useCustomPlaylist()
  const { t } = useTranslation()
  const { track } = useMixpanel()

  return (
    <Popover trigger='hover' placement='top'>
      {!certificate ? (
        <PopoverContent
          backgroundColor='gray.800'
          borderRadius='20px'
          width={'fit-content'}
          border='none'
          zIndex={'popover'}
        >
          <PopoverArrow backgroundColor='gray.800' />
          <PopoverBody textAlign={'center'}>
            <Text fontSize='14px' color='white' fontWeight={700}>
              {t('playlist.You need to complete the playlist to download your certificate')}
            </Text>
          </PopoverBody>
        </PopoverContent>
      ) : null}

      <PopoverTrigger>
        <Button
          variant='startCourseDark'
          alignItems='center'
          disabled={certificate ? false : true}
          w={{ base: '100%', lg: 'auto' }}
          onClick={() => {
            if (certificate) {
              saveAs(certificate.url, `Certificado ${playlist?.title}.jpg`)
              track(EventsImplementation.DOWNLOAD_CERTIFICATE(playlist?.title ?? 'Trilha Personalizada'))
            }
          }}
        >
          <Icon as={IoDownloadOutline} w='24px' h='24px' />
          <Text fontFamily='Poppins' fontWeight='600' marginLeft='8px'>
            {t('playlist.Download playlist certificate')}
          </Text>
        </Button>
      </PopoverTrigger>
    </Popover>
  )
}
