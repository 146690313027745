import { getAccessToken } from "src/modules/iam/apis/store"
import { cmsClient } from "src/services/http/cms-client"
import { PlaylistRepository } from "../repositories/PlaylistRepository"
import { UpdatePlaylistTranslationsUseCase } from "../use_cases/UpdatePlaylistTranslationsUseCase"

export function makeUpdatePlaylistTranslations() {
  const token = getAccessToken()
  const playlistRepository = new PlaylistRepository(cmsClient, token)
  return new UpdatePlaylistTranslationsUseCase(playlistRepository)
}
