import { User } from "src/types/User";
import { UserRepository } from "../repository/UserRepository";

interface GetUserByIdInput {
  id: number
}

type GetUserByIdOutput = User

export class GetUserByIdUseCase {
  constructor(private userRepository: UserRepository) {}
  
  async execute(args: GetUserByIdInput): Promise<GetUserByIdOutput> {
    const userInfo = await this.userRepository.getUserById(args.id)
    return userInfo
  }
}