import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAllocateSkillStore } from '../../hooks/useAllocateSkillStore'
import { useAllocateSkill } from './useAllocateSkill'

export function SelectedCollabsPopover() {
  const [t] = useTranslation()
  const { onOpen, onClose, isOpen } = useDisclosure()
  const { selectedCollabs } = useAllocateSkillStore()
  const { handleSelectCollabs } = useAllocateSkill()

  useEffect(() => {
    if (selectedCollabs.length === 0) onClose()
  }, [selectedCollabs])

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Text
          as='span'
          color='#767F89'
          fontSize='1rem'
          mt='4px'
          cursor='pointer'
          _hover={{ textDecoration: 'underline' }}
        >
          {t('selectedUsers')} ({selectedCollabs.length})
        </Text>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader pt={4} border={0}>
          {t('selectedUsers')} ({selectedCollabs.length})
        </PopoverHeader>
        <PopoverBody maxH='250px' overflowY='auto'>
          <Flex flexDirection='column'>
            {selectedCollabs.map((item) => (
              <Flex
                key={Math.random()}
                h='43px'
                align='center'
                bgColor='#F7F9FA'
                justify='space-between'
                borderRadius='8px'
                p='0 16px'
                mb='16px'
                boxShadow='0px 1px 6px rgba(0, 0, 0, 0.1)'
              >
                <Text ml='8px' fontWeight='700' fontSize='12px' color='#009639'>
                  {item.name}
                </Text>
                <Button
                  variant='unstyled'
                  fontWeight='600'
                  fontSize='12px'
                  textDecoration='underline'
                  color='red.200'
                  onClick={() => handleSelectCollabs(item)}
                >
                  {t('remove')}
                </Button>
              </Flex>
            ))}
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
