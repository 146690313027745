import React from 'react'
import { Cookies } from 'react-cookie'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import Storage from 'src/services/storage'
import { useIamStore } from '../../apis/store'

const useLogout = () => {
  const cookies = React.useMemo(() => {
    return new Cookies()
  }, [])
  const { clearToken } = useIamStore((store) => ({ clearToken: store.clearToken }))
  const { EventsImplementation, track } = useMixpanel()
  const logout = React.useCallback(() => {
    track(EventsImplementation.LOGOUT())
    clearToken()
    cookies.remove('authToken')
    Storage.path.remove()
    window.location.href = '/login'
  }, [cookies, clearToken, EventsImplementation, track])
  return { logout }
}

export default useLogout
