import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CmsSkillTable } from '../components/SkillTable'
import { CmsTabContent } from '../components/TabContent'
import { useAllocateSkillStore } from '../hooks/useAllocateSkillStore'
import { useCorporateUniversityStore } from '../hooks/useCorporateUniversityStore'
import { useListSkills } from '../hooks/useListSkills'

interface Props {
  tab: string
}

export function SkillTab({ tab }: Props) {
  const [t] = useTranslation()
  const { totalSkills } = useCorporateUniversityStore()
  const { listSkills, isLoadingSkills, refetch } = useListSkills()
  const { updateAllocateSkillModalState } = useAllocateSkillStore()

  function handleAllocateSkill() {
    updateAllocateSkillModalState(true)
  }

  useEffect(() => {
    listSkills()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <CmsTabContent
        pageTitle={t('admin.corporateUniversity.skills.pageTitle')}
        registerNewContentTitle={t('admin.corporateUniversity.skills.registerNewSkills')}
        handleRegisterNewContentClick={() => null} // criar function para abrir o drawer de criação de skill
        handleAllocateSkill={handleAllocateSkill}
        searchSectionTitle={t('admin.corporateUniversity.skills.searchSectionTitle')}
        searchPlaceholder={t('admin.corporateUniversity.skills.searchForSkill')}
        tabTable={CmsSkillTable}
        tab={tab}
        totalContentLength={totalSkills}
        isLoading={isLoadingSkills}
        refetch={refetch}
        onOpen={() => null} // onOpen do drawer de remoção de habilidade
      />
    </>
  )
}
