import { Flex, Text } from '@chakra-ui/react'

interface StepTitleProps {
  title: string
  subtitle?: string
}

export function StepTitle({ title, subtitle }: StepTitleProps) {
  return (
    <Flex flexDir='column' gap='8px'>
      <Text fontFamily='Poppins' fontSize='1.25rem' fontWeight='bold'>
        {title}
      </Text>
      {!!subtitle && (
        <Text color='bluishGray.500' fontSize='1rem'>
          {subtitle}
        </Text>
      )}
    </Flex>
  )
}
