import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import Close from 'src/components/AbilitiesQuiz/components/Close'

interface AbilityProps {
  name: string
  active?: boolean
  removeX?: boolean
  activationHandler: () => void
}

const inactiveStyle = {
  border: '2px solid #E5E5E5',
  borderRadius: '18px',
  color: '#313E4C',
  padding: '5px 15px',
  marginRight: '12px',
  fontWeight: '600',
  marginTop: '9px',
  justifyContent: 'center',
  _hover: {
    bg: 'gray.300',
    color: 'white',
    border: '1px solid gray.300',
  },
}

const activeStyle = {
  border: '2px solid #7ECD9D',
  backgroundColor: '#E5F4EB',
  color: '#313E4C',
  marginRight: '12px',
  fontWeight: '600',
  marginTop: '9px',
  borderRadius: '18px',
  justifyContent: 'space-between',
}

const Ability: React.FC<AbilityProps> = ({ name, active = false, removeX = false, activationHandler }) => {
  const style = active ? activeStyle : inactiveStyle

  return (
    <Flex
      {...style}
      h='34px'
      minW='100px'
      alignItems={'center'}
      userSelect='none'
      cursor={'pointer'}
      transition={'all .09s ease-in-out'}
      onClick={activationHandler}
    >
      {active && !removeX && <Close handler={activationHandler} />}
      <Text
        fontSize={'16px'}
        fontWeight='600'
        textAlign={'center'}
        flex='1'
        paddingX={active ? '15px' : 'initial'}
        noOfLines={1}
        textTransform='capitalize'
      >
        {name}
      </Text>
    </Flex>
  )
}

export default Ability
