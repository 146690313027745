import { SelectOption } from 'src/types/Cms'
import { create } from 'zustand'

export type IContentData = {
  id?: string
  url?: string
  type?: string
  duration?: string | number
  title?: string
  description?: string
  languages?: string[]
  subtitles?: string[]
  skills?: any[]
  cover?: File | null | string
  imageUrl?: string
  allowedCompanyIds?: number[]
  status?: string
  trailer?: string
  partner?: string
  issuesCertificate?: boolean
  contentTranslation?: {
    [key: string]: {
      title?: string
      description?: string
    }
  }
}

interface RegisterContentState {
  isRegisterModalOpen: boolean
  updateRegisterModalState: (value: boolean) => void
  selectedPermission: string
  updateSelectedPermission: (value: string) => void
  selectedCompanies: SelectOption[]
  incrementSelectedCompanies: (company: SelectOption) => void
  updateSelectedCompanies: (companies: SelectOption[]) => void
  selectedCompaniesSnapshot: SelectOption[]
  updateSelectedCompaniesSnapshot: (companies: any[]) => void
  hasPermissionsBeenClicked: boolean
  updateHasPermissionsBeenClicked: (value: boolean) => void
  isLoading: boolean
  updateLoading: (value: boolean) => void
  resetContentState: () => void
}

export const useRegisterContentStore = create<RegisterContentState>((set) => ({
  isRegisterModalOpen: false,
  updateRegisterModalState: (value) => set(() => ({ isRegisterModalOpen: value })),
  selectedPermission: '',
  updateSelectedPermission: (value) => set(() => ({ selectedPermission: value })),
  selectedCompanies: [],
  incrementSelectedCompanies: (company) =>
    set((prevState) => ({ selectedCompanies: [...prevState.selectedCompanies, company] ?? [] })),
  updateSelectedCompanies: (companies) => set(() => ({ selectedCompanies: companies })),
  selectedCompaniesSnapshot: [],
  updateSelectedCompaniesSnapshot: (companies) => set(() => ({ selectedCompaniesSnapshot: companies })),
  hasPermissionsBeenClicked: false,
  updateHasPermissionsBeenClicked: (value) => set(() => ({ hasPermissionsBeenClicked: value })),
  isLoading: false,
  updateLoading: (value) => set(() => ({ isLoading: value })),

  resetContentState: () =>
    set(() => ({
      isRegisterModalOpen: false,
      selectedPermission: '',
      selectedCompanies: [],
      selectedCompaniesSnapshot: [],
      isLoading: false,
    })),
}))
