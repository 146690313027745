import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Link as ChakraLink,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { saveAs } from 'file-saver'
import { useTranslation } from 'react-i18next'
import { IoDownloadOutline, IoLogoLinkedin } from 'react-icons/io5'
import EvaluateIcon from 'src/components/EvaluateIcon'
import { useEvaluate } from 'src/context/EvaluateContext'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { usePlaylistStore } from 'src/hooks/usePlaylist'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { getNumberOfContentsDone } from '../../../../pages/PlaylistPage/utils'
import { CertificateIcon } from '../../../../theme/Icons'

interface Props {
  playlist: NewPlaylist
}

const CertificateSection: React.FC<Props> = ({ playlist }: Props) => {
  const [t] = useTranslation()
  const { EventsImplementation, track } = useMixpanel()
  const { certificate } = usePlaylistStore()
  const { updatePlaylistInfo, openEvaluation } = useEvaluate()

  playlist = playlist!

  const numberOfContentsDone = getNumberOfContentsDone(playlist)
  const playlistLength = playlist.moduleList?.items?.reduce((sum, item) => sum + item.contentList?.items?.length!, 0);
  const isPlaylistComplete = numberOfContentsDone === playlistLength

  let certificateDate: Date | null = null

  if (certificate) {
    certificateDate = new Date(Number(certificate.createdAt))
  }

  return (
    <Flex
      marginX={{ base: '32px', lg: '72px' }}
      border='2px solid #E5E5E5'
      borderRadius='40px'
      padding={{ base: '8px 16px', lg: '22px 37px' }}
      gap={{ base: '16px' }}
      direction={{ base: 'column', lg: 'row' }}
      alignItems={{ base: 'center', lg: 'flex-start' }}
      justifyContent={{ base: 'center', lg: 'flex-start' }}
    >
      <Flex
        w='112px'
        h='112px'
        border='1px solid #ABABAB'
        borderRadius='100px'
        alignItems='center'
        justifyContent='center'
      >
        <Icon as={CertificateIcon} w='54px' h='51px' color={certificate ? 'primaryColors.400' : 'gray.300'} />
      </Flex>
      <Flex flexDir='column' flex={{ base: 'unset', lg: '1' }} width={{ base: '100%' }}>
        <Heading size='sm'>
          {!isPlaylistComplete
            ? `${t('playlist.See your progress on the Playlist')} ${playlist.title}`
            : `${t('playlist.Congratulations, you have completed the')} ${playlist.title}`}
        </Heading>
        <Text opacity='0.7' lineHeight='20px'>
          {!isPlaylistComplete
            ? `${t('playlist.We hope you are enjoying the content on the Playlist')} ${playlist.title}`
            : `${t('playlist.We hope you enjoyed your learning journey')}`}
        </Text>
        <Flex marginTop='16px' position='relative' flexDirection='column'>
          <Box w='100%' h='2px' opacity='0.3' backgroundColor='black' />
          <Box
            w={`${(numberOfContentsDone! / playlistLength!) * 100}%`}
            h='10px'
            backgroundColor='primaryColors.400'
            position='absolute'
            borderRadius='30px'
            top='-4px'
          />
          <Text
            color='gray.580'
            fontWeight='600'
            alignSelf='flex-end'
            fontFamily='Poppins'
            fontSize='12px'
            marginTop='5px'
          >
            {numberOfContentsDone}/{playlistLength}
          </Text>
        </Flex>

        {isPlaylistComplete && !certificate ? (
          <Text opacity='0.7' lineHeight='20px' marginBottom='22px'>
            {t('playlist.We are checking the details')}
          </Text>
        ) : null}

        <Flex
          direction={{ base: 'column', lg: 'row' }}
          alignItems={{ base: 'center', lg: 'unset' }}
          justifyContent={{ base: 'center', lg: 'unset' }}
          width={{ base: '100%', lg: 'auto' }}
          gap={{ base: '16px', lg: '0px' }}
          marginTop={{ base: '8px', lg: '0px' }}
          overflow='hidden'
          position='relative'
        >
          <Popover trigger='hover' placement='top'>
            {!certificate ? (
              <PopoverContent
                backgroundColor='gray.800'
                borderRadius='20px'
                width={'fit-content'}
                border='none'
                zIndex={'popover'}
              >
                <PopoverArrow backgroundColor='gray.800' />
                <PopoverBody textAlign={'center'}>
                  <Text fontSize='14px' color='white' fontWeight={700}>
                    {t('playlist.You need to complete the playlist to download your certificate')}
                  </Text>
                </PopoverBody>
              </PopoverContent>
            ) : null}

            <PopoverTrigger>
              <Button
                variant='startCourseDark'
                alignItems='center'
                disabled={certificate ? false : true}
                w={{ base: '100%', lg: 'auto' }}
                onClick={() => {
                  saveAs(certificate.url, `Certificado ${playlist.title}.jpg`)
                  track(EventsImplementation.DOWNLOAD_CERTIFICATE(playlist.title))
                }}
              >
                <Icon as={IoDownloadOutline} w='24px' h='24px' />
                <Text fontFamily='Poppins' fontWeight='600' marginLeft='8px'>
                  {t('playlist.Download playlist certificate')}
                </Text>
              </Button>
            </PopoverTrigger>
          </Popover>

          <Popover trigger='hover' placement='top'>
            {!certificate ? (
              <PopoverContent
                backgroundColor='gray.800'
                borderRadius='20px'
                width={'fit-content'}
                border='none'
                zIndex={'popover'}
              >
                <PopoverArrow backgroundColor='gray.800' />
                <PopoverBody textAlign={'center'}>
                  <Text fontSize='14px' color='white' fontWeight={700}>
                    {t('playlist.You need to complete the playlist to share your certificate')}
                  </Text>
                </PopoverBody>
              </PopoverContent>
            ) : null}

            <PopoverTrigger>
              {certificate ? (
                <ChakraLink
                  textDecoration='none'
                  href={`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
                    playlist.title
                  }&organizationId=68483898&issueYear=${certificateDate?.getFullYear()}&issueMonth=${
                    certificateDate ? certificateDate.getMonth() + 1 : ''
                  }&certUrl=${certificate?.url}&certId=${certificate?.playlistId}`}
                  target='_blank'
                  w={{ base: '100%', lg: 'auto' }}
                >
                  <Button
                    variant='startCourseDark'
                    onClick={() => {
                      track(EventsImplementation.SHARE_CERTIFICATE_ON_LINKEDIN(playlist.title))
                    }}
                    marginLeft={{ base: '0px', lg: '25px' }}
                    disabled={certificate ? false : true}
                    w={{ base: '100%', lg: 'auto' }}
                  >
                    <Icon as={IoLogoLinkedin} w='24px' h='24px' />
                    <Text fontFamily='Poppins' fontWeight='600' marginLeft='8px'>
                      Linkedin
                    </Text>
                  </Button>
                </ChakraLink>
              ) : (
                <Button
                  variant='startCourseDark'
                  marginLeft={{ base: '0px', lg: '25px' }}
                  w={{ base: '100%', lg: 'auto' }}
                  disabled={certificate ? false : true}
                >
                  <Icon as={IoLogoLinkedin} w='24px' h='24px' />
                  <Text fontFamily='Poppins' fontWeight='600' marginLeft='8px'>
                    Linkedin
                  </Text>
                </Button>
              )}
            </PopoverTrigger>
            <EvaluateIcon
              showText
              isPlaylist
              w={{ base: '100%', lg: 'auto' }}
              marginLeft={{ base: '0px', lg: '25px' }}
              onClick={() => {
                updatePlaylistInfo(playlist)
                openEvaluation({ step: 2, source: 'playlist' })
              }}
            />
          </Popover>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CertificateSection
