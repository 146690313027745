import { useToast } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { resendVerificationEmailApi } from '../../apis/resendVerificationEmail'
import { useIamStore } from '../../apis/store'
import { verifyUserByEmailApi } from '../../apis/verifyUserEmail'
import { UserStatusEnum } from '../../domain/user'
import { IAM_ERRORS } from '../../errors'

const COUNTDOWN = 30

const useConfirmEmailVerification = () => {
  const [t] = useTranslation()
  const toast = useToast()
  const { email, send, setUserEmail } = useIamStore((store) => ({
    email: store.userEmail,
    send: store.sendEvent,
    setUserEmail: store.setUserEmail,
  }))
  const [timer, setTimer] = React.useState(0)
  const [isLoading, setLoading] = React.useState(false)
  const resetTimer = React.useCallback(() => setTimer(COUNTDOWN), [])
  const RawErrors: Record<string, string> = React.useMemo(
    () => ({
      [IAM_ERRORS.INTERNAL_ERROR]: t('login.validation.Something went wrong'),
    }),
    [t],
  )
  const resendEmail = React.useCallback(() => {
    setLoading(true)
    resendVerificationEmailApi({ email })
      .then(() => {
        resetTimer()
        setLoading(false)
        toast({
          title: t('onboard.confirmEmail.emailSent'),
          isClosable: true,
          duration: 5000,
          status: 'success',
        })
      })
      .catch(() => {
        resetTimer()
        setLoading(false)
        toast({
          title: t('onboard.confirmEmail.emailSentError'),
          isClosable: true,
          duration: 5000,
          status: 'error',
        })
      })
  }, [t, resetTimer, toast, email, setLoading])

  const confirmVerification = React.useCallback(() => {
    setLoading(true)
    verifyUserByEmailApi({ email })
      .then((data) => {
        setLoading(false)
        if (data.statusCode.includes(UserStatusEnum.CONFIRMED)) send({ type: 'EMAIL_VERIFIED' })
        else {
          toast({
            title: t('onboard.confirmEmail.notVerifiedError'),
            isClosable: true,
            duration: 5000,
            status: 'error',
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        const message = RawErrors[error?.code] ?? RawErrors.INTERNAL_ERROR
        toast({
          title: message,
          isClosable: true,
          duration: 5000,
          status: 'error',
        })
        send({ type: 'INVALID_CREDENTIALS' })
      })
  }, [RawErrors, toast, t, email, send])

  React.useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((time) => time - 1)
      }, 1000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [timer])
  return { timer, setUserEmail, email, isCounting: timer > 0, resendEmail, confirmVerification, isLoading }
}

export default useConfirmEmailVerification
