import { Team } from 'src/modules/dashboard/entities/Team'
import { SelectOption } from 'src/types/Cms'
import { create } from 'zustand'

interface RegisterNewContentStore {
  selectedCompanies: SelectOption[]
  incrementSelectedCompanies: (company: SelectOption) => void
  updateSelectedCompanies: (companies: SelectOption[]) => void

  savedSelectedCompanies: SelectOption[]
  incrementSavedSelectedCompanies: (company: SelectOption) => void
  updateSavedSelectedCompanies: (companies: SelectOption[]) => void

  selectedPermission: string
  updateSelectedPermission: (value: string) => void

  hasPermissionsBeenClicked: boolean
  updateHasPermissionsBeenClicked: (value: boolean) => void

  selectedTeamsToRestrict: Team[]
  updateSelectedTeamsToRestrict: (value: Team[]) => void
}

export const useRegisterNewContent = create<RegisterNewContentStore>((set) => ({
  selectedCompanies: [],
  incrementSelectedCompanies: (company) =>
    set((prevState) => ({ selectedCompanies: [...prevState.selectedCompanies, company] ?? [] })),
  updateSelectedCompanies: (companies) => set(() => ({ selectedCompanies: companies })),

  savedSelectedCompanies: [],
  incrementSavedSelectedCompanies: (company) =>
    set((prevState) => ({ selectedCompanies: [...prevState.selectedCompanies, company] ?? [] })),
  updateSavedSelectedCompanies: (companies) => set(() => ({ selectedCompanies: companies })),

  selectedPermission: '',
  updateSelectedPermission: (value) => set(() => ({ selectedPermission: value })),

  hasPermissionsBeenClicked: false,
  updateHasPermissionsBeenClicked: (value) => set(() => ({ hasPermissionsBeenClicked: value })),

  selectedTeamsToRestrict: [],
  updateSelectedTeamsToRestrict: (value) => set(() => ({ selectedTeamsToRestrict: [...value] })),
}))
