import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import Footer from 'src/components/Footer'
import { TryRequestAgain } from 'src/components/TryRequestAgain'
import { useUser } from 'src/context/userContext'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { usePlaylists } from 'src/modules/cms/hooks/usePlaylists'
import AllSection from './components/AllSection'
import DescriptionSection from './components/DescriptionSection'
import { PlaylistsSectionSkeleton } from '../../modules/cms/components/Playlists/Skeleton'
import '../../services/i18n/i18n'
import { removeDraftPlaylists } from '../Home/utils'

const PrivatePlaylists: React.FC = () => {
  const [loadingPlaylists, setLoadingPlaylists] = useState(true)
  const { user } = useUser()
  const { listPrivatePlaylists } = usePlaylists()
  const [playlists, setPlaylists] = useState<NewPlaylist[] | undefined>(undefined)
  const [error, setError] = useState(false)

  const [t] = useTranslation()

  const fetchPlaylists = async () => {
    setLoadingPlaylists(true)

    const playlistsList = await listPrivatePlaylists({
      limit: 100,
      skip: 0
    })
    const validPlaylists = removeDraftPlaylists(playlistsList?.items || [])

    if (!validPlaylists) {
      setError(true)
    } else {
      const featuredPlaylists = validPlaylists ?? []
      setPlaylists(featuredPlaylists)
      setError(false)
    }

    setLoadingPlaylists(false)
  }

  useEffect(() => {
    if (user) fetchPlaylists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <Flex width='100%' flexDirection='column' minH='100%'>
      <Helmet>
        <title>{t('playlists.private')} | Education Journey</title>
      </Helmet>
      <DescriptionSection />
      {!loadingPlaylists && !error && playlists && <AllSection playlists={playlists} />}

      {loadingPlaylists && <PlaylistsSectionSkeleton />}

      {!loadingPlaylists && (error || playlists === undefined) && (
        <Flex w='100%' h='100%'>
          <TryRequestAgain
            errorMessage={t('tryRequestAgain.playlists')}
            functionToExecute={fetchPlaylists}
            imageHeight={300}
          />
        </Flex>
      )}
      <Footer />
    </Flex>
  )
}

export default PrivatePlaylists
