import { Button, Flex, Input, Text, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleTeam } from 'src/context-admin/useHandleTeam'
import { makeDeleteTeam } from 'src/modules/dashboard/factory/makeRemoveTeam'
import { StepTitle } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTitle'
import { StepTransition } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTransition'

export function EditExistingTeamDeleteTeam() {
  const [t] = useTranslation()
  const toast = useToast()
  const { navigateToStep, team, handleFinalMessage } = useHandleTeam()
  const [loading, setLoading] = useState(false)
  const [phraseInput, setPhraseInput] = useState('')
  const deleteTeam = makeDeleteTeam()
  const phraseToConfirmExclusion = `Quero excluir a ${team?.name}`.toLowerCase()

  const handleSubmitRemoveTeam = async () => {
    setLoading(true)
    try {
      const response = await deleteTeam.execute({
        teamId: team?.id ?? '',
      })
      if (!response?.id) {
        throw new Error()
      }
      handleFinalMessage(t('teamDeleted', { teamName: team?.name }))
      navigateToStep('success-screen')
    } catch (err: any) {
      toast({
        title: t('errorAddingNewMembers'),
        description: String(err?.message),
        status: 'error',
        isClosable: true,
        duration: 7000,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <StepTransition>
      <Flex flexDir='column' gap='16px'>
        <StepTitle title={`Confirmação de exclusão de "${team?.name ?? 'Equipe inválida'}"`} />
        <Text fontSize='1rem' fontWeight='600' color='#767F89'>
          {t('whenDeletingATeam')}
        </Text>
        <Text fontSize='1rem' fontWeight='600' color='#767F89'>
          {t('onceTheTeamIsDeleted')}
        </Text>
        <Text fontSize='1rem' fontWeight='600' color='#313E4C'>
          {t('confirmThisActionByTypingThePhraseBelow')} <strong>Quero excluir a {team?.name}</strong>
        </Text>
        <Flex gap='8px'>
          <Input
            placeholder=''
            borderRadius='12px'
            borderWidth='2px'
            borderColor='#E5E5E5'
            px='16px'
            height='36px'
            value={phraseInput}
            onChange={(e) => setPhraseInput(e.target.value)}
          />
          <Button
            variant='startCourseDark'
            fontSize='0.75rem'
            fontFamily='Poppins'
            py='4px'
            px='12px'
            h='auto'
            borderRadius='12px'
            isDisabled={team?.name ? phraseInput.toLowerCase() !== phraseToConfirmExclusion.toLowerCase() : true}
            isLoading={loading}
            onClick={handleSubmitRemoveTeam}
          >
            {t('confirm')}
          </Button>
        </Flex>
        <Flex mt='8px'>
          <Button
            variant='outline'
            fontSize='0.75rem'
            fontFamily='Poppins'
            py='0'
            px='12px'
            h='36px'
            borderRadius='12px'
            borderWidth='2px'
            borderColor='#efefef'
            _hover={{ bg: '#fcfcfc' }}
            isDisabled={loading}
            onClick={() => {
              navigateToStep('edit-existing-team--select-options')
            }}
          >
            {t('back')}
          </Button>
        </Flex>
      </Flex>
    </StepTransition>
  )
}
