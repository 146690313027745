import { PaginateApiResponse } from 'src/shared/PaginateApiResponse'
import { UserRepository } from '../repository/UserRepository'

interface GetUserCertificatesInput {
  page?: number
  limit?: number
  playlistId?: string
  type?: CertificateTypeEnum
}

export enum CertificateTypeEnum {
  CustomPlaylist = 'custom_playlist',
  CustomPlaylistModule = 'custom_playlist_module',
  FeaturedPlaylist = 'features_playlist',
  FeaturedPlaylistModule = 'featured_playlist_module',
}

export type CertificateDto = {
  url: string
  playlistId?: string
  moduleId?: string
  type: CertificateTypeEnum
  userId: number
  createdAt: string
}

type GetUserCertificatesOutput = PaginateApiResponse<CertificateDto>

export class GetUserCertificatesUseCase {
  constructor(private userRepository: UserRepository) {}

  async execute(args: GetUserCertificatesInput): Promise<GetUserCertificatesOutput> {
    const certificates = await this.userRepository.getUserCertificates(args.page, args.limit, args.playlistId, args.type)
    return certificates
  }
}
