import { useTranslation } from 'react-i18next'
import { Role } from 'src/types/Role'
import { ITab } from '../types/ITabs'

interface CmsTab {
  id: ITab
  name: string
  roleNeeded?: Role
  isDisabled: boolean
}

export const useCmsTabs = () => {
  const [t] = useTranslation()
  const cmsTabs: CmsTab[] = [
    {
      id: 'conteudos',
      name: t('contents'),
      roleNeeded: Role.ADMIN,
      isDisabled: false,
    },
    {
      id: 'conteudosImportados',
      name: t('importedContents'),
      roleNeeded: Role.BACKOFFICE,
      isDisabled: false,
    },
    {
      id: 'trilhas',
      name: t('common.Playlists'),
      roleNeeded: Role.ADMIN,
      isDisabled: false,
    },
    {
      id: 'habilidades',
      name: t('skills'),
      roleNeeded: Role.ADMIN,
      isDisabled: false,
    },
    {
      id: 'certificados',
      name: t('certificates'),
      roleNeeded: Role.ADMIN,
      isDisabled: false,
    },
    {
      id: 'tokens',
      name: t('tokenValidation'),
      roleNeeded: Role.ADMIN,
      isDisabled: false,
    },
  ]

  return cmsTabs
}
