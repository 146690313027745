import httpClient from 'src/services/http/http-client'
import { getAccessToken } from '../apis/store'
import { SaveUserEditionMassive, UserRepository } from '../repository/UserRepository'

export class SaveUserMassiveUseCase {
  constructor(private userRepository: UserRepository) {}

  async execute(params: SaveUserEditionMassive) {
    const wasSaved = await this.userRepository.saveUserEditionMassive(params)
    return wasSaved
  }
}

export function makeSaveUserMassive() {
  const token = getAccessToken()
  const userRepo = new UserRepository(token, httpClient)
  return new SaveUserMassiveUseCase(userRepo)
}
