import { UseCase } from 'src/types/UseCase'
import { ReportRepository } from '../repository/ReportRepository'

export type GetReportInput = {
  tabId: string
  userLanguage: string
}

export type GetReportOutput = any

export class GetReportUseCase implements UseCase<GetReportInput, GetReportOutput> {
  constructor(private repository: ReportRepository) {}

  async execute(args: GetReportInput): Promise<GetReportOutput> {
    const report = await this.repository.getReport(args.tabId, args.userLanguage)
    return report
  }
}
