import { Flex, Heading, Image, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useUser } from 'src/context/userContext'
import { useWindowSize } from 'src/hooks/useWindowSize'
import Partner from 'src/modules/cms/domain/Partner'
import { PartnerHeaderSkeleton } from './Skeleton'

interface Props {
  partner: Partner | undefined
}

const PartnerHeader: React.FC<Props> = ({ partner }: Props) => {
  const { width } = useWindowSize()
  const isMobile = useMemo(() => !!width && width < 992, [width])
  const { user } = useUser()
  const userLanguage = user?.language

  const descriptionToShow =
    partner?.translations?.find((translation) => translation.language === userLanguage)?.description ??
    partner?.description

  if (!partner) return <PartnerHeaderSkeleton />

  if (isMobile) {
    return (
      <Flex flexDirection='column'>
        <Flex alignItems='center' justifyContent='flex-start' padding='20px 20px 0px' gap='12px'>
          <Image h='56px' src={partner?.squareLogoUrl} />
          <Heading fontSize='1.75rem'>{partner?.name}</Heading>
        </Flex>
        <Flex flexDirection='column' padding='20px'>
          <Text fontSize='18px' opacity='0.7'>
            {descriptionToShow}
          </Text>
          {/* <Flex marginTop='20px' flexWrap='wrap' gap='12px'>
            {partner?.categories?.map((category: Category) => {
              return (
                <Tag
                  borderRadius='18px'
                  background='gray.65'
                  padding='5px 11px'
                  textTransform='capitalize'
                  width='fit-content'
                  color='gray.690'
                  fontWeight='600'
                  fontSize='13px'
                >
                  <TagLabel textTransform='capitalize'>{category?.name}</TagLabel>
                </Tag>
              )
            })}
          </Flex> */}
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex flexDirection='column'>
      <Flex w='100%' h='213px' background='primaryColors.350' />
      <Flex paddingLeft='72px' paddingRight='72px'>
        <Flex
          minW='200px'
          minH='200px'
          h='200px'
          border='2px solid #E5E5E5'
          borderRadius='60px'
          background='white'
          alignItems='center'
          justifyContent='center'
          marginTop='-72px'
        >
          <Image h='93px' src={partner?.squareLogoUrl} />
        </Flex>
        <Flex marginLeft='32px' marginTop='7px' flexDirection='column'>
          <Heading size='md'>{partner?.name}</Heading>
          <Text fontSize='18px' opacity='0.7'>
            {descriptionToShow}
          </Text>
          {/* <Flex marginTop='20px'>
            {partner?.categories?.map((category: Category) => {
              return (
                <Tag
                  borderRadius='18px'
                  background='gray.65'
                  padding='5px 11px'
                  marginRight='10px'
                  textTransform='capitalize'
                  width='fit-content'
                  color='gray.690'
                  fontWeight='600'
                  fontSize='13px'
                >
                  <TagLabel textTransform='capitalize'>{category?.name}</TagLabel>
                </Tag>
              )
            })}
          </Flex> */}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PartnerHeader
