import { LooseObject } from 'src/types/LooseObject'
import { User } from 'src/types/User'
import { FormValues } from './types/Form'

export const errorDetails: LooseObject = {
  INVALID_EMAIL: 'o email é inválido.',
  LANGUAGE_NOT_FOUND: 'o código do idioma é inválido.',
  USER_ALREADY_EXISTS: 'o usuário já foi cadastrado.',
  UNKNOWN_ERROR: 'um erro desconhecido aconteceu.',
  USER_NOT_FOUND: 'o usuário não foi encontrado.',
}

const MOCKED_LANGUAGES = [
  {
    name: 'Português',
    code: 'pt-BR',
  },
  {
    name: 'Español',
    code: 'es',
  },
]

export const generateAddDescriptionLanguagesText = (user?: User) => {
  if (!user) return ''

  let text = ''

  MOCKED_LANGUAGES.forEach((language, index) => {
    text += ` ${language.name} (${language.code})`
    if (index !== MOCKED_LANGUAGES.length - 1) text += ','
  })

  text += '.'

  return text
}

export const blankFormValues: FormValues = {
  completeName: '',
  email: '',
  language: 'pt-BR',
  // team: '',
  centralCost: '',
  branch: '',
  area: '',
  isManager: 'no',
  managerFirstName: '',
  managerLastName: '',
  managerEmail: '',
  contractModel: '',
  contractDate: '',
  location: '',
  globalTitle: '',
  status: '',
  userIdInClientPlatform: '',
  gender: '',
  birthDate: '',
}

export const blankEditFormValues = {
  nomeUsuario: '',
  centroCusto: '',
  filial: '',
  area: '',
  gestor: 'no',
  idioma: 'pt-BR',
  nomeGestor: '',
  sobrenomeGestor: '',
  emailGestor: '',
  modeloContrato: '',
  dataContratacao: '',
  localizacao: '',
  tituloGlobalCargo: '',
  status: '',
  telefone: '',
  genero: '',
  dataNascimento: '',
  plataformaClienteId: '',
}

export function generateObjectToRegisterUser(formValues: FormValues) {
  const formattedEmployees = {
    companyIdentifier: formValues.email,
    name: formValues.completeName,
    email: formValues.email,
    languageCode: formValues.language,
    // team: formValues.team ?? '',
    customAttributes: {
      centroCusto: formValues.centralCost ?? '',
      filial: formValues.branch ?? '',
      area: formValues.area ?? '',
      gestor: formValues?.isManager === 'yes' ?? false,
      nomeGestor: formValues.managerFirstName ?? '',
      sobrenomeGestor: formValues.managerLastName ?? '',
      emailGestor: formValues.managerEmail ?? '',
      modeloContrato: formValues.contractModel ?? '',
      dataContratacao: formValues.contractDate ?? '',
      localizacao: formValues.location ?? '',
      tituloGlobalCargo: formValues.globalTitle ?? '',
      status: formValues.status ?? '',
    },
  }
  return formattedEmployees
}

export const csvCreationHeaders = [
  'companyIdentifier',
  'name',
  'email',
  'userIdInClientPlatform',
  'birthDate',
  'gender',
  'languageCode',
  'centroCusto',
  'filial',
  'area',
  'team',
  'gestor',
  'nomeGestor',
  'sobrenomeGestor',
  'emailGestor',
  'modeloContrato',
  'dataContratacao',
  'localizacao',
  'tituloGlobalCargo',
  'status',
]

export const csvEditionHeaders = [
  'name',
  'email',
  'userIdInClientPlatform',
  'birthDate',
  'gender',
  'languageCode',
  'centroCusto',
  'filial',
  'area',
  'team',
  'gestor',
  'nomeGestor',
  'sobrenomeGestor',
  'emailGestor',
  'modeloContrato',
  'dataContratacao',
  'localizacao',
  'tituloGlobalCargo',
  'status',
]
