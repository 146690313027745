import { CompanyForm } from '../hooks/useCompany'
import { CompanyRepository } from '../repository/Company'

interface CreateCompanyInput extends CompanyForm {
  logoUrl?: string
}

export class CreateCompanyUseCase {
  constructor(private repository: CompanyRepository) {}

  async execute(args: CreateCompanyInput): Promise<void> {
    await this.repository.createCompany({
      name: args.name,
      tradeName: args.tradeName,
      cnpj: args.cnpj,
      admin: {
        username: args.adminEmail,
        name: args.adminName,
      },
      ...(args.logoUrl ? { logoUrl: args.logoUrl } : {}),
    })
  }
}
