import { Flex, Grid, Skeleton, Stack } from '@chakra-ui/react'

interface MyCredentialsSkeletonProps {
  isMobile?: boolean
}

const MyCredentialsSkeleton: React.FC<MyCredentialsSkeletonProps> = ({ isMobile }) => {
  if (isMobile) {
    return (
      <Flex flexDir='column'>
        <Skeleton h='24px' w='100px' marginBottom='12px' borderRadius='16px' />

        {Array.from({ length: 2 }).map((_, index) => (
          <Skeleton key={index} h='286px' w='100%' marginBottom='12px' borderRadius='16px' />
        ))}
      </Flex>
    )
  }

  return (
    <Flex width='100%' flexDirection='column'>
      <Grid gridTemplateColumns='2fr 1.5fr 0.5fr' h='100%'>
        <Stack flexDirection='column' paddingRight='20px' paddingTop='26px'>
          <Skeleton h='13px' w='100px' marginBottom='12px' />

          <Stack flexDirection='column' spacing={10}>
            <Stack flexDirection='row' alignItems='center'>
              <Skeleton w='53px' h='51px' borderRadius='6px' marginRight='15px' />
              <Skeleton h='16px' w='350px' />
            </Stack>
            <Stack flexDirection='row' alignItems='center'>
              <Skeleton w='53px' h='51px' borderRadius='6px' marginRight='15px' />
              <Skeleton h='16px' w='350px' />
            </Stack>
            <Stack flexDirection='row' alignItems='center'>
              <Skeleton w='53px' h='51px' borderRadius='6px' marginRight='15px' />
              <Skeleton h='16px' w='350px' />
            </Stack>
            <Stack flexDirection='row' alignItems='center'>
              <Skeleton w='53px' h='51px' borderRadius='6px' marginRight='15px' />
              <Skeleton h='16px' w='350px' />
            </Stack>
          </Stack>
        </Stack>
        <Stack flexDirection='column' padding='26px 53px 0px 60px' bg='gray.25'>
          <Skeleton h='13px' w='60px' marginBottom='24px' />

          <Stack flexDirection='column'>
            <Flex marginTop='10px'>
              <Skeleton h='16px' w='170px' />
            </Flex>
            <Flex paddingTop='60px'>
              <Skeleton h='16px' w='170px' />
            </Flex>
            <Flex paddingTop='70px'>
              <Skeleton h='16px' w='170px' />
            </Flex>
            <Flex paddingTop='70px'>
              <Skeleton h='16px' w='170px' />
            </Flex>
          </Stack>
        </Stack>
        <Stack flexDirection='column' paddingTop='26px' alignItems='center'>
          <Skeleton h='13px' w='100px' marginBottom='22px' />

          <Stack flexDirection='column' spacing={16}>
            <Skeleton h='24px' w='24px' />
            <Skeleton h='24px' w='24px' />
            <Skeleton h='24px' w='24px' />
            <Skeleton h='24px' w='24px' />
          </Stack>
        </Stack>
      </Grid>
    </Flex>
  )
}

export default MyCredentialsSkeleton
