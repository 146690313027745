import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAddContentDrawer } from 'src/context/CustomPlaylist/AddContentDrawerContext'
import LearningItemDrawer from '../../../../../components/ContentDrawer'

const KnowMoreAddDrawer: React.FC = () => {
  const { addContentKnowMoreDrawer, setAddContentKnowMoreDrawer, addContentModuleInfo } =
    useAddContentDrawer()

  const [t] = useTranslation()
  const closeKnowMoreAddDrawer = () => setAddContentKnowMoreDrawer(undefined)

  return (
    <LearningItemDrawer
      drawerInfo={{
        content: addContentKnowMoreDrawer!,
        moduleTitle: addContentModuleInfo!.title,
        type: 'ADD',
      }}
      onCloseClick={closeKnowMoreAddDrawer}
      closeText={t('home.Custom Playlist.back')}
    />
  )
}

export default KnowMoreAddDrawer
