import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { PlaylistRepository } from '../repositories/PlaylistRepository'

type GetPlaylistByIdUseCaseInput = {
  id: string;
}

export type GetPlaylistByIdUseCaseOutput = NewPlaylist

export class GetPlaylistByIdUseCase {
  constructor(private playlistRepository: PlaylistRepository) {}

  async execute(input: GetPlaylistByIdUseCaseInput): Promise<GetPlaylistByIdUseCaseOutput> {
    const playlist = await this.playlistRepository.getById(input.id)
    return playlist
  }
}
