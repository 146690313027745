import { Button, Drawer, DrawerCloseButton, DrawerContent, DrawerOverlay, Flex, Image, Text } from '@chakra-ui/react'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRegisterContentStore } from 'src/pages-admin/Cms/hooks/useRegisterContentStore'
import { useCorporateUniversityStore } from '../../hooks/useCorporateUniversityStore'
import { useRegisterPlaylistStore } from '../../hooks/useRegisterPlaylistStore'
import { useRegisterNewContent } from '../../hooks/useRegisterNewContent'

interface ContentAddedDrawerProps {
  isOpen: boolean
  isEdit: boolean
  onClose: () => void
  isPlaylist?: boolean
}

export function ContentAddedDrawer({ isOpen, onClose, isEdit, isPlaylist }: ContentAddedDrawerProps) {
  const [t] = useTranslation()
  const { resetContentState } = useRegisterContentStore()
  const { resetPlaylistState, updateContentsDrawerStep } = useRegisterPlaylistStore()
  const { updateActiveTab,updatePlaylistPermissions } = useCorporateUniversityStore()
  const {
    updateSelectedTeamsToRestrict,
  } = useRegisterNewContent()

  const handleCloseDrawer = useCallback(() => {
    resetContentState()
    resetPlaylistState()
    updateContentsDrawerStep(0)
    updateActiveTab(isPlaylist ? 'trilhas' : 'conteudos')
    updateSelectedTeamsToRestrict([])
    updatePlaylistPermissions([])
    onClose()
  }, [
    onClose,
    resetContentState,
    resetPlaylistState,
    updateActiveTab,
    isPlaylist,
    updateContentsDrawerStep,
    updateSelectedTeamsToRestrict,
    updatePlaylistPermissions
  ])

  useEffect(() => {
    setTimeout(() => {
      handleCloseDrawer()
    }, 3000)
  }, [handleCloseDrawer])

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={handleCloseDrawer}
      size='xl'
      data-testid='cms-content-added-drawer-wrapper'
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton data-testid='cms-content-added-drawer-close-button' />

        <Flex w='100%' h='100%' align='center' justify='center' data-testid='cms-content-added-drawer-success'>
          <Flex flexDir='column' align='center' justify='center' w='100%' h='100%'>
            <Image src='/assets/images/ballon.png' />
            <Text mt='48px' fontWeight={700} fontSize='1.25rem'>
              {isPlaylist ? 'A trilha' : 'O conteúdo'} foi {isEdit ? 'editado' : 'incluído'} com sucesso!
            </Text>
            <Button
              variant='unstyled'
              bg='gray.800'
              color='white'
              fontWeight='bold'
              padding='0px 16px'
              h='44px'
              fontSize='1rem'
              mt='16px'
              onClick={handleCloseDrawer}
            >
              {`${t('accessDashboard')} ${isPlaylist ? t('common.Playlists') : t('contents')}`}
            </Button>
          </Flex>
        </Flex>
      </DrawerContent>
    </Drawer>
  )
}
