import Icon from '@chakra-ui/icon'
import { Divider, Grid, HStack, Text } from '@chakra-ui/layout'
import { useTranslation } from 'react-i18next'
import { IoCheckmarkCircleOutline } from 'react-icons/io5'
// import '../../../i18n'
import { Step } from '../../../pages-admin/onboard/utils'

export interface StepBoxProps {
  step: Step
  status: 'todo' | 'doing' | 'done'
  hasTail: boolean
}

const StepBox: React.FC<StepBoxProps> = ({ step, status, hasTail }: StepBoxProps) => {
  const [t]: any = useTranslation()
  const icon = status === 'done' ? IoCheckmarkCircleOutline : step.icon
  return (
    <HStack alignItems='center'>
      {hasTail && (
        <Divider
          orientation='horizontal'
          borderWidth='2px'
          variant='dashed'
          borderColor={status === 'todo' ? 'gray.100' : 'primaryColors.700'}
          width='37px'
          marginX='16px'
        />
      )}
      <Grid
        w='33px'
        h='33px'
        bg={status === 'todo' ? 'gray.50' : 'primaryColors.700'}
        borderRadius='100%'
        placeItems='center'
      >
        <Icon as={icon} color={status === 'todo' ? 'gray.600' : 'white'} fontSize='sm'></Icon>
      </Grid>
      <Text
        color={status === 'todo' ? 'gray.300' : 'primaryColors.700'}
        fontWeight='bold'
        fontSize='md'
        fontFamily='Poppins'
      >
        {t(`onboard.steps.${step.title}`)}
      </Text>
    </HStack>
  )
}

export default StepBox
