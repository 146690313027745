import { getAccessToken } from 'src/modules/iam/apis/store'
import httpClient from 'src/services/http/http-client'
import { TeamRepository } from '../repository/TeamRepository'
import { SwapTeamManagerUseCase } from '../use-case/SwapTeamManagerUseCase'

export function makeSwapTeamManager() {
  const token = getAccessToken()

  const repository = new TeamRepository(token, httpClient)
  return new SwapTeamManagerUseCase(repository)
}
