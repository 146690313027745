import { Box, Input, Text } from '@chakra-ui/react'

interface Props {
  title: string
  placeholder: string
  handleSearchChange: (search: string) => void
}

const SearchInput = ({ title, placeholder, handleSearchChange }: Props) => {
  return (
    <Box mt='12px !important' w='100%'>
      <Text color='#313E4C' fontSize='16px' fontWeight={700} mb='4px'>
        {title}
      </Text>
      <Input
        placeholder={placeholder}
        variant='newFilled'
        height='36px'
        onChange={(e) => handleSearchChange(e.target.value)}
        data-testid='admin-home-search-user'
      />
    </Box>
  )
}

export default SearchInput
