import { DashboardReportsTabsWithElement } from 'src/types/DashboardReports'
import { ReportContent } from './components/ReportContent'

export const availableTabs: DashboardReportsTabsWithElement = {
  summary: <ReportContent />,
  register: <ReportContent />,
  custom: <ReportContent />,
  featured: <ReportContent />,
  contents: <ReportContent />,
  resume: <ReportContent />,
}
