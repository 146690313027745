import { TFunction } from 'react-i18next'
import { ReturnHome } from 'src/pages/Home/types'
import { LearningItem } from 'src/modules/cms/domain/LearningItem'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { CmsStatusFilterType } from 'src/types/Cms'

export const removeDraftPlaylists = (playlists: NewPlaylist[]) => {
  const filteredPlaylists = playlists.filter(playlist => playlist.status !== CmsStatusFilterType.DRAFT)
  return filteredPlaylists
}

export const sortPlaylists = (companyId: number, playlists: NewPlaylist[]) => {
  const toSort = playlists.slice().sort((firstElem: NewPlaylist, secondElem: NewPlaylist) => {
    if (isRecommended(companyId, firstElem) && !isRecommended(companyId, secondElem)) {
      return -1
    } else if (!isRecommended(companyId, firstElem) && isRecommended(companyId, secondElem)) {
      return 1
    }
    return 0
  })

  const sortedCustomPlaylists = sortByCustomPlaylists(toSort)

  return sortByCompanyPlaylists(sortedCustomPlaylists)
}

const sortByCustomPlaylists = (playlists: NewPlaylist[]) => {
  return playlists.sort(
    (firstElem, secondElem) =>
      Number(secondElem.type === 'CUSTOM') - Number(firstElem.type === 'CUSTOM'),
  )
}

const sortByCompanyPlaylists = (playlists: NewPlaylist[]) => {
  return playlists.sort(
    (firstElem, secondElem) =>
      Number(secondElem.type === 'COMPANY_PLAYLIST') - Number(firstElem.type === 'COMPANY_PLAYLIST'),
  )
}

export const isRecommended = (companyId: number, playlist: NewPlaylist): boolean => {
  return false // playlist.recommended
}

export const toTitleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

type Obj = {
  [x: string]: {
    label: string
    score: number
    learningItems: LearningItem[]
  }
}

export const getMostSeenLearningItems = (data: ReturnHome, t: TFunction<'translation', undefined>) => {
  const mostSeenLearningItemsScore = data.whatOthersAreWatching?.reduce((acc, item) => acc + item.score, 0) ?? 0

  return {
    label: t('common.mostSeenCourses'),
    score: mostSeenLearningItemsScore,
    learningItems: data.whatOthersAreWatching ?? [],
  }
}

export const sortLearningItemsLines = (data: ReturnHome, t: TFunction<'translation', undefined>) => {
  const relatedToAccessedLearningItemsScore =
    data.relatedToAccessedLearningItems?.reduce((acc, item) => acc + item.score, 0) || 0
  const shortRecommendedLearningItemScore =
    data.shortRecommendedLearningItems?.reduce((acc, item) => acc + item.score, 0) || 0

  const obj: Obj = {
    mostSeenLearningItems: getMostSeenLearningItems(data, t),
    relatedToAccessedLearningItems: {
      label: t('common.relatedToAccessedCourses'),
      score: relatedToAccessedLearningItemsScore,
      learningItems: data.relatedToAccessedLearningItems || [],
    },
    shortRecommendedLearningItems: {
      label: t('common.shortRecommendedCourses'),
      score: shortRecommendedLearningItemScore,
      learningItems: data.shortRecommendedLearningItems || [],
    },
  }

  const sortedLearningItemsLines = Object.keys(obj)
    .sort((a, b) => obj[b].score - obj[a].score)
    .map((item) => obj[item])

  return sortedLearningItemsLines
}