import { useTranslation } from 'react-i18next'
import { FormValues } from '../types/Form'

interface SingleEmployeeFormOptions {
  label: string
  id: keyof FormValues
  isRequired?: boolean
  defaultValue?: string
  placeholder?: string
  options?: { label: string; value: string }[]
  maxLength?: number
  formatFunction?: (value: string) => string
}

interface SingleEmployeeFormProps {
  areas: any[]
  teams: any[]
}

function formatDateInput(value: string): string {
  const onlyNumbers = value.replace(/[^0-9]/g, '')
  return onlyNumbers.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3')
}

export function useSingleEmployeeFormOptions({ areas, teams }: SingleEmployeeFormProps): SingleEmployeeFormOptions[] {
  const [t] = useTranslation()
  const MOCKED_PROPS: SingleEmployeeFormOptions[] = [
    {
      label: t('completeName'),
      id: 'completeName',
    },
    {
      label: 'Email',
      id: 'email',
      isRequired: true,
    },
    {
      label: t('language'),
      id: 'language',
      defaultValue: 'pt-BR',
      options: [
        { label: 'Português', value: 'pt-BR' },
        { label: 'Español', value: 'es' },
      ],
    },
    {
      label: t('centralCost'),
      id: 'centralCost',
    },
    {
      label: t('branch'),
      id: 'branch',
    },
    {
      label: t('area'),
      id: 'area',
      defaultValue: '',
      options: areas.map((area) => ({ label: area.name, value: area.id })),
    },
    {
      label: t('isManager'),
      id: 'isManager',
      defaultValue: 'false',
      options: [
        { label: t('no'), value: 'no' },
        { label: t('yes'), value: 'yes' },
      ],
    },
    {
      label: t('managerFirstName'),
      id: 'managerFirstName',
    },
    {
      label: t('managerLastName'),
      id: 'managerLastName',
    },
    {
      label: t('managerEmail'),
      id: 'managerEmail',
    },
    {
      label: t('contractModel'),
      id: 'contractModel',
    },
    {
      label: t('contractDate'),
      id: 'contractDate',
      placeholder: 'dd/mm/yyyy',
      formatFunction: formatDateInput,
      maxLength: 8,
    },
    {
      label: t('location'),
      id: 'location',
    },
    {
      label: t('globalJobTitle'),
      id: 'globalTitle',
    },
    {
      label: 'Status',
      id: 'status',
      defaultValue: '',
      options: [
        { label: t('active'), value: 'active' },
        { label: t('inactive'), value: 'inactive' },
      ],
    },
    {
      label: t('userIdInClientPlatform'),
      id: 'userIdInClientPlatform',
    },
    {
      label: t('gender'),
      id: 'gender',
      defaultValue: '',
      options: [
        { label: t('masculine'), value: 'masculino' },
        { label: t('feminine'), value: 'feminino' },
        { label: t('uninformed'), value: 'não informado' },
      ],
    },
    {
      label: t('birthDate'),
      id: 'birthDate',
      placeholder: 'dd/mm/yyyy',
      formatFunction: formatDateInput,
      maxLength: 8,
    },
  ]
  return MOCKED_PROPS
}
