import { Box, Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Accordion from 'src/components/Accordion'
import { HorizontalLearningItemCard } from 'src/components/HorizontalLearningItemCard'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { useKnowMoreDrawer } from 'src/context/CustomPlaylist/KnowMoreDrawerContext'
import Content from 'src/modules/cms/domain/Content'
import NewPlaylist, { Module } from 'src/modules/cms/domain/Playlist'
import { CertificateDto } from 'src/modules/iam/use-case/GetUserCertificatesUseCase'
import { ModuleCertificate } from './ModuleCertificate'
import { getModuleProgressPercentage, getModuleTitle } from './modules.services'
import { useUser } from 'src/context/userContext'

interface Props {
  playlist: NewPlaylist
}

const ModulesList: React.FC<Props> = ({ playlist }: Props) => {
  const [t] = useTranslation()
  const { user } = useUser()
  const { openModuleIndex, updateModuleOpen, updateContent, getCustomPlaylistModulesCertificates } = useCustomPlaylist()
  const { setDrawerInfo } = useKnowMoreDrawer()

  const [filteredModules, setFilteredModules] = useState<Module[]>([])
  const [modulesCertificates, setModulesCertificates] = useState<CertificateDto[] | undefined>(undefined)

  const handleKnowMoreClick = (module: Module, content: Content) => {
    const moduleTitle = getModuleTitle([module], user?.language)
    setDrawerInfo({ moduleTitle: moduleTitle, content, type: 'KNOW_MORE' })
  }

  useEffect(() => {
    if (playlist) {
      const filteredModules =
        playlist.moduleList?.items?.filter((module) => module?.contentList?.items?.length! > 0) ?? []
      setFilteredModules(filteredModules)
    }
  }, [playlist])

  useEffect(() => {
    if (playlist) {
      getCustomPlaylistModulesCertificates().then((certificates) => setModulesCertificates(certificates ?? []))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlist])

  return (
    <>
      {filteredModules?.map((module, index) => {
        // const recommendedLearningItemsIds = getRecommendedLearningItemsIds(module)
        const isModuleEmpty = !module.contentList?.items?.length
        // const isRecommendedLearningItemsEmpty = !recommendedLearningItemsIds.length

        if (isModuleEmpty) return null

        const title = getModuleTitle([module], user?.language)

        const moduleTitle = `${t('home.Custom Playlist.Module')} ${index + 1} - ${title}`
        const isModuleOpen = openModuleIndex === index
        const moduleProgressPercentage = getModuleProgressPercentage(module)

        const currentModuleCertificate = modulesCertificates?.find((cert) => cert.moduleId === module.id)

        return (
          <Accordion
            key={module.id}
            title={moduleTitle}
            rightText={`${moduleProgressPercentage}% ${t('home.Custom Playlist.completed')}`}
            isOpen={isModuleOpen}
            data-testid={`custom-playlist-module-${index}`}
            onOpenClick={() => updateModuleOpen(index)}
          >
            {!!currentModuleCertificate && (
              <Box mt='24px' w='100%'>
                <ModuleCertificate moduleTitle={moduleTitle} certificate={currentModuleCertificate} />
              </Box>
            )}
            
            <Flex marginTop='24px' gap='16px' flexDirection='column'>
              {module.contentList?.items?.map((item) => {
                const moduleTitle = getModuleTitle([module], user?.language)

                return (
                  <HorizontalLearningItemCard
                    key={`learning-${item.id}`}
                    content={item}
                    playlist={playlist}
                    showImage
                    showMarkButtons
                    showCustomPlaylistButtons
                    showProficiency={false}
                    moduleName={moduleTitle}
                    moduleId={module.id}
                    onUpdateLearningItem={updateContent}
                    onKnowMoreClick={(content) => handleKnowMoreClick(module, content)}
                  />
                )
              })}
            </Flex>
          </Accordion>
        )
      })}
    </>
  )
}

export default ModulesList
