import { useToast } from '@chakra-ui/react'
import { createContext, useCallback, useContext, useState } from 'react'
import { Company } from 'src/modules/cms/domain/Company'
import { makeUpdateCertificate } from 'src/modules/cms/factories/makeUpdateCertificate'
import { makeUploadCertificateCompanyLogo } from 'src/modules/cms/factories/makeUploadCertificateCompanyLogo'
import { makeUploadCertificateSignature } from 'src/modules/cms/factories/makeUploadCertificateSignature'

interface Signature {
  responsableName?: string
  signatureImage?: File | string
  shouldIncludeSignature: boolean
}

interface CompanyLogo {
  logoImage?: File | string
  shouldIncludeLogo: boolean
}

export interface CertificateTypes {
  customPlaylist: boolean
  customPlaylistModule: boolean
  featuredPlaylist: boolean
}

interface SaveCertificateChanges {
  signatureUrl?: string
  companyLogoUrl?: string
}

interface CertificateContextProps {
  signature: Signature
  companyLogo: CompanyLogo
  shouldIncludeTheseCertificateTypes: CertificateTypes
  activeTab: keyof CertificateTypes
  handleSignature: (props: Partial<Signature>) => void
  handleCompanyLogo: (props: Partial<CompanyLogo>) => void
  handleCertificateTypes: (type: keyof CertificateTypes) => void
  handleUpdateActiveTab: (value: keyof CertificateTypes) => void
  saveCertificateChanges: () => Promise<SaveCertificateChanges | undefined>
  setCertificateInitialValues: (company: Company | undefined) => void
}

const CertificateContext = createContext({} as CertificateContextProps)

export const CertificateContextProvider = ({ children }: { children: React.ReactNode }) => {
  /** Certificate types tab */
  const [activeTab, setActiveTab] = useState<keyof CertificateTypes>('customPlaylistModule')
  /** Certificate types */
  const [shouldIncludeTheseCertificateTypes, setShouldIncludeTheseCertificateTypes] = useState<CertificateTypes>({
    customPlaylist: false,
    customPlaylistModule: true,
    featuredPlaylist: false,
  })
  /** Images on certificate */
  const [companyLogo, setCompanyLogo] = useState<CompanyLogo>({ logoImage: undefined, shouldIncludeLogo: false })
  const [signature, setSignature] = useState<Signature>({
    responsableName: undefined,
    signatureImage: undefined,
    shouldIncludeSignature: false,
  })

  const toast = useToast()

  const handleSignature = (props: Partial<Signature>) => {
    setSignature((prev) => ({ ...prev, ...props }))
  }

  const handleCompanyLogo = (props: Partial<CompanyLogo>) => {
    setCompanyLogo((prev) => ({ ...prev, ...props }))
  }

  const handleCertificateTypes = (type: keyof CertificateTypes) => {
    setShouldIncludeTheseCertificateTypes((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }))
  }

  const setCertificateInitialValues = useCallback((company: Company | undefined) => {
    setShouldIncludeTheseCertificateTypes({
      customPlaylist: company?.customPlaylistCertificateEnabled ?? false,
      customPlaylistModule: company?.playlistModuleCertificateEnabled ?? false,
      featuredPlaylist: company?.featuredPlaylistCertificateEnabled ?? false,
    })

    setSignature({
      shouldIncludeSignature: !!company?.template.signatureUrl ?? false,
      responsableName: company?.template.signatureResponsible ?? undefined,
      signatureImage: company?.template.signatureUrl ?? undefined,
    })

    setCompanyLogo({
      shouldIncludeLogo: !!company?.template.logoUrl ?? false,
      logoImage: company?.template.logoUrl ?? undefined,
    })
  }, [])

  const handleUpdateActiveTab = useCallback((value: keyof CertificateTypes) => {
    setActiveTab(value)
  }, [])

  const uploadCertificateSignature = async () => {
    const uploadCertificateSignatureImage = makeUploadCertificateSignature()
    let signatureUrl: string | undefined = undefined

    if (
      signature?.shouldIncludeSignature &&
      signature?.signatureImage &&
      typeof signature?.signatureImage !== 'string' &&
      signature?.responsableName
    ) {
      try {
        const response = await uploadCertificateSignatureImage.execute(
          signature.signatureImage as File,
          signature.responsableName,
        )
        if (response) signatureUrl = response.signatureUrl
      } catch (err: any) {
        toast({
          title: 'Erro ao enviar a imagem da assinatura',
          description:
            'Ocorreu um erro ao tentar salvar as informações de assinatura do certificado. Por favor, tente novamente!',
          status: 'error',
          duration: 6000,
        })
        throw new Error()
      }
    }

    return signatureUrl
  }

  const uploadCertificateCompanyLogo = async () => {
    const uploadCertificateCompanyLogoImage = makeUploadCertificateCompanyLogo()
    let companyLogoUrl: string | undefined = undefined

    if (companyLogo.shouldIncludeLogo && companyLogo.logoImage && typeof companyLogo.logoImage !== 'string') {
      try {
        const response = await uploadCertificateCompanyLogoImage.execute(companyLogo.logoImage)
        if (response) companyLogoUrl = response.logoUrl
      } catch (err: any) {
        toast({
          title: 'Erro ao enviar a logo da empresa',
          description:
            'Ocorreu um erro ao tentar salvar as informações da logo da empresa. Por favor, tente novamente!',
          status: 'error',
          duration: 6000,
        })
        throw new Error()
      }
    }

    return companyLogoUrl
  }

  const updateCertificateConfig = async () => {
    const updateCertificate = makeUpdateCertificate()
    try {
      await updateCertificate.execute({
        customPlaylistCertificateEnabled: shouldIncludeTheseCertificateTypes.customPlaylist,
        featuredPlaylistCertificateEnabled: shouldIncludeTheseCertificateTypes.featuredPlaylist,
        playlistModuleCertificateEnabled: shouldIncludeTheseCertificateTypes.customPlaylistModule,
        shouldIncludeSignature: signature.shouldIncludeSignature,
        shouldIncludeLogo: companyLogo.shouldIncludeLogo,
      })
    } catch (err: any) {
      toast({
        title: 'Erro ao atualizar o certificado',
        description: 'Ocorreu um erro ao atualizar as informações do certificado. Por favor, tente novamente!',
        status: 'error',
        duration: 7000,
      })
      throw new Error()
    }
  }

  const saveCertificateChanges = async (): Promise<SaveCertificateChanges | undefined> => {
    await updateCertificateConfig()
    const signatureUrl = await uploadCertificateSignature()
    const companyLogoUrl = await uploadCertificateCompanyLogo()

    return {
      signatureUrl,
      companyLogoUrl,
    }
  }

  return (
    <CertificateContext.Provider
      value={{
        companyLogo,
        signature,
        shouldIncludeTheseCertificateTypes,
        activeTab,
        handleSignature,
        handleCompanyLogo,
        handleCertificateTypes,
        handleUpdateActiveTab,
        saveCertificateChanges,
        setCertificateInitialValues,
      }}
    >
      {children}
    </CertificateContext.Provider>
  )
}

export const useCertificate = () => {
  const context = useContext(CertificateContext)
  if (!context) throw new Error('useCertificate must be used within a CertificateContextProvider')
  return context
}
