import httpClient from 'src/services/http/http-client'
import { getAccessToken } from 'src/modules/iam/apis/store'
import { ReportRepository } from '../repository/ReportRepository'
import { GetUsersUseCase } from '../use-case/GetUsersUseCase'

export function makeGetUsers() {
  const token = getAccessToken()

  const repository = new ReportRepository(token, httpClient)
  return new GetUsersUseCase(repository)
}