import { Box, Button, Flex, Icon, Image, Text, Tooltip, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { IoPlayCircle } from 'react-icons/io5'
import { useHistory, useLocation } from 'react-router-dom'
import { useEvaluate } from 'src/context/EvaluateContext'
import { useUser } from 'src/context/userContext'
import { getContentData } from 'src/helpers/contentInfo'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import Content from 'src/modules/cms/domain/Content'
import { ContentType } from 'src/modules/cms/domain/LearningItem'
import { Playlist } from 'src/modules/cms/domain/Playlist'
import { makeGetContentUrl } from 'src/modules/cms/factories/makeGetContentUrl'
import { renderCorrectContentInfoByLanguage } from 'src/modules/internationalization/helpers/renderCorrectContentInfoByLanguage'
import { parseParametrizedURL } from '../../helpers/url'
import useRegisterAccess from '../../hooks/useRegisterAccess'
import EvaluateIcon from '../EvaluateIcon'

interface Props {
  content: Content
  playlist?: Playlist
}

export const RecentlyAccessedCourseCard: React.FC<Props> = ({ content }: Props) => {
  const register = useRegisterAccess()
  const { user } = useUser()
  const location = useLocation()
  const { t } = useTranslation()
  const toast = useToast()
  const { EventsImplementation, track } = useMixpanel()

  const history = useHistory()
  const { openEvaluation, updateCourseInfo } = useEvaluate()
  const contentData = getContentData(content.type, t)
  const isCourse = [ContentType.COURSE, 'OldCourse', 'CourseDto'].includes(content.type)
  const isVideo = content.type === ContentType.VIDEO || content.type === ContentType.VIDEODTO

  const handleEvaluateClick = () => {
    const courseInProgress = content?.progress !== 'DONE'

    openEvaluation({
      step: 1,
      source: courseInProgress && isCourse ? 'recentlyAccessedLearningItemInProgress' : 'recentlyAccessedLearningItem',
    })

    updateCourseInfo(content)
  }

  const handleClick = async () => {
    register(content.id)

    track(
      EventsImplementation.ACCESS_LEARNING_ITEM(
        'Recently Accessed',
        content?.favorited!,
        content.type,
        content.title,
        content.id,
        content?.partner?.name!,
        location.pathname,
        'Recently Accessed',
      ),
    )

    if (isVideo && content?.allowsEmbedding) {
      history.push(`/learningItem/${content.id}`)
    } else {
      const getUrl = makeGetContentUrl()
      const url = await getUrl.execute({ id: content.id })

      if (!url) {
        toast({
          title: t('errorWhileLoadingContent'),
          isClosable: true,
          duration: 5000,
          status: 'error',
        })

        return
      }

      window.open(parseParametrizedURL(url, user!), '_blank')
    }
  }

  if (!user) return null

  return (
    <Box onClick={handleClick} cursor='pointer'>
      <Box
        w='100%'
        minW={{ base: '280px', sm: '464px' }}
        maxW={{ base: 'calc(100vw - 64px)', sm: '464px' }}
        h='100%'
        minH='153px'
        maxH={{ base: 'unset', md: '153px' }}
        d='flex'
        flexDir={{ base: 'column', md: 'row' }}
        borderRadius='20px'
        overflow='hidden'
        boxSizing='border-box'
        border='2px solid #E5E5E5'
        transition='all 0.1s linear'
        _hover={{
          border: '2px solid #E5E5E5',
          boxShadow: '0 14px 40px rgba(0,0,0,0.05)',
        }}
        outlineOffset='5px'
      >
        <Box
          bgImage={content?.imageUrl ?? 'https://i.ibb.co/fS8fCDS/dummy-300x300-009136-ffffff-education-journey.png'}
          bgSize='cover'
          flex='1'
          minH={{ base: '100px', lg: 'unset' }}
          onClick={() => {
            track(
              EventsImplementation.ACCESS_LEARNING_ITEM(
                'Recently Accessed',
                content?.favorited!,
                content.type,
                content.title,
                content.id,
                content?.partner?.name!,
                location.pathname,
                'Recently Accessed',
              ),
            )
          }}
          bgPos={'center'}
          cursor='pointer'
        >
          {content.type === 'COURSE' ? (
            <Box
              backgroundImage={content.partner?.squareLogoUrl}
              backgroundPosition='center'
              backgroundSize='cover'
              w={42}
              h={42}
              borderRadius='15px'
              border='2px solid #E5E5E5'
              backgroundColor='white'
              margin='9px'
              overflow='hidden'
            >
              <Image src={content.partner?.squareLogoUrl} />
            </Box>
          ) : (
            <Box
              w={42}
              h={42}
              borderRadius='15px'
              border='2px solid #E5E5E5'
              backgroundColor='white'
              margin='9px'
              overflow='hidden'
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <Icon w='24px' h='24px' as={contentData?.icon} />
            </Box>
          )}
        </Box>

        <Box
          flex='2'
          paddingLeft={{ base: '8px', md: '20px' }}
          paddingY={{ base: '12px', md: '16px' }}
          d='flex'
          flexDir='column'
          alignItems='flex-start'
          justifyContent='center'
          backgroundColor='white'
        >
          <Box
            flex='1'
            onClick={() => {
              track(
                EventsImplementation.ACCESS_LEARNING_ITEM(
                  'Recently Accessed',
                  content?.favorited!,
                  content.type,
                  content.title,
                  content.id,
                  content?.partner?.name!,
                  location.pathname,
                  'Recently Accessed',
                ),
              )
            }}
          >
            {/* <Text color='gray.800' fontWeight='400' fontSize='14px' textTransform='capitalize'>
              {learningItem.tags ? learningItem.tags[0] : learnigItemData?.type}
            </Text> */}
          </Box>
          <Box flex='2'>
            <Tooltip
              hasArrow
              bg='gray.50'
              color='gray.800'
              p='16px 14px'
              placement='top'
              borderRadius='4px'
              boxShadow='0px 4px 6px rgba(0, 0, 0, 0.15);'
              label={content.title.length >= 56 && renderCorrectContentInfoByLanguage(content, 'title')}
            >
              <Text
                fontSize='16px'
                fontWeight='700'
                color='gray.800'
                marginRight='15px'
                marginBottom='10px'
                maxHeight='48px'
                overflow='hidden'
                css={{
                  '-webkit-line-clamp': '2',
                  display: '-webkit-box',
                  '-webkit-box-orient': 'vertical',
                }}
                textOverflow='ellipsis'
                textAlign={'left'}
              >
                {renderCorrectContentInfoByLanguage(content, 'title')}
              </Text>
            </Tooltip>
          </Box>
          <Flex flex='1' flexDir='row' flexWrap='nowrap'>
            <Button variant='lastCourseCard' marginRight='8px' padding={{ base: '0px 8px', md: '20px 18px 20px 8px' }}>
              <Icon w={{ base: '24px', md: '30px' }} h={{ base: '24px', md: '30px' }} color='white' as={IoPlayCircle} />
              <Text marginLeft='8px' fontSize={{ base: '.75rem', md: '1rem' }} color='white'>
                {contentData?.resumeButton}
              </Text>
            </Button>

            <EvaluateIcon onClick={handleEvaluateClick} />
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}
