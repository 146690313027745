import { Skeleton, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface CategoriesHeaderProps {
  name: string
  description?: string
  loadingState: boolean
}

const CategoriesHeader: React.FC<CategoriesHeaderProps> = ({ name, description, loadingState }) => {
  const { t } = useTranslation()

  if (loadingState) {
    return (
      <Flex>
        <Skeleton h='40px' w='500px' borderRadius={'20px'} />
      </Flex>
    )
  }

  return (
    <>
      <Text fontSize='32px' fontWeight={600} fontFamily='Poppins' marginBottom={description ? '9px' : '20px'}>
        {t('common.categoriesHeaderTitle', { contentName: name })}
      </Text>
      {description && (
        <Text fontSize='18px' marginBottom='33px'>
          {description}
        </Text>
      )}
    </>
  )
}

export default CategoriesHeader
