import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import React, { memo } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { CategoriesParams } from 'src/pages/Categories'
import { MenuPartnerLeft } from 'src/theme/Icons'

type Props = {
  index: number
  name: string
  id: string
  categorySlug: string
}

const SideFilterSubCategory: React.FC<Props> = ({ name, id, index, categorySlug }) => {
  const activeSlug = useParams<CategoriesParams>()
  const location = useLocation()
  const { track, EventsImplementation } = useMixpanel()
  const history = useHistory()

  return (
    <Flex
      position='relative'
      marginLeft='20px'
      onClick={() => {
        track(EventsImplementation.ACCESS_SUBCATEGORY(location.pathname, name, 'Page'))
        history.push(`/categories/${categorySlug}/${id}`)
      }}
    >
      <Box position='absolute' background='gray.70' w='2px' h={index !== 0 ? '38px' : '0px'} left='0' top='-33px' />
      <Icon as={MenuPartnerLeft} position='absolute' top='2px' />
      <Box
        h='40px'
        borderRadius='16px'
        _hover={{
          textDecoration: 'underline',
        }}
        d='flex'
        alignItems='center'
        justifyContent='space-between'
        cursor='pointer'
        paddingX='13px'
        paddingY='10px'
      >
        <Box d='flex' alignItems='center'>
          <Text
            textDecoration='none'
            textAlign='left'
            fontSize='16px'
            paddingBottom='12px'
            marginLeft='10px'
            fontWeight={activeSlug.subcategory === id ? '900' : 'inherit'}
          >
            {name}
          </Text>
        </Box>
      </Box>
    </Flex>
  )
}

export default memo(SideFilterSubCategory)
