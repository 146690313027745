import { Box, Flex, Stack } from '@chakra-ui/react'
import ExplorablePlaylistCard from 'src/components/ExplorablePlaylistCard'
import NewPlaylist from 'src/modules/cms/domain/Playlist'


interface Props {
  playlists: NewPlaylist[]
}

const AllSection: React.FC<Props> = ({ playlists }: Props) => {
  return (
    <Stack bg='white'>
      <Stack width='100%' padding={['20px', '20px', '40px', '40px', '40px']} margin='0 auto' spacing={12}>
        <Flex
          flexWrap={'wrap'}
          align={{ base: 'center', md: 'flex-start' }}
          justify={{ base: 'center', md: 'flex-start' }}
        >
          {playlists.map((playlist) => {
            return (
              <Box key={playlist.id} marginRight='12px' marginBottom='20px'>
                <ExplorablePlaylistCard
                  playlist={playlist}
                  // recommended={playlist.recommended}
                  isPersonalized={playlist.type === 'CUSTOM'}
                />
              </Box>
            )
          })}
        </Flex>
      </Stack>
    </Stack>
  )
}

export default AllSection
