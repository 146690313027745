import { Flex, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { DownloadButton } from './DownloadButton'
import { EvaluatePlaylist } from './EvaluatePlaylist'
import { HeaderTitle } from './HeaderTitle'
import { Logo } from './Logo'
import { ProgressBar } from './ProgressBar'
import { ShareButton } from './ShareButton'
import { SkeletonLoading } from './SkeletonLoading'

export function CertificateSection() {
  const { getCustomPlaylistCertificate, playlist, certificate, isLoadingCertificate, isPlaylistCompleted } =
    useCustomPlaylist()
  const { t } = useTranslation()

  useEffect(() => {
    if (playlist) {
      getCustomPlaylistCertificate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlist])

  if (isLoadingCertificate) return <SkeletonLoading />
  if (!isPlaylistCompleted) return <></>

  return (
    <Flex
      marginX={{ base: '32px', lg: '72px' }}
      border='2px solid #E5E5E5'
      borderRadius='40px'
      padding={{ base: '8px 16px', lg: '22px 37px' }}
      gap={{ base: '16px' }}
      direction={{ base: 'column', lg: 'row' }}
      alignItems={{ base: 'center', lg: 'flex-start' }}
      justifyContent={{ base: 'center', lg: 'flex-start' }}
      w='100%'
    >
      <Logo />
      <Flex flexDir='column' flex={{ base: 'unset', lg: '1' }} width={{ base: '100%' }}>
        <HeaderTitle />
        <ProgressBar />

        {isPlaylistCompleted && !certificate ? (
          <Text opacity='0.7' lineHeight='20px' marginBottom='22px'>
            {t('playlist.We are checking the details')}
          </Text>
        ) : null}

        <Flex
          direction={{ base: 'column', lg: 'row' }}
          alignItems={{ base: 'center', lg: 'unset' }}
          justifyContent={{ base: 'center', lg: 'unset' }}
          width={{ base: '100%', lg: 'auto' }}
          gap={{ base: '16px', lg: '0px' }}
          marginTop={{ base: '8px', lg: '0px' }}
          overflow='hidden'
          position='relative'
        >
          <DownloadButton />
          <ShareButton />
          <EvaluatePlaylist />
        </Flex>
      </Flex>
    </Flex>
  )
}
