import { Stack } from '@chakra-ui/react'
import { AdminHomeAccessProvider, useHomeAccess } from 'src/context-admin/Home/AdminHomeAccessContext'
import TeamDrawer from '../../../../components-admin/TeamDrawer'
import UserPermissionsDrawer from '../../../../components-admin/UserPermissionsDrawer'
import { usePermissions } from '../../../../context-admin/usePermissions'
import { useTeams } from '../../../../context-admin/useTeams'
import EmployeeSection from '../EmployeesSection'
import PaginationFooter from '../PaginationFooter'
import HomeSkeleton from '../Skeleton'
import RemoveAccessDrawer from './components/RemoveAccessDrawer'
import UpdateEmployeesSection from './components/UpdateEmployeesSection'

const Access = () => {
  const { togglePermissionsDrawer, isRemoveAccessModalOpen } = usePermissions()
  const { toggleTeamsDrawer } = useTeams()
  const { listUsers, loading } = useHomeAccess()

  if (loading || !listUsers) return <HomeSkeleton />

  return (
    <>
      <Stack spacing={12} mt='40px !important'>
        <UpdateEmployeesSection />
        <EmployeeSection listUsers={listUsers} />
      </Stack>

      {listUsers?.paginateInfo.total && <PaginationFooter />}

      {togglePermissionsDrawer ? <UserPermissionsDrawer /> : null}
      {toggleTeamsDrawer ? <TeamDrawer /> : null}
      {isRemoveAccessModalOpen ? <RemoveAccessDrawer /> : null}
    </>
  )
}

const AccessWithContext = () => {
  return (
    <AdminHomeAccessProvider>
      <Access />
    </AdminHomeAccessProvider>
  )
}

export default AccessWithContext
