import { Icon, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { IoCloseSharp } from 'react-icons/io5'
import { useEvaluate } from 'src/context/EvaluateContext'
import { CourseStep, EvaluateSent, PlaylistCover, PlaylistStep } from './sub-components'

interface Props {
  onClose: () => void
  isOpen: boolean
}

const EvaluateModal: React.FC<Props> = ({ onClose, isOpen }: Props) => {
  const { evaluateState } = useEvaluate()

  const steps = [<PlaylistCover />, <CourseStep />, <PlaylistStep />, <EvaluateSent />]

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} autoFocus={false} scrollBehavior='outside' isCentered>
        <ModalOverlay>
          <ModalContent
            borderRadius='30px'
            maxW='590px'
            minH='550px'
            position='relative'
            padding='40px 20px 0 20px'
            overflow='visible'
          >
            <Icon
              w='20px'
              h='20x'
              position='absolute'
              top='25px'
              right='25px'
              color='bluishGray.300'
              cursor='pointer'
              as={IoCloseSharp}
              onClick={onClose}
            />
            {steps[evaluateState.step]}
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  )
}

export default EvaluateModal
