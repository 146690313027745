import { Flex, Icon } from '@chakra-ui/react'
import { CertificateIcon } from 'src/theme/Icons'

export function Logo() {
  return (
    <Flex
      w='112px'
      h='112px'
      border='1px solid #ABABAB'
      borderRadius='100px'
      alignItems='center'
      justifyContent='center'
    >
      <Icon as={CertificateIcon} w='54px' h='51px' color='primaryColors.400' />
    </Flex>
  )
}
