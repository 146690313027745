export const ERRORS_CODE = {
  NETWORK_ERROR: 'NETWORK_ERROR',
}

class ApiError extends Error {
  code: string
  message: string
  constructor(message: string, code: string) {
    super(message)
    this.code = code
    this.message = message
  }
}

export class NetWorkError extends ApiError {
  constructor() {
    super('network connection error', ERRORS_CODE.NETWORK_ERROR)
  }
}

export default ApiError
