import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FormikProps } from 'formik'
import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from 'src/context/userContext'
import { Team } from 'src/modules/dashboard/entities/Team'
import { useCorporateUniversityStore } from '../../hooks/useCorporateUniversityStore'
import { useRegisterNewContent } from '../../hooks/useRegisterNewContent'
import { SelectCompaniesDrawer } from '../SelectCompaniesDrawer'
import { SelectSpecificUsersPlaylistRestriction } from '../SelectSpecificUsersPlaylistRestriction'

interface PermissionsAccordionProps {
  form: FormikProps<any>
}

export function PermissionsAccordion({ form }: PermissionsAccordionProps) {
  const [selectedTeams, setSelectedTeams] = useState<Team[]>([])
  const [t] = useTranslation()
  const { onOpen, onClose, isOpen } = useDisclosure()
  const {
    onOpen: onOpenSpecificUsersModal,
    onClose: onCloseSpecificUsersModal,
    isOpen: isSpecificUsersModalOpen,
  } = useDisclosure()

  const { selectedPlaylist, selectedContent, playlistPermissions, updatePlaylistPermissions } =
    useCorporateUniversityStore()

  const { user } = useUser()
  const isUserBackoffice = user?.roles?.includes('BACKOFFICE')
  const userCompanyId = user?.company?.id ?? user?.companyId

  const {
    updateSelectedCompanies,
    updateSavedSelectedCompanies,
    selectedPermission,
    updateSelectedPermission,
    updateHasPermissionsBeenClicked,
    selectedTeamsToRestrict,
    updateSelectedTeamsToRestrict,
  } = useRegisterNewContent()

  const totalMembersOfTeamsToRestrict = selectedTeamsToRestrict?.reduce(
    (acc, curr) => acc + (curr?.members?.length ?? 0),
    0,
  )

  const handlePermissionsChange = (value: string) => {
    updateSelectedPermission(value)

    if (value === 'specificCompanies') {
      onOpen()
    }

    if (value === 'allUsers') {
      updateSelectedCompanies([])
      updateSavedSelectedCompanies([])
      form.setFieldValue('allowedCompanyIds', [-1])
    }

    if (value === 'specificUsers') {
      onOpenSpecificUsersModal()
    }
  }

  const handleEditPermissions = () => {
    updateHasPermissionsBeenClicked(true)
    onOpen()
  }

  const handleFillPermissionsWithPreviousValues = useCallback(() => {
    if (selectedPlaylist) {
      if (playlistPermissions && playlistPermissions.length !== 0) {
        updateSelectedPermission('specificUsers')
        return
      }
      const companiesIds = selectedPlaylist?.allowedCompanyIds ?? [-1]
      form.setFieldValue('allowedCompanyIds', companiesIds ?? [-1])
      updateSelectedPermission(companiesIds[0] === -1 ? 'allUsers' : 'specificCompanies')
    }

    if (selectedContent) {
      const companiesIds = selectedContent?.allowedCompanyIds ?? [-1]
      form.setFieldValue('allowedCompanyIds', companiesIds ?? [-1])
      updateSelectedPermission(companiesIds[0] === -1 ? 'allUsers' : 'specificCompanies')
    }
  }, [selectedPlaylist, selectedContent, updateSelectedPermission, form, playlistPermissions])

  const handleResetRestrictedPermissions = () => {
    setSelectedTeams([])
    updateSelectedPermission('')
    updateSelectedTeamsToRestrict([])
    updatePlaylistPermissions([])
  }

  useEffect(() => {
    handleFillPermissionsWithPreviousValues()
    if (!isUserBackoffice && playlistPermissions.length === 0) {
      form.setFieldValue('allowedCompanyIds', [userCompanyId])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (playlistPermissions.length !== 0 && selectedPermission === 'specificUsers') {
      const teamsToRestrict: Team[] = playlistPermissions?.map((item) => ({ id: item.team!, name: '', members: [] }))
      updateSelectedTeamsToRestrict(_.uniqBy(teamsToRestrict, 'id'))
      updateSelectedPermission('specificUsers')
      return
    }
    updateSelectedTeamsToRestrict([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPermission])

  return (
    <>
      {isOpen && <SelectCompaniesDrawer isOpen={isOpen} onClose={onClose} form={form} />}
      {isSpecificUsersModalOpen && (
        <SelectSpecificUsersPlaylistRestriction
          isOpen={isSpecificUsersModalOpen}
          onClose={onCloseSpecificUsersModal}
          form={form}
          selectedTeams={selectedTeams}
          setSelectedTeams={setSelectedTeams}
        />
      )}
      <AccordionItem border='none' borderBottom='1px solid #767F89'>
        <AccordionButton p='32px 0' _focus={{ boxShadow: 'none' }} _hover={{ background: 'white' }}>
          <Flex w='100%' align='center' justify='space-between' pr='8px'>
            <Text color='#313E4C' fontSize='1.25rem' fontWeight={600} fontFamily='Poppins'>
              {t('contentPermission')}
            </Text>
          </Flex>
          <AccordionIcon w='1.2em' h='1.2em' color='#161616' />
        </AccordionButton>

        <AccordionPanel p='0 0 16px 0'>
          <Flex direction='column' gap='24px'>
            {isUserBackoffice && selectedTeamsToRestrict?.length <= 0 && (
              <>
                <Flex
                  direction='column'
                  gap='4px'
                  bgColor='#EAF2F5'
                  p='24px'
                  borderRadius='16px'
                  border='2px solid'
                  borderColor={selectedPermission === 'allUsers' ? '#009639' : 'transparent'}
                  cursor='pointer'
                  onClick={() => handlePermissionsChange('allUsers')}
                  transition='all 200ms ease'
                >
                  <Text
                    fontSize='1.125rem'
                    color={selectedPermission === 'allUsers' ? '#009639' : 'gray.800'}
                    fontWeight={700}
                  >
                    {t('allUsersFromEj')}
                  </Text>
                  <Text fontSize='1.125rem'>{t('theContentWillBeAvailableForEveryone')}</Text>
                </Flex>
                <Flex
                  direction='column'
                  gap='4px'
                  bgColor='#EAF2F5'
                  p='24px'
                  borderRadius='16px'
                  border='2px solid'
                  borderColor={selectedPermission === 'specificCompanies' ? '#009639' : 'transparent'}
                  cursor='pointer'
                  onClick={() => handlePermissionsChange('specificCompanies')}
                  transition='all 200ms ease'
                >
                  <Text
                    fontSize='1.125rem'
                    color={selectedPermission === 'specificCompanies' ? '#009639' : 'gray.800'}
                    fontWeight={700}
                  >
                    {t('allUsersFromSpecificCompany')}
                  </Text>
                  <Text fontSize='1.125rem'>{t('theContentWillBeAvailableForSpecificUsers')}</Text>
                  {playlistPermissions?.length === 0 &&
                    form?.values?.allowedCompanyIds !== undefined &&
                    form?.values?.allowedCompanyIds?.length !== 0 &&
                    form?.values?.allowedCompanyIds?.[0] !== -1 ? (
                    <Button
                      variant='unstyled'
                      w='186px'
                      bg='gray.800'
                      color='white'
                      fontSize='.75rem'
                      padding='6px 12px'
                      mt='16px'
                      onClick={handleEditPermissions}
                    >
                      {t('changePermission')}
                    </Button>
                  ) : null}
                </Flex>
              </>
            )}
            <Flex>
              {(selectedTeamsToRestrict?.length > 0 || playlistPermissions.length !== 0) &&
                selectedPermission === 'specificUsers' ? (
                <Flex w='100%' flexDir='column' bg='#F7F9FA' gap='16px' p='24px' borderRadius='16px'>
                  <Flex gap='5px'>
                    <Text fontWeight={700} fontSize='1.125rem' color='gray.800'>
                      {t('thisPlaylistWillBeAvailableFor')}{' '}
                      {totalMembersOfTeamsToRestrict > 0 ? (
                        <>
                          <strong style={{ color: '#009639', textTransform: 'lowercase' }}>
                            {totalMembersOfTeamsToRestrict} {t('users')}
                          </strong>{' '}
                          {t('dividedBetween')}{' '}
                        </>
                      ) : null}
                      <strong style={{ color: '#009639', textTransform: 'lowercase' }}>
                        {/* ALTERAR */}
                        {selectedTeamsToRestrict?.length} {t('teams')}
                      </strong>{' '}
                      {/* {t('forCompanies')} Education Journey e Ambev  */}
                    </Text>
                  </Flex>
                  <Flex gap='16px'>
                    <Button variant='startCourseDark' onClick={onOpenSpecificUsersModal}>
                      {t('changeSelection')}
                    </Button>
                    <Button
                      variant='outline'
                      height='48px'
                      outline='0px'
                      border='2px solid #181814'
                      onClick={handleResetRestrictedPermissions}
                    >
                      {t('resetPermissionSettings')}
                    </Button>
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  w='100%'
                  direction='column'
                  gap='4px'
                  bgColor='#EAF2F5'
                  p='24px'
                  borderRadius='16px'
                  border='2px solid'
                  borderColor={selectedPermission === 'specificUsers' ? '#009639' : 'transparent'}
                  cursor='pointer'
                  onClick={() => handlePermissionsChange('specificUsers')}
                  transition='all 200ms ease'
                >
                  <Text
                    fontSize='1.125rem'
                    color={selectedPermission === 'specificUsers' ? '#009639' : 'gray.800'}
                    fontWeight={700}
                  >
                    {t('specificUsers')}
                  </Text>
                  <Text fontSize='1.125rem'>{t('thePlaylistCanBeAccessedByUsersWhoMeetYourCriteria')}</Text>
                  {selectedTeamsToRestrict?.length > 0 ? (
                    <Button
                      variant='unstyled'
                      w='186px'
                      bg='gray.800'
                      color='white'
                      fontSize='.75rem'
                      padding='6px 12px'
                      mt='16px'
                      onClick={handleEditPermissions}
                    >
                      {t('changePermission')}
                    </Button>
                  ) : null}
                </Flex>
              )}
            </Flex>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </>
  )
}
