import { useQuery } from '@apollo/client'
import { Box, useToast } from '@chakra-ui/react'
import { createContext, useContext, useState } from 'react'
import { OptionTypeBase } from 'react-select'
import { GET_COMPANIES } from 'src/graphql/queries/GET_COMPANIES'
import { makeUpdateUserInformation } from 'src/modules/iam/factory/makeUpdateUserInformation'
import { useCorporateUniversityStore } from 'src/pages-admin/Cms/hooks/useCorporateUniversityStore'

type Props = {
  children: React.ReactNode[] | React.ReactNode
}

export type UserToUpdate = { email: string; area: string; subsidiary: string }

interface Value {
  selectedCompany: OptionTypeBase
  handleSelectCompany: (item: OptionTypeBase) => void

  usersToUpdate: UserToUpdate[]
  handleUpdateUsers: (items: UserToUpdate[]) => void

  isLoading: boolean
  submit: (e: React.FormEvent<HTMLFormElement>) => void
}

const ChangeCompanyAreaContext = createContext({} as Value)

export function ChangeCompanyAreaProvider({ children }: Props) {
  const toast = useToast()
  const { updateCompanyList, companyList } = useCorporateUniversityStore()
  const [selectedCompany, setSelectedCompany] = useState({} as OptionTypeBase)
  const [usersToUpdate, setUsersToUpdate] = useState<UserToUpdate[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const handleSelectCompany = (item: OptionTypeBase) => {
    if (item) {
      setSelectedCompany(item)
    }
  }

  const handleUpdateUsers = (items: UserToUpdate[]) => {
    setUsersToUpdate(items)
  }

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const updateUserInformation = makeUpdateUserInformation()

    try {
      setIsLoading(true)
      const response = await updateUserInformation.execute({
        companyId: Number(selectedCompany.value),
        usersToUpdate,
      })

      if (response?.errors && response?.errors?.length > 0 && response?.errors?.[0]?.code === 'LIMIT_EXCEED') {
        toast({
          title: 'O limite de áreas na planilha foi excedido.',
          description: 'O limite máximo são de 100 áreas por vez.',
          isClosable: true,
          duration: 7000,
          status: 'error',
        })
        return
      }

      if (response?.errors && response?.errors?.length !== 0) {
        toast({
          title: 'Ocorreu um erro ao trocar a área dos usuários:',
          description: (
            <Box>
              {response.errors.map((item, index) => (
                <Box key={index}>
                  <strong>{item.email}</strong> - {item.reason}
                </Box>
              ))}
            </Box>
          ),
          isClosable: true,
          duration: null,
          status: 'error',
        })
        return
      }

      toast({
        title: 'Áreas atualizadas com sucesso!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useQuery(GET_COMPANIES, {
    variables: { limit: 100 },
    skip: companyList.length !== 0,
    onCompleted: (response) => {
      updateCompanyList(response.listCompanies.data)
    },
  })

  return (
    <ChangeCompanyAreaContext.Provider
      value={{ selectedCompany, handleSelectCompany, usersToUpdate, handleUpdateUsers, isLoading, submit }}
    >
      {children}
    </ChangeCompanyAreaContext.Provider>
  )
}

export const useChangeCompanyArea = () => useContext(ChangeCompanyAreaContext)
