import { Flex, Text } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Drawer from 'src/components/Drawer'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import DeletePlaylistConfirmation from './DeletePlaylistConfirmation'
import EditSkills from './EditSkills'
import SkillsConfirmation from './SkillsConfirmation'
import SkillsDescriptions from './SkillsDescriptions'
import SuccessfulMessage from './SuccessfulMessage'

const EditSkillsDrawer = () => {
  const [t] = useTranslation()
  const { showSkillsDrawer, setShowSkillsDrawer, playlist } = useCustomPlaylist()
  const { resetState, setSkillsToAddOnEdit, setSkillsToRemoveOnEdit } = useCustomPlaylistQuiz()
  const [currentStep, setCurrentStep] = useState(0)

  useEffect(() => {
    if (showSkillsDrawer && playlist && playlist?.id) {
      setSkillsToAddOnEdit([])
      setSkillsToRemoveOnEdit([])
    }
  }, [showSkillsDrawer, playlist, setSkillsToAddOnEdit, setSkillsToRemoveOnEdit])

  const handleStepChange = useCallback((number: number) => {
    setCurrentStep(number)
  }, [])

  const handleDrawerClose = () => {
    resetState()
    setCurrentStep(0)
    setShowSkillsDrawer(false)
  }

  const steps = [
    <EditSkills handleStepChange={handleStepChange} />,
    <SkillsDescriptions handleStepChange={handleStepChange} />,
    <SkillsConfirmation handleStepChange={handleStepChange} />,
    <DeletePlaylistConfirmation handleStepChange={handleStepChange} />,
    <SuccessfulMessage close={handleDrawerClose} />,
  ]

  if (!showSkillsDrawer) return null

  return (
    <Drawer onOutsideClick={handleDrawerClose}>
      {currentStep !== 4 && (
        <Text
          color='bluishGray.500'
          textDecorationLine='underline'
          fontFamily='Poppins'
          fontWeight='600'
          marginBottom='22px'
          cursor='pointer'
          onClick={handleDrawerClose}
        >
          {t('common.drawer.back')}
        </Text>
      )}

      <Flex direction='column' height='100%'>
        {steps[currentStep]}
      </Flex>
    </Drawer>
  )
}

export default EditSkillsDrawer
