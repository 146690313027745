import { Box, Button, Flex, Image, Text, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { DeleteCustomPlaylistModal } from 'src/components/DeleteCustomPlaylistModal'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { useUser } from 'src/context/userContext'

const StepZero = () => {
  const history = useHistory()
  const { handleNextStep } = useCustomPlaylistQuiz()
  const { user } = useUser()
  const [t] = useTranslation()
  const { onOpen, onClose, isOpen } = useDisclosure()

  const shouldUserContinueToNextStep = user?.hasCustomPlaylistAssigned && !user?.hasCompletedCustomPlaylistQuiz
  const steps = [
    t('customPlaylistQuiz.stepZero.info.first'),
    t('customPlaylistQuiz.stepZero.info.second'),
    t('customPlaylistQuiz.stepZero.info.third'),
  ]

  const handleRedirectToHome = () => {
    history.push('/home')
  }

  return (
    <>
      <Box display={{ base: 'none', lg: 'block' }} position='absolute' left='72px' top='20px'>
        <Text
          color='bluishGray.500'
          textDecorationLine='underline'
          fontFamily='Poppins'
          fontWeight='600'
          cursor='pointer'
          onClick={handleRedirectToHome}
        >
          {t('common.drawer.close')}
        </Text>
      </Box>

      <Flex h='100%' align='center' justify='center' gap='56px' flexDir={{ base: 'column', md: 'row' }}>
        <Flex direction='column' maxW='600px'>
          <Text fontFamily='Poppins' fontWeight={700} fontSize='32px' color='#009639' mb='8px'>
            {t('customPlaylistQuiz.stepZero.hello')}
          </Text>
          <Text fontWeight={700} fontSize='32px' mb='16px' maxW='466px'>
            {t('customPlaylistQuiz.stepZero.personalizedStudyPlan')}
          </Text>

          {steps.map((item, index) => (
            <Flex mb='24px' gap='4px' key={`${item}-${index}`}>
              <Text fontFamily='Poppins' fontWeight={600} fontSize='20px' color='#009639'>
                {index + 1}.
              </Text>
              <Text fontFamily='Poppins' fontWeight={400} fontSize='20px'>
                {item}
              </Text>
            </Flex>
          ))}

          <Button
            variant='startCourseDark'
            w='100%'
            maxW={{ base: '96px', md: '221px' }}
            padding={0}
            height={{ base: '40px', md: '48px' }}
            mt='8px'
            onClick={handleNextStep}
            isDisabled={!shouldUserContinueToNextStep}
            disabled={!shouldUserContinueToNextStep}
            fontSize={{ base: '0.875rem', md: '1.125rem' }}
            data-testid='start-custom-playlist-quiz'
          >
            {t('customPlaylistQuiz.stepZero.start')}
          </Button>
          {!shouldUserContinueToNextStep && (
            <Flex flexDir='column' mt='16px'>
              <Text mt='4px' fontSize='.75rem'>
                {t('youAlreadyHaveCustomPlaylist')}.
              </Text>
            </Flex>
          )}
          {!shouldUserContinueToNextStep && (
            <Flex>
              <Text mt='4px' fontSize='.75rem' display='flex' gap='4px'>
                {t('youCanDeleteIt')}{' '}
                <DeleteCustomPlaylistModal
                  withTrigger
                  isOpen={isOpen}
                  onClose={onClose}
                  onOpen={onOpen}
                  label={t('byClickingHere')}
                />
              </Text>
            </Flex>
          )}
        </Flex>
        <Flex>
          <Image src='/assets/images/custom-playlist-cover.svg' />
        </Flex>
      </Flex>
    </>
  )
}

export default StepZero
