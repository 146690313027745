import { Button } from '@chakra-ui/react'
import { useImportedContents } from 'src/pages-admin/Cms/hooks/useImportedContents'
import { useImportedContentsForm } from '../../../../hooks/useImportedContentsForm'

export function PublishImportedContentsButton() {
  const { loadingSaveImportedContents } = useImportedContents()
  const { hookForm, onSubmit } = useImportedContentsForm()

  return (
    <Button
      variant='startCourseDark'
      display='flex'
      alignItems='center'
      gap='8px'
      h='48px'
      isLoading={loadingSaveImportedContents}
      onClick={() => hookForm.handleSubmit((data, event) => onSubmit(data, event, 'publish'))()}
    >
      Publicar
    </Button>
  )
}
