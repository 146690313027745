import { Box, Flex, Text } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { TryRequestAgain } from 'src/components/TryRequestAgain'
import { useUser } from 'src/context/userContext'
import { EventsImplementation } from 'src/hooks/Mixpanel/types'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { usePlaylistStore } from 'src/hooks/usePlaylist'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { usePlaylists } from 'src/modules/cms/hooks/usePlaylists'
import { makeGetUserCertificates } from 'src/modules/iam/factory/makeGetUserCertificates'
import { CertificateDto } from 'src/modules/iam/use-case/GetUserCertificatesUseCase'
import Footer from '../../components/Footer'
import ScrollToTop from '../../hooks/useScrollToTop'
import BasicInfoSection from '../../modules/cms/components/FeaturedPlaylist/BasicInfoSection'
import CertificateSection from '../../modules/cms/components/FeaturedPlaylist/CertificateSection'
import DescriptionSection from '../../modules/cms/components/FeaturedPlaylist/DescriptionSection'
import InstructorsSection from '../../modules/cms/components/FeaturedPlaylist/InstructorsSection'
import LearningItemsSection from '../../modules/cms/components/FeaturedPlaylist/LearningItemsSection'
import LearningPointsSection from '../../modules/cms/components/FeaturedPlaylist/LearningPointsSection'
import LinkedinCertificateSection from '../../modules/cms/components/FeaturedPlaylist/LinkedinCertificateSection'
import PartnersSection from '../../modules/cms/components/FeaturedPlaylist/PartnersSection'
import PlaylistSkeleton from '../../modules/cms/components/FeaturedPlaylist/Skeleton'

const PlaylistPage: React.FC = () => {
  const [t] = useTranslation()
  const { track } = useMixpanel()
  const { user } = useUser()
  const { updateCertificate, certificate } = usePlaylistStore()
  const { id } = useParams<{ id: string }>()
  const { getPlaylistById, formatPlaylist } = usePlaylists()
  const getCertificates = makeGetUserCertificates()
  const [playlist, setPlaylist] = useState<NewPlaylist | undefined>(undefined)
  const [tabIndex, setTabIndex] = useState(0)
  const [loadingPlaylists, setLoadingPlaylists] = useState(false)

  const getPlaylist = useCallback(async () => {
    if (user) {
      setLoadingPlaylists(true)
      try {
        const [data, userCertificates] = await Promise.all([
          getPlaylistById(id, user),
          getCertificates.execute({ playlistId: id }),
        ])
        if (data) setPlaylist(formatPlaylist(data, user))
        updateCertificate(
          userCertificates?.items?.find((item) => item?.playlistId === data?.id) ?? ({} as CertificateDto),
        )
      } catch {
        console.error('Ocorreu um erro ao obter os dados da trilha')
      } finally {
        setLoadingPlaylists(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, id])

  useEffect(() => {
    if (user) {
      getPlaylist()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id])

  if (loadingPlaylists || playlist === undefined) return <PlaylistSkeleton />

  const noInstructorsAvailable =
    playlist?.moduleList?.items
      ?.flatMap((module) => module.contentList?.items?.flatMap((item) => item))
      .filter((item) => item?.type === 'COURSE')
      .every((content) => content?.instructors?.length === 0) ?? false

  const tabs = [t('playlist.descriptionSection.content'), t('playlist.descriptionSection.instructors')]
  const tabContent = [
    <LearningItemsSection playlist={playlist} onUpdatePlaylist={setPlaylist} />,
    <InstructorsSection playlist={playlist} />,
  ]

  if (noInstructorsAvailable) {
    tabs.pop()
    tabContent.pop()
  }

  return (
    <ScrollToTop>
      <Flex flexDirection='column' w='100%' minH='calc(100vh - 95px)' h='100%'>
        <Helmet>
          <title>{t('customPlaylist')} | Education Journey</title>
        </Helmet>
        {!loadingPlaylists && !playlist && (
          <Flex h='100%'>
            <TryRequestAgain
              errorMessage={t('tryRequestAgain.playlist')}
              functionToExecute={getPlaylist}
              imageHeight={300}
            />
          </Flex>
        )}
        {!loadingPlaylists && (
          <>
            <DescriptionSection playlist={playlist} />
            <BasicInfoSection playlist={playlist} />

            {playlist.learningPointList?.total ? <LearningPointsSection playlist={playlist} /> : null}

            <Flex margin={{ lg: '29px 72px 0px' }} borderBottom='1px solid #313E4C' position='relative'>
              {tabs?.map((tab, index) => (
                <Box
                  onClick={() => {
                    track(EventsImplementation.MODAL_ACCESS_TAB(tabIndex.toString(), index.toString()))
                    setTabIndex(index)
                  }}
                  cursor='pointer'
                  borderBottom={index === tabIndex ? '2px solid #009639' : ''}
                  bottom='-1px'
                  width='fit-content'
                  pointerEvents={noInstructorsAvailable ? 'none' : 'visible'}
                >
                  <Text
                    color={index === tabIndex ? 'black' : 'gray.260'}
                    fontWeight={index === tabIndex ? '700' : ''}
                    padding='8px'
                  >
                    {tab}
                  </Text>
                </Box>
              ))}
            </Flex>

            {tabContent[tabIndex]}

            {certificate.url ? <CertificateSection playlist={playlist} /> : null}
            {/* <RecommendedCourseSection playlist={playlist} contents={playlist.extraContent} /> */}
            <PartnersSection playlist={playlist} />
            {certificate.url ? <LinkedinCertificateSection playlist={playlist} /> : null}
          </>
        )}
        <Footer />
      </Flex>
    </ScrollToTop>
  )
}

export default PlaylistPage
