import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { CREATE_PLAYLIST } from 'src/modules/cms/graphql/mutations/CREATE_PLAYLIST'
import { DELETE_PLAYLIST } from 'src/modules/cms/graphql/mutations/DELETE_PLAYLIST'
import { LOG_PLAYLIST_ACCESS } from 'src/modules/cms/graphql/mutations/LOG_PLAYLIST_ACCESS'
import { UPDATE_PLAYLIST } from 'src/modules/cms/graphql/mutations/UPDATE_PLAYLIST'
import { GET_PLAYLIST } from 'src/modules/cms/graphql/queries/GET_PLAYLIST'
import { apolloClient, cmsQueryContext } from 'src/services/apollo/ApolloClient'
import { cmsClient } from 'src/services/http/cms-client'
import { IHttpClient } from 'src/services/http/http-client'
import ApiError, { NetWorkError } from 'src/shared/ApiError'
import { logger } from 'src/shared/Logger'
import { CmsPlaylistQuery, PlaylistList } from 'src/types/Cms'
import { ICreatePlaylist } from '../../../pages-admin/Cms/types/IPlaylist'
import { UPDATE_PLAYLIST_MODULE_CONTENT } from '../graphql/mutations/UPDATE_PLAYLIST_MODULE_CONTENT'
import { GET_PLAYLISTS } from '../graphql/queries/GET_PLAYLISTS'
import { ISurvey } from '../types/ISurvey'
import { PlaylistPermission } from '../use_cases/ListPlaylistsPermissionsUseCase'
import { SavePlaylistPermissionsUseCaseInput } from '../use_cases/SavePlaylistPermissionsUseCase'

interface IPlaylistRepository {
  list(params: any): Promise<PlaylistList>
  getById(id: string): Promise<NewPlaylist>
  delete(playlistId: string): Promise<boolean>
  update(entity: ICreatePlaylist): Promise<boolean>
  create(playlist: ICreatePlaylist): Promise<string>
  logAccess(playlistId: string): Promise<boolean>
  updatePlaylistModuleContent(moduleId: string, oldContentId: string, newContentId: string): Promise<boolean>
  getPrivatePlaylists(params: any): Promise<PlaylistList>
  listPlaylistPermissions(params: { playlistId: string; limit?: number }): Promise<PlaylistPermission[]>
  savePlaylistPermissions(input: SavePlaylistPermissionsUseCaseInput): Promise<boolean>
  getPlaylistSurvey(surveyCode: string): Promise<ISurvey[]>
  savePlaylistSurvey(surveyId: number, optionId: number): Promise<boolean>
}

export class PlaylistRepository implements IPlaylistRepository {
  constructor(private httpClient?: IHttpClient, private token?: string) {}

  async list(params: any) {
    const promise = apolloClient
      .query<CmsPlaylistQuery>({
        query: GET_PLAYLISTS,
        context: cmsQueryContext,
        variables: {
          limit: params.limit,
          skip: params.skip,
          order: params.order,
          ...(params.ids ? { id: params.ids } : {}),
          ...(params.moduleIds ? { moduleIds: params.moduleIds } : {}),
          ...(params.companies ? { companies: params.companies } : {}),
          ...(params.type ? { type: params.type } : {}),
        },
      })
      .then(async ({ data, errors }) => {
        if (errors?.length) {
          const code = errors[0].message
          const message = ''
          throw new ApiError(message, code)
        }

        return data.playlistList
      })
      .catch((error) => {
        logger.error('list playlists request failed', { error })
        if (error instanceof ApiError) throw error
        throw new NetWorkError()
      })

    return promise
  }

  getById(id: string) {
    const promise = apolloClient
      .query({
        query: GET_PLAYLIST,
        context: cmsQueryContext,
        variables: {
          id,
        },
      })
      .then(async ({ data, errors }) => {
        if (errors?.length) {
          const code = errors[0].message
          const message = ''
          throw new ApiError(message, code)
        }

        const playlist = data.playlist as NewPlaylist
        return playlist
      })
      .catch((error) => {
        logger.error('get playlist by id request failed', { error })
        if (error instanceof ApiError) throw error
        throw new NetWorkError()
      })

    return promise
  }

  delete(playlistId: string) {
    const promise = apolloClient
      .query({
        query: DELETE_PLAYLIST,
        context: cmsQueryContext,
        variables: {
          id: playlistId,
        },
      })
      .then(async ({ errors }) => {
        if (errors?.length) {
          const code = errors[0].message
          const message = ''
          throw new ApiError(message, code)
        }

        return true
      })
      .catch((error) => {
        logger.error('delete playlist request failed', { error })
        if (error instanceof ApiError) throw error
        throw new NetWorkError()
      })

    return promise
  }

  update(entity: ICreatePlaylist) {
    const promise = apolloClient
      .query({
        query: UPDATE_PLAYLIST,
        context: cmsQueryContext,
        variables: {
          input: entity,
        },
      })
      .then(async ({ errors }) => {
        if (errors?.length) {
          const code = errors[0].message
          const message = ''
          throw new ApiError(message, code)
        }

        return true
      })
      .catch((error) => {
        logger.error('update playlist request failed', { error })
        if (error instanceof ApiError) throw error
        throw new NetWorkError()
      })

    return promise
  }

  create(playlist: ICreatePlaylist) {
    const promise = apolloClient
      .query({
        query: CREATE_PLAYLIST,
        context: cmsQueryContext,
        variables: {
          input: playlist,
        },
      })
      .then(async ({ data, errors }) => {
        if (errors?.length) {
          const code = errors[0].message
          const message = ''
          throw new ApiError(message, code)
        }

        return data.createPlaylist?.id as string
      })
      .catch((error) => {
        logger.error('create playlist request failed', { error })
        if (error instanceof ApiError) throw error
        throw new NetWorkError()
      })

    return promise
  }

  logAccess(playlistId: string) {
    const promise = apolloClient
      .query({
        query: LOG_PLAYLIST_ACCESS,
        variables: {
          playlistId,
        },
      })
      .then(async ({ errors }) => {
        if (errors?.length) {
          const code = errors[0].message
          const message = ''
          throw new ApiError(message, code)
        }

        return true
      })
      .catch((error) => {
        logger.error('log playlist access request failed', { error })
        if (error instanceof ApiError) throw error
        throw new NetWorkError()
      })

    return promise
  }

  updatePlaylistModuleContent(moduleId: string, oldContentId: string, newContentId: string) {
    const promise = apolloClient
      .query({
        query: UPDATE_PLAYLIST_MODULE_CONTENT,
        context: cmsQueryContext,
        variables: {
          moduleId,
          oldContentId,
          newContentId,
        },
      })
      .then(async ({ errors }) => {
        if (errors?.length) {
          const code = errors[0].message
          const message = ''
          throw new ApiError(message, code)
        }

        return true
      })
      .catch((error) => {
        logger.error('update playlist module content request failed', { error })
        if (error instanceof ApiError) throw error
        throw new NetWorkError()
      })

    return promise
  }

  async getPrivatePlaylists(params: any): Promise<PlaylistList> {
    try {
      const response = await cmsClient?.request({
        url: '/playlist/private/details',
        method: 'GET',
        params: {
          limit: params.limit,
          skip: params.skip,
          orderBy: params.order?.field ?? 'title',
          direction: params.order?.direction ?? 'asc',
          ...(params.ids ? { id: params.ids } : {}),
          ...(params.moduleIds ? { moduleIds: params.moduleIds } : {}),
          ...(params.companies ? { companiesIn: params.companies } : {}),
          ...(params.status ? { statusIn: params.status } : {}),
          ...(params.title ? { search: params.title } : {}),
          ...(params.type ? { type: params.type } : {}),
          ...(params.partnerId ? { partnersIn: [params.partnerId] } : {}),
          ...(params.skill ? { skillIn: params.skill } : {}),
        },
        headers: {
          Authorization: `Bearer ${this.token}`,
          'ngrok-skip-browser-warning': '69420',
        },
      })
      return (
        response?.data ?? {
          items: [],
          total: 0,
        }
      )
    } catch (err) {
      console.error('getPrivatePlaylists error', err)

      return {
        items: [],
        total: 0,
      } as PlaylistList
    }
  }

  async listPlaylistPermissions({
    playlistId,
    limit = 10,
  }: {
    playlistId: string
    limit?: number
  }): Promise<PlaylistPermission[]> {
    try {
      const response: any = await this.httpClient?.request({
        url: `/playlist/${playlistId}/policy`,
        params: {
          limit: limit,
        },
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.token}`,
          'ngrok-skip-browser-warning': '69420',
        },
      })

      return response?.data?.items as PlaylistPermission[]
    } catch (err) {
      console.error('getPlaylistPermissions error', err)
      return []
    }
  }

  async savePlaylistPermissions(input: SavePlaylistPermissionsUseCaseInput): Promise<any> {
    const { allowedFor, playlistId } = input

    try {
      await this.httpClient?.request({
        url: `/playlist/${playlistId}/policy`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.token}`,
          'ngrok-skip-browser-warning': '69420',
        },
        data: {
          teamId: allowedFor,
        },
      })
      return true
    } catch (err) {
      console.error('savePlaylistPermissions error', err)
      return false
    }
  }

  async deletePlaylistPolicy(input: { playlistId: string; policyId: number }): Promise<any> {
    const { policyId, playlistId } = input
    try {
      await this.httpClient?.request({
        url: `/playlist/${playlistId}/policy/${policyId}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      return true
    } catch (err) {
      console.error('savePlaylistPermissions error', err)
      return false
    }
  }

  async replacePlaylistPolicies(input: { playlistId: string; teamIds: string[] }): Promise<any> {
    const { teamIds, playlistId } = input
    try {
      await this.httpClient?.request({
        url: `/playlist/${playlistId}/policy/replace`,
        method: 'PUT',
        data: {
          teamIds,
        },
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      return true
    } catch (err) {
      console.error('savePlaylistPermissions error', err)
      return false
    }
  }

  async getPlaylistSurvey(surveyCode: string): Promise<ISurvey[] | []> {
    try {
      const response = await this.httpClient?.request<ISurvey[]>({
        url: `/v2/survey`,
        params: {
          survey_code: surveyCode,
        },
        method: 'GET',
        headers: {
          Authorization: `${this.token}`,
          'ngrok-skip-browser-warning': '69420',
        },
      })

      if (response?.body) {
        return response.body
      }

      return []
    } catch (err) {
      console.error('getSurvey error', err)
      return []
    }
  }

  async savePlaylistSurvey(surveyId: number, optionId: number): Promise<boolean> {
    try {
      await this.httpClient?.request({
        url: `/v2/survey/${surveyId}/response`,
        method: 'POST',
        data: {
          option_id: optionId,
        },
        headers: {
          Authorization: `${this.token}`,
          'ngrok-skip-browser-warning': '69420',
        },
      })

      return true
    } catch (err) {
      console.error('getSurvey error', err)
      return false
    }
  }

  async createPlaylistTranslations(params: {
    playlistId: string
    language: 'es'
    title: string
    description: string
  }): Promise<boolean> {
    try {
      await cmsClient?.request({
        url: `/playlist/${params.playlistId}/translation/${params.language}`,
        method: 'POST',
        data: {
          title: params.title,
          description: params.description,
        },
        headers: {
          Authorization: `Bearer ${this.token}`,
          'ngrok-skip-browser-warning': '69420',
        },
      })
      return true
    } catch (err) {
      console.error('getPrivatePlaylists error', err)
      return false
    }
  }

  async updatePlaylistTranslations(params: {
    playlistId: string
    language: 'es'
    title: string
    description: string
  }): Promise<boolean> {
    try {
      await cmsClient?.request({
        url: `/playlist/${params.playlistId}/translation/${params.language}`,
        method: 'PUT',
        data: {
          title: params.title,
          description: params.description,
        },
        headers: {
          Authorization: `Bearer ${this.token}`,
          'ngrok-skip-browser-warning': '69420',
        },
      })
      return true
    } catch (err) {
      console.error('getPrivatePlaylists error', err)
      return false
    }
  }
}
