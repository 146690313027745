import { gql } from '@apollo/client'

export const GET_CMS_PARTNER = gql`
  query partner($id: String!) {
    partner(id: $id) {
      id
      name
      squareLogoUrl
      logoUrl
      description
      coverColor
      slug
      translations {
        partner_id
        language
        description
      }
    }
  }
`
