import { gql } from '@apollo/client'

const SAVE_QUIZ_RESULTS = gql`
  mutation SaveQuizResults(
    $careerStage: String
    $developmentPlan: String
    $motivation: String
    $competences: [String]
    $themes: [String]
    $skillsStartingPoint: [String]
    $skillsEndingPoint: [String]
    $dedicationTime: String
    $deadline: Int
    $languages: [String]
  ) {
    SaveQuizResults(
      data: {
        careerStage: $careerStage
        developmentPlan: $developmentPlan
        motivation: $motivation
        competences: $competences
        themes: $themes
        skillsStartingPoint: $skillsStartingPoint
        skillsEndingPoint: $skillsEndingPoint
        dedicationTime: $dedicationTime
        deadline: $deadline
        languages: $languages
      }
    ) {
      careerStage
    }
  }
`

export default SAVE_QUIZ_RESULTS
