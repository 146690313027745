import { Box, Flex, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface Props {
  title: string
  level: number
  minLevel?: number
  onChangeLevel: (level: number) => void
}

const Level: React.FC<Props> = ({ title, level, minLevel, onChangeLevel }: Props) => {
  const [t] = useTranslation()

  if (minLevel) minLevel -= 1
  level -= 1

  const stringLevels = [
    t('quiz.level.inexperienced'),
    t('quiz.level.beginner'),
    t('quiz.level.intermediary'),
    t('quiz.level.advanced'),
    t('quiz.level.specialist'),
  ]
  const descriptionLevels = [
    t('quiz.level.description.inexperienced'),
    t('quiz.level.description.beginner'),
    t('quiz.level.description.intermediary'),
    t('quiz.level.description.advanced'),
    t('quiz.level.description.specialist'),
  ]

  return (
    <Flex flexDirection='column' marginBottom='21px'>
      <Flex alignItems='center' marginBottom='8px'>
        <Text color='gray.800' fontFamily='Poppins' marginRight='16px' textTransform='capitalize'>
          <b>
            {
              // @ts-ignore
              t(`quiz.skills.${title}`)
            }
          </b>{' '}
          - {t('quiz.level.level')} {stringLevels[level]}
        </Text>
        <Text color='gray.500' fontSize='14px'>
          {descriptionLevels[level]}
        </Text>
      </Flex>
      <Flex alignItems='center'>
        <Text color='#0E6B38' marginRight='17px'>
          {t('quiz.level.inexperienced')}
        </Text>

        <Slider
          defaultValue={0}
          min={0}
          max={4}
          step={1}
          value={level}
          onChange={(newLevel: number) => onChangeLevel(newLevel + 1)}
        >
          <SliderTrack bg='#E2E8F0' h='6px'>
            <Box position='relative' right={10} />
            <SliderFilledTrack bg={minLevel ? (minLevel >= level ? '#CFCFCF' : '#A8EBC3') : '#A8EBC3'} />
          </SliderTrack>
          <SliderThumb boxSize={4} bg='primaryColors.340' />
        </Slider>

        <Text color='#0E6B38' marginLeft='17px'>
          {t('quiz.level.specialist')}
        </Text>
      </Flex>
    </Flex>
  )
}

export default Level
