import { Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { useIntersectionObserver } from 'react-intersection-observer-hook'
import { useUser } from 'src/context/userContext'
import Content from 'src/modules/cms/domain/Content'
import { ContentType } from 'src/modules/cms/domain/LearningItem'
import { useModal } from '../../hooks/useModal'
import { useSidemenuSize } from '../../hooks/useSidemenuSize'
import { useWindowSize } from '../../hooks/useWindowSize'
import CourseModal from '../CourseModal'
import LearningItemModal from '../LearningItemModal'
import { LearningItemCardBody, LearningItemCardTab } from './components'

interface Props {
  content: Content
  lineName?: string
  style?: React.CSSProperties
  bodyStyle?: React.CSSProperties
  isFromSlider?: boolean
  removeHover?: boolean
  maxWidth?: string
  onClick?: () => void
  onKnowMoreClick?: () => void
}

export const LearningItemCard: React.FC<Props> = ({
  content,
  lineName,
  style,
  bodyStyle,
  isFromSlider = false,
  removeHover = false,
  maxWidth,
  onClick,
  onKnowMoreClick,
}: Props) => {
  const [nContent, setContent] = useState<Content>(content)
  const { isOpen, toggleModal } = useModal(false)
  const [_hover, setTab] = useState(false)
  const windowSize = useWindowSize()
  const sidemenuSize = useSidemenuSize()
  const isCourse = [ContentType.COURSE].includes(content.type as ContentType)

  const [ref, { entry }] = useIntersectionObserver({ threshold: 0.00025 })
  const isVisible = entry && entry.isIntersecting

  const { user } = useUser()

  const triggerAdditionalInfo = (event: any) => {
    if (removeHover) return

    let element = event.target

    if (element.classList.contains('course-card-tag')) element = element.parentElement
    if (!windowSize.width) return
    if (!sidemenuSize.width) return

    if (isFromSlider) {
      const leftBreakpoint = 143 + (sidemenuSize.width - 78)
      const rightBreakpoint = windowSize.width - 61

      if (element.getBoundingClientRect().left < leftBreakpoint) return
      if (element.getBoundingClientRect().right > rightBreakpoint) return
    }

    setTab(true)
  }

  const dismissAdditionalInfo = () => {
    setTab(false)
  }

  const triggerModal = () => {
    toggleModal()
    setTab(false)
  }

  const onBodyClick = () => {
    if (onClick) return onClick()
    triggerModal()
  }

  const onKnowMoreTabClick = () => {
    if (onKnowMoreClick) return onKnowMoreClick()
    triggerModal()
  }

  if (!user) return null

  return (
    <Flex
      ref={ref}
      data-cy={content.id}
      className='course-card'
      position='relative'
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
      onMouseEnter={triggerAdditionalInfo}
      onMouseLeave={dismissAdditionalInfo}
      transition='all 0.2s ease-in-out'
      userSelect='none'
      zIndex={2}
      _hover={{
        zIndex: _hover && 4,
        width: _hover && (isCourse ? '400px' : '364px'),
        transform: _hover && 'scale(1.18)',
      }}
      style={style}
      onClick={onClick}
    >
      <LearningItemCardBody _hover={_hover} content={nContent} onClick={onBodyClick} style={bodyStyle} maxWidth={maxWidth} />
      {isVisible && (
        <Flex flex='1' w={'100%'} flexWrap='wrap'>
          <LearningItemCardTab
            _hover={_hover}
            lineName={lineName}
            content={nContent}
            toggleModal={onKnowMoreTabClick}
            setContent={setContent}
          />

          {isCourse ? (
            <CourseModal initialCourse={nContent} onClose={triggerModal} isOpen={isOpen} setCourse={setContent} />
          ) : (
            <LearningItemModal
              initialLearningItem={nContent}
              onClose={toggleModal}
              isOpen={isOpen}
              setLearningItem={setContent}
            />
          )}
        </Flex>
      )}
    </Flex>
  )
}
