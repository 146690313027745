import httpClient from "src/services/http/http-client";
import { CertificateRepository } from "../repositories/CertificateRepository";
import { UploadCertificateSignatureUseCase } from "../use_cases/UploadCertificateSignatureUseCase";
import { getAccessToken } from "src/modules/iam/apis/store";

export function makeUploadCertificateSignature() {
  const token = getAccessToken()
  const certificateRepository = new CertificateRepository(token, httpClient)
  return new UploadCertificateSignatureUseCase(certificateRepository)
}
