import { Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useHandleTeam } from 'src/context-admin/useHandleTeam'
import { CloseDrawerButton } from './commonComponents/CloseDrawerButton'

export function HandleTeamDrawer() {
  const { activeStep, goToFirstStep, availableSteps, isHandleTeamDrawerOpen, onCloseHandleTeamDrawer } = useHandleTeam()

  useEffect(() => {
    return () => {
      goToFirstStep()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Drawer
      isOpen={isHandleTeamDrawerOpen}
      placement='right'
      onClose={() => {
        onCloseHandleTeamDrawer()
        goToFirstStep()
      }}
    >
      <DrawerOverlay />
      <DrawerContent maxW='600px' px='60px' py='65px' overflow='auto'>
        <CloseDrawerButton />
        {availableSteps[activeStep]}
      </DrawerContent>
    </Drawer>
  )
}
