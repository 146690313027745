import { useQuery } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import _ from 'lodash'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { GET_FEEDBACK_FLAG } from 'src/graphql/queries/GET_FEEDBACK_FLAG'
import { Company } from 'src/modules/cms/domain/Company'
import { makeGetUserCompany } from 'src/modules/iam/factory/makeGetUserCompany'
import { makeGetUserInfo } from 'src/modules/iam/factory/makeGetUserInfo'
import useLogout from 'src/modules/iam/hooks/useLogout'
import { getLanguageFromStorage, saveLanguageToStorage } from 'src/modules/internationalization/helpers/handleStorage'
import { validateLanguage } from 'src/modules/internationalization/helpers/validateLanguage'
import { axiosInstance } from 'src/services/http/axios-client'
import { HealthCheck } from 'src/types/HealthCheck'
import { Partner } from 'src/types/Partner'
import { User } from 'src/types/User'
import { version } from '../../package.json'
import { useAuthToken } from './auth'

interface Value {
  user: User | undefined
  loading: boolean
  token: string
  showRecurringFeedback?: boolean
  role: string
  userCompany: Company | null
  updateFeedbackFlag: (value: boolean) => void
  updateUserQuizData: (value: boolean) => void
  setUserToken: (token: string) => void
  setUser: (user: User) => void
  setRole: (role: string) => void
  logout: () => void
  getUser: () => Promise<User | undefined>
  platformVersion?: string
}

interface ProviderProps {
  children: React.ReactNode[] | React.ReactNode
}

export interface ReturnGetUser {
  userInfo: User
}

export interface ReturnUserPartners {
  GetUserPartners: Partner[]
}

export interface ReturnFeedbackFlag {
  showRecurringFeedback: boolean
}

const UserContext = createContext({} as Value)

export function UserProvider({ children }: ProviderProps) {
  const [user, setUser] = useState<User | undefined>(undefined)
  const [userCompany, setUserCompany] = useState<Company | null>(null)
  const [showRecurringFeedback, setShowRecurringFeedback] = useState(false)
  const [role, setRole] = useState<string>('COLAB')
  const [platformVersion, setPlatformVersion] = useState<string | undefined>(undefined)
  const [loadingUserInfo, setLoadingUserInfo] = useState(false)

  const { token } = useAuthToken()
  const [, setUserToken] = useState(token)
  const { i18n } = useTranslation()
  const { logout } = useLogout()
  const location = useLocation()
  const toast = useToast()

  const joinCompanyIntoUser = (user: User, userCompany: Company) => {
    const userWithCompany: User = {
      ..._.cloneDeep(user),
      company: {
        id: userCompany.id,
        name: userCompany.name,
        languages: [],
        areas: [],
        logoUrl: userCompany.logoUrl,
        canUserChangeLanguage: userCompany.canUserChangeLanguage,
      },
    }
    return userWithCompany
  }

  const setCorrectlyUser = (user: User) => {
    const userInfoWithoutDuplicatedInfo: User = {
      ..._.cloneDeep(user),
      contentInfo: {
        accessed: _.uniqBy(user.contentInfo.accessed, (e) => e.entryId).reverse(),
        certificates: _.uniqBy(user.contentInfo.certificates, (e) => e.playlistSlug).reverse(),
        completed: _.uniqBy(user.contentInfo.completed, (e) => e.entryId).reverse(),
        favorites: _.uniqBy(user.contentInfo.favorites, (e) => e).reverse(),
      },
      playlistInfo: {
        accessed: _.uniqBy(user.playlistInfo.accessed, (e) => e.id).reverse(),
        completed: _.uniqBy(user.playlistInfo.completed, (e) => e).reverse(),
      },
    }
    setUser(userInfoWithoutDuplicatedInfo)
    return userInfoWithoutDuplicatedInfo
  }

  const getUser = async () => {
    try {
      setLoadingUserInfo(true)

      const getUserInfo = makeGetUserInfo()
      const userInfo = await getUserInfo.execute()
      if (!userInfo) throw new Error()

      const getUserCompany = makeGetUserCompany()
      const userCompany = await getUserCompany.execute()
      if (!userCompany) throw new Error()
      setUserCompany(userCompany)
      const userInfoWithCompany = joinCompanyIntoUser(userInfo, userCompany)
      const correctlyUserInfo = setCorrectlyUser(userInfoWithCompany)

      return correctlyUserInfo
    } catch {
      setUser(undefined)
      toast({
        status: 'error',
        title: 'Usuário não encontrado',
        description: 'Por favor, tente novamente!',
        isClosable: true,
        duration: 7000,
      })
      logout()
    } finally {
      setLoadingUserInfo(false)
    }
  }

  const getPlatformVersion = useCallback(async () => {
    try {
      if (version) {
        const formatted = version.replaceAll('v', '')
        setPlatformVersion(`v${formatted}`)
      } else {
        const response = await axiosInstance.get<HealthCheck>('/v1/health-check')
        if (response?.status === 200) {
          setPlatformVersion(`v${response?.data?.version}`)
        }
      }
    } catch {
      console.error('Ocorreu um erro ao buscar os status da plataforma.')
    }
  }, [])

  useQuery<ReturnFeedbackFlag>(GET_FEEDBACK_FLAG, {
    skip: !token,
    fetchPolicy: 'no-cache',
    onCompleted: (data: ReturnFeedbackFlag) => {
      if (data.showRecurringFeedback) setShowRecurringFeedback(data.showRecurringFeedback)
    },
  })

  useEffect(() => {
    if (token) {
      getUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {
    if (!user) {
      const languageFromStorage = getLanguageFromStorage()
      if (!languageFromStorage) {
        i18n.changeLanguage('pt-BR')
        saveLanguageToStorage('pt-BR')
        return
      }
      const isLanguageValid = validateLanguage(languageFromStorage)
      if (!isLanguageValid) return
      i18n.changeLanguage(languageFromStorage)
      return
    }
    i18n.changeLanguage(user?.language)
    saveLanguageToStorage(user?.language)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    const isAdminPage = location.pathname.indexOf('/admin') !== -1

    if (user) {
      if (isAdminPage && user?.roles?.includes('ADMIN')) {
        setRole('ADMIN')
        return
      }
      if (isAdminPage && user?.roles?.includes('MANAGER')) {
        setRole('MANAGER')
        return
      }
      if (!isAdminPage) {
        setRole('COLAB')
        return
      }
    }
  }, [user, location.pathname])

  useEffect(() => {
    getPlatformVersion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateUserQuizData = (value: boolean) => {
    setUser((prevState) => ({
      ...prevState!,
      hasCompletedCustomPlaylistQuiz: value,
      hasPlaylistAssigned: value,
    }))
  }

  const updateFeedbackFlag = useCallback((value: boolean) => {
    setShowRecurringFeedback(value)
  }, [])

  return (
    <UserContext.Provider
      value={{
        user,
        getUser,
        role,
        loading: loadingUserInfo,
        token,
        userCompany,
        setUserToken,
        setUser: setCorrectlyUser,
        updateUserQuizData,
        logout,
        showRecurringFeedback,
        setRole,
        updateFeedbackFlag,
        platformVersion,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)
