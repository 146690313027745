import { Flex } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { useUser } from 'src/context/userContext'
import { User } from 'src/types/User'
import { AccessSpreadshetMessage } from '../../commonComponents/AccessSpreadshetMessage'
import { NavigationButtons } from '../../commonComponents/NavigationButtons'
import { StepTitle } from '../../commonComponents/StepTitle'
import { StepTransition } from '../../commonComponents/StepTransition'
import { UserList } from '../../commonComponents/UserList'

export function EditExistingAreaAddUsers() {
  const [t] = useTranslation()
  const { area, navigateToStep, handleSetAreaValues, usersList, getUsersList } = useHandleArea()
  const { user } = useUser()

  const handleAddUsersToArea = (users?: User[]) => {
    handleSetAreaValues({ newUsers: users ?? [] })
  }

  useEffect(() => {
    getUsersList({ variables: { areaId: 0 } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StepTransition>
      <Flex flexDir='column'>
        <StepTitle
          title={t('addingNewMembers')}
          subtitle={t('browseAndSelectTheCollaborators', { areaName: area?.name })}
        />
        <AccessSpreadshetMessage
          message={t('toAddMembersOfAnAreaUsingSpreadsheet')}
          onClick={() => {
            navigateToStep('edit-existing-area--add-users-file')
          }}
        />
        <UserList
          data={usersList.filter((u) => u.area.id !== user?.area.id)}
          selectText={t('addToTeam')}
          selectedText={t('added')}
          onSelectUsers={handleAddUsersToArea}
          alreadySelectedUsers={area?.newUsers}
        />
      </Flex>
      <NavigationButtons
        isContinueDisabled={!area?.newUsers || area?.newUsers?.length <= 0}
        onContinueClick={() => {
          navigateToStep('edit-existing-area--confirm-add-users')
        }}
        onBackClick={() => {
          navigateToStep('edit-existing-area--select-options')
        }}
      />
    </StepTransition>
  )
}
