import { useQuery } from '@apollo/client'
import {
  Box,
  Button,
  Flex,
  Icon,
  Img,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosLock } from 'react-icons/io'
import Loader from 'src/components-admin/Loader'
import { useUser } from 'src/context/userContext'
import { makeValidatePartnerToken } from 'src/modules/cms/factories/makeValidatePartnerToken'
import { GET_PARTNERS } from 'src/modules/cms/graphql/queries/GET_PARTNERS'
import { cmsQueryContext } from 'src/services/apollo/ApolloClient'
import { CmsPartner, CmsPartnersQuery } from 'src/types/Cms'

type TokenInfo = {
  isValid: boolean | undefined
  token: string
  errorMsg?: string
}

export function TokensTab() {
  const [t] = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [tokenInfo, setTokenInfo] = useState<TokenInfo>({ isValid: undefined, token: '', errorMsg: undefined })
  const [partners, setPartners] = useState<CmsPartner[]>([])
  const { onOpen, onClose, isOpen } = useDisclosure()
  const { user, getUser } = useUser()
  const validatePartnerToken = makeValidatePartnerToken()
  const aluraPartnerId = partners?.find((item) => item?.slug === 'alura')?.id
  const aluraCompanyToken = user?.companyTokens?.find((item) => item.partnerId === aluraPartnerId)


  const { loading } = useQuery<CmsPartnersQuery>(GET_PARTNERS, {
    context: cmsQueryContext,
    variables: {
      privateList: true,
    },
    onCompleted: (data) => {
      setPartners(data?.partnerList?.items ?? [])
    },
  })

  const validateToken = async (partnerSlug: string) => {
    if (tokenInfo.token.length <= 3) return

    try {
      setTokenInfo((prev) => ({ ...prev, isValid: undefined, errorMsg: undefined }))
      setIsLoading(true)
      const partner = partners.find((item) => item.slug === partnerSlug)
      if (!partner) throw new Error('Partner not found')
      const response = await validatePartnerToken.execute({ token: tokenInfo.token, partnerId: partner.id })
      if (response?.error === 'TOKEN_ALREADY_EXISTS')
        return setTokenInfo((prev) => ({ ...prev, isValid: false, errorMsg: `${t('theTokenWasAlreadyValidated')}.` }))
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!response?.error)
        return setTokenInfo((prev) => ({
          ...prev,
          isValid: false,
          errorMsg: t('invalidtokenPlaseVerify'),
        }))
      setTokenInfo((prev) => ({ ...prev, isValid: true }))
      await getUser()
    } catch {
      setTokenInfo((prev) => ({ ...prev, isValid: false }))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!user) return
    if (user?.companyTokens?.length === 0) return
    if (!aluraPartnerId) return
    if (!!aluraCompanyToken?.partnerId && !!aluraCompanyToken?.token) {
      setTokenInfo({ token: aluraCompanyToken.token, isValid: true, errorMsg: undefined })
    }
  }, [user, aluraPartnerId, aluraCompanyToken])

  if (loading) return <Loader />

  return (
    <>
      <Flex flexDir='column' flex={1} paddingBottom='40px' data-testid='cms-contents-wrapper'>
        <Text color='gray.800' fontSize='1.25rem' fontWeight={600} fontFamily='Poppins' mb='18px'>
          {t('tokenValidation')}
        </Text>
        <Text color='bluishGray.500' fontSize='1rem' fontWeight={600} fontFamily='Mulish'>
          {t('theTokenValidationIsNeededToFreeTheUserAccess')}.
        </Text>
        <Text color='bluishGray.500' fontSize='1rem' fontWeight={600} fontFamily='Mulish'>
          {t('whileTheValidationWasNotSuccessfullyTheUsersHaveNoAccess')}.
        </Text>

        <Flex flexDir='column' gap='8px' mt='24px'>
          <Flex gap='8px'>
            <Text color='gray.800' fontSize='0.875rem' fontWeight={700} fontFamily='Mulish'>
              Alura
            </Text>
            {isLoading ? (
              <Flex p='2px 12px' bgColor='#EEEEEE' borderRadius='100px'>
                <Text fontSize='0.75rem' fontWeight={600} color='gray.500'>
                  {t('validating')}...
                </Text>
              </Flex>
            ) : (
              <Flex p='2px 12px' bgColor={tokenInfo.isValid ? '#EAF5EA' : '#FFF3F3'} borderRadius='100px'>
                <Text
                  textTransform='lowercase'
                  fontSize='0.75rem'
                  fontWeight={600}
                  color={tokenInfo.isValid ? '#009639' : '#FF4949'}
                >
                  {tokenInfo.isValid ? t('tokenValidated') : t('validationPending')}
                </Text>
              </Flex>
            )}
          </Flex>
          <Flex flexDir='column' w='100%' gap='8px'>
            <Flex gap='8px' w='100%'>
              <InputGroup w='100%' maxW='494px'>
                <InputLeftElement display='flex' alignItems='center' justifyContent='center' h='36px'>
                  <Icon as={IoIosLock} color='#767F89' w='20px' h='20px' size={20} />
                </InputLeftElement>
                <Input
                  color='#313E4C'
                  height='36px'
                  fontSize='0.875rem'
                  value={tokenInfo.token}
                  onChange={(e) => setTokenInfo((prev) => ({ ...prev, token: e.target.value.replaceAll(' ', '') }))}
                  placeholder={t('insertTheTokenCode')}
                />
              </InputGroup>
              <Tooltip
                isDisabled={tokenInfo.token.length > 3}
                hasArrow
                label={t('youShouldInsertValidToken')}
                borderRadius='8px'
                bg='gray.800'
              >
                <Box>
                  <Button
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    type='submit'
                    variant='unstyled'
                    h='36px'
                    w='84px'
                    bg='gray.800'
                    color='white'
                    fontSize='14px'
                    // padding='5.5px 16px'
                    onClick={() => validateToken('alura')}
                    isLoading={isLoading}
                    isDisabled={tokenInfo.token.length <= 3}
                    _hover={{ _disabled: { bg: '#3F3D561F' } }}
                  >
                    {isLoading ? <Spinner /> : 'Validar'}
                  </Button>
                </Box>
              </Tooltip>
            </Flex>
            {tokenInfo.isValid === true ? (
              <Text display='flex' gap='4px' color='#767F89' fontWeight='500' fontSize='0.75rem'>
                {t('attentionTheAccessToContentWillBeAvailableAfter2Hours')}
              </Text>
            ) : (
              <>
                <Flex w='100%' maxW='550px' gap='16px' bg='#F7F9FA' p='8px 12px' borderRadius='8px'>
                  <Text fontWeight='700' fontSize='0.75rem'>
                    {t('yourTokenWillVeAvailableInsideSettingsArea')}
                  </Text>
                  <Text textDecoration='underline' fontSize='0.75rem' cursor='pointer' onClick={onOpen}>
                    {t('seeAnExample')}
                  </Text>
                </Flex>
                {tokenInfo.isValid === false ? (
                  <Text color='#FF4949' fontSize='0.75rem' fontWeight='600'>
                    {tokenInfo?.errorMsg}
                  </Text>
                ) : null}
              </>
            )}
          </Flex>
        </Flex>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW='70vw'>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight='600' fontSize='0.875rem'>
              {t('seeATutorialToGetYourAluraToken')}:
            </Text>
            <Flex mt='8px' w='100%'>
              <Img
                src='https://storage.googleapis.com/ej-assets/tutorial_get_alura_token.gif'
                w='100%'
                h='100%'
                borderRadius='8px'
              />
            </Flex>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
