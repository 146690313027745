import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderContentsStep } from 'src/pages-admin/Cms/components/PlaylistContentsDrawer/OrderContentsStep'
import { SelectContentsStep } from 'src/pages-admin/Cms/components/PlaylistContentsDrawer/SelectContentsStep'
import { useRegisterPlaylistStore } from 'src/pages-admin/Cms/hooks/useRegisterPlaylistStore'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export function PlaylistContentsDrawer({ isOpen, onClose }: Props) {
  const [t] = useTranslation()
  const {
    selectedContents,
    updateSelectedContents,
    contentsDrawerStep,
    updateContentsDrawerStep,
    isEditingSelectedContents,
    updateSavedSelectedContents,
  } = useRegisterPlaylistStore()

  const handleCloseDrawer = useCallback(async () => {
    onClose()
    if (!isEditingSelectedContents) updateSelectedContents([])
  }, [onClose, updateSelectedContents, isEditingSelectedContents])

  const handleNextStep = () => {
    if (contentsDrawerStep === 0 && selectedContents.length === 1) {
      updateSavedSelectedContents(selectedContents)
      onClose()
    }

    if (contentsDrawerStep === 1) {
      updateSavedSelectedContents(selectedContents)
      onClose()
    }

    updateContentsDrawerStep(1)
  }

  const handleStepBack = () => {
    if (contentsDrawerStep === 1) {
      updateContentsDrawerStep(0)

      return
    }

    handleCloseDrawer()
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={handleCloseDrawer}
      closeOnOverlayClick={false}
      size='xl'
      data-testid='cms-remove-drawer-wrapper'
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton data-testid='cms-remove-drawer-close-button' />
        <Flex flexDir='column' padding='88px 80px 0px' overflowY='auto' flexGrow='1'>
          <DrawerHeader p='0' fontWeight={700} fontSize='1.25rem'>
            {contentsDrawerStep === 0
              ? t('whichContentYouWantToIncludeOnPlaylist')
              : 'Defina a ordem dos conteúdos dentro da trilha'}
            {contentsDrawerStep === 0 ? (
              <Text color='bluishGray.500' fontWeight={600} fontSize='1.125rem' mt='8px'>
                {t('insertCoursesArticlesVideosAndPodcast')}
              </Text>
            ) : (
              <Text color='bluishGray.500' fontWeight={600} fontSize='1.125rem' mt='8px'>
                {t('customPlaylistQuiz.stepFive.description')}
              </Text>
            )}
          </DrawerHeader>
          <DrawerBody p='0' display='flex' flexDir='column' flex='1' pr='4px'>
            {contentsDrawerStep === 0 ? <SelectContentsStep /> : <OrderContentsStep />}
          </DrawerBody>
          <Flex justify='space-between' gap='12px' mt='8px' mb='24px'>
            <Button
              variant='outline'
              textDecoration='underline'
              px='0'
              border='none'
              _hover={{ bg: 'transparent' }}
              onClick={handleStepBack}
            >
              {t('back')}
            </Button>
            <Button
              isDisabled={selectedContents.length === 0}
              variant='unstyled'
              bg='gray.800'
              color='white'
              fontWeight='bold'
              padding='4px 12px'
              fontSize='.875rem'
              onClick={handleNextStep}
              data-testid='cms-remove-drawer-cancel-button'
            >
              {t('login.continue')}
            </Button>
          </Flex>
        </Flex>
      </DrawerContent>
    </Drawer>
  )
}
