const customAttributesFromApiMap: Record<string, string> = {
  nomeUsuario: 'completeName',
  centroCusto: 'centralCost',
  area: 'area',
  filial: 'branch',
  gestor: 'isManager',
  nomeGestor: 'managerFirstName',
  sobrenomeGestor: 'managerLastName',
  emailGestor: 'managerEmail',
  modeloContrato: 'contractModel',
  dataContratacao: 'contractDate',
  localizacao: 'location',
  tituloGlobalCargo: 'globalTitle',
  status: 'status',
  telefone: 'phone',
  genero: 'gender',
  dataNascimento: 'birthDate',
  plataformaClienteId: 'userIdInClientPlatform',
  language: 'language',
}

const customAttributesToApiMap: Record<string, string> = {
  completeName: 'nomeUsuario',
  centralCost: 'centroCusto',
  area: 'area',
  branch: 'filial',
  isManager: 'gestor',
  managerFirstName: 'nomeGestor',
  managerLastName: 'sobrenomeGestor',
  managerEmail: 'emailGestor',
  contractModel: 'modeloContrato',
  contractDate: 'dataContratacao',
  location: 'localizacao',
  globalTitle: 'tituloGlobalCargo',
  status: 'status',
  phone: 'telefone',
  gender: 'genero',
  birthDate: 'dataNascimento',
  userIdInClientPlatform: 'plataformaClienteId',
  language: 'language',
}

export function parseCustomAttributesFromApi(customAttributesFromApi: Record<string, string>) {
  let formValues: Record<string, string> = {
    completeName: '',
    centralCost: '',
    area: '',
    branch: '',
    isManager: '',
    managerFirstName: '',
    managerLastName: '',
    managerEmail: '',
    contractModel: '',
    contractDate: '',
    location: '',
    globalTitle: '',
    status: '',
    birthDate: '',
    gender: '',
    phone: '',
    userIdInClientPlatform: '',
    language: '',
    team: '',
  }

  Object.keys(customAttributesFromApi).forEach((key: string) => {
    const keyFromApiMap = customAttributesFromApiMap[key]
    if (!keyFromApiMap) return

    if (keyFromApiMap === 'isManager') {
      formValues[keyFromApiMap] = customAttributesFromApi[key] ? 'yes' : 'no'
      return
    }

    if ((keyFromApiMap === 'contractDate' || keyFromApiMap === 'birthDate') && customAttributesFromApi[key]) {
      const date = customAttributesFromApi[key]?.split('T')?.[0]?.replaceAll('-', '/')
      const _date = date?.split('/')[2] + '/' + date?.split('/')[1] + '/' + date?.split('/')[0]
      formValues[keyFromApiMap] = _date
      return
    }

    if (keyFromApiMap === 'status' && !!customAttributesFromApi[key]) {
      formValues[keyFromApiMap] = customAttributesFromApi[key] === 'ativo' ? 'active' : 'inactive'
      return
    }

    formValues[keyFromApiMap] = customAttributesFromApi[key]
  })

  return formValues
}

export function parseCustomAttributesToApi(customAttributesFromApi: Record<string, string>) {
  let values = {}

  Object.entries(customAttributesFromApi).forEach(([key, value]) => {
    if (customAttributesToApiMap[key] && !!value) {
      let _value: string | boolean = value

      if (key === 'contractDate' || key === 'birthDate') {
        _value = new Date(value).toISOString()
      }

      if (key === 'isManager') {
        _value = value === 'yes'
      }

      const newKey = customAttributesToApiMap[key]
      Object.assign(values, { [newKey]: _value })
    }
  })

  return values
}
