import { Flex, Skeleton } from '@chakra-ui/react'

export function TableSkeleton() {
  return (
    <Flex w='100%' flexDir='column'>
      <Skeleton h='52px' w='96%' borderRadius='16px 16px 0 0' marginBottom='1px' />
      <Skeleton h='65px' w='96%' borderRadius='0' marginBottom='1px' />
      <Skeleton h='65px' w='96%' borderRadius='0' marginBottom='1px' />
      <Skeleton h='65px' w='96%' borderRadius='0' marginBottom='1px' />
      <Skeleton h='65px' w='96%' borderRadius='0 0 16px 16px ' />
    </Flex>
  )
}
