import { getAccessToken } from 'src/modules/iam/apis/store'
import httpClient from 'src/services/http/http-client'
import { TeamRepository } from '../repository/TeamRepository'
import { RemoveTeamManagersUseCase } from '../use-case/RemoveTeamManagersUseCase'

export function makeRemoveTeamManagers() {
  const token = getAccessToken()

  const repository = new TeamRepository(token, httpClient)
  return new RemoveTeamManagersUseCase(repository)
}
