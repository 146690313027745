import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

const DescriptionSection: React.FC = () => {
  const [t] = useTranslation()

  return (
    <Flex
      backgroundSize={['auto 100%', '100vw', 'cover', 'cover', 'cover']}
      minHeight={{ base: '300px', md: '400px', lg: 'auto' }}
      paddingLeft={{ base: '20px', lg: '0px' }}
      backgroundRepeat='no-repeat'
      flexDir={{ base: 'column', lg: 'row' }}
    >
      <Flex
        alignItems={{ base: 'flex-start' }}
        marginLeft={{ base: '0px', lg: '60px' }}
        position='relative'
        flexDir={{ base: 'column' }}
      >
        <Text
          fontFamily='Poppins'
          fontWeight='bold'
          color='gray.800'
          w='100%'
          maxW={{ base: 'unset', lg: '40vw' }}
          fontSize='32px'
        >
          {t('playlists.private')}
        </Text>
        <Text
          fontFamily='Mulish'
          fontWeight='regular'
          textAlign={{ base: 'justify', sm: 'left' }}
          fontSize='18px'
          color='rgba(49, 62, 76, 0.7)'
          w='100%'
          maxW={{ base: 'unset', lg: '550px' }}
          paddingRight={{ base: '20px', lg: '0px' }}
        >
          {t('playlists.privateDescription')}
        </Text>
      </Flex>
      <Image
        display={{ base: 'block' }}
        alignSelf='flex-start'
        src='/assets/images/img-banner-trilha.svg'
        w={{ base: '100%', lg: '50%' }}
        fallback={<Box w='100%' maxW='432px' h='269.5px' bg='transparent'></Box>}
      />
    </Flex>
  )
}

export default DescriptionSection
