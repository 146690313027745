import { CloseIcon } from '@chakra-ui/icons'
import { Box, Flex, Skeleton, Text } from '@chakra-ui/react'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSelect, { OptionTypeBase } from 'react-select'
import { Skill, useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { useUser } from 'src/context/userContext'
import { EventsImplementation } from 'src/hooks/Mixpanel/types'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { useWindowSize } from 'src/hooks/useWindowSize'
import ButtonsFooter from '../ButtonsFooter'
import { selectStyles } from '../utils'

export const SelectSkills = () => {
  const [t] = useTranslation()
  const { track } = useMixpanel()
  const { quizValues, allSkills, updateQuiz, updateStep, isLoadingSkills, getAllCmsSkills } = useCustomPlaylistQuiz()
  const { skillsSnapshot } = quizValues

  const { user } = useUser()

  const { width, height } = useWindowSize()
  const isMobile = useMemo(() => !!width && width < 768, [width])
  const showBlankSpaceBeforeFooter = useMemo(() => !!height && height < 770, [height])

  const handleSelectSkill = (item: Skill) => {
    const filteredSkills = skillsSnapshot.filter((skill) => skill.id !== item.id)
    track(EventsImplementation.PERSONALIZED_PLAYLIST_QUIZ_REMOVE_SKILL(item.name))
    updateQuiz('skillsSnapshot', filteredSkills)
  }

  const handleUpdateStep = () => {
    const userSkillsFormatted = allSkills.filter((item) => item.id === user?.skills?.find((skill) => skill === item.id))
    const correctSkillsSnapshot = Array.from(new Set([...skillsSnapshot, ...userSkillsFormatted]))

    updateQuiz('skills', correctSkillsSnapshot)
    updateQuiz('skillsSnapshot', correctSkillsSnapshot)

    if (correctSkillsSnapshot?.length === 1) {
      updateStep(3)
      return
    }

    updateStep(1)
  }

  const handleNewSkill = (item: OptionTypeBase | null) => {
    const findedSkill = allSkills.find((skillFE) => skillFE.id === item?.value)
    if (!findedSkill) return
    track(EventsImplementation.PERSONALIZED_PLAYLIST_QUIZ_ADD_SKILL(findedSkill.name))
    updateQuiz('skillsSnapshot', [...skillsSnapshot, findedSkill!])
  }

  useEffect(() => {
    getAllCmsSkills({ variables: { limit: 500, skip: 0 } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex direction='column'>
      <Box>
        {!isLoadingSkills && skillsSnapshot.length <= 0 && (
          <Text fontWeight={600} fontSize='16px' color='red.300' mb='8px'>
            {t('customPlaylistQuiz.stepTwo.errorMessage')}
          </Text>
        )}
        <Flex align='flex-start' direction='column' gap='12px'>
          {!isLoadingSkills &&
            user?.skills?.map((skill, index) => (
              <Flex
                alignItems='center'
                justifyContent='space-between'
                key={skill}
                w='100%'
                minH='48px'
                p={{ base: '8px 16px', md: '8px 24px' }}
                bg={'primaryColors.30'}
                borderRadius='16px'
                data-testid={`customPlaylistQuiz-skill-listed-${index}`}
              >
                <Text fontWeight={700} fontSize={{ base: '1rem', sm: '1.125rem' }}>
                  {allSkills.find((skillFE) => skillFE.id === skill)?.name}
                </Text>
              </Flex>
            ))}
          {skillsSnapshot
            ?.filter((skill) => skill?.id !== user?.skills?.find((item) => item === skill?.id))
            .map((item, index) => {
              return (
                <Flex
                  alignItems='center'
                  justifyContent='space-between'
                  key={item.id}
                  w='100%'
                  minH='48px'
                  p={{ base: '8px 16px', md: '8px 24px' }}
                  bg={'primaryColors.30'}
                  borderRadius='16px'
                  data-testid={`customPlaylistQuiz-skill-listed-${index}`}
                >
                  <Text fontWeight={700} fontSize={{ base: '1rem', sm: '1.125rem' }}>
                    {item.name}
                  </Text>
                  <Text
                    cursor='pointer'
                    fontWeight={600}
                    fontSize='0.75rem'
                    fontFamily='Poppins'
                    padding={isMobile ? '4px' : '0px'}
                    onClick={() => handleSelectSkill(item)}
                    data-testid={`customPlaylistQuiz-skill-listed-remove-button-${index}`}
                  >
                    {isMobile ? <CloseIcon width='10px' height='10px' /> : t('customPlaylistQuiz.stepOne.removeSkill')}
                  </Text>
                </Flex>
              )
            })}
          {isLoadingSkills ? (
            <Skeleton w='100%' borderRadius='16px' h='48px' />
          ) : (
            <Box w='100%' data-testid='custom-playlist-quiz-select-skills'>
              <ReactSelect
                id='custom-playlist-react-select-skills'
                isSearchable
                isClearable={false}
                styles={selectStyles()}
                value={null}
                placeholder={t('customPlaylistQuiz.stepOne.placeholder')}
                isDisabled={skillsSnapshot.length >= 10}
                components={{
                  IndicatorSeparator: () => null,
                }}
                options={allSkills
                  .map((skill) => ({ value: skill.id, label: skill.name }))
                  .filter((option) => user?.skills?.findIndex((skill) => skill === option.value) === -1)
                  .filter((option) => skillsSnapshot.findIndex((skill) => skill?.id === option.value) === -1)}
                onChange={handleNewSkill}
                isLoading={isLoadingSkills}
              />
              {skillsSnapshot.length >= 10 && (
                <Text
                  mt='8px'
                  fontWeight={600} 
                  fontSize='16px' 
                  color='red.300' 
                >
                  {t('customPlaylistQuiz.stepOne.rateLimit')}
                </Text>
              )}
            </Box>
          )}
        </Flex>
      </Box>
      <ButtonsFooter
        disabledButton={(!skillsSnapshot?.length && user && !user?.skills?.length) || isLoadingSkills}
        // disabledButton={true}
        nextButtonCallback={handleUpdateStep}
      />
      {showBlankSpaceBeforeFooter && <Box w='100%' h='120px' display={{ base: 'block', md: 'none' }} />}
    </Flex>
  )
}
