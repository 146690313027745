import { CollabSkillRepository } from '../repositories/CollabSkillRepository'

interface AllocateCollabSkillsUseCaseInput {
  collabIds: number[]
  skillIds: string[]
}

export type AllocateCollabSkillsUseCaseOutput = {
  alocatedSkills: {
    id: number
    userId: number
    skillId: string
  }[]
  errors: {
    code: string
    message: string
    userId: number
  }[]
}

export class AllocateCollabSkillsUseCase {
  constructor(private repository: CollabSkillRepository) {}

  async execute({ collabIds, skillIds }: AllocateCollabSkillsUseCaseInput): Promise<AllocateCollabSkillsUseCaseOutput> {
    const response = await this.repository.allocate(collabIds.map(Number), skillIds)

    return response
  }
}
