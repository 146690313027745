import NewPlaylist from "src/modules/cms/domain/Playlist";

function getPlaylistDuration(playlist: NewPlaylist) {
  const playlistDurationInSeconds = playlist.moduleList?.items?.reduce((acc, module) => {
    // The sum of the duration of all module contents
    const moduleDuration = module.contentList?.items?.reduce(
      (moduleAcc, moduleContent) => moduleAcc + moduleContent.duration!, 0
    )
    // return the sum of the duration of all playlist modules
    return acc + moduleDuration!
  }, 0)

  return Math.floor(playlistDurationInSeconds! / 60) // in minutes
}

export function convertPlaylistDurationInReadableTime(playlist: NewPlaylist) {
  const durationInMinutes = getPlaylistDuration(playlist)

  if (durationInMinutes < 60) return `${durationInMinutes}min`

  return `${Math.floor(durationInMinutes / 60)}h`
}