import { Flex } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { User } from 'src/types/User'
import { AccessSpreadshetMessage } from '../../commonComponents/AccessSpreadshetMessage'
import { NavigationButtons } from '../../commonComponents/NavigationButtons'
import { StepTitle } from '../../commonComponents/StepTitle'
import { StepTransition } from '../../commonComponents/StepTransition'
import { UserList } from '../../commonComponents/UserList'

export function EditExistingAreaRemoveUsers() {
  const [t] = useTranslation()
  const { area, navigateToStep, handleSetAreaValues, usersList, getUsersList } = useHandleArea()

  const handleAddUsersToArea = (users?: User[]) => {
    handleSetAreaValues({ newUsers: users ?? [] })
  }

  useEffect(() => {
    getUsersList({ variables: { areaId: Number(area?.id) } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StepTransition>
      <Flex flexDir='column'>
        <StepTitle
          title={t('removingMembersFromArea', { areaName: area?.name ?? t('invalidArea') })}
          subtitle={t('selectMembersToRemoval', { areaName: area?.name ?? t('invalidArea') })}
        />
        <AccessSpreadshetMessage
          message={t('toRemoveMembersOfAnAreaUsingSpreadsheet')}
          onClick={() => {
            navigateToStep('edit-existing-area--remove-users-file')
          }}
        />
        <UserList
          withFilter={false}
          data={usersList}
          selectText={t('remove')}
          selectedText={t('removed')}
          selectedTextColor={'red.500'}
          onSelectUsers={handleAddUsersToArea}
          alreadySelectedUsers={area?.newUsers}
        />
      </Flex>
      <NavigationButtons
        isContinueDisabled={!area?.newUsers || area?.newUsers?.length <= 0}
        onContinueClick={() => {
          navigateToStep('edit-existing-area--confirm-remove-users')
        }}
        onBackClick={() => {
          navigateToStep('edit-existing-area--select-options')
        }}
      />
    </StepTransition>
  )
}
