// https://developers.google.com/tag-manager/devguide?hl=pt-br#events

function isWindowInitiated() {
  if (typeof window === 'undefined') return false
  return true
}

// @ts-ignore
export function gtag(...args: any) {
  if (isWindowInitiated()) {
    // @ts-ignore
    window?.dataLayer?.push(...args)
  }
}