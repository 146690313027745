import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useDashboardReports, useDashboardReportsTabs } from 'src/modules/reports/hooks/useDashboardReports'
import { availableTabs } from '../../modules/reports/availableTabs'

export function DashboardReports() {
  const { changeActiveTab, isLoading } = useDashboardReports()
  const dashboardReportTabs = useDashboardReportsTabs()

  return (
    <Flex px={{ base: '16px', md: '56px' }} flexDir='column' h='100%'>
      <Tabs colorScheme='green' h='100%'>
        <TabList borderColor='#EFEFEF'>
          {dashboardReportTabs?.map((tab) => (
            <Tab
              key={tab.id}
              borderBottomWidth='2px'
              onClick={() => changeActiveTab(tab.id)}
              isDisabled={isLoading}
              fontWeight='400'
              fontSize='1rem'
              transition='all 300ms ease'
              color='#767F89'
              borderColor='#EFEFEF'
              _selected={{ color: '#313E4C', borderColor: 'green.500', fontWeight: '700' }}
              _active={{ outline: 'none' }}
              _focus={{ outline: 'none' }}
            >
              {tab.label}
            </Tab>
          ))}
        </TabList>

        <TabPanels h='100%'>
          {Object.values(availableTabs).map((tab, index) => (
            <TabPanel key={index} h='100%'>
              {tab}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Flex>
  )
}
