import { Flex, Image, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useHomeAccess } from 'src/context-admin/Home/AdminHomeAccessContext'
import { useTeams } from '../../context-admin/useTeams'
import { useMixpanel } from '../../hooks-admin/mixpanel/useMixpanel'

type Props = {
  handleComponentChange: (value: string) => void
}

const Success: React.FC<Props> = ({ handleComponentChange }) => {
  const { fetchUsers, first } = useHomeAccess()
  const { EventsImplementation, track } = useMixpanel()
  const { handleToggleTeamsDrawer, successText, getNextOption, refetchTeams, setTeams } = useTeams()

  useEffect(() => {
    track(EventsImplementation.TEAM_EDIT_CONFIRMATION())
    const timeout = setTimeout(async () => {
      if (!getNextOption()) {
        handleToggleTeamsDrawer()
        const { data } = await refetchTeams()
        setTeams(data.listTeams)
        fetchUsers({ limit: first })
        return
      }

      handleComponentChange(getNextOption())
    }, 1500)

    return () => {
      clearTimeout(timeout)
    }
  }, [
    fetchUsers,
    getNextOption,
    handleComponentChange,
    handleToggleTeamsDrawer,
    refetchTeams,
    setTeams,
    EventsImplementation,
    track,
  ])

  return (
    <Flex height='100%' alignItems='center' justifyContent='center' marginBottom='38px'>
      <Flex flexDirection='column' alignItems='center' justifyContent='center' maxW='571px' gridGap='48px'>
        <Image src='/assets/images/floating-balloon.svg' w='357px' h='280px' />
        <Flex flexDir='column' alignItems='center' w='100%'>
          <Text
            marginBottom='20px'
            fontWeight='600'
            fontSize='20px'
            fontFamily='Poppins'
            color='gray.800'
            textAlign='center'
            maxW='460px'
          >
            {successText}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Success
