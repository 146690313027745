import { LearningItem } from 'src/modules/cms/domain/LearningItem'

let words = require('an-array-of-portuguese-words')
let autocorrect = require('autocorrect')({ words: words })

const getMaxArraysLength = (arrays: any[]) => {
  const lengthArrays = arrays.map(array => array.length)
  return lengthArrays.sort()[0]
}

const generateSearchLearningItemsOrder = (courses: LearningItem[], articles: LearningItem[], videos: LearningItem[]) => {
  const learningItems = []
  const maxLength = getMaxArraysLength([courses, articles, videos])

  for(let index = 0; index < maxLength; index++) {
    if(courses[index]) learningItems.push(courses[index])
    if(articles[index]) learningItems.push(articles[index])
    if(videos[index]) learningItems.push(videos[index])
  }

  return learningItems
}

const autoCorrectQuery = (query: string) => {
  const brokenQuery = query.trim().split(' ')

  let autoCorrected = ''
  for (const word of brokenQuery) {
    autoCorrected += ' ' + autocorrect(word)
  }
  return autoCorrected.trim()
}

const calculateNumberOfCardsRow = (width?: number) => width ? (width >= 1700 ? 4 : 3) : 3

export { generateSearchLearningItemsOrder, autoCorrectQuery, calculateNumberOfCardsRow }