import { Partner } from 'src/types/Partner'
import { partner_access_links } from './../redirects/ActivatePartner/utils'

interface Request<T = any> {
  action: 'run-script' | 'check-partner-credentials' | 'register-credentials' | 'save-redirect-link'
  data: T
}
interface Credential {
  login: string
  password: string
  partner: Partial<Partner>
}
interface CheckPartnerResult {
  hasExtension: boolean
  partnerActivated: boolean
}
const useExtension = () => {
  const extensionId =
    process.env.NODE_ENV === 'production' ? process.env.REACT_APP_EXTENSION_ID : process.env.REACT_APP_EXTENSION_ID_DEV

  const extensionUrl = `${process.env.REACT_APP_EXTENSION_BASE_URL}${extensionId}`
  /**
   * Since this is a SPA, the extension does not recognize when a page
   * is loaded. Therefore, this function forces a content script execution on the extension
   */
  const runExtension = () => {
    try {
      chrome?.runtime?.sendMessage(extensionId!, {
        action: 'run-script',
      })
    } catch ({ message }) {
      console.warn('Browser not supported or extension is missing:  ', message, extensionId)
    }
  }
  /**
   * Check if a partner's credentials are listed in the extension
   * under the logged in user
   * @param partnerSlug
   * @returns
   */
  const checkPartner = async (partnerSlug: string) => {
    try {
      const message: Request = {
        action: 'check-partner-credentials',
        data: {
          partnerSlug,
        },
      }
      return await new Promise<CheckPartnerResult>((resolve) => {
        try {
          chrome.runtime.sendMessage(extensionId!, message, (response: { partnerActivated: boolean }) => {
            if (chrome.runtime.lastError) {
              resolve({
                hasExtension: false,
                partnerActivated: false,
              })
            } else {
              resolve({
                hasExtension: !!response,
                partnerActivated: response.partnerActivated,
              })
            }
          })
        } catch (e) {
          resolve({
            hasExtension: false,
            partnerActivated: false,
          })
        }
      })
    } catch (e) {
      return await new Promise<CheckPartnerResult>((resolve) => {
        resolve({
          hasExtension: false,
          partnerActivated: false,
        })
      })
    }
  }
  /**
   * Save newly created credentials in the extension
   * @param credentials a set of credentials to be stored
   * @returns Promise<void>
   */
  const saveCredentials = async (credentials: Credential): Promise<void> => {
    try {
      chrome?.runtime?.sendMessage(extensionId!, {
        action: 'register-credentials',
        data: credentials,
      })
    } catch ({ message }) {
      console.warn('Browser not supported or extension is missing: ', message, extensionId)
    }
  }
  /**
   * Save redirectLink in extension so it can redirect
   * the user to the course later when login is successful.
   * Also redirects user to partner's access link to login or
   * directly to the course when extension is not installed
   * @param partnerSlug slug of partner
   * @param redirectLink course link that comes from contentful as URL param
   * @param accessLink partner's access link, where the user logs in, ex: aprendeai.com/login
   * @returns
   */
  const redirectToCourse = (partnerSlug: string, redirectLink: string | null) => {
    if (!redirectLink) {
      window.location.href = partner_access_links[partnerSlug]
      return
    }
    // temporarily save in extension to redirect after login
    try {
      const message: Request = {
        action: 'save-redirect-link',
        data: { partnerSlug, redirectLink },
      }
      chrome?.runtime?.sendMessage(extensionId!, message, () => {
        // redirect to login since we already registered the course link for later
        window.location.href = partner_access_links[partnerSlug]
      })
      return
    } catch ({ message }) {
      console.warn('Browser not supported or extension is missing: ', message, extensionId)
    } finally {
      // redirect to course directly in case the user doesn't have the extension
      window.location.href = redirectLink
    }
  }
  return { runExtension, checkPartner, saveCredentials, redirectToCourse, extensionUrl }
}
export default useExtension
