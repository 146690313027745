import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleTeam } from 'src/context-admin/useHandleTeam'
import { NavigationButtons } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/NavigationButtons'
import { StepTitle } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTitle'
import { User } from 'src/types/User'
import { UserList } from '../../commonComponents/UserList'

export function EditExistingTeamChangeManagerSelectNew() {
  const [t] = useTranslation()
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined)
  const { team, navigateToStep, handleSetTeamValues, getUsersList, usersList } = useHandleTeam()

  const onSelectUser = (users: User[]) => {
    setSelectedUser(users[0])
  }

  useEffect(() => {
    getUsersList({ variables: { areaId: 0 } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedUser) {
      handleSetTeamValues({ newManagers: [selectedUser] })
    } else {
      handleSetTeamValues({ newManagers: [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser])

  return (
    <Flex flexDir='column' gap='24px'>
      <StepTitle
        title={t('selectNewAreaManager', { areaName: team?.name ?? t('invalidTeam') })}
        subtitle={t('searchAndFindNewManager')}
      />
      <UserList
        data={usersList.filter((u) => !team?.managers?.map((manager) => manager.id).includes(Number(u.id)))}
        type='select-manager'
        onSelectUsers={onSelectUser}
      />
      <NavigationButtons
        isContinueDisabled={!selectedUser}
        onContinueClick={() => {
          navigateToStep('edit-existing-team--change-manager')
        }}
        onBackClick={() => {
          navigateToStep('edit-existing-team--select-options')
        }}
      />
    </Flex>
  )
}
