import { UseCase } from 'src/types/UseCase'
import { ReportRepository } from '../repository/ReportRepository'

export type GetAreasUseCaseOutput = {
  id: number
  name: string
  createdAt: string
  updatedAt: string
  deleted_at: string
  companyId: number
  manager_id: number
}[]

export class GetAreasUseCaseUseCase implements UseCase<GetAreasUseCaseOutput, GetAreasUseCaseOutput> {
  constructor(private repository: ReportRepository) {}

  async execute(): Promise<GetAreasUseCaseOutput> {
    const report = await this.repository.getAreas()
    return report
  }
}
