import { ContentRepository } from 'src/modules/cms/repositories/ContentRepository'
import { User } from 'src/types/User'

export const enum ContentAction {
  DONE = 'DONE',
  UNDONE = 'UNDONE',
  FAVORITED = 'FAVORITE',
  UNFAVORITED = 'UNFAVORITE',
  ACCESSED = 'ACCESSED',
}

type MarkContentUseCaseInput = {
  id: string
  moduleId?: string
  playlistId?: string
  action: ContentAction
}

export type MarkContentUseCaseOutput = User | null

export class MarkContentUseCase {
  constructor(private contentRepository: ContentRepository) {}

  async execute(input: MarkContentUseCaseInput): Promise<MarkContentUseCaseOutput> {
    const userInfo = await this.contentRepository.markContent(
      input.id, 
      input.playlistId ?? '', 
      input.moduleId ?? '', 
      input.action
    )
    return userInfo
  }
}
