import { ColorProps, Flex, FormControl, Input, Select, Skeleton, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDashboard } from 'src/context-admin/useDashboard'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { debounce } from 'src/helpers-admin/debounce'
import { User } from 'src/types/User'
import { UserListCard } from '../UserListCard'

type UserListType = 'select-manager' | 'select-users' | 'remove-users'

interface UserListProps {
  type?: UserListType
  withSelectButton?: boolean
  onSelectUsers?: (selectedUsers: User[]) => void
  withFilter?: boolean
  withSearch?: boolean
  data?: User[]
  selectText?: string
  selectedText?: string
  selectedTextColor?: ColorProps['color']
  alreadySelectedUsers?: User[]
  shouldRemoveUsersFromSameArea?: boolean
}

export function UserList({
  type = 'select-users',
  withSelectButton = true,
  onSelectUsers,
  withFilter = true,
  withSearch = true,
  data,
  selectText = 'Selecionar',
  selectedText = 'Selecionado',
  selectedTextColor = 'green.500',
  alreadySelectedUsers = [],
}: UserListProps) {
  const [t] = useTranslation()
  const { usersList, loadingUsers, fetchMoreUsers, pageInfo, definesUserNameFilter, definesAreaFilter } =
    useHandleArea()
  const { areas } = useDashboard()
  const [selectedManager, setSelectedManager] = useState<User | undefined>(undefined)
  const [selectedUsers, setSelectedUsers] = useState<User[]>([])

  const userListToRender = data ?? usersList

  const handleSelectUserAsManager = (user: User) => {
    setSelectedManager((prevState) => {
      if (prevState?.email === user?.email) {
        if (onSelectUsers) onSelectUsers([])
        return undefined
      }
      if (onSelectUsers) onSelectUsers([user])
      return user
    })
  }

  const handleSelectUser = (user: User) => {
    setSelectedUsers((prevState) => {
      if (prevState.find((u) => u?.email === user?.email)) {
        const usersWithoutSelected = prevState.filter((u) => u?.email !== user?.email)
        if (onSelectUsers) onSelectUsers(usersWithoutSelected)
        return usersWithoutSelected
      } else {
        const usersWithSelected = [...prevState, user]
        if (onSelectUsers) onSelectUsers(usersWithSelected)
        return usersWithSelected
      }
    })
  }

  const renderCorrectSelectionButton = (user: User) => {
    const isUserSelected = selectedUsers.find((u) => u?.email === user?.email)
    const isManagerSelected = selectedManager?.id === user?.id

    switch (type) {
      case 'select-users':
        return (
          <Text
            textDecoration='underline'
            fontSize='0.75rem'
            fontWeight='600'
            ml='auto'
            cursor='pointer'
            color={isUserSelected ? selectedTextColor : 'gray.800'}
            onClick={() => handleSelectUser(user)}
          >
            {isUserSelected ? selectedText : selectText}
          </Text>
        )
      case 'select-manager':
        return (
          <Text
            textDecoration='underline'
            fontSize='0.75rem'
            fontWeight='600'
            ml='auto'
            cursor='pointer'
            color={isManagerSelected ? selectedTextColor : 'gray.800'}
            onClick={() => handleSelectUserAsManager(user)}
          >
            {isManagerSelected ? selectedText : selectText}
          </Text>
        )
      case 'remove-users':
        return <></>
      default:
        null
    }
  }

  const debouncedFilter = debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => definesUserNameFilter(e.target.value),
    1000,
  )

  useEffect(() => {
    const alreadySelectedUsersIds = alreadySelectedUsers.map((u) => u?.id)
    if (type === 'select-users' || type === 'remove-users') {
      setSelectedUsers(userListToRender.filter((u) => alreadySelectedUsersIds.includes(u?.id)))
    }
    if (type === 'select-manager') {
      setSelectedManager(userListToRender.find((u) => alreadySelectedUsersIds.includes(u?.id)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      definesUserNameFilter('')
      definesAreaFilter(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex flexDir='column' gap='16px' w='100%'>
      {withSearch && (
        <FormControl display='flex' flexDir='column' gap='4px'>
          <Text fontWeight='bold' fontSize='1rem'>
            {t('searchCollaborators')}
          </Text>
          <Input
            height='36px'
            px='16px'
            borderRadius='12px'
            borderWidth='2px'
            borderColor='#e5e5e5'
            placeholder='Busque por um colaborador'
            onChange={debouncedFilter}
          />
        </FormControl>
      )}
      {withFilter && (
        <Flex flexDir='column' w='125px'>
          <Text fontSize='0.875rem' color='#767F89' fontWeight='600'>
            {t('filters')}
          </Text>
          <Select
            defaultValue='empty'
            bgColor='#F7F9FA'
            border='none'
            mt='6px'
            _hover={{ bgColor: '#F7F9FA' }}
            _focus={{ bgColor: '#F7F9FA' }}
            fontWeight={600}
            fontSize='0.75rem'
            w='auto'
            height='22px'
            onChange={(e) => {
              const areaId = Number(e.target.value)
              return definesAreaFilter(areaId)
            }}
            sx={{
              '&': {
                color: '#009639 !important',
                padding: '0px 0px 0px 8px',
              },
            }}
          >
            <option key={'0'} value={'0'}>
              {t('allAreas')}
            </option>
            {areas.map((area) => (
              <option key={area.id} value={area.id}>
                {area.name}
              </option>
            ))}
          </Select>
        </Flex>
      )}
      <Flex flexDir='column' w='100%' gap='16px' maxH='220px' overflowY='auto' pr='8px'>
        {loadingUsers && (
          <>
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeleton
                key={index}
                px='16px'
                py='8px'
                minH='36px'
                bg='#F7F9FA'
                borderRadius='12px'
                boxShadow='0px 1px 6px 0px rgba(0, 0, 0, 0.10)'
              />
            ))}
          </>
        )}
        {!loadingUsers && (
          <InfiniteScroll
            dataLength={userListToRender?.length}
            next={fetchMoreUsers}
            hasMore={pageInfo?.hasNextPage!}
            style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '6px 0px' }}
            loader={
              <Text textAlign='center' display={userListToRender?.length === 0 ? 'none' : 'block'}>
                {t('loadingMore')}
              </Text>
            }
            scrollThreshold={0.9}
            scrollableTarget='scrollableDiv'
          >
            {userListToRender.map((user, index) => (
              <UserListCard
                key={index}
                data={user}
                withSelectButton={withSelectButton}
                selectButton={renderCorrectSelectionButton(user)}
              />
            ))}
          </InfiniteScroll>
        )}
      </Flex>
    </Flex>
  )
}
