import Content from 'src/modules/cms/domain/Content'
import { ContentRepository } from 'src/modules/cms/repositories/ContentRepository'

type GetContentByIdUseCaseInput = {
  id: string;
}

export type GetContentByIdUseCaseOutput = Content

export class GetContentByIdUseCase {
  constructor(private contentRepository: ContentRepository) {}

  async execute(input: GetContentByIdUseCaseInput): Promise<GetContentByIdUseCaseOutput> {
    const content = await this.contentRepository.getById(input.id)
    return content
  }
}
