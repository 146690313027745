import i18next from 'i18next'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { LearningItemProgress } from 'src/types/LearningItemProgress'

export const getLearningItemsAnalytics = (playlist: NewPlaylist) => {
  let totalLearningItems = 0
  let doneLearningItems = 0

  if (playlist && playlist?.moduleList) {
    playlist?.moduleList?.items?.forEach((module) => {
      totalLearningItems += module.contentList!.items!.length
      module.contentList?.items?.forEach((item) => {
        if (item?.progress === LearningItemProgress.DONE) doneLearningItems += 1
      })
    })
  }

  const donePercentage = Math.ceil((doneLearningItems / totalLearningItems) * 100)

  return { totalLearningItems, doneLearningItems, donePercentage }
}

export const getPlaylistTopics = (playlist: NewPlaylist) => {
  let topics = ''

  playlist.moduleList?.items?.forEach((module, index) => {
    const modulesLength = playlist.moduleList!.items!.length
    if (modulesLength > 1 && index === modulesLength - 1)
      return (topics += ` ${i18next.t('home.Custom Playlist.and')} ${module.title}`)
    if (modulesLength > 1 && index !== modulesLength - 2) return (topics += `${module.title}, `)
    topics = module.title ?? ''
  })

  return topics
}
