import { Box, Button, Icon, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { IoPlayCircle } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'

interface Props {
  title: string
  image: string
  url: string
}

export const RecentlyAccessedPlaylistCard: React.FC<Props> = ({ title, image, url }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleClick = () => {
    history.push(url)
  }

  return (
    <Box onClick={handleClick} cursor='pointer'>
      <Box
        w='100%'
        minW={{ base: '280px', sm: '464px' }}
        maxW={{ base: 'calc(100vw - 64px)', sm: '464px' }}
        h='100%'
        minH='153px'
        maxH={{ base: 'unset', md: '153px' }}
        d='flex'
        flexDir={{ base: 'column', md: 'row' }}
        borderRadius='20px'
        overflow='hidden'
        boxSizing='border-box'
        border='2px solid #E5E5E5'
        transition='all 0.1s linear'
        _hover={{
          border: '2px solid #E5E5E5',
          boxShadow: '0 14px 40px rgba(0,0,0,0.05)',
        }}
        outlineOffset='5px'
      >
        <Box
          bgImage={image}
          bgSize='cover'
          flex='1'
          bgPos={'center'}
          cursor='pointer'
          minH={{ base: '100px', lg: 'unset' }}
        />

        <Box
          flex='2'
          paddingLeft='20px'
          paddingY='16px'
          d='flex'
          flexDir='column'
          alignItems='flex-start'
          justifyContent='center'
          backgroundColor='white'
        >
          <Box flex='1'>
            <Text color='gray.800' fontWeight='400' fontSize='14px' textTransform='capitalize'>
              {t('home.recently_accessed.title')}
            </Text>
          </Box>
          <Box flex='2'>
            <Text
              fontSize='16px'
              fontWeight='700'
              color='gray.800'
              paddingRight='15px'
              paddingBottom='10px'
              textAlign={'left'}
            >
              {title}
            </Text>
          </Box>
          <Box flex='1'>
            <Button variant='lastCourseCard' marginRight='8px'>
              <Icon w='30px' h='30px' color='white' as={IoPlayCircle} />
              <Text marginLeft='8px' fontSize='16px' color='white'>
                {t('home.recently_accessed.button')}
              </Text>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
