import { PartnerRepository } from 'src/modules/cms/repositories/PartnerRepository'
import { PartnerList } from 'src/types/Cms'

export type ListPartnersUseCaseOutput = PartnerList

export class ListPartnersUseCase {
  constructor(private partnerRepository: PartnerRepository) {}

  async execute(): Promise<ListPartnersUseCaseOutput> {
    const partnerList = await this.partnerRepository.list()
    return partnerList
  }
}
