import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import OnboardCard from '../../../components-admin/onboard/Card'
import Header from '../../../components-admin/onboard/Header/index'
import { useAuthOnboard } from '../utils'
import Form from './Form'

interface LocationState {
  justVerifiedEmail: boolean
}

const Company: React.FC = () => {
  const location = useLocation<LocationState>()
  const history = useHistory()
  const [t] = useTranslation()
  const toast = useToast()
  useAuthOnboard({ stepSlug: 'company' })
  const justVerified = location?.state?.justVerifiedEmail as boolean

  useEffect(() => {
    if (justVerified) {
      toast({
        title: 'E-mail successfully verified!',
        status: 'success',
        isClosable: true,
      })
      history.replace({ state: undefined })
    }
  }, [history, location.state, toast, justVerified])

  return (
    <OnboardCard>
      <Helmet>
        <title>{t('onboard.steps.Company')} | Education Journey</title>
      </Helmet>
      <Header
        title={t('onboard.company.header.title')}
        description={t('onboard.company.header.description')}
        popoverContent={t('onboard.company.header.popoverContent')}
      />
      <Form />
    </OnboardCard>
  )
}

export default Company
