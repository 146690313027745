import { useEffect, useState } from 'react'

export interface Size {
  width: number | undefined
  height: number | undefined
}

export function useLearningCardContentSize(ref: any): Size {
  const [learningCardContentSize, setLearningCardContentSize] = useState<Size>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    if (!ref.current) return

    function handleResize() {
      if (!ref.current) return

      setLearningCardContentSize({
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      })
    }

    const observer = new ResizeObserver(handleResize)
    observer.observe(ref.current)
  }, [ref])

  return learningCardContentSize
}
