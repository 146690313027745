import { Flex, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { useUser } from 'src/context/userContext'
import { makeRenameExistingArea } from 'src/modules/dashboard/factory/makeRenameExistingArea'
import { InputWithLabel } from '../../commonComponents/InputWithLabel'
import { ManagerListView } from '../../commonComponents/ManagerListView'
import { NavigationButtons } from '../../commonComponents/NavigationButtons'
import { StepTitle } from '../../commonComponents/StepTitle'
import { StepTransition } from '../../commonComponents/StepTransition'
import { UserList } from '../../commonComponents/UserList'

export function EditExistingAreaRenamingArea() {
  const [t] = useTranslation()
  const [newAreaName, setNewAreaName] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const { navigateToStep, handleFinalMessage, area, usersList, getUsersList, pageInfo } = useHandleArea()
  const { user } = useUser()

  const renameExistingArea = makeRenameExistingArea()
  const toast = useToast()

  const handleSubmitNewAreaName = async () => {
    setLoading(true)
    try {
      if (!area?.id) throw new Error('A área selecionada é inválida. Por favor, tente novamente!')
      if (newAreaName === area?.name)
        throw new Error('Os nomes são iguais. Por favor, defina um novo nome e tente novamente!')
      const areaWasRenamed = await renameExistingArea.execute({
        areaId: Number(area?.id) ?? -1,
        newName: newAreaName,
      })
      if (!areaWasRenamed) {
        throw new Error(
          `Ocorreu um erro ao renomear a área "${area?.name}" para "${newAreaName}". Por favor, tente novamente!`,
        )
      }
      handleFinalMessage(t('areaChanged', { oldAreaName: area?.name ?? t('invalidArea'), newAreaName }))
      navigateToStep('success-screen')
    } catch (err: any) {
      toast({
        title: t('errorChangingArea'),
        description: String(err?.message),
        status: 'error',
        isClosable: true,
        duration: 7000,
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getUsersList({ variables: { areaId: Number(user?.area?.id ?? 0) } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StepTransition>
      <Flex flexDir='column'>
        <Flex flexDir='column' gap='16px'>
          <StepTitle
            title={t('renamingArea', { areaName: area?.name ?? t('invalidArea') })}
            subtitle={t('insertNewAreaName', { areaName: area?.name ?? t('invalidArea') })}
          />
          <InputWithLabel
            label={t('newAreaName')}
            placeholder={area?.name ?? t('invalidArea')}
            value={newAreaName}
            onChange={(e) => setNewAreaName(e.target.value)}
          />
          <ManagerListView />
          <Flex flexDir='column' gap='8px'>
            <Flex gap='6px'>
              <Text fontSize='1rem' fontWeight='700'>
                {t('areaMembers')}
              </Text>
              {pageInfo?.total && <Text fontSize='1rem'>({pageInfo?.total} colaboradores)</Text>}
            </Flex>
            <UserList data={usersList} withSelectButton={false} withFilter={false} withSearch={false} />
          </Flex>
        </Flex>
        <NavigationButtons
          isContinueDisabled={newAreaName.length <= 1 || newAreaName === area?.name}
          isLoading={loading}
          onContinueClick={() => {
            handleSubmitNewAreaName()
          }}
          onBackClick={() => {
            navigateToStep('edit-existing-area--select-options')
          }}
        />
      </Flex>
    </StepTransition>
  )
}
