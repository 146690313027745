import { Flex, Text, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { DeleteCustomPlaylistModal } from 'src/components/DeleteCustomPlaylistModal'

export function DeletePlaylist() {
  const [t] = useTranslation()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const handleDeletePlaylist = () => {
    onOpen()
  }

  return (
    <Flex mb='16px'>
      <Flex
        bg='#F7F9FA'
        borderRadius='8px'
        h='auto'
        p='8px 12px'
        align='flex-start'
        justify='center'
        gap='8px'
        flexDir={{ base: 'column', md: 'row' }}
      >
        <Text fontSize='0.75rem' fontWeight={700} color='red.600'>
          {t('toDeleteThisPlaylist')},
        </Text>
        <Text
          cursor='pointer'
          fontWeight='700'
          color='red.600'
          textDecoration='underline'
          fontSize='0.75rem'
          height='auto'
          onClick={handleDeletePlaylist}
          data-testid='delete-playlist-button'
          textTransform='lowercase'
        >
          {t('clickHere')}.
        </Text>
        <DeleteCustomPlaylistModal isOpen={isOpen} onClose={onClose} />
      </Flex>
    </Flex>
  )
}
