import { ApolloQueryResult, OperationVariables } from '@apollo/client'
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeDeleteContent } from 'src/modules/cms/factories/makeDeleteContent'
import { useRegisterContentStore } from 'src/pages-admin/Cms/hooks/useRegisterContentStore'
import { useRegisterPlaylistStore } from 'src/pages-admin/Cms/hooks/useRegisterPlaylistStore'
import { CmsContent } from 'src/types/Cms'
import { makeDeletePlaylist } from '../../../../modules/cms/factories/makeDeletePlaylist'
import { useCorporateUniversityStore } from '../../hooks/useCorporateUniversityStore'
import { IPlaylist } from '../../types/IPlaylist'
import { DrawerButtons } from './DrawerButtons'
import { Success } from './Success'

interface RemoveContentDrawerProps {
  isOpen: boolean
  onClose: () => void
  selectedContent?: CmsContent
  selectedPlaylist?: IPlaylist
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>
  isPlaylist?: boolean
}

export function RemoveContentDrawer({ isOpen, onClose, refetch, isPlaylist }: RemoveContentDrawerProps) {
  const [t] = useTranslation()
  const [contentRemovedSuccessfully, setContentRemovedSuccessfully] = useState(false)
  const {
    updateCmsCourses,
    updateCmsPlaylists,
    updateTotalCourses,
    updateTotalPlaylists,
    selectedContent,
    selectedPlaylist,
    updateSelectedContent,
    updateSelectedPlaylist,
    updateActiveTab,
  } = useCorporateUniversityStore()
  const { updateRegisterPlaylistModalState } = useRegisterPlaylistStore()
  const { updateRegisterModalState } = useRegisterContentStore()
  const deleteContent = makeDeleteContent()
  const deletePlaylist = makeDeletePlaylist()
  const { onToggle: onFadeToggle } = useDisclosure()
  const [loading, setLoading] = useState(false)

  const handleRemoveContent = async () => {
    try {
      setLoading(true)
      if (selectedContent) {
        await deleteContent.execute({ contentId: selectedContent?.id })
        setContentRemovedSuccessfully(true)
      } else {
        if (selectedPlaylist) {
          await deletePlaylist.execute({ playlistId: selectedPlaylist?.id })
          setContentRemovedSuccessfully(true)
        }
      }
      onFadeToggle()
    } catch (err) {
      console.error(`Ocorreu um erro ao tentar excluir ${selectedContent ? 'o conteúdo.' : 'a playlist.'}`, err)
    } finally {
      setLoading(false)
    }
  }

  const handleCloseDrawer = useCallback(async () => {
    onFadeToggle()
    setContentRemovedSuccessfully(false)
    updateSelectedContent(undefined)
    updateSelectedPlaylist(undefined)
    updateRegisterModalState(false)
    updateRegisterPlaylistModalState(false)
    onClose()

    if (selectedContent) {
      const response = await refetch()
      updateCmsCourses(response?.data.contentList?.items ?? [])
      updateTotalCourses(response?.data.contentList?.total ?? 0)
      updateActiveTab('conteudos')
    } else {
      if (selectedPlaylist) {
        const response = await refetch()
        updateCmsPlaylists(response?.data.playlistList?.items ?? [])
        updateTotalPlaylists(response?.data.playlistList?.total ?? 0)
        updateActiveTab('trilhas')
      }
    }
  }, [
    onClose,
    onFadeToggle,
    updateSelectedContent,
    refetch,
    updateCmsCourses,
    updateTotalCourses,
    updateCmsPlaylists,
    updateTotalPlaylists,
    selectedPlaylist,
    selectedContent,
    updateSelectedPlaylist,
    updateActiveTab,
    updateRegisterModalState,
    updateRegisterPlaylistModalState,
  ])

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='xl' data-testid='cms-remove-drawer-wrapper'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton data-testid='cms-remove-drawer-close-button' />
        {contentRemovedSuccessfully ? (
          <Success handleCloseDrawer={handleCloseDrawer} isPlaylist={isPlaylist} />
        ) : (
          <Box padding='88px 80px 0px'>
            <DrawerHeader fontWeight={700} fontSize='1.25rem'>
              {t('areYouSureYouWantToDelete', { title: selectedContent?.title ?? selectedPlaylist?.title })}
            </DrawerHeader>
            <DrawerBody>
              <Text color='bluishGray.500' fontWeight={600} fontSize='1.125rem'>
                {t('removeItemInfoText', { title: selectedContent?.title ?? selectedPlaylist?.title })}
              </Text>
              <Flex gap='12px' mt='24px'>
                <DrawerButtons loading={loading} handleRemoveContent={handleRemoveContent} onClose={onClose} />
              </Flex>
            </DrawerBody>
          </Box>
        )}
      </DrawerContent>
    </Drawer>
  )
}
