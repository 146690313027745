import { Button, Flex, Heading, Icon, Image, Input, Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCloseSharp } from 'react-icons/io5'
import { useLocation } from 'react-router-dom'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { useUser } from 'src/context/userContext'
import { getLearningItemsAnalytics } from 'src/modules/cms/components/CustomPlaylist/CustomPlaylistHeader/header.service'
import Content from 'src/modules/cms/domain/Content'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { makeMarkContent } from 'src/modules/cms/factories/makeMarkContent'
import { ContentAction } from 'src/modules/cms/use_cases/MarkContentUseCase'
import { User } from 'src/types/User'

interface Props {
  playlist?: NewPlaylist
  content: Content
  onClose: () => void
  onMarkAsDone: (user?: User) => void
  openEvaluateModal: () => void
  isOpen: boolean
}

const MarkCompleteModal: React.FC<Props> = ({
  playlist,
  content,
  onClose,
  onMarkAsDone,
  isOpen,
  openEvaluateModal,
}: Props) => {
  const { pathname } = useLocation()
  const { getUser } = useUser()
  const { setIsEvaluationOpen, setEvaluationSource } = useCustomPlaylist()
  const [active, setActive] = useState(false)
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const markContent = makeMarkContent()
  const { t } = useTranslation()
  const { donePercentage } = getLearningItemsAnalytics(playlist!)

  const verifyContent = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const content = e.target.value
    setError(t('playlist.I got my certificate').toLowerCase().indexOf(content.toLowerCase()) === -1)
    setActive(content.toLowerCase() === t('playlist.I got my certificate').toLowerCase())
  }

  const getPlaylistModuleId = () => {
    const contentId = content.id
    const modules = playlist?.moduleList?.items
    if (!modules) return ''
    for (const m of modules) {
      const contents = m?.contentList?.items ?? []
      const foundContent = contents?.findIndex((c) => c?.id === contentId)
      if (foundContent !== -1) return m?.id ?? ''
    }
    return ''
  }

  const handleMarkAsDone = async () => {
    setIsLoading(true)
    const moduleId = getPlaylistModuleId()
    await markContent.execute({
      id: content.id,
      playlistId: playlist?.id,
      moduleId,
      action: ContentAction.DONE,
    })
    setIsLoading(false)

    if (pathname === '/customPlaylist') {
      const allContents = playlist?.moduleList?.items?.flatMap((item) => item.contentList?.items) || []
      const contentsFinisheds = allContents.filter((item) => item?.progress === 'DONE')
      const lastContentBefore100Percent = allContents
        .slice()
        .reverse()
        .find((item) => item?.progress === 'IN_PROGRESS' && donePercentage <= 100)
      const contentBefore50Percent = allContents.find(
        (item) => item?.progress === 'IN_PROGRESS' && donePercentage <= 50,
      )

      onClose()

      if (contentsFinisheds.length === 0) {
        setEvaluationSource('first_content_finished')
        setIsEvaluationOpen(true)
      } else if (lastContentBefore100Percent) {
        setEvaluationSource('playlist_finished')
        setIsEvaluationOpen(true)
      } else if (contentBefore50Percent) {
        setEvaluationSource('playlist_half_finished')
        setIsEvaluationOpen(true)
      } else {
        openEvaluateModal()
      }
    } else {
      onClose()
      openEvaluateModal()
    }

    getUser()
    onMarkAsDone()
  }

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} autoFocus={false} scrollBehavior='outside'>
        <ModalOverlay>
          <ModalContent
            borderRadius='30px'
            w='80%'
            maxW='732px'
            textAlign='center'
            padding={{ base: '20px', md: '9px 105px 45px 105px' }}
            alignItems='center'
          >
            <Button variant='closeButton' top='17px' right='14px' onClick={() => onClose()}>
              <Icon w='28px' h='28px' as={IoCloseSharp} />
            </Button>
            <Image w='189px' marginBottom='17px' src='../../assets/images/mark-as-complete-modal.png' />

            <Heading size='sm' marginBottom='12px'>
              {t('playlist.You got the course certificate')} {content?.title}!<br /> {t('playlist.Thats right')}
            </Heading>
            <Text opacity='0.7' fontFamily='Poppins' fontSize='14px'>
              {t('playlist.To confirm write below')} <b>”{t('playlist.I got my certificate')}”</b>
            </Text>
            <Flex
              width='100%'
              marginTop='17px'
              position='relative'
              flexDir={{ base: 'column', md: 'row' }}
              border='2px solid'
              borderColor={error ? 'red.200' : 'gray.800'}
              borderRadius='16px'
              borderRightRadius='18px'
            >
              <Input
                variant='unstyled'
                borderRadius='16px'
                backgroundColor='white'
                display='flex'
                placeholder='Eu consegui o meu certificado'
                width='100%'
                fontFamily='Poppins'
                fontWeight='600'
                color={error ? 'red.200' : 'gray.800'}
                height='48px'
                onChange={verifyContent}
              />
              <Button
                display='flex'
                alignItems='center'
                justifyContent='center'
                variant='unstyled'
                height='48px'
                backgroundColor={active || isLoading ? 'gray.800' : 'gray.300'}
                color='white'
                w='100%'
                maxW={{ base: 'unset', md: '144px' }}
                borderRadius='16px'
                _focus={{
                  outline: 'none',
                  boxShadow: 'unset',
                }}
                _loading={{
                  backgroundColor: 'gray.200',
                }}
                _hover={{
                  _loading: {
                    backgroundColor: 'gray.200',
                  },
                }}
                isLoading={isLoading}
                cursor={active && !isLoading ? 'pointer' : 'default'}
                onClick={active && !isLoading ? handleMarkAsDone : undefined}
              >
                <Text fontFamily='Poppins' fontWeight='600'>
                  {t('playlist.Confirm')}
                </Text>
              </Button>
            </Flex>
            {error ? (
              <Text
                fontFamily='Poppins'
                fontSize='12px'
                color='red.200'
                marginTop='5px'
                alignSelf='self-start'
                marginLeft='19px'
              >
                {t('playlist.Please retype the sentence above')}
              </Text>
            ) : null}
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  )
}

export default MarkCompleteModal
