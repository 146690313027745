import { Box, Image } from '@chakra-ui/react'
import Content from 'src/modules/cms/domain/Content'

interface Props {
  content: Content
}

export const ContentImage: React.FC<Props> = ({ content }: Props) => (
  <Box
    minW='150px'
    w={{ base: '100%', lg: 'auto' }}
    h={{ base: '110px', lg: 'auto' }}
    minH={{ base: '110px', lg: '186px' }}
    maxH={{ base: '110px', lg: 'unset' }}
    borderRadius={{ base: '30px 30px 0 0', lg: '30px 0px 0px 30px' }}
  >
    <Image
      src={content?.imageUrl ?? 'https://i.ibb.co/fS8fCDS/dummy-300x300-009136-ffffff-education-journey.png'}
      w='100%'
      minW={{ base: '100%', lg: '150px' }}
      maxW={{ base: '100%', lg: '150px' }}
      maxH={{ base: '100%', lg: '186px' }}
      h='100%'
      objectFit='cover'
      objectPosition='center'
      borderRadius={{ base: '30px 30px 0 0', lg: '30px 0px 0px 30px' }}
    />
  </Box>
)
