import { gql } from "@apollo/client";

export const ACTIVATE_PARTNER = gql`
  mutation ActivatePartner($partnerSlug: String!) {
    ActivatePartner(partnerSlug: $partnerSlug) {
      activated
      credentials {
        login
        password
      }
    }
  }
`