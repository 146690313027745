import { HttpClient } from 'src/services/http/http-client'
import { AddTeamManagersInput, AddTeamManagersOutput } from '../use-case/AddTeamManagersUseCase'
import { AddTeamMembersInput, AddTeamMembersOutput } from '../use-case/AddTeamMembersUseCase'
import { CreateNewTeamInput, CreateNewTeamOutput } from '../use-case/CreateNewTeamUseCase'
import { DeleteTeamInput, DeleteTeamOutput } from '../use-case/DeleteTeamUseCase'
import { ListTeamsInput, ListTeamsOutput } from '../use-case/ListTeamsUseCase'
import { RemoveTeamManagersInput, RemoveTeamManagersOutput } from '../use-case/RemoveTeamManagersUseCase'
import { RemoveTeamMembersInput, RemoveTeamMembersOutput } from '../use-case/RemoveTeamMembersUseCase'
import { SwapTeamManagerInput, SwapTeamManagerOutput } from '../use-case/SwapTeamManagerUseCase'

interface ITeamRepository {
  listTeams: (params?: ListTeamsInput) => Promise<ListTeamsOutput>
  createNewTeam: (args: CreateNewTeamInput) => Promise<CreateNewTeamOutput>
  addTeamMembers: (args: AddTeamMembersInput) => Promise<AddTeamMembersOutput>
  addTeamManagers: (args: AddTeamManagersInput) => Promise<AddTeamManagersOutput>
  removeTeamMembers: (args: RemoveTeamMembersInput) => Promise<RemoveTeamMembersOutput>
  removeTeamManagers: (args: RemoveTeamManagersInput) => Promise<RemoveTeamManagersOutput>
  swapTeamManager: (args: SwapTeamManagerInput) => Promise<SwapTeamManagerOutput>
  deleteTeam: (args: DeleteTeamInput) => Promise<DeleteTeamOutput>
  downloadTeamList(): Promise<any>
}

export class TeamRepository implements ITeamRepository {
  private token: string
  private client: HttpClient

  constructor(token: string, client: HttpClient) {
    this.token = token
    this.client = client
  }

  listTeams = async (params?: ListTeamsInput): Promise<ListTeamsOutput> => {
    let searchParams = {}
    if (params) {
      params?.name && Object.assign(searchParams, { name: params.name })
      if (params?.orderBy) {
        const field = params?.orderBy?.split('_')[0]?.toLowerCase() ?? 'name'
        const direction = params?.orderBy?.split('_')[1]?.toLowerCase() ?? 'asc'
        Object.assign(searchParams, { orderBy: field, direction })
      }
    }
    try {
      const response = await this.client.request<ListTeamsOutput>({
        method: 'GET',
        url: `/v1/teams?limit=500`,
        params: new URLSearchParams(searchParams),
        headers: {
          Authorization: this.token,
        },
      })

      return response.body
    } catch {
      throw new Error(`Ocorreu um erro ao listar as áreas disponíveis. Por favor, tente novamente!`)
    }
  }

  createNewTeam = async (args: CreateNewTeamInput): Promise<CreateNewTeamOutput> => {
    const newTeam = {
      name: args.teamName,
      team_members: args.teamMembers,
    }

    try {
      const wasCreated = await this.client.request<CreateNewTeamOutput>({
        method: 'POST',
        url: '/v1/team',
        data: newTeam,
        headers: {
          Authorization: `${this.token}`,
        },
      })
      return wasCreated.body
    } catch (err: any) {
      throw new Error(`Ocorreu um erro ao criar a equipe. Por favor, tente novamente!`)
    }
  }

  addTeamMembers = async (args: AddTeamMembersInput): Promise<AddTeamMembersOutput> => {
    const { teamId, teamMembersIds } = args

    try {
      const response = await this.client.request<AddTeamMembersOutput>({
        method: 'POST',
        url: `/v1/team/${teamId}/members`,
        data: {
          ids: teamMembersIds,
        },
        headers: {
          Authorization: this.token,
        },
      })

      return response.body
    } catch {
      throw new Error(`Ocorreu um erro ao adicionar novos integrantes à equipe. Por favor, tente novamente!`)
    }
  }

  addTeamManagers = async (args: AddTeamManagersInput): Promise<AddTeamManagersOutput> => {
    const { teamId, managersIds } = args

    try {
      const response = await this.client.request<AddTeamManagersOutput>({
        method: 'POST',
        url: `/v1/team/${teamId}/managers`,
        data: {
          ids: managersIds,
        },
        headers: {
          Authorization: this.token,
        },
      })

      return response.body
    } catch (err: any) {
      if (err?.response?.data?.error?.code === 'USER_NOT_FOUND') {
        throw new Error(`O usuário não pertence à sua empresa.`)
      }
      throw new Error(`Ocorreu um erro ao adicionar novos gestores à equipe. Por favor, tente novamente!`)
    }
  }

  removeTeamMembers = async (args: RemoveTeamMembersInput): Promise<RemoveTeamMembersOutput> => {
    const { teamId, membersIds } = args

    try {
      const response = await this.client.request<RemoveTeamMembersOutput>({
        method: 'DELETE',
        url: `/v1/team/${teamId}/members`,
        data: {
          ids: membersIds,
        },
        headers: {
          Authorization: this.token,
        },
      })

      return response.body
    } catch {
      throw new Error(`Ocorreu um erro ao remover os integrantes da equipe. Por favor, tente novamente!`)
    }
  }

  removeTeamManagers = async (args: RemoveTeamManagersInput): Promise<RemoveTeamManagersOutput> => {
    const { teamId, managersIds } = args

    try {
      const response = await this.client.request<RemoveTeamManagersOutput>({
        method: 'DELETE',
        url: `/v1/team/${teamId}/managers`,
        data: {
          ids: managersIds,
        },
        headers: {
          Authorization: this.token,
        },
      })

      return response.body
    } catch {
      throw new Error(`Ocorreu um erro ao remover os gestores da equipe. Por favor, tente novamente!`)
    }
  }

  swapTeamManager = async (args: SwapTeamManagerInput): Promise<SwapTeamManagerOutput> => {
    const { teamId, prevManagerId, newManagerId } = args

    try {
      const response = await this.client.request<SwapTeamManagerOutput>({
        method: 'POST',
        url: `/v1/team/${teamId}/managers/swap`,
        data: {
          prev_manager_id: prevManagerId,
          next_manager_id: newManagerId,
        },
        headers: {
          Authorization: this.token,
        },
      })

      return response.body
    } catch {
      throw new Error(`Ocorreu um erro ao trocar os gestores da equipe. Por favor, tente novamente!`)
    }
  }

  deleteTeam = async (args: DeleteTeamInput): Promise<DeleteTeamOutput> => {
    const { teamId } = args

    try {
      const response = await this.client.request<RemoveTeamManagersOutput>({
        method: 'DELETE',
        url: `/v1/team/${teamId}`,
        headers: {
          Authorization: this.token,
        },
      })

      return response.body
    } catch {
      throw new Error(`Ocorreu um erro ao deletar a equipe. Por favor, tente novamente!`)
    }
  }

  async downloadTeamList(): Promise<any> {
    try {
      const response = await this.client.request<any>({
        url: '',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      return response.body
    } catch {
      throw new Error('Ocorreu um erro ao realizar o download da lista de times.')
    }
  }
}
