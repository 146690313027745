import { Flex, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { NavigationButtons } from '../../commonComponents/NavigationButtons'
import { StepTitle } from '../../commonComponents/StepTitle'
import { StepTransition } from '../../commonComponents/StepTransition'
import { UserListCard } from '../../commonComponents/UserListCard'
import { UserListView } from '../../commonComponents/UserListView'

export function EditExistingAreaConfirmAssignmentChangeManager() {
  const [t] = useTranslation()
  const { area, navigateToStep, usersList, getUsersList, loadingUsers } = useHandleArea()

  useEffect(() => {
    getUsersList({ variables: { areaId: Number(area?.id) } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StepTransition>
      <Flex flexDir='column' gap='20px'>
        <StepTitle title={t('confirmationOfAssignments')} />
        <Flex flexDir='column' gap='4px'>
          <Text fontWeight='700' fontSize='1rem' color='#313E4C'>
            {t('currentAreaManager', { areaName: area?.name ?? '' })}
          </Text>
          <UserListCard data={area?.manager!} bg='#fff' color='#313E4C' />
        </Flex>
        <Flex flexDir='column' gap='4px'>
          <Text fontWeight='700' fontSize='1rem' color='#313E4C'>
            {t('newAreaManager', { areaName: area?.name ?? '' })}
          </Text>
          <UserListCard data={area?.newManager!} />
        </Flex>
        <UserListView data={usersList.filter((u) => u.id !== area?.newManager?.id)} loading={loadingUsers} />
        <NavigationButtons
          onContinueClick={() => {
            navigateToStep('edit-existing-area--confirm-change-manager')
          }}
          onBackClick={() => {
            navigateToStep('edit-existing-area--change-manager')
          }}
        />
      </Flex>
    </StepTransition>
  )
}
