import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import EvaluateModal from 'src/components/EvaluateModal'
import { useUser } from 'src/context/userContext'
import Content from 'src/modules/cms/domain/Content'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { Area } from 'src/types/Area'

type UserInfo = {
  name: string
  email: string
  company: string | undefined
  area: string
}

type EvaluationState = {
  step: number
  source: string
}

interface Value {
  shouldBeVisible: boolean
  evaluateState: EvaluationState
  updateStep: (step: number) => void
  close: () => void
  openEvaluation: (params: EvaluationState) => void
  updatePlaylistInfo: (values: NewPlaylist) => void
  updateCourseInfo: (values: Content) => void
  userInfo: UserInfo
  playlistInfo: NewPlaylist
  contentInfo: Content
}

interface Props {
  children: React.ReactNode[] | React.ReactNode
}

const EvaluateContext = createContext({} as Value)

export function EvaluateProvider({ children }: Props) {
  const { user } = useUser()

  const [shouldBeVisible, setShouldBeVisible] = useState(false)
  const [evaluateState, setEvaluateState] = useState({} as EvaluationState)
  const [userInfo, setUserInfo] = useState({} as UserInfo)
  const [playlistInfo, setPlaylistInfo] = useState({} as NewPlaylist)
  const [contentInfo, setContentInfo] = useState({} as Content)

  useEffect(() => {
    let area = ''

    user?.company?.areas.forEach((item: Area) => {
      if (item.id.toString() === user.area.id?.toString()) area = item.name
    })

    if (user) {
      setUserInfo({
        name: user.name,
        email: user.email,
        company: user.company?.name,
        area,
      })
    }
  }, [user])

  const updateStep = (step: number) => {
    setEvaluateState((prevState) => ({ ...prevState, step }))
  }

  const close = useCallback(() => {
    setShouldBeVisible(false)
  }, [])

  const openEvaluation = useCallback(({ step, source }: EvaluationState) => {
    setEvaluateState({ step, source })
    setShouldBeVisible(true)
  }, [])

  const updatePlaylistInfo = useCallback((values: NewPlaylist) => {
    setPlaylistInfo(values)
  }, [])

  const updateCourseInfo = useCallback((values: Content) => {
    setContentInfo(values)
  }, [])

  return (
    <EvaluateContext.Provider
      value={{
        shouldBeVisible,
        evaluateState,
        updateStep,
        close,
        openEvaluation,
        userInfo,
        updatePlaylistInfo,
        playlistInfo,
        updateCourseInfo,
        contentInfo,
      }}
    >
      {children}
       {shouldBeVisible && <EvaluateModal onClose={close} isOpen={shouldBeVisible} />}
    </EvaluateContext.Provider>
  )
}

export const useEvaluate = () => useContext(EvaluateContext)
