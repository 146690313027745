import { ContentType } from 'src/modules/cms/domain/LearningItem'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { LooseObject } from 'src/types/LooseObject'

const getPlaylistPartnerLogos = (playlist: NewPlaylist) => {
  const playlistPartnerSquareLogos: string[] = []
  const playlistPartnerLogos: string[] = []

  playlist?.moduleList?.items?.forEach((moduleItem) => {
    moduleItem.contentList?.items?.forEach((content) => {
      const isInArray = playlistPartnerLogos.indexOf(content.partner?.logoUrl!) !== -1

      if (!isInArray && [ContentType.COURSE, 'CourseDto'].includes(content.type)) {
        playlistPartnerSquareLogos.push(content.partner?.squareLogoUrl!)
        playlistPartnerLogos.push(content.partner?.logoUrl!)
      }
    })
  })

  return { playlistPartnerLogos, playlistPartnerSquareLogos }
}

const getPlaylistPartnerNames = (playlist: NewPlaylist) => {
  const playlistPartnerNames: string[] = []

  playlist?.moduleList?.items?.forEach((moduleItem) => {
    moduleItem.contentList?.items?.forEach((content) => {
      const isInArray = playlistPartnerNames.indexOf(content.partner?.logoUrl!) !== -1

      if (!isInArray && [ContentType.COURSE, 'CourseDto'].includes(content.type)) {
        playlistPartnerNames.push(content.partner?.name!)
      }
    })
  })

  return playlistPartnerNames
}

const getPlaylistTags = (playlist: NewPlaylist, language?: string) => {
  const tags: LooseObject = {}

  playlist?.moduleList?.items?.forEach((moduleItem) => {
    moduleItem.contentList?.items?.forEach((content) => {
      content.skills
        .map((item) => {
          return item.translations?.find((translation) => translation.language === language)?.name ?? item.name
        })
        .forEach((skill) => {
          skill = skill.toLowerCase()
          const isInArray = Object.getOwnPropertyNames(tags).indexOf(skill) !== -1
          if (isInArray) {
            tags[skill] += 1
            return
          }
          tags[skill] = 1
        })
    })
  })

  const tagsSorted = Object.keys(tags)
    .sort((a: string, b: string) => {
      return tags[a] - tags[b]
    })
    .reverse()

  return tagsSorted.slice(0, 5)
}

const getPlaylistConclusionTime = (playlist: NewPlaylist) => {
  const totalDurationInSeconds = playlist?.moduleList?.items?.reduce((acc, module) => {
    const moduleDuration = module.contentList?.items?.reduce((acc, item) => {
      return acc + item.duration!
    }, 0)

    return acc + moduleDuration!
  }, 0)

  const totalDurationInMinutes = totalDurationInSeconds! / 60

  return totalDurationInMinutes < 60
    ? `${Math.ceil(totalDurationInMinutes).toFixed(0)}min`
    : `${Math.floor(totalDurationInMinutes / 60).toFixed(0)}h`
}

const getNumberOfContentsDone = (playlist: NewPlaylist) => {
  const sumProgress = playlist?.moduleList?.items?.reduce((acc, module) => {
    const moduleProgress = module.contentList?.items?.reduce((moduleAcc, item) => {
      if (item.progress === 'DONE') {
        return moduleAcc + 1
      }
      return moduleAcc
    }, 0)

    return acc + moduleProgress!
  }, 0)

  return sumProgress
}

const getNextPlaylistContent = (playlist: NewPlaylist) => {
  let content = playlist?.moduleList?.items?.[0].contentList?.items?.[0]
  let lastCourse = playlist?.moduleList?.items?.[0].contentList?.items?.[0]

  playlist?.moduleList?.items?.forEach((moduleItem) => {
    moduleItem.contentList?.items?.forEach((item) => {
      if (item?.progress === 'DOING') {
        content = item
      }
      if (lastCourse?.progress === 'DONE' && item?.progress === 'TODO') {
        content = item
      }
      lastCourse = item
    })
  })

  return content
}

const scrollToPositionY = (y = 0, selector = '') => {
  document?.querySelector(`#layout-screen ${selector}`)?.scrollTo({ top: y, behavior: 'smooth' })
}

export {
  getPlaylistPartnerLogos,
  getPlaylistTags,
  getPlaylistConclusionTime,
  getNumberOfContentsDone,
  getNextPlaylistContent,
  getPlaylistPartnerNames,
  scrollToPositionY,
}
