import { Box, Flex, Icon, Image, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { IoReturnUpBack } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import AddExtension from '../Components/AddExtension'
import ItemInBetween from '../Components/ItemInBetween'

const Fluency: React.FC = () => {
  const [t] = useTranslation()
  return (
    <Stack w='100%' h='100%' flexDirection='row' justifyContent='center' bg='gray.25'>
      {/* Extension Side */}
      <AddExtension />
      <ItemInBetween />

      {/* Credentials Side */}
      <Stack
        h='100%'
        w={['100%', '100%', '100%', '50%', '50%']}
        flexDirection='column'
        justifyContent={['center', 'center', 'center', 'flex-start', 'flex-start']}
        alignItems='center'
        paddingTop={['0px', '0px', '0px', '62px', '62px']}
      >
        <Text
          lineHeight={['10px', '10px', '10px', '30px', '30px']}
          fontFamily='Poppins'
          fontSize={['18px', '18px', '20px', '28px', '28px']}
          fontWeight='extrabold'
        >
          {' '}
          {t('activatePartner.edtechs.fluency.Verify your e-mail!')}
        </Text>
        <Box bg='primaryColors.400' w='137px' h='2px' display={['none', 'none', 'none', 'flex', 'flex']} />

        <Text
          paddingTop={['10px', '10px', '10px', '35px', '35px']}
          display={['none', 'none', 'none', 'flex', 'flex']}
          paddingBottom='10px'
          fontFamily='Mulish'
          w='80%'
          textAlign='center'
          fontSize='14px'
          fontWeight='regular'
        >
          {' '}
          {t('activatePartner.edtechs.fluency.description')}
        </Text>
        <Flex paddingY={['10px', '10px', '10px', '28px', '28px']}>
          <Image src='/assets/images/fluency-logo.svg' w='130px' />
          <Image w='142px' marginLeft='40px' src='/assets/images/mail-fluency.svg' />
        </Flex>
        <Text
          paddingTop={['10px', '10px', '10px', '35px', '35px']}
          display={['flex', 'flex', 'flex', 'none', 'none']}
          paddingBottom='10px'
          fontFamily='Mulish'
          w='80%'
          textAlign='center'
          fontSize='14px'
          fontWeight='regular'
        >
          {' '}
          {t('activatePartner.edtechs.fluency.description')}
        </Text>
        <Link to='/home'>
          <Flex
            borderRadius='30px'
            _hover={{ textDecoration: 'none' }}
            paddingY={['4px', '4px', '12px', '12px', '12px']}
            bg='gray.800'
            textDecoration='none'
            fontFamily='Mulish'
            paddingX='20px'
            fontWeight='bold'
            fontSize='16px'
            color='white'
            textAlign='center'
          >
            <Icon fontSize='22px' marginRight='10px' as={IoReturnUpBack} />
            {t('activatePartner.edtechs.fluency.button')}
          </Flex>
        </Link>
      </Stack>
    </Stack>
  )
}

export default Fluency
