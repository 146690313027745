import { Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface AccessSpreadshetMessageProps {
  message?: string
  onClick: () => void
}

export function AccessSpreadshetMessage({ message, onClick }: AccessSpreadshetMessageProps) {
  const [t] = useTranslation()

  return (
    <Text
      display='flex'
      bg='#F7F9FA'
      borderRadius='8px'
      px='12px'
      py='8px'
      gap='4px'
      fontSize='0.75rem'
      fontWeight='bold'
      color='green.500'
      my='24px'
    >
      {message ?? t('defineMembersOfAnArea')}
      <Text cursor='pointer' textDecoration='underline' onClick={onClick}>
        {t('clickHere')}
      </Text>
    </Text>
  )
}
