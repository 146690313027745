import { Icon, Tab, TabList, Text, VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { IoPersonAddOutline, IoPersonRemoveOutline } from 'react-icons/io5'
import { TbUserEdit } from 'react-icons/tb'

const tabs = [
  {
    id: 'add-employees',
    title: (t: any) => t('admin.home.updateEmployees.add'),
    icon: IoPersonAddOutline,
  },
  {
    id: 'update-employees',
    title: (t: any) => t('admin.home.updateEmployees.update'),
    icon: TbUserEdit,
  },
  {
    id: 'remove-employees',
    title: (t: any) => t('admin.home.updateEmployees.remove'),
    icon: IoPersonRemoveOutline,
  },
]

interface TabOptionsProps {
  onTabClick: () => void
}

const TabOptions = ({ onTabClick }: TabOptionsProps) => {
  const [t] = useTranslation()

  return (
    <TabList mb='1em' bg='#E5E5E5'>
      {tabs.map((tab) => (
        <Tab key={tab.id} _selected={{ color: '#009028', bg: 'white' }} bg='#E5E5E5' onClick={onTabClick}>
          <VStack w='100%'>
            <Icon as={tab.icon} />
            <Text fontSize='14px' fontWeight='bold' fontFamily='Mulish'>
              {tab.title(t)}
            </Text>
          </VStack>
        </Tab>
      ))}
    </TabList>
  )
}

export default TabOptions
