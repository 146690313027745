import {
  Button,
  Flex,
  Input as ChakraInput,
  InputProps,
  Select as ChakraSelect,
  SelectProps,
  Skeleton as ChakraSkeleton,
  Text,
  useToast,
} from '@chakra-ui/react'
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHomeAccess } from 'src/context-admin/Home/AdminHomeAccessContext'
import { useUser } from 'src/context/userContext'
import { isEmailValid } from 'src/helpers/verifyEmail'
import { Team } from 'src/modules/dashboard/entities/Team'
import { makeListAreas } from 'src/modules/dashboard/factory/makeListAreas'
import { makeListTeams } from 'src/modules/dashboard/factory/makeListTeams'
import { makeImportUsers } from 'src/modules/iam/use-case/ImportUserUseCase'
import { useSingleEmployeeFormOptions } from '../hooks/useSingleEmployee'
import { FormValues } from '../types/Form'
import { blankFormValues, generateObjectToRegisterUser } from '../utils'

interface CommonInputProps {
  label: string
  isRequired?: boolean
  isLoading?: boolean
}

const Label = ({ children }: { children: ReactNode }) => (
  <Text fontFamily='Mulish' fontWeight='semibold' fontSize='14px'>
    {children}
  </Text>
)

const Input = ({ ...props }: InputProps) => <ChakraInput {...props} />
const Select = ({ ...props }: SelectProps) => <ChakraSelect {...props} />
const Skeleton = () => <ChakraSkeleton h='32px' w='full' borderRadius='12px' />

const InputWithLabel = ({ label, isRequired, isLoading, ...props }: CommonInputProps & InputProps) => (
  <Flex flexDir='column' gap='2px'>
    <Label>
      {label} {isRequired && <strong style={{ color: '#D44333' }}>*</strong>}
    </Label>
    {isLoading && <Skeleton />}
    {!isLoading && <Input padding='0px 8px' m='0' height='32px' borderRadius='12px' fontSize='0.875rem' {...props} />}
  </Flex>
)

const SelectWithLabel = ({ label, isRequired, isLoading, ...props }: CommonInputProps & SelectProps) => (
  <Flex flexDir='column' gap='2px'>
    <Label>
      {label} {isRequired && <strong style={{ color: '#D44333' }}>*</strong>}
    </Label>
    {isLoading && <Skeleton />}
    {!isLoading && (
      <Select m='0' height='32px' borderRadius='12px' fontSize='0.875rem' {...props}>
        {props.children}
      </Select>
    )}
  </Flex>
)

interface AddSingleEmployeeProps {
  onClose: () => void
}

interface FormComplements {
  areas: { id: number; name: string; managerId: number }[]
  teams: Team[]
  permissions: any
}

export function AddSingleEmployee({ onClose }: AddSingleEmployeeProps) {
  const [loadingInfos, setLoadingInfos] = useState({ areas: true, teams: true, submit: false })
  const [formValues, setFormValues] = useState<FormValues>(blankFormValues)
  const [formComplements, setFormComplements] = useState<FormComplements>({
    areas: [],
    teams: [],
    permissions: [],
  })

  const formOptions = useSingleEmployeeFormOptions({ areas: formComplements.areas, teams: formComplements.teams })
  const { user } = useUser()
  const [t] = useTranslation()
  const toast = useToast()
  const { fetchUsers, first } = useHomeAccess()

  const shouldRenderSelectInput = (id: keyof FormValues) =>
    (['area', 'team', 'language', 'isManager', 'status', 'gender'] as Partial<keyof FormValues>[]).includes(id)

  const requiredFields = formOptions?.filter((opt) => opt?.isRequired).map((opt) => opt.id) ?? []

  const listAreas = async () => {
    try {
      const list = makeListAreas()
      const areas = await list.execute({ limit: 500 })
      setFormComplements((prev) => ({ ...prev, areas: areas.items }))
    } catch {
      console.error('Error on list areas')
    } finally {
      setLoadingInfos((prev) => ({ ...prev, areas: false }))
    }
  }

  const listTeams = async () => {
    try {
      const list = makeListTeams()
      const teams = await list.execute({})
      setFormComplements((prev) => ({ ...prev, teams }))
    } catch {
      console.error('Error on list teams')
    } finally {
      setLoadingInfos((prev) => ({ ...prev, teams: false }))
    }
  }

  const verifyIfRequiredFieldsAreFilled = () => {
    const _isEmailValid = isEmailValid(formValues.email ?? '')
    return requiredFields.every((field) => formValues[field] !== '') && _isEmailValid
  }

  const handleSubmitForm = async () => {
    try {
      setLoadingInfos((prev) => ({ ...prev, submit: true }))
      const userToRegister = generateObjectToRegisterUser(formValues)
      const importUsers = makeImportUsers()
      const importedUsers = await importUsers.execute([userToRegister])
      if (importedUsers?.errors && importedUsers?.errors?.length > 0) {
        toast({
          title: t('errorWhileAddSingleCollab'),
          description: t('anErrorOccurWhileAddSingleCollab'),
          status: 'error',
          isClosable: true,
          duration: 7000,
        })
        return
      }
      if (importedUsers?.users && importedUsers?.users?.length <= 0) {
        toast({
          title: t('userAlreadyExists'),
          status: 'warning',
          isClosable: true,
          duration: 7000,
        })
        return
      }
      toast({
        title: t('collabAddedSuccessfully'),
        status: 'success',
        isClosable: true,
        duration: 5000,
      })
      fetchUsers({ limit: first })
      onClose()
    } catch {
      toast({
        title: t('errorWhileAddSingleCollab'),
        description: t('anErrorOccurWhileAddSingleCollab'),
        status: 'error',
        isClosable: true,
        duration: 7000,
      })
      console.error('Error on submit form')
    } finally {
      setLoadingInfos((prev) => ({ ...prev, submit: false }))
    }
  }

  useEffect(() => {
    if (user) {
      listAreas()
      listTeams()
    }
  }, [user])

  useEffect(() => {
    return () => {
      setFormValues(blankFormValues)
      setLoadingInfos({ areas: true, teams: true, submit: false })
    }
  }, [])

  return (
    <Flex flexDirection='column'>
      <Text fontWeight={700} fontSize='1.125rem'>
        {t('manualAddition')}
      </Text>
      <Flex flexDir='column' gap='8px' mt='8px' maxH='500px' pr='4px' overflowY='auto' overflowX='hidden'>
        {formOptions.map((props) => {
          if (shouldRenderSelectInput(props.id)) {
            return (
              <SelectWithLabel
                isLoading={props.id === 'area' && loadingInfos.areas}
                label={props.label}
                isRequired={props.isRequired}
                onChange={(e) => setFormValues((prev) => ({ ...prev, [props.id]: e.target.value }))}
                defaultValue={props.defaultValue}
              >
                <option value='' disabled></option>
                {props?.options?.map((opt) => (
                  <option key={opt.value} value={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </SelectWithLabel>
            )
          }

          return (
            <InputWithLabel
              label={props.label}
              isRequired={props.isRequired}
              placeholder={props.placeholder}
              value={formValues[props.id]}
              maxLength={props.maxLength}
              onChange={(e) => {
                if (props?.formatFunction) {
                  const value = props.formatFunction(e.target.value)
                  setFormValues((prev) => ({ ...prev, [props.id]: value }))
                  return
                }
                setFormValues((prev) => ({ ...prev, [props.id]: e.target.value }))
              }}
            />
          )
        })}
      </Flex>
      <Flex alignItems='center' justify='space-between' mt='16px'>
        <Button variant='ghost' fontSize='0.875rem' py='0' px='8px' onClick={onClose}>
          {t('close')}
        </Button>
        <Button
          variant='startCourseDark'
          fontSize='0.875rem'
          py='0'
          px='8px'
          onClick={handleSubmitForm}
          isDisabled={!verifyIfRequiredFieldsAreFilled()}
          isLoading={loadingInfos.submit}
        >
          {t('addCollab')}
        </Button>
      </Flex>
    </Flex>
  )
}
