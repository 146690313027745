import { Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface Props {
  closeText: string | undefined
  onClick?: () => void
}

const Close: React.FC<Props> = ({ onClick, closeText }: Props) => {
  const [t] = useTranslation()
  return (
    <Text
      color='#767F89'
      textDecorationLine='underline'
      fontFamily='Poppins'
      fontWeight='600'
      marginBottom='22px'
      cursor='pointer'
      onClick={onClick}
    >
      {closeText || t('common.drawer.close')}
    </Text>
  )
}

export default Close
