import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isValidEmail } from '../../../helpers-admin/account'
// // import '../../../i18n'
import { ListEmployees } from './Form'

export const useValidateEmployees = (listEmployees: ListEmployees) => {
  const [t] = useTranslation()
  const [errors, setErrors] = useState<Array<string>>([])

  useEffect(() => {
    setErrors([]) // clean
    if (!listEmployees.length) return
    listEmployees.forEach((employee, index) => {
      const line = index + 2 // skip header and index 0
      // Email
      if (!isValidEmail(employee.email)) {
        setErrors((errors) => [
          ...errors,
          t('bulkAdditionEmployee.errors.invalidEmail', {
            name: employee.name,
            line,
          }),
        ])
      }
      // Name
      if (employee?.name && employee?.name?.length < 2) {
        setErrors((errors) => [
          ...errors,
          t('bulkAdditionEmployee.errors.invalidName', {
            line,
          }),
        ])
      }
    })
  }, [listEmployees, t])

  return errors
}
