import { PlaylistRepository } from '../repositories/PlaylistRepository'

type SavePlaylistSurveyUseCaseInput = {
  surveyId: number
  optionId: number
}

export type SavePlaylistSurveyUseCaseOutput = boolean

export class SavePlaylistSurveyUseCase {
  constructor(private playlistRepository: PlaylistRepository) {}

  async execute(input: SavePlaylistSurveyUseCaseInput): Promise<SavePlaylistSurveyUseCaseOutput> {
    const hasSavedSurvey = await this.playlistRepository.savePlaylistSurvey(input.surveyId, input.optionId)
    return hasSavedSurvey
  }
}
