import { Button, Flex, Icon } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { DownloadModelIcon } from 'src/theme/Icons'
import { User } from 'src/types/User'
import { DragArea } from '../../commonComponents/DragArea'
import { NavigationButtons } from '../../commonComponents/NavigationButtons'
import { StepTitle } from '../../commonComponents/StepTitle'
import { StepTransition } from '../../commonComponents/StepTransition'

export function EditExistingAreaAddUsersFile() {
  const [t] = useTranslation()
  const { area, handleSetAreaValues, navigateToStep } = useHandleArea()

  const handleLoadUsersFromCsv = (usersFromCsv: { Email: string; Nome: string }[]) => {
    handleSetAreaValues({ newUsers: usersFromCsv.map((item) => ({ email: item.Email, name: item.Nome })) as User[] })
  }

  return (
    <StepTransition>
      <Flex flexDir='column'>
        <StepTitle
          title={t('useSpreadsheetToAddNewAreaMembers', { areaName: area?.name ?? t('invalidArea') })}
          subtitle={t('addAreaMembersFaster')}
        />
        <Flex my='32px'>
          <Button
            as='a'
            href='/assets/files/add-users-to-area.csv'
            display='flex'
            alignItems='center'
            gap='4px'
            variant='startCourseDark'
            fontSize='1rem'
            fontWeight='600'
          >
            <Icon as={DownloadModelIcon} w='24px' h='24px' />
            {t('downloadTemplate')}
          </Button>
        </Flex>
        <DragArea type='add-new-area-members' onLoadUsersFromCsv={handleLoadUsersFromCsv} />
      </Flex>
      <NavigationButtons
        onContinueClick={() => {
          navigateToStep('edit-existing-area--confirm-add-users')
        }}
        onBackClick={() => {
          handleSetAreaValues({ newUsers: [] })
          navigateToStep('edit-existing-area--add-users')
        }}
        isContinueDisabled={area?.newUsers ? area?.newUsers?.length <= 0 : true}
      />
    </StepTransition>
  )
}
