import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleTeam } from 'src/context-admin/useHandleTeam'
import { AvailableStepsType } from 'src/types/HandleTeamDrawer'
import OrderSelect from '../Filters/components/OrderSelect'
import SearchInput from '../Filters/components/SearchInput'
import { HandleTeamDrawer } from './components/HandleTeamDrawer'
import TeamsTable from './components/TeamsTable'

const Teams = () => {
  const [t] = useTranslation()
  const {
    onOpenHandleTeamDrawer,
    navigateToStep,
    getTeams,
    teams,
    definesTeamNameFilter,
    definesOrderFilter,
    filters,
  } = useHandleTeam()

  const handleOpenDrawer = (step: AvailableStepsType) => {
    navigateToStep(step)
    onOpenHandleTeamDrawer()
  }

  const downloadTeamList = () => {
    // const download = makeDownloadTeamList()
    // try {
    //   const response = download.execute()
    //   console.log({ response })
    // } catch (err) {
    //   console.error(err)
    // }
  }

  useEffect(() => {
    getTeams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Stack spacing={12} mt='40px !important'>
        <Flex direction='column' w='100%' justifyContent='space-between'>
          <Flex align='center' justify='space-between' mb='25px'>
            <Text fontFamily='Poppins' fontWeight='semibold' fontSize='24px'>
              {t('teamControl')}
            </Text>
            {/* <Text
              cursor='pointer'
              fontSize='0.75rem'
              fontFamily='Poppins'
              fontWeight='600'
              textDecoration='underline'
              color='#313E4C'
              onClick={downloadTeamList}
            >
              Exportar base de equipes
            </Text> */}
          </Flex>
          <Flex
            align='center'
            justify='space-between'
            h='68px'
            boxShadow='0px 1px 10px rgba(0, 0, 0, 0.1)'
            borderRadius='16px'
            p='16px 24px 16px 31px'
            mb='12px'
          >
            <Flex align='center' gridGap='53px'>
              <Text fontWeight={700} fontSize='18px'>
                {t('teams')}
              </Text>
            </Flex>

            <Flex gap='8px'>
              <Button
                variant='whiteOutline'
                borderColor='gray.800'
                onClick={() => handleOpenDrawer('edit-existing-team')}
              >
                <Text fontFamily='Poppins' fontWeight='bold' fontSize='12px'>
                  {t('editExistingTeams')}
                </Text>
              </Button>
              <Button variant='solidGray' onClick={() => handleOpenDrawer('add-new-team')}>
                <Text fontFamily='Poppins' fontWeight='bold' fontSize='12px'>
                  {t('createNewTeam')}
                </Text>
              </Button>
            </Flex>
          </Flex>

          <SearchInput title={t('teams')} placeholder={t('searchATeam')} handleSearchChange={definesTeamNameFilter} />

          <Box mt='8px'>
            <Text color='#767F89' fontSize='14px' fontWeight={600} mb='4px'>
              {t('filters')}
            </Text>
            <OrderSelect order={filters?.order ?? ''} handleOrderChange={definesOrderFilter} />
          </Box>

          <Box mt='15px'>
            <TeamsTable />
          </Box>
        </Flex>
      </Stack>

      <HandleTeamDrawer />
    </>
  )
}

export default Teams
