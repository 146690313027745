import { useCallback } from 'react'
import { verifyIdIsUuid } from 'src/helpers/testUuid'
import Content from 'src/modules/cms/domain/Content'
import { makeGetContentById } from 'src/modules/cms/factories/makeGetContentById'
import { makeListContents } from 'src/modules/cms/factories/makeListContents'
import { getLanguageFromStorage } from 'src/modules/internationalization/helpers/handleStorage'
import { ContentList } from 'src/types/Cms'
import { User } from 'src/types/User'
import { makeListLastAccessedContents } from '../factories/makeListLastAccessedContents'

type Pagination = {
  limit: number
  skip: number
}

type Filter = {
  where?: any
  skills?: any[]
  order?: any
  status?: string
}

type ListContentsOutput = {
  totalContents: number
  contents: Content[]
}

export function useContents() {
  const contentList = makeListContents()
  const lastContentList = makeListLastAccessedContents()

  const formatContentWithMultiLanguageInfo = (content: Content, user: User): Content => {
    const userLanguageFromStorage = getLanguageFromStorage()
    const contentLanguageInformation = content?.translations?.find(
      (translation) =>
        translation.language === (userLanguageFromStorage ?? user?.language) && translation.content_id === content.id,
    )

    const titleByUserLanguage = contentLanguageInformation?.title ?? content.title
    const descriptionByUserLanguage = contentLanguageInformation?.description ?? content.description

    return {
      ...content,
      title: titleByUserLanguage,
      description: descriptionByUserLanguage,
    }
  }

  const formatContentWithProgressAndFavorited = useCallback((content: Content, user: User): Content => {
    const accessedContent =
      user?.contentInfo.accessed.find((item) => item.entryId === content.contentfulId || item.entryId === content.id)
        ?.progress ?? ''
    const completedContent = user?.contentInfo.completed.find(
      (item) => item.entryId === content.contentfulId || item.entryId === content.id,
    )
    const favoritedContent =
      user?.contentInfo.favorites.includes(content.id) || user?.contentInfo.favorites.includes(content?.contentfulId!)

    const contentWithCorrectUserLanguage = formatContentWithMultiLanguageInfo(content, user)

    return {
      ...contentWithCorrectUserLanguage,
      progress: completedContent?.entryId ? 'DONE' : accessedContent ? accessedContent : 'TODO',
      favorited: favoritedContent,
    }
  }, [])

  const formatContentList = useCallback(
    (list: Content[], user: User) => {
      return list.map((item) => formatContentWithProgressAndFavorited(item, user!))
    },
    [formatContentWithProgressAndFavorited],
  )

  const listContents = useCallback(
    async (user: User, pagination: Pagination, filter?: Filter): Promise<ListContentsOutput> => {
      const data = await contentList.execute({ ...pagination, ...filter })

      const updatedList = formatContentList(data.items, user)

      return {
        totalContents: data.total,
        contents: updatedList,
      }
    },
    [formatContentList, contentList],
  )

  const listLastContentsAccessedByUser = async (user: User, limit = 20, skip = 0): Promise<{
    total: number,
    items: Content[],
  }> => {
    const ids = user?.contentInfo?.accessed?.map((item) => item.entryId)
    if (!ids || ids.length <= 0) return {
      total: 0,
      items: [],
    }

    const data = await lastContentList.execute({ ids, limit: limit, skip: skip })

    const updatedList = formatContentList(data.items ?? [], user).sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id)).reverse();

    return {
      total: data.total,
      items: updatedList,
    }
  }

  const listFinishedContentsByUser = async (user: User) => {
    const ids = user?.contentInfo?.completed?.map((item) => item.entryId)
    if (!ids || ids.length <= 0) return []

    const data = await lastContentList.execute({ ids, limit: 200, skip: 0 })

    const updatedList = formatContentList(data.items ?? [], user)
    return updatedList
  }

  const getContentById = useCallback(
    async (id: string, user: User): Promise<Content | undefined> => {
      const contentById = makeGetContentById()

      const content = await contentById.execute({ id })
      const updatedContent = formatContentWithProgressAndFavorited(content, user)

      return updatedContent
    },
    [formatContentWithProgressAndFavorited],
  )

  const getFavoritedContents = useCallback(
    async (pagination: Pagination, user: User): Promise<ContentList> => {
      const filteredUserFavoritesByUuid = user?.contentInfo.favorites.filter((id) => verifyIdIsUuid(id))
      const data = await contentList.execute({
        ...pagination,
        contentIds: filteredUserFavoritesByUuid,
      })
      const updatedList = formatContentList(data.items, user)

      return {
        total: filteredUserFavoritesByUuid?.length ?? 0,
        items: updatedList.filter((content) => content.favorited),
      }
    },
    [formatContentList, contentList],
  )

  const getRecentlyAccessedContents = useCallback(
    async (user: User): Promise<Content[]> => {
      const accessedContentsIds = user?.contentInfo.accessed
        .filter((item) => item.progress === 'DOING')
        .map((content) => content.entryId)
      const deduplicatedIds = Array.from(new Set(accessedContentsIds)).slice(0, 20)

      const data = await contentList.execute({ contentIds: deduplicatedIds, limit: 20 })
      const formattedList = formatContentList(data.items, user)

      return formattedList
    },
    [formatContentList, contentList],
  )

  const getFinishedContents = useCallback(
    async (user: User): Promise<Content[]> => {
      const finishedContentsIds = user?.contentInfo.accessed
        .filter((item) => item.progress === 'DONE')
        .map((content) => content.entryId)
      const deduplicatedIds = Array.from(new Set(finishedContentsIds)).slice(0, 20)

      const data = await contentList.execute({ contentIds: deduplicatedIds, limit: 20 })
      const updatedList = formatContentList(data.items, user)

      return updatedList
    },
    [formatContentList, contentList],
  )

  const getContentsByPartner = useCallback(
    async (partnerId: string, pagination: Pagination, user: User): Promise<ContentList> => {
      const data = await contentList.execute({ ...pagination, ...{ partnerId }, status: 'PUBLISHED' })
      const updatedList = formatContentList(data.items, user)

      return {
        total: data.total,
        items: updatedList,
      }
    },
    [formatContentList, contentList],
  )

  const searchContentsByQuery = useCallback(
    async (query: string, pagination: Pagination): Promise<ContentList> => {
      const data = await contentList.execute({ title: query, ...pagination })
      return data
    },
    [contentList],
  )

  return {
    listContents,
    listLastContentsAccessedByUser,
    listFinishedContentsByUser,
    getContentById,
    getFavoritedContents,
    getRecentlyAccessedContents,
    getFinishedContents,
    getContentsByPartner,
    searchContentsByQuery,
    formatContentWithProgressAndFavorited,
  }
}
