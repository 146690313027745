import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import Card from '../../../components-admin/onboard/Card'
import Header from '../../../components-admin/onboard/Header'
// // import '../../../i18n'
import { useAuthOnboard } from '../utils'
import Form from './Form'

const AdminAccount: React.FC = () => {
  const [t] = useTranslation()
  useAuthOnboard({ stepSlug: 'admin-account' })

  return (
    <Card>
      <Helmet>
        <title>{t('onboard.steps.Administrator')} | Education Journey</title>
      </Helmet>
      <Header
        title={t('onboard.adminAccount.header.title')}
        description={t('onboard.adminAccount.header.description')}
      />
      <Form />
    </Card>
  )
}

export default AdminAccount
