import { Flex } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleTeam } from 'src/context-admin/useHandleTeam'
import { NavigationButtons } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/NavigationButtons'
import { StepTitle } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTitle'
import { User } from 'src/types/User'
import { UserList } from '../../commonComponents/UserList'

export function EditExistingTeamRemoveUsers() {
  const [t] = useTranslation()
  const [selectedUsers, setSelectedUsers] = useState<User[]>([])
  const { team, navigateToStep, handleSetTeamValues, filters } = useHandleTeam()
  const filteredUsers = useMemo(
    () => (filters.userName ? team?.users?.filter((item) => item.name.includes(filters?.userName ?? '')) : team?.users),
    [filters?.userName, team?.users],
  )

  const onSelectUsers = (users: User[]) => {
    setSelectedUsers(users)
  }

  useEffect(() => {
    handleSetTeamValues({ newUsers: selectedUsers })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers])

  return (
    <Flex flexDir='column' gap='24px'>
      <StepTitle
        title={t('removingMembersFromArea', { areaName: team?.name ?? t('invalidArea') })}
        subtitle={t('selectMembersToRemoval', { areaName: team?.name ?? t('invalidArea') })}
      />
      <UserList data={filteredUsers} type='remove-users' selectedTextColor='red.500' onSelectUsers={onSelectUsers} />
      <NavigationButtons
        isDisabled={selectedUsers.length <= 0}
        onContinueClick={() => {
          navigateToStep('edit-existing-team--remove-users-confirm')
        }}
        onBackClick={() => {
          navigateToStep('edit-existing-team--select-options')
        }}
      />
    </Flex>
  )
}
