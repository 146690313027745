import { Box, Flex, Icon, Skeleton } from '@chakra-ui/react'
import React from 'react'
import { SliderChevron } from '../../../theme/Icons'
const ExploreSkeleton: React.FC = () => {
  return (
    <Box
      w={'322px'}
      h='550px'
      borderRadius='30px'
      bg='white'
      border='2px solid rgba(229, 229, 229, 1)'
      boxShadow='0px 1px 26px 0px rgba(0, 0, 0, 0.16)'
      padding='20px'
      d='flex'
      transition={'all 0.3s ease-in-out'}
      position='absolute'
      overflowX='clip'
      left='5%'
      flexDir={'column'}
      alignItems='center'
    >
      <Flex flexDirection='column' margin='0px'>
        <Skeleton h='35px' w='285px' borderRadius='8px' marginBottom='20px' />
        <Flex alignItems='center' marginBottom='12px'>
          <Skeleton h='27px' w='250px' borderRadius='8px' marginRight={'5px'} />
          <Icon as={SliderChevron} />
        </Flex>
        <Flex alignItems='center' marginBottom='12px'>
          <Skeleton h='27px' w='250px' borderRadius='8px' marginRight={'5px'} />
          <Icon as={SliderChevron} />
        </Flex>
        <Flex alignItems='center' marginBottom='12px'>
          <Skeleton h='27px' w='250px' borderRadius='8px' marginRight={'5px'} />
          <Icon as={SliderChevron} />
        </Flex>
        <Flex alignItems='center' marginBottom='12px'>
          <Skeleton h='27px' w='250px' borderRadius='8px' marginRight={'5px'} />
          <Icon as={SliderChevron} />
        </Flex>
        <Flex alignItems='center' marginBottom='12px'>
          <Skeleton h='27px' w='250px' borderRadius='8px' marginRight={'5px'} />
          <Icon as={SliderChevron} />
        </Flex>
        <Flex alignItems='center' marginBottom='12px'>
          <Skeleton h='27px' w='250px' borderRadius='8px' marginRight={'5px'} />
          <Icon as={SliderChevron} />
        </Flex>
      </Flex>
    </Box>
  )
}

export default ExploreSkeleton
