import { AxiosInstance } from "axios";
import { HttpClient, IHttpClient } from "src/services/http/http-client";

export interface IInternalizationRepository {
  getTranslationByLanguage: (language: string, text: string) => Promise<string | undefined>
}

interface TranslationResponse {
  id: string
  text: string
  language: string
  translation: {
    [key: string]: string
  }
}

export class InternationalizationRepository implements IInternalizationRepository {
  constructor(private token?: string, private httpClient?: HttpClient | AxiosInstance) { }

  async getTranslationByLanguage(language: string, text: string): Promise<string | undefined> {
    try {
      const data = [{
        id: '1',
        language,
        text,
      }]
      const response = await (this.httpClient as AxiosInstance)?.post<TranslationResponse[]>(
        '/translation/translate',
        data,
        {
          headers: {
            Authorization: `${this.token}`,
          },
        }
      )
      if (!response?.data || response?.data?.length <= 0) return undefined
      return response.data[0].translation[language]
    } catch {
      console.error('Error getting translation')
      return undefined
    }
  }
}
