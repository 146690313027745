import { useMutation } from '@apollo/client'
import { Icon } from '@chakra-ui/icons'
import { Image } from '@chakra-ui/image'
import { Box, Flex, HStack, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/layout'
import { Alert, Button, IconButton, useToast } from '@chakra-ui/react'
import lodash from 'lodash'
import React, { useCallback, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { IoCheckmark, IoDownloadOutline, IoTrash } from 'react-icons/io5'
import { useHomeAccess } from 'src/context-admin/Home/AdminHomeAccessContext'
import { useUser } from 'src/context/userContext'
import SUBMIT_EMPLOYEES from 'src/graphql/mutations/SUBMIT_EMPLOYEES'
import { makeSaveUserMassive } from 'src/modules/iam/use-case/SaveUserEditionMassiveUseCase'
import { useValidateEmployees } from 'src/pages-admin/onboard/Employees/utils'
import { handleSubmitToEditErrors } from '../hooks/handleSubmitToEditErrors'
import { csvCreationHeaders, csvEditionHeaders, errorDetails } from '../utils'

const csv = require('csvtojson/v2')

const ERRORS_SHOWN = 20

export interface Employee {
  name?: string
  email?: string
  companyIdentifier?: string
  team?: string
  userIdInClientPlatform?: string
  birthDate?: string
  gender?: string
  languageCode?: string
  centroCusto?: string
  filial?: string
  area?: string
  gestor?: string
  nomeGestor?: string
  sobrenomeGestor?: string
  emailGestor?: string
  modeloContrato?: string
  dataContratacao?: string
  localizacao?: string
  tituloGlobalCargo?: string
  status?: string
}

interface UploadError {
  name: string
  email: string
  code: string
  message: string
}

interface Props {
  onClose: () => void
  isEdit?: boolean
}

const csvHeaders = [
  'name',
  'email',
  'userIdInClientPlatform',
  'birthDate',
  'gender',
  'languageCode',
  'centroCusto',
  'filial',
  'area',
  'gestor',
  'nomeGestor',
  'sobrenomeGestor',
  'emailGestor',
  'modeloContrato',
  'dataContratacao',
  'localizacao',
  'tituloGlobalCargo',
  'status',
]

export type ListEmployees = Employee[]

const AddEmployees: React.FC<Props> = ({ onClose, isEdit = false }: Props) => {
  const { fetchUsers, first } = useHomeAccess()
  const [t] = useTranslation()
  const toast = useToast()
  const [listEmployees, setListEmployees] = useState<ListEmployees>([])
  const [usersToEdit, setUsersToEdit] = useState<Omit<Employee, 'companyIdentifier'>[]>([])
  const [loadingSubmitUsersToEdit, setLoadingSubmitUsersToEdit] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const errors = useValidateEmployees(listEmployees)
  const [submitList, { loading }] = useMutation(SUBMIT_EMPLOYEES)
  const { user } = useUser()

  /** Success */
  const onDrop = useCallback(
    (acceptedFiles, fileRejections: FileRejection[]) => {
      /** don't go into the function if there are no
       * accepted file or if there are any rejection files
       */
      if (!acceptedFiles.length || fileRejections.length) return

      // get and read file
      const file = acceptedFiles[0]
      const reader = new FileReader()

      // load file
      reader.addEventListener('load', (e) => {
        let CSVData = e!.target!.result // result from the read, stringified

        csv({
          headers: !isEdit ? csvCreationHeaders : csvEditionHeaders,
        })
          .fromString(CSVData)
          .then((jsonObj: ListEmployees) => {
            setFile(null)
            setListEmployees([])

            const formattedEmployees = jsonObj.map((item) => {
              let dataContratacao = undefined
              let dataNascimento = undefined

              if (!lodash.isUndefined(item?.dataContratacao) && /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(item?.dataContratacao)) {
                const [day, month, year] = item?.dataContratacao.split('/')

                if (parseInt(month) > 12) {
                  toast({
                    title: t('bulkAdditionEmployee.errors.dataContratacao'),
                    status: 'error',
                    isClosable: true,
                  })
                  throw new Error(`Invalid month: ${month}`);
                } else {
                  dataContratacao = `${year}-${month}-${day}`
                  dataContratacao = new Date(dataContratacao).toISOString()
                }

              }

              if (!lodash.isUndefined(item?.birthDate) && /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(item?.birthDate)) {
                const [day, month, year] = item?.birthDate.split('/')

                if (parseInt(month) > 12) {
                  toast({
                    title: t('bulkAdditionEmployee.errors.dataNascimento'),
                    status: 'error',
                    isClosable: true,
                  })
                  throw new Error(`Invalid month: ${month}`);
                } else {
                  dataNascimento = `${year}-${month}-${day}`
                  dataNascimento = new Date(dataNascimento).toISOString()
                }

              }

              const teams = item?.team?.split(';').map((team) => team.trim())
              const _newUser = {
                ...(!isEdit && { companyIdentifier: item.companyIdentifier }),
                name: item.name,
                email: item.email,
                languageCode: item.languageCode,
                ...(!isEdit && { team: item.team ?? '' }),
                customAttributes: {
                  centroCusto: item?.centroCusto ?? '',
                  filial: item?.filial ?? '',
                  area: item?.area ?? '',
                  equipe: teams ?? [],
                  gestor: item?.gestor?.toLowerCase() === 'true',
                  nomeGestor: item?.nomeGestor ?? '',
                  sobrenomeGestor: item?.sobrenomeGestor ?? '',
                  emailGestor: item?.emailGestor ?? '',
                  modeloContrato: item?.modeloContrato ?? '',
                  ...(dataContratacao ? { dataContratacao } : {}),
                  localizacao: item?.localizacao ?? '',
                  tituloGlobalCargo: item?.tituloGlobalCargo ?? '',
                  status: item?.status ?? '',
                  plataformaClienteId: item?.userIdInClientPlatform ?? '',
                  ...(dataNascimento ? { dataNascimento } : {}),
                  genero: item?.gender?.toLowerCase() ?? '',
                  ...(isEdit && { nomeUsuario: item.name }),
                  ...(isEdit && { email: item.email }),
                },
              }
              return _newUser
            })

            const employeesListWithUniqueEmails = lodash.uniqBy(formattedEmployees, 'email')
            if (isEdit) {
              const customAttributes = employeesListWithUniqueEmails?.map((u) => u?.customAttributes)
              setUsersToEdit(customAttributes as any)
            }
            setListEmployees(employeesListWithUniqueEmails as ListEmployees)
            setFile(file)
          })
      })

      // execute listener load
      reader.readAsText(file, 'utf-8')
    },
    [], // eslint-disable-line
  )

  /** Error */
  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      if (!fileRejections) return

      fileRejections[0].errors.map((error) => {
        switch (error.code) {
          case 'file-invalid-type':
            toast({
              title: t('common.dragArea.fileErrors.fileInvalidType'),
              status: 'error',
              isClosable: true,
            })
            break
          case 'file-too-large':
            toast({
              title: t('common.dragArea.fileErrors.fileTooLarge'),
              status: 'error',
              isClosable: true,
            })
            break
          case 'too-many-files':
            toast({
              title: t('common.dragArea.fileErrors.tooManyFiles'),
              status: 'error',
              isClosable: true,
            })
            break
          default:
            toast({
              title: t('common.dragArea.fileErrors.somethingWentWrongWithYourFile'),
              status: 'error',
              isClosable: true,
            })
        }
        return null
      })
    },
    [toast, t],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: [
      '.csv',
      'text/csv',
      'application/vnd.ms-excel',
      'application/csv',
      'text/x-csv',
      'application/x-csv',
      'text/comma-separated-values',
      'text/x-comma-separated-values',
    ],
    multiple: false,
    maxSize: Math.pow(10, 7), // 10MB
  })

  const deleteFile = () => {
    setFile(null)
    setListEmployees([])
  }

  const onSubmitEmployeesToEdit = async () => {
    try {
      setLoadingSubmitUsersToEdit(true)
      const saveEditedUsers = makeSaveUserMassive()
      const companyId = user?.company?.id ?? user?.companyId
      if (!companyId) throw new Error()
      const formattedUser = usersToEdit.map((user) => {
        const newUser = Object.entries(user).reduce((acc, [key, value]) => {
          if (key === 'email' && !value) throw new Error()
          if (value) return { ...acc, [key]: value }
          return acc
        }, {})
        return newUser
      })
      const response = await saveEditedUsers.execute({ companyId, users: formattedUser })
      if (response?.errors?.length > 0) {
        handleSubmitToEditErrors({ errors: response.errors, t, toast, totalUsersEditted: usersToEdit.length })
        if (!response?.wasSaved) {
          return
        }
      }
      toast({
        title: t('usersEditedSuccessfully'),
        status: 'success',
        isClosable: true,
        duration: 15000,
      })
      await fetchUsers({ limit: first })
      onClose()
      return
    } catch {
      toast({
        title: t('common.dragArea.fileErrors.somethingWentWrong'),
        status: 'error',
        isClosable: true,
      })
    } finally {
      setLoadingSubmitUsersToEdit(false)
    }
  }

  const submitEmployees = async () => {
    if (!listEmployees.length) return

    try {
      const response = await submitList({
        variables: {
          users: listEmployees,
        },
      })

      const uploadErrors = (response as any).data.importUsers.errors

      if (uploadErrors.length) {
        showToastErrors(uploadErrors)
        return
      }

      await fetchUsers({ limit: first })
      onClose()
      toast({
        title: t('onboard.employees.Employees successfully added!'),
        status: 'success',
        isClosable: true,
      })
    } catch (err) {
      toast({
        title: t('common.dragArea.fileErrors.somethingWentWrong'),
        status: 'error',
        isClosable: true,
      })
    }
  }

  const showToastErrors = (errors: UploadError[]) => {
    errors.forEach((error: UploadError) => {
      toast({
        title: `O colaborador ${error.name} não foi importado porque ${errorDetails[error.code]}`,
        status: 'error',
        isClosable: true,
        duration: 900000,
      })
    })
  }

  return (
    <Stack spacing={4}>
      {/** Download CSV Template */}
      <Flex mb='12px'>
        <Button
          variant='startCourseDark'
          height='32px'
          py='0'
          px='16px'
          fontWeight='700'
          fontFamily='Poppins'
          fontSize='0.875rem'
          as='a'
          leftIcon={<IoDownloadOutline />}
          href={!isEdit ? '/assets/files/register-employees.csv' : '/assets/files/edit-employees.csv'}
          download
        >
          {t('donwloadModel')}
        </Button>
      </Flex>
      {/** Drag Zone */}
      <Stack
        backgroundColor='gray.50'
        border='5px dashed'
        cursor='pointer'
        h='270px'
        w='100%'
        borderColor='gray.100'
        borderRadius='15px'
        paddingY='10px'
        alignItems='center'
        spacing={4}
        opacity={isDragActive ? 0.6 : 1}
        transition='0.2s all ease'
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Image src={'/assets/images/add-employees.svg'} />
        {!isDragActive ? (
          <>
            {file && !errors.length ? (
              <Box background='white' boxShadow='0 3px 6px rgba(0,0,0,0.1)' borderRadius='8px' padding='10px 15px'>
                <HStack spacing={4}>
                  <Stack spacing={0}>
                    <Text fontSize='12px' color='success'>
                      <Icon as={IoCheckmark} fontSize='lg' />
                      {t('common.dragArea.fileValidated')}
                    </Text>
                    <Text fontWeight='extrabold'>{file?.name}</Text>
                  </Stack>
                  <IconButton icon={<IoTrash />} aria-label='Delete File' onClick={deleteFile} />
                </HStack>
              </Box>
            ) : (
              <>
                <Text fontSize='14px' fontFamily='Mulish' fontWeight='regular'>
                  {t('common.dragArea.chooseAndDragACSV')}
                </Text>
                <Text fontSize='14px' fontFamily='Mulish' fontWeight='regular'>
                  {t('common.dragArea.maximunFileSize')} <strong>10MB</strong>
                </Text>
              </>
            )}
          </>
        ) : (
          <Text>{t('common.dragArea.dropTheFile')}</Text>
        )}
      </Stack>

      {/** Erros in file */}
      {errors.length && (
        <Alert status='error'>
          <UnorderedList>
            {errors.map((error, index) => {
              if (index <= ERRORS_SHOWN - 1) return <ListItem fontSize='sm'>{error}</ListItem>
              return undefined
            })}
            {errors.length > ERRORS_SHOWN && (
              <ListItem fontSize='sm'>
                {t('onboard.employees.More errors', {
                  nErrorsShown: ERRORS_SHOWN,
                  nErrorsHidden: errors.length - ERRORS_SHOWN,
                })}
              </ListItem>
            )}
          </UnorderedList>
        </Alert>
      )}

      <HStack justifyContent='flex-end'>
        <Button onClick={onClose} variant='ghost'>
          {t('admin.home.updateEmployees.cancel')}
        </Button>
        <Button
          bg='#009028'
          fontSize='16px'
          fontFamily='Poppins'
          fontWeight='bold'
          colorScheme='primaryColors'
          disabled={!!errors.length || !listEmployees.length}
          size='lg'
          isLoading={loading || loadingSubmitUsersToEdit}
          onClick={!isEdit ? submitEmployees : onSubmitEmployeesToEdit}
        >
          {!isEdit ? t('admin.home.updateEmployees.add') : t('editCollab')}
        </Button>
      </HStack>
    </Stack>
  )
}

export default AddEmployees
