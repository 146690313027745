import { gql } from '@apollo/client'

const GET_USER_CREDENTIALS = gql`
  query GetUserCredentials {
    GetUserCredentials {
      login
      password
      partner {
        name
        squareLogo
        accessLink
        slug
      }
    }
  }
`

export default GET_USER_CREDENTIALS
