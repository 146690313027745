import { Button, Flex, Heading, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { useSwapContentDrawer } from 'src/context/CustomPlaylist/SwapContentDrawerContext'
import { useUser } from 'src/context/userContext'
import { usePlaylists } from 'src/modules/cms/hooks/usePlaylists'
import { ContentImage } from '../../../HorizontalLearningItemCard/components/ContentImage'
import { HeaderInfo } from '../../../HorizontalLearningItemCard/components/HeaderInfo'

const StepTwo: React.FC = () => {
  const [loadingSwap, setLoadingSwap] = useState(false)

  const [t] = useTranslation()
  const { user } = useUser()

  const { swapContentDrawerInfo, contentToSwap, setSwapDrawerStep } = useSwapContentDrawer()
  const { updatePlaylistModuleContent } = usePlaylists()
  const { fetchCustomPlaylist } = useCustomPlaylist()

  const swap = async () => {
    setLoadingSwap(true)
    try {
      await updatePlaylistModuleContent(
        swapContentDrawerInfo!.moduleId!,
        swapContentDrawerInfo!.content.id,
        contentToSwap!.id,
      )
      await fetchCustomPlaylist(user!)

      setSwapDrawerStep(2)
    } catch (error) {
      console.error('Error while swaping module content', error)
    } finally {
      setLoadingSwap(false)
    }
  }

  return (
    <Flex flexDirection='column'>
      <Heading size='xs' fontWeight='600' marginBottom='21px' color='#767F89'>
        {t('home.Custom Playlist.Confirmation of substitution of contents')}
      </Heading>

      <Heading size='xs' fontWeight='600' marginBottom='16px'>
        {t('home.Custom Playlist.You are changing')}
      </Heading>

      <Flex
        h='auto'
        border='2px solid #E5E5E5'
        borderRadius='30px'
        flex='1'
        maxWidth='100%'
        position='relative'
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <ContentImage content={swapContentDrawerInfo!.content} />
        <Flex
          marginLeft={{ base: '0px', lg: '40px' }}
          marginRight={{ base: '0px', lg: '24px' }}
          flexDirection='column'
          flex='1'
          paddingY={{ base: '12px', lg: '19px' }}
          paddingX={{ base: '8px', lg: '0px' }}
          w={{ base: '100%', lg: 'calc(100% - 297px)' }}
          justifyContent='center'
        >
          <HeaderInfo
            content={swapContentDrawerInfo!.content}
            moduleName={swapContentDrawerInfo?.moduleTitle}
            showProficiency={false}
          />

          <Heading
            fontSize={{ base: '1rem', lg: '1.5rem' }}
            textOverflow='ellipsis'
            overflow='hidden'
            whiteSpace='nowrap'
            paddingBottom={{ base: '0px', lg: '24px' }}
          >
            {swapContentDrawerInfo!.content.title}
          </Heading>
        </Flex>
      </Flex>

      <Heading size='xs' fontWeight='600' mt='24px' mb='16px'>
        {t('home.Custom Playlist.For')}
      </Heading>

      <Flex
        h='auto'
        border='2px solid #E5E5E5'
        borderRadius='30px'
        flex='1'
        maxWidth='100%'
        position='relative'
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <ContentImage content={contentToSwap!} />
        <Flex
          marginLeft={{ base: '0px', lg: '40px' }}
          marginRight={{ base: '0px', lg: '24px' }}
          flexDirection='column'
          flex='1'
          paddingY={{ base: '12px', lg: '19px' }}
          paddingX={{ base: '8px', lg: '0px' }}
          w={{ base: '100%', lg: 'calc(100% - 297px)' }}
          justifyContent='center'
        >
          <HeaderInfo content={contentToSwap!} moduleName={'Teste'} showProficiency={false} />

          <Heading
            fontSize={{ base: '1rem', lg: '1.5rem' }}
            textOverflow='ellipsis'
            overflow='hidden'
            whiteSpace='nowrap'
            paddingBottom={{ base: '0px', lg: '24px' }}
          >
            {contentToSwap!.title}
          </Heading>
        </Flex>
      </Flex>

      <Flex
        alignItems='center'
        mt='32px'
        justifyContent='space-between'
        flexDir={{ base: 'column', lg: 'row' }}
        gap='16px'
      >
        <Button
          variant='startCourse'
          border='2px solid #E5E5E5'
          h='48px'
          w='100%'
          maxW='285px'
          onClick={() => setSwapDrawerStep(0)}
          fontSize={{ base: '.75rem', lg: '1rem' }}
        >
          {t('home.Custom Playlist.Back to suggestions')}
        </Button>

        <Button
          variant='startCourseDark'
          w='100%'
          maxW='285px'
          isLoading={loadingSwap}
          onClick={() => {
            swap()
          }}
        >
          <Text fontSize={{ base: '.75rem', lg: '1rem' }} color='white'>
            {t('home.Custom Playlist.Finish Swap')}
          </Text>
        </Button>
      </Flex>
    </Flex>
  )
}

export default StepTwo
