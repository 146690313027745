import { useToast } from '@chakra-ui/react'
import { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeListUsers } from 'src/modules/iam/factory/makeListUsers'
import { ListUsersInput, ListUsersOutput } from 'src/modules/iam/use-case/ListUsersUseCase'
import { Users } from 'src/pages-admin/Home/home.types'

interface Value {
  listUsers: Users | undefined
  first: number
  startCursors: string[]
  loading: boolean

  area: string
  team: string
  order: string
  permission: string

  setArea: (area: string) => void
  setTeam: (team: string) => void
  setSearch: (search: string) => void
  setOrder: (order: string) => void
  setPermission: (permission: string) => void

  getNextPage: () => void
  getPreviousPage: () => void
  handleChangeFirst: (e: React.ChangeEvent<HTMLSelectElement>) => void

  fetchUsers: (params: ListUsersInput) => Promise<void>
  fetchUsersAndReturn: (params: ListUsersInput) => Promise<ListUsersOutput | undefined>
}

interface Props {
  children: React.ReactNode[] | React.ReactNode
}

const AdminHomeAccessContext = createContext({} as Value)

export function AdminHomeAccessProvider({ children }: Props) {
  const listUsers = makeListUsers()
  const toast = useToast()
  const [data, setData] = useState<ListUsersOutput | undefined>(undefined)
  const [first, setFirst] = useState<number>(25)
  const [startCursors, setStartCursors] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [area, setArea] = useState('')
  const [team, setTeam] = useState('')
  const [permission, setPermission] = useState('')
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('')
  const [t] = useTranslation()

  const fetchUsers = async (params: ListUsersInput) => {
    try {
      setIsLoading(true)
      const response = await listUsers.execute(params)
      setData(response)
    } catch (error) {
      toast({
        status: 'error',
        title: t('anErrorOccurredWhenSearchingUsersList'),
        description: 'Por favor, tente novamente!',
        isClosable: true,
        duration: 7000,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const fetchUsersAndReturn = async (params: ListUsersInput) => {
    try {
      const response = await listUsers.execute(params)
      return response
    } catch (error) {
      toast({
        status: 'error',
        title: t('anErrorOccurredWhenSearchingUsersList'),
        description: t('pleaseTryAgain'),
        isClosable: true,
        duration: 7000,
      })
    }
  }

  const getNextPage = async () => {
    if (!data?.paginateInfo.next) return
    const nextCursor = data?.paginateInfo.next

    await fetchUsers({
      limit: first,
      cursor: nextCursor,
      name: search,
      teamId: team,
      areaId: +area,
      orderByName: order,
      permission,
    })
    setStartCursors((prevState) => [...prevState, nextCursor])
  }

  const getPreviousPage = async () => {
    const cursorListUpdated = startCursors.slice(0, -1)

    await fetchUsers({
      limit: first,
      ...(startCursors.length === 1 ? {} : { cursor: cursorListUpdated[cursorListUpdated.length - 1] }),
      name: search,
      teamId: team,
      areaId: +area,
      orderByName: order,
      permission,
    })
    setStartCursors((prevState) => prevState.slice(0, -1))
  }

  const handleChangeFirst = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const first = parseInt(e.target.value)
    setFirst(first)
  }

  useEffect(() => {
    fetchUsers({ limit: first, teamId: team, areaId: +area, name: search, orderByName: order, permission })
  }, [team, area, search, order, permission])

  return (
    <AdminHomeAccessContext.Provider
      value={{
        fetchUsers,
        listUsers: data,
        first,
        startCursors,
        loading: isLoading,

        area,
        team,
        order,
        permission,

        setArea,
        setTeam,
        setSearch,
        setOrder,
        setPermission,

        getNextPage,
        getPreviousPage,
        handleChangeFirst,
        fetchUsersAndReturn,
      }}
    >
      {children}
    </AdminHomeAccessContext.Provider>
  )
}

export const useHomeAccess = () => useContext(AdminHomeAccessContext)
