import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from 'src/context/userContext'
import Content from 'src/modules/cms/domain/Content'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { HorizontalLearningItemCard } from '../../../../components/HorizontalLearningItemCard'

interface Props {
  playlist: NewPlaylist
  onUpdatePlaylist: (playlist: NewPlaylist) => void
}

const LearningItemsSection: React.FC<Props> = ({ playlist, onUpdatePlaylist }: Props) => {
  const [t] = useTranslation()
  const { user } = useUser()
  playlist = playlist!

  const contentList = playlist.moduleList?.items?.flatMap((module) =>
    module.contentList?.items?.flatMap((item) => item),
  )

  const onUpdateLearningItem = (contentUpdated: Content) => {
    const playlistCopy = { ...playlist }

    if (playlistCopy.moduleList?.items) {
      playlistCopy.moduleList.items = playlistCopy.moduleList.items.map((module) => {
        const learningItems = module.contentList?.items?.map((item) => {
          if (item.id === contentUpdated.id) return contentUpdated
          return item
        })
        return { ...module, contentList: { items: learningItems } }
      })
      onUpdatePlaylist(playlistCopy)
    }
  }

  const playListTitle = useMemo(() => {
    return playlist.translations?.find((translation) => translation.language === user?.language)?.title ?? playlist.title
  }, [user, playlist])

  return (
    <Flex
      marginLeft={{ base: '39px', lg: '72px' }}
      marginRight={{ base: '39px', lg: '72px' }}
      marginTop='24px'
      flexDirection='column'
    >
      <Heading size='sm' marginBottom='6px'>
        {t('playlist.Playlist Contents')} {playListTitle}
      </Heading>
      <Text opacity='0.7' marginBottom='26px'>
        {t('playlist.Here begins your journey')} <b>{t('playlist.Mark each completed course as complete')}</b>
      </Text>

      {contentList?.map((content, index) => {
        return (
          <Box marginBottom={{ base: '16px', lg: '40px' }} id={`id${content?.id}`} w='100%'>
            <HorizontalLearningItemCard
              checkIndex={index}
              content={content!}
              onUpdateLearningItem={onUpdateLearningItem}
              playlist={playlist}
              showImage
              showCheckButtons
              showMarkButtons
            />
          </Box>
        )
      })}
    </Flex>
  )
}

export default LearningItemsSection
