import {
  Button,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { IoLogoLinkedin, IoLogoTwitter, IoLogoWhatsapp, IoShareSocialOutline } from 'react-icons/io5'
import { useLocation } from 'react-router-dom'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import Content from 'src/modules/cms/domain/Content'

type Props = {
  content: Content
  isDarkButton?: boolean
  height?: string
}

const ShareButton: React.FC<Props> = ({ content, height, isDarkButton }) => {
  const location = useLocation()
  const { EventsImplementation, track } = useMixpanel()

  return (
    <Popover placement='top'>
      <PopoverTrigger>
        <Button
          variant='icon'
          h={height ? height : '44px'}
          background={isDarkButton ? '#313E4C' : 'white'}
          border={`2px solid ${isDarkButton ? 'rgba(229, 229, 229, 0.6)' : '#E5E5E5'}`}
          _hover={{ background: isDarkButton ? '#313E4C' : 'white', borderColor: 'white' }}
          _active={{ background: isDarkButton ? '#313E4C' : 'white' }}
        >
          <Icon w='24px' h='24px' color={isDarkButton ? 'white' : '#313E4C'} as={IoShareSocialOutline} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        borderColor='gray.200'
        bgColor='#353b4d'
        borderRadius='20px'
        zIndex='popover'
        width={'fit-content'}
        border='none'
        _focus={{ boxShadow: 'none' }}
        boxShadow={'0px 0px 0px 0px'}
      >
        <PopoverArrow bgColor='#353b4d' />
        <PopoverBody>
          <Flex gap='4px'>
            <Button
              variant='icon'
              borderColor='transparent'
              h='44px'
              onClick={() => {
                window.open(
                  'https://www.linkedin.com/shareArticle?url=' +
                    encodeURIComponent(`https://app.education-journey.com/course/${content.id}`),
                  '_blank',
                )
                track(
                  EventsImplementation.CARD_MODAL_SHARE(
                    'Linkedin',
                    location.pathname,
                    'Learning Item Modal',
                    content.type,
                  ),
                )
              }}
            >
              <Icon w='24px' h='24px' as={IoLogoLinkedin} />
            </Button>

            <Button
              variant='icon'
              borderColor='transparent'
              h='44px'
              onClick={() => {
                window.open(
                  'https://twitter.com/intent/tweet?text=' +
                    encodeURIComponent(
                      `O futuro me espera! Estou estudando "${content.title}" pela Education Journey https://app.education-journey.com/course/${content.id} #lifelonglearning`,
                    ),
                  '_blank',
                )
                track(
                  EventsImplementation.CARD_MODAL_SHARE(
                    'Twitter',
                    location.pathname,
                    'Learning Item Modal',
                    content.type,
                  ),
                )
              }}
            >
              <Icon w='24px' h='24px' as={IoLogoTwitter} />
            </Button>
            <Button
              variant='icon'
              borderColor='transparent'
              h='44px'
              onClick={() => {
                window.open(
                  'https://wa.me/?text=' +
                    encodeURIComponent(
                      `Não podemos parar de evoluir! Comecei a estudar "${content.title}" pela Education Journey https://app.education-journey.com/course/${content.id}`,
                    ),
                  '_blank',
                )
                track(
                  EventsImplementation.CARD_MODAL_SHARE(
                    'WhatsApp',
                    location.pathname,
                    'Learning Item Modal',
                    content.type,
                  ),
                )
              }}
            >
              <Icon w='24px' h='24px' as={IoLogoWhatsapp} />
            </Button>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default ShareButton
