import { useLazyQuery } from '@apollo/client'
import { useUser } from 'src/context/userContext'
import { makeListPlaylists } from 'src/modules/cms/factories/makeListPlaylists'
import { GET_PLAYLISTS } from 'src/modules/cms/graphql/queries/GET_PLAYLISTS'
import { cmsQueryContext } from 'src/services/apollo/ApolloClient'
import { CmsPlaylistQuery } from 'src/types/Cms'
import { getOrderFilterField } from '../utils/getOrderFilterField'
import { useCorporateUniversityStore } from './useCorporateUniversityStore'

const useListPlaylists = () => {
  const { user } = useUser()
  const isUserBackoffice = user?.roles?.includes('BACKOFFICE')

  const {
    updateCmsPlaylists,
    limitPerPage,
    updateTotalPlaylists,
    filters,
    selectedPlaylistPrivacy,
    totalPlaylists,
    totalPrivatePlaylists,
    skip,
    updateSkip,
    searchValue,
  } = useCorporateUniversityStore()

  const allowedCompanies = [user?.company?.id ?? user?.companyId]

  if (isUserBackoffice) {
    allowedCompanies.push(-1)
  }

  const listPublicPlaylists = async (type: 'FEATURED' | 'CUSTOM' = 'FEATURED') => {
    try {
      const getPlaylists = makeListPlaylists()
      const response = await getPlaylists.execute({
        limit: limitPerPage,
        skip,
        order: getOrderFilterField(filters.order),
        companies: Array.from(new Set(allowedCompanies)),
        ...(filters.partnerId.length > 0 ? { partnerId: filters.partnerId } : {}),
        ...(filters.skill.length > 0 ? { skill: filters.skill } : {}),
        ...(filters.status.length > 0 ? { status: filters.status } : {}),
        ...(searchValue ? { title: searchValue } : {}),
        // type: { in: ['FEATURED'] },
      })
      updateCmsPlaylists(response?.items?.filter((item) => item?.type !== 'CUSTOM') ?? [])
      updateTotalPlaylists(response?.total ?? 0)
    } catch (err) {
      console.error('Error on listPublicPlaylists', err)
    }
  }

  const [listPlaylists, { loading, refetch, fetchMore }] = useLazyQuery<CmsPlaylistQuery>(GET_PLAYLISTS, {
    context: cmsQueryContext,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: limitPerPage,
      skip,
      order: getOrderFilterField(filters.order),
      companies: Array.from(new Set(allowedCompanies)),
      ...(filters.partnerId?.length > 0 ? { partnerId: filters.partnerId } : {}),
      ...(filters.skill?.length > 0 ? { skill: filters.skill } : {}),
      ...(filters.status?.length > 0 ? { status: filters.status } : {}),
      ...(searchValue ? { title: searchValue } : {}),
      // type: { in: ['FEATURED'] },
    },
    onCompleted: (data) => {
      updateCmsPlaylists(data?.playlistList?.items?.filter((item) => item?.type !== 'CUSTOM') ?? [])
      updateTotalPlaylists(data?.playlistList?.total ?? 0)
    },
  })

  const handlePrevPagePlaylists = () => {
    const newSkip = skip - limitPerPage <= 0 ? 0 : skip - limitPerPage
    updateSkip(newSkip)
  }

  const handleNextPagePlaylists = () => {
    const total = selectedPlaylistPrivacy === 'private' ? totalPrivatePlaylists : totalPlaylists
    const newSkip = skip + limitPerPage >= total ? skip : skip + limitPerPage
    updateSkip(newSkip)
  }

  return {
    listPlaylists,
    listPublicPlaylists,
    refetch,
    isPlaylistsLoading: loading,
    fetchMore,
    handlePrevPagePlaylists,
    handleNextPagePlaylists,
  }
}

export default useListPlaylists
