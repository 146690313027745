import { createContext, useCallback, useContext, useState } from 'react'
import { PageInfo } from 'src/pages-admin/Home/home.types'
import { User } from 'src/types/User'

interface ContextValues {
  togglePermissionsDrawer: boolean
  handleTogglePermissionsDrawer: () => void
  selectedPermissions: string[]
  setSelectedPermissions: React.Dispatch<React.SetStateAction<string[]>>
  resetState: () => void
  setSelectedCollab: React.Dispatch<React.SetStateAction<User>>
  selectedCollab: User
  collabsList: User[]
  updateCollabsList: (newCollabs: User[]) => void
  incrementCollabsList: (newCollabs: User[]) => void
  pageInfo: PageInfo | undefined
  updatePageInfo: (newPageInfo: PageInfo) => void
  collabsToRemoveAccess: User[]
  addCollabsToList: (user: User) => void
  removeCollabsFromList: (id: string) => void
  removeAllCollabsFromList: () => void
  toggleRemoveAccessModal: (value: boolean) => void
  isRemoveAccessModalOpen: boolean
}

type ProviderProps = {
  children: React.ReactNode[] | React.ReactNode
}

const PermissionsContext = createContext({} as ContextValues)

export function PermissionsProvider({ children }: ProviderProps) {
  const [togglePermissionsDrawer, setTogglePermissionsDrawer] = useState(false)
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([])
  const [selectedCollab, setSelectedCollab] = useState<User>({} as User)
  const [collabsList, setCollabsList] = useState<User[]>([])
  const [isRemoveAccessModalOpen, setIsRemoveAccessModalOpen] = useState(false)
  const [collabsToRemoveAccess, setCollabsToRemoveAccess] = useState<User[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo | undefined>({} as PageInfo)

  const handleTogglePermissionsDrawer = useCallback(() => {
    setTogglePermissionsDrawer((prevState) => !prevState)
  }, [])

  const updateCollabsList = useCallback((newCollabs: User[]) => {
    setCollabsList(newCollabs)
  }, [])

  const incrementCollabsList = useCallback((newCollabs: User[]) => {
    setCollabsList((prevState) => [...prevState, ...newCollabs])
  }, [])

  const updatePageInfo = useCallback((newPageInfo: PageInfo) => {
    setPageInfo(newPageInfo)
  }, [])

  const addCollabsToList = useCallback((collab: User) => {
    setCollabsToRemoveAccess((prevState) => [...prevState, { ...collab }])
  }, [])

  const removeCollabsFromList = useCallback((id: string) => {
    setCollabsToRemoveAccess((prevState) => prevState.filter((item) => item.id.toString() !== id))
  }, [])

  const removeAllCollabsFromList = useCallback(() => {
    setCollabsToRemoveAccess([])
  }, [])

  const toggleRemoveAccessModal = useCallback((value: boolean) => {
    setIsRemoveAccessModalOpen(value)
  }, [])

  const resetState = useCallback(() => {
    setTogglePermissionsDrawer(false)
    setSelectedPermissions([])
    setCollabsList([])
    setPageInfo({} as PageInfo)
  }, [])

  return (
    <PermissionsContext.Provider
      value={{
        togglePermissionsDrawer,
        handleTogglePermissionsDrawer,
        selectedPermissions,
        setSelectedPermissions,
        resetState,
        setSelectedCollab,
        selectedCollab,
        collabsList,
        updateCollabsList,
        pageInfo,
        updatePageInfo,
        incrementCollabsList,
        addCollabsToList,
        removeCollabsFromList,
        collabsToRemoveAccess,
        toggleRemoveAccessModal,
        isRemoveAccessModalOpen,
        removeAllCollabsFromList,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  )
}

export const usePermissions = () => useContext(PermissionsContext)
