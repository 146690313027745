import { useDisclosure, useToast } from '@chakra-ui/react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import Content from 'src/modules/cms/domain/Content'
import { makeListImportedContents } from 'src/modules/cms/factories/makeListImportedContents'
import { makeUpdateContent } from 'src/modules/cms/factories/makeUpdateContent'
import { usePartners } from 'src/modules/cms/hooks/usePartners'
import { ListContentsInput, SaveImportedContentByActionType } from 'src/modules/cms/types/IContentRepository'
import { getOrderFilterField } from '../utils/getOrderFilterField'

type ListImportedContentsParams = Pick<
  ListContentsInput,
  'title' | 'skills' | 'contentIds' | 'partnerId' | 'type' | 'status'
>

interface ImportedContentsContextProps {
  importedContents: Content[]
  selectedImportedContent?: Content
  loadingContents: boolean
  onOpenEditImportedContentsModal: () => void
  onCloseEditImportedContentsModal: () => void
  isEditImportedContentsModalOpen: boolean
  listImportedContents: (params?: ListImportedContentsParams) => Promise<any>
  handleEditContent: (content: Content) => void
  handleRejectContent: (content: Content) => void
  updateSelectedContent: (content?: Content) => void
  paginationInfo: { limit: number; skip: number; total: number }
  updateLimitPerPage: (limit: number) => void
  updateSkip: (skip: number) => void
  handlePreviousPage: () => void
  handleNextPage: () => void
  filters: ImportedContentsFilter
  applyFilters: (filter: ImportedContentsFilterType, value?: any) => void
  resetAllFilters: () => void
  updateTotalImportedContents: (total: number) => void
  resetPaginationInfo: () => void
  executeActionOnImportedContents: (
    editedSelectedContent: Content,
    action: SaveImportedContentByActionType,
  ) => Promise<void>
  loadingSaveImportedContents: boolean
  onOpenRejectImportedContentsModalOpen: () => void
  onCloseRejectImportedContentsModalOpen: () => void
  isRejectImportedContentsModalOpen: boolean
}

type ImportedContentsFilterType = 'order' | 'availableFor' | 'type' | 'status' | 'partner' | 'importedDate' | 'title' | 'languages'
type ImportedContentsFilter = { [key in ImportedContentsFilterType]: any }

const ImportedContentsContext = createContext({} as ImportedContentsContextProps)
const emptyFilters: ImportedContentsFilter = {
  availableFor: undefined,
  order: 'NAME_ASC',
  status: undefined,
  type: undefined,
  partner: undefined,
  importedDate: undefined,
  title: undefined,
  languages: undefined
}

export const ImportedContentsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [loadingContents, setLoadingContents] = useState(false)
  const [loadingSaveImportedContents, setLoadingSaveContents] = useState(false)
  const [importedContents, setImportedContents] = useState<Content[]>([])
  const [paginationInfo, setPaginationInfo] = useState({ alreadyLoad: false, limit: 25, skip: 0, total: 0 })
  const [filters, setFilters] = useState<ImportedContentsFilter>(emptyFilters)
  const [selectedImportedContent, setSelectedImportedContent] = useState<Content | undefined>(undefined)

  const { partnersList } = usePartners()
  const selectedPartner = useMemo(() => partnersList.find((partner) => partner.slug.includes('edx')), [partnersList])

  const toast = useToast()

  const {
    onOpen: onOpenEditImportedContentsModal,
    onClose: onCloseEditImportedContentsModal,
    isOpen: isEditImportedContentsModalOpen,
  } = useDisclosure()
  const {
    onOpen: onOpenRejectImportedContentsModalOpen,
    onClose: onCloseRejectImportedContentsModalOpen,
    isOpen: isRejectImportedContentsModalOpen,
  } = useDisclosure()

  const getImportedContents = makeListImportedContents()

  const listImportedContents = async (params?: ListImportedContentsParams): Promise<void> => {
    try {
      let selectedPartnerId = partnersList.find((partner) => partner.slug === 'alura')?.id
      setLoadingContents(true)
      const importedContents = await getImportedContents.execute({
        limit: paginationInfo.limit,
        skip: paginationInfo.skip,
        order: getOrderFilterField(filters.order),
        partnerId: selectedPartnerId,
        ...(params?.title ? { title: params.title } : {}),
        ...(params?.skills ? { skills: params.skills } : {}),
        ...(params?.contentIds ? { contentIds: params.contentIds } : {}),
        ...(filters.type ? { type: filters.type } : {}),
        ...(filters.status ? { status: filters.status } : {}),
        ...(filters.title ? { title: filters.title } : {}),
        ...(filters?.languages?.length > 0 ? { languages: filters.languages } : {}),
      })
      setImportedContents(importedContents.items)
      setPaginationInfo((prev) => ({ ...prev, total: importedContents.total }))
    } catch {
      console.error('error listing contents')
    } finally {
      setLoadingContents(false)
    }
  }

  const handleEditContent = (content: Content) => {
    setSelectedImportedContent(content)
    onOpenEditImportedContentsModal()
  }

  const handleRejectContent = (content: Content) => {
    setSelectedImportedContent(content)
    onOpenRejectImportedContentsModalOpen()
  }

  const updateSelectedContent = (content?: Content) => {
    setSelectedImportedContent(content)
  }

  const updateLimitPerPage = (limit: number) => {
    setPaginationInfo((prev) => ({ ...prev, limit }))
  }

  const updateSkip = (skip: number) => {
    setPaginationInfo((prev) => ({ ...prev, skip }))
  }

  const handlePreviousPage = () => {
    setPaginationInfo((prev) => {
      if (prev.skip - prev.limit <= 0) return { ...prev, skip: 0 }
      return { ...prev, skip: prev.skip - prev.limit }
    })
  }

  const handleNextPage = () => {
    setPaginationInfo((prev) => {
      if (prev.skip + prev.limit >= prev.total) return { ...prev }
      return { ...prev, skip: prev.skip + prev.limit }
    })
  }

  const updateTotalImportedContents = (total: number) => {
    setPaginationInfo((prev) => ({ ...prev, total }))
  }

  const resetPaginationInfo = () => {
    setPaginationInfo({ alreadyLoad: true, limit: 25, skip: 0, total: 0 })
  }

  const applyFilters = (filter: ImportedContentsFilterType, value?: any) => {
    setFilters((prev) => ({ ...prev, [filter]: value }))
  }

  const resetAllFilters = () => {
    setFilters(emptyFilters)
  }

  const executeActionOnImportedContents = async (
    editedImportedContent: Content,
    action: SaveImportedContentByActionType,
  ) => {
    try {
      if (!editedImportedContent) throw new Error('Você deve selecionar um conteúdo')
      if (!selectedPartner) throw new Error('Esse conteúdo não possui um parceiro definido')
      setLoadingSaveContents(true)
      const saveImportedContents = makeUpdateContent()
      const correctAction = action === 'publish' ? 'PUBLISHED' : 'DRAFT'
      const isSaved = await saveImportedContents.execute({
        ...editedImportedContent,
        duration: editedImportedContent.duration ?? 1,
        partner: editedImportedContent.partner?.id,
        updatedStatus: correctAction,
        languages: editedImportedContent.languages,
        skills: editedImportedContent.skills?.map((skill) => ({ value: skill.id })),
      })
      if (!isSaved) throw new Error('Não foi possível salvar o conteúdo. Tente novamente!')
    } catch (err: any) {
      toast({
        title: err.message,
        isClosable: true,
        status: 'error',
      })
    } finally {
      setLoadingSaveContents(false)
    }
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) setPaginationInfo((prev) => ({ ...prev, alreadyLoad: true }))
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    if (paginationInfo.alreadyLoad === true) {
      listImportedContents()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo.skip])

  useEffect(() => {
    if (paginationInfo.alreadyLoad === true) {
      listImportedContents()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo.limit])

  return (
    <ImportedContentsContext.Provider
      value={{
        loadingContents,
        importedContents,
        selectedImportedContent,
        paginationInfo,
        updateLimitPerPage,
        isEditImportedContentsModalOpen,
        onCloseEditImportedContentsModal,
        onOpenEditImportedContentsModal,
        listImportedContents,
        handleEditContent,
        handleRejectContent,
        updateSelectedContent,
        handleNextPage,
        handlePreviousPage,
        updateSkip,
        filters,
        applyFilters,
        resetAllFilters,
        resetPaginationInfo,
        updateTotalImportedContents,
        executeActionOnImportedContents,
        loadingSaveImportedContents,
        isRejectImportedContentsModalOpen,
        onCloseRejectImportedContentsModalOpen,
        onOpenRejectImportedContentsModalOpen,
      }}
    >
      {children}
    </ImportedContentsContext.Provider>
  )
}

export const useImportedContents = () => {
  const context = useContext(ImportedContentsContext)
  if (!context) throw new Error('useImportedContents must be used within a ImportedContentsContextProvider')
  return context
}
