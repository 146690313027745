import { Button, Spinner } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useCorporateUniversityStore } from 'src/pages-admin/Cms/hooks/useCorporateUniversityStore'

interface DrawerButtonsProps {
  handleRemoveContent: () => Promise<void>
  onClose: () => void
  loading: boolean
}

export function DrawerButtons({ handleRemoveContent, loading, onClose }: DrawerButtonsProps) {
  const [t] = useTranslation()
  const { selectedContent } = useCorporateUniversityStore()

  const getLabel = () => {
    if (selectedContent?.id) return t('wantToRemove')
    return t('wantToRemovePlaylist')
  }

  return (
    <>
      <Button
        variant='outline'
        onClick={handleRemoveContent}
        minW='310px'
        data-testid='cms-remove-drawer-confirm-button'
      >
        {loading ? <Spinner /> : getLabel()}
      </Button>
      <Button
        variant='unstyled'
        bg='gray.800'
        color='white'
        fontWeight='bold'
        padding='4px 12px'
        fontSize='.875rem'
        onClick={onClose}
        data-testid='cms-remove-drawer-cancel-button'
      >
        {t('noThanks')}
      </Button>
    </>
  )
}
