import { useMutation } from '@apollo/client'
import { Button, Flex, Input, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useTeams } from '../../context-admin/useTeams'
import { DELETE_TEAM } from './mutations'

type Props = {
  handleComponentChange: (value: string) => void
}

const DeleteTeam: React.FC<Props> = ({ handleComponentChange }) => {
  const { setSuccessText, handleSelectedOptions, selectedTeam } = useTeams()

  const [removeTeam, { loading }] = useMutation(DELETE_TEAM)

  const [text, setText] = useState('')

  const confirmationText = `Quero excluir a Equipe ${selectedTeam.name}`

  const handleContinue = async () => {
    await removeTeam({ variables: { id: selectedTeam.id } })
    setSuccessText(`“${selectedTeam.name}” foi excluída com sucesso!`)
    handleSelectedOptions('DELETE_TEAM')
    handleComponentChange('SUCCESS')
  }

  return (
    <>
      <Text
        color='#767F89'
        textDecoration='underline'
        fontFamily='Poppins'
        fontWeight='600'
        marginBottom='22px'
        cursor='pointer'
        onClick={() => handleComponentChange('0')}
      >
        Voltar
      </Text>
      <Flex flexDir='column' align='flex-start'>
        <Text fontSize='20px' fontWeight={600} fontFamily='Poppins' color='gray.31'>
          Confirmação de exclusão de {selectedTeam.name}
        </Text>
        <Text color='#767F89' mt='16px' lineHeight='20px' fontWeight='600'>
          Ao excluir uma equipe você ainda poderá acompanhar individualmente o progresso dos integrantes que a compõem
          no momento.
          <br />
          Uma vez que a equipe for excluída seu gestor atual perde automaticamente o acesso aos relatórios de equipe.
        </Text>

        <Text fontWeight='600' mt='20px' mb='20px'>
          Confirme esta ação digitando a frase abaixo no campo de texto: {confirmationText}
        </Text>

        <Flex w='100%'>
          <Input
            height='36px'
            pl='16px'
            pr='16px'
            borderRadius='12px'
            value={text}
            onChange={(e) => setText(e.target.value)}
            data-testid='remove-team-confirmation-input'
          />
          <Button
            ml='8px'
            variant='solidGray'
            fontSize='12px'
            isLoading={loading}
            disabled={confirmationText.toLowerCase() !== text.toLowerCase()}
            onClick={handleContinue}
          >
            Confirmar
          </Button>
        </Flex>

        <Button
          mt='32px'
          variant='whiteOutline'
          fontSize='12px'
          isLoading={loading}
          onClick={() => handleComponentChange('0')}
        >
          Voltar
        </Button>
      </Flex>
    </>
  )
}

export default DeleteTeam
