import { Flex } from '@chakra-ui/react'
import ReactSelect from 'react-select'
import { useQuery } from "@tanstack/react-query";
import { makeGetSubsidiaries } from '../factory/makeGetSubsidiaries';
import { MultiSelectStylesForDashboards } from 'src/pages-admin/Cms/utils/multiSelectStyles';
import { Option } from './ReportContent';
import { useTranslation } from 'react-i18next';

interface SelectSubsidiariesProps {
  subsidiariesSelected: Option[],
  setSubsidiariesSelected: (value: Option[]) => void
}

export const SelectSubsidiaries = ({ subsidiariesSelected, setSubsidiariesSelected }: SelectSubsidiariesProps) => {
  const [t] = useTranslation()
  const { data: subsidiaries, isLoading: isLoadingSubsidiaries } = useQuery({
    queryKey: [
      "subsidiaries",
    ],
    queryFn: () => {
      const getReport = makeGetSubsidiaries()
      return getReport.execute()
    },
    refetchOnWindowFocus: false,
    initialData: [],
  });

  return (
    <Flex direction='column' flex={1} minW="250px" maxW="300px">
      <ReactSelect
        isSearchable
        isMulti
        styles={MultiSelectStylesForDashboards}
        placeholder={t('admin.reports.select_branches')}
        value={subsidiariesSelected}
        options={subsidiaries
          .map((subsidiarie) => ({ value: subsidiarie.id, label: subsidiarie.name }))
        }
        onChange={(selectedOption) => {
          setSubsidiariesSelected(selectedOption as Option[])
        }}
        isLoading={isLoadingSubsidiaries}
      />
    </Flex>
  )
}
