import { UseCase } from 'src/types/UseCase'
import { ReportRepository } from '../repository/ReportRepository'

export type GetUsersUseCaseOutput = {
  id: number
  name: string
}[]

export type GetUsersUseCaseInput = {
  areas?: number[]
  subsidiaries?: number[]
}

export class GetUsersUseCase implements UseCase<GetUsersUseCaseInput, GetUsersUseCaseOutput> {
  constructor(private repository: ReportRepository) {}

  async execute(params: GetUsersUseCaseInput): Promise<GetUsersUseCaseOutput> {
    const report = await this.repository.getUsers(params)
    return report
  }
}
