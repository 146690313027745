import { useTranslation } from 'react-i18next'

const GetQuizCompetences = () => {
  const [t] = useTranslation()

  const quizCompetences = [
    t('quiz.stepTwo.options.leadership'),
    t('quiz.stepTwo.options.cooperation'),
    t('quiz.stepTwo.options.influence'),
    t('quiz.stepTwo.options.scenarioSnalysis'),
    t('quiz.stepTwo.options.innovation'),
    t('quiz.stepTwo.options.execution'),
    t('quiz.stepTwo.options.adaptation'),
    t('quiz.stepTwo.options.achievement'),
  ]

  return quizCompetences
}

export { GetQuizCompetences }
