import { UseCase } from "src/types/UseCase";
import { AreaRepository, RenameExistingAreaInput } from "../repository/AreaRepository";

type RenameExistingAreaOutput = boolean

export class RenameExistingArea implements UseCase<RenameExistingAreaInput, RenameExistingAreaOutput>{
  constructor(private areaRepository: AreaRepository){}

  async execute(args: RenameExistingAreaInput): Promise<RenameExistingAreaOutput> {
      const wasRenamed = await this.areaRepository.renameExistingArea({ 
        areaId: args?.areaId,
        newName: args?.newName
      })
      return wasRenamed
    }
}