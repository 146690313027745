import { getAccessToken } from 'src/modules/iam/apis/store'
import httpClient from 'src/services/http/http-client'
import { CompanyRepository } from '../repositories/Company'
import { ValidatePartnerTokenUseCase } from '../use_cases/ValidatePartnerTokenUseCase'

export function makeValidatePartnerToken() {
  const token = getAccessToken()
  const companyRepository = new CompanyRepository(token, httpClient)
  return new ValidatePartnerTokenUseCase(companyRepository)
}
