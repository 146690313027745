import { gql } from '@apollo/client'

export const GET_COMPANIES = gql`
  query listCompanies($limit: Int, $page: Int, $filter: ListCompaniesFilter) {
    listCompanies(limit: $limit, page: $page, filter: $filter) {
      paginateInfo {
        hasPreviousPage
        hasNextPage
        total
        count
      }
      data {
        id
        trade_name
      }
    }
  }
`
