import { User } from 'src/types/User'
import { create } from 'zustand'

interface Props {
  isAllocateSkillModalOpen: boolean
  updateAllocateSkillModalState: (value: boolean) => void

  selectedCollabs: User[]
  setSelectedCollabs: (items: User[]) => void
  updateSelectedCollabs: (item: User) => void
}

export const useAllocateSkillStore = create<Props>((set) => ({
  isAllocateSkillModalOpen: false,
  updateAllocateSkillModalState: (value) => set(() => ({ isAllocateSkillModalOpen: value })),

  selectedCollabs: [],
  setSelectedCollabs: (items) => set(() => ({ selectedCollabs: items })),
  updateSelectedCollabs: (item) =>
    set((prevState) => ({ selectedCollabs: [...prevState.selectedCollabs, item] ?? [] })),
}))
