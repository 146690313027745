import { Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleTeam } from 'src/context-admin/useHandleTeam'
import { NavigationButtons } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/NavigationButtons'
import { StepTitle } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTitle'
import { User } from 'src/types/User'
import { UserList } from '../../commonComponents/UserList'
import { CurrentManagerCard } from './CurrentManagerCard'

export function EditExistingTeamChangeManager() {
  const [t] = useTranslation()
  const [selectedNewManager, setSelectedNewManager] = useState<User | undefined>(undefined)
  const { team, navigateToStep, handleSetTeamValues } = useHandleTeam()

  const onSelectNewManager = (users: User[]) => {
    setSelectedNewManager(users[0])
  }

  useEffect(() => {
    if (selectedNewManager) {
      const currentManager = team?.newManagers?.[0]
      const newManager = selectedNewManager
      if (currentManager) handleSetTeamValues({ newManagers: [currentManager, newManager] })
    } else {
      const currentManager = team?.newManagers?.[0]
      if (currentManager) handleSetTeamValues({ newManagers: [currentManager] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNewManager])

  return (
    <Flex flexDir='column' gap='24px'>
      <StepTitle title='Troca de gestor' />
      <Flex flexDir='column' gap='24px'>
        <Flex flexDir='column' gap='12px'>
          <Text fontSize='1rem' color='#313e4c' fontWeight='700'>
            {t('newManager')}
          </Text>
          <CurrentManagerCard user={team?.newManagers?.[0]} />
        </Flex>

        <Flex flexDir='column' gap='12px'>
          <Text fontSize='1rem' color='#313e4c' fontWeight='700'>
            {t('selectTheManagerToBeRemoved', { teamName: team?.name ?? t('invalidName') })}
          </Text>
          <UserList
            type='select-manager'
            data={team?.managers?.filter((m) => m.id !== team?.newManagers?.[0]?.id)}
            withFilter={false}
            withSearch={false}
            onSelectUsers={onSelectNewManager}
          />
        </Flex>
      </Flex>
      <NavigationButtons
        isContinueDisabled={!selectedNewManager}
        onContinueClick={() => {
          navigateToStep('edit-existing-team--change-manager-confirm')
        }}
        onBackClick={() => {
          navigateToStep('edit-existing-team--change-manager-select-new')
        }}
      />
    </Flex>
  )
}
