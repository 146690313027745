import { gql } from '@apollo/client'

const LIST_TEAMS = gql`
  query listTeams {
    listTeams {
      id
      name
      manager {
        id
        name
      }
      members {
        id
        name
      }
    }
  }
`

export default LIST_TEAMS
