import mixpanel from 'mixpanel-browser'

const TOKEN =
  window.location.host === 'app.education-journey.com'
    ? process.env.REACT_APP_MIXPANEL_PROD
    : process.env.REACT_APP_MIXPANEL_DEV

mixpanel.init(TOKEN!, {
  ignore_dnt: true,
})

let actions = {
  identify: (id: string) => {
    mixpanel.identify(id)
  },
  alias: (id: string) => {
    mixpanel.alias(id)
  },
  track: (name: string, props: Record<string, unknown>) => {
    mixpanel.track(name, props)
  },
  people: {
    set: (props: Record<string, unknown>) => {
      mixpanel.people.set(props)
    },
  },
}

export let Mixpanel = actions
