import { getAccessToken } from 'src/modules/iam/apis/store'
import httpClient from 'src/services/http/http-client'
import { CompanyRepository } from '../repository/Company'

interface DeactivateCompanyInput {
  companyId: string
}

export class DeactivateCompanyUseCase {
  constructor(private repository: CompanyRepository) {}

  async execute(args: DeactivateCompanyInput): Promise<boolean> {
    const success = await this.repository.deactiveCompany(args.companyId)
    return success
  }
}

export function makeDeactivateCompanyUseCase() {
  const token = getAccessToken()
  const repository = new CompanyRepository(token, httpClient)
  return new DeactivateCompanyUseCase(repository)
}
