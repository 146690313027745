import { Box, Heading } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useQuiz } from 'src/context/QuizContext'
import useRegisterUserEvent from 'src/hooks/useRegisterUserEvent'
import { LevelType } from 'src/types/LevelType'
import ButtonsFooter from './ButtonsFooter'
import Level from './Level'
import StepHeader from './StepHeader'
import { generateLevelsObject, generateLevelsString } from './utils'

interface Props {
  onContinueButtonClick: () => void
  onBackButtonClick: () => void
  onSkipButtonClick: () => void
}

const StepSix: React.FC<Props> = ({ onContinueButtonClick, onBackButtonClick, onSkipButtonClick }: Props) => {
  const { quizResults, updateQuiz, sendMutationQuiz } = useQuiz()
  const registerUserEvent = useRegisterUserEvent()
  const [t] = useTranslation()

  const levels = generateLevelsObject(
    quizResults.skillsEndingPoint || [],
    generateLevelsObject(quizResults.skillsStartingPoint || []),
  )

  const updateLevel = (level: LevelType, newLevel: number) => {
    if (level.minLevel) if (level.minLevel > newLevel) return
    const nLevels = levels.map((levelMap: LevelType) => {
      if (level.title === levelMap.title) levelMap.level = newLevel
      return levelMap
    })
    updateQuiz('skillsEndingPoint', generateLevelsString(nLevels))
  }

  const handleNextStep = () => {
    sendMutationQuiz()
    onContinueButtonClick()
    runBetweenLevelsEvent()
    registerUserEvent('quiz-tools-ending-level', quizResults.skillsEndingPoint || [])
  }

  const runBetweenLevelsEvent = () => {
    const betweenLevels: string[] = []

    const skillsStartingPoint = quizResults.skillsStartingPoint || []
    const skillsEndingPoint = quizResults.skillsEndingPoint || []

    skillsStartingPoint.forEach((skill: string, index: number) => {
      const name = skill.split(':')[0]

      const startingLevel = Number(skill.split(':')[1])
      const endingLevel = Number(skillsEndingPoint[index].split(':')[1])
      const hasLevelBetween = startingLevel !== endingLevel || endingLevel + 1 > startingLevel

      if (!hasLevelBetween) return

      for (let i = startingLevel + 1; i < endingLevel; i++) betweenLevels.push(`${name}:${i}`)
    })

    registerUserEvent('quiz-tools-in-between-levels', betweenLevels || [])
  }

  return (
    <>
      <StepHeader step={6} />
      <Box flex='1'>
        <Heading size='xs' marginBottom='16px'>
          {t('quiz.stepSix.desiredSkillLevel')}
        </Heading>
        {levels.map((level: LevelType) => (
          <Level
            key={level.title}
            title={level.title}
            level={level.level}
            minLevel={level.minLevel}
            onChangeLevel={(newLevel: number) => updateLevel(level, newLevel)}
          />
        ))}
      </Box>
      <ButtonsFooter
        step={6}
        onSkipButtonClick={onSkipButtonClick}
        onContinueButtonClick={handleNextStep}
        onBackButtonClick={onBackButtonClick}
      />
    </>
  )
}

export default StepSix
