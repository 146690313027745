import { Icon, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { useEffect } from 'react'
import { IoCloseSharp } from 'react-icons/io5'
import { useLocation } from 'react-router-dom'
import { useQuiz } from 'src/context/QuizContext'
import { EventsImplementation } from 'src/hooks/Mixpanel/types'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import StepEight from './StepEight'
import StepFive from './StepFive'
import StepFour from './StepFour'
import StepOne from './StepOne'
import StepSeven from './StepSeven'
import StepSix from './StepSix'
import StepThree from './StepThree'
import StepTwo from './StepTwo'
import StepZero from './StepZero'

interface Props {
  onClose: () => void
  isOpen: boolean
  isOnboarding?: boolean
}

const QuizModal: React.FC<Props> = ({ onClose, isOpen, isOnboarding = false }: Props) => {
  const { track } = useMixpanel()
  const location = useLocation()
  const { onHalfConcluded, quizState, updateStep } = useQuiz()

  useEffect(() => {
    track(EventsImplementation.QUIZ_ACCESS_QUIZ(location.pathname))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const skip = () => {
    onHalfConcluded(quizState.step)
    onClose()
  }

  const handleStep = (step: number) => {
    updateStep(step)
  }

  const steps = [
    <StepZero onContinueButtonClick={() => handleStep(1)} onSkipButtonClick={skip} isOnboarding={isOnboarding} />,
    <StepOne onContinueButtonClick={() => handleStep(2)} onSkipButtonClick={skip} />,
    <StepTwo
      onContinueButtonClick={() => handleStep(3)}
      onSkipButtonClick={skip}
      onBackButtonClick={() => handleStep(1)}
    />,
    <StepThree
      onContinueButtonClick={() => handleStep(4)}
      onSkipButtonClick={skip}
      onBackButtonClick={() => handleStep(2)}
    />,
    <StepFour
      onContinueButtonClick={() => handleStep(5)}
      onSkipButtonClick={skip}
      onBackButtonClick={() => handleStep(3)}
    />,
    <StepFive
      onContinueButtonClick={() => handleStep(6)}
      onSkipButtonClick={skip}
      onBackButtonClick={() => handleStep(4)}
    />,
    <StepSix
      onContinueButtonClick={() => handleStep(7)}
      onSkipButtonClick={skip}
      onBackButtonClick={() => handleStep(5)}
    />,
    <StepSeven
      onContinueButtonClick={() => handleStep(8)}
      onSkipButtonClick={skip}
      onBackButtonClick={() => handleStep(6)}
    />,
    <StepEight onClose={onClose} />,
  ]

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} autoFocus={false} scrollBehavior='outside'>
        <ModalOverlay>
          <ModalContent
            borderRadius='30px'
            w='90%'
            maxW='1008px'
            minH='90%'
            position='relative'
            padding='34px 55px'
            overflow='visible'
          >
            <Icon
              w='20px'
              h='20x'
              position='absolute'
              top='25px'
              right='25px'
              color='bluishGray.300'
              as={IoCloseSharp}
              onClick={onClose}
            />
            {steps[quizState.step]}
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  )
}

export default QuizModal
