import { createContext, useContext, useState } from 'react'
import Content from 'src/modules/cms/domain/Content'

export interface ContentDrawerInfo {
  moduleTitle: string
  moduleId?: string
  content: Content
  type?: 'KNOW_MORE' | 'SWAP' | 'ADD'
}

interface Value {
  drawerInfo?: ContentDrawerInfo
  setDrawerInfo: (info: ContentDrawerInfo | undefined) => void
  closeText: string
  setCloseText: (text: string) => void
  closeLearningItemDrawer: () => void
}

interface Props {
  children: React.ReactNode[] | React.ReactNode
}

const KnowMoreDrawerContext = createContext({} as Value)

export function KnowMoreDrawerProvider({ children }: Props) {
  const [drawerInfo, setDrawerInfo] = useState<ContentDrawerInfo>()
  const [closeText, setCloseText] = useState('')

  const closeLearningItemDrawer = () => setDrawerInfo(undefined)

  return (
    <KnowMoreDrawerContext.Provider
      value={{
        drawerInfo,
        setDrawerInfo,
        closeText,
        setCloseText,
        closeLearningItemDrawer,
      }}
    >
      {children}
    </KnowMoreDrawerContext.Provider>
  )
}

export const useKnowMoreDrawer = () => useContext(KnowMoreDrawerContext)
