import { useMutation } from '@apollo/client'
import { Button, Flex, Heading } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HorizontalLearningItemCard } from 'src/components/HorizontalLearningItemCard'
import { useAddContentDrawer } from 'src/context/CustomPlaylist/AddContentDrawerContext'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { ADD_LEARNING_ITEMS_TO_PLAYLIST } from 'src/modules/cms/graphql/mutations/ADD_LEARNING_ITEMS_TO_PLAYLIST'

interface Props {
  handleStep: (page: number) => void
}

const LearningItemsConfirmation: React.FC<Props> = ({ handleStep }) => {
  const [t] = useTranslation()
  const [addLearningItem] = useMutation(ADD_LEARNING_ITEMS_TO_PLAYLIST)
  const { EventsImplementation, track } = useMixpanel()
  const { newContents, setAddContentKnowMoreDrawer, addContentModuleInfo } = useAddContentDrawer()

  useEffect(() => {
    if (newContents.length === 0) {
      handleStep(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newContents])

  const handleAddContent = async () => {
    const newItems = newContents.map((item) => item.title).toString()
    const newItemsIds = newContents.map((item) => item.id)

    track(EventsImplementation.ADD_LEARNING_ITEM_CONFIRMATION(addContentModuleInfo!.title, newItems, newItems.length))
    await addLearningItem({
      variables: { learningItemsEntryId: newItemsIds, moduleId: addContentModuleInfo!.id },
    }).then(() => handleStep(2))
  }

  const handleBackToSuggestions = () => {
    handleStep(0)
  }

  return (
    <Flex direction='column' height='100%'>
      <Heading size='xs' fontWeight='600' marginBottom='21px' color='bluishGray.500'>
        {t('home.Custom Playlist.Confirmation of added content')}
      </Heading>

      <Heading size='xs' fontWeight='600' marginBottom='21px'>
        {t('home.Custom Playlist.You are adding to your playlist')}
      </Heading>

      <Flex flex={1} direction='column' gap='16px' maxH='calc(100% - 200px)' overflowY='auto'>
        {newContents.map((item) => (
          <HorizontalLearningItemCard
            key={Math.random()}
            content={item}
            showImage
            showMarkButtons={false}
            showCustomPlaylistButtons={false}
            showProficiency={false}
            moduleName={addContentModuleInfo?.title}
            showAddItemButtons
            showRemoveButton
            onKnowMoreClick={setAddContentKnowMoreDrawer}
          />
        ))}
      </Flex>

      <Flex justify='space-between' mt='24px' flexWrap='wrap' gap='12px' w='100%'>
        <Button
          fontSize={{ base: '.75rem', md: '1rem' }}
          variant='startCourse'
          h='48px'
          border='2px solid #E5E5E5'
          onClick={handleBackToSuggestions}
          flexGrow='1'
        >
          {t('home.Custom Playlist.Back to suggestions')}
        </Button>

        <Button
          fontSize={{ base: '.75rem', md: '1rem' }}
          variant='startCourseDark'
          height='48px'
          padding='12px 24px'
          onClick={handleAddContent}
          flexGrow='1'
          data-testid='confirmation-learning-item-add-contents'
        >
          {t('home.Custom Playlist.Add content')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default LearningItemsConfirmation
