import { UseCase } from "src/types/UseCase";
import { AreaRepository, CreateNewAreaInput, CreateNewAreaOutput } from "../repository/AreaRepository";

export class CreateNewAreaUseCase implements UseCase<CreateNewAreaInput, CreateNewAreaOutput>{
  constructor(private areaRepository: AreaRepository){}

  async execute(args: CreateNewAreaInput): Promise<CreateNewAreaOutput> {
      const wasCreated = await this.areaRepository.createNewArea({ 
        areaName: args?.areaName, 
        managerId: args?.managerId, 
      })
      return wasCreated
    }
}