import { Button, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { EventsImplementation } from 'src/hooks/Mixpanel/types'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'

interface Props {
  step: number
  disabledButton?: boolean
  onSkipButtonClick?: () => void
  onBackButtonClick?: () => void
  onContinueButtonClick?: () => void
  onFinishButtonClick?: () => void
}

const ButtonsFooter: React.FC<Props> = ({
  step,
  disabledButton = false,
  onSkipButtonClick,
  onBackButtonClick,
  onContinueButtonClick,
  onFinishButtonClick,
}: Props) => {
  const { track } = useMixpanel()
  const [t] = useTranslation()

  return (
    <Flex alignItems='center' justifyContent='space-between'>
      <Text
        onClick={() => {
          track(EventsImplementation.QUIZ_DO_IT_LATER(step.toString()))
          onSkipButtonClick!()
        }}
        color='gray.260'
        fontFamily='Poppins'
        userSelect='none'
        cursor='pointer'
      >
        {t('quiz.footer.doLater')}
      </Text>
      {step !== 0 && (
        <Flex align={'center'} justifyContent='space-between'>
          <Text
            onClick={() => {
              track(EventsImplementation.QUIZ_RETURN(step.toString()))
              onBackButtonClick!()
            }}
            mr='36px'
            color='gray.260'
            fontFamily='Poppins'
            userSelect='none'
            cursor='pointer'
            display={step === 1 ? 'none' : 'initial'}
          >
            {t('quiz.footer.back')}
          </Text>
          <Button
            variant='startCourseDark'
            height='48px'
            padding='12px 24px'
            disabled={disabledButton}
            onClick={() => {
              track(EventsImplementation.QUIZ_NEXT_STEP(step.toString()))
              if (step === 7) onFinishButtonClick!()
              else onContinueButtonClick!()
            }}
          >
            {step === 7 ? t('quiz.footer.finish') : t('quiz.footer.continue')}
          </Button>
        </Flex>
      )}
    </Flex>
  )
}

export default ButtonsFooter
