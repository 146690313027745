import { getAccessToken } from "src/modules/iam/apis/store"
import { cmsClient } from "src/services/http/cms-client"
import { PlaylistRepository } from "../repositories/PlaylistRepository"
import { CreatePlaylistTranslationsUseCase } from "../use_cases/CreatePlaylistTranslationsUseCase"

export function makeCreatePlaylistTranslations() {
  const token = getAccessToken()
  const playlistRepository = new PlaylistRepository(cmsClient, token)
  return new CreatePlaylistTranslationsUseCase(playlistRepository)
}
