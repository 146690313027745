export enum ErrorsEnum {
  QUIZ_ALREADY_ANSWERED = 'QUIZ_ALREADY_ANSWERED',
  NO_CONTENT_RECOMMENDED = 'NO_CONTENT_RECOMMENDED',
  NO_CONTENT_FOR_SKILL = 'NO_CONTENT_FOR_SKILL',
  MISSING_CONTENT_VIDEO_FIFTEEN = 'MISSING_CONTENT_VIDEO_FIFTEEN',
  MISSING_CONTENT_VIDEO_SIXTY = 'MISSING_CONTENT_VIDEO_SIXTY',
  MISSING_CONTENT_ARTICLE = 'MISSING_CONTENT_ARTICLE',
  MISSING_CONTENT_COURSE_FIVE = 'MISSING_CONTENT_COURSE_FIVE',
  MISSING_CONTENT_COURSE_MORE_FIVE = 'MISSING_CONTENT_COURSE_MORE_FIVE',
  MISSING_CONTENT = 'MISSING_CONTENT',
}
