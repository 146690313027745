import { useMutation } from '@apollo/client'
import { Button, Flex, Text, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { useTeams } from '../../context-admin/useTeams'
import { ChangeIcon } from '../../theme/Icons'
import { CHANGE_TEAM_MANAGER } from './mutations'

type Props = {
  handleComponentChange: (value: string) => void
}

const ChangeManagerStepTwo: React.FC<Props> = ({ handleComponentChange }) => {
  const { selectedTeam, newManager, setSuccessText, handleSelectedOptions, setSelectedTeam } = useTeams()

  const [updateManager] = useMutation(CHANGE_TEAM_MANAGER)
  const [loading, setLoading] = useState(false)

  const handleBack = () => {
    handleComponentChange('CHANGE_MANAGER')
  }

  const handleNextStep = async () => {
    setLoading(true)

    const updatedTeam = await updateManager({
      variables: { teamId: selectedTeam.id, managerId: Number(newManager.id) },
    })

    setSelectedTeam(updatedTeam.data.changeTeamManager)
    setSuccessText(`O gestor de “${selectedTeam.name}” foi alterado com sucesso!`)
    handleSelectedOptions('CHANGE_MANAGER')
    handleComponentChange('SUCCESS')
  }

  return (
    <>
      <Text
        color='#767F89'
        textDecoration='underline'
        fontFamily='Poppins'
        fontWeight='600'
        marginBottom='22px'
        cursor='pointer'
        onClick={handleBack}
      >
        Voltar
      </Text>

      <VStack align='flex-start'>
        <Text fontSize='20px' fontWeight={600} fontFamily='Poppins' color='gray.31'>
          Confirmar troca de gestor de “{selectedTeam.name}”
        </Text>

        <Flex
          h='43px'
          align='center'
          bgColor='#F7F9FA'
          justify='space-between'
          borderRadius='8px'
          p='0 16px'
          mb='16px'
          w='100%'
          boxShadow='0px 1px 6px rgba(0, 0, 0, 0.1)'
        >
          <Text ml='8px' fontWeight='700' fontSize='12px' color='#009639'>
            {newManager.name}
          </Text>
        </Flex>

        <Flex
          w='116px'
          h='36px'
          bg='#F2F2F2'
          borderRadius='12px'
          alignSelf='center'
          alignItems='center'
          justifyContent='center'
        >
          <ChangeIcon color='#767F89' />
        </Flex>

        <Flex
          h='43px'
          align='center'
          bgColor='#F7F9FA'
          justify='space-between'
          borderRadius='8px'
          p='0 16px'
          w='100%'
          boxShadow='0px 1px 6px rgba(0, 0, 0, 0.1)'
        >
          <Text ml='8px' fontWeight='700' fontSize='12px' color='#009639'>
            {selectedTeam.manager.name}
          </Text>
        </Flex>

        <Text color='#767F89' pt='32px' pb='24px' fontWeight='600'>
          Você está atribuindo {newManager.anem} como gestor de “{selectedTeam.name}”.
        </Text>

        <Text mb='24px' fontWeight='700'>
          Ao confirmar esta ação, o atual gestor desta equipe, {selectedTeam.manager.name}, perderá o acesso aos
          relatórios de “{selectedTeam.name}”
        </Text>

        <Flex w='100%' justify='space-between' mt='24px !important'>
          <Button variant='whiteOutline' fontSize='12px' onClick={handleBack}>
            Voltar
          </Button>

          <Button variant='solidGray' isLoading={loading} fontSize='12px' onClick={handleNextStep}>
            Confirmar
          </Button>
        </Flex>
      </VStack>
    </>
  )
}

export default ChangeManagerStepTwo
