import { ReactElement, useCallback, useState } from 'react'
import { useTeams } from '../../context-admin/useTeams'
import Drawer from '../Drawer'
import AddCollabs from './AddCollabs'
import AddCollabsStepTwo from './AddCollabsStepTwo'
import ChangeManager from './ChangeManager'
import ChangeManagerStepTwo from './ChangeManagerStepTwo'
import DeleteTeam from './DeleteTeam'
import Options from './Options'
import RemoveCollabs from './RemoveCollabs'
import RemoveCollabsStepTwo from './RemoveCollabsStepTwo'
import Success from './Success'

interface StringMap {
  [key: string]: ReactElement
}

const TeamDrawer: React.FC = () => {
  const { handleToggleTeamsDrawer } = useTeams()
  const [component, setComponent] = useState('0')

  const handleComponentChange = useCallback((value: string) => {
    setComponent(value)
  }, [])

  const components: StringMap = {
    '0': <Options handleComponentChange={handleComponentChange} />,
    ADD_COLLABS: <AddCollabs handleComponentChange={handleComponentChange} />,
    ADD_COLLABS_STEP_TWO: <AddCollabsStepTwo handleComponentChange={handleComponentChange} />,
    CHANGE_MANAGER: <ChangeManager handleComponentChange={handleComponentChange} />,
    CHANGE_MANAGER_STEP_TWO: <ChangeManagerStepTwo handleComponentChange={handleComponentChange} />,
    REMOVE_COLLABS: <RemoveCollabs handleComponentChange={handleComponentChange} />,
    REMOVE_COLLABS_STEP_TWO: <RemoveCollabsStepTwo handleComponentChange={handleComponentChange} />,
    DELETE_TEAM: <DeleteTeam handleComponentChange={handleComponentChange} />,
    SUCCESS: <Success handleComponentChange={handleComponentChange} />,
  }

  return (
    <Drawer onOutsideClick={handleToggleTeamsDrawer} width='595px'>
      {components[component]}
    </Drawer>
  )
}

export default TeamDrawer
