import { gql } from '@apollo/client'

export const UPDATE_USER_PREFERENCES = gql`
  mutation updateUserPreferences($userPreferences: UserPreferencesInput!) {
    updateUserPreferences(userPreferences: $userPreferences) {
      id
      audio_languages
    }
  }
`
