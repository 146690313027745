import { CompanyRepository } from '../repository/Company'

export class UploadCompanyLogoUseCase {
  constructor(private repository: CompanyRepository) {}

  async execute(image: File): Promise<string | undefined> {
    const logoUrl = await this.repository.uploadLogo(image)
    return logoUrl
  }
}
