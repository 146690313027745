import { Drawer, DrawerContent, DrawerOverlay, Flex, Img, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

const DEFAULT_MESSAGE = 'A edição foi realizada com sucesso!'

interface SuccessModalProps {
  isOpen: boolean
  onClose: () => void
  message?: string
}

export function ImportedContentSuccessModal({ isOpen, onClose, message = DEFAULT_MESSAGE }: SuccessModalProps) {
  const [timer, setTimer] = useState(5)

  useEffect(() => {
    let count = 5
    const interval = setInterval(() => {
      if (count <= 1) {
        clearInterval(interval)
        onClose()
      } else {
        count -= 1
        setTimer(count)
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement='right'>
      <DrawerOverlay />
      <DrawerContent maxW='600px' px='60px' py='65px' overflow='auto'>
        <Flex flexDir='column' align='center' justify='center' gap='48px' pt='48px'>
          <Img src='/assets/images/ballon.png' alt='Success ballon image' />
          <Text
            color='gray.800'
            fontWeight='600'
            fontSize='1.25rem'
            letterSpacing='-0.6px'
            fontFamily='Poppins'
            textAlign='center'
          >
            {message}
          </Text>
        </Flex>
        <Text textAlign='center' fontSize='0.75rem' color='bluishGray.300' mt='24px'>
          Essa tela fechará automaticamente em {timer} {timer <= 1 ? 'segundo' : 'segundos'}...
        </Text>
      </DrawerContent>
    </Drawer>
  )
}
