import { Flex, FormControl, Input, Text, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleTeam } from 'src/context-admin/useHandleTeam'
import { makeCreateTeam } from 'src/modules/dashboard/factory/makeCreateTeam'
import { NavigationButtons } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/NavigationButtons'
import { StepTitle } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTitle'
import { StepTransition } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTransition'
import { User } from 'src/types/User'
import { UserList } from '../../commonComponents/UserList'

export function AddNewTeamConfirmTeamInfo() {
  const [t] = useTranslation()
  const [loading, setLoading] = useState(false)
  const { navigateToStep, team, handleFinalMessage } = useHandleTeam()

  const createNewTeam = makeCreateTeam()

  const toast = useToast()
  const sortAreaUsersByNameAsc = (users: User[]) => users.sort((a, b) => (a?.name ?? '').localeCompare(b?.name ?? ''))

  const handleSubmitNewArea = async () => {
    setLoading(true)
    try {
      const createdTeam = await createNewTeam.execute({
        teamName: team?.name ?? '',
        teamMembers: [
          ...team?.newManagers?.map((item) => ({ id: Number(item.id), type: 'MANAGER' }))!,
          ...team?.newUsers?.map((item) => ({ id: Number(item.id), type: 'COLAB' }))!,
        ],
      })
      if (!createdTeam?.id) {
        throw new Error()
      }
      handleFinalMessage(`"${team?.name}" foi criada com sucesso!`)
      navigateToStep('success-screen')
    } catch (err: any) {
      toast({
        title: 'Erro ao criar nova equipe',
        description: String(err?.message),
        status: 'error',
        isClosable: true,
        duration: 7000,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <StepTransition>
      <Flex flexDir='column'>
        <StepTitle title='Confirmação de atribuições' />
        <Flex flexDir='column' mt='24px' gap='24px'>
          <FormControl display='flex' flexDir='column' gap='4px'>
            <Text fontSize='1rem' fontWeight='700'>
              {t('team')}
            </Text>
            <Input
              fontSize='1rem'
              color='#767F89'
              value={team?.name ?? 'Nome da equipe inválido'}
              readOnly
              borderRadius='8px'
              px='16px'
              height='36px'
            />
          </FormControl>

          <Flex flexDir='column' gap='8px'>
            <Flex gap='6px'>
              <Text fontSize='1rem' fontWeight='700'>
                {t('teamManagers')}
              </Text>
              <Text fontSize='1rem'>
                ({team?.newManagers?.length ?? 0} {team?.newManagers?.length === 1 ? 'colaborador' : 'colaboradores'})
              </Text>
            </Flex>
            <UserList
              data={sortAreaUsersByNameAsc(team?.newManagers ?? [])}
              withSelectButton={false}
              withFilter={false}
              withSearch={false}
            />
          </Flex>

          <Flex flexDir='column' gap='8px'>
            <Flex gap='6px'>
              <Text fontSize='1rem' fontWeight='700'>
                {t('teamMembers')}
              </Text>
              <Text fontSize='1rem'>
                ({team?.newUsers?.length ?? 0} {team?.newUsers?.length === 1 ? 'colaborador' : 'colaboradores'})
              </Text>
            </Flex>
            <UserList
              data={sortAreaUsersByNameAsc(team?.newUsers ?? [])}
              withSelectButton={false}
              withFilter={false}
              withSearch={false}
            />
          </Flex>
        </Flex>
        <NavigationButtons
          isLoading={loading}
          onBackClick={() => navigateToStep('add-new-team--select-users')}
          onContinueClick={handleSubmitNewArea}
        />
      </Flex>
    </StepTransition>
  )
}
