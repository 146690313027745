import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useHandleArea } from 'src/context-admin/useHandleArea'

export function CloseDrawerButton() {
  const [t] = useTranslation()
  const { goToFirstStep, onCloseHandleAreaDrawer } = useHandleArea()

  return (
    <Flex mb='22px'>
      <Text
        cursor='pointer'
        onClick={() => {
          onCloseHandleAreaDrawer()
          goToFirstStep()
        }}
        fontFamily='Poppins'
        fontSize='1rem'
        textDecoration='underline'
        color='bluishGray.500'
        fontWeight='600'
      >
        {t('common.drawer.close')}
      </Text>
    </Flex>
  )
}
