import { useMutation } from '@apollo/client'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoChevronForward, IoEye, IoEyeOff } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import SUBMIT_ADMIN_ACCOUNT from 'src/graphql/mutations/SUBMIT_ADMIN_ACCOUNT'
import * as Yup from 'yup'
import { useOnboard } from '../../../context-admin/useOnboard'
// // import '../../../i18n'
import { getNextStep, getPreviousStep } from '../utils'
import ValidatePassword from './ValidatePassword'

interface FormValues {
  name: string
  email: string
  password: string
}

interface ReturnDataSubmit {
  createCompanyAndAdmin: {
    success: boolean
    admin: {
      id: string
      name: string
      email: string
    }
  }
}

const FormAdminAccount: React.FC = () => {
  const [t] = useTranslation()
  const history = useHistory()
  const toast = useToast()
  const [submitAdminAccount] = useMutation<ReturnDataSubmit>(SUBMIT_ADMIN_ACCOUNT)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const { company } = useOnboard()

  const back = () => {
    const previousStep = getPreviousStep('admin-account')
    history.push(`/onboard/${previousStep!.slug}`)
  }

  const Validation = Yup.object().shape({
    name: Yup.string().trim().required(t('onboard.adminAccount.validation.required')),
    area: Yup.string().required(t('onboard.registration.validation.required')),
    email: Yup.string()
      .email(t('onboard.adminAccount.validation.invalid-email'))
      .required(t('onboard.adminAccount.validation.required')),
    password: Yup.string()
      .min(8)
      .matches(/[a-z]/)
      .matches(/[A-Z]/)
      .matches(/[0-9]/)
      .matches(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/)
      .required(),
  })

  const handleSubmit = (values: FormValues, setSubmitting: React.Dispatch<boolean>) => {
    submitAdminAccount({
      variables: {
        ...values,
        companyName: company?.name,
        companyTradeName: company?.tradeName,
        companyAreas: company?.areas,
        companyCnpj: company?.cnpj,
      },
    })
      .then(() => {
        setSubmitting(false)
        const nextStep = getNextStep('admin-account')
        history.push(`/onboard/${nextStep!.slug}`)
      })
      .catch((err) => {
        console.log(err)
        toast({
          title: t('onboard.adminAccount.toast.Something went wrong'),
          description: t('onboard.adminAccount.toast.Please, try again'),
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      })
  }
  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        password: '',
        area: undefined,
      }}
      validationSchema={Validation}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
    >
      {(props: FormikProps<FormValues>) => (
        <Form>
          <Stack spacing={4}>
            <Field name='name'>
              {({ field, form }: FieldProps) => (
                <FormControl id='name' isInvalid={!!(form.errors.name && form.touched.name)}>
                  <FormLabel htmlFor='name' fontFamily='Mulish' fontWeight='extrabold'>
                    {t('onboard.adminAccount.Full Name')}
                  </FormLabel>
                  <Input
                    {...field}
                    type='text'
                    placeholder={t('onboard.adminAccount.Insira o seu nome completo')}
                    w='100%'
                  />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name='email'>
              {({ field, form }: FieldProps) => (
                <FormControl id='email' isInvalid={!!(form.errors.email && form.touched.email)}>
                  <FormLabel htmlFor='email' fontFamily='Mulish' fontWeight='extrabold'>
                    {t('onboard.adminAccount.E-mail')}
                  </FormLabel>
                  <Input {...field} type='email' placeholder={t('onboard.adminAccount.exemplo@corp.com')} w='100%' />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name='area'>
              {({ field, form }: FieldProps) => (
                <FormControl id='area' isInvalid={!!(form.errors.area && form.touched.area)}>
                  <FormLabel htmlFor='area' fontFamily='Mulish' fontWeight='extrabold'>
                    {t('onboard.adminAccount.Area')}
                  </FormLabel>
                  <Select
                    type='text'
                    height='48px'
                    placeholder={t('onboard.adminAccount.Select your current area')}
                    w='100%'
                    style={{ color: !field.value ? '#8C8C8C' : '#313E4C' }}
                    {...field}
                  >
                    {company?.areas.map((area: string) => {
                      return (
                        <option value={area} key={area}>
                          {area}
                        </option>
                      )
                    })}
                  </Select>

                  <FormErrorMessage>{form.errors.area}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name='password'>
              {({ field, form }: FieldProps) => (
                <FormControl id='password' isInvalid={!!(form.errors.password && form.touched.password)}>
                  <FormLabel htmlFor='password' fontFamily='Mulish' fontWeight='extrabold'>
                    {t('onboard.adminAccount.Password')}
                  </FormLabel>
                  <InputGroup>
                    <Input
                      {...field}
                      type={showPassword ? 'text' : 'password'}
                      placeholder={t('onboard.adminAccount.Crie sua senha')}
                      w='100%'
                    />
                    <InputRightElement width='4.5rem'>
                      <IconButton
                        as='div'
                        cursor='pointer'
                        icon={showPassword ? <IoEyeOff /> : <IoEye />}
                        backgroundColor='transparent'
                        fontSize='xl'
                        color='gray.700'
                        size='sm'
                        fontFamily='Poppins'
                        fontWeight='bold'
                        aria-label='Show/hide password'
                        onClick={() => setShowPassword(!showPassword)}
                        type='button'
                      ></IconButton>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              )}
            </Field>

            <ValidatePassword password={props.values.password} />
          </Stack>

          <Button
            w='100%'
            rightIcon={<IoChevronForward />}
            type='submit'
            disabled={props.isSubmitting || props.isValidating || !props.isValid}
            marginTop={6}
            size='lg'
            isLoading={props.isSubmitting}
          >
            {t('onboard.adminAccount.Next Step')}
          </Button>
          <Button variant='ghost' onClick={back} width='100%' marginTop='8px'>
            {t('onboard.Back')}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default FormAdminAccount
