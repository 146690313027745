import { Flex, Image, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

const EmptyState: React.FC = () => {
  const [t] = useTranslation()
  return (
    <Flex width='100%' justifyContent='center'>
      <Stack textAlign='center' width='50%' alignItems='center' margin='50px auto'>
        <Image src={'/assets/images/empty-state.svg'} width='300px' />
        <Text fontFamily='Poppins' fontWeight='semibold' fontSize='xl'>
          {t('admin.home.employeeSection.noUsers')}
        </Text>
        <Text>{t('admin.home.employeeSection.noUsersDescription')}</Text>
      </Stack>
    </Flex>
  )
}

export default EmptyState
