import { ApolloClient, ApolloLink, DefaultOptions, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import appConfig from 'src/config'
import { clearToken, getAccessToken } from 'src/modules/iam/apis/store'
import { RetryLink } from '@apollo/client/link/retry';
import { createUploadLink } from "apollo-upload-client";

const httpLink = new HttpLink({
  uri: appConfig.backend.graphqlUrl,
})

const cmsHttpLink = createUploadLink({
  uri: appConfig.backend.cmsGraphqlUrl,
  headers: {
    "Apollo-Require-Preflight": "true",
  },
})

const authMiddleware = () =>
  new ApolloLink((operation, forward) => {
    const token = getAccessToken()
    // add the authorization to the headers
    if (token) {
      operation.setContext({
        headers: {
          authorization: token,
        },
      })
    }

    return forward(operation)
  })

const cmsAuthMiddleware = () =>
  new ApolloLink((operation, forward) => {
    const token = getAccessToken()
    // add the authorization to the headers
    if (token) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    }

    return forward(operation)
  })

const logoutLink = () =>
  onError(({ graphQLErrors }) => {
    if (graphQLErrors?.flatMap((item) => item.extensions).some((item) => item.code === 'UNAUTHENTICATED')) {
      clearToken()
      window.location.href = '/login'
    }
  })

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const cache = new InMemoryCache()

const cmsOperationClientName = 'cms'
export const cmsQueryContext = {
  clientName: cmsOperationClientName,
}

const directionalLink = new RetryLink().split(
  (operation) => operation.getContext().clientName === cmsOperationClientName,
  cmsAuthMiddleware().concat(logoutLink()).concat(cmsHttpLink),
  authMiddleware().concat(logoutLink()).concat(httpLink)
)

const apolloClient = new ApolloClient({
  link: directionalLink,
  cache,
  defaultOptions,
})

export { apolloClient }

export const useAppApolloClient = () => {
  return apolloClient
}

export default useAppApolloClient
