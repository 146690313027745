import { Flex, Heading, Image } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { getPlaylistPartnerLogos } from '../../../../pages/PlaylistPage/utils'

interface Props {
  playlist: NewPlaylist
}

const PartnersSection: React.FC<Props> = ({ playlist }: Props) => {
  const [t] = useTranslation()
  playlist = playlist!

  const { playlistPartnerLogos } = getPlaylistPartnerLogos(playlist)

  if (playlistPartnerLogos.length === 0) return null

  return (
    <Flex
      marginLeft='72px'
      marginRight='72px'
      marginTop='50px'
      mb={!playlist.issuesCertificate ? '70px' : '0'}
      flexDirection='column'
      alignItems='center'
    >
      <Heading size='sm' marginBottom='47px'>
        {t('playlist.Learn from the best partners')}
      </Heading>
      <Flex>
        {playlistPartnerLogos.map((logo: string) => (
          <Image h='50px' marginLeft='30px' marginRight='30px' src={logo} />
        ))}
      </Flex>
    </Flex>
  )
}

export default PartnersSection
