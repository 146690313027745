import { IHttpClient } from 'src/services/http/http-client'
import { Company } from '../../cms/domain/Company'

interface ICompanyRepository {
  getCompanyInfo(params: CreateCompanyInput): Promise<any>
  uploadLogo(image: File): Promise<string | undefined>
  createCompany(params: CreateCompanyInput): Promise<void>
  deactiveCompany(companyId: string): Promise<boolean>
}

export type CreateCompanyInput = {
  name: string
  tradeName: string
  cnpj: string
  admin: {
    username: string
    name: string
  }
  logoUrl?: string
}

export class CompanyRepository implements ICompanyRepository {
  constructor(private token: string, private httpClient: IHttpClient) {}

  async getCompanyInfo(): Promise<Company> {
    try {
      const response = await this.httpClient.request<Company>({
        url: `/v1/company`,
        method: 'GET',
        headers: {
          Authorization: this.token,
        },
      })

      return response.body
    } catch {
      throw new Error('Ocorreu um erro ao buscar as informações da empresa.')
    }
  }

  async createCompany(params: CreateCompanyInput): Promise<void> {
    try {
      await this.httpClient.request({
        url: `/v1/company/onboarding`,
        method: 'POST',
        data: params,
        headers: {
          Authorization: this.token,
        },
      })
    } catch {
      throw new Error('Ocorreu um erro ao criar a empresa.')
    }
  }

  async uploadLogo(image: File): Promise<string | undefined> {
    try {
      if (!image) throw new Error('A logo da empresa é obrigatória.')

      const formData = new FormData()
      formData.append('file', image)

      const response = await this.httpClient.request<{ url: string }>({
        url: `/v1/company/logo`,
        method: 'PUT',
        data: formData,
        headers: {
          Authorization: this.token,
        },
      })
      return response.body.url
    } catch (err) {
      throw new Error('Ocorreu um erro ao fazer o upload da logo.')
    }
  }

  async deactiveCompany(companyId: string): Promise<boolean> {
    try {
      await this.httpClient.request({
        method: 'PATCH',
        url: `/v1/iam/users/company/${companyId}/disable`,
        headers: {
          Authorization: this.token,
        },
      })
      return true
    } catch {
      return false
    }
  }
}
