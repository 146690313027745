import { Box, Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CoursePageItem } from 'src/components/CourseModal/CourseModalBody'
import DescriptionPage from 'src/components/CourseModal/DescriptionPage'
import InstructorsPage from 'src/components/CourseModal/InstructorsPage'
import TrailerPage from 'src/components/CourseModal/TrailerPage'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import Content from 'src/modules/cms/domain/Content'

interface Props {
  content: Content
}

const Course: React.FC<Props> = ({ content }: Props) => {
  const [t] = useTranslation()
  const { track, EventsImplementation } = useMixpanel()
  const [bodyPageOpened, setBodyPageOpened] = useState(content?.trailerUrl ? 0 : 1)

  const pagesName = ['Trailer', t('home.Course Modal.Description'), t('home.Course Modal.Instructors')]

  const pages = [
    <TrailerPage content={content} />,
    <DescriptionPage content={content} margin={0} />,
    // <ContentPage course={content} margin={0} />,
    <InstructorsPage content={content} margin={0} />,
  ]

  const handleCoursePageItemClick = (index: number) => {
    track(
      EventsImplementation.CHANGE_TAB_LEARNING_ITEM_DRAWER(content.title, bodyPageOpened.toString(), index.toString()),
    )
    setBodyPageOpened(index)
  }

  return (
    <Box>
      <Flex margin='28px 0px' borderBottom='1px solid #313E4C' position='relative'>
        {pagesName.map((name: string, index: number) => {
          const hideTrailer = index === 0 && !content.trailerUrl
          // const hideContentPage = index === 2 && !learningItem.modules?.length
          const hideInstructorsPage = index === 2 && !content.instructors?.length

          if (hideTrailer || hideInstructorsPage) return null

          return (
            <CoursePageItem
              key={name}
              onClick={() => handleCoursePageItemClick(index)}
              text={name}
              selected={bodyPageOpened === index}
            />
          )
        })}
      </Flex>

      <Box margin='17px 0px 0px' marginBottom='40px'>
        {pages[bodyPageOpened]}
      </Box>
    </Box>
  )
}

export default Course
