import { Flex, Icon, Text, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TbArrowsShuffle2 } from 'react-icons/tb'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { makeChangeManagerFromExistingArea } from 'src/modules/dashboard/factory/makeChangeManagerFromExistingArea'
import { NavigationButtons } from '../../commonComponents/NavigationButtons'
import { StepTitle } from '../../commonComponents/StepTitle'
import { StepTransition } from '../../commonComponents/StepTransition'
import { UserListCard } from '../../commonComponents/UserListCard'

export function EditExistingAreaConfirmChangeManager() {
  const [t] = useTranslation()
  const [loading, setLoading] = useState(false)
  const { area, navigateToStep, handleFinalMessage } = useHandleArea()

  const changeManagerOfArea = makeChangeManagerFromExistingArea()
  const toast = useToast()

  const handleSubmitNewManager = async () => {
    setLoading(true)
    try {
      if (!area?.id) throw new Error('A área selecionada é inválida. Por favor, tente novamente!')
      if (area?.newManager?.id === undefined && area?.manager?.id === undefined)
        throw new Error('O gestor selecionado é inválido. Por favor, tente novamente!')
      const managerWasChanged = await changeManagerOfArea.execute({
        areaId: Number(area?.id) ?? -1,
        newManagerId: area?.newManager?.id ?? -1,
      })
      if (!managerWasChanged) {
        throw new Error(`Ocorreu um erro ao trocar o gestor da área "${area?.name ?? ''}". Por favor, tente novamente!`)
      }
      handleFinalMessage(t('areaManagerChanged', { areaName: area?.name }))
      navigateToStep('success-screen')
    } catch (err: any) {
      toast({
        title: t('errorChangingAreaManager'),
        description: String(err?.message),
        status: 'error',
        isClosable: true,
        duration: 7000,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <StepTransition>
      <Flex flexDir='column' gap='24px'>
        <StepTitle title={t('confirmManagerChange', { areaName: area?.name ?? t('invalidArea') })} />
        <Flex flexDir='column' gap='12px'>
          <UserListCard data={area?.manager} />
          <Flex align='center' justify='center' w='116px' py='8px' borderRadius='12px' bg='#F2F2F2' mx='auto'>
            <Icon as={TbArrowsShuffle2} w='20px' h='16px' color='#767F89' />
          </Flex>
          <UserListCard data={area?.newManager} />
        </Flex>
        <Text fontWeight='600' fontSize='1rem' color='#767F89'>
          {t('youAreAssigningNewManager', {
            areaManager: area?.newManager?.name ?? t('newInvalidManager'),
            areaName: area?.name ?? t('invalidArea'),
          })}
        </Text>
        <Text fontWeight='700' fontSize='1rem' color='#313E4C'>
          {t('whenConfirmingManagerWillLoseAccessReports', {
            areaManager: area?.manager?.name ?? t('oldInvalidManager'),
            areaName: area?.name ?? t('invalidArea'),
          })}
        </Text>
      </Flex>
      <NavigationButtons
        continueLabel='Confirmar'
        onContinueClick={() => {
          handleSubmitNewManager()
        }}
        isLoading={loading}
        onBackClick={() => {
          navigateToStep('edit-existing-area--confirm-assignments-change-manager')
        }}
      />
    </StepTransition>
  )
}
