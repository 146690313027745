import { Flex, Skeleton } from '@chakra-ui/react'
import { useMemo } from 'react'
import { PaginatedCoursesSkeleton } from 'src/components/PaginatedCourses/PaginatedCoursesSkeleton'
import { useWindowSize } from 'src/hooks/useWindowSize'

const PartnerHeaderSkeleton: React.FC = () => {
  const { width } = useWindowSize()
  const isMobile = useMemo(() => !!width && width < 992, [width])

  if (isMobile) {
    return (
      <Flex padding='20px' w='100%' gap='24px' flexDir='column'>
        <Flex flexDir='row' w='100%' align='center' gap='12px'>
          <Skeleton minW='56px' h='56px' borderRadius='100%' />
          <Skeleton display='flex' flexGrow={1} minW='200px' h='32px' maxW='300px' borderRadius='16px' />
        </Flex>
        <Skeleton w='100%' h='162px' borderRadius='16px' />
        <Flex gap='12px'>
          <Skeleton flexGrow='2' h='26px' borderRadius='16px' />
          <Skeleton flexGrow='1' h='26px' borderRadius='16px' />
          <Skeleton flexGrow='1' h='26px' borderRadius='16px' />
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex flexDirection='column'>
      <Skeleton w='100%' h='213px' />
      <Flex paddingLeft='72px' paddingRight='72px' w='100%'>
        <Skeleton
          minW='200px'
          minH='200px'
          h='200px'
          background='gray.30'
          borderRadius='60px'
          opacity='1'
          alignItems='center'
          justifyContent='center'
          marginTop='-72px'
        />
        <Flex marginLeft='32px' marginTop='25px' flexDirection='column' width='100%'>
          <Skeleton w='250px' h='31px' borderRadius='16px' marginBottom='10px' />
          <Flex w='100%' flexDirection='column'>
            <Skeleton w='80%' h='22px' borderRadius='16px' marginBottom='10px' />
            <Skeleton w='80%' h='22px' borderRadius='16px' />
          </Flex>
          <Flex marginTop='20px'>
            <Skeleton w='150px' h='26px' borderRadius='14px' marginRight='10px' />
            <Skeleton w='80px' h='26px' borderRadius='14px' marginRight='10px' />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

interface CoursesListSkeletonProps {
  numberOfCardsRow: number
}

const CoursesListSkeleton: React.FC<CoursesListSkeletonProps> = ({ numberOfCardsRow }: CoursesListSkeletonProps) => {
  const { width } = useWindowSize()
  const isMobile = useMemo(() => !!width && width < 992, [width])

  if (isMobile) {
    return (
      <Flex flexWrap='wrap' gap='12px' padding='0px 20px'>
        {Array.from({ length: 4 }).map((_, index) => (
          <Skeleton key={index} w='100%' flexGrow='1' h='200px' borderRadius='32px' />
        ))}
      </Flex>
    )
  }

  return (
    <Flex flexDirection='column' paddingLeft='72px' paddingRight='72px'>
      <PaginatedCoursesSkeleton numberOfCardsRow={numberOfCardsRow} />
    </Flex>
  )
}

export { CoursesListSkeleton, PartnerHeaderSkeleton }
