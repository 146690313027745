import { getAccessToken } from "src/modules/iam/apis/store";
import httpClient from "src/services/http/http-client";
import { TeamRepository } from "../repository/TeamRepository";
import { CreateNewTeamUseCase } from "../use-case/CreateNewTeamUseCase";

export function makeCreateTeam() {
  const token = getAccessToken()

  const repository = new TeamRepository(token, httpClient)
  return new CreateNewTeamUseCase(repository)
}