import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import Content from 'src/modules/cms/domain/Content'
import { LearningItemCard } from '../../components/LearningItemCard'
import { ScrollingCarousel } from '../../components/ScrollingCarousel'
import { SliderChevron } from '../../theme/Icons'
import './styles.scss'

interface Props {
  contents: Content[]
  label?: string
  showInitialQuizCard?: boolean
  mlTitle?: string
  carouselPadLeft?: string
  removeCourseHover?: boolean
  onCourseClick?: (item: Content) => void
  onKnowMoreClick?: (item: Content) => void
}

export const LearningItemsSlider: React.FC<Props> = ({
  contents,
  label,
  carouselPadLeft = '33px',
  mlTitle = '39px',
  removeCourseHover = false,
  onCourseClick,
  onKnowMoreClick,
}) => {
  const location = useLocation()
  const { EventsImplementation, track } = useMixpanel()

  if (!contents || contents.length === 0) return null
  return (
    <Flex w='100%' alignItems='center' justifyContent='center' marginBottom='78px'>
      <Flex w='100%' paddingLeft={{ base: '16px', md: carouselPadLeft }} flexDirection='column'>
        {label && (
          <Text fontWeight='600' fontFamily='Poppins' fontSize='20px' marginLeft={{ base: '0px', md: '39px' }}>
            {label}
          </Text>
        )}
        <ScrollingCarousel
          className='scrolling-carousel'
          defaultSlideAmount={600}
          leftIcon={
            <Flex
              position='absolute'
              height='calc(100% + 30px)'
              alignItems='center'
              width={{ base: '44px', md: '80px' }}
              left='-1px'
              zIndex='4'
              onClick={() => {
                track(EventsImplementation.SWIPE(location.pathname, label || 'Playlist Section'))
              }}
              cursor='pointer'
              background='linear-gradient(90deg, #ffffffd4 34.77%, rgba(255, 255, 255, 0) 93.38%)'
            >
              <Icon as={SliderChevron} transform='matrix(-1, 0, 0, 1, 0, 0)' w='13px' h='27px' />
            </Flex>
          }
          rightIcon={
            <Flex
              position='absolute'
              height='calc(100% + 30px)'
              alignItems='center'
              width={{ base: '44px', md: '80px' }}
              zIndex='4'
              transform='matrix(-1, 0, 0, 1, 0, 0)'
              right='0'
              onClick={() => {
                track(EventsImplementation.SWIPE(location.pathname, label || 'Playlist Section'))
              }}
              cursor='pointer'
              background='linear-gradient(90deg, #ffffffd4 34.77%, rgba(255, 255, 255, 0) 93.38%)'
            >
              <Box marginLeft='16px'>
                <Icon as={SliderChevron} transform='matrix(-1, 0, 0, 1, 0, 0)' w='13px' h='27px' />
              </Box>
            </Flex>
          }
        >
          {contents.map((item, index) => (
            <Flex
              key={`${item.id}-${index}`}
              marginRight='20px'
              marginLeft={{ base: '0px', md: !index ? mlTitle : '' }}
              paddingTop='25px'
              paddingBottom='200px'
            >
              <LearningItemCard
                key={index}
                content={item}
                lineName={label}
                isFromSlider={true}
                removeHover={removeCourseHover}
                onClick={onCourseClick ? () => onCourseClick(item) : undefined}
                onKnowMoreClick={onKnowMoreClick ? () => onKnowMoreClick(item) : undefined}
              />
            </Flex>
          ))}
        </ScrollingCarousel>
      </Flex>
    </Flex>
  )
}
