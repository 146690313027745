import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { IoReorderThree } from 'react-icons/io5'
import Content from 'src/modules/cms/domain/Content'
import { convertSecondsToMinutesAndHours } from 'src/pages-admin/Cms/utils/convertSecondsToMinutesAndHours'
import { parseCourseType } from 'src/pages-admin/Cms/utils/parseCourseType'
import { CmsContentType } from 'src/types/Cms'

interface Props {
  content: Content
  hasOrderIcon?: boolean
}

export function ContentItem({ content, hasOrderIcon = false }: Props) {
  return (
    <Flex
      w='100%'
      h='72px'
      align='flex-start'
      gap='10px'
      p='8px 24px'
      bg='primaryColors.30'
      borderRadius='16px'
      border='2px solid transparent'
      _hover={{
        borderColor: hasOrderIcon ? 'primaryColors.650' : 'transparent',
      }}
      _active={{
        color: 'primaryColors.650',
        borderColor: 'primaryColors.650',
      }}
      userSelect='none'
      mb='12px'
      overflowX='auto'
      overflowY='hidden'
      css={{
        '&::-webkit-scrollbar': {
          height: '0px',
          display: 'none',
          cursor: 'regular !important',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#303a4e',
        },
      }}
    >
      {hasOrderIcon && <Icon w='28px' h='28px' color='primaryColors.650' as={IoReorderThree} />}

      <Box>
        <Text fontWeight={700} fontSize='18px' whiteSpace='nowrap' overflow={{ base: 'unset', lg: 'hidden' }} w='100%'>
          {content.title}
        </Text>
        <Text fontWeight={400} fontSize='1rem' textTransform='capitalize'>
          {parseCourseType(content.type as CmsContentType)} / {convertSecondsToMinutesAndHours(content?.duration!)}
        </Text>
      </Box>
    </Flex>
  )
}
