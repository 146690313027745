import { getAccessToken } from "src/modules/iam/apis/store";
import httpClient from "src/services/http/http-client";
import { CertificateRepository } from "../repositories/CertificateRepository";
import { UpdateCertificateUseCase } from "../use_cases/UpdateCompanyCertificateUseCase";

export function makeUpdateCertificate() {
  const token = getAccessToken()
  const certificateRepository = new CertificateRepository(token, httpClient)
  return new UpdateCertificateUseCase(certificateRepository)
}
