import React, { useEffect, useState } from 'react'
import Drawer from 'src/components/Drawer'
import { useSwapContentDrawer } from 'src/context/CustomPlaylist/SwapContentDrawerContext'
import { useUser } from 'src/context/userContext'
import { EventsImplementation } from 'src/hooks/Mixpanel/types'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import Content from 'src/modules/cms/domain/Content'
import { cmsClient } from 'src/services/http/cms-client'
import KnowMoreSwapDrawer from './components/KnowMoreSwapDrawer'
import Skeleton from './components/Skeleton'
import Steps from './components/Steps'

const ContentSwapDrawer: React.FC = () => {
  const { track } = useMixpanel()
  const { swapContentDrawerInfo, knowMoreSwapDrawer, closeSwapContentDrawer } = useSwapContentDrawer()
  const [loading, setLoading] = useState(false)
  const [contents, setContents] = useState<Content[]>([])

  const { token } = useUser()

  const getSimilarContents = async () => {
    setLoading(true)
    try {
      const response = await cmsClient.request({
        url: `/playlist/module/${swapContentDrawerInfo!.moduleId}/content/${swapContentDrawerInfo!.content!
          .id!}/similar`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const sortedContentsByScore = response.data?._items?.sort(
        (a: Content, b: Content) => (b.score ?? 0) - (a.score ?? 0),
      )
      setContents(sortedContentsByScore ?? [])
    } catch (err) {
      console.error('Ocorreu um erro ao buscar os conteúdos similares', err)
      setContents([])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (swapContentDrawerInfo) {
      getSimilarContents()
      track(EventsImplementation.OPEN_LEARNING_ITEM_DRAWER(swapContentDrawerInfo!.content.title))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapContentDrawerInfo])

  useEffect(() => {
    return () => {
      setContents([])
      setLoading(true)
    }
  }, [])

  if (!swapContentDrawerInfo) return null

  return (
    <>
      <Drawer onOutsideClick={closeSwapContentDrawer}>
        {/* {loading ? (
          <Flex flexDir='column' gap='16px' h='100%' align='center' justify='center'>
            <Fade in={true} unmountOnExit transition={{ enter: { duration: 1 }, exit: { duration: 0.5 } }}>
              <RecommendationContentsLoading contentTitle={swapContentDrawerInfo?.content?.title ?? ''} />
              <Text fontWeight='600' fontSize='1rem' textAlign='center' mt='8px'>
                Por favor, aguarde...
              </Text>
            </Fade>
          </Flex>
        ) : (
          <Steps contents={contents} />
        )} */}
        {loading ? <Skeleton /> : <Steps contents={contents} />}
      </Drawer>
      {knowMoreSwapDrawer && <KnowMoreSwapDrawer />}
    </>
  )
}

export default ContentSwapDrawer
