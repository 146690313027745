import { getAccessToken } from "src/modules/iam/apis/store"
import { cmsClient } from "src/services/http/cms-client"
import { ContentRepository } from "../repositories/ContentRepository"
import { IContentRepository, SaveTranslatedContentsInput } from "../types/IContentRepository"

export class SaveContentTranslationUseCase {
  constructor(private contentRepository: IContentRepository) { }

  async execute(params: SaveTranslatedContentsInput): Promise<boolean> {
    const wasSaved = await this.contentRepository.saveContentTranslations(params)
    return wasSaved
  }
}

export function makeSaveContentTranslations() {
  const token = getAccessToken()
  const contentRepository = new ContentRepository(token, cmsClient)
  return new SaveContentTranslationUseCase(contentRepository)
}
