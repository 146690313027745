import { Button, Flex, Heading, Input, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from 'src/context/userContext'

type Props = {
  handleStepChange: (step: number) => void
}

const DeletePlaylistConfirmation: React.FC<Props> = ({ handleStepChange }) => {
  const [t] = useTranslation()
  const { updateUserQuizData } = useUser()

  const [active, setActive] = useState(false)
  const [error, setError] = useState(false)

  const handleContinueButton = async () => {
    // await sendMutationUserSkills([])
    updateUserQuizData(false)
    handleStepChange(4)
  }

  const handleStepBack = () => {
    handleStepChange(0)
  }

  const verifyContent = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const content = e.target.value
    setError(
      t('home.Custom Playlist.I want to delete my custom playlist').toLowerCase().indexOf(content.toLowerCase()) === -1,
    )
    setActive(content.toLowerCase() === t('home.Custom Playlist.I want to delete my custom playlist').toLowerCase())
  }

  return (
    <>
      <Heading size='xs' fontWeight='600' marginBottom='21px' color='gray.800'>
        {t('home.Custom Playlist.Playlist deletion confirmation')}
      </Heading>

      <Text fontWeight='600' fontSize='18px' color='red.300' mb='24px'>
        {t('home.Custom Playlist.When removing all skills your track will be deleted')}
      </Text>

      <Text fontWeight='600' fontSize='18px' color='gray.800'>
        {t('home.Custom Playlist.To confirm this action, enter the sentence below in the text field')}
      </Text>
      <Text fontWeight='700' fontSize='18px' color='gray.800' mb='16px'>
        {t('home.Custom Playlist.I want to delete my custom playlist')}
      </Text>

      <Input
        variant='unstyled'
        border='2px solid'
        borderColor='gray.70'
        borderRadius='16px'
        fontFamily='Poppins'
        fontWeight='600'
        color={error ? 'red.200' : 'gray.800'}
        height='44px'
        onChange={verifyContent}
      />

      <Flex justify='space-between' mt='32px'>
        <Button
          variant='startCourse'
          color='bluishGray.500'
          border='2px solid #EFEFEF'
          height='44px'
          onClick={handleStepBack}
        >
          {t('quiz.footer.back')}
        </Button>
        <Button
          variant='startCourseDark'
          height='44px'
          padding='12px 24px'
          onClick={handleContinueButton}
          disabled={!active || error}
        >
          {t('playlist.Confirm')}
        </Button>
      </Flex>
    </>
  )
}

export default DeletePlaylistConfirmation
