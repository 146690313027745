import Content from 'src/modules/cms/domain/Content'
import Partner from 'src/modules/cms/domain/Partner'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { Area } from 'src/modules/iam/domain/area'

export enum CmsContentType {
  COURSE = 'COURSE',
  VIDEO = 'VIDEO',
  ARTICLE = 'ARTICLE',
}

export enum CmsStatusFilterType {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
}

interface ContentSkill {
  id: string
  name: string
}

export interface CmsContent {
  __typename: string
  id: string
  title: string
  type: CmsContentType
  description?: string
  duration?: number
  url: string
  imageUrl: string
  trailerUrl?: string
  language?: string
  allowedCompanyIds: number[]
  hasCertificate?: boolean // defaults to false if not provided
  allowsEmbedding?: boolean // defaults to false if not provided
  proficiencyLevelId?: {
    name?: string
    color?: string
  }
  partner?: {
    name?: string
    squareLogoUrl?: string
    logoUrl?: string
    description?: string
    coverColor?: string
    id?: string
  }
  skills?: ContentSkill[]
  status: CmsStatusFilterType
  updatedAt: string
}

export interface CmsPlaylist {
  id: string
  title: string
  status: CmsStatusFilterType
  link: string
  updatedAt: string
}

export interface CmsSkills {
  id: string
  name: string
  __typename: string
}

export interface CmsCompany {
  id: string
  name: string
}

export interface CmsPartner {
  id: string
  name: string
  slug: string;
  squareLogoUrl?: string
  logoUrl?: string
  description?: string
  coverColor?: string
}

export type CmsFiltersType = 'order' | 'skill' | 'partnerId' | 'status' | 'companies' | 'languages'

export type ContentList = {
  total: number
  items: Content[]
}

export interface CmsContentQuery {
  contentList: ContentList
}

export type PlaylistList = {
  total: number
  items: NewPlaylist[]
}

export interface CmsPlaylistQuery {
  playlistList: PlaylistList
}

export interface CmsSkillsQuery {
  skillList: {
    total: number
    items: CmsSkills[]
  }
}

export type PartnerList = {
  total: number
  items: Partner[]
}

export interface CmsPartnersQuery {
  partnerList: PartnerList
}

export interface CmsCompany {
  id: string
  name: string
  cnpj: string
  trade_name: string
  subsidy: number
  areas: Area[]
  authType: string
  createdAt: string
  updatedAt: string
}

export interface SelectOption {
  value: string
  label: string
}

export type UpdateContentInput = {
  id: string
  title?: string
  status?: string
  type?: string
  description?: string
  duration?: number
  url?: string
  imageUrl?: string
  languages?: string[]
  subtitles?: string[]
  hasCertificate?: boolean
  allowsEmbedding?: boolean
  trailerUrl?: string
  partnerId?: string
  proficiencyLevelId?: string
  allowedCompanyIds?: number[]
  skills?: string[]
}
