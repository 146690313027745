import {
  Avatar,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import { User } from 'src/types/User'

interface ModalProps {
  user: User
  isOpen: boolean
  onClose: () => void
}

interface InfoSnippetProps {
  label: string
  value: string | React.ReactNode
}

const InfoSnippet: React.FC<InfoSnippetProps> = ({ label, value }: InfoSnippetProps) => {
  return (
    <HStack justifyContent='center'>
      <Stack alignItems='center'>
        <Text fontWeight='extrabold'>{label}</Text>
        <Text>{value}</Text>
      </Stack>
    </HStack>
  )
}

const UserModal: React.FC<ModalProps> = ({ user, isOpen, onClose }: ModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='lg'>
      <ModalOverlay />
      <ModalContent
        backgroundImage={"url('/assets/images/modal-user-bg.svg')"}
        bgPosition='top'
        bgSize='100%'
        bgRepeat='no-repeat'
        borderRadius='12px'
      >
        <ModalCloseButton color='white' />

        <Flex marginY='40px' justifyContent='center' direction='column' alignItems='center'>
          <Avatar size='2xl' name={user.name} />
          <Text fontWeight='900' fontSize='xl' marginTop='15px'>
            {user.name}
          </Text>
        </Flex>

        <ModalBody padding='27px'>
          <Flex align='center' justify='space-around'>
            <InfoSnippet label='Área' value={user.area ? user.area.name : 'N/A'} />
            <InfoSnippet label='E-mail' value={user.email} />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default UserModal
