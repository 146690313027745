import { useMutation } from '@apollo/client'
import { Button } from '@chakra-ui/button'
import { Image } from '@chakra-ui/image'
import { Box, Flex, Stack, Text } from '@chakra-ui/layout'
import { useToast } from '@chakra-ui/toast'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { IoPaperPlane } from 'react-icons/io5'
import Card from '../../../components-admin/onboard/Card'
import { useAuthOnboard } from '../utils'
import RESEND_EMAIL from './Mutation'

const VerifyEmail: React.FC = () => {
  const toast = useToast()
  const [resendEmail, { loading }] = useMutation(RESEND_EMAIL)
  const [t] = useTranslation()
  useAuthOnboard({ stepSlug: 'verify-email' })

  // if (admin?.emailVerified) {
  //   history.push('/onboard/company')
  // }

  const handleResendEmail = () => {
    resendEmail().then((result) => {
      if (result.data.ResendAdminVerificationEmail) {
        return toast({
          title: t('onboard.verifyEmail.E-mail successfully sent'),
          description: t('onboard.verifyEmail.Verify your spam inbox'),
          status: 'success',
          isClosable: true,
        })
      }

      return toast({
        title: t("onboard.verifyEmail.Couldn't find account with email"),
        description: t('onboard.verifyEmail.Check if the information is correct'),
        status: 'error',
        isClosable: true,
      })
    })
  }

  return (
    <Card>
      <Helmet>
        <title>{t('onboard.steps.Verify')} | Education Journey</title>
      </Helmet>
      <Box width='100%' height='8px' bg='primaryColors.700' mb={7}></Box>

      <Stack alignItems='center' spacing={4} textAlign='center' mt={16}>
        <Flex justifyContent='center'>
          <Image src='/assets/images/verify-email.svg' />
        </Flex>
        <Text fontSize='3xl' fontWeight='black'>
          {t('onboard.verifyEmail.Confirm your e-mail')}
        </Text>
        <Text>{t('onboard.verifyEmail.description')}</Text>
        <Text fontWeight='bold' color='gray.600' w='70%'>
          {t('onboard.verifyEmail.didntReceiveEmail')}
        </Text>
        <Flex justifyContent='center'>
          <Button leftIcon={<IoPaperPlane />} onClick={handleResendEmail} isLoading={loading}>
            {t('onboard.verifyEmail.Resend e-mail')}
          </Button>
        </Flex>
      </Stack>
    </Card>
  )
}

export default VerifyEmail
