import { Flex } from '@chakra-ui/react'
import ReactSelect from 'react-select'
import { useQuery } from "@tanstack/react-query";
import { makeGetAreas } from '../factory/makeGetAreas'
import { MultiSelectStylesForDashboards } from 'src/pages-admin/Cms/utils/multiSelectStyles';
import { Option } from './ReportContent';
import { useTranslation } from 'react-i18next';

interface SelectAreasProps {
  areasSelected: Option[],
  setAreasSelected: (value: Option[]) => void
}

export const SelectAreas = ({ areasSelected, setAreasSelected }: SelectAreasProps) => {
  const [t] = useTranslation()
  const { data: areas, isLoading: isLoadingAreas } = useQuery({
    queryKey: [
      "areas",
    ],
    queryFn: () => {
      const getReport = makeGetAreas()
      return getReport.execute()
    },
    refetchOnWindowFocus: false,
    initialData: [],
  });

  return (
    <Flex direction='column' flex={1} minW="250px" maxW="300px">
      <ReactSelect
        isSearchable
        isMulti
        styles={MultiSelectStylesForDashboards}
        placeholder={t('admin.reports.select_areas')}
        value={areasSelected}
        options={areas
          .map((area) => ({ value: area.id, label: area.name }))
        }
        onChange={(selectedOption) => {
          setAreasSelected(selectedOption as Option[])
        }}
        isLoading={isLoadingAreas}
      />
    </Flex>
  )
}

