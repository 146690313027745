import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleTeam } from 'src/context-admin/useHandleTeam'
import { NavigationButtons } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/NavigationButtons'
import { StepTitle } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTitle'
import { StepTransition } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTransition'
import { User } from 'src/types/User'
import { UserList } from '../../commonComponents/UserList'

export function AddNewTeamSelectUsers() {
  const [t] = useTranslation()
  const [selectedUsers, setSelectedUsers] = useState<User[]>([])
  const { navigateToStep, handleSetTeamValues, team, usersList } = useHandleTeam()

  useEffect(() => {
    handleSetTeamValues({
      newUsers: selectedUsers,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers])

  return (
    <StepTransition>
      <Flex flexDir='column'>
        <StepTitle
          title={t('selectTeamMembers', { teamName: team?.name })}
          subtitle={t('searchAndSelectTeamMembers', { teamName: team?.name })}
        />
        <Flex w='100%'>
          <UserList
            data={usersList.filter((u) => !team?.newManagers?.map(manager => manager.id).includes(u.id))}
            type='select-users'
            onSelectUsers={setSelectedUsers}
          />
        </Flex>
      </Flex>
      <NavigationButtons
        onContinueClick={() => navigateToStep('add-new-team--confirm-team-info')}
        onBackClick={() => navigateToStep('add-new-team--select-managers')}
        isContinueDisabled={selectedUsers.length <= 0}
      />
    </StepTransition>
  )
}
