import { ContentRepository } from 'src/modules/cms/repositories/ContentRepository'

type Input = {
  id: string
}

export type Output = string | null

export class GetContentUrlUseCase {
  constructor(private contentRepository: ContentRepository) {}

  async execute(input: Input): Promise<Output> {
    const url = await this.contentRepository.getUrl(input.id)
    return url
  }
}
