import { Box, Flex, Skeleton } from '@chakra-ui/react'

interface PaginatedCoursesSkeletonProps {
  numberOfCardsRow: number
}

const HorizontalCourseCardSkeleton: React.FC = () => (
  <Flex background='gray.20' width='100%' h='185px' borderRadius='30px' marginTop='28px'>
    <Skeleton w='230px' h='100%' borderRadius='30px 0px 0px 30px' />
    <Flex marginLeft='40px' flexDirection='column' justifyContent='center'>
      <Skeleton w='118px' h='24px' borderRadius='16px' />
      <Skeleton w='396px' h='34px' marginTop='10px' borderRadius='16px' />
      <Flex marginTop='20px'>
        <Skeleton w='207px' h='48px' marginTop='10px' borderRadius='16px' />
        <Skeleton w='151px' h='48px' marginTop='10px' borderRadius='16px' marginLeft='12px' />
      </Flex>
    </Flex>
  </Flex>
)

const PaginatedCoursesSkeleton: React.FC<PaginatedCoursesSkeletonProps> = ({
  numberOfCardsRow,
}: PaginatedCoursesSkeletonProps) => (
  <Flex flexDirection='column' paddingTop='59px' paddingBottom='59px'>
    {[...Array(5)].map(() => (
      <Flex marginBottom='20px' key={Math.random()}>
        {[...Array(numberOfCardsRow)].map((x: number, index: number) => (
          <Box flex='1' key={Math.random()} marginLeft={index ? '20px' : ''}>
            <Skeleton
              style={{
                aspectRatio: '360 / 243',
              }}
              borderRadius='30px'
              height='100%'
            />
          </Box>
        ))}
      </Flex>
    ))}
  </Flex>
)

const PaginatedHorizontalCoursesSkeleton: React.FC = () => (
  <Flex flexDirection='column'>
    {[...Array(8)].map(() => (
      <HorizontalCourseCardSkeleton key={Math.random()} />
    ))}
  </Flex>
)

export { PaginatedCoursesSkeleton, PaginatedHorizontalCoursesSkeleton }
