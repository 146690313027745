import { AxiosRequestConfig } from 'axios'
import { axiosInstance } from './axios-client'

export type HttpResponse<T = any> = {
  statusCode: number
  body: T
  paginateInfo: {
    count: number
    next: string
    total: number
    hasPreviousPage: boolean
    hasNextPage: boolean
  }
}

export interface IHttpClient {
  request: <R = any>(data: AxiosRequestConfig) => Promise<HttpResponse<R>>
}

export class HttpClient implements IHttpClient {
  async request<R = any>(config: AxiosRequestConfig): Promise<HttpResponse<R>> {
    const axiosResponse = await axiosInstance.request({
      baseURL: config.baseURL,
      url: config.url,
      method: config.method,
      data: config.data,
      headers: config.headers,
      ...config,
    })

    return {
      statusCode: axiosResponse.status,
      body: axiosResponse.data.data || axiosResponse.data,
      paginateInfo: axiosResponse.data.paginateInfo,
    }
  }
}

export default new HttpClient()
