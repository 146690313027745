import { Stack, Text } from '@chakra-ui/react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import '../../services/i18n/i18n'
import CredentialsTable from './CredentialsTable'
import PathMenu from './PathMenu'

const MyCredentials: React.FC = () => {
  const [t] = useTranslation()
  return (
    <>
      <Helmet>
        <title> {t('common.My Credentials')} | Education Journey</title>
      </Helmet>
      <PathMenu />
      <Stack padding={{ base: '20px', lg: '30px 96px 30px 44px' }} minH='100vh'>
        <Text fontFamily='Poppins' fontSize='30px' fontWeight='semibold' color='gray.800'>
          {t('common.My Credentials')}
        </Text>
        <Text fontFamily='Mulish' fontSize='14px' fontWeight='regular' color='bluishGray.500' paddingBottom='28px'>
          {t('credentials.My credentials description')}
        </Text>

        <CredentialsTable />
      </Stack>
    </>
  )
}

export default MyCredentials
