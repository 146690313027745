import { Flex, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { AvailableStepsType } from 'src/types/HandleAreaDashboard'
import { NavigationButtons } from '../../commonComponents/NavigationButtons'
import { StepTitle } from '../../commonComponents/StepTitle'
import { StepTransition } from '../../commonComponents/StepTransition'

const addNewArea: AvailableStepsType = 'add-new-area'
const editExistingArea: AvailableStepsType = 'edit-existing-area'

export function ChooseYourAction() {
  const [t] = useTranslation()
  const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined)
  const { navigateToStep } = useHandleArea()

  const options = [
    {
      id: addNewArea,
      title: t('createNewArea'),
    },
    {
      id: editExistingArea,
      title: t('editExistingArea'),
    },
  ]

  const handleSelectOption = (optionId: string) => {
    setSelectedOption((prev) => (prev === optionId ? undefined : optionId))
  }

  return (
    <StepTransition>
      <Flex flexDir='column'>
        <StepTitle title={t('whatWouldYouLikeToDo')} subtitle={t('selectDesiredAssignments')} />
        <Flex flexDir='column' gap='16px' mt='24px'>
          {options.map((option) => (
            <Flex
              align='center'
              justify='flex-start'
              key={option.id}
              h='43px'
              w='100%'
              shadow='0px 1px 6px 0px rgba(0, 0, 0, 0.10)'
              borderRadius='8px'
              bg='#F7F9FA'
              cursor='pointer'
              px='16px'
              borderColor={selectedOption === option.id ? 'green.500' : 'transparent'}
              borderWidth='2px'
              onClick={() => handleSelectOption(option.id)}
              transition='all 150ms ease'
            >
              <Text fontSize='0.75rem' fontWeight='bold' color='bluishGray.500'>
                {option.title}
              </Text>
            </Flex>
          ))}
        </Flex>

        <NavigationButtons
          isDisabled={selectedOption === undefined}
          showBackButton={false}
          onContinueClick={() => navigateToStep(selectedOption as AvailableStepsType)}
        />
      </Flex>
    </StepTransition>
  )
}
