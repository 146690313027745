import { useApolloClient } from "@apollo/client";
import appConfig from "../config";
import Storage from '../services/storage'


export const useAuthToken = () => {
  return {
    token: Storage.token.get(),
    getToken: Storage.token.get,
    setAuthToken: Storage.token.set,
    removeAuthToken: Storage.token.remove,
  };
};

export const useLogout = () => {
  const { removeAuthToken } = useAuthToken();
  const apolloClient = useApolloClient();
  const logout = async () => {
    await apolloClient.clearStore(); // we remove all information in the store
    removeAuthToken(); //we clear the authToken
    window.location.href = `${appConfig.mainAppBaseUrl}/logout`;
  };

  return logout;
};
