import { createContext, useCallback, useContext, useState } from 'react'
import { useUser } from 'src/context/userContext'
import Content from 'src/modules/cms/domain/Content'
import { useContents } from 'src/modules/cms/hooks/useContents'
import { CoursesAndPlaylists } from 'src/types/CoursesAndPlaylists'

interface Value {
  content: Content | undefined
  course: Content | undefined
  courseToLoad: Content | undefined
  loading: boolean
  bodyPageOpened: number
  related: CoursesAndPlaylists | undefined
  loadContent: (course: Content) => void
  loadContentById: (contentId: string) => void
  loadRelated: (course: Content) => void
  setBodyPageOpened: (bodyPageOpened: number) => void
  resetCourseToLoad: () => void
  updateCourse: (newCourse: Content) => void
}

interface ProviderProps {
  children: React.ReactNode[] | React.ReactNode
}

const CourseModalContext = createContext({} as Value)

export function CourseModalProvider({ children }: ProviderProps) {
  const { user } = useUser()
  const [content, setContent] = useState<Content | undefined>(undefined)
  const [related, setRelated] = useState<CoursesAndPlaylists | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const [bodyPageOpened, setBodyPageOpened] = useState(0)

  const { getContentById } = useContents()

  const resetCourseToLoad = () => setContent(undefined)

  const changeCourseURL = (content: Content) => {
    window.history.pushState(null, '', `/course/${content.id}`)
  }

  const loadContent = async (contentToLoad: Content) => {
    if (!user) return

    changeCourseURL(contentToLoad)
    setLoading(true)
    setBodyPageOpened(0)
    const newContent = await getContentById(contentToLoad!.id, user)
    setContent(newContent)
    setLoading(false)
  }

  const loadContentById = async (contentId: string) => {
    if (!user) return

    setLoading(true)
    setBodyPageOpened(0)
    const newContent = await getContentById(contentId, user)
    setContent(newContent)
    setLoading(false)
  }

  const loadRelated = (courseToLoad: Content) => {
    // getRelatedCourse({ variables: { id: courseToLoad!.id } })
  }

  const updateCourse = useCallback((newContent: Content) => {
    setContent(newContent)
  }, [])

  return (
    <CourseModalContext.Provider
      value={{
        content,
        course: content,
        courseToLoad: content,
        loading,
        bodyPageOpened,
        related,
        setBodyPageOpened,
        loadRelated,
        resetCourseToLoad,
        loadContent,
        loadContentById,
        updateCourse,
      }}
    >
      {children}
    </CourseModalContext.Provider>
  )
}

export const useCourseModal = () => useContext(CourseModalContext)
