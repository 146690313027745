import { UseToastOptions } from '@chakra-ui/react'
import { CmsSkill } from 'src/modules/cms/domain/Skill'
import { ErrorsEnum } from './ErrorsEnum'

type Error = {
  code: string
  skill_id?: string
}
interface QuizErrorHandlerProps {
  t: any
  errors: Error[]
  playlistWasCreated?: boolean
  skills?: CmsSkill[]
}

interface QuizErrorHandler {
  skillsAndContentTypes: string[]
  errorMessage?: string
  toastOptions?: UseToastOptions
  showToast?: boolean
}

const generateDefaultErrorProps = (status: 'error' | 'info' = 'error'): Partial<UseToastOptions> => {
  return {
    duration: 15000,
    isClosable: true,
    status,
  }
}

function getContentTypeNameByCode(code: string, t: any) {
  switch (code) {
    case ErrorsEnum.MISSING_CONTENT_VIDEO_FIFTEEN:
      return t('videosOfFifteenMinutes')

    case ErrorsEnum.MISSING_CONTENT_VIDEO_SIXTY:
      return t('videosOfSixtyMinutes')

    case ErrorsEnum.MISSING_CONTENT_ARTICLE:
      return t('internetArticles')

    case ErrorsEnum.MISSING_CONTENT_COURSE_FIVE:
      return t('coursesUntilFiveHours')

    case ErrorsEnum.MISSING_CONTENT_COURSE_MORE_FIVE:
      return t('coursesMoreFiveHours')

    case ErrorsEnum.MISSING_CONTENT:
      return t('missingContentDefault')

    default:
      t('missingContentDefault')
  }
}

export function quizErrorHandler({
  t,
  errors,
  playlistWasCreated = false,
  skills = [],
}: QuizErrorHandlerProps): QuizErrorHandler {
  if (!errors || errors.length === 0) return { skillsAndContentTypes: [] }

  if (errors && errors.length === 1) {
    const skill = skills?.find((skill) => skill.id === errors[0].skill_id)
    return {
      skillsAndContentTypes: [],
      errorMessage: `${t('noContentFoundByTypeAndSkill', {
        contentType: getContentTypeNameByCode(errors[0].code, t),
        skillName: skill?.name ?? t('invalidSkillName'),
      })}`,
      showToast: true,
      toastOptions: {
        title: t('anErrorOcurredWhileGeneratingYourPlaylist'),
        description: `${t('noContentFoundByTypeAndSkill', {
          contentType: getContentTypeNameByCode(errors[0].code, t),
          skillName: skill?.name ?? t('invalidSkillName'),
        })}`,
        ...generateDefaultErrorProps(playlistWasCreated ? 'info' : 'error'),
      },
    }
  }

  const skillsAndContentTypes = errors?.reduce((acc, curr) => {
    const skill = skills?.find((skill) => skill.id === curr.skill_id)
    const key = curr.code

    if (acc.hasOwnProperty(key)) {
      const value = acc[key]
      return { ...acc, [key]: value + `, ${skill?.name ?? '(habilidade inválida)'}` }
    }

    return { ...acc, [key]: `${skill?.name ?? '(habilidade inválida)'}` }
  }, {} as { [key: string]: string })

  const _skillsAndContentTypes: { [key: string]: string } = {}

  Object.keys(skillsAndContentTypes).forEach((key) => {
    Object.assign(_skillsAndContentTypes, {
      [getContentTypeNameByCode(key, t)]: skillsAndContentTypes[key],
    })
  })

  const parsedListOfSkillsAndContents = Object.keys(_skillsAndContentTypes).map(
    (key: string) => `${key}: ${_skillsAndContentTypes[key]}`,
  )

  return {
    errorMessage: `${t('noContentFoundForCertainSkillsSeeMoreBelow')}`,
    skillsAndContentTypes: parsedListOfSkillsAndContents,
    showToast: playlistWasCreated,
    toastOptions: playlistWasCreated
      ? {
          title: '',
          description: t('someSkillsNotFound'),
          ...generateDefaultErrorProps(playlistWasCreated ? 'info' : 'error'),
        }
      : undefined,
  }
}
