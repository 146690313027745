import { Flex } from '@chakra-ui/react'
import ReactSelect from "react-select";
import { useQuery } from "@tanstack/react-query";
import { makeGetUsers } from '../factory/makeGetUsers';
import { MultiSelectStylesForDashboards } from 'src/pages-admin/Cms/utils/multiSelectStyles';
import { Option } from './ReportContent';
import { useTranslation } from 'react-i18next';

interface SelectUsersProps {
  usersSelected: Option[],
  subsidiariesSelected: Option[],
  areasSelected: Option[],
  setUsersSelected: (value: Option[]) => void
}

export const SelectUsers = ({ subsidiariesSelected, areasSelected, usersSelected, setUsersSelected }: SelectUsersProps) => {
  const [t] = useTranslation()
  const { data: users, isLoading: isLoadingUsers } = useQuery({
    queryKey: [
      "users",
      areasSelected,
      subsidiariesSelected,
    ],
    queryFn: () => {
      const getReport = makeGetUsers()
      return getReport.execute({
        areas: areasSelected.length ? areasSelected.map((area) => area.value) : undefined,
        subsidiaries: subsidiariesSelected.length ? subsidiariesSelected.map((subsidiary) => subsidiary.value) : undefined,
      })
    },
    refetchOnWindowFocus: false,
    initialData: [],
  });

  return (
    <Flex direction='column' flex={1} minW="250px" maxW="300px">
      <ReactSelect
        isSearchable
        isMulti
        styles={MultiSelectStylesForDashboards}
        placeholder={t('admin.reports.select_users')}
        value={usersSelected}
        options={users
          .map((user) => ({ value: user.id, label: user.name }))
        }
        onChange={(selectedOption) => {
          setUsersSelected(selectedOption as Option[])
        }}
        isLoading={isLoadingUsers}
      />
    </Flex>
  )
}
