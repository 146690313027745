import { Flex, Skeleton } from '@chakra-ui/react'

export function SkeletonLoading() {
  return (
    <Flex
      marginX={{ base: '32px', lg: '72px' }}
      border='2px solid #E5E5E5'
      borderRadius='40px'
      padding={{ base: '8px 16px', lg: '22px 37px' }}
      gap={{ base: '16px' }}
      direction={{ base: 'column', lg: 'row' }}
      alignItems={{ base: 'center', lg: 'flex-start' }}
      justifyContent={{ base: 'center', lg: 'flex-start' }}
      w='100%'
    >
      <Skeleton w='112px' h='112px' borderRadius='999px' />
      <Flex flexDir='column' flex={{ base: 'unset', lg: '1' }} width={{ base: '100%' }} gap=''>
        <Skeleton w='100%' h='28px' borderRadius='16px' />
        <Skeleton w='100%' h='20px' borderRadius='16px' mt='4px' />
        <Flex my='16px' position='relative' flexDirection='column'>
          <Skeleton w='100%' h='2px' opacity='0.3' />
          <Skeleton w='100%' h='10px' position='absolute' borderRadius='30px' top='-4px' />
          <Skeleton alignSelf='flex-end' marginTop='5px' />
        </Flex>

        <Flex
          direction={{ base: 'column', lg: 'row' }}
          alignItems={{ base: 'center', lg: 'unset' }}
          justifyContent={{ base: 'center', lg: 'unset' }}
          width={{ base: '100%', lg: 'auto' }}
          gap='16px'
          marginTop={{ base: '8px', lg: '0px' }}
          overflow='hidden'
          position='relative'
        >
          <Skeleton w='100%' minW='220px' maxW={{ base: 'auto', lg: '450px' }} h='48px' borderRadius='16px' />
          <Skeleton w='100%' minW='220px' maxW={{ base: 'auto', lg: '250px' }} h='48px' borderRadius='16px' />
          <Skeleton w='100%' minW='220px' maxW={{ base: 'auto', lg: '250px' }} h='48px' borderRadius='16px' />
        </Flex>
      </Flex>
    </Flex>
  )
}
