export const MultiSelectStyles = (form: any) => ({
  container: (base: any) => ({
    ...base,
    zIndex: 500,
    minHeight: '48px',
    height: 'auto',
  }),
  control: (base: any, { isDisabled }: any) => ({
    ...base,
    minHeight: '48px',
    height: 'auto',
    borderRadius: '16px',
    border: form.errors.skills && form.touched.skills ? '2px solid #E53E3E' : '2px solid #E5E5E5',
    backgroundColor: isDisabled ? '#F7F9FA' : '#FFFFFF',
    '&:hover': {
      border: '2px solid #313E4C',
    },
    boxShadow: 'none',
    fontSize: '.875rem',
  }),
  option: (base: any, { isFocused }: any) => ({
    ...base,
    backgroundColor: isFocused ? '#E5F4EB' : null,
    color: 'black',
    '&:active': {
      backgroundColor: '#E5F4EB',
    },
  }),
  multiValue: (styles: any) => ({
    ...styles,
    backgroundColor: '#E5F4EB',
    borderRadius: '12px',
    border: '2px solid #7ECD9D',
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingRight: '10px',
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: '#313E4C',
    fontWeight: 'bold',
    flexGrow: 1,
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: '#009028',
    cursor: 'pointer',
    backgroundColor: '#7ECD9D',
    borderRadius: '0',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    ':hover': {
      backgroundColor: '#7ECD9D',
      color: '#009028',
    },
  }),
  menuPortal: (styles: any) => ({ ...styles, zIndex: 9999 }),
})

export const MultiSelectStylesForImportedContents = (form: any) => ({
  container: (base: any) => ({
    ...base,
    zIndex: 500,
    minHeight: '48px',
    height: 'auto',
  }),
  control: (base: any, { isDisabled }: any) => ({
    ...base,
    minHeight: '48px',
    height: 'auto',
    borderRadius: '16px',
    border: '2px solid #E5E5E5',
    backgroundColor: isDisabled ? '#F7F9FA' : '#FFFFFF',
    '&:hover': {
      border: '2px solid #313E4C',
    },
    boxShadow: 'none',
    fontSize: '.875rem',
  }),
  option: (base: any, { isFocused }: any) => ({
    ...base,
    backgroundColor: isFocused ? '#E5F4EB' : null,
    color: 'black',
    '&:active': {
      backgroundColor: '#E5F4EB',
    },
  }),
  multiValue: (styles: any) => ({
    ...styles,
    backgroundColor: '#E5F4EB',
    borderRadius: '12px',
    border: '2px solid #7ECD9D',
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingRight: '10px',
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: '#313E4C',
    fontWeight: 'bold',
    flexGrow: 1,
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: '#009028',
    cursor: 'pointer',
    backgroundColor: '#7ECD9D',
    borderRadius: '0',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    ':hover': {
      backgroundColor: '#7ECD9D',
      color: '#009028',
    },
  }),
})

export const MultiSelectStylesForDashboards = {
  container: (base: any) => ({
    ...base,
    zIndex: 500,
    width: "100%",
    minHeight: '48px',
    height: 'auto',
  }),
  valueContainer: (base: any) => ({
    ...base,
    flexWrap: 'wrap',
    overflowY: 'auto',
    maxHeight: '48px',
  }),
  control: (base: any, { isDisabled }: any) => ({
    ...base,
    minHeight: '48px',
    height: 'auto',
    borderRadius: '16px',
    border: '2px solid #E5E5E5',
    backgroundColor: isDisabled ? '#F7F9FA' : '#FFFFFF',
    '&:hover': {
      border: '2px solid #313E4C',
    },
    boxShadow: 'none',
    fontSize: '.875rem',
  }),
  option: (base: any, { isFocused }: any) => ({
    ...base,
    backgroundColor: isFocused ? '#E5F4EB' : null,
    color: 'black',
    '&:active': {
      backgroundColor: '#E5F4EB',
    },
  }),
  multiValue: (styles: any) => ({
    ...styles,
    backgroundColor: '#E5F4EB',
    borderRadius: '12px',
    border: '2px solid #7ECD9D',
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingRight: '10px',
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: '#313E4C',
    fontWeight: 'bold',
    flexGrow: 1,
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: '#009028',
    cursor: 'pointer',
    backgroundColor: '#7ECD9D',
    borderRadius: '0',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    ':hover': {
      backgroundColor: '#7ECD9D',
      color: '#009028',
    },
  }),
}
