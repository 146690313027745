import { useQuery } from '@apollo/client'
import { Box, Button, Flex, Input, Select, Skeleton, Text, VStack } from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import LIST_USERS from 'src/graphql/queries/LIST_USERS'
import { ReturnDashInfo } from 'src/pages-admin/Home/home.types'
import { useDashboard } from '../../context-admin/useDashboard'
import { usePermissions } from '../../context-admin/usePermissions'
import { useTeams } from '../../context-admin/useTeams'

type Props = {
  handleComponentChange: (value: string) => void
}

const first = 25

const ChangeManager: React.FC<Props> = ({ handleComponentChange }) => {
  const { areas } = useDashboard()
  const { selectedCollab, selectedTeam, newManager, handleNewManager } = useTeams()
  const { collabsList, pageInfo, updateCollabsList, updatePageInfo, incrementCollabsList } = usePermissions()
  const [area, setArea] = useState('')
  const [filter, setFilter] = useState('')

  const { refetch, loading } = useQuery<ReturnDashInfo>(LIST_USERS, {
    skip: collabsList.length > 0,
    variables: { limit: first },
    onCompleted: (data) => {
      updateCollabsList(data.listUsers.data)
      updatePageInfo(data.listUsers.paginateInfo)
    },
  })

  const filteredCollaborators = useMemo(() => {
    const newCollabsList = collabsList.filter((item) => item.id !== selectedCollab.id)

    const collabsByArea = area ? newCollabsList.filter((item) => item.area?.name?.includes(area)) : newCollabsList

    if (filter) {
      return collabsByArea.filter((item) => item.name.toLowerCase().includes(filter.toLowerCase()))
    }

    return area ? collabsByArea : newCollabsList
  }, [filter, collabsList, area, selectedCollab.id])

  const fetchMore = async () => {
    if (!pageInfo?.next) return

    const response = await refetch({
      limit: first,
      cursor: Number(pageInfo?.next),
    })

    incrementCollabsList(response.data.listUsers.data)
    updatePageInfo(response.data.listUsers.paginateInfo)
  }

  const handleBack = () => {
    handleComponentChange('0')
  }

  const handleNextStep = () => {
    handleComponentChange('CHANGE_MANAGER_STEP_TWO')
  }

  const handleAreaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setArea(e.target.value)
  }

  return (
    <>
      <Text
        color='#767F89'
        textDecoration='underline'
        fontFamily='Poppins'
        fontWeight='600'
        marginBottom='22px'
        cursor='pointer'
        onClick={handleBack}
      >
        Voltar
      </Text>

      <VStack align='flex-start'>
        <Text fontSize='20px' fontWeight={600} fontFamily='Poppins' color='gray.31'>
          Selecione o novo gestor de "{selectedTeam.name}"
        </Text>
        <Text color='#767F89' mb='24px'>
          Procure e selecione o novo gestor dessa equipe.
        </Text>

        <Flex direction='column' gridGap='4px' w='100%' mt='24px !important'>
          <Text fontWeight='700' color='#313E4C'>
            Busque colaboradores
          </Text>

          <Input
            variant='newFilled'
            placeholder='Busque por um colaborador'
            w='100%'
            height='36px'
            borderRadius='12px'
            onChange={(e) => setFilter(e.target.value)}
          />
        </Flex>

        <Box m='16px 0 !important'>
          <Text color='#767F89' fontSize='14px' fontWeight={600} mb='4px'>
            Filtros
          </Text>

          <Select
            value={area}
            bgColor='#F7F9FA'
            border='none'
            _hover={{ bgColor: '#F7F9FA' }}
            _focus={{ bgColor: '#F7F9FA' }}
            color='#009639'
            fontWeight={600}
            onChange={handleAreaChange}
            fontSize='12px'
            height='22px'
            width='100%'
            maxW='132px'
            className='area-select'
          >
            <option value=''>Todas as áreas</option>
            {areas.map((item) => (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            ))}
          </Select>
        </Box>

        {loading ? (
          <Flex direction='column' w='100%' mt='16px' gridGap='16px'>
            <Skeleton w='100%' h='43px' borderRadius='8px' />
            <Skeleton w='100%' h='43px' borderRadius='8px' />
            <Skeleton w='100%' h='43px' borderRadius='8px' />
            <Skeleton w='100%' h='43px' borderRadius='8px' />
            <Skeleton w='100%' h='43px' borderRadius='8px' />
          </Flex>
        ) : (
          <Flex id='scrollableDiv' direction='column' mt='16px' w='100%' maxH='calc(100vh - 550px)' overflowY='auto'>
            <InfiniteScroll
              dataLength={filteredCollaborators.length}
              next={fetchMore}
              hasMore={pageInfo?.hasNextPage!}
              loader={
                <Text textAlign='center' display={filteredCollaborators.length === 0 && filter ? 'none' : 'block'}>
                  Carregando mais...
                </Text>
              }
              scrollThreshold={0.9}
              scrollableTarget='scrollableDiv'
            >
              {filteredCollaborators?.map((item) => {
                const isSelected = newManager.name === item.name

                return (
                  <Flex
                    key={item.name}
                    h='43px'
                    align='center'
                    bgColor='#F7F9FA'
                    justify='space-between'
                    borderRadius='8px'
                    p='0 16px'
                    mb='16px'
                    boxShadow='0px 1px 6px rgba(0, 0, 0, 0.1)'
                  >
                    <Text ml='8px' fontWeight='700' fontSize='12px' color='#009639'>
                      {item.name}
                    </Text>
                    <Button
                      variant='unstyled'
                      fontWeight='600'
                      fontSize='12px'
                      textDecoration='underline'
                      color={isSelected ? '#009639' : '#313E4C'}
                      onClick={() => handleNewManager(item)}
                    >
                      {isSelected ? 'Selecionado' : 'Selecionar'}
                    </Button>
                  </Flex>
                )
              })}
            </InfiniteScroll>
          </Flex>
        )}

        <Flex w='100%' justify='space-between' mt='24px !important'>
          <Button variant='whiteOutline' fontSize='12px' onClick={handleBack}>
            Voltar
          </Button>

          <Button variant='solidGray' fontSize='12px' disabled={!newManager.name} onClick={handleNextStep}>
            Continuar
          </Button>
        </Flex>
      </VStack>
    </>
  )
}

export default ChangeManager
