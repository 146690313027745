import { PlaylistRepository } from '../repositories/PlaylistRepository'

interface UpdatePlaylistModuleContentUseCaseInput {
  moduleId: string
  oldContentId: string
  newContentId: string
}

export type UpdatePlaylistModuleContentUseCaseOutput = boolean

export class UpdatePlaylistModuleContentUseCase {
  constructor(private playlistRepository: PlaylistRepository) {}

  async execute(input: UpdatePlaylistModuleContentUseCaseInput): Promise<UpdatePlaylistModuleContentUseCaseOutput> {
    const hasUpdated = await this.playlistRepository.updatePlaylistModuleContent(input.moduleId, input.oldContentId, input.newContentId)
    return hasUpdated
  }
}
