import { Flex, Icon, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
// import "../../../i18n";
import { IoCheckmarkCircle, IoCheckmarkCircleOutline } from 'react-icons/io5'

interface ValidatePasswordProps {
  password: string
  repeatPassword?: string
  mustMatch?: boolean
}

interface ValidationItemProps {
  label: string
  valid: boolean
}

const ValidationItem: React.FC<ValidationItemProps> = ({ label, valid }: ValidationItemProps) => {
  return (
    <Flex alignItems='center'>
      <Icon
        as={valid ? IoCheckmarkCircle : IoCheckmarkCircleOutline}
        color={valid ? 'primaryColor' : 'gray.400'}
        fontSize='xl'
      ></Icon>
      <Text marginLeft='10px'>{label}</Text>
    </Flex>
  )
}

const ValidatePassword: React.FC<ValidatePasswordProps> = ({
  password,
  repeatPassword,
  mustMatch,
}: ValidatePasswordProps) => {
  const [t] = useTranslation()
  const Validation = {
    min: password.length >= 8,
    upperAndLowerCaseLetters: password.match(/[a-z]/) && password.match(/[A-Z]/),
    numbersAndSpecialCharacteres: password.match(/[0-9]/) && password.match(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/),
    matchingPasswords: password !== '' && password === repeatPassword,
  }

  return (
    <Stack spacing={2}>
      <ValidationItem label={t('onboard.adminAccount.validation.Minimum of 8 characteres')} valid={Validation.min} />
      <ValidationItem
        label={t('onboard.adminAccount.validation.Upper and lowercase letter')}
        valid={!!Validation.upperAndLowerCaseLetters}
      />
      <ValidationItem
        label={t('onboard.adminAccount.validation.Number and special character')}
        valid={!!Validation.numbersAndSpecialCharacteres}
      />
      {mustMatch && (
        <ValidationItem
          label={t('onboard.adminAccount.validation.Passwords Match')}
          valid={!!Validation.matchingPasswords}
        />
      )}
    </Stack>
  )
}

export default ValidatePassword
