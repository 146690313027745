import { Select } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useImportedContents } from 'src/pages-admin/Cms/hooks/useImportedContents'

export function TypeFilter() {
  const { filters, applyFilters, listImportedContents } = useImportedContents()

  useEffect(() => {
    listImportedContents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.type])

  useEffect(() => {
    return () => {
      applyFilters('type', undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Select
      value={filters.type}
      bgColor='#F7F9FA'
      border='none'
      _hover={{ bgColor: '#F7F9FA' }}
      _focus={{ bgColor: '#F7F9FA' }}
      fontWeight={600}
      fontSize='.875rem'
      paddingRight='12px'
      w='auto'
      height='22px'
      className={`select-default`}
      onChange={(e) => applyFilters('type', e.target.value)}
      data-testid='cms-type-filter-select'
    >
      <option value=''>Tipo</option>
      <option value='VIDEO'>Video</option>
      <option value='ARTICLE'>Artigo</option>
      <option value='COURSE'>Curso</option>
    </Select>
  )
}
