import { Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleTeam } from 'src/context-admin/useHandleTeam'
import { NavigationButtons } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/NavigationButtons'
import { StepTitle } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTitle'
import { AvailableStepsType } from 'src/types/HandleTeamDrawer'

type Option = {
  id: AvailableStepsType
  label: string
  color?: string
  bg?: string
}

export function EditExistingTeamSelectOptions() {
  const [t] = useTranslation()
  const [selectedStep, setSelectedStep] = useState<AvailableStepsType | undefined>(undefined)
  const { team, handleSetTeamValues, navigateToStep } = useHandleTeam()

  const options: Option[] = [
    {
      id: 'edit-existing-team--add-new-manager',
      label: t('addManager'),
      color: '#313E4C',
    },
    {
      id: 'edit-existing-team--change-manager-select-new',
      label: t('changeManager'),
      color: '#2F80ED',
    },
    {
      id: 'edit-existing-team--remove-manager',
      label: t('removeManager'),
    },
    {
      id: 'edit-existing-team--add-new-users',
      label: t('addNewMembers'),
      color: 'green.500',
    },
    {
      id: 'edit-existing-team--remove-users',
      label: t('removeMembers'),
      color: '#FF4949',
      bg: '#FFF4F4',
    },
    {
      id: 'edit-existing-team--delete-team',
      label: t('deleteTeam'),
      color: '#313E4C',
    },
  ]

  const handleSelectStepToNavigate = (id?: AvailableStepsType) => {
    setSelectedStep((prev) => (prev === id ? undefined : id))
  }

  useEffect(() => {
    handleSetTeamValues({ newManagers: undefined, newUsers: undefined })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex flexDir='column'>
      <StepTitle title={t('editingArea', { areaName: team?.name ?? t('invalidTeam') })} />
      <Flex flexDir='column' mt='24px'>
        <Text>{t('whatDoYouWantToDoWithThisTeam')}</Text>
        <Flex flexDir='column' gap='16px' mt='16px'>
          {options.map((option) => {
            const optionConditions = { shouldDisableThisOption: false, labelSuffix: '' }
            if (option.id === 'edit-existing-team--remove-manager') {
              const teamHasManagers = (team?.managers?.length ?? 0) > 0
              optionConditions.shouldDisableThisOption = !teamHasManagers
              if (!teamHasManagers)
                optionConditions.labelSuffix = '*disponível apenas para equipes com mais de um gestor'
            }
            return (
              <Flex
                key={option.id}
                bg={option.bg ? option.bg : '#F7F9FA'}
                px='16px'
                py='12px'
                cursor='pointer'
                borderRadius='8px'
                borderWidth='2px'
                borderColor={selectedStep === option.id ? option.color ?? 'bluishGray.500' : 'transparent'}
                boxShadow='0px 1px 6px 0px rgba(0, 0, 0, 0.10)'
                onClick={() =>
                  optionConditions.shouldDisableThisOption ? null : handleSelectStepToNavigate(option.id)
                }
                transition='all 150ms ease'
              >
                <Text
                  opacity={optionConditions.shouldDisableThisOption ? 0.3 : 1}
                  fontWeight='700'
                  fontSize='0.75rem'
                  {...(option.color ? { color: option.color } : {})}
                >
                  {option.label} {optionConditions.labelSuffix}
                </Text>
              </Flex>
            )
          })}
        </Flex>
      </Flex>
      <NavigationButtons
        isDisabled={!selectedStep}
        onContinueClick={() => navigateToStep(selectedStep!)}
        onBackClick={() => navigateToStep('edit-existing-team')}
      />
    </Flex>
  )
}
