export function getOrderSkillsFilterField(filter: string) {
  let field = 'name'
  let direction = 'ASC'

  if (filter === 'NAME_DESC' || filter === 'LATEST_UPDATE') {
    direction = 'DESC'
  }

  if (filter === 'LATEST_UPDATE' || filter === 'OLDER_UPDATE') {
    field = 'updatedAt'
  }

  return { [field]: direction }
}
