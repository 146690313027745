import { Popover } from '@chakra-ui/react'
import EvaluateIcon from 'src/components/EvaluateIcon'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { useEvaluate } from 'src/context/EvaluateContext'

export function EvaluatePlaylist() {
  const { playlist } = useCustomPlaylist()
  const { openEvaluation, updatePlaylistInfo } = useEvaluate()

  return (
    <Popover trigger='hover' placement='top'>
      <EvaluateIcon
        showText
        isPlaylist
        w={{ base: '100%', lg: 'auto' }}
        marginLeft={{ base: '0px', lg: '25px' }}
        onClick={() => {
          if (playlist) updatePlaylistInfo(playlist)
          openEvaluation({ step: 2, source: 'playlist' })
        }}
      />
    </Popover>
  )
}
