import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { FormikProps } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSelect, { OptionTypeBase } from 'react-select'
import { selectStyles } from 'src/components/CustomPlaylistQuiz/utils'
import { useCorporateUniversityStore } from 'src/pages-admin/Cms/hooks/useCorporateUniversityStore'
import { IContentData } from 'src/pages-admin/Cms/hooks/useRegisterContentStore'
import { SelectOption } from 'src/types/Cms'
import { useRegisterNewContent } from '../../hooks/useRegisterNewContent'

interface SelectCompaniesDrawerProps {
  isOpen: boolean
  onClose: () => void
  form: FormikProps<IContentData>
}

export function SelectCompaniesDrawer({ isOpen, onClose, form }: SelectCompaniesDrawerProps) {
  const [t] = useTranslation()
  const { companyList } = useCorporateUniversityStore()
  const {
    selectedCompanies,
    updateSelectedCompanies,
    incrementSelectedCompanies,
    updateSelectedPermission,
    updateSavedSelectedCompanies,
  } = useRegisterNewContent()

  const handleCloseDrawer = () => {
    const allowedCompanyIds = form.values.allowedCompanyIds ?? []
    if (allowedCompanyIds.length === 0 || allowedCompanyIds[0] === -1) {
      updateSelectedPermission('allUsers')
    }

    updateSelectedCompanies([])
    onClose()
  }

  const handleSelectCompany = (item: OptionTypeBase | null) => {
    if (item) {
      incrementSelectedCompanies(item as SelectOption)
    }
  }

  const handleRemoveCompany = (item: SelectOption) => {
    const filteredCompanies = selectedCompanies.filter((company) => company.value !== item.value)
    updateSelectedCompanies(filteredCompanies)
  }

  const handleSave = () => {
    updateSavedSelectedCompanies(selectedCompanies)
    form.setFieldValue(
      'allowedCompanyIds',
      selectedCompanies.map((item) => Number(item.value)),
    )
    updateSelectedCompanies([])
    onClose()
  }

  useEffect(() => {
    if (form && form.values && form.values.allowedCompanyIds) {
      const allowedCompanyIds = form.values.allowedCompanyIds
      const previousCompanies = companyList?.filter(
        (item) => Number(item.id) === allowedCompanyIds.find((companyId) => companyId === Number(item.id)),
      )
      updateSelectedCompanies(previousCompanies.map((item) => ({ label: item.trade_name, value: item.id })))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={handleCloseDrawer} size='xl' closeOnEsc={false}>
      <DrawerOverlay />
      <DrawerContent>
        <Box padding='88px 80px 0px' h='100%'>
          <DrawerHeader fontWeight={700} fontSize='1.25rem'>
            {t('forWhoIsThisContent')}
          </DrawerHeader>

          <DrawerBody h='100%'>
            <Text fontWeight={600} fontSize='1.125rem'>
            {t('selectCompaniesToAccessThisContent')}
            </Text>

            <Flex flexDir='column' h='100%' maxH='70%' w='100%' mt='16px'>
              <ReactSelect
                isClearable={false}
                styles={selectStyles()}
                value={null}
                placeholder='Selecionar empresas'
                components={{ IndicatorSeparator: () => null }}
                onChange={handleSelectCompany}
                options={companyList
                  .filter((item) => !selectedCompanies.find((company) => item.id === company.value))
                  .map((company) => ({ value: company.id, label: company.trade_name }))}
              />
              <Flex h='100%' direction='column' gap='12px' mt='12px' py='12px' overflowY='auto'>
                {selectedCompanies.map((item) => {
                  return (
                    <Flex
                      key={item.value}
                      alignItems='center'
                      justifyContent='space-between'
                      w='100%'
                      minH='48px'
                      p={{ base: '8px 16px', md: '8px 24px' }}
                      bg={'primaryColors.30'}
                      borderRadius='16px'
                    >
                      <Text fontWeight={700} fontSize={{ base: '1rem', sm: '1.125rem' }}>
                        {item.label}
                      </Text>
                      <Text
                        cursor='pointer'
                        fontWeight={600}
                        fontSize='0.75rem'
                        fontFamily='Poppins'
                        onClick={() => handleRemoveCompany(item)}
                      >
                        {t('remove')}
                      </Text>
                    </Flex>
                  )
                })}
              </Flex>
            </Flex>

            <Flex w='100%' align='center' justify='space-between' mt='40px'>
              <Text
                color='#767F89'
                fontSize='1rem'
                textDecoration='underline'
                fontFamily='Poppins'
                fontWeight='600'
                cursor='pointer'
                onClick={handleCloseDrawer}
              >
                {t('back')}
              </Text>
              <Tooltip
                hasArrow
                placement='top'
                fontSize='12px'
                label='Selecione quais empresas terão acesso ao conteúdo'
                isDisabled={selectedCompanies.length !== 0}
                shouldWrapChildren
              >
                <Button
                  variant='unstyled'
                  h='44px'
                  bg='gray.800'
                  color='white'
                  fontSize='1rem'
                  padding='8px 16px'
                  isDisabled={selectedCompanies.length === 0}
                  onClick={handleSave}
                >
                  {t('save')}
                </Button>
              </Tooltip>
            </Flex>
          </DrawerBody>
        </Box>
      </DrawerContent>
    </Drawer>
  )
}
