import { gql } from '@apollo/client'

const SUBMIT_EMPLOYEES = gql`
  mutation importUsers($users: [ImportUserInput]) {
    importUsers(users: $users) {
      errors {
        email
        message
        code
        name
      }
      users {
        id
      }
    }
  }
`

export default SUBMIT_EMPLOYEES
