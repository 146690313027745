import { AxiosInstance } from 'axios'
import Content from 'src/modules/cms/domain/Content'
import { CREATE_CONTENT } from 'src/modules/cms/graphql/mutations/CREATE_CONTENT'
import { DELETE_CONTENT } from 'src/modules/cms/graphql/mutations/DELETE_CONTENT'
import { UPDATE_CONTENT } from 'src/modules/cms/graphql/mutations/UPDATE_CONTENT'
import { GET_CONTENT } from 'src/modules/cms/graphql/queries/GET_CONTENT'
import { GET_CONTENTS } from 'src/modules/cms/graphql/queries/GET_CONTENTS'
import { ContentAction } from 'src/modules/cms/use_cases/MarkContentUseCase'
import { IContentData } from 'src/pages-admin/Cms/hooks/useRegisterContentStore'
import { apolloClient, cmsQueryContext } from 'src/services/apollo/ApolloClient'
import { cmsClient } from 'src/services/http/cms-client'
import httpClient, { IHttpClient } from 'src/services/http/http-client'
import ApiError, { NetWorkError } from 'src/shared/ApiError'
import { logger } from 'src/shared/Logger'
import { CmsContentQuery, UpdateContentInput } from 'src/types/Cms'
import { User } from 'src/types/User'
import {
  IContentRepository,
  ListContentsInput,
  SaveImportedContentByActionType,
  SaveTranslatedContentsInput,
} from '../types/IContentRepository'
import { ListLastAccessedByUserInputs } from '../use_cases/ListLastAccessedContentsUseCase'

export class ContentRepository implements IContentRepository {
  constructor(private token?: string, private httpClient?: IHttpClient | typeof apolloClient | AxiosInstance) {
    this.token = token ?? ''
  }

  list(params: ListContentsInput) {
    const promise = apolloClient
      .query<CmsContentQuery>({
        query: GET_CONTENTS,
        context: cmsQueryContext,
        fetchPolicy: 'no-cache',
        variables: {
          limit: params.limit,
          skip: params.skip,
          order: params.order,
          ...(params.title ? { title: params.title } : {}),
          ...(params.skills ? { skills: params.skills } : {}),
          ...(params.partnerId ? { partnerId: params.partnerId } : {}),
          ...(params.contentIds ? { ids: params.contentIds } : {}),
          ...(params.type ? { type: params.type } : {}),
          ...(params.status ? { status: params.status } : {}),
          ...(params?.languages && params?.languages?.length > 0 && params?.languages?.some((lang) => lang !== '')
            ? { languages: { in: params.languages } }
            : {}),
        },
      })
      .then(async ({ data, errors }) => {
        if (errors?.length) {
          const code = errors[0].message
          const message = ''
          throw new ApiError(message, code)
        }

        return data.contentList
      })
      .catch((error) => {
        logger.error('list contents request failed', { error })
        if (error instanceof ApiError) throw error
        throw new NetWorkError()
      })

    return promise
  }

  listLastAccessedByUser(params: ListLastAccessedByUserInputs) {
    const promise = apolloClient
      .query<CmsContentQuery>({
        query: GET_CONTENTS,
        context: cmsQueryContext,
        variables: {
          limit: params.limit,
          skip: params.skip,
          ids: params.ids,
        },
      })
      .then(async ({ data, errors }) => {
        if (errors?.length) {
          const code = errors[0].message
          const message = ''
          throw new ApiError(message, code)
        }

        return data.contentList
      })
      .catch((error) => {
        logger.error('list contents by ids request failed', { error })
        if (error instanceof ApiError) throw error
        throw new NetWorkError()
      })

    return promise
  }

  getById(id: string) {
    const promise = apolloClient
      .query({
        query: GET_CONTENT,
        context: cmsQueryContext,
        variables: {
          id,
        },
      })
      .then(async ({ data, errors }) => {
        if (errors?.length) {
          const code = errors[0].message
          const message = ''
          throw new ApiError(message, code)
        }

        return data.content as Content
      })
      .catch((error) => {
        logger.error('list contents request failed', { error })
        if (error instanceof ApiError) throw error
        throw new NetWorkError()
      })

    return promise
  }

  async getUrl(id: string): Promise<string | null> {
    try {
      const response = await cmsClient.request({
        url: `/content/${id}/url`,
        method: 'GET',
        headers: {
          Authorization: `${this.token}`,
        },
      })

      return response.data.url as string
    } catch {
      return null
    }
  }

  create(entity: IContentData) {
    const promise = apolloClient
      .query({
        query: CREATE_CONTENT,
        context: cmsQueryContext,
        variables: {
          input: entity,
        },
      })
      .then(async ({ data, errors }) => {
        if (errors?.length) {
          const code = errors[0].message
          const message = ''
          throw new ApiError(message, code)
        }

        return data?.createContent?.id
      })
      .catch((error) => {
        logger.error('create content request failed', { error })
        if (error instanceof ApiError) throw error
        throw new NetWorkError()
      })

    return promise
  }

  update(entity: UpdateContentInput) {
    const promise = apolloClient
      .query({
        query: UPDATE_CONTENT,
        context: cmsQueryContext,
        variables: {
          input: entity,
        },
      })
      .then(async ({ errors }) => {
        if (errors?.length) {
          const code = errors[0].message
          const message = ''
          throw new ApiError(message, code)
        }

        return true
      })
      .catch((error) => {
        logger.error('update content request failed', { error })
        if (error instanceof ApiError) throw error
        throw new NetWorkError()
      })

    return promise
  }

  delete(contentId: string) {
    const promise = apolloClient
      .query({
        query: DELETE_CONTENT,
        context: cmsQueryContext,
        variables: {
          id: contentId,
        },
      })
      .then(async ({ errors }) => {
        if (errors?.length) {
          const code = errors[0].message
          const message = ''
          throw new ApiError(message, code)
        }

        return true
      })
      .catch((error) => {
        logger.error('delete content request failed', { error })
        if (error instanceof ApiError) throw error
        throw new NetWorkError()
      })

    return promise
  }

  async markContent(id: string, playlistId: string, moduleId: string, action: ContentAction) {
    try {
      const data = { id, playlistId, moduleId, action }
      const response = await httpClient.request({
        url: `/v1/iam/content/${id}/act`,
        method: 'POST',
        headers: {
          Authorization: `${this.token}`,
        },
        data,
      })
      return response?.body as User
    } catch {
      return null
    }
  }

  async saveImportedContent(content: Content, action: SaveImportedContentByActionType, partnerId: string) {
    try {
      await httpClient.request({
        url: `/v1/cms/content/import`,
        method: 'POST',
        headers: {
          Authorization: `${this.token}`,
        },
        data: {
          content,
          action,
          partnerId,
        },
      })
      return true
    } catch {
      return false
    }
  }

  async saveContentTranslations({ contentId, isUpdate, language, contentInfo }: SaveTranslatedContentsInput) {
    try {
      const data = {
        ...(contentInfo?.title && { title: contentInfo.title }),
        ...(contentInfo?.description && { description: contentInfo.description }),
      }
      await (this?.httpClient as AxiosInstance)[isUpdate ? 'put' : 'post'](
        `/content/${contentId}/translation/${language}`,
        data,
        {
          headers: {
            Authorization: `${this.token}`,
          },
        },
      )
      return true
    } catch {
      return false
    }
  }
}
