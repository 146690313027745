import axios, { AxiosRequestConfig } from 'axios'
import { HttpResponse, IHttpClient } from './http-client'

export const cmsClient = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_CMS_API_REST_URI ?? 'https://cms-jmyusmpnnq-uk.a.run.app/api/v1'
      : process.env.REACT_APP_CMS_API_REST_LOCAL_URI ?? 'https://cms-jmyusmpnnq-uk.a.run.app/api/v1',
})

export class CmsHttpClient implements IHttpClient {
  async request<R = any>(config: AxiosRequestConfig): Promise<HttpResponse<R>> {
    const axiosResponse = await cmsClient.request({
      baseURL: config.baseURL,
      url: config.url,
      method: config.method,
      data: config.data,
      headers: config.headers,
      ...config,
    })

    return {
      statusCode: axiosResponse.status,
      body: axiosResponse.data.data || axiosResponse.data,
      paginateInfo: axiosResponse.data.paginateInfo,
    }
  }
}

export default new CmsHttpClient()
