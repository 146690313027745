import { PlaylistList } from 'src/types/Cms'
import { PlaylistRepository } from '../repositories/PlaylistRepository'

type ListPrivatePlaylistsUseCaseInput = {
  status?: string
  limit?: number
  skip?: number
  order?: {
    field?: string
    direction?: string
  }
}

export type ListPrivatePlaylistsUseCaseOutput = PlaylistList

export class ListPrivatePlaylistsUseCase {
  constructor(private playlistRepository: PlaylistRepository) {}

  async execute(input?: ListPrivatePlaylistsUseCaseInput): Promise<ListPrivatePlaylistsUseCaseOutput> {
    const params = { ...input }

    const playlistList = await this.playlistRepository.getPrivatePlaylists(params)
    return playlistList
  }
}
