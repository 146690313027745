import { Button } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useImportedContents } from 'src/pages-admin/Cms/hooks/useImportedContents'
import { useImportedContentsForm } from '../../../../hooks/useImportedContentsForm'

export function SaveDraftImportedContents() {
  const [t] = useTranslation()
  const { loadingSaveImportedContents } = useImportedContents()
  const { hookForm, onSubmit } = useImportedContentsForm()

  return (
    <Button
      variant='outline'
      display='flex'
      alignItems='center'
      gap='8px'
      h='48px'
      isLoading={loadingSaveImportedContents}
      onClick={() => hookForm.handleSubmit((data, event) => onSubmit(data, event, 'draft'))()}
    >
      {t('saveChanges')}
    </Button>
  )
}
