import { TFunction } from 'react-i18next'

export const accountRoutes = {
  account: '/account',
  myCourses: '/account/my-courses',
  myPlaylists: '/account/my-playlists',
}

export const menus = (t: TFunction<'translation', undefined>) => {
  return [
    {
      name: t('account.infoSection.Personal Information'),
      path: accountRoutes.account,
    },
    {
      name: t('common.My Courses'),
      path: accountRoutes.myCourses,
    },
    {
      name: t('common.My Playlists'),
      path: accountRoutes.myPlaylists,
    },
  ]
}

export const getLastUpdate = (updateAt: string) => {
  const dateObject = new Date(Number(updateAt))

  let month = (dateObject.getMonth() + 1).toString()
  let hours = dateObject.getHours().toString()
  let minutes = dateObject.getMinutes().toString()

  if (month.length === 1) month = `0${month}`
  if (hours.length === 1) hours = `0${hours}`
  if (minutes.length === 1) minutes = `0${minutes}`

  const date = `${dateObject.getDate()}/${month}/${dateObject.getFullYear()}`
  const time = `${hours}:${minutes}`

  return [date, time]
}
