import { Button, Flex, Heading, Image, Link, Stack, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { LOGIN_CONFIRM_EMAIL_VERIFICATION_TEST_ID } from 'src/modules/iam/components/ConfirmEmailVerification'
import useConfirmEmailVerification from 'src/modules/iam/hooks/useConfirmEmailVerification'
import '../../services/i18n/i18n'

const InvalidSignUp: React.FC = () => {
  const [t] = useTranslation()
  const history = useHistory()
  const urlParams = new URLSearchParams(window.location.search)
  const email = urlParams.get('email')
  const { setUserEmail, timer, isCounting, resendEmail, isLoading } = useConfirmEmailVerification()

  useEffect(() => {
    if (email) setUserEmail(email)
  }, [email, setUserEmail])

  const goToLogin = () => {
    history.push('/login')
  }

  return (
    <Flex bg='white' minHeight='100vh' flexDirection={['column', 'column', 'column', 'row', 'row']}>
      <Helmet>
        <title>{t('signup.title')} | Education Journey</title>
      </Helmet>
      <Stack
        backgroundImage={[
          "url('/assets/images/responsive-extra-path.svg')",
          "url('/assets/images/responsive-extra-path.svg')",
          "url('/assets/images/responsive-extra-path.svg')",
          "url('/assets/images/extra-path.svg')",
          "url('/assets/images/extra-path.svg')",
        ]}
        backgroundSize={['auto 100%', 'auto 100%', 'auto 100%', 'cover', 'cover']}
        backgroundRepeat='no-repeat'
        flexDirection='column'
        alignItems='flex-start'
        flex='1'
        minHeight={['300px', '450px', '650px', 'auto', 'auto']}
      >
        <Link href='https://new.education-journey.com/'>
          <Image margin='30px 0px 0px 30px' width='200px' src='/assets/images/ej-white-logo.svg' />
        </Link>
      </Stack>
      <Flex flexDirection='column' alignItems='center' justifyContent='center' flex='1' marginY='50px'>
        <Heading
          alignSelf='left'
          fontWeight='600'
          color='gray.800'
          fontFamily='Poppins'
          size='md'
          marginBottom='10px'
          marginTop='21px'
        >
          {t('invalid sign up.title')}
        </Heading>
        <Text fontSize='16px' fontWeight='500' color='gray.800' width='70%' marginBottom='30px'>
          {t('invalid sign up.description')}
        </Text>
        <Button
          disabled={isCounting}
          isLoading={isLoading}
          onClick={resendEmail}
          variant='startCourseDark'
          data-testid={LOGIN_CONFIRM_EMAIL_VERIFICATION_TEST_ID.resend}
        >
          {isCounting ? `${t('onboard.confirmEmail.resend')} (${String(timer)})` : t('onboard.confirmEmail.resend')}
        </Button>
        <Button variant={'outline'} marginTop={6} onClick={goToLogin}>
          {t('invalid sign up.button')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default InvalidSignUp
