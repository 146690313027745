import { gql } from '@apollo/client'

export const ADD_LEARNING_ITEMS_TO_PLAYLIST = gql`
  mutation addLearningItemsToPlaylist($learningItemsEntryId: [String]!, $moduleId: Int!) {
    addLearningItemsToPlaylist(learningItemsEntryId: $learningItemsEntryId, moduleId: $moduleId) {
      playlistId
      moduleId
    }
  }
`
