import { ContentRepository } from 'src/modules/cms/repositories/ContentRepository'
import { IContentData } from 'src/pages-admin/Cms/hooks/useRegisterContentStore'
import { convertTimeToSeconds } from 'src/pages-admin/Cms/utils/convertTimeToSeconds'
import { UpdateContentInput } from 'src/types/Cms'

interface UpdateContentUseCaseInput extends IContentData {
  id: string
  updatedStatus?: string
}

export type UpdateContentUseCaseOutput = boolean

export class UpdateContentUseCase {
  constructor(private contentRepository: ContentRepository) {}

  async execute(input: UpdateContentUseCaseInput): Promise<UpdateContentUseCaseOutput> {
    const content: UpdateContentInput = {
      id: input.id,
      title: input.title,
      type: input.type,
      url: input.url,
      description: input.description,
      duration:
        typeof input.duration === 'number' ? input.duration : convertTimeToSeconds(String(input.duration) ?? '0'),
      languages: input.languages,
      subtitles: input.subtitles?.some(item => item === 'no-subtitle') ? [] : input.subtitles!,
      allowedCompanyIds: input.allowedCompanyIds,
      imageUrl: input?.imageUrl ?? 'https://i.ibb.co/fS8fCDS/dummy-300x300-009136-ffffff-education-journey.png',
      skills: input.skills?.map((item) => item.value),
      hasCertificate: input.issuesCertificate,
      partnerId: input.partner,
      trailerUrl: input.trailer,
      status: input?.updatedStatus ?? 'PUBLISHED',
    }

    const hasUpdated = await this.contentRepository.update(content)
    return hasUpdated
  }
}
