import { Select } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface Props {
  order: string
  handleOrderChange: (order: string) => void
}

const OrderSelect: React.FC<Props> = ({ order, handleOrderChange }: Props) => {
  const [t] = useTranslation()
  return (
    <Select
      value={order}
      bgColor='white'
      border='2px solid #767F89'
      _hover={{ borderColor: '#313E4C' }}
      fontWeight={600}
      onChange={(e) => handleOrderChange(e.target.value)}
      fontSize='12px'
      height='36px'
      width='fit-content'
      sx={{ color: '#767F89' }}
    >
      <option value=''>{t('admin.filter.Sort')}</option>
      <option value='NAME_A_Z'>{t('nameFromAtoZ')}</option>
      <option value='NAME_Z_A'>{t('nameFromZtoA')}</option>
    </Select>
  )
}

export default OrderSelect
