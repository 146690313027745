import Drawer from 'src/components/Drawer'
import { ContentModal } from 'src/pages-admin/Cms/components/ContentModal'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export function AddContentDrawer({ onClose }: Props) {
  return (
    <Drawer width='896px'>
      <ContentModal close={onClose} padding='0 100px' />
    </Drawer>
  )
}
