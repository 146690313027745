import { PlaylistRepository } from 'src/modules/cms/repositories/PlaylistRepository'
import { getAccessToken } from 'src/modules/iam/apis/store'
import { cmsClient } from 'src/services/http/cms-client'
import { ReplacePlaylistPoliciesUseCase } from '../use_cases/ReplacePlaylistPoliciesUseCase'

export function makeReplacePlaylistPolicies() {
  const token = getAccessToken()
  const playlistRepository = new PlaylistRepository(cmsClient, token)
  return new ReplacePlaylistPoliciesUseCase(playlistRepository)
}
