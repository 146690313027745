import { Box, Button, Collapse, Flex, Heading, Tooltip, useDisclosure, useToast } from '@chakra-ui/react'
import { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IoChevronDown, IoChevronUp } from 'react-icons/io5'
import { useHistory, useLocation } from 'react-router-dom'
import RemoveContentModal from 'src/components/RemoveContentModal'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { useSwapContentDrawer } from 'src/context/CustomPlaylist/SwapContentDrawerContext'
import { useEvaluate } from 'src/context/EvaluateContext'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { useModal } from 'src/hooks/useModal'
import { useWindowSize } from 'src/hooks/useWindowSize'
import Content from 'src/modules/cms/domain/Content'
import { ContentType } from 'src/modules/cms/domain/LearningItem'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { makeGetContentUrl } from 'src/modules/cms/factories/makeGetContentUrl'
import { renderCorrectContentInfoByLanguage } from 'src/modules/internationalization/helpers/renderCorrectContentInfoByLanguage'
import { LearningItemProgress } from 'src/types/LearningItemProgress'
import { User } from 'src/types/User'
import useRegisterAccess from '../../hooks/useRegisterAccess'
import FavoriteIcon from '../FavoriteIcon'
import MarkCompleteModal from '../MarkCompleteModal'
import { AccessLearningItemButton } from './components/AccessLearningItemButton'
import { ContentImage } from './components/ContentImage'
import { ContentMainButton } from './components/ContentMainButton'
import { CustomPlaylistButtons } from './components/CustomPlaylistButtons'
import { CustomPlaylistDone } from './components/CustomPlaylistDone'
import { HeaderInfo } from './components/HeaderInfo'
import { KnowMoreButton } from './components/KnowMoreButton'
import { MarkButtons } from './components/MarkButtons'
import SelectContent from './components/SelectContent'
import { useLearningCardContentSize } from './useLearningCardContentSize'

interface Props {
  content: Content
  playlist?: NewPlaylist
  style?: React.CSSProperties
  moduleName?: string
  moduleId?: string
  showImage?: boolean
  showCustomPlaylistButtons?: boolean
  showMarkButtons?: boolean
  isCompleteOpen: boolean
  showHeaderInfo?: boolean
  showMainIconButton?: boolean
  showProficiency: boolean
  toggleModal: () => void
  toggleCompleteModal: () => void
  onMarkAsDoing: () => void
  onMarkAsUndone: () => void
  onMarkAsDone: (userInfo: User | undefined) => void
  onKnowMoreClick?: (content: Content) => void
  setLearningItem: (content: Content) => void
  showAddItemButtons?: boolean
  showRemoveButton?: boolean
  showSource?: boolean
  isCompanyPlaylist?: boolean
}

export const HorizontalLearningCardContent: React.FC<Props> = ({
  content,
  playlist,
  showImage,
  style,
  showCustomPlaylistButtons = false,
  showMarkButtons = true,
  showHeaderInfo = true,
  showMainIconButton = true,
  showProficiency,
  moduleName,
  moduleId,
  toggleModal,
  onMarkAsDoing,
  onKnowMoreClick,
  toggleCompleteModal,
  onMarkAsUndone,
  isCompleteOpen,
  onMarkAsDone,
  setLearningItem,
  showAddItemButtons,
  showRemoveButton,
  isCompanyPlaylist = false,
}: Props) => {
  const { t } = useTranslation()
  const toast = useToast()
  const register = useRegisterAccess()
  const container = useRef(null)
  const location = useLocation()
  const history = useHistory()
  const { EventsImplementation, track } = useMixpanel()
  const { openEvaluation, updateCourseInfo, updatePlaylistInfo } = useEvaluate()
  const { showSuccessfulMessage } = useCustomPlaylist()
  const { isOpen: isRemoveContentOpen, toggleModal: toggleRemoveContentModal } = useModal(showSuccessfulMessage)
  const { setSwapContentDrawerInfo } = useSwapContentDrawer()
  const { isOpen: isMobileCardContentOpen, onToggle: onToggleMobileCardContent } = useDisclosure()
  const { width } = useWindowSize()
  const isMobile = useMemo(() => width && width < 992, [width])

  const playlistCourseCardContentSize = useLearningCardContentSize(container)
  const removeCourseWordInButtons = playlistCourseCardContentSize.width
    ? playlistCourseCardContentSize.width < 940
    : false
  const useShowMoreIconInstead = playlistCourseCardContentSize.width ? playlistCourseCardContentSize.width < 900 : false

  const isContentOnProgress = content?.progress === LearningItemProgress.DOING
  const isContentDone = content?.progress === LearningItemProgress.DONE
  const isContentToDo = content?.progress === LearningItemProgress.TODO

  const isCourse = [ContentType.COURSE, 'OldCourse', 'CourseDto'].includes(content.type)

  const handleEvaluateClick = () => {
    openEvaluateModal()

    track(
      EventsImplementation.RATE_LEARNING_ITEM(location.pathname, content.title, content.type, content?.partner?.name),
    )
  }

  const openEvaluateModal = () => {
    const isTheLastCourseOfThePlaylist = content.id === playlist?.id

    if (isTheLastCourseOfThePlaylist) {
      openEvaluation({
        step: !isContentDone ? 1 : 0,
        source: !isContentDone ? 'playlistLearningItemInProgress' : 'lastPlaylistLearningItem',
      })
      updatePlaylistInfo(playlist)
    } else {
      openEvaluation({
        step: 1,
        source: !isContentDone && isCourse ? 'playlistLearningItemInProgress' : 'playlistLearningItemFinished',
      })
    }

    updateCourseInfo(content)
  }

  const handleOnClick = () => {
    if (onKnowMoreClick) return onKnowMoreClick(content)

    toggleModal()

    // if (content.attributes) registerUserEvent('open-course-modal', content.attributes)

    track(
      EventsImplementation.OPEN_LEARNING_ITEM_MODAL(
        location.pathname,
        content.title,
        content?.partner?.name!,
        'Curso',
        content.type,
      ),
    )
  }

  const handleStartContent = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    register(content.id)
    const isVideo = content.type === ContentType.VIDEO || content.type === ContentType.VIDEODTO

    if (isVideo && content?.allowsEmbedding) {
      history.push(`/learningItem/${content.id}`)
    } else {
      const getUrl = makeGetContentUrl()
      const url = await getUrl.execute({ id: content.id })

      if (!url) {
        toast({
          title: t('errorWhileLoadingContent'),
          isClosable: true,
          duration: 5000,
          status: 'error',
        })

        return
      }

      window.open(url, '_blank')
    }

    track(
      EventsImplementation.ACCESS_LEARNING_ITEM(
        null,
        content?.favorited!,
        content.type,
        content.title,
        content.id,
        content?.partner?.name!,
        location.pathname,
        'Card',
      ),
    )

    if (showMarkButtons && isContentToDo) onMarkAsDoing()
  }

  const handleRemoveItem = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()

    //setNewLearningItems((prevState) => prevState.filter((item) => item.entryId !== learningItem.entryId))
  }

  return (
    <Flex
      h='auto'
      minH='186px'
      border='2px solid #E5E5E5'
      borderRadius='30px'
      style={style}
      flex='1'
      maxWidth='100%'
      w='100%'
      cursor='pointer'
      onClick={() => (isMobile ? null : handleOnClick())}
      position='relative'
      ref={container}
      direction={{ base: 'column', lg: 'row' }}
      data-cy={`${content.type}_${content.id}`}
    >
      {showImage && <ContentImage content={content} />}
      <Flex
        marginLeft={{ base: '0px', lg: '40px' }}
        marginRight={{ base: '0px', lg: '24px' }}
        paddingX={{ base: '16px', lg: '0px' }}
        paddingY={{ base: '18px', lg: '19px' }}
        flexDirection='column'
        flex='1'
        width={{ base: '100%', lg: showImage ? 'calc(100% - 297px)' : 'calc(100% - 67px)' }}
        justifyContent='center'
        align={{ base: 'flex-start', lg: 'flex-start' }}
      >
        {showHeaderInfo && <HeaderInfo content={content} moduleName={moduleName} showProficiency={showProficiency} />}

        <Tooltip
          hasArrow
          bg='gray.50'
          color='gray.800'
          p='16px 14px'
          placement='top'
          borderRadius='4px'
          boxShadow='0px 4px 6px rgba(0, 0, 0, 0.15);'
          label={content.title.length >= 56 && renderCorrectContentInfoByLanguage(content, 'title')}
        >
          <Heading
            size='sm'
            marginTop={!showHeaderInfo ? '19px' : 'unset'}
            textOverflow='ellipsis'
            overflow='hidden'
            mb='4px'
            maxHeight='29px'
            css={{
              '-webkit-line-clamp': '1',
              display: '-webkit-box',
              '-webkit-box-orient': 'vertical',
            }}
          >
            {renderCorrectContentInfoByLanguage(content, 'title')}
          </Heading>
        </Tooltip>

        {/* {showSource && (
          <Flex alignItems='center'>
            {learningItem.partner && (
              <Image
                w='18px'
                h='18px'
                src={
                  isCourse
                    ? learningItem.partner?.squareLogo
                    : `https://s2.googleusercontent.com/s2/favicons?domain=${getDomain(learningItem.url)}`
                }
              />
            )}
            <Text ml='8px' color='#767F89' bg='red.900'>
              {isCourse
                ? learningItem.partner
                  ? `${t('playlist.courseCard.Provided by')} ${learningItem.partner.name}`
                  : null
                : `${t('playlist.courseCard.Available at')} ${getDomain(learningItem.url)}`}
            </Text>
          </Flex>
        )} */}

        <Flex
          justify={{ base: 'center', lg: 'space-between' }}
          align={{ base: 'flex-start' }}
          flexDir={{ base: 'row' }}
          marginTop='10px'
          w={{ base: '100%' }}
        >
          {isMobile ? (
            <Flex flexDir='column' align='center' justify='flex-start' w='100%' gap='8px'>
              <Flex flexDir='row' w='100%' gap='8px'>
                {!showAddItemButtons && (
                  <ContentMainButton
                    contentType={content.type}
                    isContentOnProgress={isContentOnProgress}
                    isContentDone={isContentDone}
                    removeCourseWordInButtons={removeCourseWordInButtons}
                    showMainIconButton={showMainIconButton}
                    handleStartContent={handleStartContent}
                    handleEvaluateClick={handleEvaluateClick}
                    cardContentSize={playlistCourseCardContentSize}
                  />
                )}

                {!isContentDone && !showRemoveButton && (
                  <KnowMoreButton useShowMoreIconInstead={false} handleOnClick={handleOnClick} />
                )}
              </Flex>

              <Box w='100%'>
                <Collapse in={isMobileCardContentOpen} animateOpacity>
                  <Flex flexDir='row' flexWrap='wrap' w='100%' h='auto' gap='8px'>
                    {showMarkButtons && !isContentToDo && (
                      <MarkButtons
                        playlist={playlist}
                        content={content}
                        isContentDone={isContentDone}
                        toggleCompleteModal={toggleCompleteModal}
                        openEvaluateModal={openEvaluateModal}
                        onMarkAsUndone={onMarkAsUndone}
                        onMarkAsDone={onMarkAsDone}
                      />
                    )}

                    {!showAddItemButtons && !isCompanyPlaylist && (
                      <Box w='100%'>
                        <FavoriteIcon content={content} onFavorite={setLearningItem} />
                      </Box>
                    )}

                    {showAddItemButtons && !showRemoveButton && (
                      <Box w='100%'>
                        <SelectContent content={content} />
                      </Box>
                    )}

                    {showRemoveButton && (
                      <Button
                        onClick={handleRemoveItem}
                        variant='unstyled'
                        color='bluishGray.500'
                        _hover={{ color: 'red.300' }}
                        _focus={{ outline: 'none' }}
                        margin='0'
                        fontSize={{ base: '.75rem', md: '1rem' }}
                      >
                        {t('home.Custom Playlist.remove content')}
                      </Button>
                    )}

                    {isContentDone && <AccessLearningItemButton handleStartCourseClick={handleStartContent} />}

                    {showCustomPlaylistButtons && !isContentDone && (
                      <Box w='100%'>
                        <CustomPlaylistButtons
                          content={content}
                          cardContentSize={playlistCourseCardContentSize}
                          toggleSwapContentDrawer={() =>
                            setSwapContentDrawerInfo({ content, moduleTitle: moduleName!, moduleId: moduleId! })
                          }
                          toggleRemoveContentModal={toggleRemoveContentModal}
                        />
                      </Box>
                    )}

                    {showCustomPlaylistButtons && isContentDone && (
                      <Box ml={{ base: '0px', lg: 'auto' }} w={{ base: '100%', lg: 'auto' }}>
                        <CustomPlaylistDone />
                      </Box>
                    )}
                  </Flex>
                </Collapse>
              </Box>

              <Flex alignItems='center' justifyContent='flex-start' w='100%'>
                <Button d='flex' gap='4px' variant='unstyled' fontSize='.75rem' onClick={onToggleMobileCardContent}>
                  {isMobileCardContentOpen ? (
                    <>
                      Ver menos
                      <IoChevronUp />
                    </>
                  ) : (
                    <>
                      Ver mais
                      <IoChevronDown />
                    </>
                  )}
                </Button>
              </Flex>
            </Flex>
          ) : (
            <Flex
              flexDir='row'
              flexWrap='wrap'
              align='center'
              justify='flex-start'
              w='100%'
              gap={{ base: '12px', lg: '8px', '2xl': '12px' }}
            >
              {!showAddItemButtons && (
                <ContentMainButton
                  contentType={content.type}
                  isContentOnProgress={isContentOnProgress}
                  isContentDone={isContentDone}
                  removeCourseWordInButtons={removeCourseWordInButtons}
                  showMainIconButton={showMainIconButton}
                  handleStartContent={handleStartContent}
                  handleEvaluateClick={handleEvaluateClick}
                  cardContentSize={playlistCourseCardContentSize}
                />
              )}

              {!isContentDone && !showRemoveButton && !isMobile && (
                <KnowMoreButton useShowMoreIconInstead={useShowMoreIconInstead} handleOnClick={handleOnClick} />
              )}

              {showAddItemButtons && !showRemoveButton && <SelectContent content={content} />}

              {showRemoveButton && (
                <Button
                  onClick={handleRemoveItem}
                  variant='unstyled'
                  color='bluishGray.500'
                  _hover={{ color: 'red.300' }}
                  _focus={{ outline: 'none' }}
                  margin='0'
                >
                  {t('home.Custom Playlist.remove content')}
                </Button>
              )}

              {isContentDone && <AccessLearningItemButton handleStartCourseClick={handleStartContent} />}

              {showMarkButtons && !isContentToDo && (
                <MarkButtons
                  playlist={playlist}
                  content={content}
                  isContentDone={isContentDone}
                  toggleCompleteModal={toggleCompleteModal}
                  openEvaluateModal={openEvaluateModal}
                  onMarkAsUndone={onMarkAsUndone}
                  onMarkAsDone={onMarkAsDone}
                />
              )}

              {!showAddItemButtons && !isCompanyPlaylist && (
                <Box>
                  <FavoriteIcon content={content} onFavorite={setLearningItem} />
                </Box>
              )}

              {showCustomPlaylistButtons && !isContentDone && (
                <Box ml={{ base: '0px', '2xl': 'auto' }}>
                  <CustomPlaylistButtons
                    content={content}
                    cardContentSize={playlistCourseCardContentSize}
                    toggleSwapContentDrawer={() =>
                      setSwapContentDrawerInfo({ content, moduleTitle: moduleName!, moduleId: moduleId! })
                    }
                    toggleRemoveContentModal={toggleRemoveContentModal}
                  />
                </Box>
              )}

              {showCustomPlaylistButtons && isContentDone && (
                <Box ml={{ base: '0px', lg: 'auto' }} w={{ base: '100%', lg: 'auto' }}>
                  <CustomPlaylistDone />
                </Box>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>

      {showMarkButtons ? (
        <MarkCompleteModal
          content={content}
          playlist={playlist}
          onClose={toggleCompleteModal}
          isOpen={isCompleteOpen}
          onMarkAsDone={onMarkAsDone}
          openEvaluateModal={openEvaluateModal}
        />
      ) : null}

      {isRemoveContentOpen && (
        <RemoveContentModal
          moduleName={moduleName}
          moduleId={moduleId}
          content={content}
          isOpen={isRemoveContentOpen}
          onClose={toggleRemoveContentModal}
        />
      )}
    </Flex>
  )
}
