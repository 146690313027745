import { Grid, Spinner } from "@chakra-ui/react";

export default function Loader() {
  return (
    <Grid height="100vh" width="100%" placeItems="center">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Grid>
  );
}
