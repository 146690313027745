import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import TabOptions from './components/TabOptions'
import TabPanelsComponent from './components/TabPanels'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const ModalUpdateEmployees: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const [t] = useTranslation()

  const { isOpen: isOpenAddEmployee, onOpen: onOpenAddEmployee, onClose: onCloseAddEmployee } = useDisclosure()
  const { isOpen: isOpenEditEmployee, onOpen: onOpenEditEmployee, onClose: onCloseEditEmployee } = useDisclosure()

  const onTabClick = () => {
    onCloseAddEmployee()
    onCloseEditEmployee()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay />
      <ModalContent borderRadius='5px' padding='0px'>
        <ModalCloseButton />
        <ModalBody>
          <Stack w='100%'>
            <Text fontFamily='Poppins' fontWeight='semibold' fontSize='20px' paddingY='12px'>
              {t('admin.home.updateEmployees.update')}
            </Text>
          </Stack>
          <Stack bg='white' borderTop='1px solid #E5E5E5'>
            <Tabs isFitted variant='enclosed'>
              <TabOptions onTabClick={onTabClick} />
              <TabPanelsComponent
                isOpenAddEmployee={isOpenAddEmployee}
                onCloseAddEmployee={onCloseAddEmployee}
                onOpenAddEmployee={onOpenAddEmployee}
                isOpenEditEmployee={isOpenEditEmployee}
                onCloseEditEmployee={onCloseEditEmployee}
                onOpenEditEmployee={onOpenEditEmployee}
                onClose={onClose}
              />
            </Tabs>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ModalUpdateEmployees
