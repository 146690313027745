import { Box, Button, Flex, Input, Select, Text, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDashboard } from '../../context-admin/useDashboard'
import { usePermissions } from '../../context-admin/usePermissions'
import { useTeamManagement } from '../../context-admin/useTeamManagement'
import { debounce } from '../../helpers-admin/debounce'
import UserList from './UserList'

type Props = {
  handleStepChange: (value: number) => void
}

const SelectCollabs: React.FC<Props> = ({ handleStepChange }) => {
  const { areas } = useDashboard()
  const { selectedCollab } = usePermissions()
  const { selectTeam, teamName, selectedCollabs } = useTeamManagement()
  const [area, setArea] = useState<number | undefined>(undefined)
  const [filter, setFilter] = useState('')
  const [t] = useTranslation()

  const handleBack = () => {
    handleStepChange(2)
  }

  const handleNextStep = () => {
    handleStepChange(4)
  }

  const handleAreaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setArea(+e.target.value)
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value)
  }

  const debouncedCollabChange = debounce(handleSearchChange, 1000)

  return (
    <>
      <Text
        color='#767F89'
        textDecoration='underline'
        fontFamily='Poppins'
        fontWeight='600'
        marginBottom='22px'
        cursor='pointer'
        onClick={handleBack}
      >
        Voltar
      </Text>

      <VStack align='flex-start'>
        <Text fontSize='20px' fontWeight={600} fontFamily='Poppins' color='gray.31'>
          Atribuição de {t('admin.common.manager')}
        </Text>
        <Text color='#767F89' mb='24px'>
          Selecione os {t('admin.common.colabs')} sob a gestão de {selectedCollab.name} em{' '}
          {teamName ? teamName : selectTeam.label}
        </Text>

        <Flex direction='column' gridGap='4px' w='100%' mt='24px !important'>
          <Text fontWeight='700' color='#313E4C'>
            Busque {t('admin.common.colabs')}
          </Text>

          <Input
            variant='newFilled'
            placeholder='Busque por um colaborador'
            w='100%'
            height='36px'
            borderRadius='12px'
            data-testid='select-collabs-search-input'
            onChange={debouncedCollabChange}
          />
        </Flex>

        <Box m='16px 0 !important'>
          <Text color='#767F89' fontSize='14px' fontWeight={600} mb='4px'>
            Filtros
          </Text>

          <Select
            value={area}
            bgColor='#F7F9FA'
            border='none'
            _hover={{ bgColor: '#F7F9FA' }}
            _focus={{ bgColor: '#F7F9FA' }}
            color='#009639'
            fontWeight={600}
            onChange={handleAreaChange}
            fontSize='12px'
            height='22px'
            width='100%'
            maxW='132px'
            className='area-select'
          >
            <option value={0}>Todas as áreas</option>
            {areas.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        </Box>

        <UserList area={area} filter={filter} />

        <Flex w='100%' justify='space-between' mt='24px !important'>
          <Button variant='whiteOutline' fontSize='12px' onClick={handleBack}>
            Voltar
          </Button>

          <Button variant='solidGray' fontSize='12px' disabled={selectedCollabs.length === 0} onClick={handleNextStep}>
            Continuar
          </Button>
        </Flex>
      </VStack>
    </>
  )
}

export default SelectCollabs
