import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { User } from 'src/types/User'

interface ManagerToRemoveCardProps {
  user?: User
}

export function ManagerToRemoveCard({ user }: ManagerToRemoveCardProps) {
  const [t] = useTranslation()

  return (
    <Flex
      align='center'
      px='16px'
      h='43px'
      bg='#fff'
      borderRadius='12px'
      boxShadow='0px 1px 6px 0px rgba(0, 0, 0, 0.10)'
    >
      <Text fontWeight='700' color='#313E4C' fontSize='0.75rem' m='0' p='0' ml='8px'>
        {user?.name ?? t('invalidName')}
      </Text>
    </Flex>
  )
}
