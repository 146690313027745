import { Box, Flex, Text } from '@chakra-ui/react'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'

export function ProgressBar() {
  const { numberOfPlaylistModules, numberOfPlaylistModulesDone } = useCustomPlaylist()

  return (
    <Flex marginTop='16px' position='relative' flexDirection='column'>
      <Box w='100%' h='2px' opacity='0.3' backgroundColor='black' />
      <Box
        w={`${(numberOfPlaylistModulesDone! / numberOfPlaylistModules!) * 100}%`}
        h='10px'
        backgroundColor='primaryColors.400'
        position='absolute'
        borderRadius='30px'
        top='-4px'
      />
      <Text color='gray.580' fontWeight='600' alignSelf='flex-end' fontFamily='Poppins' fontSize='12px' marginTop='5px'>
        {numberOfPlaylistModulesDone}/{numberOfPlaylistModules}
      </Text>
    </Flex>
  )
}
