import { ContentRepository } from 'src/modules/cms/repositories/ContentRepository'
import { ContentList } from 'src/types/Cms'
import { ListContentsInput } from '../types/IContentRepository'

type ListImportedContentsUseCaseInput = ListContentsInput

export type ListImportedContentsUseCaseOutput = ContentList

export class ListImportedContentsUseCase {
  constructor(private contentRepository: ContentRepository) {}

  async execute(input: ListImportedContentsUseCaseInput): Promise<ListImportedContentsUseCaseOutput> {
    const params = { ...input }
    const contentList = await this.contentRepository.list(params)
    return contentList
  }
}
