import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { IoChevronForward } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import '../../services/i18n/i18n'

const PathMenu: React.FC = () => {
  const [t] = useTranslation()

  return (
    <Flex
      padding={[
        '12px 0px 12px 22px',
        '12px 0px 12px 22px',
        '40px 0px 0px 44px',
        '40px 0px 0px 44px',
        '40px 0px 0px 44px',
      ]}
      w='100%'
      bg={['#EAF2F5', '#EAF2F5', 'transparent', 'transparent', 'transparent']}
    >
      <Breadcrumb
        spacing={['4px', '6px', '8px', '8px', '8px']}
        fontSize={['x-small', 'sm', 'sm', 'sm', 'sm']}
        separator={<IoChevronForward color='gray.500' />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to='/home'>
            {t('common.Home')}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink fontWeight='extrabold'> {t('common.My Credentials')}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </Flex>
  )
}

export default PathMenu
