import { User } from 'src/types/User'
import { UserRepository } from '../repository/UserRepository'

export interface ListUsersInput {
  limit: number
  cursor?: string
  teamId?: string
  areaId?: number
  name?: string
  permission?: string
  orderByName?: string
}

export type ListUsersOutput = {
  data: User[]
  paginateInfo: {
    count: number
    next: string
    total: number
    hasPreviousPage: boolean
    hasNextPage: boolean
  }
}

export class ListUsersUseCase {
  constructor(private userRepository: UserRepository) {}

  async execute(args: ListUsersInput): Promise<ListUsersOutput> {
    const users = await this.userRepository.list({
      ...args,
      ...(args.orderByName ? { orderByName: args.orderByName === 'NAME_A_Z' ? 'ASC' : 'DESC' } : {}),
    })
    return users
  }
}
