import { gql } from '@apollo/client'

export const GET_CONTENTS = gql`
  query contentList(
    $limit: Int
    $skip: Int
    $title: String
    $order: OrderBy
    $skills: [String]
    $partnerId: [String]
    $status: [ContentStatus]
    $ids: [String!]
    $companies: [Int!]
    $languages: IdWhere
    $type: [ContentType]
  ) {
    contentList(
      limit: $limit
      skip: $skip
      order: $order
      where: {
        title: { contains: $title, mode: insensitive }
        partner_id: { in: $partnerId }
        status: { in: $status }
        type: { in: $type }
        id: { in: $ids }
        skills: { in: $skills }
        languages: $languages
        companies: { in: $companies }
      }
    ) {
      total
      items {
        id
        contentfulId
        title
        type
        description
        duration
        url
        imageUrl
        allowsEmbedding
        language
        languages
        subtitles
        proficiencyLevel {
          name
          color
        }
        partner {
          name
          squareLogoUrl
          logoUrl
          description
          coverColor
          id
        }
        allowedCompanyIds
        skills {
          id
          name
          translations {
            name
            language
          }
        }
        trailerUrl
        status
        updatedAt
        translations {
          content_id
          title
          description
          language
          created_at
          updated_at
          deleted_at
        }
      }
    }
  }
`
