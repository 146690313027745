import { Box, Flex, Image, Progress, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuiz } from 'src/context/QuizContext'
import { EventsImplementation } from 'src/hooks/Mixpanel/types'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'

interface Props {
  onClose: () => void
}

const StepEight: React.FC<Props> = ({ onClose }) => {
  const { track } = useMixpanel()
  const [t] = useTranslation()

  const { onConcluded } = useQuiz()
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    track(EventsImplementation.QUIZ_FINISH_QUIZ())
    onConcluded()
    const interval = setInterval(() => {
      setProgress((prevState) => {
        let newValue = prevState + 20

        if (newValue === 100) {
          clearInterval(interval)
          onClose()
        }

        return newValue
      })
    }, 400)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Flex flex={1} alignItems='center' justifyContent='center'>
        <Flex flexDirection='column' alignItems='center' justifyContent='center' maxW='571px'>
          <Image src='/assets/images/loading-quiz.svg' w='458px' h='395px' />
          <Box w='100%' mt='-70px'>
            <Text
              marginBottom='20px'
              fontWeight='600'
              fontSize='20px'
              fontFamily='Poppins'
              color='gray.800'
              textAlign='center'
              maxW='460px'
            >
              {t('quiz.stepEight.coursesSearch')}
            </Text>
            <Progress colorScheme='green' value={progress} w='100%' height='16px' />
          </Box>
        </Flex>
      </Flex>
    </>
  )
}

export default StepEight
