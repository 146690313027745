import httpClient from 'src/services/http/http-client'
import ApiError, { NetWorkError } from 'src/shared/ApiError'
import { logger } from 'src/shared/Logger'
import { IAM_ERRORS } from '../errors'

export type Props = { email: string }

export const resendVerificationEmailApi = async (props: Props): Promise<void> =>
  httpClient
    .request({
      url: `/v1/iam/resend-confirmation-code?email=${props.email}`,
      method: 'get',
    })
    .then(async () => {
      return
    })
    .catch((error) => {
      if (!error.response) throw new NetWorkError()
      const data = error.response.data
      logger.requestError(data.request_id, data.error, 'failed to resend confirmation code')
      const code: keyof typeof IAM_ERRORS = data?.error?.code
      throw new ApiError(data.error, IAM_ERRORS.hasOwnProperty(code) ? IAM_ERRORS[code] : IAM_ERRORS.INTERNAL_ERROR)
    })
