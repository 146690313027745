import { CloseIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, Heading, Text, useToast } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Skill, useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { useWindowSize } from 'src/hooks/useWindowSize'

type Props = {
  handleStepChange: (step: number) => void
}

const SkillsConfirmation: React.FC<Props> = ({ handleStepChange }) => {
  const [t] = useTranslation()
  const { EventsImplementation, track } = useMixpanel()
  const toast = useToast()
  const { width } = useWindowSize()

  const isMobile = useMemo(() => width && width < 768, [width])

  const [loadingPlaylistEdition, setLoadingPlaylistEdition] = useState(false)

  const {
    updateQuiz,
    userSkills,
    quizValues,
    skillsToAddOnEdit,
    skillsToRemoveOnEdit,
    setSkillsToAddOnEdit,
    setSkillsToRemoveOnEdit,
    editSkills,
    allSkills,
    getAllCmsSkills,
  } = useCustomPlaylistQuiz()
  const { skillsSnapshot } = quizValues

  useEffect(() => {
    if ([...skillsToAddOnEdit, ...skillsToRemoveOnEdit].length === 0) {
      updateQuiz('skillsSnapshot', userSkills)
      handleStepChange(0)
    }
  }, [skillsToAddOnEdit, skillsToRemoveOnEdit, handleStepChange, userSkills, updateQuiz])

  const handleContinueButton = async () => {
    setLoadingPlaylistEdition(true)

    const skillsAdded = skillsToAddOnEdit.map((item) => item.name).toString()
    const skillsRemoved = skillsToRemoveOnEdit.map((item) => item.name).toString()

    track(EventsImplementation.PLAYLIST_EDIT_SKILLS_CONFIRMATION(skillsRemoved, skillsAdded))

    const newSkills = skillsSnapshot
      .map((item) => ({
        ...item,
        ...skillsToAddOnEdit.find((skillToAdd) => skillToAdd.id === item.id),
      }))
      .map((item, index) => ({ skillId: item.id, context: item.context, order: index }))

    try {
      const response = await editSkills(newSkills)

      if (response.data.errors.length > 0) {
        const titles = response.data.errors.map((error: any) => {
          const matchingItem = allSkills.find((item) => error.skillId === item.id)
          return matchingItem ? matchingItem.name : null
        })

        toast({
          title: `Não foram encontrados conteúdos para as habilidades: ${titles.join(', ')}.`,
          isClosable: true,
          duration: 5000,
          status: 'warning',
        })

        return
      }

      handleStepChange(4)
    } catch (err) {
      console.log('Ocorreu um erro ao atualizar as habilidades da playlist.', err)
      toast({
        title: 'Não foram encontrados conteúdos para as habilidades selecionadas.',
        isClosable: true,
        duration: 5000,
        status: 'warning',
      })
    } finally {
      setLoadingPlaylistEdition(false)
    }
  }

  const handleStepBack = () => {
    if (skillsToRemoveOnEdit.length > 0 && skillsToAddOnEdit.length === 0) {
      handleStepChange(0)
    } else {
      handleStepChange(1)
    }
  }

  const handleRemoveSkill = (itemToRemove: Skill) => {
    const filteredSkills = skillsToAddOnEdit.filter((skill) => skill.id !== itemToRemove.id)
    setSkillsToAddOnEdit(filteredSkills)
  }

  const handleKeepSkill = (itemToKeep: Skill) => {
    const filteredSkills = skillsToRemoveOnEdit.filter((skill) => skill.id !== itemToKeep.id)
    setSkillsToRemoveOnEdit(filteredSkills)
  }

  useEffect(() => {
    getAllCmsSkills({ variables: { limit: 500, skip: 0 } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box paddingBottom='12px'>
      <Heading size='xs' fontWeight='600' marginBottom='21px' color='bluishGray.500'>
        {t('home.Custom Playlist.Skill edit confirmation')}
      </Heading>

      {skillsToAddOnEdit.length !== 0 && (
        <Flex direction='column'>
          <Heading size='xs' fontWeight='600' marginBottom='8px'>
            {t('home.Custom Playlist.You are adding')}
          </Heading>
          <Text fontWeight='600' fontSize='16px' color='bluishGray.500'>
            {t('home.Custom Playlist.When adding a new skill we will create a custom content')}
          </Text>

          <Flex direction='column' mt='16px' gap='16px'>
            {skillsToAddOnEdit.map((item) => {
              return (
                <Flex
                  alignItems='center'
                  justifyContent='space-between'
                  key={item.id}
                  w='100%'
                  h='48px'
                  p='8px 24px'
                  bg={'primaryColors.30'}
                  borderRadius='16px'
                  border='2px solid transparent'
                  _hover={{ border: '2px solid #009639' }}
                >
                  <Text fontWeight={700} fontSize={{ base: '.875rem', md: '1.125rem' }}>
                    {item.name}
                  </Text>
                  <Text
                    cursor='pointer'
                    fontWeight={600}
                    fontSize='12px'
                    fontFamily='Poppins'
                    onClick={() => handleRemoveSkill(item)}
                    _active={{ color: 'primaryColors.650' }}
                  >
                    {isMobile ? (
                      <CloseIcon w={{ base: '10px', md: '14px' }} />
                    ) : (
                      <>{t('home.Custom Playlist.Remove skill')}</>
                    )}
                  </Text>
                </Flex>
              )
            })}
          </Flex>
        </Flex>
      )}

      {skillsToRemoveOnEdit.length !== 0 && (
        <Flex direction='column' mt='24px'>
          <Heading size='xs' fontWeight='600' marginBottom='8px'>
            {t('home.Custom Playlist.You are removing')}
          </Heading>

          <Text fontWeight='600' fontSize='16px' color='bluishGray.500'>
            {t('home.Custom Playlist.Removing a skill deletes all content')}
          </Text>

          <Flex direction='column' mt='16px' gap='16px'>
            {skillsToRemoveOnEdit.map((item) => {
              return (
                <Flex
                  alignItems='center'
                  justifyContent='space-between'
                  key={item.id}
                  w='100%'
                  h='48px'
                  p='8px 24px'
                  bg={'primaryColors.30'}
                  borderRadius='16px'
                  border='2px solid transparent'
                  _hover={{ border: '2px solid #009639' }}
                >
                  <Text fontWeight={700} fontSize='18px'>
                    {item.name}
                  </Text>
                  <Text
                    cursor='pointer'
                    fontWeight={600}
                    fontSize='12px'
                    fontFamily='Poppins'
                    onClick={() => handleKeepSkill(item)}
                    _active={{ color: 'primaryColors.650' }}
                  >
                    {t('home.Custom Playlist.Keep skill')}
                  </Text>
                </Flex>
              )
            })}
          </Flex>
        </Flex>
      )}

      <Flex justify='space-between' mt='32px' flexDir={{ base: 'column', md: 'row' }} gap='12px'>
        <Button
          variant='startCourse'
          color='bluishGray.500'
          border='2px solid #EFEFEF'
          height='44px'
          onClick={handleStepBack}
        >
          {t('quiz.footer.back')}
        </Button>
        <Button
          variant='startCourseDark'
          height='44px'
          padding='12px 24px'
          disabled={loadingPlaylistEdition}
          isLoading={loadingPlaylistEdition}
          onClick={handleContinueButton}
          data-testid='edit-skills-save-changes'
        >
          {t('home.Custom Playlist.Save changes')}
        </Button>
      </Flex>
    </Box>
  )
}

export default SkillsConfirmation
