import { Box, Flex } from '@chakra-ui/layout'
import { Icon, Image, Stack, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoChevronBack, IoChevronForward, IoPersonOutline } from 'react-icons/io5'
import { MdOutlineQuiz } from 'react-icons/md'
import { useLocation } from 'react-router-dom'
import { useFeedback } from 'src/context/FeedbackContext'
import { useUser } from 'src/context/userContext'
import Partner from 'src/modules/cms/domain/Partner'
import { usePartners } from 'src/modules/cms/hooks/usePartners'
import '../../services/i18n/i18n'
import {
  DashboardIcon,
  ExpandIcon,
  FeedbackHorizontalIcon,
  HeartIcon,
  HelpIcon,
  MyCredentialsIcon,
  RetractIcon,
} from '../../theme/Icons'
import MenuLink from './MenuLink'
import PartnerLink from './PartnerLink'
import PlaylistLink from './PlaylistLink'
import SideMenuList, { Item } from './SideMenuList'

interface Props {
  isMobile?: boolean
  onCloseMobile?: () => void
}

const Sidemenu: React.FC<Props> = ({ isMobile = false, onCloseMobile = () => null }: Props) => {
  const { partnersList } = usePartners()
  const [isFixedMenuOpen, setIsFixedMenuOpen] = useState(false)
  const [isHoverMenuOpen, setIsHoverMenuOpen] = useState(false)
  const [isPartnersOpen, setIsPartnersOpen] = useState(false)
  const [isPlaylistsOpen, setIsPlaylistsOpen] = useState(false)

  const location = useLocation()
  const { user, platformVersion } = useUser()
  const [t] = useTranslation()
  const { openFeedback } = useFeedback()

  const isPartnerActive = location.pathname.indexOf('partner') !== -1
  const isPlaylistActive = location.pathname.indexOf('playlist') !== -1

  const canUserAnswerQuiz = user && user?.hasCustomPlaylistAssigned

  const isMenuOpen = isHoverMenuOpen || isFixedMenuOpen

  const renderPlatformVersion = () => {
    if (!platformVersion) return <></>

    const platformVersionTextElement = (
      <Text color='bluishGray.300' fontSize='8px'>
        {platformVersion}
      </Text>
    )

    if (isMobile) {
      return (
        <Flex align='center' pl='25px' pointerEvents='none' pb='8px'>
          {platformVersionTextElement}
        </Flex>
      )
    }

    return (
      <Flex
        pos='absolute'
        right='0'
        left={isMenuOpen ? '25px' : '0'}
        bottom='8px'
        align='center'
        justify={isMenuOpen ? 'flex-start' : 'center'}
        pointerEvents='none'
      >
        {platformVersionTextElement}
      </Flex>
    )
  }

  if (isMobile) {
    return (
      <Flex
        className='side-menu'
        height='100%'
        flexDirection='column'
        position='relative'
        display={{ base: 'flex', lg: 'none' }}
      >
        <Flex marginLeft='17px' gap='17px' alignItems='center' marginBottom='40px'>
          <Image src='../../assets/images/ej-logo-reduced.svg' w='45px' />
          <Text fontFamily='Poppins' fontSize='14px' fontWeight={600}>
            Education Journey
          </Text>
        </Flex>

        {/** TOP PART - Links */}
        <Flex
          width='100%'
          height='100%'
          flexDirection='column'
          justifyContent='space-between'
          overflowY='auto'
          paddingBottom='32px'
          css={{
            '&::-webkit-scrollbar': {
              width: '0px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
          }}
        >
          <Stack>
            <MenuLink
              href='/home'
              icon={DashboardIcon}
              isMenuOpen={true}
              label={t('common.Home')}
              onClick={onCloseMobile}
            >
              <Text fontFamily='Poppins'>{t('common.Home')}</Text>
            </MenuLink>

            <PlaylistLink
              isMenuOpen={true}
              isOpen={isPlaylistsOpen}
              active={isPlaylistActive}
              onClick={() => {
                setIsPlaylistsOpen((current) => !current)
              }}
            />

            {isPlaylistsOpen ? (
              <Box paddingLeft='40px'>
                <SideMenuList
                  items={[
                    {
                      title: t('playlists.all'),
                      url: '/playlists',
                      image: '/assets/images/all-playlists.svg',
                    },
                    user?.hasCompletedCustomPlaylistQuiz ?
                      {
                        title: t('customPlaylist'),
                        url: '/customPlaylist',
                        image: '/assets/images/custom-playlist.svg',
                      } : null,
                    {
                      title: t('playlists.private'),
                      url: '/privatePlaylists',
                      image: '/assets/images/custom-playlist.svg',
                    },
                  ].filter(Boolean) as Item[]}
                  onClick={onCloseMobile}
                />
              </Box>
            ) : null}

            <PartnerLink
              isMenuOpen={true}
              isPartnersOpen={isPartnersOpen}
              userPartners={partnersList}
              active={isPartnerActive}
              onClick={() => setIsPartnersOpen(!isPartnersOpen)}
            />

            {isPartnersOpen ? (
              <Box paddingLeft='40px'>
                <SideMenuList
                  onClick={onCloseMobile}
                  items={partnersList?.map((partner: Partner) => {
                    return {
                      title: partner.name,
                      url: `/partner/${partner.slug}/${partner.id}`,
                      image: partner.squareLogoUrl,
                    }
                  })}
                />
              </Box>
            ) : null}

            <MenuLink
              href='/favorites'
              icon={HeartIcon}
              isMenuOpen={true}
              label={t('common.Favorites')}
              onClick={onCloseMobile}
            >
              <Text fontFamily='Poppins'>{t('common.Favorites')}</Text>
            </MenuLink>

            <MenuLink
              href='/myCredentials'
              icon={MyCredentialsIcon}
              isMenuOpen={true}
              label={t('common.My Credentials')}
              onClick={onCloseMobile}
            >
              <Text fontFamily='Poppins'>{t('common.My Credentials')}</Text>
            </MenuLink>
            <MenuLink
              href='/account'
              icon={IoPersonOutline}
              isMenuOpen={true}
              label={t('common.Account')}
              onClick={onCloseMobile}
            >
              <Text fontFamily='Poppins'>{t('common.Account')}</Text>
            </MenuLink>
            <MenuLink
              onClick={() => openFeedback({ step: 0 })}
              icon={FeedbackHorizontalIcon}
              isExternal={false}
              isMenuOpen={false}
              label='Feedback'
            >
              <Text fontFamily='Poppins'>Feedback</Text>
            </MenuLink>
            {canUserAnswerQuiz && (
              <MenuLink
                href='/customPlaylistQuiz'
                icon={MdOutlineQuiz}
                isMenuOpen={isMenuOpen}
                label={`${t('createYourPlaylist')}`}
              >
                <Text fontFamily='Poppins'>{t('createYourPlaylist')}</Text>
              </MenuLink>
            )}
            <MenuLink
              href='https://educationjourney.zendesk.com'
              icon={HelpIcon}
              isExternal={true}
              isMenuOpen={true}
              label={t('common.Help')}
            >
              <Text fontFamily='Poppins'>{t('common.Help')}</Text>
            </MenuLink>
          </Stack>
        </Flex>

        {renderPlatformVersion()}
      </Flex>
    )
  }

  return (
    <>
      {isHoverMenuOpen && <Box minW='78px' h='100%' display={{ base: 'none', lg: 'flex' }} />}

      <Flex
        className='side-menu'
        background='gray.30'
        height='100%'
        flexDirection='column'
        paddingTop='30px'
        display={{ base: 'none', lg: 'flex' }}
        position={isHoverMenuOpen ? 'absolute' : 'relative'}
        paddingRight={isMenuOpen ? '17px' : 'unset'}
        zIndex='6'
        filter={isHoverMenuOpen ? 'drop-shadow(0px 5.43953px 100.631px rgba(0, 0, 0, 0.35))' : ''}
        onMouseLeave={() => {
          setIsHoverMenuOpen(false)
        }}
      >
        {!isMenuOpen ? (
          <Flex
            position='absolute'
            top='0'
            left='0'
            w='78px'
            h='110px'
            onMouseEnter={() => {
              setIsHoverMenuOpen(true)
            }}
          />
        ) : null}
        <Flex marginBottom='40px' marginLeft='16px' gap='17px' alignItems='center'>
          <Image src='../../assets/images/ej-logo-reduced.svg' w='45px' />
          {isMenuOpen && (
            <Text fontFamily='Poppins' fontSize='14px' fontWeight={600}>
              Education Journey
            </Text>
          )}
        </Flex>

        <Flex
          variant='unstyled'
          position='absolute'
          top='40px'
          right='-14px'
          w='25px'
          h='25px'
          background='white'
          border='1px solid #E5E5E5'
          borderRadius='8px'
          display='flex'
          alignItems='center'
          justifyContent='center'
          cursor='pointer'
          _focus={{ outline: 'none' }}
          onClick={() => {
            setIsHoverMenuOpen(false)
            setIsFixedMenuOpen((current) => !current)
          }}
        >
          <Icon w='12px' h='12px' as={isFixedMenuOpen ? IoChevronBack : IoChevronForward} />
        </Flex>

        {/** TOP PART - Links */}
        <Flex
          width='100%'
          height='100%'
          flexDirection='column'
          justifyContent='space-between'
          overflowY='auto'
          css={{
            '&::-webkit-scrollbar': {
              width: '0px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
          }}
        >
          <Stack>
            <MenuLink href='/home' icon={DashboardIcon} isMenuOpen={isMenuOpen} label={t('common.Home')}>
              {isMenuOpen ? <Text fontFamily='Poppins'>{t('common.Home')}</Text> : null}
            </MenuLink>

            <PlaylistLink
              isMenuOpen={isMenuOpen}
              isOpen={isPlaylistsOpen}
              active={isPlaylistActive}
              onClick={() => setIsPlaylistsOpen((current) => !current)}
            />

            {isPlaylistsOpen && isMenuOpen ? (
              <Box paddingLeft='40px'>
                <SideMenuList
                  items={[
                    {
                      title: t('playlists.all'),
                      url: '/playlists',
                      image: '/assets/images/all-playlists.svg',
                    },
                    user?.hasCompletedCustomPlaylistQuiz ?
                      {
                        title: t('customPlaylist'),
                        url: '/customPlaylist',
                        image: '/assets/images/custom-playlist.svg',
                      } : null,
                    {
                      title: t('playlists.private'),
                      url: '/privatePlaylists',
                      image: '/assets/images/custom-playlist.svg',
                    },
                  ].filter(Boolean) as Item[]}
                />
              </Box>
            ) : null}

            <PartnerLink
              isMenuOpen={isMenuOpen}
              isPartnersOpen={isPartnersOpen}
              userPartners={partnersList}
              active={isPartnerActive}
              onClick={() => setIsPartnersOpen(!isPartnersOpen)}
            />

            {isPartnersOpen && isMenuOpen ? (
              <Box paddingLeft='40px'>
                <SideMenuList
                  items={partnersList?.map((partner: Partner) => {
                    return {
                      title: partner.name,
                      url: `/partner/${partner.slug}/${partner.id}`,
                      image: partner.squareLogoUrl,
                    }
                  })}
                />
              </Box>
            ) : null}

            <MenuLink href='/favorites' icon={HeartIcon} isMenuOpen={isMenuOpen} label={t('common.Favorites')}>
              {isMenuOpen ? <Text fontFamily='Poppins'>{t('common.Favorites')}</Text> : null}
            </MenuLink>

            <MenuLink
              href='/myCredentials'
              icon={MyCredentialsIcon}
              isMenuOpen={isMenuOpen}
              label={t('common.My Credentials')}
            >
              {isMenuOpen ? <Text fontFamily='Poppins'>{t('common.My Credentials')}</Text> : null}
            </MenuLink>
            {canUserAnswerQuiz && (
              <Box data-testid='menu-create-playlist-quiz'>
                <MenuLink
                  href='/customPlaylistQuiz'
                  icon={MdOutlineQuiz}
                  isMenuOpen={isMenuOpen}
                  label={t('createYourPlaylist')}
                >
                  <Text fontFamily='Poppins'>{isMenuOpen ? t('createYourPlaylist') : null}</Text>
                </MenuLink>
              </Box>
            )}
            <MenuLink
              href='https://educationjourney.zendesk.com'
              icon={HelpIcon}
              isExternal={true}
              isMenuOpen={isMenuOpen}
              label={t('common.Help')}
            >
              {isMenuOpen ? <Text fontFamily='Poppins'>{t('common.Help')}</Text> : null}
            </MenuLink>
          </Stack>

          {isMenuOpen ? (
            <Flex marginBottom='48px' marginTop='8px' w='100%'>
              <MenuLink
                onClick={() => {
                  setIsHoverMenuOpen(false)
                  setIsFixedMenuOpen(!isFixedMenuOpen)
                }}
                icon={isFixedMenuOpen ? RetractIcon : ExpandIcon}
                isMenuOpen={isMenuOpen}
              >
                <Flex justifyContent='center' alignItems='center'>
                  <Text fontFamily='Poppins'>
                    {isFixedMenuOpen ? `${t('common.Retract')}` : `${t('common.Expand')}`}
                  </Text>
                </Flex>
              </MenuLink>
            </Flex>
          ) : null}

          {!isMenuOpen ? (
            <Flex
              flex='1'
              onMouseEnter={() => {
                setIsHoverMenuOpen(true)
              }}
            />
          ) : null}
        </Flex>
        {renderPlatformVersion()}
      </Flex>
    </>
  )
}

export default Sidemenu
