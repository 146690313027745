import { Flex, HStack, IconButton, Select, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { IoChevronBack, IoChevronForward } from 'react-icons/io5'
import { useHomeAccess } from 'src/context-admin/Home/AdminHomeAccessContext'

const PaginationFooter = () => {
  const [t] = useTranslation()
  const { listUsers, first, startCursors, handleChangeFirst, getPreviousPage, getNextPage } = useHomeAccess()

  const isBackButtonDisabled = !listUsers?.paginateInfo.hasPreviousPage
  const isNextButtonDisabled = !listUsers?.paginateInfo.hasNextPage

  return (
    <Flex justifyContent='space-between' mt='24px !important'>
      <Flex w='120px' bg='#FFFFFF' boxShadow='0 4px 20px rgba(0,0,0,.1)' borderRadius='4px'>
        <Select onChange={handleChangeFirst} width='120px' border='none'>
          <option value={25} selected={first === 25}>
            {t('admin.home.employeeSection.view')} 25
          </option>
          <option value={50} selected={first === 50}>
            {t('admin.home.employeeSection.view')} 50
          </option>
          <option value={100} selected={first === 100}>
            {t('admin.home.employeeSection.view')} 100
          </option>
          <option value={200} selected={first === 200}>
            {t('admin.home.employeeSection.view')} 200
          </option>
          <option value={300} selected={first === 300}>
            {t('admin.home.employeeSection.view')} 300
          </option>
        </Select>
      </Flex>
      <HStack>
        <IconButton
          icon={<IoChevronBack />}
          aria-label='Voltar'
          disabled={isBackButtonDisabled}
          onClick={getPreviousPage}
          fontSize='xl'
          variant='ghost'
          borderRadius='100%'
        />
        <Text>
          {(startCursors.length + 1) * first - first + 1} -{' '}
          {(startCursors.length + 1) * first - first + (listUsers?.data.length || 0)} de {listUsers?.paginateInfo.total}
        </Text>
        <IconButton
          icon={<IoChevronForward />}
          aria-label='Prosseguir'
          disabled={isNextButtonDisabled}
          onClick={getNextPage}
          background='transparent'
          fontSize='xl'
          variant='ghost'
          borderRadius='100%'
        />
      </HStack>
    </Flex>
  )
}

export default PaginationFooter
