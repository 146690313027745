import { getAccessToken } from 'src/modules/iam/apis/store'
import httpClient from 'src/services/http/http-client'
import { TeamRepository } from '../repository/TeamRepository'
import { ListTeamsUseCase } from '../use-case/ListTeamsUseCase'

export function makeListTeams() {
  const token = getAccessToken()

  const repository = new TeamRepository(token, httpClient)
  return new ListTeamsUseCase(repository)
}
