import { Box, Flex, Input, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Skill, useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import ButtonsFooter from '../ButtonsFooter'
import StepHeader from '../StepHeader'

const StepFour = () => {
  const [t] = useTranslation()
  const { EventsImplementation, track } = useMixpanel()
  const { quizValues, updateStep, handleStepBack, updateQuiz } = useCustomPlaylistQuiz()
  const [skills, setSkills] = useState<Skill[]>(quizValues.skills)

  const handleBackButton = () => {
    if (quizValues.skills.length === 1) {
      updateStep(2)
    } else {
      handleStepBack()
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, item: Skill) => {
    const formattedSkills = skills.map((skill) => {
      if (skill.id === item.id) {
        return {
          ...skill,
          context: e.target.value,
        }
      }

      return skill
    })

    setSkills(formattedSkills)
  }

  const handleNextButton = () => {
    skills.forEach((skill) => {
      track(
        EventsImplementation.PERSONALIZED_PLAYLIST_SKILL_CONTEXT(skill.name, skill?.context ?? 'Contexto inexistente'),
      )
    })

    updateQuiz('skills', skills)
  }

  const handleUpdateStep = () => {
    if (skills.some((item) => !item.context)) {
      setSkills((prevState) => prevState.map((item) => verifySkillError(item)))
      return
    } else {
      updateStep(5)
    }
  }

  const verifySkillError = (item: Skill) => {
    if (!item.context) {
      return {
        ...item,
        hasError: true,
      }
    }

    return item
  }

  let isDisabled = false

  return (
    <Flex h='100%' direction='column'>
      <Box>
        <StepHeader
          step={4}
          title={t('customPlaylistQuiz.stepFour.title')}
          description={t('customPlaylistQuiz.stepFour.description')}
        />

        <Flex direction='column' gap='24px' mt='24px' data-testid='custom-playlist-quiz-skills-context'>
          {skills.map((item, index) => {
            if (!item.context) isDisabled = true

            return (
              <Flex key={item.id} direction='column' gap='4px'>
                <Text fontSize='18px' fontWeight={700}>
                  {item.name}
                </Text>
                <Input
                  // @ts-ignore
                  value={item.context}
                  placeholder={t('customPlaylistQuiz.stepFour.placeholder', { skill: item.name })}
                  onChange={(e) => handleChange(e, item)}
                  _placeholder={{
                    color: 'bluishGray.500',
                  }}
                  data-testid={`custom-playlist-skill-input-context-${index}`}
                />
              </Flex>
            )
          })}
        </Flex>
      </Box>
      <ButtonsFooter
        backButtonCallback={handleBackButton}
        nextButtonCallback={handleNextButton}
        updateStep={handleUpdateStep}
        disabledButton={isDisabled}
      />
    </Flex>
  )
}

export default StepFour
