import { Flex, Icon, Text, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsExclamationCircle } from 'react-icons/bs'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { makeAddNewUsersToArea } from 'src/modules/dashboard/factory/makeAddNewUsersToArea'
import { User } from 'src/types/User'
import { NavigationButtons } from '../../commonComponents/NavigationButtons'
import { StepTitle } from '../../commonComponents/StepTitle'
import { StepTransition } from '../../commonComponents/StepTransition'
import { UserList } from '../../commonComponents/UserList'

export function EditExistingAreaConfirmAddUsers() {
  const [t] = useTranslation()
  const [loading, setLoading] = useState(false)
  const { area, navigateToStep, handleSetAreaValues, handleFinalMessage } = useHandleArea()

  const addNewUsersToArea = makeAddNewUsersToArea()
  const toast = useToast()

  const handleRemoveSelectedUsersFromArea = (users: User[]) => {
    const usersIds = users?.map((u) => u?.id)
    handleSetAreaValues({
      newUsers: area?.newUsers?.filter((u) => !usersIds?.includes(u?.id)) ?? [],
    })
  }

  const handleSubmitNewUsers = async () => {
    setLoading(true)
    try {
      const usersWasAdded = await addNewUsersToArea.execute({
        areaId: Number(area?.id) ?? -1,
        usersIds: area?.newUsers?.map((u) => Number(u.id)).filter((id) => !isNaN(id)) ?? [],
        usersEmails: area?.newUsers?.map((item) => item.email) ?? [],
      })
      if (!usersWasAdded) {
        throw new Error(
          `Ocorreu um erro ao adicionar os usuários na área "${area?.name ?? ''}". Por favor, tente novamente!`,
        )
      }
      handleFinalMessage(t('newAreaMembersAdded', { areaName: area?.name }))
      navigateToStep('success-screen')
    } catch (err: any) {
      toast({
        title: t('errorAddingNewUsers'),
        description: String(err?.message),
        status: 'error',
        isClosable: true,
        duration: 7000,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <StepTransition>
      <Flex flexDir='column' gap='16px'>
        <StepTitle
          title={t('youAreAddingTo', { areaName: area?.name ?? t('invalidArea') })}
          subtitle={t('checkAndConfirmNewAreaEmployees', { areaName: area?.name ?? t('invalidArea') })}
        />
        {!area?.newUsers ||
          (area?.newUsers?.length <= 0 && (
            <Flex
              gap='16px'
              align='center'
              borderRadius='12px'
              borderWidth='2px'
              borderColor='#efefef'
              px='16px'
              py='4px'
            >
              <Icon as={BsExclamationCircle} w='16px' color='bluishGray.600' />
              <Text fontWeight='600' fontSize='14px' color='bluishGray.600'>
                {t('mustSelectAtLeastOneUserToProceed')}
              </Text>
            </Flex>
          ))}
        <UserList
          data={area?.newUsers ?? []}
          selectText={t('cancel')}
          selectedText={t('canceled')}
          withFilter={false}
          withSearch={false}
          onSelectUsers={handleRemoveSelectedUsersFromArea}
        />
      </Flex>
      <NavigationButtons
        isContinueDisabled={!area?.newUsers || area?.newUsers?.length <= 0}
        isLoading={loading}
        onContinueClick={() => {
          handleSubmitNewUsers()
        }}
        onBackClick={() => {
          navigateToStep('edit-existing-area--add-users')
        }}
      />
    </StepTransition>
  )
}
