import { Button, Flex, Image, Skeleton, Text, useDisclosure } from '@chakra-ui/react'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TablePagination } from 'src/components-admin/TablePagination'
import { debounce } from 'src/helpers-admin/debounce'
import { durationConverter } from 'src/helpers/duration'
import Content from 'src/modules/cms/domain/Content'
import { AddContentDrawer } from 'src/pages-admin/Cms/components/AddContentDrawer'
import { AvailabilityFilter } from 'src/pages-admin/Cms/components/AvailabilityFilter'
import { OrderFilter } from 'src/pages-admin/Cms/components/OrderFilter'
import { SearchContent } from 'src/pages-admin/Cms/components/SearchContent'
import { SkillsFilter } from 'src/pages-admin/Cms/components/SkillsFilter'
import { useCorporateUniversityStore } from 'src/pages-admin/Cms/hooks/useCorporateUniversityStore'
import useListContents from 'src/pages-admin/Cms/hooks/useListContents'
import { useRegisterPlaylistStore } from 'src/pages-admin/Cms/hooks/useRegisterPlaylistStore'
import { parseCourseType } from 'src/pages-admin/Cms/utils/parseCourseType'
import { CmsContentType } from 'src/types/Cms'
import { getReadableContentDuration } from '../../utils/getReadableContentDuration'

export function SelectContentsStep() {
  const [t] = useTranslation()
  const { refetch, listContents, isListContentsLoading } = useListContents()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { cmsCourses, totalCourses, applyFilters } = useCorporateUniversityStore()
  const { selectedContents, incrementSelectedContents, updateSelectedContents } = useRegisterPlaylistStore()

  const handleSearch = useCallback(
    (value: string) => {
      refetch({
        title: value,
      })
    },
    [refetch],
  )

  const searchDebounce = debounce(handleSearch, 500)

  const isContentSelected = (item: Content) => {
    return selectedContents.some((content) => content.id === item.id)
  }

  const handleSelectContent = (item: Content) => {
    if (!isContentSelected(item)) {
      incrementSelectedContents(item)

      return
    }

    const newContentList = selectedContents.filter((content) => content.id !== item.id)
    updateSelectedContents(newContentList)
  }

  const renderContent = (item: Content) => (
    <Flex key={item.id} h='150px' borderRadius='24px' border='2px solid #F2F2F2' gap='16px'>
      <Image
        src={item?.imageUrl ?? 'https://i.ibb.co/fS8fCDS/dummy-300x300-009136-ffffff-education-journey.png'}
        alt={`Imagem do conteúdo: ${item.title}`}
        w='150px'
        borderLeftRadius='24px'
      />

      <Flex flexDir='column' gap='12px' justify='center' w='100%' py='8px'>
        <Flex gap='8px'>
          <Flex
            w='fit-content'
            alignItems='center'
            padding={{ base: '2px 6px', md: '2px 12px' }}
            bg='#EAF2F5'
            borderRadius='100px'
            marginRight='8px'
            textTransform='initial'
          >
            <Text textTransform='capitalize' color='#767F89' fontSize='12px' fontWeight={600}>
              {parseCourseType(item.type as CmsContentType)}
            </Text>
          </Flex>
          <Flex
            w='fit-content'
            alignItems='center'
            padding={{ base: '2px 6px', md: '2px 12px' }}
            bg='#EAF2F5'
            borderRadius='100px'
            marginRight='8px'
          >
            <Text textTransform='capitalize' color='#767F89' fontSize='12px' fontWeight={600}>
              {durationConverter(item.duration ?? 0)}
            </Text>
          </Flex>
        </Flex>
        <Text fontWeight={700} fontSize='1rem' noOfLines={2}>
          {item.title}
        </Text>
        <Button
          w='140px'
          variant='unstyled'
          bg={isContentSelected(item) ? 'primaryColors.650' : 'gray.800'}
          color='white'
          fontSize='.75rem'
          padding='6px 12px'
          onClick={() => handleSelectContent(item)}
        >
          {isContentSelected(item) ? 'Selecionado' : `Selecionar ${parseCourseType(item.type as CmsContentType)}`}
        </Button>
      </Flex>
    </Flex>
  )

  useEffect(() => {
    applyFilters('companies', [])
    listContents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Flex
        alignItems='center'
        justify='space-between'
        w='100%'
        h='auto'
        bg='gray.55'
        border='2px solid #F7F9FA'
        padding='12px 24px'
        borderRadius='16px'
        mt='24px'
      >
        <Text color='#313E4C' fontSize='1.125rem' fontWeight={600} fontFamily='Poppins'>
          {t('registerExclusiveContent')}
        </Text>
        <Button variant='unstyled' bg='gray.800' color='white' fontSize='.75rem' padding='6px 12px' onClick={onOpen}>
          {t('admin.corporateUniversity.contents.registerNewContent')}
        </Button>
      </Flex>

      <Flex w='100%' mt='16px'>
        <SearchContent
          title='Busca'
          placeholder={t('admin.corporateUniversity.contents.searchForContent')}
          searchContent={searchDebounce}
        />
      </Flex>

      <Flex gap='6px' mt='16px' data-testid='cms-filters-wrapper' alignItems='center'>
        <OrderFilter />
        <SkillsFilter />
        <AvailabilityFilter />
      </Flex>

      <Flex flexDir='column' my='16px' gap='4px' overflowY='hidden' h='100%'>
        <Text fontSize='0.875rem' color='bluishGray.500'>
          {t('selectedContents')} ({selectedContents.length}) / {t('duration')} (
          {getReadableContentDuration(selectedContents)})
        </Text>

        <Flex flexDir='column' gap='16px' overflowY='auto' h='100%' mb='8px'>
          {isListContentsLoading ? (
            <Flex flexDir='column' gap='16px' h='100%'>
              <Skeleton w='100%' h='150px' borderRadius='16px' />
              <Skeleton w='100%' h='150px' borderRadius='16px' />
            </Flex>
          ) : (
            <Flex flexDir='column' gap='16px' h='100%'>
              {cmsCourses.filter((item) => isContentSelected(item)).map((item: Content) => renderContent(item))}
              {cmsCourses.filter((item) => !isContentSelected(item)).map((item: Content) => renderContent(item))}
            </Flex>
          )}
        </Flex>
        <Flex w='100%' mt='auto'>
          <TablePagination contentLength={totalCourses} />
        </Flex>
      </Flex>

      {isOpen && <AddContentDrawer isOpen={isOpen} onClose={onClose} />}
    </>
  )
}
