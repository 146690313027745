import { Flex, Icon, Text } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoChevronDown } from 'react-icons/io5'
import { checkIsAbilitySelected } from 'src/components/QuizModal/utils'
import Ability from '../Ability'
import Option from '../Option'

interface AbilitiesSelectProps {
  maxItems: number
  setValidState?: React.Dispatch<React.SetStateAction<boolean>>
  options?: string[] | { category: string; subcategoriesSlugs: string[] }[]
  shouldReduceSelectedItems?: boolean
  removeMaxItemsText?: boolean
  shouldAlignOptionsTo?: 'left' | 'center' | 'right'
  activeAbilities: string[]
  onChange: (abilities: string[]) => void
  useCategoryLabel?: boolean
  minHeight?: string
  maxWidth?: string
}

const AbilitiesSelect: React.FC<AbilitiesSelectProps> = ({
  maxItems,
  options = [],
  shouldReduceSelectedItems,
  shouldAlignOptionsTo = 'center',
  activeAbilities,
  onChange,
  setValidState,
  useCategoryLabel = false,
  removeMaxItemsText = false,
  minHeight,
  maxWidth,
}) => {
  const [t] = useTranslation()
  const [isDropdownOpen, setDropdownState] = useState(false)
  const select = useRef(null)
  const text = useRef(null)
  const allAbilitiesWhereSelected = activeAbilities.length === maxItems

  useEffect(() => {
    allAbilitiesWhereSelected && setDropdownState(false)
  }, [activeAbilities, options, allAbilitiesWhereSelected])

  useEffect(() => {
    setValidState && setValidState(activeAbilities.length >= 1)
  }, [allAbilitiesWhereSelected, options, activeAbilities, setValidState])

  const handleDropdownState = (event: React.MouseEvent) => {
    if (event.target === select.current || event.target === text.current) {
      setDropdownState(!isDropdownOpen)
    }
  }

  const handleAbilityClick = (name: string) => {
    if (!checkIsAbilitySelected(name, activeAbilities)) {
      if (allAbilitiesWhereSelected) return
      return onChange([...activeAbilities, name])
    }
    const newActiveAbilities = activeAbilities.filter((abilityFilter: string) => name !== abilityFilter)
    onChange(newActiveAbilities)
  }

  const ActiveAbilities = (
    <Flex w='auto' maxW={'90%'} justifyContent={'flex-start'} paddingBottom='9px'>
      {activeAbilities.map((ability: string, index: number) => {
        if (shouldReduceSelectedItems) {
          return (
            <Ability
              key={index}
              name={ability.split('-')[0]}
              active={true}
              activationHandler={() => handleAbilityClick(ability)}
            />
          )
        } else {
          // @ts-ignore
          const name = t(`quiz.themes.${ability}`)
          return (
            <Ability
              key={ability}
              name={name as string}
              active={true}
              activationHandler={() => handleAbilityClick(ability)}
            />
          )
        }
      })}
    </Flex>
  )

  return (
    <Flex flexDir={'column'} flex={1} mb='25px'>
      {!removeMaxItemsText && (
        <Text fontWeight='600' fontSize='16px' color='bluishGray.500' mb='16px' mt='8px'>
          Você pode selecionar até {maxItems} opções
        </Text>
      )}
      <Flex maxW={maxWidth || '891px'} flexDir={'column'} flex={1}>
        <Flex
          w='100%'
          h='64px'
          borderRadius={'15px'}
          border={'2px solid'}
          borderColor='gray.70'
          paddingY='20px'
          paddingX='16px'
          userSelect='none'
          cursor={'pointer'}
          alignItems='center'
          justifyContent='space-between'
          onClick={handleDropdownState}
          ref={select}
        >
          {activeAbilities.length > 0 ? (
            ActiveAbilities
          ) : (
            <Text ref={text} color='gray.550'>
              Selecione até {maxItems} opções
            </Text>
          )}
          <Icon
            as={IoChevronDown}
            onClick={() => setDropdownState(!isDropdownOpen)}
            transform={isDropdownOpen ? 'rotate(180deg)' : 'none'}
          />
        </Flex>
        <Flex
          display={isDropdownOpen ? 'flex' : 'none'}
          flex='1 1 0'
          border='2px solid #E5E5E5'
          borderRadius='15px  0 0 15px'
          overflowY='auto'
          boxShadow='0px 1px 6px rgba(0, 0, 0, 0.1);'
          scrollSnapType='y proximity'
          paddingX='10px'
          paddingBottom='25px'
          flexWrap='wrap'
          alignContent='flex-start'
          justifyContent={shouldAlignOptionsTo}
          mt='8px'
          minHeight={minHeight}
          sx={{
            '&::-webkit-scrollbar': {
              width: '5px',
              borderRadius: '15px',
              backgroundColor: 'gray.60',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'gray.800',
              height: '20px',
              borderRadius: '100px',
            },
            '&::-webkit-track': {
              h: '90%',
            },
          }}
        >
          {!useCategoryLabel &&
            options.map(
              (option: any, index: number) =>
                !checkIsAbilitySelected(option, activeAbilities) && (
                  <Option
                    name={option as string}
                    boldReduce={true}
                    index={index}
                    key={index}
                    activationHandler={() => {
                      handleAbilityClick(option)
                    }}
                  />
                ),
            )}

          {useCategoryLabel &&
            options.map((option: any, index: number) => {
              return (
                <>
                  <Text
                    fontWeight='800'
                    scrollSnapAlign={'start'}
                    paddingLeft='8px'
                    paddingTop='12px'
                    userSelect='none'
                    fontSize='18px'
                    w='100%'
                    my='10px'
                    textAlign={'left'}
                    key={index}
                  >
                    {option.category}
                  </Text>
                  {option.subcategoriesSlugs.map((item: any, index: number) => {
                    if (!checkIsAbilitySelected(item, activeAbilities)) {
                      // @ts-ignore
                      const name = t(`quiz.themes.${item}`)
                      return (
                        <Option
                          name={name as string}
                          index={index}
                          key={index + item}
                          activationHandler={() => {
                            handleAbilityClick(item)
                          }}
                        />
                      )
                    } else {
                      return null
                    }
                  })}
                </>
              )
            })}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AbilitiesSelect
