import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FormikProps } from 'formik'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PlaylistContentsDrawer } from 'src/pages-admin/Cms/components/PlaylistContentsDrawer'
import { ContentItem } from 'src/pages-admin/Cms/components/PlaylistContentsDrawer/ContentItem'
import { useRegisterPlaylistStore } from 'src/pages-admin/Cms/hooks/useRegisterPlaylistStore'
interface PlaylistContentAccordionProps {
  form: FormikProps<any>
}

export function PlaylistContentAccordion({ form }: PlaylistContentAccordionProps) {
  const [t] = useTranslation()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { savedSelectedContents, updateIsEditingSelectedContents, updateContentsDrawerStep, updateSelectedContents } =
    useRegisterPlaylistStore()

  const isFormValuesFilled = useMemo(
    () =>
      savedSelectedContents.length === 0 &&
      form.values?.modules?.length > 0 &&
      form.values?.modules?.[0]?.contents?.length > 0,
    [savedSelectedContents, form.values],
  )

  const updateSelectedContentsWithCorrectValues = () => {
    if (isFormValuesFilled) {
      updateSelectedContents(form.values?.modules?.[0]?.contents ?? [])
    } else {
      updateSelectedContents(savedSelectedContents)
    }
  }

  const openSelectContentsStep = () => {
    updateIsEditingSelectedContents(true)
    updateSelectedContentsWithCorrectValues()
    updateContentsDrawerStep(0)
    onOpen()
  }

  const openReorderStep = () => {
    updateIsEditingSelectedContents(true)
    updateSelectedContentsWithCorrectValues()
    updateContentsDrawerStep(1)
    onOpen()
  }

  return (
    <>
      <AccordionItem border='none' borderBottom='1px solid #767F89'>
        <AccordionButton p='32px 0' _focus={{ boxShadow: 'none' }} _hover={{ background: 'white' }}>
          <Flex w='100%' align='center' justify='space-between' pr='8px'>
            <Text color='#313E4C' fontSize='1.25rem' fontWeight={600} fontFamily='Poppins'>
              {t('playlistContent')}
            </Text>
          </Flex>
          <AccordionIcon w='1.2em' h='1.2em' color='#161616' />
        </AccordionButton>

        <AccordionPanel p='0 0 16px 0'>
          {!isFormValuesFilled && savedSelectedContents.length === 0 ? (
            <Flex
              flexDir='column'
              bgColor='#EAF2F5'
              p='24px'
              borderRadius='16px'
              border='2px solid'
              borderColor={savedSelectedContents.length !== 0 ? '#009639' : 'transparent'}
              cursor='pointer'
              onClick={onOpen}
            >
              <Text
                mb='4px'
                fontSize='1.125rem'
                color={savedSelectedContents.length !== 0 ? '#009639' : 'gray.800'}
                fontWeight={700}
              >
                {t('addContentsThatWillBePartOfThisPlaylist')}
              </Text>
              <Text fontSize='1.125rem'>{t('chooseTheContentsThatMakeSenseForThisPlaylist')}</Text>
              <Button
                variant='unstyled'
                w='200px'
                bg='gray.800'
                color='white'
                fontSize='.75rem'
                padding='6px 12px'
                mt='16px'
                onClick={onOpen}
              >
                {t('addContentToPlaylist')}
              </Button>
            </Flex>
          ) : (
            <Box>
              <Flex flexDir='column'>
                {isFormValuesFilled &&
                  form.values?.modules?.[0]?.contents?.map((item: any) => <ContentItem content={item} key={item.id} />)}

                {!isFormValuesFilled &&
                  savedSelectedContents.length !== 0 &&
                  savedSelectedContents.map((item) => <ContentItem content={item} key={item.id} />)}
              </Flex>

              <Flex align='center' gap='16px' mt='4px'>
                <Button
                  variant='unstyled'
                  w='200px'
                  bg='gray.800'
                  color='white'
                  fontSize='.75rem'
                  padding='6px 12px'
                  onClick={openSelectContentsStep}
                >
                  {t('editPlaylistContent')}
                </Button>
                {(isFormValuesFilled || savedSelectedContents.length > 1) && (
                  <Button
                    variant='outline'
                    onClick={openReorderStep}
                    w='157px'
                    fontSize='.75rem'
                    data-testid='cms-remove-drawer-confirm-button'
                  >
                    {t('reorderContents')}
                  </Button>
                )}
              </Flex>
            </Box>
          )}
        </AccordionPanel>
      </AccordionItem>

      {isOpen ? <PlaylistContentsDrawer isOpen={isOpen} onClose={onClose} /> : null}
    </>
  )
}
