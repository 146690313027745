import { ComponentStyleConfig } from '@chakra-ui/theme'

const ButtonStyle: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    borderRadius: '16px',
    fontFamily: 'Poppins',
    colorScheme: 'primaryColors',
    _disabled: {
      backgroundColor: '#3F3D561F',
      color: 'rgba(63, 61, 86, 0.44)',
    },
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    solid: {
      bg: 'primaryColor',
      _hover: {
        backgroundColor: 'primaryColors.600',
      },
      _active: {
        backgroundColor: 'primaryColors.800',
      },
      _focus: {
        backgroundColor: 'primaryColors.800',
      },
      color: 'white',
    },
    yellow: {
      bg: 'secondaryColors.400',
      _hover: {
        backgroundColor: 'secondaryColors.600',
      },
      _active: {
        backgroundColor: 'secondaryColors.400',
      },
      _focus: {
        backgroundColor: 'secondaryColors.400',
      },
      color: 'black',
      height: '60px',
      width: '417px',
    },
    outlined: {
      bg: 'transparent',
      _hover: {
        backgroundColor: 'gray.50',
      },
      _active: {
        backgroundColor: 'gray.50',
      },
      _focus: {
        backgroundColor: 'gray.50',
      },
      color: 'black',

      border: '2.5px solid black',
      fontSize: '18px',
      fontWeight: '600',
      borderRadius: '25px',
    },
    startCourse: {
      backgroundColor: 'white',
      backgroundPosition: 'center',
      transition: 'background 0.8s',

      _hover: {
        background:
          'rgba(255, 255, 255, 0.9) radial-gradient(circle, transparent 1%, rgba(255, 255, 255, 0.9) 1%) center/15000%',
      },
      _focus: {
        outline: 'none',
        boxShadow: 'unset',
      },
      _active: {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backgroundSize: '100%',
        transition: 'background 0s',
      },
      _disabled: {
        backgroundColor: 'gray.300',
        color: 'white',
      },
    },
    startCourseDark: {
      backgroundColor: 'gray.800',
      backgroundPosition: 'center',
      transition: 'background 0.8s',
      padding: '24px 17px',
      color: 'white',
      fontSize: '16px',
      _hover: {
        background: '#3c4855 radial-gradient(circle, transparent 1%, #3c4855 1%) center/15000%',
        _disabled: {
          backgroundColor: 'gray.300',
        },
      },
      _focus: {
        outline: 'none',
        boxShadow: 'unset',
      },
      _active: {
        backgroundColor: '#485768',
        backgroundSize: '100%',
        transition: 'background 0s',
      },
      _disabled: {
        backgroundColor: 'gray.300',
        color: 'white',
      },
    },
    exploreButton: {
      borderRadius: '16px',
      backgroundColor: 'gray.800',
      backgroundPosition: 'center',
      transition: 'background 0.8s',
      height: '48px',
      padding: '10px 65px',
      color: 'white',
      _hover: {
        background: '#3c4855 radial-gradient(circle, transparent 1%, #3c4855 1%) center/15000%',
        _disabled: {
          backgroundColor: 'gray.300',
        },
      },
      _focus: {
        outline: 'none',
        boxShadow: 'unset',
      },
      _active: {
        backgroundColor: '#485768',
        backgroundSize: '100%',
        transition: 'background 0s',
      },
      _disabled: {
        backgroundColor: 'gray.300',
        color: 'white',
      },
    },
    access: {
      borderRadius: '10px',
      backgroundColor: 'gray.800',
      backgroundPosition: 'center',
      transition: 'background 0.8s',
      padding: '18px 32px ',
      color: 'white',
      fontWeight: '500',
      h: '32px',
      _hover: {
        background: '#3c4855 radial-gradient(circle, transparent 1%, #3c4855 1%) center/15000%',
      },
      _focus: {
        outline: 'none',
        boxShadow: 'unset',
      },
      _active: {
        backgroundColor: '#485768',
        backgroundSize: '100%',
        transition: 'background 0s',
      },
    },
    courseCard: {
      borderRadius: '16px',
      backgroundColor: 'gray.800',
      backgroundPosition: 'center',
      transition: 'background 0.8s',
      padding: '24px 17px',
      _hover: {
        background: '#27323D radial-gradient(circle, transparent 1%, rgba(255, 255, 255, 0.9) 1%) center/15000%',
      },
      _focus: {
        outline: 'none',
        boxShadow: 'unset',
      },
      _active: {
        backgroundColor: '#27323D',
        transition: 'background 0s',
      },
    },
    lastCourseCard: {
      borderRadius: '16px',
      backgroundColor: 'gray.800',
      backgroundPosition: 'center',
      h: '48px',
      transition: 'background 0.8s',
      padding: '20px 18px 20px 8px',
      _hover: {
        background: '#27323D radial-gradient(circle, transparent 1%, rgba(255, 255, 255, 0.9) 1%) center/15000%',
      },
      _focus: {
        outline: 'none',
        boxShadow: 'unset',
      },
      _active: {
        backgroundColor: '#27323D',
        transition: 'background 0s',
      },
    },
    loginDark: {
      borderRadius: '16px',
      backgroundColor: 'gray.800',
      color: 'white',
      backgroundPosition: 'center',
      w: ['100%', '100%', '100%', '100%', '100%'],
      height: '48px',
      transition: 'background 0.8s',
      padding: '20px 18px',
      fontSize: '16px',
      _hover: {
        background: '#27323D radial-gradient(circle, transparent 1%, rgba(255, 255, 255, 0.9) 1%) center/15000%',
      },
      _focus: {
        outline: 'none',
        boxShadow: 'unset',
      },
      _active: {
        backgroundColor: '#27323D',
        transition: 'background 0s',
      },
    },
    icon: {
      backgroundColor: 'rgba(49, 62, 76, 0.7)',
      backgroundPosition: 'center',
      transition: 'background 0.6s',
      border: '2px solid rgba(229, 229, 229, 0.6)',
      padding: '12px',
      borderRadius: '16px',
      w: '48px',
      h: '48px',
      color: 'white',
      _hover: {
        background: '#313E4C radial-gradient(circle, transparent 1%, #313E4C 1%) center/15000%',
        border: '2px solid #E5E5E5',
      },
      _focus: {
        outline: 'none',
        boxShadow: 'unset',
      },
      _active: {
        backgroundColor: '#384756',
        backgroundSize: '100%',
        transition: 'background 0s',
      },
    },
    iconButtonClear: {
      border: '2px solid #E5E5E5',
      borderRadius: '15px',
      w: '48px',
      h: '48px',
      padding: '5px',
      backgroundColor: 'gray.10',
      cursor: 'pointer',
      _focus: {
        outline: 'none',
        boxShadow: 'unset',
      },
    },
    iconDisabled: {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      backgroundPosition: 'center',
      transition: 'background 0.6s',
      border: '2px solid rgba(229, 229, 229, 0.6)',
      padding: '12px',
      borderRadius: '16px',
      w: '48px',
      h: '48px',
      color: 'rgba(229, 229, 229, 0.6)',
      _hover: {
        background:
          'rgba(255, 255, 255, 0.1) radial-gradient(circle, transparent 1%, rgba(255, 255, 255, 0.1) 1%) center/15000%',
        border: '2px solid #E5E5E5',
        color: 'white',
      },
      _focus: {
        outline: 'none',
        boxShadow: 'unset',
      },
      _active: {
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        backgroundSize: '100%',
        transition: 'background 0s',
      },
    },
    closeButton: {
      backgroundColor: '#000',
      backgroundPosition: 'center',
      borderRadius: '300px',
      w: '47px',
      h: '47px',
      position: 'absolute',
      top: '25px',
      right: '25px',
      transition: 'background 0.8s',
      color: 'white',
      _hover: {
        background: '#262626 radial-gradient(circle, transparent 1%, #262626 1%) center/15000%',
      },
      _focus: {
        outline: 'none',
        boxShadow: 'unset',
      },
      _active: {
        backgroundColor: '#7E7E7E',
        transition: 'background 0s',
      },
    },
    menuLink: {
      backgroundColor: 'transparent',
      backgroundPosition: 'center',
      transition: 'background 0.6s',
      padding: '12px',
      borderRadius: '16px',
      w: '45px',
      h: '45px',
      color: 'rgba(229, 229, 229, 0.6)',
      _hover: {
        background: '#E1E3E4 radial-gradient(circle, transparent 1%, #E1E3E4 1%) center/15000%',
      },
      _focus: {
        outline: 'none',
        boxShadow: 'unset',
      },
      _active: {
        backgroundColor: 'gray.60',
        backgroundSize: '100%',
        transition: 'background 0s',
      },
    },
    whiteOutline: {
      height: '36px',
      backgroundColor: 'white',
      backgroundPosition: 'center',
      transition: 'background 0.8s',
      border: '2px solid #EFEFEF',
      borderRadius: '12px',
      _hover: {
        background:
          'rgba(255, 255, 255, 0.9) radial-gradient(circle, transparent 1%, rgba(255, 255, 255, 0.9) 1%) center/15000%',
      },
      _focus: {
        outline: 'none',
        boxShadow: 'unset',
      },
      _active: {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backgroundSize: '100%',
        transition: 'background 0s',
      },
    },
    solidGray: {
      height: '36px',
      backgroundColor: 'gray.800',
      backgroundPosition: 'center',
      transition: 'background 0.8s',
      padding: '4px 12px',
      color: 'white',
      fontSize: '16px',
      borderRadius: '12px',
      _hover: {
        background: '#3c4855 radial-gradient(circle, transparent 1%, #3c4855 1%) center/15000%',
        _disabled: {
          backgroundColor: 'gray.300',
        },
      },
      _focus: {
        outline: 'none',
        boxShadow: 'unset',
      },
      _active: {
        backgroundColor: '#485768',
        backgroundSize: '100%',
        transition: 'background 0s',
      },
      _disabled: {
        backgroundColor: 'gray.300',
        color: 'white',
      },
    },
  },
}

export default ButtonStyle
