import { gql } from '@apollo/client'

export const GET_PLAYLISTS = gql`
  query playlistList(
    $id: [String]
    $type: [PlaylistType]
    $limit: Int
    $skip: Int
    $title: String
    $order: OrderBy
    $skill: [String]
    $status: [ContentStatus]
    $partnerId: [String]
    $companies: [Int]
  ) {
    playlistList(
      where: {
        id: { in: $id }
        title: { contains: $title, mode: insensitive }
        status: { in: $status }
        type: { in: $type }
      }
      limit: $limit
      skip: $skip
      order: $order
      skills: { in: $skill }
      partners: { in: $partnerId }
      companies: { in: $companies }
    ) {
      total
      items {
        id
        type
        title
        status
        allowedCompanyIds
        description
        color
        imageUrl
        cardImageUrl
        certificateImageUrl
        certificateTemplateUrl
        learningPointList {
          total
          items {
            id
            title
            description
            iconUrl
          }
        }
        moduleList {
          total
          items {
            id
            title
            order
            contentList {
              total
              items {
                id
                contentfulId
                type
                order
                title
                description
                duration
                url
                imageUrl
                trailerUrl
                allowsEmbedding
                language
                status
                updatedAt
                proficiencyLevel {
                  name
                  color
                }
                partner {
                  name
                  squareLogoUrl
                  logoUrl
                  description
                  coverColor
                  id
                  slug
                }
                skills {
                  id
                  name
                }
                translations {
                  content_id
                  title
                  description
                  language
                  created_at
                  updated_at
                  deleted_at
                }
              }
            }
          }
        }
        translations {
          playlist_id
          language
          title
          description
          created_at
          updated_at
        }
      }
    }
  }
`
