import { Stack, Text, Link } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  query: string
  notFound: boolean
  autoCorrected: string
}

const SearchHeader: React.FC<Props> = ({ query, notFound, autoCorrected }) => {
  const [t] = useTranslation()

  return (
    <Stack spacing={2}>
      <Text color='gray.800' fontSize='24px' fontFamily='Poppins' fontWeight='regular' marginRight='-10px'>
        {t('search.Results for')} <strong>{query}</strong>
      </Text>
      
      {notFound && autoCorrected !== query && (
        <Text>
          {t('search.Did you mean')}
          <Text fontWeight='extrabold' color='primaryColor' display='inline'>
            <Link to={`/search/${autoCorrected}`}>{autoCorrected}</Link>
          </Text>
          ?
        </Text>
      )}
    </Stack>
  )
}

export default SearchHeader