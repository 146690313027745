import { Button, ButtonProps, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface NavigationButtonsProps extends ButtonProps {
  onContinueClick: () => void
  onBackClick?: () => void
  continueLabel?: string
  showBackButton?: boolean
  isContinueDisabled?: boolean
}

/**
 * Renderiza os botões de navegação do drawer de gerenciamento de área. Todas as propriedades herdadas do "ButtonProps",
 * do ChakraUI, são direcionadas ao botão "Continuar".
 * @returns Dois botões, "Voltar" e "Continuar".
 */
export function NavigationButtons({
  showBackButton = true,
  onContinueClick,
  onBackClick,
  continueLabel,
  isContinueDisabled = false,
  ...rest
}: NavigationButtonsProps) {
  const [t] = useTranslation()

  const onContinueClickWithTimeout = () => {
    setTimeout(() => {
      onContinueClick()
    }, 150)
  }

  const onBackClickWithTimeout = () => {
    if (!onBackClick) return
    setTimeout(() => {
      onBackClick()
    }, 150)
  }

  return (
    <Flex mt='46px' align='center' justify='space-between'>
      {showBackButton && (
        <Button
          variant='whiteOutline'
          fontFamily='Poppins'
          fontSize='0.75rem'
          px='12px'
          py='4px'
          borderWidth='2px'
          borderColor='#EFEFEF'
          onClick={onBackClickWithTimeout}
          isDisabled={rest.isLoading}
        >
          {t('back')}
        </Button>
      )}
      <Button
        variant='startCourseDark'
        m='0'
        ml={showBackButton ? '0' : 'auto'}
        fontSize='0.75rem'
        fontFamily='Poppins'
        py='4px'
        px='12px'
        onClick={onContinueClickWithTimeout}
        isDisabled={isContinueDisabled}
        {...rest}
      >
        {continueLabel ?? t('continue')}
      </Button>
    </Flex>
  )
}
