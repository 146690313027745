import { Flex } from '@chakra-ui/layout'
import { useHistory, useParams } from 'react-router'
import { OnboardProvider } from '../../context-admin/useOnboard'
import Steps from './Steps'
import { getComponentFromStepSlug, Step } from './utils'

interface Params {
  stepSlug: Step['slug']
}

const Onboard: React.FC = () => {
  const location = useHistory()
  const { stepSlug } = useParams<Params>()
  const StepComponent = getComponentFromStepSlug(stepSlug)

  // Validate Slug
  if (!StepComponent) {
    location.push('/login')
    return null
  }

  return (
    <OnboardProvider>
      <Flex flexDirection='column'>
        <Steps currentStepSlug={stepSlug} />
        <Flex background='gray.50' justifyContent='center' paddingY='10' minHeight='100vh'>
          <StepComponent />
        </Flex>
      </Flex>
    </OnboardProvider>
  )
}

export default Onboard
