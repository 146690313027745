import { Flex, Image, Text } from '@chakra-ui/react'
import { CertificateImageFile } from '../../tabs/Certificate'

interface FileFormPreviewProps {
  fileAndError: CertificateImageFile
  isDisabled?: boolean
  previewLabel?: string
}

/**
 * Render a box to preview the selected file.
 * @param fileAndError A React State that handle file and error.
 * @param isDisabled If the input file should be disabled. Optional
 * @param previewLabel The label to be displayed when no file is selected. Optional
 * @example <FileFormPreview fileAndError={fileAndError} isDisabled={boolean} />
 */
export function FileFormPreview({ fileAndError, isDisabled, previewLabel = '120 x 40px' }: FileFormPreviewProps) {
  const renderFilePreviewOrText = () => {
    if (!fileAndError.file)
      return (
        <>
          <Text color='#767f89' fontSize='1.25rem' fontWeight='400'>
            Tamanho recomendado
          </Text>
          <Text color='#767f89' fontSize='1.25rem' fontWeight='400'>
            {previewLabel}
          </Text>
        </>
      )

    const url = typeof fileAndError.file !== 'string' ? URL.createObjectURL(fileAndError.file) : fileAndError.file
    const fileName = typeof fileAndError.file !== 'string' ? fileAndError.file.name : 'Image Preview'
    return (
      <Image
        data-testid='cms-certificate--preview-image'
        maxW='100%'
        maxH='100%'
        objectFit='contain'
        src={url}
        alt={fileName}
      />
    )
  }

  return (
    <Flex
      flexDir='column'
      align='center'
      justify='center'
      h='112px'
      borderWidth='2px'
      borderColor='#767F89'
      borderRadius='8px'
      bg='#F7F9FA'
      overflow='hidden'
      pointerEvents={isDisabled ? 'none' : 'auto'}
      opacity={isDisabled ? 0.4 : 1}
    >
      {renderFilePreviewOrText()}
    </Flex>
  )
}
