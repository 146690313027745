import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { OptionTypeBase } from 'react-select'
import { makeDeactivateCompanyUseCase } from '../../use_cases/DeactivateCompanyUseCase'

const CONFIRMATION_TEXT = 'Quero desativar essa empresa'

interface ConfirmModalProps {
  selectedCompany: OptionTypeBase | null
  onClose: () => void
  isOpen: boolean
}

export function ConfirmModal({ selectedCompany, isOpen, onClose }: ConfirmModalProps) {
  const [inputValue, setInputValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  const handleConfirmDesactivation = async () => {
    if (!selectedCompany) return
    setIsLoading(true)
    try {
      const deactivateCompany = makeDeactivateCompanyUseCase()
      const success = await deactivateCompany.execute({ companyId: selectedCompany?.value as string })
      if (success) {
        onClose()
        toast({
          title: 'Empresa desativada com sucesso',
          description: 'A empresa e seus usuários foram desativados com sucesso.',
          status: 'success',
          duration: 7000,
          isClosable: true,
        })
      } else {
        toast({
          title: 'Erro ao desativar a empresa',
          description: 'Ocorreu um erro ao desativar a empresa. Tente novamente.',
          status: 'error',
          duration: 7000,
          isClosable: true,
        })
      }
    } catch {
      toast({
        title: 'Erro ao desativar a empresa',
        description: 'Ocorreu um erro ao desativar a empresa. Tente novamente.',
        status: 'error',
        duration: 7000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW='520px'>
        <ModalHeader>Confirme a ação</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Você está prestes a desativar a empresa <strong>{selectedCompany?.label}</strong>. <br />A empresa{' '}
            <strong>{selectedCompany?.label}</strong> e todos os usuários seus não-admin serão{' '}
            <strong>desativados</strong>.
          </Text>
          <Text color='red.500' fontWeight='700' my='24px'>
            Essa ação é irreversível.
          </Text>
          <Text>
            Se você tem certeza que deseja continuar com a desativação, digite o seguinte texto no campo abaixo:
          </Text>
          <Flex flexDir='column' mt='16px' gap='4px'>
            <Text fontWeight='700' color='gray.800'>
              {CONFIRMATION_TEXT}
            </Text>
            <Input onChange={(e) => setInputValue(e.target.value)} />
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button
            variant='startCourseDark'
            py='0px'
            px='16px'
            height='40px'
            fontSize='0.875rem'
            mr={3}
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            variant='ghost'
            _hover={{ color: 'red.500' }}
            onClick={handleConfirmDesactivation}
            isLoading={isLoading}
            isDisabled={!inputValue || inputValue.toLowerCase().trim() !== CONFIRMATION_TEXT.toLowerCase() || !selectedCompany}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
