import { CloseIcon } from '@chakra-ui/icons'
import { Flex } from '@chakra-ui/layout'
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react'
import _ from 'lodash'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoClose, IoSearch } from 'react-icons/io5'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { ISkillTree, useExploreContext } from 'src/context/exploreContext'
import { useUser } from 'src/context/userContext'
import { renderBrowser } from 'src/helpers/renderBrowser'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { BurguerIcon, ChevronDown, SearchIcon } from '../../theme/Icons'
import Explore from '../Explore'
import Sidemenu from '../Sidemenu'
import ProfileMenu from './ProfileMenu'

const Topmenu = () => {
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false)
  const [exploreVisibility, setExploreVisibility] = useState(false)
  const [showSearchInputOnMobile, setShowSearchInputOnMobile] = useState(false)

  const history = useHistory()
  const { skillTreeData, setSkillTreeData } = useExploreContext()
  const { user, role } = useUser()
  const [t] = useTranslation()
  const location = useLocation()
  const { EventsImplementation, track } = useMixpanel()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isMobileDevice] = useMediaQuery('(max-width: 992px)')
  const mobileMenuButtonRef = useRef(null)
  const browser = renderBrowser()

  const hasCustomPlaylist = useMemo(() => !!user?.customPlaylistId, [user?.customPlaylistId])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const s = e.target.value
    if (s.length <= 0) return
    history.push(`/search/${s}`)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(_.debounce(handleSearchChange, 1000), [])

  const handleExplore = () => {
    if (!location.pathname.includes(skillTreeData.skillId || skillTreeData.subCategoryId) && exploreVisibility) {
      setSkillTreeData({} as ISkillTree)
    }

    setExploreVisibility((prev) => !prev)
    track(EventsImplementation.OPEN_EXPLORE(location.pathname))
  }

  // Mobile
  if (isMobileDevice) {
    return (
      <Box width='100%' boxShadow='0px 4px 4px rgba(0, 0, 0, 0.1);' marginBottom='6px' zIndex={3} bg='white'>
        <Flex padding={{ base: '12px 24px', sm: '14px 32px' }} alignItems='center' justifyContent='space-between'>
          {showSearchInputOnMobile ? (
            <Flex w='100%' align='center' justify='space-between' gap='14px'>
              <Input
                variant='search'
                display='flex'
                placeholder={`${t('common.search')}`}
                onChange={debouncedChangeHandler}
              />
              <Flex align='center' justify='center' onClick={() => setShowSearchInputOnMobile(false)}>
                <CloseIcon width='12px' />
              </Flex>
            </Flex>
          ) : (
            <>
              <Flex align='center' gap='14px'>
                <Link to='/home'>
                  <Image src='../../assets/images/ej-logo-reduced.svg' w='45px' />
                </Link>
              </Flex>

              <Flex gap='17px' alignItems='center'>
                <Icon
                  as={SearchIcon}
                  w='28px'
                  h='28px'
                  color='gray.800'
                  cursor='pointer'
                  marginX='12px'
                  onClick={() => setShowSearchInputOnMobile(true)}
                />
                <ProfileMenu />
                <Button variant='link' onClick={onOpen}>
                  <Icon as={BurguerIcon} color='black' cursor='pointer' width='32px' />
                </Button>
              </Flex>
            </>
          )}
        </Flex>
        <Drawer isOpen={isOpen} placement='right' onClose={onClose} finalFocusRef={mobileMenuButtonRef}>
          <DrawerOverlay />
          <DrawerContent maxWidth='260px'>
            <DrawerCloseButton zIndex={2} />
            <DrawerBody padding='32px 0 0 0'>
              <Sidemenu isMobile onCloseMobile={onClose} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    )
  }

  // Desktop

  return (
    <Box
      position='sticky'
      background='white'
      zIndex='5'
      top='-16px'
      _before={{
        content: "''",
        position: 'sticky',
        height: '16px',
        top: '64px',
        boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.08)',
        display: 'block',
      }}
      _after={{
        content: "''",
        position: 'sticky',
        height: '16px',
        background:
          'linear-gradient(white 10%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.4) 70%, transparent)',
        top: '0',
        zIndex: '3',
        display: 'block',
      }}
    >
      <Flex
        background='white'
        width='100%'
        top='0'
        position='sticky'
        justifyContent={['space-between', 'space-between', 'center', 'center', 'center']}
        padding='16px 72px'
        marginTop='-16px'
        alignItems='center'
      >
        <Flex justifyContent='space-between' flex={1} width='min-content' alignItems='center'>
          {!mobileSearchOpen && (
            <>
              {user?.company?.logoUrl && <Image src={user?.company?.logoUrl} height='40px' mr='48px' />}
              {role === 'COLAB' ? (
                <Flex
                  width={['350px', '250px', '350px', '350px', '350px']}
                  display={['none', 'flex', 'flex', 'flex', 'flex']}
                  flex={1}
                  marginRight='95px'
                >
                  <Flex alignItems='center' flex={1}>
                    <InputGroup>
                      <InputLeftElement pointerEvents='all' h='100%' w='120px'>
                        <Button variant='exploreButton' height='48px' onClick={handleExplore}>
                          <Text marginRight='10px'>{t('common.explore')}</Text>
                          <Icon as={ChevronDown} color='white' cursor='pointer' />
                        </Button>
                      </InputLeftElement>
                      <Input
                        variant='search'
                        size='sm'
                        display='flex'
                        paddingLeft='145px'
                        placeholder={`${t('common.search')}`}
                        onChange={debouncedChangeHandler}
                      />
                      <InputRightElement
                        pointerEvents='none'
                        h='100%'
                        children={
                          <Icon
                            as={SearchIcon}
                            w='28px'
                            h='28px'
                            color='gray.800'
                            marginLeft='9px'
                            cursor='pointer'
                            marginRight='12px'
                          />
                        }
                      />
                    </InputGroup>
                  </Flex>
                </Flex>
              ) : (
                <Flex flex={1} />
              )}
            </>
          )}
          {/** RIGHT SIDE - Profile */}
          <Flex>
            {!mobileSearchOpen && (
              <>
                {role === 'COLAB' && browser === 'Supported' && (
                  <Flex alignItems='center' id='extensionInstallButton' marginRight={6}>
                    <Link to='/extension'>
                      <Text
                        fontWeight='500'
                        fontFamily='Poppins'
                        fontSize={{ base: '0.75rem', sm: '0.875rem', md: '1rem' }}
                      >
                        {t('common.downloadExtension')}
                      </Text>
                    </Link>
                  </Flex>
                )}

                {role === 'COLAB' && hasCustomPlaylist && (
                  <Link to='/customPlaylist'>
                    <Button variant='startCourseDark'>
                      <Text>{t('customPlaylist')}</Text>
                    </Button>
                  </Link>
                )}

                <Box marginLeft='62px'>
                  <ProfileMenu />
                </Box>
              </>
            )}
          </Flex>
        </Flex>

        {/** Mobile Search */}
        {!mobileSearchOpen && (
          <IconButton
            icon={<IoSearch />}
            aria-label='Search'
            bg='transparent'
            color='gray.260'
            fontSize='2xl'
            variant='ghost'
            display={['flex', 'none', 'none', 'none', 'none']}
            onClick={() => setMobileSearchOpen(true)}
          ></IconButton>
        )}

        {mobileSearchOpen && (
          <InputGroup width='100%'>
            <InputLeftElement pointerEvents='none' h='100%' children={<IoSearch color='#AAAAAA' />} />
            <Input
              size='md'
              display='flex'
              boxShadow='0 4px 20px rgba(0,0,0,0.05)'
              placeholder='Pesquisar...'
              width='100%'
              onChange={debouncedChangeHandler}
            />
            <InputRightElement children={<IoClose color='#666' />} onClick={() => setMobileSearchOpen(false)} />
          </InputGroup>
        )}
      </Flex>
      {exploreVisibility && <Explore visibilityHandler={setExploreVisibility} />}
    </Box>
  )
}

export default Topmenu
