import { Button, Center, Flex, Icon, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { IoCheckmarkCircleOutline, IoChevronForward } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import Card from '../../../components-admin/onboard/Card'
import Header from '../../../components-admin/onboard/Header'
// import '../../../i18n'
import { getNextStep, useAuthOnboard } from '../utils'

const HomepageHR: React.FC = () => {
  const [t] = useTranslation()
  const history = useHistory()
  const nextStep = getNextStep('home')
  useAuthOnboard({ stepSlug: 'home' })

  const redirect = () => {
    history.push(`/onboard/${nextStep!.slug}`)
  }

  return (
    <Card>
      <Helmet>
        <title>{t('onboard.steps.Home')} | Education Journey</title>
      </Helmet>
      <Header caption={t('onboard.home.header.Caption')} />
      <Text
        as='h1'
        fontSize='4xl'
        marginTop='-10%'
        fontFamily='Poppins'
        marginBottom='4%'
        color='gray.800'
        align='center'
        fontWeight='black'
      >
        {t('onboard.home.header.Title')}
      </Text>
      <Text>{t('onboard.home.header.Description')}</Text>
      <Image margin='34px auto' src={'/assets/images/homepage-hr-img.svg'} />

      <Flex margin='20px auto' color='white'>
        <Center w='100px' color='gray.800'>
          <Icon fontSize='4xl' as={IoCheckmarkCircleOutline} />
        </Center>
        <Center w='100px' color='gray.800' width='500px'>
          <Text fontFamily='Mulish'>{t('onboard.home.steps.First Step')}</Text>
        </Center>
      </Flex>
      <Flex margin='20px auto' color='white'>
        <Center w='100px' color='gray.800'>
          <Icon fontSize='4xl' as={IoCheckmarkCircleOutline} />
        </Center>
        <Center w='100px' color='gray.800' width='500px'>
          <Text fontFamily='Mulish'>{t('onboard.home.steps.Second Step')}</Text>
        </Center>
      </Flex>
      <Flex margin='20px auto' color='white'>
        <Center w='100px' color='gray.800'>
          <Icon fontSize='4xl' as={IoCheckmarkCircleOutline} />
        </Center>
        <Center w='100px' color='gray.800' width='500px'>
          <Text fontFamily='Mulish'>{t('onboard.home.steps.Third Step')}</Text>
        </Center>
      </Flex>
      <Button
        w='100%'
        rightIcon={<IoChevronForward />}
        bg='quaternaryColor'
        colorScheme='primaryColors'
        marginTop={6}
        size='lg'
        onClick={redirect}
        fontFamily='Poppins'
      >
        {t('onboard.home.Continue')}
      </Button>
    </Card>
  )
}

export default HomepageHR
