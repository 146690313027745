import Content from 'src/modules/cms/domain/Content'
import { create } from 'zustand'

interface RegisterPlaylistStore {
  isRegisterPlaylistModalOpen: boolean
  updateRegisterPlaylistModalState: (value: boolean) => void

  isLoading: boolean
  updateLoading: (value: boolean) => void

  selectedContents: Content[]
  incrementSelectedContents: (item: Content) => void
  updateSelectedContents: (items: Content[]) => void

  savedSelectedContents: Content[]
  updateSavedSelectedContents: (items: Content[]) => void

  isEditingSelectedContents: boolean
  updateIsEditingSelectedContents: (value: boolean) => void

  contentsDrawerStep: number
  updateContentsDrawerStep: (value: number) => void

  resetPlaylistState: () => void
}

export const useRegisterPlaylistStore = create<RegisterPlaylistStore>((set) => ({
  isRegisterPlaylistModalOpen: false,
  updateRegisterPlaylistModalState: (value) => set(() => ({ isRegisterPlaylistModalOpen: value })),

  isLoading: false,
  updateLoading: (value) => set(() => ({ isLoading: value })),

  selectedContents: [],
  updateSelectedContents: (items) => set(() => ({ selectedContents: items })),
  incrementSelectedContents: (item) =>
    set((prevState) => ({ selectedContents: [...prevState.selectedContents, item] ?? [] })),

  savedSelectedContents: [],
  updateSavedSelectedContents: (items) => set(() => ({ savedSelectedContents: items })),

  isEditingSelectedContents: false,
  updateIsEditingSelectedContents: (value) => set(() => ({ isEditingSelectedContents: value })),

  contentsDrawerStep: 0,
  updateContentsDrawerStep: (value) => set(() => ({ contentsDrawerStep: value })),

  resetPlaylistState: () =>
    set(() => ({
      isRegisterPlaylistModalOpen: false,
      isLoading: false,
      isEditingSelectedContents: false,
      savedSelectedContents: [],
      selectedContents: [],
    })),
}))
