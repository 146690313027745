export function verifyCorrectlyImageType(type: string) {
  if (!type) return undefined;

  if (
    type.includes('png') ||
    type.includes('jpg') ||
    type.includes('jpeg') ||
    type.includes('svg') ||
    type.includes('webp')
  ) return true

  return false;
}