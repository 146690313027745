import { Box, Flex, Skeleton } from '@chakra-ui/react'
/* @ts-ignore */
import SemiCircleProgressBar from 'react-progressbar-semicircle'

const CourseCardSkeleton: React.FC = () => (
  <Flex background='gray.20' width='100%' h='182px' borderRadius='30px' marginTop='28px'>
    <Skeleton w='100%' maxW='185px' h='100%' borderRadius='30px 0px 0px 30px' />
    <Flex
      marginLeft={{ base: '0px', lg: '40px' }}
      flexDirection='column'
      justifyContent='center'
      paddingX={{ base: '16px', lg: 0 }}
      paddingY={{ base: '16px', lg: 0 }}
    >
      <Skeleton w='118px' h='24px' borderRadius='16px' />
      <Skeleton w='100%' maxW='396px' h='34px' marginTop='10px' borderRadius='16px' />
      <Flex marginTop={{ base: '0px', lg: '20px' }} flexDir={{ base: 'column', lg: 'row' }}>
        <Skeleton w='207px' h='48px' marginTop='10px' borderRadius='16px' />
        <Skeleton w='151px' h='48px' marginTop='10px' borderRadius='16px' marginLeft={{ base: '0px', lg: '12px' }} />
      </Flex>
    </Flex>
  </Flex>
)

const CertificateSectionSkeleton: React.FC = () => (
  <Flex
    marginTop={{ base: '32px', lg: '40px' }}
    marginX={{ base: '32px', lg: '72px' }}
    background='gray.20'
    borderRadius='40px'
    padding={{ base: '12px 24px', lg: '22px 37px' }}
    marginBottom={{ base: '50px', lg: '61px' }}
    flexDir={{ base: 'column', lg: 'row' }}
  >
    <Skeleton w='112px' h='112px' borderRadius='100px' flexShrink='0' />
    <Flex
      marginTop={{ base: '12px', lg: 0 }}
      marginLeft={{ base: '0px', lg: '18px' }}
      flexDir='column'
      flex={{ base: '0', lg: '1' }}
    >
      <Skeleton w='100%' maxW='600px' h='22px' borderRadius='16px' marginBottom='16px' />
      <Skeleton w='100%' maxW='540px' h='24px' borderRadius='16px' marginBottom='15px' />
      <Skeleton w='140px' h='10px' borderRadius='16px' />
      <Flex marginTop={{ base: '16px', lg: '30px' }} flexDir={{ base: 'column', lg: 'row' }}>
        <Skeleton w='207px' h='48px' marginTop='10px' borderRadius='16px' />
        <Skeleton w='151px' h='48px' marginTop='10px' borderRadius='16px' marginLeft={{ base: '0px', lg: '12px' }} />
      </Flex>
    </Flex>
  </Flex>
)

const CoursesSectionSkeleton: React.FC = () => (
  <Flex marginX={{ base: '32px', lg: '72px' }} marginTop={{ base: '77px' }} flexDirection='column'>
    <Skeleton w='300px' h='29px' borderRadius='16px' marginBottom='11px' />
    <Skeleton w='500px' h='20px' borderRadius='16px' />

    <CourseCardSkeleton />
    <CourseCardSkeleton />
    <CourseCardSkeleton />
    <CourseCardSkeleton />
  </Flex>
)

const LearningPointsSectionSkeleton: React.FC = () => (
  <Flex marginX={{ base: '32px', lg: '72px' }} marginTop={{ base: '41px' }} alignItems='center' flexDirection='column'>
    <Skeleton w='100%' maxW='450px' h='29px' borderRadius='16px' />

    <Flex
      alignItems='space-between'
      marginTop='22px'
      flexDir={{ base: 'column', lg: 'row' }}
      gap={{ base: '24px', lg: '0px' }}
    >
      <Flex flexDirection='column' alignItems='center' flex='1'>
        <Skeleton w='100%' maxW='40px' h='30px' borderRadius='14px' marginBottom='2px' />
        <Skeleton w='100%' maxW='90px' h='22px' borderRadius='14px' marginBottom='3px' />
        <Skeleton w='100%' maxW='200px' h='22px' borderRadius='14px' marginBottom='3px' />
        <Skeleton w='100%' maxW='150px' h='22px' borderRadius='14px' marginBottom='3px' />
        <Skeleton w='100%' maxW='100px' h='22px' borderRadius='14px' marginBottom='3px' />
      </Flex>
      <Box
        marginLeft='26px'
        marginRight='26px'
        opacity='0.3'
        width='2px'
        background='black'
        transform='rotate(180deg)'
        height='calc(100% + 30px)'
        display={{ base: 'none', lg: 'flex' }}
      />
      <Flex flexDirection='column' alignItems='center' flex='1'>
        <Skeleton w='40px' h='30px' borderRadius='14px' marginBottom='2px' />
        <Skeleton w='90px' h='22px' borderRadius='14px' marginBottom='3px' />
        <Skeleton w='200px' h='22px' borderRadius='14px' marginBottom='3px' />
        <Skeleton w='150px' h='22px' borderRadius='14px' marginBottom='3px' />
        <Skeleton w='100px' h='22px' borderRadius='14px' marginBottom='3px' />
      </Flex>

      <Box
        marginLeft='26px'
        marginRight='26px'
        opacity='0.3'
        width='2px'
        background='black'
        transform='rotate(180deg)'
        height='calc(100% + 30px)'
        display={{ base: 'none', lg: 'flex' }}
      />
      <Flex flexDirection='column' alignItems='center' flex='1'>
        <Skeleton w='40px' h='30px' borderRadius='14px' marginBottom='2px' />
        <Skeleton w='90px' h='22px' borderRadius='14px' marginBottom='3px' />
        <Skeleton w='200px' h='22px' borderRadius='14px' marginBottom='3px' />
        <Skeleton w='150px' h='22px' borderRadius='14px' marginBottom='3px' />
        <Skeleton w='100px' h='22px' borderRadius='14px' marginBottom='3px' />
      </Flex>
    </Flex>
  </Flex>
)

const DescriptionSectionSkeleton: React.FC = () => (
  <Flex
    marginX={{ base: '0px', lg: '72px' }}
    paddingX={{ base: '32px', lg: '0px' }}
    alignItems={{ base: 'flex-start', lg: 'center' }}
    justifyContent='space-between'
    marginBottom='41px'
    flexDir={{ base: 'column', lg: 'row' }}
    gap={{ base: '12px', lg: '0px' }}
  >
    <Flex alignItems={{ base: 'flex-start', lg: 'center' }} flexDir={{ base: 'column', lg: 'row' }}>
      <Skeleton w='167px' h='167px' borderRadius='54px' />
      <Flex marginLeft={{ base: '0px', lg: '21px' }} marginTop={{ base: '12px', lg: '0px' }} flexDirection='column'>
        <Skeleton w='100%' maxW='178px' h='31px' borderRadius='16px' marginBottom='11px' />
        <Skeleton w='100%' maxW='400px' h='25px' borderRadius='16px' marginBottom='3px' />
        <Skeleton w='100%' maxW='400px' h='25px' borderRadius='16px' marginBottom='3px' />
        <Flex marginTop='13px'>
          <Skeleton w='88px' h='26px' borderRadius='16px' marginRight='10px' />
          <Skeleton w='88px' h='26px' borderRadius='16px' marginRight='10px' />
          <Skeleton w='88px' h='26px' borderRadius='16px' marginRight='10px' />
        </Flex>
      </Flex>
    </Flex>

    <Flex flexDirection='column' alignItems={{ base: 'flex-start', lg: 'flex-end' }}>
      <Skeleton w='121px' h='26px' borderRadius='16px' />
      <Flex marginTop='10px'>
        <Skeleton w='26px' h='26px' borderRadius='100px' marginRight='2px' />
        <Skeleton w='26px' h='26px' borderRadius='100px' marginRight='2px' />
        <Skeleton w='26px' h='26px' borderRadius='100px' marginRight='2px' />
        <Skeleton w='26px' h='26px' borderRadius='100px' marginRight='2px' />
        <Skeleton w='26px' h='26px' borderRadius='100px' />
      </Flex>
    </Flex>
  </Flex>
)

const BasicInfoSectionSkeleton: React.FC = () => (
  <Flex
    padding={{ base: '12px', lg: '23px 72px' }}
    background='gray.60'
    borderRadius='0px 0px 0px 54px'
    alignItems='center'
    flexWrap={{ base: 'wrap', lg: 'unset' }}
    justifyContent={{ base: 'center', lg: 'flex-start' }}
    gap={{ base: '24px', lg: 'unset' }}
  >
    <Skeleton w='100%' maxW='215px' h='50px' marginRight='2px' borderRadius='16px' />
    <Skeleton w='100%' maxW='50px' h='50px' marginRight='2px' borderRadius='16px' />

    <Box
      marginLeft='26px'
      marginRight='26px'
      opacity='0.3'
      width='2px'
      background='black'
      transform='rotate(180deg)'
      height='44px'
      display={{ base: 'none', lg: 'flex' }}
    />

    <Flex alignItems='flex-end'>
      <SemiCircleProgressBar stroke='#FF5757' strokeWidth={22} percentage={0} diameter={110} />
      <Flex flexDirection='column'>
        <Skeleton w='48px' h='18px' marginBottom='2px' borderRadius='16px' />
        <Skeleton w='83px' h='8px' marginBottom='2px' borderRadius='16px' />
        <Skeleton w='54px' h='8px' borderRadius='16px' />
      </Flex>
    </Flex>

    <Box
      marginLeft='26px'
      marginRight='26px'
      opacity='0.3'
      width='2px'
      background='black'
      transform='rotate(180deg)'
      height='44px'
      display={{ base: 'none', lg: 'flex' }}
    />

    <Flex>
      <Skeleton w='48px' h='48px' marginRight='3px' borderRadius='16px' />
      <Flex fontWeight='600' flexDirection='column' justifyContent='flex-end'>
        <Skeleton w='48px' h='18px' borderRadius='14px' marginBottom='3px' />
        <Skeleton w='83px' h='8px' borderRadius='14px' />
      </Flex>
    </Flex>

    <Box
      marginLeft='26px'
      marginRight='26px'
      opacity='0.3'
      width='2px'
      background='black'
      transform='rotate(180deg)'
      height='44px'
      display={{ base: 'none', lg: 'flex' }}
    />

    <Flex>
      <Skeleton w='48px' h='48px' marginRight='3px' borderRadius='16px' />
      <Flex fontWeight='600' flexDirection='column' justifyContent='flex-end'>
        <Skeleton w='48px' h='18px' borderRadius='14px' marginBottom='3px' />
        <Skeleton w='83px' h='8px' borderRadius='14px' />
      </Flex>
    </Flex>
  </Flex>
)

const PlaylistsSkeleton: React.FC = () => {
  return (
    <Flex flexDirection='column'>
      <DescriptionSectionSkeleton />
      <BasicInfoSectionSkeleton />
      <LearningPointsSectionSkeleton />
      <CoursesSectionSkeleton />
      <CertificateSectionSkeleton />
    </Flex>
  )
}

export default PlaylistsSkeleton
