import { ApolloQueryResult, OperationVariables } from '@apollo/client'
import { Flex, Text } from '@chakra-ui/react'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { TablePagination } from 'src/components-admin/TablePagination'
import { debounce } from 'src/helpers-admin/debounce'
import { useCorporateUniversityStore } from 'src/pages-admin/Cms/hooks/useCorporateUniversityStore'
import { CmsContentQuery, CmsPlaylistQuery } from 'src/types/Cms'
import { AvailabilityFilter } from '../../components/AvailabilityFilter'
import { OrderFilter } from '../../components/OrderFilter'
import { RegisterNewContent } from '../../components/RegisterNewContent'
import { SearchContent } from '../../components/SearchContent'
import { SkillsFilter } from '../../components/SkillsFilter'
import { TableSkeleton } from '../../components/TableSkeleton'
import { LanguageFilter } from '../LanguageFilter'
import { PlaylistPrivacyFilter } from '../PlaylistPrivacyFilter'
import { StatusFilter } from '../StatusFilter'

interface TabTableProps {
  openDrawer?: () => void
}

interface CmsTabContentProps {
  pageTitle: string
  registerNewContentTitle: string
  handleRegisterNewContentClick: () => void
  handleAllocateSkill?: () => void
  searchSectionTitle: string
  searchPlaceholder: string
  tab: string
  tabTable: ({ openDrawer }: TabTableProps) => JSX.Element
  totalContentLength: number
  isLoading: boolean
  onOpen: () => void
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<CmsContentQuery | CmsPlaylistQuery>>
}

export function CmsTabContent({
  pageTitle,
  registerNewContentTitle,
  handleRegisterNewContentClick,
  handleAllocateSkill,
  searchSectionTitle,
  searchPlaceholder,
  tab,
  tabTable: TabTable,
  totalContentLength,
  isLoading,
  onOpen,
  refetch,
}: CmsTabContentProps) {
  const [t] = useTranslation()
  const { updateSearchValue, selectedPlaylistPrivacy } = useCorporateUniversityStore()
  const isContentTab = tab === 'conteudos'
  const isPlaylistTab = tab === 'trilhas'
  const isSkillTab = tab === 'habilidades'
  const isPublicPlaylist = selectedPlaylistPrivacy === 'public'

  const handleSearch = useCallback(
    async (value: string) => {
      updateSearchValue(value)

      if (isPublicPlaylist) {
        await refetch({
          ...(isSkillTab ? { name: value } : { title: value }),
        })
      }
    },
    [refetch, updateSearchValue],
  )

  const searchDebounce = debounce(handleSearch, 500)

  return (
    <>
      <Flex flexDir='column' flex={1} paddingBottom='40px' data-testid='cms-contents-wrapper'>
        <Text color='#313E4C' fontSize='1.25rem' fontWeight={600} fontFamily='Poppins'>
          {pageTitle}
        </Text>

        {isSkillTab ? (
          <Flex mt='15px' w='100%'>
            <RegisterNewContent
              isAllocate
              title={t('admin.corporateUniversity.skills.allocateSkills')}
              onClick={handleAllocateSkill!}
            />
          </Flex>
        ) : null}

        {!isSkillTab ? (
          <Flex mt='15px' w='100%'>
            <RegisterNewContent title={registerNewContentTitle} onClick={handleRegisterNewContentClick} />
          </Flex>
        ) : null}

        <Flex w='100%' mt='24px'>
          <SearchContent title={searchSectionTitle} placeholder={searchPlaceholder} searchContent={searchDebounce} />
        </Flex>

        <Flex w='100%' flexDir='column' mt='24px'>
          <Text color='bluishGray.500'> {t('admin.filter.Filters')}</Text>

          <Flex gap='6px' mt='6px' data-testid='cms-filters-wrapper' alignItems='center'>
            <OrderFilter />
            {!isSkillTab && !isPlaylistTab && <SkillsFilter />}
            {isContentTab && <LanguageFilter />}
            {isPlaylistTab && <StatusFilter />}
            {!isSkillTab && !isPlaylistTab && <AvailabilityFilter />}
            {isPlaylistTab && <PlaylistPrivacyFilter />}
          </Flex>
        </Flex>

        <Flex w='100%' mt='16px'>
          {isLoading ? (
            <TableSkeleton />
          ) : (
            <Flex w='100%' flexDir='column'>
              <TabTable openDrawer={onOpen} />

              <Flex w='100%' mt='24px'>
                <TablePagination contentLength={totalContentLength} isPlaylist={isPlaylistTab} />
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  )
}
