import AdminAccount from "./AdminAccount";
import VerifyEmail from "./VerifyEmail";
import Company from "./Company";
import HomepageHR from "./HomePage";
import {
  IoPerson,
  IoMailUnread,
  IoBusiness,
  IoHome,
} from "react-icons/io5";
import { IconType } from "react-icons/lib";

export interface Step {
  slug: "admin-account" | "verify-email" | "company" | "employees" | "home";
  title: string;
  icon: IconType;
  component: React.ComponentType;
}

type Steps = Step[];

export const steps: Steps = [
  {
    slug: "home",
    title: "Home",
    icon: IoHome,
    component: HomepageHR,
  },
  {
    slug: "company",
    title: "Company",
    icon: IoBusiness,
    component: Company,
  },
  {
    slug: "admin-account",
    title: "Administrator",
    icon: IoPerson,
    component: AdminAccount,
  },
  {
    slug: "verify-email",
    title: "Verify",
    icon: IoMailUnread,
    component: VerifyEmail,
  },
];

export function getComponentFromStepSlug(
  stepSlug: Step["slug"]
): React.ComponentType | undefined {
  const chosenStep = steps.filter((step) => step.slug === stepSlug)[0];
  if (chosenStep) return chosenStep.component;
  return undefined;
}

export function getNextStep(currentStepSlug: Step["slug"]) {
  const currentStepIndex = steps.findIndex(
    (step) => step.slug === currentStepSlug
  );
  if (currentStepIndex + 1 === steps.length) return null;
  return steps[currentStepIndex + 1];
}

export function getPreviousStep(currentStepSlug: Step["slug"]) {
  const currentStepIndex = steps.findIndex(
    (step) => step.slug === currentStepSlug
  );
  if (currentStepIndex - 1 === steps.length) return null;
  return steps[currentStepIndex - 1];
}

interface useAuthOnboardProps {
  stepSlug: Step["slug"];
}

export const useAuthOnboard: React.FC<useAuthOnboardProps> = () => {
  return null;
};
