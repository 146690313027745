import { Accordion, AccordionItem, AccordionPanel, Box, Flex, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useImportedContents } from 'src/pages-admin/Cms/hooks/useImportedContents'
import { AccordionButton } from './components/AccordionButton'
import { CloseImportedContentModal } from './components/Buttons/Back'
import { PublishImportedContentsButton } from './components/Buttons/Publish'
import { SaveDraftImportedContents } from './components/Buttons/SaveDraft'
import { ContentInfoForm } from './components/ContentInfoForm'

export function EditImportedContentModal() {
  const { selectedImportedContent, updateSelectedContent } = useImportedContents()

  useEffect(() => {
    return () => {
      if (selectedImportedContent) updateSelectedContent(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex w='100%' h='100%' bg='white' position='fixed' left='0' top='0' zIndex={7} py='50px'>
      <Flex as='form' flexDir='column' w='100%' h='100%' maxW='918px' mx='auto' gap='32px' px='8px'>
        <Flex flexDir='column' w='100%' h='100%' gap='32px' overflowY='auto'>
          <Flex flexDir='column' gap='4px'>
            <Text fontWeight='700' fontFamily='Poppins' fontSize='1.5rem'>
              Edição: "{selectedImportedContent?.title ?? 'Nome do conteúdo inválido'}"
            </Text>
            <Text fontSize='1rem'>
              Aqui você pode editar todas as informações do conteúdo “
              {selectedImportedContent?.title ?? 'Nome do conteúdo inválido'}”.
            </Text>
          </Flex>

          <Accordion allowToggle defaultIndex={[0]}>
            <AccordionItem>
              <AccordionButton title='Informações do conteúdo' />
              <AccordionPanel pb='16px'>
                <ContentInfoForm />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Flex>
        <Flex w='100%' gap='16px' align='center'>
          <Box mr='auto'>
            <CloseImportedContentModal />
          </Box>
          {/* <RejectImportedContents /> */}
          <SaveDraftImportedContents />
          <PublishImportedContentsButton />
        </Flex>
      </Flex>
    </Flex>
  )
}
