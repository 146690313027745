import { Box, Image, Link as ChakraLink, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import useExtension from 'src/hooks/useExtension'

const AddExtension = () => {
  const {extensionUrl} = useExtension()
  const [t] = useTranslation()
  const { EventsImplementation, track } = useMixpanel()

  return (
    <Stack
      w='50%'
      display={['none', 'none', 'none', 'flex', 'flex']}
      flexDirection='column'
      justifyContent='flex-start'
      bg='white'
      alignItems='center'
      paddingTop='62px'
    >
      <Text
        textAlign='center'
        lineHeight='36px'
        w='90%'
        fontFamily='Poppins'
        fontSize={['18px', '18px', '20px', '28px', '28px']}
        fontWeight='extrabold'
      >
        {' '}
        {t('activatePartner.addExtension.Title')}
      </Text>
      <Box bg='primaryColors.400' w='137px' h='2px' />
      <Text
        paddingTop='20px'
        fontFamily='Mulish'
        w={['80%', '70%', '80%', '70%', '70%']}
        textAlign='center'
        fontSize='14px'
        fontWeight='regular'
      >
        {' '}
        {t('activatePartner.addExtension.Description')}
      </Text>
      <Image src='/assets/images/img-extension-screen.svg' w='300px' />
      <ChakraLink
        w='80%'
        display='flex'
        flexDirection='row'
        justifyContent='center'
        alignItems='center'
        bg='primaryColors.400'
        color='white'
        fontFamily='Mulish'
        fontWeight='bold'
        fontSize='16px'
        paddingY='12px'
        borderRadius='100px'
        marginTop='20px'
        marginBottom='10px'
        isExternal
        href={extensionUrl}
        _hover={{
          textDecoration: 'none',
        }}
        onClick={() => {
          track(EventsImplementation.ONBOARDING_INSTALL_EXTENSION())
        }}
      >
        <Image marginRight='8px' fontSize='16px' src='/assets/images/chrome-icon.svg' color='white' />
        {t('activatePartner.addExtension.Add to Chrome')}
      </ChakraLink>
    </Stack>
  )
}

export default AddExtension
