import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import Content from 'src/modules/cms/domain/Content'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { usePlaylists } from 'src/modules/cms/hooks/usePlaylists'
import { makeGetUserCertificates } from 'src/modules/iam/factory/makeGetUserCertificates'
import { CertificateDto, CertificateTypeEnum } from 'src/modules/iam/use-case/GetUserCertificatesUseCase'
import { logger } from 'src/shared/Logger'
import { User } from 'src/types/User'

interface Value {
  playlist?: NewPlaylist
  loading: boolean
  isPlaylistRemoved: boolean
  setPlaylist: (playlist: NewPlaylist) => void
  setIsPlaylistRemoved: (isRemoved: boolean) => void
  removeLearningItemFromPlaylist: (content: Content) => void
  fetchCustomPlaylist: (user: User) => Promise<void>

  evaluationSource: string
  setEvaluationSource: (source: string) => void

  isEvaluationOpen: boolean
  setIsEvaluationOpen: (item: boolean) => void

  openModuleIndex?: number
  updateModuleOpen: (moduleIndex: number) => void

  updateContent: (content: Content) => void

  showSuccessfulMessage: boolean
  setShowSuccessfulMessage: (item: boolean) => void

  showSkillsDrawer: boolean
  setShowSkillsDrawer: React.Dispatch<React.SetStateAction<boolean>>
  toggleSkillsDrawer: () => void

  certificate?: CertificateDto
  isLoadingCertificate: boolean
  getCustomPlaylistCertificate: () => Promise<CertificateDto | undefined>
  numberOfPlaylistModules: number
  numberOfPlaylistModulesDone: number
  isPlaylistCompleted: boolean

  getCustomPlaylistModulesCertificates: () => Promise<CertificateDto[] | undefined>
}

interface Props {
  children: React.ReactNode[] | React.ReactNode
}

const CustomPlaylistContext = createContext({} as Value)

export function CustomPlaylistProvider({ children }: Props) {
  const { getCustomPlaylist } = usePlaylists()
  const [playlist, setPlaylist] = useState<NewPlaylist | undefined>({} as NewPlaylist)
  const [isLoading, setIsLoading] = useState(false)
  const [certificate, setCertificate] = useState<CertificateDto | undefined>(undefined)
  const [isLoadingCertificate, setIsLoadingCertificate] = useState(false)
  const [evaluationSource, setEvaluationSource] = useState('')
  const [isEvaluationOpen, setIsEvaluationOpen] = useState(false)

  const [openModuleIndex, setOpenModuleIndex] = useState<number | undefined>(
    localStorage.getItem('lastModuleOpen') ? Number(localStorage.getItem('lastModuleOpen')) : 0,
  )

  const [isPlaylistRemoved, setIsPlaylistRemoved] = useState(false)
  const [showSuccessfulMessage, setShowSuccessfulMessage] = useState(false)
  const [showSkillsDrawer, setShowSkillsDrawer] = useState(false)

  const getCertificatesList = makeGetUserCertificates()

  const numberOfPlaylistModules = playlist?.moduleList?.items?.length ?? 0
  const numberOfPlaylistModulesDone = useMemo(
    () =>
      playlist?.moduleList?.items?.filter((mod) =>
        mod.contentList?.items?.every((content) => content.progress === 'DONE'),
      )?.length ?? 0,
    [playlist?.moduleList?.items],
  )

  const isPlaylistCompleted = numberOfPlaylistModules === numberOfPlaylistModulesDone

  const fetchCustomPlaylist = async (user: User) => {
    setIsLoading(true)
    getCustomPlaylist(user)
      .then((response) => {
        setPlaylist(response)
        setIsLoading(false)
      })
      .catch((err) => logger.error('Falha no carregamento da trilha personalizada', { err }))
      .finally(() => setIsLoading(false))
  }

  const updateModuleOpen = (moduleIndex: number) => {
    localStorage.setItem('lastModuleOpen', moduleIndex.toString())
    if (moduleIndex !== openModuleIndex) setOpenModuleIndex(moduleIndex)
    else setOpenModuleIndex(undefined)
  }

  const updateContent = (content: Content) => {
    if (!playlist) return
    const newPlaylist = { ...playlist }

    if (newPlaylist.moduleList?.items) {
      newPlaylist.moduleList.items = newPlaylist.moduleList.items.map((module) => {
        const contents = module.contentList?.items?.map((item) => {
          if (item.id === content.id) return content
          return item
        })
        return { ...module, contentList: { items: contents } }
      })
      setPlaylist(newPlaylist)
    }
  }

  const removeLearningItemFromPlaylist = useCallback(
    (content: Content) => {
      if (!playlist) return
      const newPlaylist = { ...playlist }

      if (newPlaylist.moduleList?.items) {
        const newLearningItems = newPlaylist.moduleList.items.map((module) => {
          const learningItems = module.contentList?.items?.filter((item) => item.id !== content.id)
          return { ...module, contentList: { items: learningItems } }
        })

        newPlaylist.moduleList.items = newLearningItems.filter((item) => !!item.contentList.items?.length)
        setPlaylist(newPlaylist)
      }
    },
    [playlist],
  )

  const toggleSkillsDrawer = useCallback(() => {
    setShowSkillsDrawer((prevState) => !prevState)
  }, [])

  const getCustomPlaylistCertificate = async () => {
    try {
      setIsLoadingCertificate(true)
      const userCertificates = await getCertificatesList.execute({ playlistId: playlist?.id })
      if (!userCertificates || userCertificates?.items?.length <= 0) return undefined
      const customPlaylistCertificate = userCertificates?.items?.find((item) => item.playlistId === playlist?.id)
      setCertificate(customPlaylistCertificate)
      return customPlaylistCertificate
    } catch {
      console.error('Ocorreu um erro ao buscar o certificado da Trilha personalizada')
      return undefined
    } finally {
      setIsLoadingCertificate(false)
    }
  }

  const getCustomPlaylistModulesCertificates = async () => {
    const userCertificates = await getCertificatesList.execute({
      type: CertificateTypeEnum.CustomPlaylistModule,
      limit: 20,
    })
    return userCertificates?.items
  }

  return (
    <CustomPlaylistContext.Provider
      value={{
        playlist,
        setPlaylist,
        loading: isLoading,
        openModuleIndex,
        updateModuleOpen,

        evaluationSource,
        setEvaluationSource,

        isEvaluationOpen,
        setIsEvaluationOpen,

        updateContent,

        removeLearningItemFromPlaylist,
        isPlaylistRemoved,
        setIsPlaylistRemoved,
        showSuccessfulMessage,
        setShowSuccessfulMessage,
        showSkillsDrawer,
        setShowSkillsDrawer,
        toggleSkillsDrawer,
        fetchCustomPlaylist,

        certificate,
        isLoadingCertificate,
        getCustomPlaylistCertificate,
        numberOfPlaylistModules,
        numberOfPlaylistModulesDone,
        isPlaylistCompleted,

        getCustomPlaylistModulesCertificates,
      }}
    >
      {children}
    </CustomPlaylistContext.Provider>
  )
}

export const useCustomPlaylist = () => useContext(CustomPlaylistContext)
