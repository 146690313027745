import * as Yup from 'yup'

export const RegisterPlaylistFormSchema = Yup.object().shape({
  title: Yup.string().required('Obrigatório'),
  type: Yup.string().required('Obrigatório'),
  status: Yup.string(),
  imageUrl: Yup.mixed().test('required', 'Obrigatório', (file) => {
    return file !== undefined && file !== null
  }),
  hasCertificate: Yup.boolean(),
  cardImageUrl: Yup.string().required('Obrigatório'),
  description: Yup.string(),
  color: Yup.string(),
  certificateImageUrl: Yup.mixed().when('hasCertificate', {
    is: true,
    then: Yup.mixed().test('required', 'Obrigatório', (file) => {
      return file !== undefined && file !== null
    }),
  }),
  certificateTemplateUrl: Yup.string().when('hasCertificate', {
    is: true,
    then: Yup.string().required('Obrigatório'),
  }),
  modules: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required('Obrigatório'),
      order: Yup.number().required('Obrigatório'),
      contents: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required('Obrigatório'),
          order: Yup.number().required('Obrigatório'),
        })
      ),
    })
  ).required('Obrigatório'),
  learningPoints: Yup.array()
})
