import { Company } from '../domain/Company'
import { CertificateRepository } from '../repositories/CertificateRepository'

export interface UpdateCertificateUseCaseInput {
  customPlaylistCertificateEnabled: boolean
  featuredPlaylistCertificateEnabled: boolean
  playlistModuleCertificateEnabled: boolean
  shouldIncludeSignature: boolean
  shouldIncludeLogo: boolean
}

type UpdateCertificateUseCaseOutput = Company

export class UpdateCertificateUseCase {
  constructor(private certificateRepository: CertificateRepository) {}

  async execute(args: UpdateCertificateUseCaseInput): Promise<UpdateCertificateUseCaseOutput> {
    const company = await this.certificateRepository.updateCertificate(args)
    return company
  }
}
