import { Button, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface Props {
  handleStartCourseClick: (e: React.MouseEvent<HTMLElement>) => void
}

export const AccessLearningItemButton: React.FC<Props> = ({ handleStartCourseClick }: Props) => {
  const { t } = useTranslation()
  return (
    <Button
      h='48px'
      variant={'startCourse'}
      border='2px solid #E5E5E5'
      paddingX='20px'
      onClick={handleStartCourseClick}
      w='auto'
      flexGrow={{ base: '1', lg: '0' }}
      display='flex'
      alignItems='center'
      justifyContent='flex-start'
    >
      <Text fontSize={{ base: '.75rem', md: '1rem' }}>{t('common.card.access')}</Text>
    </Button>
  )
}
