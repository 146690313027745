import { UseCase } from "src/types/UseCase";
import { AreaRepository, ListAreasInput, ListAreasOutput } from "../repository/AreaRepository";

export class ListAreasUseCase implements UseCase<ListAreasInput, ListAreasOutput> {
  constructor(private areaRepository: AreaRepository){}
  
  async execute(args?: ListAreasInput): Promise<ListAreasOutput> {
    const areas = this.areaRepository.listAreasByCompany(args)
    return areas
  }
}