import { Flex, Image, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { makeListPlaylistsPermissions } from 'src/modules/cms/factories/makeListPlaylistsPermissions'
import { usePlaylists } from 'src/modules/cms/hooks/usePlaylists'
import { DeleteContent, EditContent } from 'src/theme/Icons'
import { useCorporateUniversityStore } from '../../hooks/useCorporateUniversityStore'
import { useRegisterPlaylistStore } from '../../hooks/useRegisterPlaylistStore'
import { convertPlaylistDurationInReadableTime } from '../../utils/getPlaylistDuration'

interface CmsPlaylistTableProps {
  openDrawer?: () => void
  courses?: NewPlaylist[]
  playlists?: NewPlaylist[]
}

export function CmsPlaylistTable({ openDrawer }: CmsPlaylistTableProps) {
  const [isLoadingPlaylistDetails, setIsLoadingPlaylistDetails] = useState(false)
  const {
    cmsPlaylists,
    cmsPrivatePlaylists,
    selectedPlaylist,
    selectedPlaylistPrivacy,
    updateSelectedPlaylist,
    updateSelectedContent,
    updatePlaylistPermissions,
  } = useCorporateUniversityStore()
  const { getPlaylist } = usePlaylists()
  const { updateRegisterPlaylistModalState, updateSavedSelectedContents } = useRegisterPlaylistStore()
  const [t] = useTranslation()

  const isPublicPlaylist = selectedPlaylistPrivacy === 'public'
  const playlistsToRender = isPublicPlaylist ? cmsPlaylists : cmsPrivatePlaylists

  const handleRemoveContent = useCallback(
    (playlist: NewPlaylist) => {
      updateSelectedPlaylist(playlist)
      updateSelectedContent(undefined)
      if (openDrawer) {
        openDrawer()
      }
    },
    [updateSelectedPlaylist, openDrawer, updateSelectedContent],
  )

  const handleEditContent = async (playlist: NewPlaylist) => {
    updateSelectedPlaylist(playlist)
    setIsLoadingPlaylistDetails(true)
    const listPlaylistPermissions = makeListPlaylistsPermissions()

    const [playlistPermissionsResponse, playlistData] = await Promise.all([
      listPlaylistPermissions.execute({ playlistId: playlist.id, limit: 500 }),
      getPlaylist(playlist.id),
    ])

    updateSelectedPlaylist({
      ...playlist,
      allowedCompanyIds: playlistData?.allowedCompanyIds,
    })

    if (playlistPermissionsResponse?.length !== 0) updatePlaylistPermissions(playlistPermissionsResponse)
    updateSavedSelectedContents(playlistData?.moduleList?.items?.[0]?.contentList?.items ?? [])
    updateRegisterPlaylistModalState(true)
    setIsLoadingPlaylistDetails(false)
  }

  return (
    <TableContainer
      d='flex'
      position='relative'
      w='100%'
      borderRadius='16px'
      boxShadow='0px 1px 10px rgba(0, 0, 0, 0.1)'
      data-testid='cms-table-wrapper'
    >
      <Table variant='unstyled' size='lg'>
        <Thead bg='#F7F9FA'>
          <Tr padding='16px 34px' w='100%'>
            <Th w='100px' fontWeight={700} textTransform='none' fontSize='1.125rem'>
              {t('admin.corporateUniversity.playlists.tableImageTitle')}
            </Th>
            <Th w='40%' fontWeight={700} textTransform='none' fontSize='1.125rem'>
              {t('admin.corporateUniversity.playlists.tableContentTitle')}
            </Th>
            <Th fontWeight={700} textTransform='none' fontSize='1.125rem'>
              Status
            </Th>
            {isPublicPlaylist && (
              <Th fontWeight={700} textTransform='none' fontSize='1.125rem'>
                {t('admin.corporateUniversity.playlists.tableDurationTitle')}
              </Th>
            )}
            <Th w='80px' />
          </Tr>
        </Thead>

        <Tbody>
          {!playlistsToRender ||
            (playlistsToRender.length <= 0 && (
              <Flex padding='20px'>
                <Text color='bluishGray.500'>{t('noContentFoundFor')}.</Text>
              </Flex>
            ))}

          {playlistsToRender?.length > 0 &&
            playlistsToRender?.map((playlist, index) => (
              <Tr
                key={playlist.id}
                w='100%'
                borderBottom='1px solid #EFEFEF'
                color='bluishGray.500'
                _hover={{ backgroundColor: 'gray.50' }}
                transition='all 300ms ease'
              >
                <Td display='flex' alignItems='center' justifyContent='center'>
                  <Image
                    src={
                      playlist?.imageUrl ?? 'https://i.ibb.co/fS8fCDS/dummy-300x300-009136-ffffff-education-journey.png'
                    }
                    alt={`Imagem da playlist ${playlist.title}`}
                    w='50px'
                    borderRadius='4px'
                  />
                </Td>
                <Td maxW='300px' overflowX='hidden' textOverflow='ellipsis'>
                  {playlist.title}
                </Td>
                <Td>{playlist.status === 'PUBLISHED' ? 'Publicado' : 'Rascunho'}</Td>
                {isPublicPlaylist && <Td>{convertPlaylistDurationInReadableTime(playlist)}</Td>}
                <Td>
                  <Flex align='center' gap='24px' position='relative'>
                    <Tooltip
                      hasArrow
                      shouldWrapChildren
                      bg='gray.800'
                      color='gray.50'
                      p='8px'
                      placement='top'
                      borderRadius='4px'
                      boxShadow='0px 4px 6px rgba(0, 0, 0, 0.15);'
                      label={t('admin.corporateUniversity.tableEditButton')}
                    >
                      {isLoadingPlaylistDetails && selectedPlaylist?.id === playlist?.id ? (
                        <Spinner width='24px' />
                      ) : (
                        <EditContent
                          width='24px'
                          height='24px'
                          cursor='pointer'
                          onClick={() => handleEditContent(playlist)}
                          data-testid={`cms-edit-content-button-${index}`}
                        />
                      )}
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      shouldWrapChildren
                      bg='gray.800'
                      color='gray.50'
                      p='8px'
                      placement='top'
                      borderRadius='4px'
                      boxShadow='0px 4px 6px rgba(0, 0, 0, 0.15);'
                      label={t('admin.corporateUniversity.tableDeleteButton')}
                    >
                      <DeleteContent
                        width='24px'
                        height='24px'
                        onClick={() => handleRemoveContent(playlist)}
                        cursor='pointer'
                        data-testid={`cms-remove-content-button-${index}`}
                      />
                    </Tooltip>
                  </Flex>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
