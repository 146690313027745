import { Button, Flex, Spinner, Text, Tooltip } from '@chakra-ui/react'
import { FormikProps } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useCorporateUniversityStore } from 'src/pages-admin/Cms/hooks/useCorporateUniversityStore'
import { IContentData, useRegisterContentStore } from 'src/pages-admin/Cms/hooks/useRegisterContentStore'
import { registerContentInitialValues } from 'src/pages-admin/Cms/utils/registerContentInitialValues'

interface Props {
  form: FormikProps<IContentData>
  close?: () => void
}

export function PublishContent({ form, close }: Props) {
  const [t] = useTranslation()
  const {
    isLoading,
    updateRegisterModalState,
    updateSelectedPermission,
    updateSelectedCompanies,
    updateSelectedCompaniesSnapshot,
    updateLoading,
  } = useRegisterContentStore()
  const { selectedContent } = useCorporateUniversityStore()
  const isInvalid = Object.keys(form.errors).length !== 0

  useEffect(() => {
    const validate = async () => {
      await form.validateForm(registerContentInitialValues(selectedContent))
    }

    validate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeModal = () => {
    updateRegisterModalState(false)
    updateSelectedPermission('')
    updateSelectedCompanies([])
    updateSelectedCompaniesSnapshot([])
    updateLoading(false)
    if (close) close()
  }

  return (
    <Flex align='center' justify='space-between' pb='91px'>
      <Text
        color='#767F89'
        fontSize='1rem'
        textDecoration='underline'
        fontFamily='Poppins'
        fontWeight='600'
        cursor='pointer'
        onClick={closeModal}
      >
        {t('back')}
      </Text>
      <Tooltip
        hasArrow
        placement='top'
        fontSize='12px'
        label={t('verifyTheStrictedFields')}
        isDisabled={!isInvalid}
        shouldWrapChildren
      >
        <Button
          type='submit'
          variant='unstyled'
          w='100px'
          h='44px'
          bg='gray.800'
          color='white'
          fontSize='1rem'
          padding='8px 16px'
          isDisabled={isInvalid || isLoading}
          _hover={{ _disabled: { bg: '#3F3D561F' } }}
        >
          {isLoading ? <Spinner /> : t('publish')}
        </Button>
      </Tooltip>
    </Flex>
  )
}
