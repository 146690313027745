import { Box, Button, Flex, Icon, Link as ChakraLink, Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import { IoCloseSharp, IoPlayCircle } from 'react-icons/io5'
import { useLocation } from 'react-router-dom'
import YouTube from 'react-youtube'
import Vimeo from '@u-wave/react-vimeo'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import Content from 'src/modules/cms/domain/Content'
import { checkVideoProvider } from 'src/modules/cms/utils/checkVideoProvider'
import { extractIdFromTrailerUrl } from 'src/modules/cms/utils/extractIdFromTrailerUrl'
import useRegisterAccess from '../../hooks/useRegisterAccess'
import { useTranslation } from 'react-i18next'

interface Props {
  content: Content
  onClose: () => void
  isOpen: boolean
}

const youtubeOptions = {
  playerVars: {
    autoplay: 1,
    loop: 1,
    showinfo: 0,
    modestbranding: 1,
  } as const,
}

const TrailerModal: React.FC<Props> = ({ content, onClose, isOpen }: Props) => {
  const [t] = useTranslation()
  const register = useRegisterAccess()
  const location = useLocation()
  const { EventsImplementation, track } = useMixpanel()

  if (!content) return null

  const provider = checkVideoProvider(content?.trailerUrl)
  const trailerId = extractIdFromTrailerUrl(content?.trailerUrl)

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} autoFocus={false} scrollBehavior='outside'>
        <ModalOverlay backgroundColor='rgba(0, 0, 0, 0.7)' />
        <ModalContent w='85%' maxW='890px' background='transparent'>
          <Button
            backgroundColor='white'
            backgroundPosition='center'
            borderRadius='300px'
            w='47px'
            h='47px'
            position='absolute'
            top='-14px'
            right='-10px'
            zIndex='2'
            transition='background 0.8s'
            _hover={{
              background: '#E1E1E1 radial-gradient(circle, transparent 1%, #E1E1E1 1%) center/15000%',
            }}
            _focus={{
              outline: 'none',
              boxShadow: 'unset',
            }}
            _active={{
              backgroundColor: 'gray.200',
              transition: 'background 0s',
            }}
            onClick={() => onClose()}
          >
            <Icon w='28px' h='28px' color='black' as={IoCloseSharp} />
          </Button>
          <Box background='linear-gradient(0deg, #2F2E2E 3.52%, rgba(47, 46, 46, 0) 100%)' borderRadius='30px'>
            {content.trailerUrl &&
              (provider === 'youtube' ? (
                <YouTube
                  videoId={trailerId}
                  opts={youtubeOptions}
                  containerClassName='trailer-youtube-container course-youtube-rounded'
                />
              ) : (
                <Vimeo
                  video={trailerId}
                  className='vimeo-video vimeo-video-rounded'
                  responsive
                  autoplay
                  volume={0.5}
                  loop
                />
              ))}
          </Box>
          <Flex alignItems='center' marginTop='29px' marginLeft='15px'>
            <ChakraLink
              href={content.url}
              target='_blank'
              textDecoration='none'
              _focus={{ textDecoration: 'none' }}
              _active={{ textDecoration: 'none' }}
              _hover={{ textDecoration: 'none' }}
            >
              <Button
                variant='startCourse'
                padding='27px 21px'
                minW='fit-content'
                onClick={() => {
                  register(content.id)
                  track(
                    EventsImplementation.ACCESS_LEARNING_ITEM(
                      null,
                      content?.favorited!,
                      content.type,
                      content.title,
                      content.id,
                      content?.partner?.name!,
                      location.pathname,
                      'Trailer',
                    ),
                  )
                }}
              >
                <Icon w='33px' h='33px' color='gray.800' as={IoPlayCircle} />
                <Text marginLeft='8px' fontSize='20px' color='gray.800'>
                  {t('common.card.start')}
                </Text>
              </Button>
            </ChakraLink>
            <Text
              color='white'
              whiteSpace='nowrap'
              textOverflow='ellipsis'
              overflow='hidden'
              fontFamily='Poppins'
              fontWeight='600'
              fontSize='30px'
              marginLeft='29px'
            >
              {content.title}
            </Text>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  )
}

export default TrailerModal
