import { Flex } from '@chakra-ui/react'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import SidemenuAdmin from '../../components-admin/Sidemenu'
import Feedback from '../Feedback'
import { NoConnection } from '../NoConnection'
import Sidemenu from '../Sidemenu'
import Topmenu from '../Topmenu'

interface LayoutProps {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
  const location = useLocation()
  const layoutRef = useRef<HTMLDivElement>(null)

  const isUserInAdminPanel = window.location.pathname.includes('/admin/')

  useEffect(() => {
    if (layoutRef && layoutRef.current) layoutRef.current.scroll(0, 0)
  }, [location])

  return (
    <Flex height='100vh' minHeight='100vh' maxHeight='100vh' direction='column' overflow='hidden'>
      <Flex height='100%' overflow='hidden'>
        {!isUserInAdminPanel ? <Sidemenu /> : <SidemenuAdmin />}
        <NoConnection />
        <Flex width='100%' height='100%' overflow='hidden' flexDirection='column' alignItems='center' ref={layoutRef}>
          <Flex
            width='100%'
            height='100%'
            overflow='hidden'
            flexDirection='column'
            scrollBehavior='smooth'
            id='layout-screen'
            minHeight='100%'
            maxHeight='100%'
          >
            <Topmenu />
            <Flex
              id='layout-page'
              flexDirection='column'
              width='100%'
              height='100%'
              overflowY='auto'
              overflowX='hidden'
            >
              {children}
            </Flex>
            <Feedback />
          </Flex>
        </Flex>
      </Flex>
      {/* <MobileMenu /> */}
    </Flex>
  )
}

export default Layout
