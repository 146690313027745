import { useMutation } from '@apollo/client'
import { Button, Flex, Text, VStack } from '@chakra-ui/react'
import { useTeams } from '../../context-admin/useTeams'
import { useMixpanel } from '../../hooks-admin/mixpanel/useMixpanel'
import { REMOVE_TEAM_MEMBERS } from './mutations'

type Props = {
  handleComponentChange: (value: string) => void
}

const RemoveCollabsStepTwo: React.FC<Props> = ({ handleComponentChange }) => {
  const { selectedTeam, removeCollabs, setSuccessText, handleCollabsRemove, handleSelectedOptions, setSelectedTeam } =
    useTeams()

  const { EventsImplementation, track } = useMixpanel()
  const [removeMembers, { loading: removeMembersLoading }] = useMutation(REMOVE_TEAM_MEMBERS)

  const handleBack = () => {
    handleComponentChange('REMOVE_COLLABS')
  }

  const handleNextStep = async () => {
    const teamMemberIds = removeCollabs.map((removeMember) => Number(removeMember.id))
    const updatedTeam = await removeMembers({
      variables: { teamId: selectedTeam.id, teamMemberIds },
    })
    track(EventsImplementation.EDIT_TEAM('Remover colaboradores', teamMemberIds.length))
    setSelectedTeam(updatedTeam.data.removeTeamMembers)
    setSuccessText(`Os colaboradores foram removidos de “${selectedTeam.name}” com sucesso!`)
    handleSelectedOptions('REMOVE_COLLABS')
    handleComponentChange('SUCCESS')
  }

  return (
    <>
      <Text
        color='#767F89'
        textDecoration='underline'
        fontFamily='Poppins'
        fontWeight='600'
        marginBottom='22px'
        cursor='pointer'
        onClick={handleBack}
      >
        Voltar
      </Text>

      <VStack align='flex-start'>
        <Text fontSize='20px' fontWeight={600} fontFamily='Poppins' color='gray.31'>
          Você está removendo de "{selectedTeam.name}"
        </Text>
        <Text color='#767F89' mb='24px'>
          Confira e confirme se você realmente deseja remover estes colaboradores de Equipe "{selectedTeam.name}".
        </Text>

        <Flex id='scrollableDiv' direction='column' mt='16px' w='100%' maxH='calc(100vh - 550px)' overflowY='auto'>
          {removeCollabs.map((item) => {
            return (
              <Flex
                key={item.name}
                h='43px'
                align='center'
                bgColor='#F7F9FA'
                justify='space-between'
                borderRadius='8px'
                p='0 16px'
                mb='16px'
                boxShadow='0px 1px 6px rgba(0, 0, 0, 0.1)'
              >
                <Text ml='8px' fontWeight='700' fontSize='12px' color='#009639'>
                  {item.name}
                </Text>
                <Button
                  variant='unstyled'
                  fontWeight='600'
                  fontSize='12px'
                  textDecoration='underline'
                  color='#313E4C'
                  onClick={() => handleCollabsRemove(item)}
                >
                  Cancelar
                </Button>
              </Flex>
            )
          })}
        </Flex>

        <Flex w='100%' justify='space-between' mt='24px !important'>
          <Button variant='whiteOutline' fontSize='12px' onClick={handleBack}>
            Voltar
          </Button>

          <Button
            variant='solidGray'
            fontSize='12px'
            disabled={removeCollabs.length === 0}
            onClick={handleNextStep}
            isLoading={removeMembersLoading}
          >
            Confirmar
          </Button>
        </Flex>
      </VStack>
    </>
  )
}

export default RemoveCollabsStepTwo
