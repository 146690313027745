import { Flex, FormControl, FormLabel, Icon, Input, Text } from '@chakra-ui/react'
import { BsFillCloudUploadFill } from 'react-icons/bs'
import { ACCEPT_FORMATS, validateCertificateFile } from '../../helpers/validateCertificateFile'
import { CertificateImageFile } from '../../tabs/Certificate'
interface FileFormProps {
  fileAndError: CertificateImageFile
  onFileChange: React.Dispatch<React.SetStateAction<CertificateImageFile>>
  inputFilelabel?: string
  isDisabled?: boolean
  isSignature?: boolean
}

/**
 * Render an input file with error handler.
 * @param fileAndError A React State that handle file and error.
 * @param onFileChange The callback function to change the file and error (React State).
 * @param inputFilelabel The label to be displayed. Optional
 * @param isDisabled If the input file should be disabled. Optional
 * @example <FileForm fileAndError={fileAndError} onFileChange={callbackReactState} inputFilelabel={label} isDisabled={boolean} />
 */
export function FileForm({
  fileAndError,
  onFileChange,
  inputFilelabel,
  isDisabled = false,
  isSignature = false,
}: FileFormProps) {
  const imageWidth = isSignature ? 360 : 450
  const imageHeight = isSignature ? 120 : 150

  return (
    <FormControl pointerEvents={isDisabled ? 'none' : 'auto'} opacity={isDisabled ? 0.4 : 1}>
      {inputFilelabel && (
        <FormLabel fontSize='1rem' fontWeight='700'>
          {inputFilelabel}
        </FormLabel>
      )}
      <Flex
        align='center'
        justifyContent='center'
        borderWidth='2px'
        borderColor={fileAndError.error?.isError ? 'red.600' : '#e5e5e5'}
        borderRadius='12px'
        minH='36px'
        maxH='48px'
        px='16px'
      >
        <Input
          display='none'
          accept={ACCEPT_FORMATS.map((format) => `image/${format}`).join(',')}
          placeholder={`Arquivos aceitos: ${ACCEPT_FORMATS.join(', ')}`}
          type='file'
          onChange={(e) =>
            validateCertificateFile({
              file: e.currentTarget.files?.[0],
              callback: onFileChange,
              maxWidth: imageWidth,
              maxHeight: imageHeight,
            })
          }
        />
        <FormLabel
          display='flex'
          alignItems='center'
          w='100%'
          h='100%'
          m='0'
          p='0'
          fontSize='1rem'
          fontWeight='400'
          color='#767F89'
        >
          {!fileAndError.file
            ? `Arquivos aceitos: ${ACCEPT_FORMATS.join(', ')}`
            : `${typeof fileAndError.file !== 'string' ? fileAndError.file?.name : fileAndError.file}`}
        </FormLabel>
        <Icon as={BsFillCloudUploadFill} width='20px' height='20px' ml='auto' />
      </Flex>
      {fileAndError.error?.isError && (
        <Text fontSize='0.75rem' color='red.600' mt='4px'>
          {fileAndError.error.message}
        </Text>
      )}
    </FormControl>
  )
}
