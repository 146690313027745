import React, { useState } from 'react'
import Drawer from 'src/components/Drawer'
import { useAddContentDrawer } from 'src/context/CustomPlaylist/AddContentDrawerContext'
import Content from 'src/modules/cms/domain/Content'
import { ContentType } from 'src/modules/cms/domain/LearningItem'
import KnowMoreAddDrawer from './KnowMoreAddDrawer'
import Pages from './Pages'
import Skeleton from './Skeleton'

const AddLearningItemDrawer: React.FC = () => {
  const {
    isAddContentDrawerOpen,
    toggleAddContentDrawer,
    setRecommendedAddContentsIds,
    addContentKnowMoreDrawer,
    setNewContents,
  } = useAddContentDrawer()

  const [items, setItems] = useState<Content[]>([])
  const [contentType, setContentType] = useState<ContentType | null>(null)

  // const isRecommendedLearningItemsEmpty = !recommendedAddContentsIds.length

  // useQuery<ReturnGetLearningItems>(GET_LEARNING_ITEMS, {
  //   skip: isRecommendedLearningItemsEmpty,
  //   variables: { ids: recommendedAddLearningItemsIds },
  //   onCompleted: (data: ReturnGetLearningItems) => {
  //     const learningItems = data.learningItems
  //     const courses = getCoursesList(learningItems)
  //     const videos = getVideosList(learningItems)

  //     const isCoursesEmpty = !courses.length
  //     const isVideosEmpty = !videos.length

  //     setContentType(!isCoursesEmpty ? ContentType.COURSE : !isVideosEmpty ? ContentType.VIDEO : ContentType.ARTICLE)
  //     setItems(learningItems)
  //   },
  // })

  const handleDrawerClose = () => {
    toggleAddContentDrawer()
    setNewContents([])
    setRecommendedAddContentsIds([])
    setItems([])
    setContentType(null)
  }

  if (!isAddContentDrawerOpen) return null

  const isLoading = !items.length && !contentType

  return (
    <>
      <Drawer onOutsideClick={handleDrawerClose} overflow='unset' zIndex='1001' data-testid='add-learning-item-drawer'>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Pages items={items} handleDrawerClose={handleDrawerClose} contentType={contentType} />
        )}
      </Drawer>
      {addContentKnowMoreDrawer && <KnowMoreAddDrawer />}
    </>
  )
}

export default AddLearningItemDrawer
