import { UseCase } from 'src/types/UseCase'
import { Team } from '../entities/Team'
import { TeamRepository } from '../repository/TeamRepository'

export type DeleteTeamInput = {
  teamId: string
}

export type DeleteTeamOutput = Team

export class DeleteTeamUseCase implements UseCase<DeleteTeamInput, DeleteTeamOutput> {
  constructor(private repository: TeamRepository) {}

  async execute(args: DeleteTeamInput): Promise<DeleteTeamOutput> {
    const team = await this.repository.deleteTeam(args)
    return team
  }
}
