import { Box, Button, Flex, Icon, Modal, ModalContent, ModalOverlay, Spinner, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaArrowRight } from 'react-icons/fa6'
import { IoCloseSharp } from 'react-icons/io5'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { useUser } from 'src/context/userContext'
import { makeGetPlaylistSurvey } from 'src/modules/cms/factories/makeGetPlaylistSurvey'
import { makeSavePlaylistSurvey } from 'src/modules/cms/factories/makeSavePlaylistSurvey'
import { ISurvey } from 'src/modules/cms/types/ISurvey'
import Storage from 'src/services/storage'

interface Props {
  onClose: () => void
  isOpen: boolean
}

export const EvaluateCustomPlaylistModal: React.FC<Props> = ({ onClose, isOpen }: Props) => {
  const [t] = useTranslation()
  const getSurvey = makeGetPlaylistSurvey()
  const { evaluationSource } = useCustomPlaylist()
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [survey, setSurvey] = useState<ISurvey[]>([])
  const [answer, setAnswer] = useState<number | null>(null)
  const [questionIndex, setQuestionIndex] = useState(0)
  const currentQuestion = survey?.[questionIndex]
  const { user } = useUser()

  const handleSelectOption = (optionId: number) => {
    setAnswer(optionId)
  }

  const handleSubmit = async () => {
    const saveSurvey = makeSavePlaylistSurvey()

    setIsSubmitting(true)
    const hasSaved = await saveSurvey.execute({ surveyId: currentQuestion.id, optionId: Number(answer) })
    setIsSubmitting(false)

    if (survey.length > 1 && questionIndex !== 1 && hasSaved) {
      setQuestionIndex(1)

      return
    }

    if (evaluationSource === 'playlist_created') Storage.customPlaylistEvaluation.set('true')
    onClose()
  }

  useEffect(() => {
    getSurvey
      .execute({ surveyCode: evaluationSource })
      .then((data) => setSurvey(data))
      .finally(() => setIsLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluationSource])

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} autoFocus={false} scrollBehavior='outside' isCentered>
        <ModalOverlay>
          <ModalContent
            borderRadius='30px'
            maxW='550px'
            minH='550px'
            position='relative'
            padding='40px 20px 0 20px'
            overflow='visible'
          >
            <Icon
              w='20px'
              h='20px'
              position='absolute'
              top='25px'
              right='25px'
              color='bluishGray.300'
              cursor='pointer'
              as={IoCloseSharp}
              onClick={onClose}
            />
            <Box flex={1} w='100%' mt='8px' pb='10px'>
              {isLoading ? (
                <Flex h='450px' align='center' justify='center'>
                  <Spinner thickness='4px' speed='0.45s' emptyColor='gray.100' color='primaryColor' size='xl' />
                </Flex>
              ) : (
                <>
                  <Flex gap='8px'>
                    <Flex h='fit-content' align='center' gap='2px'>
                      <Text color='primaryColor'>{questionIndex + 1}</Text>
                      <Icon color='primaryColor' w='12px' h='12px' as={FaArrowRight} />
                    </Flex>
                    <Text fontFamily='Poppins' fontSize='18px'>
                      <Flex
                        dangerouslySetInnerHTML={{ __html: currentQuestion?.description }}
                        sx={{
                          div: {
                            p: {
                              fontSize: '1rem',
                              color: '#',
                              ':first-child': {
                                display: user?.language !== 'es' ? 'block' : 'none',
                              },
                              ':last-child': {
                                display: user?.language === 'es' ? 'block' : 'none',
                              },
                            },
                          },
                        }}
                      />
                    </Text>
                  </Flex>
                  <Flex gap='8px' mt='16px' flexWrap='wrap'>
                    {currentQuestion?.options?.map((item: { description: string; id: number }) => (
                      <Flex
                        key={item.id}
                        w='100%'
                        maxW='250px'
                        h='80px'
                        align='center'
                        justify='center'
                        borderRadius='4px'
                        bgColor='rgba(0, 150, 57, 0.1)'
                        border='1px solid'
                        borderColor='primaryColors.650'
                        cursor='pointer'
                        onClick={() => handleSelectOption(item.id)}
                        boxShadow={answer === item.id ? 'rgba(0, 150, 57, 0.8) 0px 0px 0px 2px inset' : 'none'}
                      >
                        <Text
                          fontFamily='Poppins'
                          textAlign='center'
                          fontWeight={700}
                          fontSize='0.875rem'
                          color='primaryColors.650'
                        >
                          <Flex
                            dangerouslySetInnerHTML={{ __html: item?.description }}
                            sx={{
                              div: {
                                p: {
                                  fontSize: '1rem',
                                  textTransform: 'capitalize',
                                  color: '#009028',
                                  ':first-child': {
                                    display: user?.language !== 'es' ? 'block' : 'none',
                                  },
                                  ':last-child': {
                                    display: user?.language === 'es' ? 'block' : 'none',
                                  },
                                },
                              },
                            }}
                          />
                        </Text>
                      </Flex>
                    ))}
                  </Flex>

                  <Button w='100px' mt='16px' isDisabled={!answer} isLoading={isSubmitting} onClick={handleSubmit}>
                    {survey.length > 1 && questionIndex === 0 ? t('continue') : t('home.Custom Playlist.send')}
                  </Button>
                </>
              )}
            </Box>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  )
}
