import { Avatar, Box, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import Content from 'src/modules/cms/domain/Content'
import { Instructor } from 'src/types/Instructor'

interface Props {
  content: Content | undefined
  margin?: number
}

const InstructorsPage: React.FC<Props> = ({ content, margin }: Props) => {
  return (
    <Box marginLeft={margin || '29px'} marginRight={margin || '29px'}>
      {content?.instructors?.map((instructor: Instructor, index: number) => (
        <Flex
          key={instructor.name}
          flexDirection='column'
          padding='24px'
          gap='8px'
          borderRadius='16px'
          border='2px solid #E5E5E5'
          marginTop={index && '16px'}
        >
          <Flex alignItems='center'>
            {instructor?.image ? (
              <Image src={instructor?.image?.url} width='56px' height='56px' borderRadius='100px' />
            ) : (
              <Avatar size='md' w='56px' h='56px' name={instructor.name} fontWeight='400' />
            )}
            <Text
              fontFamily='Poppins'
              fontWeight='700'
              fontSize='16px'
              lineHeight='150%'
              color='gray.800'
              marginLeft='16px'
            >
              {instructor?.name}
            </Text>
          </Flex>
          {instructor?.description && (
            <Text lineHeight='150%' color='gray.800' marginTop='8px'>
              {instructor?.description}
            </Text>
          )}
        </Flex>
      ))}
    </Box>
  )
}

export default InstructorsPage
