import { UseCase } from "src/types/UseCase";
import { AreaRepository, DeleteExistingAreaInput } from "../repository/AreaRepository";

type DeleteExistingAreaAreaOutput = boolean

export class DeleteExistingAreaArea implements UseCase<DeleteExistingAreaInput, DeleteExistingAreaAreaOutput>{
  constructor(private areaRepository: AreaRepository){}

  async execute(args: DeleteExistingAreaInput): Promise<DeleteExistingAreaAreaOutput> {
      const wasDeleted = await this.areaRepository.deleteExistingArea({ 
        areaId: args?.areaId,
      })
      return wasDeleted
    }
}