import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import PaginatedCourses from 'src/components/PaginatedCourses/PaginatedCourses'
import SideFilter from 'src/components/SideFilter'
import { ISkillTree, useExploreContext } from 'src/context/exploreContext'
import { useUser } from 'src/context/userContext'
import useExplore from 'src/hooks/useExplore'
import { useWindowSize } from 'src/hooks/useWindowSize'
import Content from 'src/modules/cms/domain/Content'
import { useContents } from 'src/modules/cms/hooks/useContents'
import CategoriesHeader from 'src/pages/Categories/components/CategoriesHeader'
import { PageInfo } from 'src/types/PageInfo'

export interface CategoriesParams {
  category: string
  subcategory: string
}

const Categories: React.FC = () => {
  const activeSlug = useParams<CategoriesParams>()
  const { getNameBySubcategorySlug } = useExplore()
  const { setSkillTreeData } = useExploreContext()
  const { listContents } = useContents()
  const { user } = useUser()

  const [loading, setLoading] = useState(false)
  const [learningItems, setLearningItems] = useState<Content[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo | undefined>(undefined)
  const [currentPage, setCurrentPage] = useState<number>(0)

  const { width } = useWindowSize()
  const { t } = useTranslation()
  const history = useHistory()

  const defineNumberOfCards = (currentWidth: number) => {
    if (currentWidth <= 1211) {
      return 1
    } else if (currentWidth > 1211 && currentWidth <= 1569) {
      return 2
    } else if (currentWidth > 1569 && currentWidth <= 1920) {
      return 3
    } else {
      return 3
    }
  }

  const numberOfCardsRow = defineNumberOfCards(width as number)
  const numberOfLearningItems = numberOfCardsRow * 5

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const runListContents = () => {
    setLoading(true)
    setLearningItems([])

    listContents(
      user!,
      { limit: numberOfLearningItems, skip: currentPage * numberOfLearningItems },
      { skills: [activeSlug.subcategory], order: { field: 'title', direction: 'asc' }, where: '', status: 'PUBLISHED' },
    )
      .then((data) => {
        setLearningItems(data.contents)
        setPageInfo({ currentStart: 0, totalEntries: data.totalContents })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!activeSlug.subcategory) history.push('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!activeSlug.subcategory || loading) return
    setCurrentPage(0)
    runListContents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSlug.subcategory])

  useEffect(() => {
    runListContents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  useEffect(() => {
    return () => setSkillTreeData({} as ISkillTree)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex flexDirection='row' alignItems={'flex-start'} w='100%' paddingLeft='20px'>
      <SideFilter />
      <Flex
        flexDirection={'column'}
        justifyContent='flex-start'
        paddingLeft='40px'
        marginLeft='5px'
        borderLeft={'1px solid #D9D9D9'}
        h='calc(100vh - 96px)'
        overflowY='auto'
        flex='2'
        paddingRight='52px'
      >
        <CategoriesHeader loadingState={!user || loading} name={getNameBySubcategorySlug(activeSlug.category!)} />

        {!loading && (!learningItems || learningItems?.length === 0) ? (
          <Flex w='full' flexDir='column' alignItems='center' justifyContent={'center'} h='800px'>
            <Text marginY='50px' fontSize={'18px'}>
              {t('common.notAvailableInThisCategory')}
            </Text>
            <Image src='/assets/images/empty.svg' width='360px' />
          </Flex>
        ) : (
          <>
            <Box>
              <PaginatedCourses
                courses={user ? learningItems : []}
                pageInfo={pageInfo!}
                currentPage={currentPage}
                onChangeCurrentPage={setCurrentPage}
                numberOfCardsRow={numberOfCardsRow}
              />
            </Box>
          </>
        )}
      </Flex>
    </Flex>
  )
}

export default Categories
