import { Flex, Skeleton } from '@chakra-ui/react'

const SwapSkeleton = () => {
  return (
    <Flex direction='column' height='100%'>
      <Skeleton w='80%' h='38px' borderRadius='30px' mb='24px' />

      <Flex flex='1' flexDir='column'>
        <Skeleton w='100%' h='25%' borderRadius='30px' mb='24px' />
        <Skeleton w='100%' h='25%' borderRadius='30px' mb='24px' />
        <Skeleton w='100%' h='25%' borderRadius='30px' mb='24px' />
        <Skeleton w='100%' h='25%' borderRadius='30px' />
      </Flex>
    </Flex>
  )
}

export default SwapSkeleton
