import { Flex, FormControl, FormLabel, Switch, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface CheckboxProps {
  isChecked: boolean
  onCheck: React.Dispatch<React.SetStateAction<boolean>>
  label?: string
}

/**
 * Render a checkbox with a label.
 * @param isChecked The checkbox state (React State).
 * @param onCheck The callback function to change the checkbox state (React State).
 * @param label The label to be displayed. Optional
 */
export function LabeledCheckbox({ isChecked, onCheck, label }: CheckboxProps) {
  const [t] = useTranslation()
  return (
    <FormControl display='flex' alignItems='center'>
      {label && (
        <FormLabel fontSize='1rem' fontWeight='700' mb='0'>
          {label}
        </FormLabel>
      )}
      <Flex gap='8px' align='center' justifyContent='center'>
        <Switch colorScheme='green' id='email-alerts' isChecked={isChecked} onChange={() => onCheck((prev) => !prev)} />
        <Text fontWeight='500' fontSize='1rem'>
          {isChecked ? t('yes') : t('no')}
        </Text>
      </Flex>
    </FormControl>
  )
}
