import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import FeedbackModal from 'src/components/FeedbackModal'
import { useUser } from 'src/context/userContext'
import { Area } from 'src/types/Area'

type UserInfo = {
  name: string
  company: string | undefined
  area: string
}

type FeedbackState = {
  step: number
}

interface Value {
  shouldBeVisible: boolean
  feedbackState: FeedbackState
  updateStep: (step: number) => void
  close: () => void
  openFeedback: (params: FeedbackState) => void
  userInfo: UserInfo
}

interface Props {
  children: React.ReactNode[] | React.ReactNode
}

const FeedbackContext = createContext({} as Value)

export function FeedbackProvider({ children }: Props) {
  const { user } = useUser()

  const [shouldBeVisible, setShouldBeVisible] = useState(false)
  const [feedbackState, setFeedbackState] = useState({} as FeedbackState)
  const [userInfo, setUserInfo] = useState({} as UserInfo)

  useEffect(() => {
    let area = ''

    user?.company?.areas.forEach((item: Area) => {
      if (item.id.toString() === user.area.id?.toString()) area = item.name
    })

    if (user) {
      setUserInfo({
        name: user.name,
        company: user.company?.name,
        area,
      })
    }
  }, [user])

  const updateStep = (step: number) => {
    setFeedbackState((prevState) => ({ ...prevState, step }))
  }

  const close = useCallback(() => {
    setShouldBeVisible(false)
  }, [])

  const openFeedback = useCallback(({ step }: FeedbackState) => {
    setFeedbackState({ step })
    setShouldBeVisible(true)
  }, [])

  return (
    <FeedbackContext.Provider
      value={{
        shouldBeVisible,
        feedbackState,
        userInfo,
        updateStep,
        close,
        openFeedback,
      }}
    >
      {children}
       {shouldBeVisible && <FeedbackModal onClose={close} isOpen={shouldBeVisible} />}
    </FeedbackContext.Provider>
  )
}

export const useFeedback = () => useContext(FeedbackContext)
