import { UseCase } from 'src/types/UseCase'
import { ReportRepository } from '../repository/ReportRepository'

export type GenerateReportUrlUseCaseInput = {
  date?: string
  area?: number[]
  users?: number[]
  subsidiaries?: number[]
  playlistId?: string
  type: string
}

export type GenerateReportUrlUseCaseOutput = any

export class GenerateReportUrlUseCase
  implements UseCase<GenerateReportUrlUseCaseInput, GenerateReportUrlUseCaseOutput>
{
  constructor(private repository: ReportRepository) {}

  async execute(args: GenerateReportUrlUseCaseInput): Promise<GenerateReportUrlUseCaseOutput> {
    const report = await this.repository.generateReportUrl(args)
    return report
  }
}
