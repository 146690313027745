import { UseCase } from 'src/types/UseCase'
import { Team } from '../entities/Team'
import { TeamRepository } from '../repository/TeamRepository'

export type AddTeamManagersInput = {
  teamId: string
  managersIds: number[]
}

export type AddTeamManagersOutput = Team

export class AddTeamManagersUseCase implements UseCase<AddTeamManagersInput, AddTeamManagersOutput> {
  constructor(private repository: TeamRepository) {}

  async execute(args: AddTeamManagersInput): Promise<AddTeamManagersOutput> {
    const team = await this.repository.addTeamManagers(args)
    return team
  }
}
