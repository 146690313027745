import { SlideFade, useDisclosure } from '@chakra-ui/react'
import { useEffect } from 'react'

interface StepTransitionProps {
  children: React.ReactNode
}

export function StepTransition({ children }: StepTransitionProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    onOpen()
    return () => {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SlideFade
      in={isOpen}
      offsetX='90px'
      offsetY='0px'
      transition={{
        exit: { delay: 0.5 },
        enter: { duration: 0.5, type: 'spring', bounce: 0.2 },
      }}
    >
      {children}
    </SlideFade>
  )
}
