import { Flex, FormControl, Input, Text, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { makeAddNewUsersToArea } from 'src/modules/dashboard/factory/makeAddNewUsersToArea'
import { makeCreateNewArea } from 'src/modules/dashboard/factory/makeCreateNewArea'
import { User } from 'src/types/User'
import { ManagerListView } from '../../commonComponents/ManagerListView'
import { NavigationButtons } from '../../commonComponents/NavigationButtons'
import { StepTitle } from '../../commonComponents/StepTitle'
import { StepTransition } from '../../commonComponents/StepTransition'
import { UserList } from '../../commonComponents/UserList'

export function AddNewAreaConfirmAreaInfo() {
  const [t] = useTranslation()
  const [loading, setLoading] = useState(false)
  const { navigateToStep, area, handleFinalMessage } = useHandleArea()

  const createNewArea = makeCreateNewArea()
  const addNewUsersToArea = makeAddNewUsersToArea()

  const toast = useToast()
  const sortAreaUsersByNameAsc = (users: User[]) => users.sort((a, b) => (a?.name ?? '').localeCompare(b?.name ?? ''))

  const handleSubmitNewArea = async () => {
    setLoading(true)
    try {
      const createdArea = await createNewArea.execute({
        areaName: area?.name ?? '',
        managerId: Number(area?.newManager?.id ?? area?.manager?.id) ?? -1,
      })
      if (!createdArea?.id) {
        throw new Error()
      }
      const failedUsers = await addNewUsersToArea.execute({
        areaId: createdArea?.id ?? -1,
        usersIds: area?.newUsers?.map((u) => Number(u.id)).filter((id) => !isNaN(id)) ?? [],
        usersEmails: area?.newUsers?.map((item) => item.email) ?? [],
      })
      if (failedUsers.length === area?.newUsers?.length) {
        toast({
          title: t('unableToRegisterEmployeesInTheArea'),
          description: t('unableToRegisterSomeEmployeessInTheArea', {
            amount: area?.newUsers?.length,
            areaName: area?.name,
          }),
          status: 'warning',
          duration: 7000,
          isClosable: true,
        })
      } else if (failedUsers.length > 0) {
        toast({
          title: t('someEmployeesWereNotRegisteredInTheArea'),
          status: 'warning',
          description: `Um total de ${failedUsers.length} ${
            failedUsers.length <= 1 ? 'colaborador' : 'colaboradores'
          } não foram cadastrados na área.`,
          duration: 7000,
          isClosable: true,
        })
      }
      handleFinalMessage(t('areaCreated', { areaName: area?.name }))
      navigateToStep('success-screen')
    } catch (err: any) {
      toast({
        title: t('errorCreatingArea'),
        description: String(err?.message),
        status: 'error',
        isClosable: true,
        duration: 7000,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <StepTransition>
      <Flex flexDir='column'>
        <StepTitle title={t('confirmationOfAssignments')} />
        <Flex flexDir='column' mt='24px' gap='24px'>
          <FormControl display='flex' flexDir='column' gap='4px'>
            <Text fontSize='1rem' fontWeight='700'>
              {t('areaName')}
            </Text>
            <Input
              fontSize='1rem'
              color='#767F89'
              value={area?.name ?? t('invalidAreaName')}
              readOnly
              borderRadius='8px'
              px='16px'
              height='36px'
            />
          </FormControl>
          <ManagerListView manager={area?.newManager} />
          <Flex flexDir='column' gap='8px'>
            <Flex gap='6px'>
              <Text fontSize='1rem' fontWeight='700'>
                {t('areaMembers')}
              </Text>
              <Text fontSize='1rem'>({area?.newUsers?.length ?? 0} colaboradores)</Text>
            </Flex>
            <UserList
              data={sortAreaUsersByNameAsc(area?.newUsers ?? [])}
              withSelectButton={false}
              withFilter={false}
              withSearch={false}
            />
          </Flex>
        </Flex>
        <NavigationButtons
          isLoading={loading}
          onBackClick={() => navigateToStep('add-new-area--select-users')}
          onContinueClick={handleSubmitNewArea}
        />
      </Flex>
    </StepTransition>
  )
}
