import { datadogLogs } from '@datadog/browser-logs'
import appConfig from 'src/config'

export function initLogger() {
  datadogLogs.init({
    clientToken: appConfig.datadog.clientToken,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: `${appConfig.applicationName}-${appConfig.env}`,
    env: appConfig.env,
  })
}

datadogLogs.setGlobalContext({ env: appConfig.env })
datadogLogs.setGlobalContextProperty('referrer', document.referrer)

export const logger = {
  info: (msg: string, obj?: Record<string, unknown>) => {
    datadogLogs.logger.info(msg, obj)
  },
  error: (msg: string, obj?: Record<string, unknown>) => {
    datadogLogs.logger.error(msg, obj)
  },
  warn: (msg: string, obj?: Record<string, unknown>) => {
    datadogLogs.logger.warn(msg, obj)
  },
  debug: (msg: string, obj?: Record<string, unknown>) => {
    datadogLogs.logger.debug(msg, obj)
  },
  setUser: (user: { id?: number; name?: string; email?: string; companyId?: number }) => {
    datadogLogs.setUser({ id: String(user?.id), name: user?.name, email: user?.name, companyId: user?.companyId })
  },
  clearUser: () => {
    datadogLogs.clearUser()
  },
  requestError: (requestId: string, error: Record<string, string>, message: string) => {
    datadogLogs.logger.error(`request_id ${requestId} ${message}`, { requestId, error })
  },
}
