import { TFunction } from 'react-i18next'
import { LevelType } from 'src/types/LevelType'
import { Competences } from 'src/types/QuizResults'

const checkIsAbilitySelected = (ability: string, selectedAbilities: string[]) => {
  return selectedAbilities.indexOf(ability) !== -1
}

const generateLevelsObject = (skills: string[], startingSkills?: LevelType[]): LevelType[] => {
  return skills.map((skill: string, index: number) => {
    return {
      title: skill.split(':')[0],
      level: Number(skill.split(':')[1]),
      minLevel: startingSkills ? startingSkills[index].level : undefined,
    }
  })
}

const generateLevelsString = (skills: LevelType[]): string[] => {
  return skills.map((skill: LevelType) => {
    return `${skill.title}:${skill.level}`
  })
}

const convertCompetencesStringsToEnum = (
  competences: string[],
  t: TFunction<'translation', undefined>,
): Competences[] => {
  const competencesStringsToEnumList = {
    [t('quiz.stepTwo.options.leadership')]: Competences.LEADERSHIP,
    [t('quiz.stepTwo.options.cooperation')]: Competences.COOPERATION,
    [t('quiz.stepTwo.options.influence')]: Competences.INFLUENCE,
    [t('quiz.stepTwo.options.scenarioSnalysis')]: Competences.SCENARIO_ANALYSIS,
    [t('quiz.stepTwo.options.innovation')]: Competences.INOVATION,
    [t('quiz.stepTwo.options.execution')]: Competences.EXECUTION,
    [t('quiz.stepTwo.options.adaptation')]: Competences.ADAPTATION,
    [t('quiz.stepTwo.options.achievement')]: Competences.REALIZATION,
  }

  // @ts-ignore
  const list = competences.map((competence: string): Competences => competencesStringsToEnumList[competence])
  return list
}

const convertCompetencesEnumToStrings = (
  competences: Competences[],
  t: TFunction<'translation', undefined>,
): string[] => {
  const competencesEnumToStringList = {
    LEADERSHIP: t('quiz.stepTwo.options.leadership'),
    COOPERATION: t('quiz.stepTwo.options.cooperation'),
    INFLUENCE: t('quiz.stepTwo.options.influence'),
    SCENARIO_ANALYSIS: t('quiz.stepTwo.options.scenarioSnalysis'),
    INOVATION: t('quiz.stepTwo.options.innovation'),
    EXECUTION: t('quiz.stepTwo.options.execution'),
    ADAPTATION: t('quiz.stepTwo.options.adaptation'),
    REALIZATION: t('quiz.stepTwo.options.achievement'),
  }

  // @ts-ignore
  const list = competences.map((competence: Competences): string => competencesEnumToStringList[competence.toString()])
  return list
}

export {
  checkIsAbilitySelected,
  generateLevelsObject,
  generateLevelsString,
  convertCompetencesStringsToEnum,
  convertCompetencesEnumToStrings,
}
