export const renderBrowser = () => {
  console.log(navigator.userAgent, navigator.vendor)

  if (/Edge/.test(navigator.userAgent) || /Google Inc/.test(navigator.vendor) || /OPR/.test(navigator.userAgent)) {
    return 'Supported'
  } else if (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) {
    return 'Safari'
  } else {
    return 'Not supported'
  }
}
