import { Text } from '@chakra-ui/react'
import { useImportedContents } from 'src/pages-admin/Cms/hooks/useImportedContents'

export function CloseImportedContentModal() {
  const { onCloseEditImportedContentsModal } = useImportedContents()

  return (
    <Text
      onClick={onCloseEditImportedContentsModal}
      cursor='pointer'
      textDecoration='underline'
      fontWeight='700'
      color='#767f89'
      fontFamily='Poppins'
    >
      Voltar
    </Text>
  )
}
