import { CloseIcon } from '@chakra-ui/icons'
import { Flex, SlideFade, Text, useDisclosure } from '@chakra-ui/react'
import { useCallback, useEffect } from 'react'
import { MdSignalWifiStatusbarConnectedNoInternet1 } from 'react-icons/md'

export function NoConnection() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleStatusChange = useCallback(() => {
    if (navigator.onLine) {
      onClose()
    } else {
      onOpen()
    }
  }, [onOpen, onClose])

  useEffect(() => {
    window.addEventListener('online', handleStatusChange)
    window.addEventListener('offline', handleStatusChange)

    return () => {
      window.removeEventListener('online', handleStatusChange)
      window.removeEventListener('offline', handleStatusChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <Flex
      id='no-connection-warning-component'
      display={isOpen ? 'flex' : 'none'}
      position='absolute'
      zIndex={1000}
      top='12px'
      left='0px'
      right='0px'
      maxW='350px'
      margin='0 auto'
      align='center'
      justify='center'
    >
      <SlideFade in={isOpen} offsetY='-20px'>
        <Flex
          w='100%'
          bg='red.300'
          align='center'
          justify='center'
          borderRadius='16px'
          padding='12px'
          boxShadow='0px 8px 8px rgba(0, 0, 0, 0.375)'
        >
          <Flex gap='12px' marginLeft='auto'>
            <MdSignalWifiStatusbarConnectedNoInternet1 size={24} color='#efefef' />
            <Text color='white' fontWeight='bold' fontSize='1.125rem' cursor='default'>
              Sem conexão de internet
            </Text>
          </Flex>
          <Flex marginLeft='auto' onClick={() => onClose()} padding='8px' cursor='pointer'>
            <CloseIcon color='white' width='10px' height='10px' />
          </Flex>
        </Flex>
      </SlideFade>
    </Flex>
  )
}
