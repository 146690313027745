// @ts-nocheck
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import Ability from 'src/components/AbilitiesQuiz/components/Ability'
import OtherSkills from 'src/components/AbilitiesQuiz/components/OtherSkills'
import { useQuiz } from 'src/context/QuizContext'
import ButtonsFooter from './ButtonsFooter'
import StepHeader from './StepHeader'
import subCategoriesSkillsRelation from './subcategoriesSkillsRelation'
import { checkIsAbilitySelected } from './utils'

interface Props {
  onContinueButtonClick: () => void
  onBackButtonClick: () => void
  onSkipButtonClick: () => void
}

interface SubCategoryProps {
  title: string
  defaultSkills: string[]
  otherSkills: string[]
  selectedSkills: string[]
  onSkillClick: (ability: string) => void
}

const SubCategory: React.FC<SubCategoryProps> = ({
  title,
  defaultSkills,
  otherSkills,
  selectedSkills,
  onSkillClick,
}: SubCategoryProps) => {
  const [t] = useTranslation()

  return (
    <Box marginBottom='16px'>
      <Text color='gray.800' fontFamily='Poppins' fontWeight='600'>
        {title}
      </Text>
      <Flex flexWrap='wrap'>
        {defaultSkills.map((skill: string) => (
          <Ability
            key={`${title}-${skill}`}
            name={t(`quiz.skills.${skill}`)}
            active={checkIsAbilitySelected(skill, selectedSkills)}
            removeX={true}
            activationHandler={() => onSkillClick(skill)}
          />
        ))}
        {selectedSkills.map((skill: string) => {
          const isOtherSkill = checkIsAbilitySelected(skill, otherSkills)
          if (isOtherSkill)
            return (
              <Ability
                key={`${title}-${skill}`}
                name={t(`quiz.skills.${skill}`)}
                active={true}
                removeX={false}
                activationHandler={() => onSkillClick(skill)}
              />
            )
          else return null
        })}
        {otherSkills.length ? (
          <OtherSkills onSelectSkill={onSkillClick} options={otherSkills} selectedSkills={selectedSkills} />
        ) : null}
      </Flex>
    </Box>
  )
}

const StepFour: React.FC<Props> = ({ onContinueButtonClick, onBackButtonClick, onSkipButtonClick }: Props) => {
  const { quizResults, updateQuiz, sendMutationQuiz } = useQuiz()
  const [t] = useTranslation()

  const skills = quizResults.skillsStartingPoint
    ? quizResults.skillsStartingPoint.map((skill: string) => skill.split(':')[0])
    : []

  const onSkillClick = (skill: string) => {
    const isSkillSelected = checkIsAbilitySelected(skill, skills)

    if (!isSkillSelected) {
      if (skills.length === 6) return
      updateQuiz('skillsStartingPoint', [...(quizResults.skillsStartingPoint || []), `${skill}:1`])
      updateQuiz('skillsEndingPoint', [...(quizResults.skillsEndingPoint || []), `${skill}:1`])
      return
    }

    const newSkills = quizResults.skillsStartingPoint?.filter((skillFilter: string) => {
      const skillName = skillFilter.split(':')[0]
      return skill !== skillName
    })

    updateQuiz('skillsStartingPoint', newSkills)
    updateQuiz('skillsEndingPoint', newSkills)
  }

  const handleNextStep = () => {
    sendMutationQuiz()
    onContinueButtonClick()
  }

  return (
    <>
      <StepHeader step={4} />
      <Box flex='1'>
        <Heading size='xs'>{t('quiz.stepFour.importantToolsAndMethodologies')}</Heading>
        <Text marginTop='8px' marginBottom='16px' color='bluishGray.500'>
          {t('quiz.stepFour.studyProgram')}
        </Text>
        {quizResults.themes?.map((theme: string) => {
          // @ts-ignore
          const title = t(`quiz.themes.${theme}`)
          return (
            <SubCategory
              defaultSkills={subCategoriesSkillsRelation[theme].default}
              otherSkills={subCategoriesSkillsRelation[theme].other}
              title={title as string}
              selectedSkills={skills}
              onSkillClick={onSkillClick}
            />
          )
        })}
      </Box>
      <ButtonsFooter
        step={4}
        onSkipButtonClick={onSkipButtonClick}
        disabledButton={!skills.length}
        onContinueButtonClick={handleNextStep}
        onBackButtonClick={onBackButtonClick}
      />
    </>
  )
}

export default StepFour
