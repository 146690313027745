import { Box, Flex, Text } from '@chakra-ui/react'
import { Widget } from '@typeform/embed-react'
import { useTranslation } from 'react-i18next'
import { useEvaluate } from 'src/context/EvaluateContext'
import { ContentType } from 'src/modules/cms/domain/LearningItem'
import StepHeader from './StepHeader'

const CourseStep = () => {
  const [t] = useTranslation()
  const { contentInfo, evaluateState, updateStep, userInfo } = useEvaluate()
  const islastPlaylistLearningItem = evaluateState.source === 'lastPlaylistLearningItem'
  const isplaylistLearningItemFinished = evaluateState.source === 'playlistLearningItemFinished'
  const isLearningItemFinished = evaluateState.source === 'recentlyAccessedLearningItem'
  const isCourse = [ContentType.COURSE, 'OldCourse', 'CourseDto'].includes(contentInfo.type)

  const handleCourseStep = () => {
    if (islastPlaylistLearningItem) {
      updateStep(2)
    } else {
      updateStep(3)
    }
  }

  return (
    <Flex flex={1} flexDir='column' alignItems='center' justifyContent='start'>
      <Flex flexDir='column' alignItems='center' flex={1}>
        {islastPlaylistLearningItem && <StepHeader step={1} />}

        <Text fontFamily='Poppins' fontWeight='semibold' fontSize={20} padding='0 20px' textAlign='center'>
          {t('evaluate.contentThoughts', { learningItemName: contentInfo.title })}
        </Text>

        <Box flex={1} w='100%' mt='8px' pb='10px'>
          <Widget
            id={
              islastPlaylistLearningItem || isplaylistLearningItemFinished || isLearningItemFinished
                ? 'GykR6P7i'
                : 'APzA9zEH'
            }
            hidden={{
              name: userInfo.name,
              email: userInfo.email,
              company: userInfo.company!,
              areas: userInfo.area,
              course_name: contentInfo.title,
              partner: isCourse ? contentInfo.partner?.name ?? '' : '',
              evaluation_source: evaluateState.source,
            }}
            style={{ width: '100%', height: '550px' }}
            className='my-form'
            onSubmit={handleCourseStep}
          />
        </Box>
      </Flex>
    </Flex>
  )
}

export default CourseStep
