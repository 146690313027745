import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { User } from 'src/types/User'
import { NavigationButtons } from '../../commonComponents/NavigationButtons'
import { StepTitle } from '../../commonComponents/StepTitle'
import { StepTransition } from '../../commonComponents/StepTransition'
import { UserList } from '../../commonComponents/UserList'

export function EditExistingAreaChangeManager() {
  const [t] = useTranslation()
  const [selectedUser, setSelectedUser] = useState<User[] | undefined>(undefined)
  const { area, handleSetAreaValues, navigateToStep, usersList, getUsersList } = useHandleArea()

  useEffect(() => {
    handleSetAreaValues({ newManager: selectedUser?.[0] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser])

  useEffect(() => {
    getUsersList({ variables: { areaId: 0 } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StepTransition>
      <Flex flexDir='column' gap='16px'>
        <StepTitle
          title={t('selectNewAreaManager', { areaName: area?.name ?? t('invalidArea') })}
          subtitle={t('searchAndSelectNewAreaManager')}
        />
        <UserList
          type='select-manager'
          data={usersList.filter((u) => u.email !== (area?.manager?.email ?? area?.newManager?.email))}
          onSelectUsers={setSelectedUser}
        />
        <NavigationButtons
          isContinueDisabled={!selectedUser || selectedUser?.length <= 0}
          onContinueClick={() => {
            navigateToStep('edit-existing-area--confirm-assignments-change-manager')
          }}
          onBackClick={() => {
            handleSetAreaValues({ newManager: undefined })
            navigateToStep('edit-existing-area--select-options')
          }}
        />
      </Flex>
    </StepTransition>
  )
}
