import { Button, Flex, Tooltip } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'

interface Props {
  disabledButton?: boolean
  backButtonCallback?: () => void
  nextButtonCallback?: () => void
  updateStep?: () => void
  showTooltip?: boolean
  tooltipLabel?: string
}

const ButtonsFooter: React.FC<Props> = ({
  disabledButton = false,
  nextButtonCallback,
  backButtonCallback,
  updateStep,
  showTooltip,
  tooltipLabel,
}: Props) => {
  const { step, handleNextStep, handleStepBack } = useCustomPlaylistQuiz()
  const [t] = useTranslation()

  const handleNext = () => {
    if (nextButtonCallback) nextButtonCallback()

    if (updateStep) {
      updateStep()
    } else {
      handleNextStep()
    }
  }

  const handleBack = () => {
    if (backButtonCallback) {
      backButtonCallback()
    } else {
      handleStepBack()
    }
  }

  return (
    <>
      {step !== 0 && step !== 7 && (
        <Flex align='center' justifyContent={step === 1 ? 'flex-end' : 'space-between'} mt='18px' mb='48px'>
          {step !== 1 && (
            <Button
              variant='unstyled'
              textDecoration='underline'
              _focus={{ outline: 'none', boxShadow: 'none' }}
              color='bluishGray.500'
              onClick={handleBack}
              data-testid='custom-playlist-quiz-prev-step'
            >
              {t('quiz.footer.back')}
            </Button>
          )}
          <Tooltip
            hasArrow
            shouldWrapChildren
            bg='gray.50'
            color='gray.800'
            p='16px 14px'
            placement='top'
            borderRadius='4px'
            boxShadow='0px 4px 6px rgba(0, 0, 0, 0.15);'
            label={showTooltip ? (tooltipLabel ? tooltipLabel : t('areYouSureYouWantToProceedWithoutReviewing')) : null}
          >
            <Button
              variant='startCourseDark'
              height='48px'
              padding='12px 24px'
              disabled={disabledButton}
              onClick={handleNext}
              data-testid='custom-playlist-quiz-next-step'
            >
              {t('quiz.footer.continue')}
            </Button>
          </Tooltip>
        </Flex>
      )}
    </>
  )
}

export default ButtonsFooter
