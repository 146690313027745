import { UseCase } from "src/types/UseCase";
import { User } from "src/types/User";
import { UserRepository } from "../repository/UserRepository";

export class GetUserInfoUseCase implements UseCase<unknown, User | null> {
  constructor(private userRepository: UserRepository) {}

  async execute(): Promise<User | null> {
    const userInfo = await this.userRepository.getUserInfo()
    return userInfo
  }
}