import { Company } from '../domain/Company'
import { CompanyRepository } from '../../backoffice/repository/Company'

export type GetCompanyUseCaseOutput = Company

export class GetCompanyUseCase {
  constructor(private repository: CompanyRepository) {}

  async execute(): Promise<GetCompanyUseCaseOutput | undefined> {
    const company = await this.repository.getCompanyInfo()
    return company
  }
}
