interface GenerateErrorMessageProps {
  code?: string
}

interface ErrorCode {
  [key: string]: string
}

const errorCodes: ErrorCode = {
  'NOT_AUTHORIZED': 'Você não está autorizado à realizar essa ação. Por favor, contate o suporte!',
  'MANAGER_NOT_FOUND': 'Gestor não encontrado. Por favor, tente novamente!',
  'Internal error': 'Ocorreu um erro com o servidor. Por favor, tente novamente!'
}

export function generateErrorMessage({ code }: GenerateErrorMessageProps) {
  if (!code) return 'Ocorreu um erro. Por favor, tente novamente!'
  if (errorCodes[code]) return errorCodes[code]
  return 'Ocorreu um erro. Por favor, tente novamente!'
}