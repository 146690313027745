import { Team } from 'src/types/Team'
import { User } from 'src/types/User'
import { Collaborator, EngagementChart } from './Types'

const chartLabel: { [x: string]: string } = {
  HAS_NOT_CREATED_PLAYLIST: 'Não criou trilha',
  HAS_CREATED: 'Criou trilha',
  HAS_STARTED: 'Iniciou trilha',
  ONE_QUARTER_PROGRESS: 'Pelo menos 25%',
  HALF_COMPLETED: 'Pelo menos 50%',
  THREE_QUARTER_PROGRESS: 'Pelo menos 75%',
  FINISHED: 'Finalizou a trilha',
}

export const getEngagementChart = (data: EngagementChart) => {
  return data
    ? Object.entries(data)
        .map((item) => {
          if (item[0] === '__typename') return {}
          if (item[0] === 'TOTAL') return {}

          return {
            name: chartLabel[item[0]],
            Colaboradores: item[1],
            step: item[0],
          }
        })
        .filter((value) => Object.keys(value).length !== 0)
    : []
}

type Return = {
  [x: string]: {
    type: string
  }
}

export const getContentData = (type: string) => {
  const data: Return = {
    COURSE: {
      type: 'Curso',
    },
    ARTICLE: {
      type: 'Artigo',
    },
    VIDEO: {
      type: 'Vídeo',
    },
    PODCAST: {
      type: 'Podcast',
    },
  }

  return data[type]
}

export const orderCollaborators = (collaborators: Collaborator[], order: string) => {
  return collaborators.sort((a, b) => {
    const nameA = a.userName.toUpperCase()
    const nameB = b.userName.toUpperCase()

    if (order === 'NAME_A_Z') {
      return nameA < nameB ? -1 : nameA < nameB ? 1 : 0
    }

    if (order === 'NAME_Z_A') {
      return nameA > nameB ? -1 : nameA > nameB ? 1 : 0
    }

    if (order === 'INCREASING_PROGRESS') {
      if (!a.hasCreatedPlaylist) {
        if (!b.hasCreatedPlaylist) return 0
        return 1
      }
      return a.progress < b.progress ? -1 : a.progress < b.progress ? 1 : 0
    }

    if (order === 'DESCENDING_COMPLETED') {
      return a.progress > b.progress ? -1 : a.progress > b.progress ? 1 : 0
    }

    return 0
  })
}

export const filterByFunnelStep = (step: string, collaborators: Collaborator[], order: string) => {
  if (step === '' && order === '') return collaborators

  const filtered = collaborators.filter(
    (item) =>
      (step === 'HAS_NOT_CREATED_PLAYLIST' && !item.hasCreatedPlaylist) ||
      (step === 'HAS_CREATED' && item.hasCreatedPlaylist) ||
      (step === 'ONE_QUARTER_PROGRESS' && item.progress !== 0 && item.progress >= 0.25) ||
      (step === 'HALF_COMPLETED' && item.progress !== 0 && item.progress >= 0.5) ||
      (step === 'THREE_QUARTER_PROGRESS' && item.progress !== 0 && item.progress >= 0.75) ||
      (step === 'FINISHED' && item.progress === 1) ||
      step === '',
  )

  return orderCollaborators(filtered, order)
}

export const filterByNameAndId = (collaborators: Collaborator[], filterValue: string) => {
  return collaborators.filter(
    (item) =>
      item.userName.toLowerCase().includes(filterValue.toLowerCase()) ||
      item.userId.toString().toLowerCase().includes(filterValue.toLowerCase()),
  )
}

export const filterBySkill = (skill: string, status: string, collaborators: Collaborator[], order: string) => {
  if (skill === '' && order === '') return collaborators

  const filtered = collaborators.filter((item) => {
    if (!skill) return true

    const skillsNames = item.modules.map((module) => module.attributeTitle)
    const hasSkill = skillsNames.indexOf(skill) !== -1

    if (hasSkill && status) {
      let isTheSameStatus = false
      item.modules.forEach((module) => {
        if (module.attributeTitle === skill && module.status === status) isTheSameStatus = true
      })
      return isTheSameStatus
    }

    return hasSkill
  })

  return orderCollaborators(filtered, order)
}

export const getMyTeams = (teams: Team[], user: User) => {
  return teams?.filter((team) => team?.manager?.id?.toString() === user?.id?.toString()) ?? []
}

export const getAllTeams = (teams: Team[], user: User) => {
  return teams?.filter((team) => team?.manager?.id?.toString() !== user?.id?.toString()) ?? []
}
