/* eslint-disable react-hooks/rules-of-hooks */
import { useUser } from 'src/context/userContext'
import { CmsSkill } from 'src/modules/cms/domain/Skill'

type Prop = 'name'

export function renderCorrectSkillInfoByLanguage(skill?: CmsSkill, prop?: Prop) {
  const { user } = useUser()
  const language = user?.language ?? 'pt-BR'

  if(!skill || !prop) return "-"

  let translatedInfo = skill?.translations?.find((t) => t.language === language)?.[
    prop
  ]
  return translatedInfo ?? skill?.[prop]
}
