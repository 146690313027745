import { Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSwapContentDrawer } from 'src/context/CustomPlaylist/SwapContentDrawerContext'
import Content from 'src/modules/cms/domain/Content'
import StepOne from '../StepOne'
import StepThree from '../StepThree'
import StepTwo from '../StepTwo'

interface Props {
  contents: Content[]
}

const Steps: React.FC<Props> = ({ contents }: Props) => {
  const [t] = useTranslation()
  const { swapDrawerStep, closeSwapContentDrawer, setSwapDrawerStep } = useSwapContentDrawer()

  const steps = [<StepOne contents={contents} />, <StepTwo />, <StepThree />]

  useEffect(() => {
    return () => {
      setSwapDrawerStep(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {swapDrawerStep !== 2 && (
        <Text
          color='#767F89'
          textDecorationLine='underline'
          fontFamily='Poppins'
          fontWeight='600'
          marginBottom='22px'
          cursor='pointer'
          onClick={closeSwapContentDrawer}
        >
          {t('common.drawer.close')}
        </Text>
      )}
      {steps[swapDrawerStep]}
    </>
  )
}

export default Steps
