import { Image, Stack, Text } from '@chakra-ui/react'
import { Component, ReactNode } from 'react'

interface State {
  error: string
  eventId: string
  errorInfo: string
  hasError: boolean
}

interface Props {
  children: ReactNode
}

export default class ErrorBoundary extends Component<Props, State> {
  state = {
    error: '',
    eventId: '',
    errorInfo: '',
    hasError: false,
  }

  static getDerivedStateFromError(error: State) {
    return { hasError: true, error }
  }

  render() {
    return (
      <>
        {this.state.hasError ? (
          <Stack h='100vh' w='100vw' bg='white' flexDirection='column' alignItems='center' justifyContent='center'>
            <Image src='/assets/images/warning_img.svg' h='200px' />
            <Text
              fontFamily='Poppins'
              fontWeight='semibold'
              w={['90vw', '90vw', '80vw', '80vw', '80vw']}
              fontSize={['18px', '20px', '30px', '32px', '36px']}
              textAlign='center'
              color='primaryColors.400'
            >
              Ops, no meio do caminho tinha uma pedra...
            </Text>
            <Text
              fontFamily='Poppins'
              fontWeight='semibold'
              fontSize={['14px', '18px', '20px', '22px', '22px']}
              textAlign='center'
              w={['80vw', '80vw', '50vw', '50vw', '50vw']}
              color='gray.800'
              paddingBottom='40px'
            >
              Parece que tivemos um problema, mas não se preocupe. Volte para a Página Inicial para seguir sua jornada.
            </Text>
            <Stack
              flexDirection='row'
              alignItems='center'
              bg='primaryColors.400'
              color='white'
              paddingX='20px'
              borderRadius='20px'
              fontWeight='800'
              paddingBottom='8px'
              paddingTop='8px'
            >
              <a href='https://app.education-journey.com/'>Voltar para a Página Inicial</a>
            </Stack>
          </Stack>
        ) : (
          this.props.children
        )}
      </>
    )
  }
}
