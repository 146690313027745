import { Box } from '@chakra-ui/react'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import Content from 'src/modules/cms/domain/Content'

interface Props {
  content: Content | undefined
  margin?: number
}

const DescriptionPage: React.FC<Props> = ({ content, margin }: Props) => {
  return (
    <Box marginLeft={margin || '29px'} marginRight={margin || '29px'}>
      <ReactMarkdown className='course-modal-react-markdown' children={content!.description!} />
      {/* <CourseModalTags course={content!} /> */}
    </Box>
  )
}

export default DescriptionPage
