import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { BaseSyntheticEvent, useEffect, useState } from 'react'
import { CourseModalProvider, useCourseModal } from 'src/context/CourseModalContext'
import Content from 'src/modules/cms/domain/Content'
import CourseModalBody from './CourseModalBody'
import CourseModalHeader from './CourseModalHeader'
import CourseModalInfo from './CourseModalInfo'
import './styles.scss'

interface Props {
  initialCourse?: Content
  courseId?: string
  onClose: () => void
  setCourse?: (course: Content) => void
  isOpen: boolean
}

const CourseModal: React.FC<Props> = ({
  initialCourse,
  courseId,
  onClose,
  isOpen,
  setCourse,
}: Props) => {
  const { courseToLoad, resetCourseToLoad, loadContent, loadContentById, course } = useCourseModal()
  const [scrolled, setScrollState] = useState(false)
  const [lastPathname, setLastPathname] = useState('')

  const handleScroll = (e: BaseSyntheticEvent) => {
    const { scrollTop, firstChild } = e.target

    if (scrollTop > firstChild.firstChild.clientHeight * 0.75) {
      setScrollState(true)
    }
    if (scrollTop < firstChild.firstChild.clientHeight * 0.75) {
      setScrollState(false)
    }
  }

  useEffect(() => {
    setScrollState(false)
    setLastPathname(window.location.pathname)
    if (isOpen) {
      const containerInterval = setInterval(() => {
        const container = document.querySelector('.chakra-modal__content-container')
        if (container) {
          //@ts-ignore
          container?.addEventListener('scroll', handleScroll)
          clearInterval(containerInterval)
        }
      }, 100)
    }
  }, [isOpen])

  useEffect(() => {
    if (!courseToLoad && isOpen && initialCourse) {
      loadContent(initialCourse)
    }

    if (!courseToLoad && isOpen && courseId) {
      loadContentById(courseId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const getLastPathname = () => {
    const isLasPathNameCourse = lastPathname.indexOf('course') !== -1
    if (isLasPathNameCourse) return '/home'
    return lastPathname
  }

  const removeCourseURL = () => {
    window.history.pushState(null, '', getLastPathname())
  }

  const onModalClose = () => {
    removeCourseURL()
    resetCourseToLoad()
    onClose()
  }

  return (
    <>
      <Modal onClose={onModalClose} isOpen={isOpen} autoFocus={false} scrollBehavior='outside'>
        <ModalOverlay>
          <ModalContent borderRadius='30px' w='80%' maxW='836px'>
            <CourseModalHeader
              course={course || initialCourse || courseToLoad}
              courseUpdated={initialCourse || courseToLoad}
              onClose={onModalClose}
              scrolled={scrolled}
              setCourse={setCourse}
            />
            <CourseModalInfo />
            <CourseModalBody />
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  )
}

const CourseModalWithContext: React.FC<Props> = ({
  initialCourse,
  courseId,
  onClose,
  isOpen,
  setCourse,
}: Props) => (
  <CourseModalProvider>
    <CourseModal
      initialCourse={initialCourse}
      courseId={courseId}
      onClose={onClose}
      isOpen={isOpen}
      setCourse={setCourse}
    />
  </CourseModalProvider>
)

export default CourseModalWithContext
