import { gql } from '@apollo/client'

const GET_DASH_USER_INFO = gql`
  query dashboardUserInfo($where: DashboardUserInfoWhere) {
    dashboardUserInfo(where: $where) {
      totalStartedCourses
      totalFinishedCourses
      totalStudiedTime
      items {
        companyName
        userName
        userId
        userArea
        courseName
        courseTotalProgress
        courseWeeklyProgress
        courseTotalHours
        courseWeeklyHours
        startedCourse
        date
        coursePartner
      }
    }
  }
`

export default GET_DASH_USER_INFO
