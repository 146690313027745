import { useTranslation } from 'react-i18next'
import LearningItemDrawer from 'src/components/ContentDrawer'
import { useSwapContentDrawer } from 'src/context/CustomPlaylist/SwapContentDrawerContext'

const KnowMoreSwapDrawer = () => {
  const [t] = useTranslation()
  const { swapContentDrawerInfo, knowMoreSwapDrawer, setKnowMoreSwapDrawer } = useSwapContentDrawer()

  const closeKnowMoreSwapDrawer = () => setKnowMoreSwapDrawer(undefined)

  return (
    <LearningItemDrawer
      drawerInfo={{
        content: knowMoreSwapDrawer!,
        moduleTitle: swapContentDrawerInfo!.moduleTitle,
        moduleId: swapContentDrawerInfo!.moduleId,
        type: 'SWAP',
      }}
      onCloseClick={closeKnowMoreSwapDrawer}
      closeText={t('home.Custom Playlist.back')}
    />
  )
}

export default KnowMoreSwapDrawer
