import { Box, Text } from '@chakra-ui/react'
import React, { memo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useExploreContext } from 'src/context/exploreContext'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'

type Props = {
  skillId: string
  skillName: string
  rootId: string
  visibilityHandler: React.Dispatch<React.SetStateAction<boolean>>
}

const SkillLevel: React.FC<Props> = ({ skillId, skillName, visibilityHandler, rootId }) => {
  const { setSkillTreeData, skillTreeData } = useExploreContext()
  const location = useLocation()
  const { track, EventsImplementation } = useMixpanel()
  const history = useHistory()

  const handleClick = () => {
    visibilityHandler(false)
    setSkillTreeData((prevState: any) => ({ ...prevState, skillId }))
    track(EventsImplementation.ACCESS_SUBCATEGORY(location.pathname, 'Explorer', skillName))
    history.push(`/categories/${rootId}/${skillId}`)
  }

  return (
    <Box
      h='39px'
      borderRadius='16px'
      d='flex'
      alignItems='center'
      justifyContent='space-between'
      color={skillTreeData.skillId === skillId ? 'primaryColors.400' : 'gray.800'}
      paddingX='13px'
      paddingY='10px'
      cursor='pointer'
      _hover={{
        backgroundColor: 'gray.60',
      }}
      onClick={handleClick}
    >
      <Box d='flex' alignItems='center'>
        <Text
          color={skillTreeData.skillId === skillId ? 'primaryColors.400' : 'gray.800'}
          fontWeight={skillTreeData.skillId === skillId ? '700' : '400'}
          transition='all .2s'
          textDecoration='none'
          textAlign='left'
          fontSize='16px'
        >
          {skillName}
        </Text>
      </Box>
    </Box>
  )
}

export default memo(SkillLevel)
