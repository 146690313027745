import { CmsContentType } from "src/types/Cms";

export function parseCourseType(type: CmsContentType) {  
  if (type === CmsContentType.COURSE) {
    return 'curso'
  }

  if (type === CmsContentType.VIDEO) {
    return 'vídeo'
  }

  return 'artigo'
}