import { Button, Flex, Select, Text, VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNewTeamManagement } from 'src/context-admin/useNewTeamManagement'
import { usePermissions } from '../../context-admin/usePermissions'

type Props = {
  handleStepChange: (value: number) => void
}

const ManagerStep: React.FC<Props> = ({ handleStepChange }) => {
  const { selectedCollab } = usePermissions()
  const { selectedTeam, teams, handleSelectTeam } = useNewTeamManagement()
  const [t] = useTranslation()

  const filteredTeams = teams.filter((team) => team.members.some((m) => String(m.id) !== String(selectedCollab.id)))

  const handleBack = () => {
    handleStepChange(0)
  }

  const handleNextStep = () => {
    handleStepChange(4)
  }

  const handleTeamChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const chosenTeam = teams.find((team) => team.id === e.target.value)
    handleSelectTeam(chosenTeam)
  }

  return (
    <>
      <Text
        color='#767F89'
        textDecoration='underline'
        fontFamily='Poppins'
        fontWeight='600'
        marginBottom='22px'
        cursor='pointer'
        onClick={handleBack}
        textTransform='capitalize'
      >
        {t('back')}
      </Text>

      <VStack align='flex-start'>
        <Text fontSize='20px' fontWeight={600} fontFamily='Poppins' color='gray.31'>
          {t('assignmentOf')} {t('admin.common.manager')}
        </Text>
        <Text color='#767F89' mb='24px'>
          {t('youAreAssigningCollabAsTeamRole', { collabName: selectedCollab.name, role: t('admin.common.Manager') })}
        </Text>

        <Flex direction='column' w='100%' align='flex-start'>
          <Text textAlign='center' fontWeight={700} lineHeight='175%'>
            {t('team')}
          </Text>
          <Select
            defaultValue={'emptyValue'}
            value={selectedTeam?.id}
            onChange={handleTeamChange}
            data-testid='select-team-input'
          >
            <option value='emptyValue' disabled>
              {t('selectNewTeam')}
            </option>
            {filteredTeams?.map((team) => (
              <option key={team.id} value={team.id}>
                {team.name}
              </option>
            ))}
          </Select>
        </Flex>

        <Flex w='100%' justify='space-between' mt='24px !important'>
          <Button variant='whiteOutline' fontSize='12px' onClick={handleBack}>
            {t('back')}
          </Button>

          <Button variant='solidGray' fontSize='12px' disabled={!selectedTeam} onClick={handleNextStep}>
            {t('login.continue')}
          </Button>
        </Flex>
      </VStack>
    </>
  )
}

export default ManagerStep
