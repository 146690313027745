import { gql } from '@apollo/client'

export const GET_CONTENT = gql`
  query content($id: String!) {
    content(id: $id) {
      id
      contentfulId
      title
      type
      description
      duration
      url
      imageUrl
      allowsEmbedding
      language
      languages
      subtitles
      proficiencyLevel {
        name
        color
      }
      partner {
        name
        squareLogoUrl
        logoUrl
        description
        coverColor
        id
      }
      allowedCompanyIds
      skills {
        id
        name
      }
      trailerUrl
      status
      updatedAt
      translations {
        content_id
        title
        description
        language
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
