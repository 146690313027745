import { PlaylistRepository } from '../repositories/PlaylistRepository'

interface UpdatePlaylistTranslationsUseCaseInput {
  playlistId: string
  language: 'es'
  title: string
  description: string
}

export type UpdatePlaylistTranslationsUseCaseOutput = boolean

export class UpdatePlaylistTranslationsUseCase {
  constructor(private playlistRepository: PlaylistRepository) {}

  async execute(input: UpdatePlaylistTranslationsUseCaseInput): Promise<UpdatePlaylistTranslationsUseCaseOutput> {
    const content = {
      language: input.language,
      title: input.title,
      description: input.description,
      playlistId: input.playlistId,
    }

    const result = await this.playlistRepository.updatePlaylistTranslations(content)
    return result
  }
}
