import { useCallback, useEffect, useState } from 'react'
import { useNewTeamManagement } from 'src/context-admin/useNewTeamManagement'
import { usePermissions } from '../../context-admin/usePermissions'
import { useTeamManagement } from '../../context-admin/useTeamManagement'
import Drawer from '../Drawer'
import ConfirmationStep from './ConfirmationStep'
import ConfirmChanges from './ConfirmChanges'
import ManagerStep from './ManagerStep'
import SelectCollabs from './SelectCollabs'
import SelectPermissions from './SelectPermissions'

const UserPermissionsDrawer: React.FC = () => {
  const { handleTogglePermissionsDrawer, resetState } = usePermissions()
  const { resetState: resetTeamSTate } = useTeamManagement()
  const { handleSelectTeam } = useNewTeamManagement()
  const [step, setStep] = useState(0)

  useEffect(() => {
    return () => {
      resetState()
      resetTeamSTate()
      handleSelectTeam(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetState, resetTeamSTate])

  const handleStepChange = useCallback((value: number) => {
    setStep(value)
  }, [])

  const components = [
    <SelectPermissions handleStepChange={handleStepChange} />,
    <ConfirmationStep handleStepChange={handleStepChange} />,
    <ManagerStep handleStepChange={handleStepChange} />,
    <SelectCollabs handleStepChange={handleStepChange} />,
    <ConfirmChanges handleStepChange={handleStepChange} />,
    // <ManagerChangeConfirm handleStepChange={handleStepChange} />,
  ]

  return (
    <Drawer onOutsideClick={handleTogglePermissionsDrawer} width='595px'>
      {components[step]}
    </Drawer>
  )
}

export default UserPermissionsDrawer
