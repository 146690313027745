import { Box, Image, Text } from '@chakra-ui/react'
import { useUser } from 'src/context/userContext'
import { useCertificate } from '../../hooks/useCertificate'

const customPlaylistCertificatePtBr = '/assets/images/custom-playlist-certificate.svg'
const featuredPlaylistCertificatePtBr = '/assets/images/featured-playlist-certificate.svg'
const customPlaylistModuleCertificatePtBr = '/assets/images/module-custom-playlist-certificate.svg'
const customPlaylistCertificateSignPtBr = '/assets/images/custom-playlist-certificate-with-signature.svg'
const featuredPlaylistCertificateSignPtBr = '/assets/images/featured-playlist-certificate-with-signature.svg'
const customPlaylistModuleCertificateSignPtBr = '/assets/images/module-custom-playlist-certificate-with-signature.svg'

const customPlaylistCertificateEs = '/assets/images/custom-playlist-certificate-es.svg'
const featuredPlaylistCertificateEs = '/assets/images/featured-playlist-certificate-es.svg'
const customPlaylistModuleCertificateEs = '/assets/images/module-custom-playlist-certificate-es.svg'
const customPlaylistCertificateSignEs = '/assets/images/custom-playlist-certificate-with-signature-es.svg'
const featuredPlaylistCertificateSignEs = '/assets/images/featured-playlist-certificate-with-signature-es.svg'
const customPlaylistModuleCertificateSignEs = '/assets/images/module-custom-playlist-certificate-with-signature-es.svg'

const certificates: Record<string, Record<string, string>> = {
  customPlaylistCertificate: {
    'pt-BR': customPlaylistCertificatePtBr,
    es: customPlaylistCertificateEs,
  },
  featuredPlaylistCertificate: {
    'pt-BR': featuredPlaylistCertificatePtBr,
    es: featuredPlaylistCertificateEs,
  },
  customPlaylistModuleCertificate: {
    'pt-BR': customPlaylistModuleCertificatePtBr,
    es: customPlaylistModuleCertificateEs,
  },
  customPlaylistCertificateSign: {
    'pt-BR': customPlaylistCertificateSignPtBr,
    es: customPlaylistCertificateSignEs,
  },
  featuredPlaylistCertificateSign: {
    'pt-BR': featuredPlaylistCertificateSignPtBr,
    es: featuredPlaylistCertificateSignEs,
  },
  customPlaylistModuleCertificateSign: {
    'pt-BR': customPlaylistModuleCertificateSignPtBr,
    es: customPlaylistModuleCertificateSignEs,
  },
}

/**
 * Renders the certificate preview with dynamic company logo and signature, if they exists.
 */
export function CertificatePreview() {
  const { companyLogo, activeTab, signature } = useCertificate()
  const { user } = useUser()
  const userLanguage = user?.language ?? 'pt-BR'

  const renderCertificateByType = () => {
    const url: { [key: string]: string } = {
      customPlaylist: signature.shouldIncludeSignature
        ? certificates.customPlaylistCertificateSign[userLanguage]
        : certificates.customPlaylistCertificate[userLanguage],
      customPlaylistModule: signature.shouldIncludeSignature
        ? certificates.customPlaylistModuleCertificateSign[userLanguage]
        : certificates.customPlaylistModuleCertificate[userLanguage],
      featuredPlaylist: signature.shouldIncludeSignature
        ? certificates.featuredPlaylistCertificateSign[userLanguage]
        : certificates.featuredPlaylistCertificate[userLanguage],
    }

    return url[activeTab]
  }

  const createImageUrlFromFile = (image: File | string) => {
    if (typeof image === 'string') return null

    const url = URL.createObjectURL(image)
    const fileName = image.name
    return { url, fileName }
  }

  const renderImageByFile = (image?: File | string) => {
    if (!image) return <></>
    const imageFile = createImageUrlFromFile(image)

    return (
      <Image
        w='120px'
        h='40px'
        src={typeof image === 'string' ? image : imageFile?.url}
        alt={imageFile?.fileName ?? 'Logo da Empresa'}
      />
    )
  }

  const renderResponsableSignature = (responsableName?: string) => {
    if (!responsableName) return <></>
    return (
      <Text
        fontSize='7px'
        color='black'
        fontFamily='Poppins'
        fontWeight='600'
        whiteSpace='nowrap'
        textOverflow='ellipsis'
        overflow='hidden'
        textAlign='center'
      >
        {responsableName}
      </Text>
    )
  }

  return (
    <Box position='relative' pointerEvents='none'>
      <Image
        minW='564px'
        w='564px'
        maxW='564px'
        objectFit='contain'
        src={renderCertificateByType()}
        alt='preview'
        pointerEvents='none'
      />
      <Box position='absolute' top='52px' left='181px' bg='white' pointerEvents='none'>
        {renderImageByFile(companyLogo.logoImage)}
      </Box>
      <Box position='absolute' bottom='75px' right='160px' bg='white' pointerEvents='none'>
        {renderImageByFile(signature.signatureImage)}
      </Box>
      <Box position='absolute' w='100%' maxW='127px' bottom='58px' right='157px' bg='white' pointerEvents='none'>
        {renderResponsableSignature(signature.responsableName)}
      </Box>
    </Box>
  )
}
