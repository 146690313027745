import { Flex } from '@chakra-ui/layout'
import StepBox, { StepBoxProps } from '../../components-admin/onboard/StepBox'
import { Step, steps } from './utils'

interface StepsProps {
  currentStepSlug: Step['slug']
}

const Steps: React.FC<StepsProps> = ({ currentStepSlug }: StepsProps) => {
  const renderSteps = () => {
    let ListSteps: JSX.Element[] | null

    let currentStatus: StepBoxProps['status']
    let passedCurrent = false
    ListSteps = steps.map((step, index) => {
      if (currentStepSlug === step.slug) {
        currentStatus = 'doing'
        passedCurrent = true
      } else if (passedCurrent === false) {
        currentStatus = 'done'
      } else {
        currentStatus = 'todo'
      }
      return <StepBox step={step} status={currentStatus} hasTail={index > 0} />
    })
    return ListSteps
  }
  return (
    <Flex
      backgroundColor='white'
      boxShadow='0 4px 4px rgba(0, 0, 0, .05)'
      padding='20px'
      justifyContent='center'
      zIndex='2'
    >
      <Flex>{renderSteps()}</Flex>
    </Flex>
  )
}

export default Steps
