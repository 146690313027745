import { ContentRepository } from 'src/modules/cms/repositories/ContentRepository'
import { IContentData } from 'src/pages-admin/Cms/hooks/useRegisterContentStore'
import { convertTimeToSeconds } from 'src/pages-admin/Cms/utils/convertTimeToSeconds'
import { CmsContentType } from 'src/types/Cms'

type CreateContentUseCaseInput = IContentData

export type CreateContentUseCaseOutput = string

export class CreateContentUseCase {
  constructor(private contentRepository: ContentRepository) {}

  async execute(input: CreateContentUseCaseInput): Promise<CreateContentUseCaseOutput> {
    const payload: IContentData = {
      title: input.title,
      type: input.type,
      url: input.url,
      description: input.description,
      duration: convertTimeToSeconds(String(input.duration) ?? '0'),
      languages: input.languages,
      subtitles: input.subtitles?.some((item) => item === 'no-subtitle') ? [] : input.subtitles!,
      allowedCompanyIds: input.allowedCompanyIds,
      imageUrl: input?.imageUrl ?? 'https://i.ibb.co/fS8fCDS/dummy-300x300-009136-ffffff-education-journey.png',
      skills: input.skills?.map((item) => item.value),
      ...(input.type === CmsContentType.COURSE && { hasCertificate: input.issuesCertificate }),
      ...(input.type === CmsContentType.COURSE && { trailerUrl: input.trailer }),
      ...((input.type === CmsContentType.COURSE || input.type === CmsContentType.VIDEO) && {
        partnerId: input.partner,
      }),
      status: 'PUBLISHED',
    }

    const createdContentId = await this.contentRepository.create(payload)
    return createdContentId
  }
}
