import { CertificateDto } from 'src/modules/iam/use-case/GetUserCertificatesUseCase'
import { create } from 'zustand'

interface PlaylistState {
  certificate: CertificateDto
  updateCertificate: (certificate: CertificateDto) => void
}

export const usePlaylistStore = create<PlaylistState>((set) => ({
  certificate: {} as CertificateDto,
  updateCertificate: (certificate: CertificateDto) => set(() => ({ certificate })),
}))
