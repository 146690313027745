import { PlaylistRepository } from '../repositories/PlaylistRepository'

export interface ReplacePlaylistPoliciesUseCaseInput {
  playlistId: string
  teamIds: string[]
}

export type ReplacePlaylistPoliciesUseCaseOutput = boolean

export class ReplacePlaylistPoliciesUseCase {
  constructor(private playlistRepository: PlaylistRepository) {}

  async execute(input: ReplacePlaylistPoliciesUseCaseInput): Promise<ReplacePlaylistPoliciesUseCaseOutput> {
    if (!input.playlistId) return false
    const wasReplaced = await this.playlistRepository.replacePlaylistPolicies(input)
    return wasReplaced
  }
}
