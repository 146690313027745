import { Flex } from '@chakra-ui/react'
import React from 'react'

interface CloseProps {
  handler: () => void
}

const Close: React.FC<CloseProps> = () => {
  return (
    <Flex
      alignItems='center'
      justifyContent='center'
      background='#7ECD9D'
      color='#009028'
      borderLeftRadius='18px'
      w='40px'
      h='100%'
      fontWeight='800'
      transition={'all .09s ease-in-out'}
      _hover={{
        color: 'white',
      }}
    >
      X
    </Flex>
  )
}

export default Close
