import { PlaylistRepository } from 'src/modules/cms/repositories/PlaylistRepository'

interface LogPlaylistAccessUseCaseInput {
  playlistId: string
}

export type LogPlaylistAccessUseCaseOutput = boolean

export class LogPlaylistAccessUseCase {
  constructor(private playlistRepository: PlaylistRepository) {}

  async execute({ playlistId }: LogPlaylistAccessUseCaseInput): Promise<LogPlaylistAccessUseCaseOutput> {
    const hasAccess = await this.playlistRepository.logAccess(playlistId)
    return hasAccess
  }
}
