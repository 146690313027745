import { Flex, Icon, Text, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsExclamationCircle } from 'react-icons/bs'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { makeRemoveUsersFromExistingArea } from 'src/modules/dashboard/factory/makeRemoveUsersFromExistingArea'
import { User } from 'src/types/User'
import { NavigationButtons } from '../../commonComponents/NavigationButtons'
import { StepTitle } from '../../commonComponents/StepTitle'
import { StepTransition } from '../../commonComponents/StepTransition'
import { UserList } from '../../commonComponents/UserList'

export function EditExistingAreaConfirmRemoveUsers() {
  const [t] = useTranslation()
  const [loading, setLoading] = useState(false)
  const { area, navigateToStep, handleSetAreaValues, handleFinalMessage } = useHandleArea()

  const removeUsersFromArea = makeRemoveUsersFromExistingArea()
  const toast = useToast()

  const handleRemoveSelectedUsersFromArea = (users: User[]) => {
    const usersIds = users?.map((u) => u?.id)
    handleSetAreaValues({
      newUsers: area?.newUsers?.filter((u) => !usersIds?.includes(u?.id)) ?? [],
    })
  }

  const handleSubmitRemoveUsers = async () => {
    setLoading(true)
    try {
      if (!area?.id) throw new Error('A área selecionada é inválida. Por favor, tente novamente!')
      const failedUsers = await removeUsersFromArea.execute({
        areaId: Number(area?.id) ?? -1,
        usersIds: area?.newUsers?.map((u) => u.id) ?? [],
      })
      if (failedUsers.length > 0) {
        toast({
          title: t('someContributorsWereNotRemoved'),
          description: t('problemRemovingContributors', { amount: failedUsers.length }),
          status: 'warning',
          isClosable: true,
          duration: 7000,
        })
      }
      handleFinalMessage(t('collaboratorsRemoved', { areaName: area?.name ?? t('invalidArea') }))
      navigateToStep('success-screen')
    } catch (err: any) {
      toast({
        title: t('errorRemovingCollaborators'),
        description: String(err?.message),
        status: 'error',
        isClosable: true,
        duration: 7000,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <StepTransition>
      <Flex flexDir='column' gap='16px'>
        <StepTitle
          title={t('youAreRemovingManager', { areaName: area?.name ?? t('invalidArea') })}
          subtitle={t('checkAndConfirmCollaboratorsRemoval', { areaName: area?.name ?? t('invalidArea') })}
        />
        {!area?.newUsers ||
          (area?.newUsers?.length <= 0 && (
            <Flex
              gap='16px'
              align='center'
              borderRadius='12px'
              borderWidth='2px'
              borderColor='#efefef'
              px='16px'
              py='4px'
            >
              <Icon as={BsExclamationCircle} w='16px' color='bluishGray.600' />
              <Text fontWeight='600' fontSize='14px' color='bluishGray.600'>
                {t('mustSelectAtLeastOneUserToProceed')}
              </Text>
            </Flex>
          ))}
        <UserList
          data={area?.newUsers ?? []}
          selectText={t('cancel')}
          selectedText={t('canceled')}
          withFilter={false}
          withSearch={false}
          onSelectUsers={handleRemoveSelectedUsersFromArea}
        />
      </Flex>
      <NavigationButtons
        isContinueDisabled={!area?.newUsers || area?.newUsers?.length <= 0}
        isLoading={loading}
        onContinueClick={() => {
          handleSubmitRemoveUsers()
        }}
        onBackClick={() => {
          navigateToStep('edit-existing-area--remove-users')
        }}
      />
    </StepTransition>
  )
}
