import { Flex, FormControl, Input, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { NavigationButtons } from '../../commonComponents/NavigationButtons'
import { StepTitle } from '../../commonComponents/StepTitle'
import { StepTransition } from '../../commonComponents/StepTransition'

export function AddNewArea() {
  const [t] = useTranslation()
  const [areaName, setAreaName] = useState('')
  const { navigateToStep, handleSetAreaValues } = useHandleArea()

  return (
    <StepTransition>
      <Flex flexDir='column'>
        <Flex flexDir='column' gap='24px'>
          <StepTitle title={t('giveTheAreaAName')} subtitle={t('createANameForTheNewArea')} />
          <FormControl display='flex' flexDir='column' gap='4px'>
            <Text fontWeight='bold' fontSize='1rem'>
              {t('areaName')}
            </Text>
            <Input
              placeholder='Área 1'
              borderRadius='12px'
              height='36px'
              w='100%'
              px='16px'
              m='0'
              borderWidth='2px'
              borderColor='#E5E5E5'
              value={areaName}
              onChange={(e) => setAreaName(e.target.value)}
            />
          </FormControl>
        </Flex>
        <NavigationButtons
          onContinueClick={() => {
            handleSetAreaValues({ name: areaName })
            navigateToStep('add-new-area--select-manager')
          }}
          onBackClick={() => navigateToStep('choose-options')}
          isDisabled={areaName.length <= 1}
        />
      </Flex>
    </StepTransition>
  )
}
