import { CertificateRepository } from "../repositories/CertificateRepository";
import httpClient from "src/services/http/http-client";
import { UploadCertificateCompanyLogoUseCase } from "../use_cases/UploadCertificateCompanyLogoUseCase";
import { getAccessToken } from "src/modules/iam/apis/store";

export function makeUploadCertificateCompanyLogo() {
  const token = getAccessToken()
  const certificateRepository = new CertificateRepository(token, httpClient)
  return new UploadCertificateCompanyLogoUseCase(certificateRepository)
}
