import { Flex, Img, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHandleTeam } from 'src/context-admin/useHandleTeam'
import { User } from 'src/types/User'

interface ManagerListProps {
  users?: User[]
  shouldRenderButtons?: boolean
}

export function ManagerList({ users, shouldRenderButtons = true }: ManagerListProps) {
  const [t] = useTranslation()
  const [selectedUsersToRemove, setSelectedUsersToRemove] = useState<User[]>([])
  const { usersList, loadingUsers, fetchMoreUsers, pageInfo, team, handleSetTeamValues } = useHandleTeam()

  const userListToRender = users ?? usersList
  const currentManagers = team?.managers ?? []

  const handleSelectUser = (user: User) => {
    if (selectedUsersToRemove?.find((selectedUser) => selectedUser?.id === user?.id)) {
      setSelectedUsersToRemove((prev) => prev.filter((selectedUser) => selectedUser?.id !== user?.id))
    } else {
      setSelectedUsersToRemove((prev) => [...prev, user])
    }
  }

  const shouldRenderCurrentManagerLabel = (user: User) => {
    if (!currentManagers?.find((manager) => +manager?.id === +user?.id)) return false
    return true
  }

  useEffect(() => {
    if (shouldRenderButtons) {
      handleSetTeamValues({ newManagers: selectedUsersToRemove })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsersToRemove, shouldRenderButtons])
  
  return (
    <Flex flexDir='column' w='100%' gap='16px' maxH='220px' overflowY='auto' pr='8px'>
      {!loadingUsers && (
        <InfiniteScroll
          dataLength={userListToRender?.length}
          next={fetchMoreUsers}
          hasMore={pageInfo?.hasNextPage!}
          style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '6px 0px' }}
          loader={
            <Text textAlign='center' display={userListToRender?.length === 0 ? 'none' : 'block'}>
              {t('loadingMore')}
            </Text>
          }
          scrollThreshold={0.9}
          scrollableTarget='scrollableDiv'
        >
          {userListToRender.map((user, index) => {
            const isUserSelected =
              selectedUsersToRemove?.findIndex((selectedUser) => selectedUser?.id === user?.id) !== -1
            return (
              <Flex
                key={user?.id ?? index}
                align='center'
                px='16px'
                py='8px'
                bg='#F7F9FA'
                borderRadius='12px'
                boxShadow='0px 1px 6px 0px rgba(0, 0, 0, 0.10)'
              >
                <Img src='https://fakeimg.pl/150x150' alt='Colaborator image' w='24px' borderRadius='999px' />
                <Flex align='center' gap='8px'>
                  <Text fontWeight='700' color='green.500' fontSize='0.75rem' m='0' p='0' ml='8px'>
                    {user?.name ?? t('invalidName')}
                  </Text>
                  {shouldRenderCurrentManagerLabel(user) && (
                    <Text fontSize='0.75rem' color='#767f89'>
                      ({t('currentManager')})
                    </Text>
                  )}
                </Flex>
                {shouldRenderButtons && (
                  <Text
                    textDecoration='underline'
                    fontSize='0.75rem'
                    fontWeight='600'
                    ml='auto'
                    cursor='pointer'
                    color={isUserSelected ? 'red.500' : 'gray.800'}
                    onClick={() => handleSelectUser(user)}
                  >
                    {isUserSelected ? t('selected') : t('remove')}
                  </Text>
                )}
              </Flex>
            )
          })}
        </InfiniteScroll>
      )}
    </Flex>
  )
}
