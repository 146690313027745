import { Button, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { DrawerHandleArea } from '../../DrawerHandleArea'
import ModalUpdateEmployees from '../../ModalUpdateEmployees'

const UpdateEmployees = () => {
  const [t] = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { onOpenHandleAreaDrawer } = useHandleArea()

  return (
    <>
      <Flex direction='column' w='100%' justifyContent='space-between'>
        <Text fontFamily='Poppins' fontWeight='semibold' fontSize='24px' mb='40px'>
          {t('admin.home.updateEmployees.title')}
        </Text>
        <Flex
          align='center'
          justify='space-between'
          h='68px'
          boxShadow='0px 1px 10px rgba(0, 0, 0, 0.1)'
          borderRadius='16px'
          p='16px 24px 16px 31px'
        >
          <Flex align='center' gridGap='53px'>
            <Text fontWeight={700} fontSize='18px'>
              {t('admin.home.employeeSection.title')}
            </Text>
          </Flex>
          <Button variant='solidGray' onClick={onOpen}>
            <Text fontFamily='Poppins' fontWeight='bold' fontSize='12px'>
              {t('admin.home.employeeSection.button')}
            </Text>
          </Button>
        </Flex>
        <Flex
          align='center'
          justify='space-between'
          h='68px'
          boxShadow='0px 1px 10px rgba(0, 0, 0, 0.1)'
          borderRadius='16px'
          p='16px 24px 16px 31px'
          mt='16px'
        >
          <Flex align='center' gridGap='53px'>
            <Text fontWeight={700} fontSize='18px'>
              {t('areas')}
            </Text>
          </Flex>
          <Button variant='solidGray' onClick={onOpenHandleAreaDrawer}>
            <Text fontFamily='Poppins' fontWeight='bold' fontSize='12px'>
              {t('updateAreas')}
            </Text>
          </Button>
        </Flex>
      </Flex>

      <ModalUpdateEmployees onClose={onClose} isOpen={isOpen} />
      <DrawerHandleArea />
    </>
  )
}

export default UpdateEmployees
