import { Flex, LayoutProps } from '@chakra-ui/react'
import React from 'react'

interface Props {
  children: any
  onOutsideClick?: () => void
  overflow?: LayoutProps['overflowY']
  zIndex?: string
  width?: string
}

const Drawer: React.FC<Props> = ({ children, onOutsideClick, overflow, zIndex, width }: Props) => {
  return (
    <>
      <Flex
        position='absolute'
        w='100%'
        h='100%'
        background='#313E4C'
        opacity='0.6'
        top='0'
        left='0'
        zIndex='1000'
        onClick={onOutsideClick}
      ></Flex>

      <Flex
        position='absolute'
        w={width ? width : { base: '90%', lg: '62%' }}
        maxW={width ? width : '700px'}
        h='100%'
        backgroundColor='white'
        top='0'
        right='0'
        zIndex={zIndex ? zIndex : '1002'}
        padding={{ base: overflow === 'unset' ? '54px 24px' : '44px 24px 24px', lg: '65px' }}
        flexDirection='column'
        maxH='100%'
        overflowY={overflow ? overflow : 'auto'}
      >
        {children}
      </Flex>
    </>
  )
}

export default Drawer
