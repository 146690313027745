import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { User } from 'src/types/User'
import { t } from 'xstate'
import { NavigationButtons } from '../../commonComponents/NavigationButtons'
import { StepTitle } from '../../commonComponents/StepTitle'
import { StepTransition } from '../../commonComponents/StepTransition'
import { UserList } from '../../commonComponents/UserList'

export function AddNewAreaSelectManager() {
  const [t] = useTranslation()
  const [selectedUser, setSelectedUser] = useState<User[]>([])
  const { navigateToStep, handleSetAreaValues, area, usersList, getUsersList } = useHandleArea()

  useEffect(() => {
    getUsersList({ variables: { areaId: 0 } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StepTransition>
      <Flex flexDir='column'>
        <StepTitle title={t('managerAssignment')} subtitle={t('selectManagerOf', { areaName: area?.name })} />
        <Flex w='100%' mt='24px'>
          <UserList data={usersList} type='select-manager' onSelectUsers={setSelectedUser} />
        </Flex>
        <NavigationButtons
          onContinueClick={() => {
            navigateToStep('add-new-area--select-users')
            handleSetAreaValues({ newManager: selectedUser[0] })
          }}
          onBackClick={() => {
            navigateToStep('add-new-area')
            handleSetAreaValues({ newManager: selectedUser[0] })
          }}
          isDisabled={selectedUser[0] === undefined}
        />
      </Flex>
    </StepTransition>
  )
}
