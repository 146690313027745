import { Box, Flex, Icon, Skeleton, SkeletonProps } from '@chakra-ui/react'
import { SliderChevron } from '../../theme/Icons'

const PreviousArrow = () => {
  return (
    <Flex
      background='linear-gradient(270deg, #FFFFFF 34.77%, rgba(255, 255, 255, 0) 93.38%)'
      w='auto'
      h='100%'
      alignItems='center'
      justifyContent='flex-start'
    >
      <Icon as={SliderChevron} w='13px' h='27px' transform='matrix(-1, 0, 0, 1, 0, 0)' color='bluishGray.200' />
    </Flex>
  )
}

const NextArrow = () => {
  return (
    <Flex
      background='linear-gradient(270deg, #FFFFFF 34.77%, rgba(255, 255, 255, 0) 93.38%)'
      position='absolute'
      right='0'
      w={{ base: '40px', md: '80px' }}
      h='100%'
      alignItems='center'
      justifyContent='flex-end'
    >
      <Icon as={SliderChevron} w='13px' h='27px' color='bluishGray.200' />
    </Flex>
  )
}

interface CardListProps extends SkeletonProps {
  children?: React.ReactNode
  numberOfCards?: number
}

const CardListSkeleton = ({ children, numberOfCards = 6, ...skeletonProps }: CardListProps) => {
  return (
    <Flex alignItems='center' gap={{ base: '12px', md: '20px' }} w='100%' overflowX='hidden'>
      {Array.from({ length: numberOfCards }).map((_, index) => (
        <Skeleton key={index} {...skeletonProps} marginLeft={{ base: '0px', md: index === 0 ? '20px' : 0 }}>
          {children}
        </Skeleton>
      ))}
    </Flex>
  )
}

export const CoursesSliderSkeleton = () => {
  return (
    <Flex alignItems='center' position='relative' overflowX='hidden'>
      <PreviousArrow />
      <CardListSkeleton numberOfCards={6} w='100%' minW='274px' maxW='274px' h='242px' borderRadius='30px'>
        <Box
          w='41px'
          h='41px'
          backgroundColor='gray.30'
          borderRadius='11px'
          visibility='unset'
          marginLeft='18px'
          marginTop='14px'
        />
      </CardListSkeleton>
      <NextArrow />
    </Flex>
  )
}

export const RecentlyAccessedSkeleton = () => {
  return (
    <Flex
      flexDirection='column'
      paddingLeft={{ base: '16px', md: '39px' }}
      paddingRight={{ base: '16px', md: '72px' }}
      width='100%'
      overflowX='hidden'
    >
      <Skeleton w='240px' h='30px' borderRadius='8px' marginLeft={{ base: '16px', md: '39px' }} marginBottom='19px' />
      <Flex alignItems='center' position='relative'>
        <PreviousArrow />
        <CardListSkeleton numberOfCards={6} w='100%' minW='274px' maxW='274px' h='153px' borderRadius='30px' />
        <NextArrow />
      </Flex>
    </Flex>
  )
}

export const MostSeenSkeleton = () => {
  return (
    <Flex
      flexDirection='column'
      paddingLeft={{ base: '16px', md: '33px' }}
      paddingRight={{ base: '16px', md: '72px' }}
      paddingTop={{ base: '24px', md: '42px' }}
    >
      <Skeleton w='240px' h='30px' borderRadius='8px' marginLeft={{ base: '16px', md: '39px' }} marginBottom='30px' />
      <CoursesSliderSkeleton />
    </Flex>
  )
}

export const PlaylistsSkeleton = () => {
  return (
    <Flex
      flexDirection='column'
      paddingLeft={{ base: '16px', md: '33px' }}
      paddingRight={{ base: '16px', md: '72px' }}
      marginBottom='30px'
    >
      <Skeleton w='240px' h='30px' borderRadius='8px' marginLeft={{ base: '16px', md: '39px' }} marginY='30px' />
      <Flex alignItems='center' position='relative' overflowX='hidden'>
        <PreviousArrow />
        <CardListSkeleton numberOfCards={6} w='100%' minW='274px' maxW='274px' h='378px' borderRadius='30px' />
        <NextArrow />
      </Flex>
    </Flex>
  )
}

interface HomeSkeletonProps {
  showRecentlyAccessedSkeletonSkeleton?: boolean
}

const HomeSkeleton: React.FC<HomeSkeletonProps> = ({ showRecentlyAccessedSkeletonSkeleton }) => {
  return (
    <Flex flexDirection='column' marginTop='15px'>
      {showRecentlyAccessedSkeletonSkeleton && <RecentlyAccessedSkeleton />}
      <MostSeenSkeleton />
      <PlaylistsSkeleton />
    </Flex>
  )
}

export default HomeSkeleton
