export function getOrderFilterField(filter: string) {
  let field = 'title'
  let direction = 'asc'

  if (filter === 'NAME_DESC' || filter === 'LATEST_UPDATE' || filter === 'LATEST_CREATE') {
    direction = 'desc'
  }

  if (filter === 'LATEST_UPDATE' || filter === 'OLDER_UPDATE') {
    field = 'updated_at'
  }

  if (filter === 'LATEST_CREATE' || filter === 'OLDER_CREATE') {
    field = 'created_at'
  }

  return {
    field,
    direction,
  }
}
