import { createContext, useContext, useEffect, useState } from 'react'
import { makeListPrivatePlaylists } from 'src/modules/cms/factories/makeListPrivatePlaylists'
import { getOrderFilterField } from '../utils/getOrderFilterField'
import { useCorporateUniversityStore } from './useCorporateUniversityStore'

interface Props {
  children: React.ReactNode[] | React.ReactNode
}

interface Values {
  isPrivatePlaylistsLoading: boolean
  handlePrevPagePlaylists: () => void
  handleNextPagePlaylists: () => void
  listPrivatePlaylists: () => Promise<void>
}

const PrivatePlaylistsContext = createContext({} as Values)

export function PrivatePlaylistsProvider({ children }: Props) {
  const {
    updateCmsPrivatePlaylists,
    limitPerPage,
    updateTotalPrivatePlaylists,
    filters,
    totalPlaylists,
    skip,
    updateSkip,
    searchValue,
    selectedPlaylistPrivacy,
  } = useCorporateUniversityStore()
  const [isLoading, setIsLoading] = useState(false)
  const isPublicPlaylist = selectedPlaylistPrivacy === 'public'

  const listPrivatePlaylists = async () => {
    const listPlaylists = makeListPrivatePlaylists()

    setIsLoading(true)

    const data = await listPlaylists.execute({
      limit: limitPerPage,
      skip,
      order: getOrderFilterField(filters.order),
      ...(filters.partnerId ? { partnerId: filters.partnerId } : {}),
      ...(filters.skill ? { skill: filters.skill } : {}),
      ...(filters.status ? { status: filters.status } : {}),
      ...(searchValue ? { title: searchValue } : {}),
    })

    updateCmsPrivatePlaylists(data?.items?.filter((item) => item?.type !== 'CUSTOM') ?? [])
    updateTotalPrivatePlaylists(data?.total ?? 0)

    setIsLoading(false)
  }

  const handlePrevPagePlaylists = () => {
    const newSkip = skip - limitPerPage <= 0 ? 0 : skip - limitPerPage
    updateSkip(newSkip)
  }

  const handleNextPagePlaylists = () => {
    const newSkip = skip + limitPerPage >= totalPlaylists ? skip : skip + limitPerPage
    updateSkip(newSkip)
  }

  useEffect(() => {
    if (!isPublicPlaylist) listPrivatePlaylists()
  }, [isPublicPlaylist, filters, searchValue, skip])

  return (
    <PrivatePlaylistsContext.Provider
      value={{
        isPrivatePlaylistsLoading: isLoading,
        listPrivatePlaylists,
        handlePrevPagePlaylists,
        handleNextPagePlaylists,
      }}
    >
      {children}
    </PrivatePlaylistsContext.Provider>
  )
}

export const useListPrivatePlaylists = () => useContext(PrivatePlaylistsContext)
