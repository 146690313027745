import { User } from 'src/types/User'

export const parseParametrizedURL = (url: string, user: User): string => {
  if (!url) return ''
  const [firstName, lastName] = user.name.split(' ')
  const replacements: { [x: string]: string } = {
    full_name: user.name,
    first_name: firstName,
    last_name: lastName,
    email: user.email,
    company_name: user.company?.name || '',
  }

  let newURL = url

  for (const replacement in replacements) {
    /* DO NOT USE REPLACE ALL - IT LEADS TO COMPATIBILITY PROBLEMS */

    const ReplacementRegexp = new RegExp(`{{${replacement}}}`, 'g')

    newURL = newURL.replace(ReplacementRegexp, replacements[replacement])
  }

  return newURL
}
