import Partner from 'src/modules/cms/domain/Partner'
import { PartnerRepository } from 'src/modules/cms/repositories/PartnerRepository'

type GetPartnerByIdUseCaseInput = {
  id: string;
}

export type GetPartnerByIdUseCaseOutput = Partner

export class GetPartnerByIdUseCase {
  constructor(private partnerRepository: PartnerRepository) {}

  async execute(input: GetPartnerByIdUseCaseInput): Promise<GetPartnerByIdUseCaseOutput> {
    const partner = await this.partnerRepository.getById(input.id)
    return partner
  }
}
