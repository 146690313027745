import httpClient from 'src/services/http/http-client'
import { getAccessToken } from '../apis/store'
import { UserRepository } from '../repository/UserRepository'

type ImportUsersInput = any

export class ImportUsersUseCase {
  constructor(private userRepository: UserRepository) {}

  async execute(args?: ImportUsersInput) {
    const importedUsers = await this.userRepository.importUsers(args)
    return importedUsers
  }
}

export function makeImportUsers() {
  const token = getAccessToken()
  const userRepository = new UserRepository(token, httpClient)
  return new ImportUsersUseCase(userRepository)
}
