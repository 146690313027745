import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { User } from 'src/types/User'
import { AccessSpreadshetMessage } from '../../commonComponents/AccessSpreadshetMessage'
import { NavigationButtons } from '../../commonComponents/NavigationButtons'
import { StepTitle } from '../../commonComponents/StepTitle'
import { StepTransition } from '../../commonComponents/StepTransition'
import { UserList } from '../../commonComponents/UserList'

export function AddNewAreaSelectUsers() {
  const [t] = useTranslation()
  const [selectedUsers, setSelectedUsers] = useState<User[]>([])
  const { navigateToStep, handleSetAreaValues, area, usersList } = useHandleArea()

  useEffect(() => {
    handleSetAreaValues({
      newUsers: selectedUsers,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers])

  return (
    <StepTransition>
      <Flex flexDir='column'>
        <StepTitle
          title={t('selectEmployessOfArea', {
            areaManager: area?.manager?.name ?? area?.newManager?.name ?? t('invalidManager'),
            areaName: area?.name ?? t('invalidArea')
          })}
        />
        <AccessSpreadshetMessage onClick={() => navigateToStep('add-new-area--select-users-file')} />
        <Flex w='100%'>
          <UserList
            data={usersList.filter((u) => u.id !== area?.newManager?.id)}
            type='select-users'
            onSelectUsers={setSelectedUsers}
          />
        </Flex>
      </Flex>
      <NavigationButtons
        onContinueClick={() => navigateToStep('add-new-area--confirm-area-info')}
        onBackClick={() => navigateToStep('add-new-area--select-manager')}
        isContinueDisabled={selectedUsers.length <= 0}
      />
    </StepTransition>
  )
}
