import { createContext, useCallback, useContext, useState } from 'react'
import { Team } from 'src/types/Team'
import { useTeams } from './useTeams'

type SelectValue = {
  value: string
  label: string
}

interface ContextValues {
  resetState: () => void
  handleTeamChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  handleTeamName: (e: React.ChangeEvent<HTMLInputElement>) => void
  selectTeam: SelectValue
  teamName: string
  selectedCollabs: any[]
  handleCollabsChange: (collab: any) => void
  updateTeamCreation: () => void
  teamHasBeenCreated: boolean
  updateManagerChange: () => void
  managerHasChanged: boolean
  teams: Team[]
  getTeamInfo: () => Team | undefined
}

type ProviderProps = {
  children: React.ReactNode[] | React.ReactNode
}

const TeamManagement = createContext({} as ContextValues)

export function TeamManagementProvider({ children }: ProviderProps) {
  const { teams } = useTeams()
  const [selectTeam, setSelectTeam] = useState({} as SelectValue)
  const [teamName, setTeamName] = useState('')
  const [selectedCollabs, setSelectedCollabs] = useState<any[]>([])
  const [teamHasBeenCreated, setTeamHasBeenCreated] = useState(false)
  const [managerHasChanged, setManagerHasChanged] = useState(false)

  const handleTeamChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value, options, selectedIndex } = e.target

    if (value === 'emptyValue') {
      setSelectTeam({} as SelectValue)

      return
    }

    setSelectTeam({
      value,
      label: options[selectedIndex].innerHTML,
    })
  }, [])

  const handleTeamName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTeamName(e.target.value)
  }, [])

  const handleCollabsChange = useCallback(
    (collab: any) => {
      const isSelected = selectedCollabs.some((item) => item.id === collab.id)

      if (isSelected) {
        setSelectedCollabs((prevState) => prevState.filter((item) => item.id !== collab.id))
      } else {
        setSelectedCollabs((prevState) => [...prevState, collab])
      }
    },
    [selectedCollabs],
  )

  const updateTeamCreation = useCallback(() => {
    setTeamHasBeenCreated((prevState) => !prevState)
  }, [])

  const updateManagerChange = useCallback(() => {
    setManagerHasChanged((prevState) => !prevState)
  }, [])

  const getTeamInfo = useCallback(() => {
    return teams?.find((item) => item.id === selectTeam.value)
  }, [teams, selectTeam])

  const resetState = useCallback(() => {
    setSelectTeam({} as SelectValue)
    setTeamName('')
    setSelectedCollabs([])
    setTeamHasBeenCreated(false)
    setManagerHasChanged(false)
  }, [])

  return (
    <TeamManagement.Provider
      value={{
        resetState,
        handleTeamChange,
        handleTeamName,
        selectTeam,
        teamName,
        selectedCollabs,
        handleCollabsChange,
        updateTeamCreation,
        teamHasBeenCreated,
        updateManagerChange,
        managerHasChanged,
        teams,
        getTeamInfo,
      }}
    >
      {children}
    </TeamManagement.Provider>
  )
}

export const useTeamManagement = () => useContext(TeamManagement)
