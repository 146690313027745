import httpClient from 'src/services/http/http-client'
import { getAccessToken } from 'src/modules/iam/apis/store'
import { ReportRepository } from '../repository/ReportRepository'
import { GetAreasUseCaseUseCase } from '../use-case/GetAreasUseCase'

export function makeGetAreas() {
  const token = getAccessToken()

  const repository = new ReportRepository(token, httpClient)
  return new GetAreasUseCaseUseCase(repository)
}