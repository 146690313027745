import { PlaylistRepository } from '../repositories/PlaylistRepository'

type ListPlaylistsPermissionsInput = {
  playlistId: string
  limit?: number
}

enum PlaylistPolicyType {
  TEAMS = 'TEAMS',
}

export type PlaylistPermission = {
  id?: number
  type?: PlaylistPolicyType | null
  playlistId?: string | null
  team?: string | null
  createdAt?: Date | null
  updatedAt?: Date | null
  deletedAt?: Date | null
}

export type ListPlaylistsPermissionsOutput = PlaylistPermission[]

export class ListPlaylistsPermissionsUseCase {
  constructor(private playlistRepository: PlaylistRepository) {}

  async execute(input: ListPlaylistsPermissionsInput): Promise<ListPlaylistsPermissionsOutput> {
    const playlistPermissions = await this.playlistRepository.listPlaylistPermissions(input)
    return playlistPermissions
  }
}
