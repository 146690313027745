import { PlaylistRepository } from 'src/modules/cms/repositories/PlaylistRepository'
import { getAccessToken } from 'src/modules/iam/apis/store'
import { cmsClient } from 'src/services/http/cms-client'
import { ListPrivatePlaylistsUseCase } from '../use_cases/ListPrivatePlaylistsUseCase'

export function makeListPrivatePlaylists() {
  const token = getAccessToken()
  const playlistRepository = new PlaylistRepository(cmsClient, token)
  return new ListPrivatePlaylistsUseCase(playlistRepository)
}
