import { gql } from '@apollo/client'

const SUBMIT_ADMIN_ACCOUNT = gql`
  mutation SubmitAdminAccount(
    $companyName: String!
    $companyTradeName: String!
    $companyAreas: [String!]!
    $companyCnpj: String!
    $name: String!
    $email: String!
    $password: String!
    $area: String!
  ) {
    createCompanyAndAdmin(
      company: { name: $companyName, tradeName: $companyTradeName, areas: $companyAreas, cnpj: $companyCnpj }
      admin: { username: $email, name: $name, password: $password, area: $area }
    ) {
      company {
        companyId
      }
      admin {
        id
      }
    }
  }
`

export default SUBMIT_ADMIN_ACCOUNT
