import { Text } from '@chakra-ui/react'

interface DeleteCustomPlaylistModalTriggerProps {
  label?: string
  onOpen: () => void
}

export function DeleteCustomPlaylistModalTrigger({
  onOpen,
  label = 'clique aqui',
}: DeleteCustomPlaylistModalTriggerProps) {
  return (
    <Text
      fontWeight='bold'
      textDecoration='underline'
      cursor='pointer'
      onClick={() => onOpen()}
      textTransform='lowercase'
    >
      {label}
    </Text>
  )
}
