import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { useWindowSize } from 'src/hooks/useWindowSize'
import Content from 'src/modules/cms/domain/Content'
import { PageInfo } from 'src/types/PageInfo'
import { SliderChevron } from '../../theme/Icons'
import { HorizontalLearningItemCard } from '../HorizontalLearningItemCard'
import { LearningItemCard } from '../LearningItemCard'
import { PaginatedCoursesSkeleton, PaginatedHorizontalCoursesSkeleton } from './PaginatedCoursesSkeleton'

interface Props {
  courses: Content[]
  pageInfo: PageInfo
  currentPage: number
  numberOfCardsPerPage?: number
  numberOfCardsRow?: number
  onChangeCurrentPage: (page: number) => void
  horizontal?: boolean
}

interface PaginationProps {
  currentPage: number
  numberOfPages: number
  onChangeCurrentPage: (page: number) => void
}

interface NumberItemProps {
  page: number
  currentPage: number
  onChangeCurrentPage: (page: number) => void
}

const NumberItem: React.FC<NumberItemProps> = ({ page, currentPage, onChangeCurrentPage }: NumberItemProps) => {
  const location = useLocation()
  const { EventsImplementation, track } = useMixpanel()

  return (
    <Flex
      borderRadius='100px'
      padding='0px 8px'
      backgroundColor={currentPage === page ? `gray.800` : ''}
      width={{ base: currentPage === page ? `32px` : '', lg: currentPage === page ? `50px` : '' }}
      height={{ base: currentPage === page ? `32px` : '', lg: currentPage === page ? `50px` : '' }}
      alignItems='center'
      justifyContent='center'
      cursor='pointer'
      onClick={() => {
        onChangeCurrentPage(page)
        track(EventsImplementation.USED_PAGINATION(location.pathname, page + 1))
      }}
    >
      <Text color={currentPage === page ? `white` : 'gray.800'} fontWeight={currentPage === page ? `600` : '400'}>
        {page + 1}
      </Text>
    </Flex>
  )
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  onChangeCurrentPage,
  numberOfPages,
}: PaginationProps) => {
  const [t] = useTranslation()
  const { EventsImplementation, track } = useMixpanel()
  const location = useLocation()
  return (
    <Flex
      w={{ base: '100%', lg: 'auto' }}
      marginTop='15px'
      marginBottom={{ base: '0px 0px 32px', lg: '0px' }}
      justifyContent='space-between'
      alignItems='center'
      fontFamily='Poppins'
      userSelect='none'
    >
      <Flex
        color='#464646'
        opacity={!currentPage ? '0.2' : '1'}
        cursor={!currentPage ? 'default' : 'pointer'}
        alignItems='center'
        onClick={() => {
          if (currentPage) {
            onChangeCurrentPage(currentPage - 1)
          }
          track(EventsImplementation.USED_PAGINATION(location.pathname, currentPage - 1))
        }}
      >
        <Icon as={SliderChevron} marginRight='6px' h='14px' transform='matrix(-1, 0, 0, 1, 0, 0)' />
        <Text fontWeight='600' fontSize={{ base: '0.875rem', lg: '1rem' }}>
          {t('pagination.previous')}
        </Text>
      </Flex>

      <Flex>
        {[...Array(numberOfPages)].map((x: number, index: number) => {
          if (numberOfPages > 6) {
            if (currentPage >= 4) {
              const startPage = currentPage - 3
              if (startPage > index) return null
              if (currentPage + 1 < index) return null
            } else {
              if (index >= 5) return null
            }

            return (
              <NumberItem
                key={Math.random()}
                currentPage={currentPage}
                onChangeCurrentPage={onChangeCurrentPage}
                page={index}
              />
            )
          }

          return (
            <NumberItem
              key={Math.random()}
              currentPage={currentPage}
              onChangeCurrentPage={onChangeCurrentPage}
              page={index}
            />
          )
        })}

        {numberOfPages > 6 &&
        !(
          currentPage === numberOfPages - 1 ||
          currentPage === numberOfPages - 2 ||
          currentPage === numberOfPages - 3
        ) ? (
          <Flex alignItems='center' justifyContent='center'>
            <Text color={'gray.800'} fontSize={{ base: '0.875rem', lg: '1rem' }}>
              ...
            </Text>
          </Flex>
        ) : null}
      </Flex>

      <Flex
        color='#464646'
        opacity={currentPage === numberOfPages - 1 ? '0.2' : '1'}
        cursor={currentPage === numberOfPages - 1 ? 'default' : 'pointer'}
        alignItems='center'
        onClick={() => {
          if (currentPage !== numberOfPages - 1) {
            onChangeCurrentPage(currentPage + 1)
          }
          track(EventsImplementation.USED_PAGINATION(location.pathname, currentPage + 1))
        }}
      >
        <Text fontWeight='600' fontSize={{ base: '0.875rem', lg: '1rem' }}>
          {t('pagination.next')}
        </Text>
        <Icon as={SliderChevron} marginLeft='6px' h='14px' />
      </Flex>
    </Flex>
  )
}
const PaginatedCourses: React.FC<Props> = ({
  courses,
  pageInfo,
  currentPage,
  numberOfCardsRow = 3,
  numberOfCardsPerPage = 10,
  onChangeCurrentPage,
  horizontal,
}: Props) => {
  const { width } = useWindowSize()
  const isMobile = useMemo(() => !!width && width < 992, [width])

  if ((!courses?.length || !pageInfo) && horizontal) return <PaginatedHorizontalCoursesSkeleton />
  if (!courses?.length || !pageInfo) return <PaginatedCoursesSkeleton numberOfCardsRow={numberOfCardsRow} />

  if (horizontal) {
    const numberOfPages = Math.ceil(pageInfo.totalEntries / numberOfCardsPerPage)
    return (
      <>
        {courses.map((course) => (
          <Box marginBottom='30px' key={course.id}>
            <HorizontalLearningItemCard content={course} showImage showMarkButtons />
          </Box>
        ))}
        {courses.length ? (
          <Pagination
            currentPage={currentPage}
            numberOfPages={numberOfPages}
            onChangeCurrentPage={onChangeCurrentPage}
          />
        ) : null}
      </>
    )
  }

  const blankArray = []
  const numberOfPages = Math.ceil(pageInfo.totalEntries / (numberOfCardsRow * 5))

  for (let i = 0; i < 5; i++) blankArray.push(numberOfCardsRow * i)

  if (isMobile) {
    return (
      <Flex flexWrap='wrap' gap='12px'>
        {courses.map((course) => (
          <Box flex='1' key={course.id}>
            <LearningItemCard
              content={course}
              style={{
                aspectRatio: '360/243',
                width: '100%',
              }}
              bodyStyle={{
                height: '100%',
              }}
              maxWidth='none'
            />
          </Box>
        ))}
        {courses.length ? (
          <Pagination
            currentPage={currentPage}
            numberOfPages={numberOfPages}
            onChangeCurrentPage={onChangeCurrentPage}
          />
        ) : null}
      </Flex>
    )
  }

  return (
    <Flex flexDirection='column' paddingTop='59px' paddingBottom='59px' width='100%'>
      {blankArray.map((x: number) => {
        const numberOfItems = courses.slice(x, x + numberOfCardsRow).length
        return (
          <Flex flexDirection='row' key={Math.random()}>
            {courses.slice(x, x + numberOfCardsRow).map((course, index) => (
              <Box marginLeft={index ? '20px' : ''} marginBottom='20px' flex='1' key={course.id}>
                <LearningItemCard
                  content={course}
                  style={{
                    aspectRatio: '360/243',
                    width: '100%',
                  }}
                  bodyStyle={{
                    height: '100%',
                  }}
                  maxWidth='none'
                />
              </Box>
            ))}
            {numberOfItems !== numberOfCardsRow
              ? [...Array(numberOfCardsRow - numberOfItems)].map(() => <Box key={Math.random()} flex='1' />)
              : null}
          </Flex>
        )
      })}
      {courses.length ? (
        <Pagination currentPage={currentPage} numberOfPages={numberOfPages} onChangeCurrentPage={onChangeCurrentPage} />
      ) : null}
    </Flex>
  )
}

export default PaginatedCourses
