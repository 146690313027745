import { Icon, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { IoCloseSharp } from 'react-icons/io5'
import { useFeedback } from 'src/context/FeedbackContext'
import FeedbackSent from './FeedbackSent'
import FeedbackStep from './FeedbackStep'

interface Props {
  onClose: () => void
  isOpen: boolean
}

const FeedbackModal: React.FC<Props> = ({ onClose, isOpen }: Props) => {
  const { feedbackState } = useFeedback()

  const steps = [<FeedbackStep />, <FeedbackSent />]

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} autoFocus={false} scrollBehavior='outside' isCentered>
        <ModalOverlay>
          <ModalContent
            borderRadius='30px'
            maxW='590px'
            minH='550px'
            position='relative'
            padding='40px 20px 0 20px'
            overflow='visible'
          >
            <Icon
              w='20px'
              h='20x'
              position='absolute'
              top='25px'
              right='25px'
              color='bluishGray.300'
              cursor='pointer'
              as={IoCloseSharp}
              onClick={onClose}
            />
            {steps[feedbackState.step]}
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  )
}

export default FeedbackModal
