import { useMutation } from '@apollo/client'
import { Button, Flex, Text, VStack } from '@chakra-ui/react'
import { useTeams } from '../../context-admin/useTeams'
import { useMixpanel } from '../../hooks-admin/mixpanel/useMixpanel'
import { ADD_TEAM_MEMBERS } from './mutations'

type Props = {
  handleComponentChange: (value: string) => void
}

const AddCollabsStepTwo: React.FC<Props> = ({ handleComponentChange }) => {
  const { selectedTeam, setSelectedTeam, addCollabs, setSuccessText, handleCollabsAdd, handleSelectedOptions } =
    useTeams()
  const { EventsImplementation, track } = useMixpanel()

  const [addTeamMembers, { loading: addMembersLoading }] = useMutation(ADD_TEAM_MEMBERS)

  const handleBack = () => {
    handleComponentChange('ADD_COLLABS')
  }

  const handleNextStep = async () => {
    const teamMemberIds = addCollabs.map((member) => Number(member.id))
    const updatedTeam = await addTeamMembers({
      variables: { teamId: selectedTeam.id, teamMemberIds },
    })
    track(EventsImplementation.EDIT_TEAM('Adicionar colaboradores', teamMemberIds.length))
    setSelectedTeam(updatedTeam.data.addTeamMembers)
    setSuccessText('Os novos integrantes foram adicionados à equipe com sucesso!')
    handleSelectedOptions('ADD_COLLABS')
    handleComponentChange('SUCCESS')
  }

  return (
    <>
      <Text
        color='#767F89'
        textDecoration='underline'
        fontFamily='Poppins'
        fontWeight='600'
        marginBottom='22px'
        cursor='pointer'
        onClick={handleBack}
      >
        Voltar
      </Text>

      <VStack align='flex-start'>
        <Text fontSize='20px' fontWeight={600} fontFamily='Poppins' color='gray.31'>
          Você está adicionando à Equipe "{selectedTeam.name}"
        </Text>
        <Text color='#767F89' mb='24px'>
          Confira e confirme se estes realmente são os novos colaboradores da Equipe "{selectedTeam.name}".
        </Text>

        <Flex id='scrollableDiv' direction='column' mt='16px' w='100%' maxH='calc(100vh - 550px)' overflowY='auto'>
          {addCollabs.map((item) => {
            return (
              <Flex
                key={item.name}
                h='43px'
                align='center'
                bgColor='#F7F9FA'
                justify='space-between'
                borderRadius='8px'
                p='0 16px'
                mb='16px'
                boxShadow='0px 1px 6px rgba(0, 0, 0, 0.1)'
              >
                <Text ml='8px' fontWeight='700' fontSize='12px' color='#009639'>
                  {item.name}
                </Text>
                <Button
                  variant='unstyled'
                  fontWeight='600'
                  fontSize='12px'
                  textDecoration='underline'
                  color='#313E4C'
                  onClick={() => handleCollabsAdd(item)}
                >
                  Cancelar
                </Button>
              </Flex>
            )
          })}
        </Flex>

        <Flex w='100%' justify='space-between' mt='24px !important'>
          <Button variant='whiteOutline' fontSize='12px' onClick={handleBack}>
            Voltar
          </Button>

          <Button
            variant='solidGray'
            fontSize='12px'
            disabled={addCollabs.length === 0}
            isLoading={addMembersLoading}
            onClick={handleNextStep}
          >
            Confirmar
          </Button>
        </Flex>
      </VStack>
    </>
  )
}

export default AddCollabsStepTwo
