import { Table, Tbody, Td, Text, Tr, VStack } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleTeam } from 'src/context-admin/useHandleTeam'
import TableColumns from 'src/pages-admin/Dashboard/components/TableColumns'

const TeamsTable = () => {
  const [t] = useTranslation()
  const { teams, filters } = useHandleTeam()
  const filteredTeams = useMemo(() => {
    const filteredArray = teams.filter((item) =>
      filters.teamName ? item.name.toLowerCase().includes(filters.teamName.toLowerCase()) : true,
    )

    if (filters.order === 'NAME_A_Z') {
      return filteredArray.slice().sort((a, b) => (a.name > b.name ? 1 : -1))
    }

    if (filters.order === 'NAME_Z_A') {
      return filteredArray.slice().sort((a, b) => (a.name < b.name ? 1 : -1))
    }

    return filteredArray
  }, [teams, filters.teamName, filters.order])

  return (
    <VStack boxShadow='0 4px 20px rgba(0,0,0,.08)' w='100%' bg='#FFFFFF' borderRadius='10px'>
      <Table>
        <TableColumns columns={[t('team'), t('manager'), t('managerEmail'), t('totalMembers')]} />

        {filteredTeams?.map((item) => (
          <Tbody _hover={{ bg: '#fafafa' }}>
            <Tr>
              <Td fontFamily='Mulish' fontSize='16px' fontWeight='bold' textAlign='left'>
                <Text color='#767F89'>{item.name}</Text>
              </Td>
              <Td fontFamily='Mulish' fontSize='16px' fontWeight='regular' textAlign='left' color='#767F89'>
                {item.members.find((member) => member.type === 'MANAGER')?.name ?? 'N/A'}
              </Td>
              <Td fontFamily='Mulish' fontSize='16px' fontWeight='regular' textAlign='left' color='#767F89'>
                {item.members.find((member) => member.type === 'MANAGER')?.email ?? 'N/A'}
              </Td>
              <Td
                fontFamily='Mulish'
                fontSize='16px'
                fontWeight='regular'
                textAlign='left'
                color='#767F89'
                width='250px'
                textDecoration='underline'
              >
                {item.members.length} {t('members')}
              </Td>
            </Tr>
          </Tbody>
        ))}
      </Table>
    </VStack>
  )
}

export default TeamsTable
