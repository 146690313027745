import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { IoCheckmarkCircle, IoPlayCircle } from 'react-icons/io5'
import { useHistory, useLocation } from 'react-router-dom'
import FavoriteIcon from 'src/components/FavoriteIcon'
import { useAddContentDrawer } from 'src/context/CustomPlaylist/AddContentDrawerContext'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { ContentDrawerInfo, useKnowMoreDrawer } from 'src/context/CustomPlaylist/KnowMoreDrawerContext'
import { useSwapContentDrawer } from 'src/context/CustomPlaylist/SwapContentDrawerContext'
import { useUser } from 'src/context/userContext'
import { getContentData } from 'src/helpers/contentInfo'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { useConfetti } from 'src/hooks/useConfetti'
import Content from 'src/modules/cms/domain/Content'
import { ContentType } from 'src/modules/cms/domain/LearningItem'
import { makeMarkContent } from 'src/modules/cms/factories/makeMarkContent'
import { ContentAction } from 'src/modules/cms/use_cases/MarkContentUseCase'
import { Icons8CircleCloseIcon } from 'src/theme/Icons'
import { LearningItemProgress } from 'src/types/LearningItemProgress'

interface Props {
  contentDrawerInfo?: ContentDrawerInfo
}

const Buttons: React.FC<Props> = ({ contentDrawerInfo }: Props) => {
  const [t] = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { getUser } = useUser()
  const { track, EventsImplementation } = useMixpanel()
  const { drawerInfo, setDrawerInfo } = useKnowMoreDrawer()
  const { setKnowMoreSwapDrawer, setContentToSwap, setSwapDrawerStep } = useSwapContentDrawer()
  const { setAddContentKnowMoreDrawer, setNewContents, newContents } = useAddContentDrawer()
  const { updateContent, playlist } = useCustomPlaylist()
  const { showConfetti } = useConfetti()
  const markContent = makeMarkContent()

  const contentInfoObject = contentDrawerInfo || drawerInfo
  const content = contentInfoObject!.content

  const contentData = getContentData(content ? content.type : '', t)
  const isItemInNewLearningItems = newContents.some((item) => item.id === content?.id)
  const isLearningItemDone = content?.progress === LearningItemProgress.DONE

  const playlistId = playlist?.id
  const moduleId = playlist?.moduleList?.items?.find((m) => m.contentList?.items?.find((c) => c.id === content.id))?.id ??
    ''

  const onMarkAsUndone = async () => {
    try {
      const itemCopy = { ...content }
      itemCopy.progress = LearningItemProgress.DOING
      await markContent.execute({
        id: content.id,
        action: ContentAction.UNDONE,
        moduleId,
        playlistId,
      })
      getUser()
      update(itemCopy)
      track(EventsImplementation.MARK_AS_UNDONE(content.title, location.pathname, 'Modal'))
    } catch (error) {
      const itemCopy = { ...content }
      itemCopy.progress = LearningItemProgress.DONE
    }
  }

  const handleMarkAsDone = async () => {
    await markContent.execute({
      id: content.id, action: ContentAction.DONE,
      moduleId,
      playlistId,
    })
    getUser()
    onMarkAsDone()
  }

  const onMarkAsDone = () => {
    const itemCopy = { ...content }
    itemCopy.progress = LearningItemProgress.DONE
    showConfetti()
    update(itemCopy)
    track(EventsImplementation.MARK_AS_DONE(content.title, location.pathname, 'Modal'))
  }

  const update = (content: Content) => {
    updateContent(content)
    setDrawerInfo({ ...drawerInfo!, content })
  }

  const handleAddItem = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()

    if (isItemInNewLearningItems) {
      setNewContents(newContents.filter((item) => item.id !== content.id))
    } else {
      setNewContents([...newContents, content])

      setTimeout(() => {
        setAddContentKnowMoreDrawer(undefined)
      }, 1500)
    }
  }

  const handleOpenLearningItem = async () => {
    await markContent.execute({ id: content.id, action: ContentAction.ACCESSED })
    getUser()

    const itemCopy = { ...content }
    itemCopy.progress = LearningItemProgress.DOING
    update(itemCopy)

    const isVideo = content.type === ContentType.VIDEO || content.type === ContentType.VIDEODTO

    if (isVideo && content?.allowsEmbedding) {
      history.push(`/learningItem/${content.id}`)
    } else {
      window.open(content.url, '_blank')
    }

    track(
      EventsImplementation.ACCESS_LEARNING_ITEM(
        null,
        content?.favorited!,
        content.type,
        content.title,
        content.id,
        content?.partner?.name ?? '',
        location.pathname,
        'Modal',
      ),
    )
  }

  return (
    <>
      {contentInfoObject?.type === 'ADD' && (
        <Flex gap='16px'>
          <Button
            variant='startCourseDark'
            height='48px'
            padding='12px 24px'
            bg={isItemInNewLearningItems ? 'primaryColors.650' : 'gray.800'}
            _hover={{ bg: isItemInNewLearningItems ? 'primaryColors.650' : 'gray.800' }}
            onClick={handleAddItem}
            data-testid='learning-item-drawer-add-button'
          >
            {isItemInNewLearningItems && (
              <Icon w='30px' h='30px' color='white' marginRight='8px' as={IoCheckmarkCircle} />
            )}
            {isItemInNewLearningItems ? t('home.Custom Playlist.Selected') : t('home.Custom Playlist.Select content')}
          </Button>

          <Button
            variant='startCourse'
            h='48px'
            border='2px solid #E5E5E5'
            onClick={() => setAddContentKnowMoreDrawer(undefined)}
          >
            <Text fontSize={{ base: '.75rem', md: '1rem' }}>{t('home.Custom Playlist.Back to suggestions')}</Text>
          </Button>
        </Flex>
      )}

      {contentInfoObject?.type === 'KNOW_MORE' && (
        <Flex flexDir={{ base: 'column', lg: 'row' }} gap='16px'>
          <Button
            variant='startCourseDark'
            onClick={handleOpenLearningItem}
            display='flex'
            alignItems='center'
            justifyContent='flex-start'
          >
            <Icon w='30px' h='30px' color='white' marginRight='8px' as={IoPlayCircle} />
            <Text fontSize={{ base: '.75rem', md: '.875rem', lg: '1rem' }} color='white'>
              {contentData.startButton}
            </Text>
          </Button>

          <Button
            variant='startCourse'
            border='2px solid #E5E5E5'
            minH='48px'
            flexGrow='1'
            // maxW='285px'
            onClick={() => (isLearningItemDone ? onMarkAsUndone() : handleMarkAsDone())}
            whiteSpace='normal'
            fontSize={{ base: '.75rem', md: '.875rem', lg: '1rem' }}
            display='flex'
            alignItems='center'
            justifyContent='flex-start'
            data-testid='learning-item-drawer-mark-as-done'
          >
            <Icon
              w='30px'
              h='30px'
              marginRight='8px'
              as={isLearningItemDone ? Icons8CircleCloseIcon : contentData?.icon}
            />
            {isLearningItemDone ? contentData?.markAsNotComplete : contentData?.markAsComplete}
          </Button>

          <Box>
            <FavoriteIcon content={content} onFavorite={update} height='48px' isDrawer />
          </Box>
        </Flex>
      )}

      {contentInfoObject?.type === 'SWAP' && (
        <Flex flexDir={{ base: 'column', md: 'row' }} gap='16px'>
          <Button
            variant='startCourseDark'
            onClick={() => {
              setKnowMoreSwapDrawer(undefined)
              setSwapDrawerStep(1)
              setContentToSwap(content)
            }}
          >
            <Text fontSize={{ base: '.75rem', md: '1rem' }} color='white'>
              {t('home.Custom Playlist.Choose this content')}
            </Text>
          </Button>

          <Button
            variant='startCourse'
            border='2px solid #E5E5E5'
            minH='48px'
            flexGrow='1'
            whiteSpace='normal'
            onClick={() => setKnowMoreSwapDrawer(undefined)}
            fontSize={{ base: '.75rem', md: '1rem' }}
          >
            {t('home.Custom Playlist.Back to suggestions')}
          </Button>
        </Flex>
      )}
    </>
  )
}

export default Buttons
