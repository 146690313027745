import { useLazyQuery } from '@apollo/client'
import { GET_CONTENTS } from 'src/modules/cms/graphql/queries/GET_CONTENTS'
import { usePartners } from 'src/modules/cms/hooks/usePartners'
import { cmsQueryContext } from 'src/services/apollo/ApolloClient'
import { CmsContentQuery } from 'src/types/Cms'
import { getOrderFilterField } from '../utils/getOrderFilterField'
import { useCorporateUniversityStore } from './useCorporateUniversityStore'

const useListContents = () => {
  const { updateCmsCourses, limitPerPage, updateTotalCourses, filters, totalCourses, skip, updateSkip, searchValue } =
    useCorporateUniversityStore()
  const { partnersList } = usePartners()

  const aluraPartnerId = partnersList?.find((partner) => partner.name === 'Alura')?.id

  const [listContents, { loading, refetch, fetchMore }] = useLazyQuery<CmsContentQuery>(GET_CONTENTS, {
    context: cmsQueryContext,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: limitPerPage,
      skip,
      order: getOrderFilterField(filters.order),
      ...(filters.status
        ? { status: filters.status }
        : aluraPartnerId && filters.partnerId === aluraPartnerId
        ? { status: 'PUBLISHED' }
        : {}),
      // ...(aluraPartnerId && filters.partnerId === aluraPartnerId ? { status: 'PUBLISHED' } : {}),
      ...(filters.partnerId?.length > 0 ? { partnerId: filters.partnerId } : {}),
      ...(filters.skill?.length > 0 ? { skills: filters.skill } : {}),
      ...(searchValue ? { title: searchValue } : {}),
      ...(filters.companies?.length > 0 ? { companies: filters.companies } : {}),
      ...(filters.languages && filters?.languages?.length > 0 && filters?.languages?.some((lang) => lang !== '')
        ? { languages: { in: filters.languages } }
        : {}),
    },
    onCompleted: (data) => {
      updateCmsCourses(data?.contentList?.items ?? [])
      updateTotalCourses(data?.contentList?.total ?? 0)
    },
  })

  const handlePrevPageCourses = () => {
    const newSkip = skip - limitPerPage <= 0 ? 0 : skip - limitPerPage
    updateSkip(newSkip)
  }

  const handleNextPageCourses = () => {
    const newSkip = skip + limitPerPage >= totalCourses ? skip : skip + limitPerPage
    updateSkip(newSkip)
  }

  return {
    listContents,
    refetch,
    isListContentsLoading: loading,
    fetchMore,
    handlePrevPageCourses,
    handleNextPageCourses,
  }
}

export default useListContents
