import { Box, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface Props {
  step: number
  steps?: number
  showStepString?: boolean
  customFillColor?: string
  customEmptyColor?: string
  customEmptyFill?: string
  currentStepShouldBeFilled?: boolean
  customSize?: number | string
}

const StepHeader: React.FC<Props> = ({
  step,
  steps = 7,
  showStepString = true,
  customFillColor,
  customEmptyColor,
  customEmptyFill,
  currentStepShouldBeFilled = false,
  customSize,
}: Props) => {
  const [t] = useTranslation()

  const fillColor = customFillColor ? customFillColor : 'primaryColors.340'
  const emptyColor = customEmptyColor ? customEmptyColor : 'gray.70'
  const emptyFill = customEmptyFill ? customEmptyFill : 'transparent'

  const comparisonRule = (i: number) => {
    if (currentStepShouldBeFilled) {
      return step >= i ? fillColor : emptyFill
    } else {
      return step > i ? fillColor : emptyFill
    }
  }

  const mapArray = []
  for (let i = 1; i <= steps; i++) mapArray.push(i)

  return (
    <Box marginBottom='20px'>
      {showStepString && (
        <Text color='gray.260' fontSize='12px' lineHeight='24px' marginBottom='4px'>
          {t('quiz.step')} {step} de {steps}
        </Text>
      )}
      <Flex alignItems='center'>
        {mapArray.map((i: number) => (
          <>
            {i !== 1 ? (
              <Box flex='1' h='2px' boxSizing='border-box' backgroundColor={step >= i ? fillColor : emptyColor} />
            ) : null}
            <Box
              w={customSize ? customSize : '16px'}
              h={customSize ? customSize : '16px'}
              borderRadius='100px'
              backgroundColor={comparisonRule(i)}
              border={`2px solid`}
              boxSizing='border-box'
              borderColor={`${step >= i ? fillColor : emptyColor}`}
            />
            {i !== steps ? (
              <Box flex='1' h='2px' boxSizing='border-box' backgroundColor={step >= i ? fillColor : emptyColor} />
            ) : null}
          </>
        ))}
      </Flex>
    </Box>
  )
}

export default StepHeader
