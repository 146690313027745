// import ReactGA from 'react-ga4'
import { useUser } from 'src/context/userContext'
import { EventsImplementation, MixpanelEvent } from 'src/hooks/Mixpanel/types'
import { gtag } from 'src/modules/reports/AnalyticsEvent'

function formatToLowerCaseAndRemoveSpace(str: string) {
  let newStr = str.toLowerCase().replaceAll('-', '_').replaceAll(' ', '_')
  let caracter = '_'
  for (let i = 1; i <= 3; i++) {
    newStr = newStr.replaceAll(caracter.repeat(i), '_')
  }
  return newStr.replaceAll('__', '_')
}

export const useMixpanel = () => {
  const { user } = useUser()
  const track = (eventImplementation: MixpanelEvent) => {
    const { eventName, properties, callbackToBeExecuted, beforeTracking } = eventImplementation

    if (beforeTracking) {
      beforeTracking()
    }

    // let props = ''

    // for (const prop in properties) {
    //   props += `${prop}: ${properties[prop]}; `
    // }

    const userId = user?.id ?? -1

    gtag({ event: formatToLowerCaseAndRemoveSpace(eventName), userId, ...properties })

    if (callbackToBeExecuted) {
      callbackToBeExecuted()
    }
  }

  return { track, EventsImplementation }
}
