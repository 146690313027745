import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SideFilterSubCategory from 'src/components/SideFilter/components/SideFilterSubCategory'
import { useExploreContext } from 'src/context/exploreContext'
import { CategoriesParams } from 'src/pages/Categories'
import { SliderChevron } from '../../../theme/Icons'

type CategoryItemProps = {
  name: string
  id: string
  handleCategoryClick: (title: string) => void
  selectedCategorySubcategories: any[]
}

const SideFilterCategoryItem: React.FC<CategoryItemProps> = ({
  name,
  id,
  handleCategoryClick,
  selectedCategorySubcategories,
}) => {
  const activeSlug = useParams<CategoriesParams>()
  const { setSkillTreeData } = useExploreContext()
  const isActive = activeSlug.category === id

  const handleActivation = () => {
    setSkillTreeData((prevState) => ({ ...prevState, rootId: id }))
  }

  useEffect(() => {
    if (isActive) handleCategoryClick(id)
    // eslint-disable-next-line
  }, [])

  return (
    <Flex flexDir='column'>
      <Box
        w='285px'
        h='39px'
        borderRadius='16px'
        _hover={{
          backgroundColor: 'bluishGray.50',
          color: 'primaryColors.400',
        }}
        bg={isActive ? 'bluishGray.50' : 'initial'}
        color={isActive ? 'primaryColors.400' : 'gray.800'}
        d='flex'
        alignItems='center'
        justifyContent='space-between'
        cursor='pointer'
        paddingX='13px'
        paddingY='25px'
        onClick={() => {
          handleActivation()
          handleCategoryClick(id)
        }}
        marginY='5px'
      >
        <Box d='flex' alignItems='center'>
          <Text fontWeight={isActive ? '900' : '600'} textAlign='left'>
            {name}
          </Text>
        </Box>
        <Icon
          as={SliderChevron}
          transition='all 0.2s ease'
          transform={isActive ? 'rotate(90deg)' : 'rotate(0)'}
          width='6px'
        />
      </Box>
      {isActive && selectedCategorySubcategories && (
        <>
          {selectedCategorySubcategories.map((subcategory, index) => {
            return <SideFilterSubCategory key={index} index={index} categorySlug={id} {...subcategory} />
          })}
        </>
      )}
    </Flex>
  )
}

export default SideFilterCategoryItem
