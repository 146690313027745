import { useQuery } from '@apollo/client'
import { Flex, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AbilitiesSelect from 'src/components/AbilitiesQuiz/components/AbilitiesSelect'
import { useQuiz } from 'src/context/QuizContext'
import { GET_CATEGORIES } from 'src/graphql/queries/GET_CATEGORIES'
import useRegisterUserEvent from 'src/hooks/useRegisterUserEvent'
import { Category } from 'src/types/Category'
import ButtonsFooter from './ButtonsFooter'
import StepHeader from './StepHeader'

interface Props {
  onContinueButtonClick: () => void
  onBackButtonClick: () => void
  onSkipButtonClick: () => void
}

type Options = { category: string; subcategoriesSlugs: string[] }

const StepThree: React.FC<Props> = ({ onContinueButtonClick, onBackButtonClick, onSkipButtonClick }: Props) => {
  const [t] = useTranslation()
  const registerUserEvent = useRegisterUserEvent()

  const { quizResults, updateQuiz, sendMutationQuiz } = useQuiz()
  const [options, setOptions] = useState<Options[]>([])
  const [valid, setValidState] = useState<boolean>(false)

  const { loading } = useQuery(GET_CATEGORIES, {
    onCompleted: (data) => {
      const parsedOptions: Options[] = []

      data.categories.forEach((category: Category) => {
        parsedOptions.push({
          category: category.name,
          subcategoriesSlugs: category.subcategories.map((item) => item.slug),
        })
      })

      setOptions(parsedOptions)
    },
  })

  const handleNextStep = () => {
    sendMutationQuiz()
    onContinueButtonClick()
    registerUserEvent('quiz-subcategories-and-interests', quizResults.themes || [])
  }

  const onChangeThemes = (themes: string[]) => {
    updateQuiz('themes', themes)
    updateQuiz('skillsStartingPoint', '')
    updateQuiz('skillsEndingPoint', '')
  }

  const themes = quizResults.themes || []

  return (
    <>
      <StepHeader step={3} />
      <Flex flexDirection='column' flex={1}>
        {!loading && (
          <>
            <Text fontWeight='800' fontSize='20px'>
              {t('quiz.stepThree.relevantContents')}
            </Text>
            <AbilitiesSelect
              setValidState={setValidState}
              maxItems={3}
              options={options}
              useCategoryLabel
              activeAbilities={quizResults.themes || []}
              onChange={onChangeThemes}
            />
          </>
        )}
      </Flex>

      <ButtonsFooter
        step={3}
        onSkipButtonClick={onSkipButtonClick}
        onContinueButtonClick={handleNextStep}
        onBackButtonClick={onBackButtonClick}
        disabledButton={!valid || !themes.length}
      />
    </>
  )
}

export default StepThree
