import { Button, Flex, Icon, Text } from '@chakra-ui/react'
import { DragArea } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/DragArea'
import { DownloadModelIcon } from 'src/theme/Icons'
import { useChangeCompanyArea } from '../../contexts/ChangeCompanyAreaContext'

export const UploadCompanyFile = () => {
  const { handleUpdateUsers } = useChangeCompanyArea()

  const handleDataFromCsv = (data: any[]) => {
    if (data.length > 0)
      handleUpdateUsers(data.map((item) => ({ email: item.email, area: item.field2, subsidiary: item.field3 })))
  }

  return (
    <Flex flexDir='column' gap='8px'>
      <Text color='bluishGray.500' fontSize='1rem'>
        Atualize a area do colaboradores mais rapidamente. Basta baixar a planilha modelo e fazer o upload com as novas
        informações.
      </Text>
      <Button
        as='a'
        href='/assets/files/update-area.csv'
        display='flex'
        alignItems='center'
        variant='startCourseDark'
        fontSize='1rem'
        fontWeight='600'
        p='8px 16px'
        w='fit-content'
      >
        <Icon as={DownloadModelIcon} w='24px' h='24px' />
        Baixar modelo
      </Button>

      <DragArea type='update-area' onLoadUsersFromCsv={handleDataFromCsv} />
    </Flex>
  )
}
