import { Box, Flex, Skeleton, Stack } from '@chakra-ui/react'

const HomeSkeleton = () => {
  return (
    <Stack w='100%' id='admin-home-loading-skeleton' mt='40px !important'>
      <Skeleton w='417px' h='24px' borderRadius='8px' mb='22px' />

      <Skeleton h='68px' borderRadius='12px' mb='22px' />

      <Box pt='30px' pb='22px'>
        <Skeleton w='183px' h='24px' borderRadius='12px' />

        <Flex gridGap='28px' w='100%' pt='22px'>
          <Flex direction='column' gridGap='6px' w='100%'>
            <Skeleton w='100px' h='16px' borderRadius='8px' />
            <Skeleton w='100%' h='36px' borderRadius='8px' />
          </Flex>
        </Flex>
      </Box>

      <Flex direction='column' gridGap='6px' w='376px' pb='8px'>
        <Skeleton w='56px' h='16px' borderRadius='8px' />

        <Flex gridGap='8px'>
          <Skeleton w='89px' h='22px' borderRadius='8px' />
          <Skeleton w='89px' h='22px' borderRadius='8px' />
          <Skeleton w='89px' h='22px' borderRadius='8px' />
        </Flex>
      </Flex>

      <Skeleton w='100%' h='384px' borderRadius='24px' />
    </Stack>
  )
}

export default HomeSkeleton
