import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Stack } from '@chakra-ui/react'
import axios from 'axios'
import { formatToCNPJ, isCNPJ } from 'brazilian-values'
import { Field, FieldProps, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoChevronForward } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useOnboard } from '../../../context-admin/useOnboard'
// // import '../../../i18n'
import { getNextStep } from '../utils'
import AreaTag from './AreaTag'

interface FormValues {
  name: string
  tradeName: string
  cnpj: string
  area: string
}

const FormCompany: React.FC = () => {
  const history = useHistory()
  const [t] = useTranslation()
  const { company, setCompany } = useOnboard()
  const [areas, setAreas] = useState<string[]>(company ? company.areas : [])

  const Validation = Yup.object().shape({
    name: Yup.string().trim().required(t('onboard.company.validation.required')),
    tradeName: Yup.string().required(t('onboard.company.validation.required')),
    cnpj: Yup.string()
      .required(t('onboard.company.validation.required'))
      .test('isValidCNPJ', t('onboard.company.validation.invalid-cnpj'), function (value: string | undefined) {
        return isCNPJ(value!)
      }),
  })

  const handleSubmit = (values: FormValues) => {
    setCompany({
      areas,
      cnpj: values.cnpj,
      tradeName: values.tradeName,
      name: values.name,
    })

    const nextStep = getNextStep('company')
    history.push(`/onboard/${nextStep!.slug}`)
  }

  const handleChangeCNPJ = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValueFunc: FormikHelpers<FormValues>['setFieldValue'],
  ) => {
    if (!isCNPJ(e.target.value.toString())) return
    /* eslint-disable no-useless-escape */
    const requestCNPJ = e.target.value.replace(/[.\/\s-]/g, '')
    const cnpj = formatToCNPJ(e.target.value)
    setFieldValueFunc('cnpj', cnpj)
    axios
      .get(`https://brasilapi.com.br/api/cnpj/v1/${requestCNPJ}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setFieldValueFunc('name', response.data.razao_social)
        setFieldValueFunc('tradeName', response.data.nome_fantasia)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleAddArea = (
    e: React.KeyboardEvent<HTMLInputElement>,
    setFieldValueFunc: FormikHelpers<FormValues>['setFieldValue'],
    currentArea: FormValues['area'],
  ) => {
    if (e.code !== 'Comma') return
    if (currentArea.length < 2) return
    setAreas([...areas, currentArea.replace(',', ' ')])
    setFieldValueFunc('area', '')
  }

  const deleteArea = (toDeleteArea: string) => {
    setAreas(areas.filter((area) => area !== toDeleteArea))
  }

  return (
    <Formik
      initialValues={{
        name: company ? company.name : '',
        tradeName: company ? company.tradeName : '',
        cnpj: company ? company.cnpj : '',
        area: '',
      }}
      validationSchema={Validation}
      onSubmit={(values) => handleSubmit(values)}
    >
      {(props: FormikProps<FormValues>) => (
        <Form>
          <Stack spacing={4}>
            <Field name='cnpj'>
              {({ field, form }: FieldProps) => (
                <FormControl id='cnpj' isInvalid={!!(form.errors.cnpj && form.touched.cnpj)}>
                  <FormLabel htmlFor='cnpj' fontFamily='Mulish' fontWeight='extrabold'>
                    {t('onboard.company.Registered Number')}
                  </FormLabel>
                  <Input
                    {...field}
                    type='text'
                    placeholder='CNPJ da filial'
                    w='100%'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      field.onChange(e)
                      handleChangeCNPJ(e, props.setFieldValue)
                    }}
                  />
                  <FormErrorMessage>{form.errors.cnpj}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name='name'>
              {({ field, form }: FieldProps) => (
                <FormControl id='name' isInvalid={!!(form.errors.name && form.touched.name)}>
                  <FormLabel htmlFor='name' fontFamily='Mulish' fontWeight='extrabold'>
                    {t('onboard.company.Company Name')}
                  </FormLabel>
                  <Input {...field} type='text' placeholder={t('onboard.company.tradenamePlaceholder')} w='100%' />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name='tradeName'>
              {({ field, form }: FieldProps) => (
                <FormControl id='tradeName' isInvalid={!!(form.errors.name && form.touched.name)}>
                  <FormLabel htmlFor='tradeName' fontFamily='Mulish' fontWeight='extrabold'>
                    {t('onboard.company.Company Trade Name')}
                  </FormLabel>
                  <Input {...field} type='text' placeholder={t('onboard.company.namePlaceholder')} w='100%' />
                  <FormErrorMessage>{form.errors.tradeName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name='area'>
              {({ field, form }: FieldProps) => (
                <FormControl id='area' isInvalid={!!(form.errors.area && form.touched.area)}>
                  <FormLabel htmlFor='area' fontFamily='Mulish' fontWeight='extrabold'>
                    {t('onboard.company.Areas')}
                  </FormLabel>
                  <Input
                    {...field}
                    type='text'
                    placeholder={t('onboard.company.areasPlaceholder')}
                    marginBottom='10px'
                    w='100%'
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      handleAddArea(e, props.setFieldValue, props.values.area)
                    }}
                    onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.code !== 'Comma') return
                      props.setFieldValue('area', '')
                    }}
                  />
                  <Flex direction='row' width='100%' wrap='wrap' alignItems='center' justifyContent='flex-start'>
                    {areas.map((area) => {
                      return <AreaTag area={area} deleteArea={deleteArea} />
                    })}
                  </Flex>
                </FormControl>
              )}
            </Field>
          </Stack>
          <Button
            w='100%'
            rightIcon={<IoChevronForward />}
            type='submit'
            bg='quaternaryColor'
            colorScheme='primaryColors'
            disabled={props.isSubmitting || props.isValidating || !props.isValid}
            marginTop='4%'
            size='lg'
            isLoading={props.isSubmitting}
          >
            {t('onboard.adminAccount.Next Step')}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default FormCompany
