import { Box, Flex, Stack, Text, VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import ExplorablePlaylistCard from '../../../../components/ExplorablePlaylistCard'

interface Props {
  playlists: NewPlaylist[]
}

const AllSection: React.FC<Props> = ({ playlists }: Props) => {
  const [t] = useTranslation()

  // const allPlaylists = useMemo(() => playlists.filter((playlist) => !playlist.recommended), [playlists])
  // const recommendedPlaylists = useMemo(
  //   () => playlists.filter((playlist) => playlist.recommended && playlist.type !== 'CUSTOM_PLAYLIST'),
  //   [playlists],
  // )

  return (
    <Stack bg='white'>
      <Stack width='100%' padding={['20px', '20px', '40px', '40px', '40px']} margin='0 auto' spacing={12}>
        {/* {recommendedPlaylists.length > 0 && (
          <>
            <VStack alignItems='flex-start'>
              <Text
                color='gray.800'
                fontSize={['lg', 'xl', 'xl', 'xl', 'xl']}
                fontFamily='Poppins'
                fontWeight='semibold'
                marginBottom='-25px'
              >
                {t('playlists.recommendedBy')} {user?.company?.name}
              </Text>
            </VStack>
            <Flex
              flexWrap={'wrap'}
              align={{ base: 'center', md: 'flex-start' }}
              justify={{ base: 'center', md: 'flex-start' }}
            >
              {recommendedPlaylists.map((playlist) => {
                return (
                  <Box key={playlist.slug} marginRight='12px' marginBottom='20px'>
                    <ExplorablePlaylistCard
                      key={playlist.slug}
                      playlist={playlist}
                      recommended={playlist.recommended}
                    />
                  </Box>
                )
              })}
            </Flex>
          </>
        )} */}
        <VStack alignItems='flex-start'>
          <Text
            color='gray.800'
            fontSize={['lg', 'xl', 'xl', 'xl', 'xl']}
            fontFamily='Poppins'
            fontWeight='semibold'
            marginBottom='-25px'
          >
            {t('playlists.all')}
          </Text>
        </VStack>
        <Flex
          flexWrap={'wrap'}
          align={{ base: 'center', md: 'flex-start' }}
          justify={{ base: 'center', md: 'flex-start' }}
        >
          {playlists.map((playlist) => {
            return (
              <Box key={playlist.id} marginRight='12px' marginBottom='20px'>
                <ExplorablePlaylistCard
                  playlist={playlist}
                  // recommended={playlist.recommended}
                  isPersonalized={playlist.type === 'CUSTOM'}
                />
              </Box>
            )
          })}
        </Flex>
      </Stack>
    </Stack>
  )
}

export default AllSection
