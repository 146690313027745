import { Flex, HStack, IconButton, Select, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { IoChevronBack, IoChevronForward } from 'react-icons/io5'
import { useCorporateUniversityStore } from 'src/pages-admin/Cms/hooks/useCorporateUniversityStore'
import useListContents from 'src/pages-admin/Cms/hooks/useListContents'
import useListPlaylists from 'src/pages-admin/Cms/hooks/useListPlaylists'

interface TablePaginationProps {
  contentLength: number
  isPlaylist?: boolean
}

export function TablePagination({ contentLength, isPlaylist }: TablePaginationProps) {
  const { limitPerPage, updateLimitPerPage, skip } = useCorporateUniversityStore()
  const { handlePrevPageCourses, handleNextPageCourses } = useListContents()
  const { handlePrevPagePlaylists, handleNextPagePlaylists } = useListPlaylists()

  const [t] = useTranslation()

  return (
    <Flex w='100%' align='center' justify='space-between' data-testid='cms-table-pagination-wrapper'>
      <Flex w='120px' bg='#FFFFFF' boxShadow='0 4px 20px rgba(0,0,0,.1)' borderRadius='4px'>
        <Select
          width='120px'
          border='none'
          onChange={(e) => updateLimitPerPage(Number(e.target.value))}
          data-testid='cms-content-page-limit-select'
        >
          <option value={25} selected={limitPerPage === 25}>
            {t('admin.home.employeeSection.view')} 25
          </option>
          <option value={50} selected={limitPerPage === 50}>
            {' '}
            {t('admin.home.employeeSection.view')} 50
          </option>
          <option value={100} selected={limitPerPage === 100}>
            {t('admin.home.employeeSection.view')} 100
          </option>
          <option value={200} selected={limitPerPage === 200}>
            {t('admin.home.employeeSection.view')} 200
          </option>
          <option value={300} selected={limitPerPage === 300}>
            {t('admin.home.employeeSection.view')} 300
          </option>
        </Select>
      </Flex>

      <HStack>
        <IconButton
          icon={<IoChevronBack />}
          aria-label='Voltar'
          disabled={skip <= 0}
          onClick={() => (isPlaylist ? handlePrevPagePlaylists() : handlePrevPageCourses())}
          fontSize='xl'
          variant='ghost'
          borderRadius='100%'
          data-testid='cms-table-pagination-prev-button'
        />
        <Text>
          {contentLength === 0 ? (
            '0 - 0 de 0'
          ) : (
            <>
              {skip + 1} - {limitPerPage + skip > contentLength ? contentLength : limitPerPage + skip} de{' '}
              {contentLength}
            </>
          )}
        </Text>
        <IconButton
          icon={<IoChevronForward />}
          aria-label='Prosseguir'
          disabled={limitPerPage + skip > contentLength}
          onClick={() => (isPlaylist ? handleNextPagePlaylists() : handleNextPageCourses())}
          background='transparent'
          fontSize='xl'
          variant='ghost'
          borderRadius='100%'
          data-testid='cms-table-pagination-next-button'
        />
      </HStack>
    </Flex>
  )
}
