import { UserToUpdate } from 'src/modules/backoffice/contexts/ChangeCompanyAreaContext'
import { UseCase } from 'src/types/UseCase'
import { UserRepository } from '../repository/UserRepository'

type Input = {
  companyId: number
  usersToUpdate: UserToUpdate[]
}

type Output = {
  errors?: { email: string; code: string; reason: string }[]
}

export class UpdateUserInformationUseCase implements UseCase<Input, Output> {
  constructor(private userRepository: UserRepository) {}

  async execute(args: Input) {
    const response = await this.userRepository.updateUserInformation({
      companyId: args.companyId,
      users: args.usersToUpdate,
    })

    return response
  }
}
