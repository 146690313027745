import { useEffect, useState } from 'react'
// Define general type for useWindowSize hook, which includes width and height
export interface Size {
  width: number | undefined
  height: number | undefined
}

export function useSidemenuSize(): Size {
  const [sidemenuSize, setSidemenuSize] = useState<Size>({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    function handleResize() {
      setSidemenuSize({
        width: document.querySelector('.side-menu')?.clientWidth,
        height: document.querySelector('.side-menu')?.clientHeight,
      })
    }

    const observer = new ResizeObserver(handleResize)
    observer.observe(document.querySelector('.side-menu')!)
  }, [])
  return sidemenuSize
}
