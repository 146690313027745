import { gql } from '@apollo/client'

export const GET_CMS_SKILLS = gql`
  query SkillList($limit: Int, $skip: Int, $name: String, $order: SkillListOrder, $id: String) {
    skillList(limit: $limit, skip: $skip, name: $name, order: $order, id: $id) {
      total
      items {
        id
        name
        translations {
          name
          language
        }
        partners_skills {
          name
          partner_id
        }
        createdAt
        updatedAt
      }
    }
  }
`
