import { Flex, Image, Skeleton, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { getContentData } from 'src/helpers/contentInfo'
import Content from 'src/modules/cms/domain/Content'
import { dateNumberToString } from 'src/pages/LearningItem/utils'

type Props = {
  content: Content
}

const LearningItemInfoCard: React.FC<Props> = ({ content }) => {
  const [t] = useTranslation()
  const totalDurationInMinutes = content?.duration! / 60
  const durationInHours = totalDurationInMinutes / 60
  const contentData = getContentData(content?.type, t)

  if (!content) {
    return <Skeleton w='100%' h='84px' borderRadius='16px' mt='24px' />
  }

  return (
    <Flex
      mt='24px'
      padding='15px 24px 15px 39px'
      bgColor='#F7F9FA'
      borderRadius='16px'
      alignItems='center'
      gap='28px'
      maxW='376px'
    >
      <Image src='/assets/images/clock.png' />

      <Flex flexWrap='wrap' columnGap='6px'>
        <Text fontSize='18px' fontWeight={600}>
          {t('common.approximately')}
        </Text>
        <Text fontSize='18px' fontWeight={600} color='#009639'>
          {totalDurationInMinutes >= 60
            ? `${durationInHours === 1 ? '1 hora' : dateNumberToString(durationInHours)}`
            : `${totalDurationInMinutes} minutos`}
        </Text>
        <Text fontSize='18px' fontWeight={600}>
          de {contentData?.durationLearningText}
        </Text>
      </Flex>
    </Flex>
  )
}

export default LearningItemInfoCard
