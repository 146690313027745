import { Text } from '@chakra-ui/react'

interface TabTitleProps {
  label: string
  showMarginBotton?: boolean
}

export function TabTitle({ label, showMarginBotton = true }: TabTitleProps) {
  return (
    <Text
      color='#313E4C'
      fontSize='1.125rem'
      fontWeight={600}
      fontFamily='Poppins'
      mb={showMarginBotton ? '32px' : '0px'}
    >
      {label}
    </Text>
  )
}
