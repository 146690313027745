import { Flex, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleTeam } from 'src/context-admin/useHandleTeam'
import { makeRemoveTeamMembers } from 'src/modules/dashboard/factory/makeRemoveTeamMembers'
import { NavigationButtons } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/NavigationButtons'
import { StepTitle } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTitle'
import { UserList } from '../../commonComponents/UserList'

export function EditExistingTeamRemoveUsersConfirm() {
  const [t] = useTranslation()
  const toast = useToast()
  const { team, navigateToStep, handleFinalMessage } = useHandleTeam()
  const [loading, setLoading] = useState(false)
  const removeTeamMembers = makeRemoveTeamMembers()

  const handleConfirmRemoveUsers = async () => {
    setLoading(true)
    try {
      const response = await removeTeamMembers.execute({
        teamId: team?.id ?? '',
        membersIds: team?.newUsers?.map((item) => +item.id) ?? [],
      })
      if (!response?.id) {
        throw new Error()
      }
      handleFinalMessage(t('usersRemoved', { teamName: team?.name ?? t('invalidName') }))
      navigateToStep('success-screen')
    } catch (err: any) {
      toast({
        title: t('errorRemovingTeamMembers'),
        description: String(err?.message),
        status: 'error',
        isClosable: true,
        duration: 7000,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Flex flexDir='column' gap='24px'>
      <StepTitle
        title={t('youAreRemovingManager', { areaName: team?.name ?? t('invalidArea') })}
        subtitle={t('checkAndConfirmTeamCollaboratorsRemoval', { teamName: team?.name ?? t('invalidName') })}
      />
      <UserList data={team?.newUsers} withFilter={false} withSearch={false} selectText='' />
      <NavigationButtons
        isLoading={loading}
        isDisabled={!team?.newUsers || team?.newUsers?.length <= 0}
        onContinueClick={() => {
          handleConfirmRemoveUsers()
        }}
        onBackClick={() => {
          navigateToStep('edit-existing-team--remove-users')
        }}
      />
    </Flex>
  )
}
