import { Box, Flex, Portal, Text } from '@chakra-ui/react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { ContentItem } from 'src/pages-admin/Cms/components/PlaylistContentsDrawer/ContentItem'
import { useRegisterPlaylistStore } from 'src/pages-admin/Cms/hooks/useRegisterPlaylistStore'

export function OrderContentsStep() {
  const { selectedContents, updateSelectedContents } = useRegisterPlaylistStore()

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return

    const reorderedItems = Array.from(selectedContents)
    const [removed] = reorderedItems.splice(result.source.index, 1)
    reorderedItems.splice(result.destination.index, 0, removed)

    updateSelectedContents(reorderedItems)
  }

  return (
    <>
      <Flex align='center' gap='16px' w='100%' mt='14px'>
        <Flex direction='column' mt='24px'>
          {Array.from({ length: selectedContents.length }).map((item, index) => (
            <Flex key={index} align='center' h='72px' mb='12px'>
              <Text fontFamily='Poppins' fontWeight={600} fontSize='20px'>
                {index + 1}.
              </Text>
            </Flex>
          ))}
        </Flex>

        <Box w='100%'>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'fewfwefew'}>
              {(provided) => (
                <Flex {...provided.droppableProps} ref={provided.innerRef} mt='24px' direction='column'>
                  {selectedContents.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                      {(provided, snapshot) =>
                        snapshot.isDragging ? (
                          <Portal>
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <ContentItem content={item} hasOrderIcon />
                            </div>
                          </Portal>
                        ) : (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <ContentItem content={item} hasOrderIcon />
                          </div>
                        )
                      }
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Flex>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Flex>
    </>
  )
}
