import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import Confetti from 'react-confetti'
import { useWindowSize } from 'src/hooks/useWindowSize'

interface Value {
  showConfetti: () => void
}

interface Props {
  children: React.ReactNode[] | React.ReactNode
}

const ConfettiContext = createContext({} as Value)

export function ConfettiProvider({ children }: Props) {
  const windowSize = useWindowSize()
  const [confetti, setConfetti] = useState(false)
  const [shouldBeVisible, setShouldBeVisible] = useState(false)

  useEffect(() => {
    if (shouldBeVisible) {
      setConfetti(true)
      setTimeout(() => setConfetti(false), 6000)
    }

    return () => setShouldBeVisible(false)
  }, [shouldBeVisible])

  const showConfetti = useCallback(() => {
    setShouldBeVisible(true)
  }, [])

  return (
    <ConfettiContext.Provider value={{ showConfetti }}>
      {children}
      {confetti && (
        <Confetti
          width={windowSize.width}
          height={windowSize.height}
          data-testid='confetti'
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            zIndex: 10000,
          }}
        />
      )}
    </ConfettiContext.Provider>
  )
}

export const useConfetti = () => useContext(ConfettiContext)
