import { Flex, Skeleton } from '@chakra-ui/react'
import React from 'react'

const AddSkeleton: React.FC = () => {
  return (
    <Flex direction='column' height='100%'>
      <Skeleton w='80%' h='34px' borderRadius='30px' mb='24px' />

      <Flex mb='24px'>
        <Skeleton w='52px' h='24px' borderRadius='30px' mr='8px' />
        <Skeleton w='52px' h='24px' borderRadius='30px' mr='8px' />
        <Skeleton w='52px' h='24px' borderRadius='30px' />
      </Flex>

      <Flex flex='1' flexDir='column'>
        <Skeleton w='100%' h='30%' borderRadius='30px' mb='24px' />
        <Skeleton w='100%' h='30%' borderRadius='30px' mb='24px' />
        <Skeleton w='100%' h='30%' borderRadius='30px' />
      </Flex>
    </Flex>
  )
}

export default AddSkeleton
