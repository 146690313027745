import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSelect from 'react-select'
import { selectStyles } from 'src/components/CustomPlaylistQuiz/utils'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { Area } from 'src/types/Area'

interface Props {
  area: string
  areas?: Area[]
  handleAreaChange: (area: string) => void
}

const AreaSelect: React.FC<Props> = ({ area, handleAreaChange }: Props) => {
  const [t] = useTranslation()
  const { getAreas } = useHandleArea()
  const [areas, setAreas] = useState<Area[]>([])

  const getAreaValue = () => {
    const item = areas.find((item) => item?.id === Number(area))
    return item ? { value: item?.id, label: item?.name } : null
  }

  const incrementAreas = async () => {
    const response = await getAreas(undefined, 500)

    if (response && response.items) {
      setAreas(response.items)
    }
  }

  useEffect(() => {
    incrementAreas()
  }, [])

  return (
    <ReactSelect
      styl
      isClearable={false}
      styles={{
        ...selectStyles('36px'),
        container: (base) => ({ ...base, width: '100%' }),
      }}
      value={getAreaValue()}
      placeholder='Selecione uma área'
      components={{ IndicatorSeparator: () => null }}
      onChange={(item) => handleAreaChange(item?.value?.toString()!)}
      options={areas.map((item) => ({ value: item.id, label: item.name }))}
    />
  )
}

export default AreaSelect
