import { Divider, Flex, Image, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface Props {
  query: string
}

const SearchNotFound: React.FC<Props> = ({ query }: Props) => {
  const [t] = useTranslation()
  return (
    <Flex h='85vh' align={'center'} flexDirection={['column', 'column', 'row', 'row', 'row']}>
      <Stack spacing={6} alignItems='center' padding='50px'>
        <Image src='/assets/images/empty.svg' width='240px' />
        <Stack spacing={2} alignItems='center'>
          <Text color='primaryColor' fontWeight='extrabold' fontSize='xl'>
            {t('search.notFound.Sorry')}
          </Text>
          <Text textAlign='center' fontWeight='bold'>
            {t('search.notFound.It was not possible to find any results with')} "{query}"
          </Text>
        </Stack>
      </Stack>
      <Divider orientation='vertical' />
      <Stack padding={['20px', '20px', '50px', '50px', '50px']}>
        <Text fontSize='lg' fontWeight='extrabold'>
          {t('search.notFound.Searching tips')}
        </Text>
        <br />
        <UnorderedList spacing={3}>
          <ListItem marginLeft='20px'>{t('search.notFound.tips.tip1')}</ListItem>
          <ListItem marginLeft='20px'>{t('search.notFound.tips.tip2')}</ListItem>
          <ListItem marginLeft='20px'>{t('search.notFound.tips.tip3')}</ListItem>
        </UnorderedList>
      </Stack>
    </Flex>
  )
}

export default SearchNotFound
