import { PlaylistRepository } from '../repositories/PlaylistRepository'

interface CreatePlaylistTranslationsUseCaseInput {
  playlistId: string
  language: 'es'
  title: string
  description: string
}

export type CreatePlaylistTranslationsUseCaseOutput = boolean

export class CreatePlaylistTranslationsUseCase {
  constructor(private playlistRepository: PlaylistRepository) {}

  async execute(input: CreatePlaylistTranslationsUseCaseInput): Promise<CreatePlaylistTranslationsUseCaseOutput> {
    const content = {
      language: input.language,
      title: input.title,
      description: input.description,
      playlistId: input.playlistId,
    }

    const result = await this.playlistRepository.createPlaylistTranslations(content)
    return result
  }
}
