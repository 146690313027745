import { Flex, Select } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useCorporateUniversityStore } from 'src/pages-admin/Cms/hooks/useCorporateUniversityStore'

export function PlaylistPrivacyFilter() {
  const [t] = useTranslation()
  const { selectedPlaylistPrivacy, updateSelectedPlaylistPrivacy, updateSkip, updateSearchValue, applyFilters } =
    useCorporateUniversityStore()

  const handleChangePlaylistVisualization = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateSkip(0)
    updateSearchValue('')
    applyFilters('partnerId', undefined)
    applyFilters('skill', undefined)
    applyFilters('status', undefined)
    updateSelectedPlaylistPrivacy(e.target.value as any)
  }

  return (
    <Flex data-testid='cms-playlist-privacy-filter'>
      <Select
        value={selectedPlaylistPrivacy}
        defaultValue={selectedPlaylistPrivacy}
        bgColor='#F7F9FA'
        border='none'
        _hover={{ bgColor: '#F7F9FA' }}
        _focus={{ bgColor: '#F7F9FA' }}
        fontWeight={600}
        fontSize='.875rem'
        minW='167px'
        height='22px'
        onChange={handleChangePlaylistVisualization}
        className={`select-default`}
      >
        <option value='public'>{t('publicPlaylists')}</option>
        <option value='private'>{t('privatePlaylists')}</option>
      </Select>
    </Flex>
  )
}
