import Content from 'src/modules/cms/domain/Content'
import { convertSecondsToTimeString } from 'src/pages-admin/Cms/utils/convertSecondsToTimeString'

export const registerContentInitialValues = (values = {} as Content) => ({
  id: values.id ?? '',
  url: values.url ?? '',
  type: values.type ?? '',
  duration: values.duration ? convertSecondsToTimeString(values.duration) : '',
  title: values.title ?? '',
  description: values.description ?? '',
  languages: values.languages ?? ['pt-BR'],
  subtitles: values.subtitles ?? ['no-subtitle'],
  skills: values.skills?.map((item) => ({ value: item.id, label: item.name })) ?? [],
  cover: values.imageUrl ?? '',
  trailer: values.trailerUrl ?? '',
  partner: values.partner?.id ?? '',
  issuesCertificate: values.hasCertificate ?? false,
  status: values.status,
  allowedCompanyIds: values.allowedCompanyIds,
})
