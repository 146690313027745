import { Box, Button, Flex, Icon, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineArrowsExpand } from 'react-icons/hi'
import { IoPlayCircle } from 'react-icons/io5'
import { useHistory, useLocation } from 'react-router-dom'
import FavoriteIcon from 'src/components/FavoriteIcon'
import { getContentData } from 'src/helpers/contentInfo'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import useRegisterUserEvent from 'src/hooks/useRegisterUserEvent'
import { useWindowSize } from 'src/hooks/useWindowSize'
import Content from 'src/modules/cms/domain/Content'
import { ContentType } from 'src/modules/cms/domain/LearningItem'
import { makeGetContentUrl } from 'src/modules/cms/factories/makeGetContentUrl'
import { renderCorrectContentInfoByLanguage } from 'src/modules/internationalization/helpers/renderCorrectContentInfoByLanguage'
import useRegisterAccess from '../../../../hooks/useRegisterAccess'
import CourseModal from '../../../CourseModal'
import LearningItemModal from '../../../LearningItemModal'

interface Props {
  content: Content
  lineName?: string
  _hover: boolean
  toggleModal: () => void
  setContent: (item: Content) => void
}

export const LearningItemCardTab: React.FC<Props> = ({ content, _hover, toggleModal, setContent }) => {
  const location = useLocation()
  const { isOpen: isOpenCourse, onClose: onCloseCourse } = useDisclosure()
  const { t } = useTranslation()
  const { EventsImplementation, track } = useMixpanel()
  const isCourse = [ContentType.COURSE].includes(content.type as ContentType)
  const contentData = getContentData(content.type, t)

  const register = useRegisterAccess()
  const registerUserEvent = useRegisterUserEvent()
  const history = useHistory()
  const toast = useToast()

  const { width } = useWindowSize()
  const isMobile = useMemo(() => width && width < 1156, [width])

  const handleStartLearningItem = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    register(content.id)

    const type = content.type
    const isVideo = type === ContentType.VIDEO || type === ContentType.VIDEODTO

    if (isVideo && content?.allowsEmbedding) {
      history.push(`/learningItem/${content.id}`)
    } else {
      const getUrl = makeGetContentUrl()
      const url = await getUrl.execute({ id: content.id })

      if (!url) {
        toast({
          title: t('errorWhileLoadingContent'),
          isClosable: true,
          duration: 5000,
          status: 'error',
        })

        return
      }

      window.open(url, '_blank')
    }

    track(
      EventsImplementation.ACCESS_LEARNING_ITEM(
        null,
        content?.favorited!,
        content.type,
        content.title,
        content.id,
        content?.partner?.name ?? '',
        location.pathname,
        'Card',
      ),
    )
  }

  return (
    <>
      <Flex
        w='100%'
        h='auto'
        backgroundColor='gray.10'
        padding='12px'
        position='absolute'
        display={_hover ? 'flex' : 'none'}
        boxShadow={_hover ? '-2px 15px 30px -6px rgba(0,0,0,0.2);' : 'none'}
        borderRadius='0 0 30px 30px'
        alignItems='center'
      >
        <Box alignItems='center' width='100%'>
          <Box>
            <Text fontSize='14px' noOfLines={3} mb='16px'>
              {renderCorrectContentInfoByLanguage(content, 'description')}
            </Text>
          </Box>
          <Flex flexDirection='row' alignItems='center' justifyContent='space-between'>
            <Flex marginRight='8px'>
              <Button
                variant='iconButtonClear'
                w='unset'
                h='36px'
                padding='0 14px'
                onClick={handleStartLearningItem}
                marginRight='2.5%'
              >
                <Icon w='24px' h='24px' as={IoPlayCircle} />
                <Text marginLeft='4px' fontSize='12px'>
                  {isCourse ? t('common.card.startCourse') : contentData.startButton}
                </Text>
              </Button>

              <FavoriteIcon content={content} onFavorite={setContent} height='36px' />
            </Flex>

            <Button
              onClick={() => {
                toggleModal()
                if (content.skills)
                  registerUserEvent(
                    'open-course-modal',
                    content.skills.map((item) => item.name),
                  )

                track(
                  EventsImplementation.OPEN_LEARNING_ITEM_MODAL(
                    'Button',
                    content.title,
                    content?.partner?.name ?? '',
                    content.skills[0].name,
                    content.type,
                  ),
                )
              }}
              variant='courseCard'
              width='auto'
              maxW='102px'
              h='36px'
              padding='0px 12px'
            >
              {isMobile ? (
                <Icon w='30px' h='23px' as={HiOutlineArrowsExpand} color='white' />
              ) : (
                <Text fontSize='0.75rem' color='white'>
                  {t('common.card.toKnowMore')}
                </Text>
              )}
            </Button>
          </Flex>
        </Box>
      </Flex>
      {isCourse ? (
        <CourseModal initialCourse={content} onClose={onCloseCourse} isOpen={isOpenCourse} setCourse={setContent} />
      ) : (
        <LearningItemModal
          initialLearningItem={content}
          onClose={onCloseCourse}
          isOpen={isOpenCourse}
          setLearningItem={setContent}
        />
      )}
    </>
  )
}
