/* eslint-disable no-constant-condition */
import { Box, Flex } from '@chakra-ui/react'
import LogRocket from 'logrocket'
import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import CourseModal from 'src/components/CourseModal'
import Footer from 'src/components/Footer'
import LearningItemModal from 'src/components/LearningItemModal'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { useUser } from 'src/context/userContext'
import Content from 'src/modules/cms/domain/Content'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { useContents } from 'src/modules/cms/hooks/useContents'
import { usePlaylists } from 'src/modules/cms/hooks/usePlaylists'
import { Mixpanel } from 'src/services/mixpanel/Mixpanel'
import { User } from 'src/types/User'
import { useFeedback } from '../../context/FeedbackContext'
import { MostSeenSkeleton, PlaylistsSkeleton, RecentlyAccessedSkeleton } from './HomeSkeleton'
import { LearningItemsSlider } from './LearningItemsSlider'
import PlaylistSection from './PlaylistSection'
import RecentlyAccessed from './RecentlyAccessed'

interface HomeParams {
  courseId: string
  learningItemId: string
}

interface PlaylistPagination {
  limit: number
  skip: number
  total?: number
}

const Home: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const { user, showRecurringFeedback } = useUser()
  const { listContents, listLastContentsAccessedByUser } = useContents()
  const { listPlaylistsByCompanies, listPrivatePlaylists } = usePlaylists()
  const { openFeedback } = useFeedback()
  const { isAlreadyRedirected, alreadyRedirected } = useCustomPlaylistQuiz()

  const [isCourseModalOpen, setIsCourseModalOpen] = useState(false)
  const [isLearningItemModalOpen, setIsLearningItemModalOpen] = useState(false)
  const [lastContents, setLastContents] = useState<Content[] | undefined>(undefined)
  const [lastContentsPagination, setLastContentsPagination] = useState<PlaylistPagination>
    ({
      limit: 20,
      skip: 0,
      total: undefined,
    })
  const [playlists, setPlaylists] = useState<NewPlaylist[] | undefined>(undefined)
  const [playlistPagination, setPlaylistPagination] = useState<PlaylistPagination>
    ({
      limit: 20,
      skip: 0,
      total: undefined,
    })
  const [privatePlaylists, setPrivatePlaylists] = useState<NewPlaylist[] | undefined>(undefined)
  const [privatePlaylistsPagination, setPrivatePlaylistsPagination] = useState<PlaylistPagination>
    ({
      limit: 20,
      skip: 0,
      total: undefined,
    })

  const [loadingAllContents, setLoadingAllContents] = useState({
    contents: true,
    lastContents: true,
    playlists: true,
    lastContentsPagination: false,
    playlistsPagination: false,
    privatePlaylists: true,
    privatePlaylistsPagination: false,
  })

  const hasPlayListPagination = playlists && playlistPagination?.total !== undefined && playlistPagination!.total > playlists.length
  const hasPrivatePlaylistsPagination = privatePlaylists && privatePlaylistsPagination?.total !== undefined && privatePlaylistsPagination!.total > privatePlaylists.length
  const hasLastContentsPagination = lastContents && lastContentsPagination?.total !== undefined && lastContentsPagination!.total > lastContents.length

  const [contents, setContents] = useState<Content[]>([])
  const { courseId, learningItemId } = useParams<HomeParams>()

  const loadData = async () => {
    loadContents()
    loadLastContents()
    loadPlaylists()
    loadPrivatePlaylists()
  }

  const loadContents = async () => {
    if (!user) return
    try {
      setLoadingAllContents((prev) => ({ ...prev, contents: true }))
      const response = await listContents(user, { skip: 0, limit: 20 }, { status: 'PUBLISHED' })
      setContents(response.contents ?? [])
    } catch {
      setContents([])
      console.error('Error loading contents')
    } finally {
      setLoadingAllContents((prev) => ({ ...prev, contents: false }))
    }
  }

  const loadLastContents = async (pagination?: {
    limit: number,
    skip: number,
  }) => {
    if (!user) return

    try {
      const limit = pagination?.limit || lastContentsPagination.limit
      const skip = pagination?.skip || lastContentsPagination.skip

      if (loadingAllContents.lastContentsPagination && skip !== 0) return

      setLoadingAllContents((prev) => ({
        ...prev,
        lastContents: skip === 0 ? true : false,
        lastContentsPagination: skip !== 0 ? true : false,
      }))

      const response = await listLastContentsAccessedByUser(user, limit,
        skip)

      const newLastsContents = response.items ?? []
      const actualLastContents = lastContents ?? []
      const lastContentsMerged = [...actualLastContents, ...newLastsContents]
      console.log(lastContentsMerged)
      setLastContents(lastContentsMerged)

      setLastContentsPagination(() => ({
        limit: limit,
        skip: skip,
        total: response?.total,
      }))
    } catch {
      setLastContents([])
      console.error('Error loading last contents')
    } finally {
      setLoadingAllContents((prev) => ({ ...prev, lastContents: false, lastContentsPagination: false }))
    }
  }

  const loadPlaylists = async (pagination?: {
    limit: number,
    skip: number,
  }) => {
    if (!user) return
    try {
      const limit = pagination?.limit || playlistPagination.limit
      const skip = pagination?.skip || playlistPagination.skip

      if (loadingAllContents.playlistsPagination && skip !== 0) return

      setLoadingAllContents((prev) => ({
        ...prev,
        playlists: skip === 0 ? true : false,
        playlistsPagination: skip !== 0 ? true : false,
      }))
      const userCompanyId = user?.companyId ?? user?.company?.id ?? -1
      const response = await listPlaylistsByCompanies(
        userCompanyId ? [-1, userCompanyId] : [-1],
        "FEATURED",
        limit,
        skip,
      )

      const newPlaylists = response?.items ?? []
      const actualPlaylists = playlists ?? []
      const playlistsMerged = [...actualPlaylists, ...newPlaylists]

      setPlaylists(playlistsMerged)

      setPlaylistPagination(() => ({
        limit: limit,
        skip: skip,
        total: response?.total,
      }))
    } catch {
      setPlaylists([])
      console.error('Error loading playlists')
    } finally {
      setLoadingAllContents((prev) => ({ ...prev, playlists: false, playlistsPagination: false, }))
    }
  }

  const loadPrivatePlaylists = async (pagination?: {
    limit: number,
    skip: number,
  }) => {
    if (!user) return
    try {
      const limit = pagination?.limit || privatePlaylistsPagination.limit
      const skip = pagination?.skip || privatePlaylistsPagination.skip

      if (loadingAllContents.privatePlaylistsPagination && skip !== 0) return

      setLoadingAllContents((prev) => ({
        ...prev,
        privatePlaylists: skip === 0 ? true : false,
        privatePlaylistsPagination: skip !== 0 ? true : false,
      }))

      const response = await listPrivatePlaylists({
        limit,
        skip,
      })

      const newPlaylists = response?.items ?? []
      const actualPlaylists = privatePlaylists ?? []
      const privatePlaylistsMerged = [...actualPlaylists, ...newPlaylists]

      console.log(response?.total)

      setPrivatePlaylists(privatePlaylistsMerged)

      setPrivatePlaylistsPagination(() => ({
        limit: limit,
        skip: skip,
        total: response?.total,
      }))
    } catch {
      setPrivatePlaylists([])
      console.error('Error loading private playlists')
    } finally {
      setLoadingAllContents((prev) => ({ ...prev, privatePlaylists: false, privatePlaylistsPagination: false }))
    }
  }

  const loadCorseInfo = async ({ isLearningItem = true }) => {
    if (user) {
      if (isLearningItem && !learningItemId) return
      if (!isLearningItem && !courseId) return
      if (!isLearningItem) {
        setIsCourseModalOpen(true)
      } else {
        setIsLearningItemModalOpen(true)
      }
      alreadyRedirected()
      loadData()
      history.push(!isLearningItem ? `/course/${courseId}` : `/content/${learningItemId}`)
    }
  }

  useEffect(() => {
    if (user) identifyUser(user)
  }, [user])

  useEffect(() => {
    if (showRecurringFeedback) openFeedback({ step: 0 })
  }, [openFeedback, showRecurringFeedback])

  useEffect(() => {
    if (user && courseId) {
      loadCorseInfo({ isLearningItem: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, courseId])

  useEffect(() => {
    if (user && learningItemId) {
      loadCorseInfo({ isLearningItem: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, learningItemId])

  useEffect(() => {
    if (!location.pathname.includes('course') && !location.pathname.includes('content')) {
      if (user && user.hasCustomPlaylistAssigned && !user.hasCompletedCustomPlaylistQuiz && !isAlreadyRedirected) {
        history.push('/customPlaylistQuiz')
      } else {
        loadData()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAlreadyRedirected, location, history])

  const identifyUser = (user: User) => {
    Mixpanel.identify(user.email)
    Mixpanel.people.set({
      distinct_id: user.email,
      name: user.name,
      email: user.email,
      Area: user.company?.areas.filter((item) => String(item.id) === user.area.id?.toString())[0]?.name,
      'Company Name': user?.company?.name,
      Active: true,
    })

    LogRocket.identify(String(user.id), {
      name: user.name,
      email: user.email,
    })
  }

  const onCloseCourseModal = () => {
    setIsCourseModalOpen(false)
  }

  const onCloseLearningItemModal = () => {
    setIsLearningItemModalOpen(false)
  }

  return (
    <>
      <>
        <Flex flexDirection='column' minHeight='100%'>
          <Helmet>
            <title>Education Journey</title>
          </Helmet>
          <Box flex='1'>
            {loadingAllContents.lastContents ? (
              <RecentlyAccessedSkeleton />
            ) : (
              <>
                {user?.contentInfo?.accessed &&
                  user?.contentInfo?.accessed?.length > 0 &&
                  lastContents &&
                  lastContents?.length > 0 && <RecentlyAccessed
                    contents={lastContents!}
                    hasPagination={hasLastContentsPagination}
                    nextPagination={() => {
                      loadLastContents({
                        limit: lastContentsPagination.limit,
                        skip: lastContentsPagination.skip + lastContentsPagination.limit,
                      })
                    }}
                  />}
              </>
            )}

            {loadingAllContents.privatePlaylists ? (
              <PlaylistsSkeleton />
            ) : (
              <>
                {privatePlaylists && privatePlaylists.length > 0 ? (
                  <PlaylistSection
                    label={t('common.exclusivePlaylist')}
                    playlists={privatePlaylists || []}
                    hasPagination={hasPrivatePlaylistsPagination}
                    nextPagination={() => {
                      loadPrivatePlaylists({
                        limit: privatePlaylistsPagination.limit,
                        skip: privatePlaylistsPagination.skip + privatePlaylistsPagination.limit,
                      })
                    }}
                  />
                ) : null}
              </>
            )}

            {loadingAllContents.playlists ? (
              <PlaylistsSkeleton />
            ) : (
              <>
                {playlists && playlists.length > 0 ? (
                  <PlaylistSection
                    label={t('common.Playlists')}
                    playlists={playlists || []}
                    hasPagination={hasPlayListPagination}
                    nextPagination={() => {
                      loadPlaylists({
                        limit: playlistPagination.limit,
                        skip: playlistPagination.skip + playlistPagination.limit,
                      })
                    }}
                  />
                ) : null}
              </>
            )}

            {loadingAllContents.contents ? (
              <MostSeenSkeleton />
            ) : (
              <LearningItemsSlider contents={contents || []} label={t('common.mostSeenCourses')} />
            )}
          </Box>
          <Footer mt='100px' />
        </Flex>
      </>

      {isCourseModalOpen && <CourseModal courseId={courseId} onClose={onCloseCourseModal} isOpen={isCourseModalOpen} />}

      {isLearningItemModalOpen && (
        <LearningItemModal
          learningId={learningItemId}
          onClose={onCloseLearningItemModal}
          isOpen={isLearningItemModalOpen}
        />
      )}
    </>
  )
}

export default Home
