import { Button, Icon, useClipboard } from '@chakra-ui/react'
import { IoCheckmarkSharp, IoCopyOutline } from 'react-icons/io5'
import '../../services/i18n/i18n'

interface Props {
  value: string
}

const CopyButton: React.FC<Props> = ({ value }: Props) => {
  const { hasCopied, onCopy } = useClipboard(value)

  return (
    <Button
      bg='transparent'
      onClick={onCopy}
      ml={2}
      _active={{ bg: 'transparent' }}
      _focus={{ bg: 'transparent' }}
      _hover={{ bg: 'transparent' }}
    >
      {hasCopied ? (
        <Icon fontSize={{ base: '20px', lg: '24px' }} color='black' as={IoCheckmarkSharp} />
      ) : (
        <Icon fontSize={{ base: '20px', lg: '24px' }} color='black' as={IoCopyOutline} />
      )}
    </Button>
  )
}

export default CopyButton
