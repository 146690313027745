import { Box, Flex, Text } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Skill, useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { useWindowSize } from 'src/hooks/useWindowSize'
import ButtonsFooter from '../ButtonsFooter'
import StepHeader from '../StepHeader'

const StepTwo = () => {
  const [t] = useTranslation()
  const { quizValues, updateQuiz, updateStep } = useCustomPlaylistQuiz()
  const { selectedSkills, skillsSnapshot } = quizValues
  const [hasError, setHasError] = useState(false)

  const { width } = useWindowSize()
  const isMobile = useMemo(() => !!width && width < 992, [width])

  useEffect(() => {
    if (selectedSkills.length !== 0) setHasError(false)
  }, [selectedSkills])

  const handleSelectSkill = (item: Skill) => {
    const isSelected = selectedSkills.findIndex((selected) => selected.id === item.id) !== -1

    if (isSelected) {
      updateQuiz(
        'selectedSkills',
        selectedSkills.filter((skill) => skill.id !== item.id),
      )
    } else {
      const filteredSkills = skillsSnapshot.filter((skill) => skill.id === item.id)
      updateQuiz('selectedSkills', [...selectedSkills, ...filteredSkills])
    }
  }

  const handleNextButton = () => {
    // const skillsToUpdate = selectedSkills.sort((a, b) => Number(a.order) - Number(b.order))

    updateQuiz('skills', selectedSkills)
  }

  const handleUpdateStep = () => {
    if (selectedSkills.length === 0) {
      setHasError(true)
      return
    }

    if (selectedSkills.length === 1) {
      updateStep(4)
    } else {
      updateStep(3)
    }
  }

  return (
    <Flex w='100%' direction='column' justify='space-between'>
      <Box>
        <StepHeader step={2} title={t('customPlaylistQuiz.stepTwo.title')} />

        <Box mt='24px'>
          {hasError && (
            <Text fontWeight={600} fontSize='16px' color='red.300' mb='8px'>
              {t('customPlaylistQuiz.stepTwo.errorMessage')}
            </Text>
          )}
          <Flex align='flex-start' direction='column' gap='12px'>
            {skillsSnapshot.map((item, index) => {
              const isSelected = selectedSkills.some((skill) => skill.id === item.id)

              return (
                <Flex
                  key={item.id}
                  w='100%'
                  h='48px'
                  p='8px 24px'
                  bg={isSelected ? '#F7F9FA' : 'primaryColors.30'}
                  border='2px solid'
                  borderColor={isSelected ? 'primaryColors.650' : 'transparent'}
                  borderRadius='16px'
                  cursor='pointer'
                  transition='all 300ms ease-in-out'
                  onClick={() => handleSelectSkill(item)}
                  data-testid={`custom-playlist-quiz-priority-skill-${index}`}
                  _hover={{
                    ...(!isMobile ? { borderColor: 'primaryColors.650' } : {}),
                  }}
                >
                  <Text fontWeight={700} fontSize='18px' color={isSelected ? 'primaryColors.650' : 'gray.800'}>
                    {item.name}
                  </Text>
                </Flex>
              )
            })}
          </Flex>
        </Box>
      </Box>

      <ButtonsFooter
        nextButtonCallback={handleNextButton}
        updateStep={handleUpdateStep}
        disabledButton={selectedSkills.length === 0}
      />
    </Flex>
  )
}

export default StepTwo
