import { Button, Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useEvaluate } from 'src/context/EvaluateContext'

const PlaylistCover = () => {
  const { close, updateStep } = useEvaluate()
  const [t] = useTranslation()

  return (
    <Flex flex={1} flexDir='column' alignItems='center' justifyContent='center'>
      <Flex flexDir='column' alignItems='center'>
        <Image src='/assets/images/playlist-cover.svg' w={283} h={179} />
        <Text fontFamily='Poppins' fontWeight='semibold' fontSize={16} mt='24px'>
          {t('evaluate.playlistCongratulations')} Trilha Inovação!
        </Text>
        <Text fontWeight='medium' fontSize={16} mt='5px'>
          {t('evaluate.evaluateYourExperience')}
        </Text>
        <Flex flexDir='column' mt='24px'>
          <Button variant='startCourseDark' onClick={() => updateStep(1)}>
            <Text>{t('evaluate.evaluate')}</Text>
          </Button>
          <Button
            color='gray.260'
            bg='transparent'
            _hover={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
            _focus={{ bg: 'transparent' }}
            textDecoration='underline'
            fontFamily='Mulish'
            fontSize='16px'
            onClick={close}
          >
            {t('evaluate.maybeLater')}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PlaylistCover
