import { ChakraProvider } from '@chakra-ui/react'
import LogRocket from 'logrocket'
import React, { Suspense } from 'react'
import { CookiesProvider } from 'react-cookie'
import ReactDOM from 'react-dom'
import App from './App'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import Loader from './components/Loader'
import Routes from './pages/Routes'
import reportWebVitals from './reportWebVitals'
import './services/i18n/i18n'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import theme from './theme/theme'

const isProduction = process.env.NODE_ENV === 'production'
const isProductionAddress = window.location.host === 'app.education-journey.com'
const LogRocketAppId = process.env.REACT_APP_LOGROCKET_API_KEY as string

if (isProduction && isProductionAddress) LogRocket.init(LogRocketAppId)

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <ChakraProvider theme={theme}>
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <App>
              <Routes />
            </App>
          </Suspense>
        </ErrorBoundary>
      </ChakraProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
serviceWorkerRegistration.unregister()
