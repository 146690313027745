export const exploreDataEs = [
  {
    id: 'communication_languages',
    name: 'Comunicación e Idiomas',
    subCategories: [
      {
        name: 'Comunicación',
        id: 'communication',
        children: [
          {
            name: 'Presentaciones',
            id: '28285195-f2af-4a18-9860-e90e9e93539f',
          },
          {
            name: 'Comunicación',
            id: '3842e85a-56ee-4346-9e99-aaa290da4317',
          },
          {
            name: 'Comunicación asertiva',
            id: '1ee13340-4289-4510-a94a-c9ed97274ae1',
          },
          {
            name: 'Comunicación con el gerente',
            id: 'd2837b73-13e6-4868-8b3f-dbe86b01ff28',
          },
          {
            name: 'Comunicación escrita',
            id: 'df18a75b-73c8-422c-9372-ca67c5275f57',
          },
          {
            name: 'Comunicación ejecutiva',
            id: '7706a602-e134-4541-80e4-6610261844c3',
          },
          {
            name: 'Comunicación no violenta',
            id: '0e19eff3-46a1-432c-b6b2-35be08080cfa',
          },
          {
            name: 'Comunicación en redes sociales',
            id: 'b36e0db9-f48a-4cd2-8e07-51fe148b45cf',
          },
          {
            name: 'Comunicación',
            id: 'ecd9e70b-c83f-4f8b-918a-6efce0373040',
          },
          {
            name: 'Coolhunting',
            id: 'f1b5d104-c4b0-4723-849a-17bcb23f5cf6',
          },
          {
            name: 'Creatividad',
            id: '42de1438-2dab-47fd-9823-6b12e5d067fa',
          },
          {
            name: 'Creatividad (Profesional Fuera de la Caja)',
            id: '03d03346-3ac9-11ee-be56-0242ac120002',
          },
          {
            name: 'Exposición',
            id: 'e54d7d76-4ebf-4d07-a649-2764fa4e3197',
          },
          {
            name: 'Fluidez (Profesional Fuera de la Caja)',
            id: 'f91296f6-3ac8-11ee-be56-0242ac120002',
          },
          {
            name: 'Lenguaje de señas',
            id: '077e5af4-1ebb-41fb-82b5-dcd64ca561b0',
          },
          {
            name: 'Media Training',
            id: 'af095e5a-35d5-4146-9f67-c6aa438ba4f3',
          },
          {
            name: 'Oratoria',
            id: '86bf3e3d-6403-4790-bf6d-eab585176191',
          },
          {
            name: 'Pitch',
            id: '4c14be40-256a-4819-b16f-0cd3d13c59a2',
          },
          {
            name: 'Redacción corporativa',
            id: '96216129-6017-4277-8f40-03337386792e',
          },
          {
            name: 'Storytelling',
            id: '95981d48-acc5-465b-a63d-37241afc8124',
          },
          {
            name: 'Técnicas de argumentación',
            id: '673d34be-0173-4259-abdc-187588184592',
          },
          {
            name: 'Técnicas de persuasión',
            id: '1fee1222-30bb-4b65-895b-0f240fd7b88f',
          },
        ],
      },
      {
        name: 'Idiomas',
        id: 'languages',
        children: [
          {
            name: 'Alemán',
            id: '483d2a06-7241-4ebf-a630-7f0d9c1a0520',
          },
          {
            name: 'Español',
            id: '731720ea-e1f6-4946-a7c0-16608d1ad407',
          },
          {
            name: 'Francés',
            id: 'b2162dba-88b2-42da-aa4c-49bc563c8589',
          },
          {
            name: 'Inglés',
            id: '10d00e3a-a236-41f3-abed-bb2a8d188bfa',
          },
          {
            name: 'Italiano',
            id: '5158c876-5c9c-4f78-a4ce-6b46f7ea485a',
          },
          {
            name: 'Japonés',
            id: '301a78d2-0763-40a1-a4e2-8ed831837d79',
          },
          {
            name: 'Mandarín',
            id: '17838b53-dc61-4db6-80c2-55496a0437c0',
          },
        ],
      },
    ],
  },
  {
    id: 'development',
    name: 'Desarrollo',
    subCategories: [
      {
        name: 'Auto-Mejoramiento y Conciencia Personal',
        id: 'self_improvement_personal_awareness',
        children: [
          {
            name: 'Aprendizaje activo',
            id: '097112b6-e73c-4e28-b94f-4dcfb4b4ebe7',
          },
          {
            name: 'Autoconfianza',
            id: '4f9ae00e-4e08-425d-85fa-f248094b6521',
          },
          {
            name: 'Autoconocimiento',
            id: '5c1ca17a-cec0-4643-97df-866719c2becb',
          },
          {
            name: 'Autocrítica',
            id: '081f3720-cf65-4570-aab0-99578f3ef5ed',
          },
          {
            name: 'Autogestión',
            id: '7299f77b-b16c-4aeb-b0da-6a03811d401f',
          },
          {
            name: 'Auto-Responsabilidad (Profesional Fuera de la Caja)',
            id: 'f080d41c-3ac8-11ee-be56-0242ac120002',
          },
          {
            name: 'Empatía (Profesional Fuera de la Caja)',
            id: 'fd37f6ea-3ac8-11ee-be56-0242ac120002',
          },
          {
            name: 'Equilibrio entre vida personal y trabajo',
            id: 'dadf9e9c-d06a-4342-b78b-7717d01aa941',
          },
          {
            name: 'Equilibrio trabajo-vida',
            id: '5c906b43-6df3-4791-92e5-f4ad871cd846',
          },
          {
            name: 'Ejercicios físicos',
            id: 'ca3a4e88-c801-4343-8f95-6a5005845622',
          },
          {
            name: 'Imposición de límites',
            id: '1fee1964-8bbc-48af-a9ba-834461bf6a32',
          },
          {
            name: 'Iniciativa',
            id: '262d85e2-018e-4751-b400-3125b065aa0b',
          },
          {
            name: 'Lifelong Learning (Profesional Fuera de la Caja)',
            id: 'ffae2d9a-3ac8-11ee-be56-0242ac120002',
          },
          {
            name: 'Maternidad y trabajo',
            id: 'd1b970d5-3391-4053-ac2e-9a5a5f8cd333',
          },
          {
            name: 'Memorización',
            id: 'aecb2cc8-029f-4327-8b22-5cc813090ae8',
          },
          {
            name: 'Mentalidad de crecimiento',
            id: '92fc0075-0641-4445-afa0-393beb70b67f',
          },
          {
            name: 'Mindset',
            id: 'fb477282-b84e-47ba-af7d-0afee0aa52ea',
          },
          {
            name: 'Objetividad',
            id: '05db6dfe-4201-4291-b0c2-369733afdd10',
          },
          {
            name: 'Pensamiento crítico',
            id: 'bdd95d48-e2be-4da7-a975-4482d0f98174',
          },
          {
            name: 'Pensamiento Crítico (Profesional Fuera de la Caja)',
            id: 'f42143a4-3ac8-11ee-be56-0242ac120002',
          },
          {
            name: 'Pensamiento estratégico',
            id: '5887d837-4ccd-4d44-b954-cbcbdd1ba8f8',
          },
          {
            name: 'Psicología',
            id: '9fe761d2-1f77-41e1-96f8-0024f7139d3c',
          },
          {
            name: 'Salud mental',
            id: 'd236dfd2-6295-4f32-bd9e-fa59a2045ead',
          },
        ],
      },
      {
        name: 'Gestión de Personas y Liderazgo',
        id: 'people_management_and_leadership',
        children: [
          {
            name: 'Acciones de reconocimiento',
            id: '77456e7a-d4b7-41a6-9bc6-5f4ae58c5bec',
          },
          {
            name: 'Coaching',
            id: '2581bdc7-3bfc-4296-8ef4-88da22b24ea0',
          },
          {
            name: 'Competitividad',
            id: 'f6c3d0b1-b781-4305-bf8e-72b9410ae077',
          },
          {
            name: 'División de conocimiento',
            id: 'b4e79350-1ef1-4662-950d-e887ad5b7cfd',
          },
          {
            name: 'Compromiso del equipo',
            id: '2c0bb4a2-1574-4f70-b390-558a1cbb5d71',
          },
          {
            name: 'Equipos',
            id: '1b348d60-7a6a-4c85-aa04-f11f06660964',
          },
          {
            name: 'Feedback',
            id: '5aa0b982-9696-4105-9501-4c83fc0d11ae',
          },
          {
            name: 'Feedback continuo',
            id: '65c61cb3-ad67-4014-8fec-41d7868b3cf3',
          },
          {
            name: 'Gestión de equipos',
            id: '70b81088-e888-4275-8665-68d479e06b97',
          },
          {
            name: 'Gestión de personas',
            id: 'b2c20e45-bc40-4826-a5d5-955c5bf369b5',
          },
          {
            name: 'Impacto e influencia',
            id: 'aa50323a-2e9e-4c8a-a1b2-64eb1375befa',
          },
          {
            name: 'Inspirar (Profesional Fuera de la Caja)',
            id: 'f72bc0ce-3ac8-11ee-be56-0242ac120002',
          },
          {
            name: 'Liderazgo',
            id: '9458872c-329b-4a21-9bc2-775ea0706431',
          },
          {
            name: 'Liderazgo de líderes',
            id: '2a7f86d8-a92b-4f26-81b1-0fc622a166a1',
          },
          {
            name: 'Liderazgo femenino',
            id: '583ba0f6-3eb1-45b5-89af-8da10940b236',
          },
          {
            name: 'Liderazgo',
            id: 'a8cb92a4-ead2-494f-8fd3-e40fb6409829',
          },
          {
            name: 'Meritocracia',
            id: 'e92ad045-7150-41fe-8dfc-2bc67fe739a2',
          },
          {
            name: 'Proactividad',
            id: '2b9afbaf-1f61-4ed5-a09d-46315f1d8ce7',
          },
          {
            name: 'Programación neurolingüística',
            id: 'f0bf1bef-6ccc-4426-b702-187a7056bfa0',
          },
          {
            name: 'Protagonismo',
            id: 'b6bf3ca8-4cb8-11ee-be56-0242ac120002',
          },
          {
            name: 'Reclutamiento',
            id: 'ab13fe1c-3af8-42f8-a995-65d3349fa7b3',
          },
          {
            name: 'Recursos humanos',
            id: 'e64c1b79-a33d-4071-a247-f6de09a3d23b',
          },
          {
            name: 'Relación con el líder',
            id: 'c83d1718-618e-445e-ba58-0c8452a53a6c',
          },
          {
            name: 'Toma de decisiones',
            id: '2ebb4dfb-7e24-4345-a907-4d62d03ec367',
          },
          {
            name: 'Trabajo en equipo',
            id: 'a17be9f7-62e3-411e-9f41-5da0ff20e002',
          },
          {
            name: 'Trabajo en equipo',
            id: '020077e4-4cea-4d9c-abe0-b18b319c2079',
          },
          {
            name: 'Trabajo en Equipo (Profesional Fuera de la Caja)',
            id: '083c56b2-3ac9-11ee-be56-0242ac120002',
          },
          {
            name: 'Entrenamiento de sesgo',
            id: 'ebd1e3d0-1776-463d-98c7-e8746da9ccdf',
          },
          {
            name: 'Entrenamiento y desarrollo',
            id: 'a0ef542b-f37d-4bb8-af54-e9c02f742b1b',
          },
          {
            name: 'Upskilling',
            id: '4af44b61-32e6-45ce-9d09-3433204dd5ba',
          },
          {
            name: 'Valores organizacionales',
            id: '323d0726-1c2a-4c96-bca2-e161ecead8c9',
          },
        ],
      },
      {
        name: 'Habilidades Cognitivas y de Aprendizaje',
        id: 'cognitive_and_learning_skills',
        children: [
          {
            name: 'Alto rendimiento',
            id: '6cacfd39-2295-4903-885b-894452cfee02',
          },
          {
            name: 'Aprendizaje',
            id: '619ecd08-88e2-48da-949e-3899e58f3813',
          },
          {
            name: 'Capacidad analítica',
            id: '264c2580-da6e-40a0-a32d-826aef8e4e7d',
          },
          {
            name: 'Capacidad de abstracción',
            id: '42afb567-d1b9-4528-b683-33d57c3bfbe4',
          },
          {
            name: 'Capacidad de aprendizaje',
            id: 'c013014e-f963-4d6a-a7ed-52fbca22c817',
          },
          {
            name: 'Capacidad de Ejecución',
            id: '4b1f1747-2f5e-40ad-bd97-1e94896597a2',
          },
          {
            name: 'Eficiencia en entregas',
            id: '807d4ee5-c3d4-4539-ac74-f34a8ceb9830',
          },
          {
            name: 'Razonamiento lógico',
            id: '77cd1840-4038-4fb9-8890-f2bee9a0bae6',
          },
          {
            name: 'Técnicas de aprendizaje',
            id: 'df4557e2-813e-4542-8f08-d29c5b5aa13c',
          },
        ],
      },
      {
        name: 'Inteligencia Emocional y Social',
        id: 'emotional_and_social_intelligence',
        children: [
          {
            name: 'Empatía',
            id: '3f8b6890-18d1-4d19-af8b-25c78e321b27',
          },
          {
            name: 'Inteligencia emocional',
            id: '2b18bd11-2405-4d6c-8764-a45157166754',
          },
          {
            name: 'Inteligencia social',
            id: '71e124bf-e457-405a-99f9-a8ac71f9cbf1',
          },
          {
            name: 'Motivación',
            id: 'cb7adf9f-eb20-4672-a988-adff5599f29f',
          },
          {
            name: 'Resiliencia',
            id: '053db7f8-03cb-4127-a471-4edf95c61546',
          },
          {
            name: 'Resiliencia (Profesional Fuera de la Caja)',
            id: 'ed21d960-3ac8-11ee-be56-0242ac120002',
          },
          {
            name: 'Sensación de urgencia',
            id: '0d12ed08-7a48-4f1b-a5af-77c8a42092af',
          },
          {
            name: 'Sentido de urgencia',
            id: '14b2dc1f-4879-4be5-bf98-410e8bb8725b',
          },
        ],
      },
      {
        name: 'Relaciones Interpersonales',
        id: 'interpersonal_relationship',
        children: [
          {
            name: 'Adaptabilidad',
            id: '52f42571-091e-47a0-b997-51b76ffecdc1',
          },
          {
            name: 'Adaptabilidad (Profesional Fuera de la Caja)',
            id: '4135b4ec-3ac7-11ee-be56-0242ac120002',
          },
          {
            name: 'Análisis de fenómenos',
            id: '65f0058a-9653-43c4-a012-734f976ecfb3',
          },
          {
            name: 'Combate a la procrastinación',
            id: '0a2ceaf5-feff-4ed5-9c44-8f72675167a2',
          },
          {
            name: 'Conversaciones difíciles',
            id: '629df4b8-7d6f-4100-876e-3b26143df119',
          },
          {
            name: 'Diversidad e inclusión',
            id: '0fcaacde-4922-4ccd-ad01-548fe536cdb5',
          },
          {
            name: 'Escucha activa',
            id: '25beaca8-07bc-40f7-9bb4-7060e53e36dc',
          },
          {
            name: 'Escucha Activa (Profesional Fuera de la Caja)',
            id: 'fb3a603a-3ac8-11ee-be56-0242ac120002',
          },
          {
            name: 'Ética Profesional',
            id: 'e5bfb106-d89b-426a-b82c-2d3ef72a868e',
          },
          {
            name: 'Influencia',
            id: 'd6651737-479f-4143-8414-52143575bf52',
          },
          {
            name: 'Networking',
            id: 'a901cf59-2a74-44b0-b872-8c3fe1e82770',
          },
          {
            name: 'Observabilidad',
            id: 'cc07a79c-7f02-432f-8243-2278fcea367b',
          },
          {
            name: 'People analytics',
            id: '177cf6d8-c486-4701-a4d9-fd3cb4685d71',
          },
          {
            name: 'Relaciones interpersonales',
            id: 'd122835d-5cf7-41a0-832c-07ef4c3f9947',
          },
          {
            name: 'Relaciones estratégicas',
            id: '59c2508f-8fd7-49e0-bc79-89852b0b1977',
          },
          {
            name: 'Resolución de problemas',
            id: '660dac9b-998b-4dcd-8d18-69f725eb4151',
          },
          {
            name: 'Solución de problemas',
            id: '2f478e98-9c5f-4f6b-937f-15984aa6d819',
          },
        ],
      },
    ],
  },
  {
    id: 'design',
    name: 'Diseño',
    subCategories: [
      {
        name: 'Audiovisual y Artes Visuales',
        id: 'audiovisual_and_visual_arts',
        children: [
          {
            name: 'Colores en el Ambiente',
            id: 'ca314065-72c2-499b-b738-89507ff28a62',
          },
          {
            name: 'Dibujo Artístico',
            id: '1faecad2-f81c-4e42-a498-a4b2cbf70642',
          },
          {
            name: 'Gestión Visual',
            id: '5ece2cc9-37d9-49e4-85a7-b6da87ce660d',
          },
          {
            name: 'Fotografía',
            id: 'ef58c507-bf80-4081-abed-ed8126429268',
          },
          {
            name: 'Producción de Video',
            id: 'bca1170a-8145-4da9-b72d-146b916e95bf',
          },
          {
            name: 'Cinema 4D',
            id: '6d043b54-3c62-45b8-90ec-5963a3249284',
          },
        ],
      },
      {
        name: 'Diseño Digital',
        id: 'digital_design',
        children: [
          {
            name: 'Diseño Web',
            id: '928afe64-8629-4b6c-8277-322464ef7804',
          },
        ],
      },
      {
        name: 'Diseño Gráfico',
        id: 'graphic_design',
        children: [
          {
            name: '3D y Juegos',
            id: 'edb61b3f-9ace-4e6a-9556-94146b4ca886',
          },
          {
            name: 'Diseño Gráfico',
            id: 'f064b702-ad89-40da-a421-a5aced742ac8',
          },
          {
            name: 'SketchUp',
            id: '3d169518-f94f-4116-818b-13d9c405853f',
          },
        ],
      },
      {
        name: 'Herramientas de Diseño',
        id: 'design_tools',
        children: [
          {
            name: 'Corel Draw',
            id: 'd0408d19-fa36-4288-b5c8-9919881a941b',
          },
          {
            name: 'After Effects',
            id: '6afc8b66-69a7-449b-88e2-8fc2b1796996',
          },
          {
            name: 'Illustrator',
            id: '3d8943b8-b19b-4522-b83f-9ecbcad891a2',
          },
          {
            name: 'Indesign',
            id: 'b51f1c0d-a3fe-44cc-aa1e-a9b35efc6e68',
          },
          {
            name: 'Lightroom',
            id: '727660ad-f6ac-499f-be5c-ca6bf0aaf177',
          },
          {
            name: 'Photoshop',
            id: '4ab2d38c-b663-43f9-a143-6ea7d76a3878',
          },
          {
            name: 'Dreamweaver',
            id: '69e2cbf0-b528-4626-8681-aa5def50751c',
          },
          {
            name: 'Premiere',
            id: '54106f9c-d1e1-4c03-8c8e-9da25dbdf143',
          },
        ],
      },
      {
        name: 'Diseño UX/UI',
        id: 'ux_ui_design',
        children: [
          {
            name: 'Diseño de Servicios',
            id: '4b09aeac-4764-4467-ae55-ba7cb5f93e9c',
          },
          {
            name: 'Design Thinking',
            id: 'e555d1ed-4ed8-4040-a266-f86e6a563498',
          },
          {
            name: 'Figma',
            id: '2bad0f7d-f95e-45b7-b171-cf94a5245fbf',
          },
          {
            name: 'Métricas de UX',
            id: '719294f5-3697-42b2-85aa-4b97fc55bff1',
          },
          {
            name: 'UX',
            id: '14bccff2-01cf-4528-994b-46342f11d705',
          },
        ],
      },
    ],
  },
  {
    id: 'esg',
    name: 'ESG',
    subCategories: [
      {
        name: 'Auditoría Ambiental',
        id: 'bca8834c-bf15-4151-894f-0ac8dac7ea94',
        children: [],
      },
      {
        name: 'ESG',
        id: '123c4d2b-a806-4c5b-aa75-d0895482fe6c',
        children: [],
      },
      {
        name: 'Sostenibilidad',
        id: '1d5baf16-3ac9-11ee-be56-0242ac120002',
        children: [],
      },
    ],
  },
  {
    id: 'finances',
    name: 'Finanzas',
    subCategories: [
      {
        name: 'Cálculo del beneficio real',
        id: '75fa26f2-b042-4325-ac93-c573367aa827',
        children: [],
      },
      {
        name: 'Contabilidad',
        id: 'fe2347e3-0e1d-4d45-aa4c-5cf8f072e73c',
        children: [],
      },
      {
        name: 'Escrituración contable fiscal (ECF)',
        id: 'ef803307-1c3d-4e7d-8fd4-804f1ed8e96c',
        children: [],
      },
      {
        name: 'Estadística',
        id: '404358ad-b8f6-4fce-9be8-9252f0f25886',
        children: [],
      },
      {
        name: 'Estadística avanzada',
        id: '19dda1b8-87f2-4ab6-b08c-ac8d73ac8e1c',
        children: [],
      },
      {
        name: 'Finanzas',
        id: '7733e428-7b73-45cb-8a77-bac003620a51',
        children: [],
      },
      {
        name: 'Finanzas y contabilidad',
        id: 'b2843fbd-bfdc-4b8d-aeb0-9c60097f4499',
        children: [],
      },
      {
        name: 'Gestión de costos',
        id: '7799529d-e5dd-4331-9a23-7ae7135aa106',
        children: [],
      },
      {
        name: 'Inversiones',
        id: 'cbab0132-cbe6-4218-91c2-9485d3591077',
        children: [],
      },
    ],
  },
  {
    id: 'marketing',
    name: 'Marketing',
    subCategories: [
      {
        name: 'Branding',
        id: 'cf05846a-eeec-4229-9dfa-c2388a2c9823',
        children: [],
      },
      {
        name: 'Copywriting',
        id: 'b23e37de-188b-428f-ad88-c3374b849317',
        children: [],
      },
      {
        name: 'Digital',
        id: 'c6c31fb3-bed2-4998-98db-25f838dc80f2',
        children: [],
      },
      {
        name: 'E-Commerce',
        id: '7862d772-e6eb-418a-9396-7fe9a90337c0',
        children: [],
      },
      {
        name: 'Marketing',
        id: '6e9cde15-4fd1-4936-861f-4bb79790643c',
        children: [],
      },
      {
        name: 'Marketing digital',
        id: '85dcd24f-a450-464d-ab6f-6ca2a4bfcc87',
        children: [],
      },
      {
        name: 'Publicidad',
        id: 'a118b818-21e8-413d-ad27-e93dc4141264',
        children: [],
      },
      {
        name: 'SEO',
        id: 'fc1ee208-a099-42b4-93b8-08387751e067',
        children: [],
      },
    ],
  },
  {
    id: 'business',
    name: 'Negocio',
    subCategories: [
      {
        name: 'Atención al Cliente',
        id: 'customer_service',
        children: [
          {
            name: 'Atención al cliente',
            id: '5f21625b-ebc5-4237-bb7b-961202eb74e8',
          },
          {
            name: 'Éxito del Cliente',
            id: '4488a454-0068-407f-8470-47f123e66927',
          },
          {
            name: 'Experiencia del Cliente',
            id: '5f5acdd6-fbf3-4da4-aa06-e504883cb9ee',
          },
          {
            name: 'Experiencia del Cliente',
            id: '1a923a2e-4f47-45d8-8c0d-252ea03c6169',
          },
          {
            name: 'Enfoque en el Usuario',
            id: '3b857da1-ab06-480c-8ee9-411019c2766e',
          },
          {
            name: 'Gestión de Relaciones con el Cliente',
            id: '92904966-f59b-4c08-bee0-c73f819a5da4',
          },
          {
            name: 'Soporte al Cliente',
            id: 'f6504695-919a-46ae-80d0-ec2f11f839f5',
          },
        ],
      },
      {
        name: 'Cadena de Suministro',
        id: 'supply_chain',
        children: [
          {
            name: 'Inventario',
            id: '2a994ea4-2d7c-4fd1-893d-5756822be1bc',
          },
          {
            name: 'Gestión de flotas',
            id: '27523ca3-3902-4b0d-b1e6-fbf4280f7806',
          },
          {
            name: 'Logística',
            id: '1397e322-7f41-4d8e-a8ab-a9d3615aa76a',
          },
          {
            name: 'Última Milla',
            id: '4f954c00-0509-4f87-8868-adfb264f0b90',
          },
        ],
      },
      {
        name: 'Derecho',
        id: 'law',
        children: [
          {
            name: 'Derecho',
            id: '779782ff-1333-4bbc-9b92-bdd459a287db',
          },
          {
            name: 'Derecho Ambiental',
            id: '0f1e485a-3ac9-11ee-be56-0242ac120002',
          },
          {
            name: 'Derecho Inmobiliario',
            id: '0cfe485e-3ac9-11ee-be56-0242ac120002',
          },
        ],
      },
      {
        name: 'Facilidades',
        id: 'facilities',
        children: [
          {
            name: 'Conocimientos sobre el Mercado de Lujo',
            id: 'd059a36f-4b99-4cbe-bfa7-d19a6d4324e8',
          },
          {
            name: 'Gestión de Centros Comerciales o Condominios',
            id: '15f8f9fe-3ac9-11ee-be56-0242ac120002',
          },
          {
            name: 'Mercado Inmobiliario',
            id: '0aa8d48e-3ac9-11ee-be56-0242ac120002',
          },
        ],
      },
      {
        name: 'Fusiones y Adquisiciones',
        id: 'fusion_and_acquisitions',
        children: [
          {
            name: 'Responsabilidad',
            id: '2887ed2e-f571-4578-aa8a-a479fb503148',
          },
          {
            name: 'M&A y Due Diligence',
            id: '11c8a9ec-3ac9-11ee-be56-0242ac120002',
          },
          {
            name: 'Capital de Riesgo',
            id: '4d9dc272-4c1c-11ee-be56-0242ac120002',
          },
        ],
      },

      {
        name: 'Gestión y Procesos',
        id: 'management_and_processes',
        children: [
          {
            name: 'Análisis',
            id: '6b4d9351-74ed-49fa-8abf-b2bff65f7c0c',
          },
          {
            name: 'Cronograma',
            id: 'd92f04cb-41ba-4689-abb1-1b5cdc82286a',
          },
          {
            name: 'Herramientas de Calidad',
            id: 'd2dd0b6a-3712-45f6-8538-c24cb1ce1e13',
          },
          {
            name: 'Gestión Anticipada',
            id: '95fd558b-cce9-4a85-8edc-25b87e95757b',
          },
          {
            name: 'Gestión de Ansiedad',
            id: '4cdcc489-c50f-41e9-afeb-416a4c6c6c54',
          },
          {
            name: 'Gestión de Conflictos',
            id: '9c5593f6-b48b-4a9c-ad75-380d71e89aff',
          },
          {
            name: 'Gestión de Contratos',
            id: '1ba9fccc-3ac9-11ee-be56-0242ac120002',
          },
          {
            name: 'Gestión de KPIs',
            id: '9730ab2f-d569-434a-91fa-b41b617914f3',
          },
          {
            name: 'Gestión de Métricas',
            id: '441c9c3e-4087-4047-9cdb-709fccf2e492',
          },
          {
            name: 'Gestión de Cambio',
            id: '8c9b8112-8f3c-4793-897b-b27405d6eea1',
          },
          {
            name: 'Gestión de Procesos',
            id: '3006276d-79f4-419d-915d-89498de1a689',
          },
          {
            name: 'Gestión de Producción',
            id: '7b67af03-e844-4cb3-a595-332f56e1f017',
          },
          {
            name: 'Gestión de Proyectos',
            id: '6fa10bf0-4860-4961-ab9c-3eac3d15b3f8',
          },
          {
            name: 'Gestión de Riesgos',
            id: 'a6cc77e4-4c33-4d0b-a1b6-9d5b48e83f41',
          },
          {
            name: 'Gestión de Tiempo',
            id: '4161b0e4-2b89-4853-87e8-9bbd6713fe40',
          },
          {
            name: 'Gestión de Tráfico',
            id: 'b07f73a1-6b2c-4c6f-b70a-2742b50ca3ae',
          },
          {
            name: 'Gestión de Proyectos',
            id: 'aa1074dc-6d28-4182-9c08-b86d00a0352f',
          },
          {
            name: 'Hospitalidad',
            id: 'b011a33e-eae0-4d79-9ad9-fd88704494b4',
          },
          {
            name: 'Mapeo de Procesos',
            id: '98a02a97-f4d8-4cb4-a050-45d17569b99f',
          },
          {
            name: 'Mapeo de Stakeholders',
            id: '88a395ab-0466-4ce7-9009-cc278c78dd13',
          },
          {
            name: 'OKR',
            id: '259bb216-1540-4642-93fc-6523a2a4a99f',
          },
          {
            name: 'Tablero de Control',
            id: '4c7ada64-958d-4310-af09-2855a39024df',
          },
          {
            name: 'Probabilidad',
            id: '2352b080-66cf-42a4-a137-6aee1541cdb5',
          },
          {
            name: 'Procesos de Calidad',
            id: 'd0386d56-f714-426f-a01d-9eb76a657a3c',
          },
          {
            name: 'Calidad',
            id: '3ce22ade-878d-4adb-b32f-99550231dc10',
          },
          {
            name: 'Calidad en las Entregas',
            id: '0195c4b7-d575-49f4-b708-a5e9af33751e',
          },
        ],
      },
      {
        name: 'Métodos Ágiles',
        id: 'agile_methods',
        children: [
          {
            name: 'Canvas',
            id: 'e2f4a5ca-1eb6-483a-8b93-4848f6f7ec25',
          },
          {
            name: 'Gestión de Equipos (Squads)',
            id: '53bebe22-14cf-4d55-a145-e63de8833c13',
          },
          {
            name: 'KPI',
            id: 'e8ee1203-4d4d-4180-9457-097ec4b2b5fc',
          },
          {
            name: 'Pensamiento Lean Ágil',
            id: 'e1b45556-f32f-40ab-aaf6-07b399d2b7fa',
          },
          {
            name: 'Metodología Ágil',
            id: 'cf236d6e-d4e5-4002-b453-e04e0105dcc1',
          },
          {
            name: 'Producto',
            id: '1e6a30d4-87cd-4301-ae15-3d20fd872e10',
          },
          {
            name: 'Project Model Canvas',
            id: 'daa7a611-649d-41fa-bb74-062db1a3539c',
          },
          {
            name: 'Scrum',
            id: 'd0a34c61-e06d-4e5a-a8e1-612ad95889d5',
          },
          {
            name: 'Six Sigma',
            id: 'e3061101-a681-476a-83ab-a8a641f1b9c6',
          },
        ],
      },
      {
        name: 'Normas y Regulaciones',
        id: 'standards_and_regulation',
        children: [
          {
            name: 'Cumplimiento',
            id: '17a478e6-3ac9-11ee-be56-0242ac120002',
          },
          {
            name: 'ISO',
            id: 'f089ff75-479e-403c-87aa-25cb090c2546',
          },
          {
            name: 'Iluminación Industrial',
            id: 'e339e2d7-397c-46c2-a99f-ff9ac8e27ed6',
          },
          {
            name: 'Legislación Fiscal',
            id: '19922ebe-3ac9-11ee-be56-0242ac120002',
          },
          {
            name: 'Normas de Seguridad',
            id: 'eb396316-b1a0-4bc3-8fd4-3a1396adea03',
          },
          {
            name: 'Seguridad Laboral',
            id: '58865c49-8165-47ba-9f1b-fcd2d1165668',
          },
        ],
      },
      {
        name: 'Planificación y Estrategia',
        id: 'planning_and_strategy',
        children: [
          {
            name: 'Control de Calidad',
            id: '4d4337ae-527a-44fe-bba3-20eb4f7a5725',
          },
          {
            name: 'Emprendimiento',
            id: 'f27126a6-d6e4-498f-bd7b-983f1c99d0ab',
          },
          {
            name: 'Finanzas Corporativas',
            id: '13fb3a04-3ac9-11ee-be56-0242ac120002',
          },
          {
            name: 'Gestión Escolar',
            id: '77bd0c6a-1e4b-44aa-8d3a-3c4aada4a08d',
          },
          {
            name: 'Gestión Ejecutiva',
            id: '67437378-b6de-4859-9f60-c4d787aafd25',
          },
          {
            name: 'Gestión Pública',
            id: '3b656304-6983-499b-8b0d-5ce2b458f1db',
          },
          {
            name: 'Innovación',
            id: '7203c807-cb82-4674-81c1-3bc2774db043',
          },
          {
            name: 'Innovación',
            id: 'f5adff37-71a5-42dc-9a0f-d3fbac8617aa',
          },
          {
            name: 'Planificación',
            id: 'c14b8563-15b4-4923-bc7f-15728cec07c2',
          },
          {
            name: 'Planificación de Carrera',
            id: '8018c410-2c18-4547-8749-6acca737415d',
          },
          {
            name: 'Plan de Continuidad de Negocio',
            id: '5a0ce6ba-1583-4fc5-bc1e-8448e95fd882',
          },
          {
            name: 'Posicionamiento Estratégico',
            id: '03a25ce6-7210-4e1e-92bd-d1402e7f3bfc',
          },
          {
            name: 'Viabilidad de Incorporación Inmobiliaria',
            id: 'a5a14a1d-24e1-4556-9ee9-6031e481df37',
          },
          {
            name: 'Visión de Dueño',
            id: 'e49a679b-987f-4b9e-b750-159b408abf77',
          },
          {
            name: 'Visión de Negocio',
            id: '7152a092-521a-43e4-9181-4b1543dbe31d',
          },
          {
            name: 'Visión Estratégica de Recursos Humanos',
            id: 'c87a456d-8068-4de0-bf0d-77a0140afc3b',
          },
        ],
      },
    ],
  },
  {
    id: 'software',
    name: 'TI e Software',
    subCategories: [
      {
        name: 'Bases de Datos',
        id: 'database',
        children: [
          {
            name: 'Análisis de Datos',
            id: '7e93c650-673c-4573-8c7f-625544731468',
          },
          {
            name: 'API',
            id: '14c30c41-49ca-4794-a4d0-8d5501031026',
          },
          {
            name: 'Base de Datos',
            id: '37c963e0-cf53-4375-aaea-d9626f754d65',
          },
          {
            name: 'Cognos',
            id: 'ae23a5e4-e39a-4592-be6f-7fccd2dabd55',
          },
          {
            name: 'CRISP-DM',
            id: '56e49360-a090-4b54-9121-54a56b778736',
          },
          {
            name: 'Datos',
            id: '882e8aad-b547-4227-a861-3359b99709b8',
          },
          {
            name: 'Análisis de Datos',
            id: '8b83017c-8ece-426d-a4d3-0e986e473700',
          },
          {
            name: 'Ingeniería de Diagnóstico',
            id: '49ed0423-ce47-4bf3-bb3a-9ef2340db91d',
          },
          {
            name: 'Herramientas de Análisis de Datos',
            id: '75c9dcb6-f56d-4147-adf6-a7d929a96f35',
          },
          {
            name: 'MySQL',
            id: '631269e5-a981-4482-9d9e-f8dc10f0c14a',
          },
        ],
      },
      {
        name: 'Certificaciones de Tecnología',
        id: 'tech_certifications',
        children: [
          {
            name: 'Certificación MS-900',
            id: 'c986c48e-22f4-4c88-9c0b-588c84655c59',
          },
        ],
      },
      {
        name: 'Desarrollo de Software',
        id: 'software_development',
        children: [
          {
            name: 'Automatización con Selenium',
            id: 'e940ac58-4851-4fb8-a7e9-8bbb93059159',
          },
          {
            name: 'Automatización de Pruebas',
            id: '7d956f1c-d018-4dc8-bc3a-068dfc6297d9',
          },
          {
            name: 'Ciencia de Datos',
            id: '568b6ba7-73ac-4aad-8016-4f9db2a836a9',
          },
          {
            name: 'CTFL',
            id: '8a6d7b28-3289-4115-8db9-73ef86679d6f',
          },
          {
            name: 'Front-end',
            id: '9f6254a0-7574-4f52-b239-388a998407c2',
          },
        ],
      },
      {
        name: 'Discovery',
        id: 'discovery',
        children: [
          {
            name: 'Discovery',
            id: '7c10e6df-db0a-4de2-8954-8befa51c3f6d',
          },
          {
            name: 'Discovery Continuo',
            id: '6f7a1ce4-b3c6-4a51-96c4-fcd17ef0de97',
          },
          {
            name: 'Discovery de Producto',
            id: 'a72b1d13-bbba-4985-b05a-463327b5bc3b',
          },
        ],
      },
      {
        name: 'Herramientas y Software',
        id: 'tools_and_software',
        children: [
          {
            name: 'DevOps',
            id: '0a9ff1c6-bfed-42f1-912a-8c2b8aa628ad',
          },
          {
            name: 'SAP',
            id: '96bd3ce9-b47c-4c2a-8d3d-1f3ef9ca47fc',
          },
          {
            name: 'Access',
            id: '61ff1a05-54c9-42ad-9fba-e36ceed34db4',
          },
          {
            name: 'Autocad',
            id: 'eae2f044-741a-4bdd-bfe1-576db269ad50',
          },
          {
            name: 'Cloud',
            id: '83a457f3-ecc4-43a3-9744-0a434a0794c7',
          },
          {
            name: 'Seguridad en la Nube',
            id: 'a1be8c18-6656-4b05-a34f-20e786218eb6',
          },
          {
            name: 'Computación',
            id: 'd33a48b2-6a46-4884-a46d-3c96ac2f5235',
          },
          {
            name: 'Conceptos de Calidad de Software',
            id: '272c8014-6b74-4864-a5b9-c9b09314cfc5',
          },
          {
            name: 'Excel',
            id: 'c9f2d853-8c0e-4189-b961-29db6e7d7a47',
          },
          {
            name: 'Excel Básico',
            id: 'f3883419-696e-433c-be61-30e7cc3eaa9f',
          },
          {
            name: 'Excel Intermedio',
            id: '7b735bbe-ad34-4ff3-8e3b-34e3758e46b4',
          },
          {
            name: 'Herramientas de Google',
            id: '2f7cee32-0ea5-4b52-a5ca-80fa1e3b0e98',
          },
          {
            name: 'Flash',
            id: 'c49087cb-e473-46ad-ba70-65473e16a1bd',
          },
          {
            name: 'Futurismo',
            id: 'ca560f99-18b4-463f-a32a-25e918bfd91f',
          },
          {
            name: 'Geoprocesamiento',
            id: '8f5da0d5-6269-4d32-803e-159596f67f27',
          },
          {
            name: 'Google AdWords',
            id: '8f856e01-6bc1-4645-b4a8-97b58ec0cf7c',
          },
          {
            name: 'G Suite',
            id: 'fd47ac2d-c429-4d69-9753-3b09fc93222c',
          },
          {
            name: 'Jira',
            id: 'f9ae345f-3aee-4f74-ac70-8c9a452c5888',
          },
          {
            name: 'Office',
            id: 'fc7ee075-8199-47b6-8c3c-9205feab650d',
          },
          {
            name: 'Powerpoint',
            id: '1746c664-358b-48b9-929a-2738b5ad58ea',
          },
          {
            name: 'Sharepoint',
            id: '87747f79-87b3-4805-a413-aa166616e09f',
          },
          {
            name: 'Tablas',
            id: 'ef7de2f2-46d8-4b58-9e29-eb0dadae7079',
          },
          {
            name: 'Word',
            id: '324b3b7d-6046-413b-a604-9ef416af9901',
          },
          {
            name: 'Power BI',
            id: '8138d43b-7fe3-43d3-93b8-903df1830bbd',
          },
        ],
      },
      {
        name: 'Inteligencia Artificial y Aprendizaje Automático',
        id: 'artificial_intelligence_and_machine_learning',
        children: [
          {
            name: 'Aprendizaje de Máquina',
            id: '51837e4a-930b-4840-a823-edd118bbb6fe',
          },
          {
            name: 'Blockchain',
            id: '43fb2c83-8ec4-46bb-89b5-a4ae478ff3dc',
          },
          {
            name: 'Inteligencia Artificial',
            id: 'f3742704-4c1c-11ee-be56-0242ac120002',
          },
          {
            name: 'Machine Learning',
            id: 'cc604722-b0dd-4926-bb01-d71e581d9301',
          },
          {
            name: 'Wordpress',
            id: 'a1071f7f-d777-4ce2-9887-70c4950ed96f',
          },
        ],
      },
      {
        name: 'Programación',
        id: 'programming',
        children: [
          {
            name: 'Arquitectura de Sistemas',
            id: '614eff39-02f6-4c21-9191-7b11c8b9bb9b',
          },
          {
            name: 'C',
            id: '9689760b-b8af-4861-8950-8857d894ae45',
          },
          {
            name: 'Conceptos de C#',
            id: 'b6b7db7d-a22f-426e-bc09-e004d640b7b6',
          },
          {
            name: 'CSS',
            id: '5ef3a4b8-4dbd-40a0-ab38-9f585f1d7e8a',
          },
          {
            name: 'CSS3',
            id: '6850a6d6-8b1b-4836-8d6b-12482a96e995',
          },
          {
            name: 'Diseño impulsado por dominio',
            id: 'f2803a98-dd01-4baa-a1f1-4a0e46a858f4',
          },
          {
            name: 'Desarrollo de Software',
            id: '63239a02-d31c-4f60-bbcd-3e1ddc1072ef',
          },
          {
            name: 'HTML',
            id: 'e872c293-617d-480d-a3d5-d1757d6bae05',
          },
          {
            name: 'HTML5',
            id: 'cde003d7-f477-406e-adb2-cc345b4d819e',
          },
          {
            name: 'Javascript',
            id: '4c5781e6-ceab-49b9-b7bb-459726fbd26e',
          },
          {
            name: 'PHP',
            id: '42d70c0b-9da8-4b78-b57d-bc10a6063aa3',
          },
          {
            name: 'Análisis de Producto',
            id: '585767f8-4183-4355-a90c-31a1d178ef0a',
          },
          {
            name: 'Programación',
            id: '5b5accaf-c976-45d1-a121-9a90e2547c0c',
          },
        ],
      },
      {
        name: 'Redes Sociales',
        id: 'social_media',
        children: [
          {
            name: 'Facebook',
            id: 'dbd858b7-2eca-4fec-b0c7-347805768aeb',
          },
          {
            name: 'Instagram',
            id: '20ac6e60-e4f1-427f-96ab-f2474cd19693',
          },
          {
            name: 'LinkedIn',
            id: 'fe94cf9e-2908-41bd-afec-c3f6c1af18e7',
          },
          {
            name: 'Youtube',
            id: '26999a74-f920-42c1-9f46-b3a7c7874a58',
          },
        ],
      },
      {
        name: 'Seguridad de la Información',
        id: 'information_security',
        children: [
          {
            name: 'Firewall/redes',
            id: 'f78b71d8-dae9-4c02-90bd-7eacb0ff090f',
          },
          {
            name: 'Seguridad de la información',
            id: '04f213e0-c1cb-4214-8a9d-3a349b01e600',
          },
        ],
      },
      {
        name: 'Sistemas Operativos',
        id: 'operational_systems',
        children: [
          {
            name: 'Android',
            id: 'cfbbbb97-fa00-49d0-ab1b-080a5aa82339',
          },
          {
            name: 'iOS',
            id: '44660a01-7d28-4a40-ae3a-8beb8ef029f3',
          },
          {
            name: 'Linux',
            id: 'a201f99b-6696-4d3d-a309-356e16fbabf0',
          },
          {
            name: 'Windows',
            id: 'acc18519-1bd1-435c-8231-e4a85de279d9',
          },
        ],
      },
    ],
  },
  {
    id: 'sales',
    name: 'Ventas',
    subCategories: [
      {
        name: 'Datos para Inbound',
        id: '2cefa402-c5e2-4a06-bfec-8b765f5e97f3',
        children: [],
      },
      {
        name: 'Datos para Outbound',
        id: '0d4c5a9f-9bc2-4047-b237-8c1a848f61c9',
        children: [],
      },
      {
        name: 'Datos para Ventas',
        id: '34f677e5-3646-4f04-aae3-ed65d22547cb',
        children: [],
      },
      {
        name: 'Gestión de Cartera de Clientes',
        id: '5252b089-489c-4466-a5d6-1e5d86d86e61',
        children: [],
      },
      {
        name: 'Crecimiento',
        id: 'cf99c893-d70d-413f-8643-08d30d4a9af4',
        children: [],
      },
      {
        name: 'Negociación',
        id: 'deba818b-052c-487c-afb0-e3d777e07725',
        children: [],
      },
      {
        name: 'Objeciones en Ventas',
        id: 'c5892b68-9cc2-47d6-89b0-15bfbf688818',
        children: [],
      },
      {
        name: 'Omnicanal',
        id: 'dd5ecb29-e852-4ac6-9bdb-19db8073e3ad',
        children: [],
      },
      {
        name: 'Procesos de Ventas',
        id: '70f19a06-d6b8-41a3-8979-6670c61f4d6a',
        children: [],
      },
      {
        name: 'Segmentación de Cartera',
        id: '988b831d-06ad-4c61-900e-2834abc3fb10',
        children: [],
      },
      {
        name: 'Técnicas de Ventas',
        id: 'd4bce079-a5ae-4bb9-aa52-bc2100d05ff9',
        children: [],
      },
      {
        name: 'Técnicas de Ventas Outbound',
        id: 'e617147b-d7fb-4e16-9883-9693744f5076',
        children: [],
      },
      {
        name: 'Ventas',
        id: 'ae650ea1-dc12-4e10-9ea6-ee8a46286387',
        children: [],
      },
      {
        name: 'Ventas Inbound',
        id: '7e61309e-d439-4795-a7ef-d8f992a17636',
        children: [],
      },
    ],
  },
  {
    id: 'other_themes',
    name: 'Otras Temáticas',
    subCategories: [
      {
        name: 'Arquitectura',
        id: '8e9cc90d-9db6-4528-af48-dbfad02d08ef',
        children: [],
      },
      {
        name: 'Diseño de Interiores',
        id: '24e5cd96-c6fc-4b42-9d82-611baa19c210',
        children: [],
      },
      {
        name: 'Housekeeping',
        id: '0aa434f4-0965-424e-89e7-0292705dd6d7',
        children: [],
      },
      {
        name: 'Introducción al Paisajismo',
        id: 'de04ea50-21fe-4253-bab0-c06891f86a2a',
        children: [],
      },
      {
        name: 'Mecánica',
        id: '828f0b44-a504-4026-9217-a8efa0490153',
        children: [],
      },
      {
        name: 'Mecatrónica',
        id: 'ea08faba-ff56-420d-b123-9642a39f1ed3',
        children: [],
      },
    ],
  },
]
