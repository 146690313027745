import { Button, Flex, Skeleton, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { usePermissions } from 'src/context-admin/usePermissions'
import { useAllocateSkillStore } from '../../hooks/useAllocateSkillStore'
import { SearchContent } from '../SearchContent'
import { SelectedCollabsPopover } from './SelectedCollabsPopover'
import SelectSkillsDrawer from './SelectSkillsDrawer'
import { useAllocateSkill } from './useAllocateSkill'

export function AllocateSkill() {
  const [t] = useTranslation()
  const { updateAllocateSkillModalState, selectedCollabs } = useAllocateSkillStore()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { fetchMore, loadingRefetch, handleSelectCollabs, loading, searchDebounce } = useAllocateSkill()
  const { collabsList, pageInfo } = usePermissions()

  function handleClose() {
    updateAllocateSkillModalState(false)
  }

  return (
    <>
      <Flex bgColor='white' h='90vh' pos='fixed' left={0} right={0} overflowY='auto'>
        <Flex p='48px 215px' flexDir='column' w='100%'>
          <Text color='#313E4C' fontSize='1.5rem' fontWeight={700} fontFamily='Poppins'>
            {t('whoIsThisSkillsAllocation')}
          </Text>
          <Text color='#767F89' fontSize='1rem' mt='4px'>
            {t('selectWhoTheSkillsWillBeAllocated')}
          </Text>

          <Flex w='100%' mt='16px'>
            <SearchContent title='Busque usuários' placeholder='Busque por um usuário' searchContent={searchDebounce} />
          </Flex>

          <Flex w='100%' justify='flex-end' mt='16px' mb='8px'>
            <SelectedCollabsPopover />
          </Flex>

          <Flex flexDir='column' gap='4px' overflowY='hidden' h='100%'>
            {loading ? (
              <Flex direction='column' w='100%' gridGap='16px' display='flex'>
                <Skeleton w='100%' h='43px' borderRadius='8px' />
                <Skeleton w='100%' h='43px' borderRadius='8px' />
                <Skeleton w='100%' h='43px' borderRadius='8px' />
              </Flex>
            ) : (
              <Flex id='scrollableDiv' direction='column' w='100%' maxH='calc(100vh - 550px)' overflowY='auto'>
                <InfiniteScroll
                  dataLength={collabsList.length}
                  next={fetchMore}
                  hasMore={pageInfo?.hasNextPage ?? false}
                  loader={
                    <Text textAlign='center' display={loadingRefetch ? 'block' : 'none'}>
                      {t('loadingMore')}
                    </Text>
                  }
                  scrollThreshold={0.7}
                  scrollableTarget='scrollableDiv'
                >
                  <>
                    {!collabsList.length && <Text textAlign='center'>{t('noCollaboratorsFound')}</Text>}
                    {collabsList?.map((item) => {
                      const isSelected = selectedCollabs.some((collab) => collab.id === item.id)

                      return (
                        <Flex
                          key={Math.random()}
                          h='43px'
                          align='center'
                          bgColor='#F7F9FA'
                          justify='space-between'
                          borderRadius='8px'
                          p='0 16px'
                          mb='16px'
                          boxShadow='0px 1px 6px rgba(0, 0, 0, 0.1)'
                        >
                          <Flex flex='1'>
                            <Text ml='8px' fontWeight='700' fontSize='12px' color='#009639'>
                              {item.name}
                            </Text>
                          </Flex>
                          <Flex flex='1'>
                            <Text ml='8px' color='#767F89' fontSize='12px'>
                              {item.email}
                            </Text>
                          </Flex>
                          <Flex flex='1' flexDirection='row-reverse'>
                            <Button
                              variant='unstyled'
                              fontWeight='600'
                              fontSize='12px'
                              textDecoration='underline'
                              color={isSelected ? '#009639' : '#313E4C'}
                              onClick={() => handleSelectCollabs(item)}
                            >
                              {isSelected ? t('selected') : t('select')}
                            </Button>
                          </Flex>
                        </Flex>
                      )
                    })}
                  </>
                </InfiniteScroll>
              </Flex>
            )}
          </Flex>

          <Flex align='center' justify='space-between' pb='91px' mt='28px'>
            <Text
              color='#767F89'
              fontSize='1rem'
              textDecoration='underline'
              fontFamily='Poppins'
              fontWeight='600'
              cursor='pointer'
              onClick={handleClose}
            >
              {t('back')}
            </Text>
            <Tooltip
              hasArrow
              placement='top'
              fontSize='12px'
              label='Selecione pelo menos um colaborador'
              isDisabled={selectedCollabs.length !== 0}
              shouldWrapChildren
            >
              <Button
                type='submit'
                variant='unstyled'
                w='176px'
                h='44px'
                bg='gray.800'
                color='white'
                fontSize='1rem'
                padding='8px 16px'
                isDisabled={selectedCollabs.length === 0}
                onClick={onOpen}
                _hover={{ _disabled: { bg: '#3F3D561F' } }}
              >
                {t('continue')}
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>

      {isOpen && <SelectSkillsDrawer isOpen={isOpen} onClose={onClose} />}
    </>
  )
}
