import { useMutation } from '@apollo/client'
import { Box, Button, Flex, Input, Text, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHomeAccess } from 'src/context-admin/Home/AdminHomeAccessContext'
import REMOVE_EMPLOYEES from 'src/graphql/mutations/REMOVE_EMPLOYEES'
import Drawer from '../../../../../components/Drawer'
import { usePermissions } from '../../../../../context-admin/usePermissions'
import { useMixpanel } from '../../../../../hooks-admin/mixpanel/useMixpanel'

const RemoveAccessDrawer: React.FC = () => {
  const { EventsImplementation, track } = useMixpanel()
  const [t] = useTranslation()
  const toast = useToast()
  const { fetchUsers, first } = useHomeAccess()
  const { toggleRemoveAccessModal, removeCollabsFromList, collabsToRemoveAccess, removeAllCollabsFromList } =
    usePermissions()
  const [submitList, { loading }] = useMutation(REMOVE_EMPLOYEES)
  const [confirmationText, setConfirmationText] = useState('')

  const close = () => {
    toggleRemoveAccessModal(false)
  }

  const handleSubmit = async () => {
    try {
      await submitList({
        variables: {
          users: collabsToRemoveAccess.map((item) => ({ email: item.email })),
        },
      })

      track(EventsImplementation.REMOVE_USERS_CONFIRMATION(collabsToRemoveAccess.length))
      removeAllCollabsFromList()
      await fetchUsers({ limit: first })
      close()
      toast({
        title: t('onboard.employees.Employees successfully removed!'),
        status: 'success',
        isClosable: true,
      })
    } catch (err) {
      toast({
        title: 'Ocorreu um erro ao remover os acessos.',
        status: 'error',
        isClosable: true,
      })
    }
  }

  return (
    <Drawer onOutsideClick={close}>
      <Text fontSize='20px' fontWeight={600} fontFamily='Poppins' color='gray.31' mb='16px'>
        {t('youAreRemovingTheFollowingAccess')}
      </Text>
      <Text color='#767F89' fontWeight={600} mb='24px'>
        {t('checkAndConfirmAccessRemove')}
      </Text>

      <Flex direction='column' overflowY='auto' maxH='350px'>
        {collabsToRemoveAccess.map((item) => (
          <Flex
            key={item.id}
            h='43px'
            align='center'
            bgColor='#F7F9FA'
            justify='space-between'
            borderRadius='8px'
            p='0 16px'
            mb='16px'
            boxShadow='0px 1px 6px rgba(0, 0, 0, 0.1)'
          >
            <Text ml='8px' fontWeight='700' fontSize='12px' color='#009639'>
              {item.name}
            </Text>
            <Button
              variant='unstyled'
              fontWeight='600'
              fontFamily='Mulish'
              fontSize='12px'
              textDecoration='underline'
              color='#313E4C'
              onClick={() => removeCollabsFromList(item.id.toString())}
            >
              {t('cancel')}
            </Button>
          </Flex>
        ))}
      </Flex>

      <Flex m='16px 0' direction='column' gridGap='16px'>
        <Box>
          <Text fontSize='18px'>{t('toConfirmThisAction')}</Text>
          <Text as='b' fontSize='18px'>
            Quero remover estes acessos
          </Text>
        </Box>

        <Input
          height='36px'
          pl='16px'
          pr='16px'
          borderRadius='12px'
          value={confirmationText}
          onChange={(e) => setConfirmationText(e.target.value)}
        />
      </Flex>

      <Flex justify='flex-end' mt='16px'>
        <Button
          variant='solidGray'
          fontSize='12px'
          disabled={
            collabsToRemoveAccess.length === 0 ||
            confirmationText.trim().toLowerCase() !== 'quero remover estes acessos' ||
            loading
          }
          isLoading={loading}
          onClick={handleSubmit}
        >
          {t('confirm')}
        </Button>
      </Flex>
    </Drawer>
  )
}

export default RemoveAccessDrawer
