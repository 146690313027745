import httpClient from 'src/services/http/http-client'
import { getAccessToken } from '../apis/store'
import { SaveUserEdition, UserRepository } from '../repository/UserRepository'

export class SaveUserEditionUseCase {
  constructor(private userRepository: UserRepository) {}

  async execute(params: SaveUserEdition): Promise<boolean> {
    const hasSaved = await this.userRepository.saveUserEdition(params)
    return hasSaved
  }
}

export function makeSaveUserEdition() {
  const token = getAccessToken()
  const userRepo = new UserRepository(token, httpClient)
  return new SaveUserEditionUseCase(userRepo)
}
