import { Flex, Icon, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { IoCheckmarkSharp } from 'react-icons/io5'

export const CustomPlaylistDone: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Flex
      bg='#EAF5EA'
      h='36px'
      borderRadius='16px'
      paddingX='12px'
      alignItems='center'
      justifyContent='center'
      w={{ base: '100%', lg: 'auto' }}
    >
      <Icon w='20px' h='20px' as={IoCheckmarkSharp} color='#009639' mr='4px' />
      <Text fontFamily='Poppins' color='#009639' fontSize='12px' fontWeight='600'>
        {t('common.card.complete')}
      </Text>
    </Flex>
  )
}
