import { HttpClient } from 'src/services/http/http-client'

interface IReportRepository {
  getReport: (reportTab: string, userLanguage: string) => Promise<any>
}

type GetReportInput = {
  date?: string
  area?: number[]
  users?: number[]
  subsidiaries?: number[]
  playlistId?: string
  type: string
}

type GetAreasOutput = {
  id: number
  name: string
  createdAt: string
  updatedAt: string
  deleted_at: string
  companyId: number
  manager_id: number
}[]

type GetUsersInput = {
  areas?: number[]
  subsidiaries?: number[]
}

type GetUsersOutput = {
  id: number
  name: string
}[]

type GetSubsidiariesOutput = {
  id: number
  name: string
}[]

type GetPlaylistsOutput = {
  id: number
  title: string
}[]

export class ReportRepository implements IReportRepository {
  private token: string
  private client: HttpClient

  constructor(token: string, client: HttpClient) {
    this.token = token
    this.client = client
  }

  getReport = async (reportTab: string, userLanguage = 'pt-BR'): Promise<string> => {
    try {
      const response = await this.client.request<{ iframe: string }>({
        method: 'GET',
        url: `/v1/dashboard/${reportTab}?language=${userLanguage}`,
        headers: {
          Authorization: this.token,
        },
      })

      return response.body.iframe
    } catch {
      return ''
      // throw new Error(`Ocorreu um erro ao carregar o relátorio. Por favor, tente novamente!`)
    }
  }

  generateReportUrl = async ({ type, ...params }: GetReportInput): Promise<string> => {
    const paths: Record<string, string> = {
      summary: '/v1/dashboard/summary',
      register: '/v1/dashboard/register',
      custom: '/v1/dashboard/custom',
      featured: '/v1/dashboard/featured',
      contents: '/v1/dashboard/contents',
      resume: '/v1/dashboard/custom/resume',
      colab: '/v1/dashboard/custom/colab',
    }

    try {
      console.log(type, 'paths[type]', paths[type])

      if (!paths[type]) {
        throw new Error(`Path não encontrado!`)
      }

      const response = await this.client.request<{ iframe: string }>({
        method: 'POST',
        url: paths[type],
        data: params,
        headers: {
          Authorization: this.token,
        },
      })

      return response.body.iframe
    } catch {
      return ''
      // throw new Error(`Ocorreu um erro ao carregar o relátorio. Por favor, tente novamente!`)
    }
  }

  getAreas = async (): Promise<GetAreasOutput> => {
    try {
      const response = await this.client.request({
        method: 'GET',
        url: `/v1/dashboard/areas`,
        headers: {
          Authorization: this.token,
        },
      })

      return response.body
    } catch {
      throw new Error(`Ocorreu um erro ao buscar áreas. Por favor, tente novamente!`)
    }
  }

  getUsers = async (params: GetUsersInput): Promise<GetUsersOutput> => {
    try {
      const response = await this.client.request({
        method: 'GET',
        url: `/v1/dashboard/users`,
        params: params,
        headers: {
          Authorization: this.token,
        },
      })

      return response.body
    } catch {
      throw new Error(`Ocorreu um erro ao buscar usuários. Por favor, tente novamente!`)
    }
  }

  getSubsidiaries = async (): Promise<GetSubsidiariesOutput> => {
    try {
      const response = await this.client.request({
        method: 'GET',
        url: `/v1/dashboard/subsidiaries`,
        headers: {
          Authorization: this.token,
        },
      })

      return response.body
    } catch {
      throw new Error(`Ocorreu um erro ao buscar subsidiárias. Por favor, tente novamente!`)
    }
  }

  getPlaylists = async (): Promise<GetPlaylistsOutput> => {
    try {
      const response = await this.client.request({
        method: 'GET',
        url: `/playlist/featured/dashboard`,
        headers: {
          Authorization: this.token,
        },
      })

      console.log(response.body)

      return response.body
    } catch {
      throw new Error(`Ocorreu um erro ao buscar trilhas. Por favor, tente novamente!`)
    }
  }
}
