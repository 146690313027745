import { Flex } from '@chakra-ui/react'
import React, { useEffect, useRef } from 'react'
import { useUser } from 'src/context/userContext'

const MoodleLogin: React.FC = () => {
  const { user } = useUser()
  const formElement = useRef(null)
  const emailElement = useRef(null)
  const passwordElement = useRef(null)

  useEffect(() => {
    if (formElement !== null && passwordElement !== null && emailElement !== null) {
      if (formElement.current && passwordElement.current && emailElement.current) {
        // @ts-ignore
        if (passwordElement.current.value === user?.password && emailElement.current.value === user?.email) {
          // @ts-ignore
          formElement.current.submit()
        }
      }
    }
    // eslint-disable-next-line
  }, [formElement.current, passwordElement.current, emailElement.current])

  if (!user) return null

  return (
    <Flex flexDirection='column' display='none'>
      <form name='login' method='post' action='https://lms.education-journey.com/login/index.php' ref={formElement}>
        Username:
        <input type='text' name='username' value={user.email} ref={emailElement} /> <br />
        Password:
        <input type='password' name='password' value={user.password} ref={passwordElement} /> <br />
        <input type='submit' name='Submit' value='Login' /> <br />
        <br />
      </form>
    </Flex>
  )
}

export default MoodleLogin
