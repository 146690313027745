import { gql } from '@apollo/client'

const LIST_USERS = gql`
  query GetUsers($limit: Int!, $cursor: Int, $teamId: String, $areaId: Int, $name: String) {
    listUsers(limit: $limit, cursor: $cursor, teamId: $teamId, areaId: $areaId, filter: { name: { contains: $name } }) {
      data {
        id
        companyIdentifier
        company {
          id
          name
        }
        name
        language
        email
        area {
          id
          name
        }
        roles
      }
      paginateInfo {
        count
        hasPreviousPage
        hasNextPage
        total
        next
      }
    }
  }
`

export default LIST_USERS
