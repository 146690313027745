import { Icon } from "@chakra-ui/icons";
import {
  Stack,
  Text,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import { IoInformationCircleOutline } from "react-icons/io5";

interface Props {
  title?: string;
  caption?: string;
  description?: string;
  popoverContent?: string;
}

const OnboardHeader: React.FC<Props> = (props: Props) => {
  return (
    <Stack spacing={4} marginBottom={6}>
      <Box width="100%" height="8px" bg="primaryColors.700" mb={5}></Box>
      <Text
        as="h2"
        fontSize="2lg"
        color="gray.800"
        fontFamily="Mulish"
        fontWeight="thin"
      >
        {props.caption}
      </Text>
      <Text
        as="h3"
        fontSize="3xl"
        color="gray.800"
        fontFamily="Poppins"
        marginTop="-4%"
        fontWeight="black"
      >
        {props.title}
      </Text>
      <Text as="p" fontSize="md" fontFamily="Mulish" color="gray.600">
        {props.description}
        {props.popoverContent && (
          <Popover
            trigger="hover"
            placement="bottom-end"
            colorScheme="whiteAlpha"
          >
            <PopoverTrigger>
              <Icon as={IoInformationCircleOutline} cursor="pointer" />
            </PopoverTrigger>
            <PopoverContent
              borderColor="quaternaryColor"
              borderRadius="20px"
              padding="12px"
            >
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>{props.popoverContent}</PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Text>
    </Stack>
  );
};

export default OnboardHeader;
