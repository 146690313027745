import {
  Button,
  Flex,
  Input as ChakraInput,
  InputProps,
  Select as ChakraSelect,
  SelectProps,
  Skeleton as ChakraSkeleton,
  Text,
  useToast,
} from '@chakra-ui/react'
import _ from 'lodash'
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSelect from 'react-select'
import { selectStyles } from 'src/components/CustomPlaylistQuiz/utils'
import { useHomeAccess } from 'src/context-admin/Home/AdminHomeAccessContext'
import { debounce } from 'src/helpers-admin/debounce'
import { isEmailValid } from 'src/helpers/verifyEmail'
import { Team } from 'src/modules/dashboard/entities/Team'
import { makeListAreas } from 'src/modules/dashboard/factory/makeListAreas'
import { UserToEdit } from 'src/modules/iam/repository/UserRepository'
import { makeGetUserToEdit } from 'src/modules/iam/use-case/GetUserToEditUseCase'
import { makeSaveUserEdition } from 'src/modules/iam/use-case/SaveUserEditionUseCase'
import { User } from 'src/types/User'
import { getErrorTitle } from '../hooks/getErrorTitle'
import { parseCustomAttributesFromApi, parseCustomAttributesToApi } from '../hooks/parseCustomAttributesFromApi'
import { useSingleEmployeeFormOptions } from '../hooks/useSingleEmployee'
import { FormValues } from '../types/Form'
import { blankFormValues } from '../utils'

interface CommonInputProps {
  label: string
  isRequired?: boolean
  isLoading?: boolean
}

const Label = ({ children }: { children: ReactNode }) => (
  <Text fontFamily='Mulish' fontWeight='semibold' fontSize='14px'>
    {children}
  </Text>
)

const Input = ({ ...props }: InputProps) => <ChakraInput {...props} />
const Select = ({ ...props }: SelectProps) => <ChakraSelect {...props} />
const Skeleton = () => <ChakraSkeleton h='32px' w='full' borderRadius='12px' />

const InputWithLabel = ({ label, isRequired, isLoading, ...props }: CommonInputProps & InputProps) => (
  <Flex flexDir='column' gap='2px'>
    <Label>
      {label} {isRequired && <strong style={{ color: '#D44333' }}>*</strong>}
    </Label>
    {isLoading && <Skeleton />}
    {!isLoading && <Input padding='0px 8px' m='0' height='32px' borderRadius='12px' fontSize='0.875rem' {...props} />}
  </Flex>
)

const SelectWithLabel = ({ label, isRequired, isLoading, ...props }: CommonInputProps & SelectProps) => (
  <Flex flexDir='column' gap='2px'>
    <Label>
      {label} {isRequired && <strong style={{ color: '#D44333' }}>*</strong>}
    </Label>
    {isLoading && <Skeleton />}
    {!isLoading && (
      <Select m='0' height='32px' borderRadius='12px' fontSize='0.875rem' {...props}>
        {props.children}
      </Select>
    )}
  </Flex>
)

interface AddSingleEmployeeProps {
  onClose: () => void
}

interface FormComplements {
  areas: { id: number; name: string; managerId: number }[]
  teams: Team[]
  permissions: any
}

export function EditSingleEmployee({ onClose }: AddSingleEmployeeProps) {
  const [loadingInfos, setLoadingInfos] = useState({ areas: true, teams: true, selectedUser: false, submit: false })
  const [formValues, setFormValues] = useState<FormValues>(blankFormValues)
  const [formComplements, setFormComplements] = useState<FormComplements>({
    areas: [],
    teams: [],
    permissions: [],
  })

  const formOptions = useSingleEmployeeFormOptions({ areas: formComplements.areas, teams: formComplements.teams })
  const [t] = useTranslation()
  const toast = useToast()
  const { fetchUsers, fetchUsersAndReturn, first } = useHomeAccess()

  const [users, setUsers] = useState<User[]>([])
  const [selectedUser, setSelectedUser] = useState<UserToEdit | undefined>(undefined)
  const [loadingSearchUser, setLoadingSearchUser] = useState(false)

  const getUsersToEdit = async (name?: string, isFirstRequest = false) => {
    try {
      setLoadingSearchUser(true)
      if (!name && !isFirstRequest) {
        setLoadingSearchUser(false)
        return
      }
      const response = await fetchUsersAndReturn({
        limit: isFirstRequest ? 100 : 1000,
        name: name ?? '',
        orderByName: 'NAME_A_Z',
      })
      if (!response?.data) {
        setUsers([])
        return
      }
      setUsers((prev) => {
        const deduplicated = _.uniqBy([...prev, ...response.data], (e) => e.email)
        return deduplicated
      })
    } catch {
      setUsers([])
    } finally {
      setLoadingSearchUser(false)
    }
  }

  const debouncedSearchUser = debounce(getUsersToEdit, 1000)

  const shouldRenderSelectInput = (id: keyof FormValues) =>
    (['area', 'team', 'language', 'isManager', 'status', 'gender'] as Partial<keyof FormValues>[]).includes(id)

  const requiredFields = formOptions?.filter((opt) => opt?.isRequired).map((opt) => opt.id) ?? []

  const listAreas = async () => {
    try {
      const list = makeListAreas()
      const areas = await list.execute({ limit: 500 })
      setFormComplements((prev) => ({ ...prev, areas: areas.items }))
      if (selectedUser) {
        setFormValues((prev) => ({ ...prev, area: String(selectedUser.area.name) }))
      }
    } catch {
      console.error('Error on list areas')
    } finally {
      setLoadingInfos((prev) => ({ ...prev, areas: false }))
    }
  }

  const verifyIfRequiredFieldsAreFilled = () => {
    const _isEmailValid = isEmailValid(formValues.email ?? '')
    return requiredFields.every((field) => formValues[field] !== '') && _isEmailValid
  }

  const handleSubmitForm = async () => {
    try {
      if (!selectedUser) return
      setLoadingInfos((prev) => ({ ...prev, submit: true }))
      const parsedFormValues = parseCustomAttributesToApi(formValues as any)
      const data = { userToEdit: selectedUser, fieldsEditted: parsedFormValues }
      const saveUserEdition = makeSaveUserEdition()
      const hasSaved = await saveUserEdition.execute(data)
      if (!hasSaved) {
        toast({
          title: t('errorWhileAddSingleCollab'),
          description: t('anErrorOccurWhileAddSingleCollab'),
          status: 'error',
          isClosable: true,
          duration: 7000,
        })
        return
      }
      toast({
        title: t('collabEditedSuccessfully'),
        status: 'success',
        isClosable: true,
        duration: 5000,
      })
      fetchUsers({ limit: first })
      onClose()
    } catch {
      toast({
        title: t('errorWhileAddSingleCollab'),
        description: t('anErrorOccurWhileAddSingleCollab'),
        status: 'error',
        isClosable: true,
        duration: 7000,
      })
      console.error('Error on submit form')
    } finally {
      setLoadingInfos((prev) => ({ ...prev, submit: false }))
    }
  }

  const handleSelectUser = async (props: any) => {
    setLoadingInfos((prev) => ({ ...prev, selectedUser: true }))
    const findedUser = users.find((u) => u.id === props?.value)
    if (!findedUser) {
      toast({
        title: t('anErrorOccurredSelectingUserId'),
        status: 'error',
        duration: 15000,
        isClosable: true,
      })
      setLoadingInfos((prev) => ({ ...prev, selectedUser: false }))
      return
    }
    const listUserFieldsToEdit = makeGetUserToEdit()
    const response = await listUserFieldsToEdit.execute({ id: findedUser.id })
    if (response?.error) {
      toast({
        title: getErrorTitle(response?.error ?? 'DEFAULT', t),
        description: getErrorTitle(response?.message ?? 'TRY_AGAIN', t),
        status: 'error',
        duration: 15000,
        isClosable: true,
      })
      setLoadingInfos((prev) => ({ ...prev, selectedUser: false }))
      return
    }
    setSelectedUser(response.body!)
    setLoadingInfos((prev) => ({ ...prev, selectedUser: false }))
  }

  useEffect(() => {
    if (selectedUser) {
      listAreas()
    }
  }, [selectedUser])

  useEffect(() => {
    getUsersToEdit('', true)

    return () => {
      setUsers([])
      setSelectedUser(undefined)
      setFormValues(blankFormValues)
      onClose()
    }
  }, [])

  useEffect(() => {
    if (selectedUser) {
      setFormValues((prev) => {
        const hasCustomAttributes = selectedUser?.customAttributes
        return {
          ...prev,
          ...(hasCustomAttributes ? parseCustomAttributesFromApi(selectedUser.customAttributes as any) : {}),
          language: selectedUser.language,
          email: selectedUser.email,
        }
      })
    }
  }, [selectedUser])

  return (
    <Flex flexDirection='column'>
      <Text fontWeight={700} fontSize='1.125rem'>
        {t('manualEdit')}
      </Text>
      <Flex flexDir='column' gap='8px' mt='8px' maxH='500px' pr='4px' overflowY='auto' overflowX='hidden'>
        <ReactSelect
          styles={selectStyles()}
          loadingMessage={() => `${t('searchingUsers')}...`}
          noOptionsMessage={() => `${t('noUsersFound')}`}
          value={
            !selectedUser ? null : { value: selectedUser.id, label: `${selectedUser?.name} (${selectedUser.email})` }
          }
          placeholder={loadingInfos.selectedUser ? `${t('loadingUserData')}...` : t('searchForUsersName')}
          components={{ IndicatorSeparator: () => null }}
          onChange={loadingInfos.selectedUser ? () => null : handleSelectUser}
          onInputChange={(e) => debouncedSearchUser(e)}
          isLoading={loadingSearchUser || loadingInfos.selectedUser}
          options={users.map((u) => ({ value: u.id, label: `${u.name} (${u.email})` }))}
        />
        <Flex
          gap='8px'
          flexDir='column'
          pointerEvents={!selectedUser ? 'none' : 'auto'}
          cursor={!selectedUser ? 'not-allowed' : 'default'}
          opacity={!selectedUser ? 0.4 : 1}
        >
          {formOptions.map((props) => {
            if (shouldRenderSelectInput(props.id)) {
              return (
                <SelectWithLabel
                  isLoading={props.id === 'area' && loadingInfos.areas}
                  label={props.label}
                  isRequired={props.isRequired}
                  value={formValues[props.id]}
                  onChange={(e) => {
                    return setFormValues((prev) => ({ ...prev, [props.id]: e.target.value }))
                  }}
                  defaultValue={props.defaultValue}
                >
                  <option value='' disabled></option>
                  {props?.options?.map((opt) => (
                    <>
                      {props.id === 'area' ? (
                        <option key={opt.value} value={opt.label}>
                          {opt.label}
                        </option>
                      ) : (
                        <option key={opt.value} value={opt.value}>
                          {opt.label}
                        </option>
                      )}
                    </>
                  ))}
                </SelectWithLabel>
              )
            }

            return (
              <InputWithLabel
                label={props.label}
                isRequired={props.isRequired}
                placeholder={props.placeholder}
                value={formValues[props.id]}
                maxLength={props.maxLength}
                isReadOnly={props.id === 'email'}
                isDisabled={props.id === 'email'}
                onChange={(e) => {
                  if (props.id === 'email') return
                  if (props?.formatFunction) {
                    const value = props.formatFunction(e.target.value)
                    setFormValues((prev) => ({ ...prev, [props.id]: value }))
                    return
                  }
                  setFormValues((prev) => ({ ...prev, [props.id]: e.target.value }))
                }}
              />
            )
          })}
        </Flex>
      </Flex>
      <Flex alignItems='center' justify='space-between' mt='16px'>
        <Button variant='ghost' fontSize='0.875rem' py='0' px='8px' onClick={onClose}>
          {t('close')}
        </Button>
        <Button
          variant='startCourseDark'
          fontSize='0.875rem'
          py='0'
          px='8px'
          onClick={handleSubmitForm}
          onChange={(e) => debouncedSearchUser(e.currentTarget.value)}
          isDisabled={!verifyIfRequiredFieldsAreFilled()}
          isLoading={loadingInfos.submit}
        >
          {t('editCollab')}
        </Button>
      </Flex>
    </Flex>
  )
}
