import { Grid, Skeleton as ChakraSkeleton, Stack } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useWindowSize } from 'src/hooks/useWindowSize'

const Skeleton: React.FC = () => {
  const { width } = useWindowSize()
  const isMobile = useMemo(() => width && width < 992, [width])

  return (
    <Stack spacing={12} width='100%'>
      <ChakraSkeleton w='100px' h='25px' borderRadius='6px' />
      {isMobile ? (
        <>
          <Grid gridTemplateColumns='1fr' gap={4}>
            <ChakraSkeleton h='280px' borderRadius='36px' />
            <ChakraSkeleton h='280px' borderRadius='36px' />
            <ChakraSkeleton h='280px' borderRadius='36px' />
            <ChakraSkeleton h='280px' borderRadius='36px' />
          </Grid>
        </>
      ) : (
        <>
          <Grid gridTemplateColumns='1fr 1fr 1fr' gap={4}>
            <ChakraSkeleton h='280px' borderRadius='36px' />
            <ChakraSkeleton h='280px' borderRadius='36px' />
            <ChakraSkeleton h='280px' borderRadius='36px' />
          </Grid>
          <Grid gridTemplateColumns='1fr 1fr 1fr' gap={4}>
            <ChakraSkeleton h='280px' borderRadius='36px' />
            <ChakraSkeleton h='280px' borderRadius='36px' />
            <ChakraSkeleton h='280px' borderRadius='36px' />
          </Grid>
        </>
      )}
    </Stack>
  )
}

export default Skeleton
