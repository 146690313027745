import { Flex, Icon, Tag, TagLabel, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { MdOutlineAccessTime } from 'react-icons/md'
import { durationConverter } from 'src/helpers/duration'
import { getContentData } from 'src/helpers/contentInfo'
import Content from 'src/modules/cms/domain/Content'
import { useUser } from 'src/context/userContext'
import { getProficiencyLevel } from 'src/helpers/proeficiencyLevel'

interface Props {
  content: Content
  moduleName?: string
  showProficiency: boolean
}



export const HeaderInfo: React.FC<Props> = ({ content, moduleName, showProficiency }: Props) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const contentData = getContentData(content?.type, t)

  return (
    <Flex justifyContent='space-between' marginBottom='8px' w='100%'>
      <Flex>
        <Flex
          alignItems='center'
          padding={{ base: '2px 6px', md: '2px 12px' }}
          bg='#EAF2F5'
          borderRadius='100px'
          marginRight='8px'
        >
          <Text textTransform='capitalize' color='#767F89' fontSize='12px' fontWeight={600}>
            {contentData?.type}
          </Text>
        </Flex>

        {content.proficiencyLevel && showProficiency ? (
          <Tag
            backgroundColor={content.proficiencyLevel?.color || 'transparent'}
            borderRadius='23px 50px 8.55818px 20px'
            padding={{ base: '2px 6px', md: '2px 12px' }}
            color='white'
            fontWeight='700'
            fontSize='14px'
          >
            <TagLabel>{getProficiencyLevel(content.proficiencyLevel?.name || '', user?.language)}</TagLabel>
          </Tag>
        ) : (
          <></>
        )}

        {moduleName && (
          <Flex
            alignItems='center'
            padding={{ base: '2px 6px', md: '2px 12px' }}
            bg='rgba(127, 17, 214, 0.16)'
            borderRadius='100px'
          >
            <Text color='#720DAF' fontSize={{ base: '.75rem' }} fontWeight={600}>
              {moduleName}
            </Text>
          </Flex>
        )}
      </Flex>

      <Flex
        alignItems='center'
        gap='6px'
        padding={{ base: '0px 6px', md: '0px 12px' }}
        bg='#EAF2F5'
        borderRadius='100px'
      >
        <Icon as={MdOutlineAccessTime} color='#767F89' />
        <Text color='#767F89' fontSize='12px' fontWeight={600}>
          {durationConverter(content?.duration!)}
        </Text>
      </Flex>
    </Flex>
  )
}
