import { FormControl, FormLabel, Input } from '@chakra-ui/react'

interface TextInputProps {
  value?: string
  label?: string
  placeholder?: string
  isDisabled?: boolean
  onChange?: (value: string) => void
}

export function TextInput({ value, label, placeholder, isDisabled = false, onChange }: TextInputProps) {
  return (
    <FormControl pointerEvents={isDisabled ? 'none' : 'auto'} opacity={isDisabled ? 0.4 : 1}>
      {label && (
        <FormLabel fontSize='1rem' fontWeight='700'>
          {label}
        </FormLabel>
      )}
      <Input value={value} onChange={(e) => (onChange ? onChange(e.target.value) : undefined)} placeholder={placeholder} />
    </FormControl>
  )
}
