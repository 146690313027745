import { Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { getContentData } from 'src/helpers/contentInfo'
import Content from 'src/modules/cms/domain/Content'
import { dateNumberToString } from 'src/pages/LearningItem/utils'

interface Props {
  content: Content
  module: string
}

const NonCourse: React.FC<Props> = ({ content, module }: Props) => {
  const [t] = useTranslation()
  const contentData = getContentData(content ? content.type : '', t)
  const totalDurationInMinutes = content?.duration! / 60
  const durationInHours = totalDurationInMinutes / 60

  return (
    <Flex flexDirection='column'>
      <Flex
        w='100%'
        mt='24px'
        padding='15px 24px 15px 39px'
        bgColor='#F7F9FA'
        borderRadius='16px'
        alignItems='center'
        gap='28px'
      >
        <Image src='/assets/images/clock.png' />
        <Flex flexWrap='wrap' columnGap='6px'>
          <Text fontSize='18px' fontWeight={600}>
            {t('common.approximately')}
          </Text>
          <Text fontSize='18px' fontWeight={600} color='#009639'>
            {totalDurationInMinutes >= 60
              ? `${durationInHours === 1 ? '1 hora' : dateNumberToString(durationInHours)}`
              : `${totalDurationInMinutes} minutos`}
          </Text>
          <Text fontSize='18px' fontWeight={600}>
            {t('common.of')} {contentData?.durationLearningText}
          </Text>
        </Flex>
      </Flex>

      <Flex
        w='100%'
        mt='24px'
        padding='15px 24px 15px 39px'
        bgColor='#F7F9FA'
        borderRadius='16px'
        alignItems='center'
        gap='28px'
      >
        <Image src='/assets/images/personalized-target.svg' />
        <Flex flexWrap='wrap' columnGap='6px'>
          <Text fontSize='18px' fontWeight={600}>
            {t('common.drawer.Focus on skill development')}
          </Text>
          <Text fontSize='18px' fontWeight={600} color='#009639'>
            {module}
          </Text>
        </Flex>
      </Flex>

      <Flex
        w='100%'
        mt='24px'
        padding='15px 24px 15px 39px'
        bgColor='#F7F9FA'
        borderRadius='16px'
        alignItems='center'
        gap='28px'
      >
        <Image src='/assets/images/pdi.png' />
        <Flex flexWrap='wrap' columnGap='6px'>
          <Text fontSize='18px' fontWeight={600}>
            {t('common.drawer.This content is related to your')}
          </Text>
          <Text fontSize='18px' fontWeight={600} color='#009639'>
            PDI
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default NonCourse
