import { Box, Heading } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useQuiz } from 'src/context/QuizContext'
import useRegisterUserEvent from 'src/hooks/useRegisterUserEvent'
import { LevelType } from 'src/types/LevelType'
import ButtonsFooter from './ButtonsFooter'
import Level from './Level'
import StepHeader from './StepHeader'
import { generateLevelsObject, generateLevelsString } from './utils'

interface Props {
  onContinueButtonClick: () => void
  onBackButtonClick: () => void
  onSkipButtonClick: () => void
}

const StepFive: React.FC<Props> = ({ onContinueButtonClick, onBackButtonClick, onSkipButtonClick }: Props) => {
  const { quizResults, updateQuiz, sendMutationQuiz } = useQuiz()
  const [t] = useTranslation()
  const registerUserEvent = useRegisterUserEvent()
  
  const levels = generateLevelsObject(quizResults.skillsStartingPoint || [])

  const updateLevel = (level: LevelType, newLevel: number) => {
    const nLevels = levels.map((levelMap: LevelType) => {
      if (level.title === levelMap.title) levelMap.level = newLevel
      return levelMap
    })
    const levelsString = generateLevelsString(nLevels)

    updateQuiz('skillsStartingPoint', levelsString)
    updateQuiz('skillsEndingPoint', levelsString)
  }

  const handleNextStep = () => {
    sendMutationQuiz()
    onContinueButtonClick()
    registerUserEvent('quiz-tools-starting-level', quizResults.skillsStartingPoint || [])
  }

  return (
    <>
      <StepHeader step={5} />
      <Box flex='1'>
        <Heading size='xs' marginBottom='16px'>
          {t('quiz.stepFive.currentLevelOfExperience')}
        </Heading>
        {levels.map((level: LevelType) => (
          <Level
            key={level.title}
            title={level.title}
            level={level.level}
            onChangeLevel={(newLevel: number) => updateLevel(level, newLevel)}
          />
        ))}
      </Box>
      <ButtonsFooter
        step={5}
        disabledButton={false}
        onSkipButtonClick={onSkipButtonClick}
        onContinueButtonClick={handleNextStep}
        onBackButtonClick={onBackButtonClick}
      />
    </>
  )
}

export default StepFive
