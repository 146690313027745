import { Flex, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleTeam } from 'src/context-admin/useHandleTeam'
import { makeRemoveTeamManagers } from 'src/modules/dashboard/factory/makeRemoveTeamManagers'
import { NavigationButtons } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/NavigationButtons'
import { StepTitle } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTitle'
import { ManagerList } from './ManagerList'

export function EditExistingTeamRemoveManager() {
  const [t] = useTranslation()
  const toast = useToast()
  const { navigateToStep, team, handleSetTeamValues, handleFinalMessage } = useHandleTeam()
  const [loading, setLoading] = useState(false)
  const removeTeamManagers = makeRemoveTeamManagers()

  const handleRemoveManagers = async () => {
    setLoading(true)
    try {
      const response = await removeTeamManagers.execute({
        teamId: team?.id ?? '',
        managersIds: team?.newManagers?.map((item) => +item.id) ?? [],
      })
      if (!response?.id) {
        throw new Error()
      }
      handleFinalMessage(t('managersUpdated', { teamName: team?.name ?? t('invalidTeam') }))
      navigateToStep('success-screen')
    } catch (err: any) {
      toast({
        title: t('errorRemovingManagers'),
        description: String(err?.message),
        status: 'error',
        isClosable: true,
        duration: 7000,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Flex flexDir='column' gap='24px'>
      <StepTitle
        title={t('selectManagersToRemove')}
        subtitle={t('checkManagersToBeRemoved', { teamName: team?.name })}
      />
      <Flex flexDir='column'>
        <ManagerList users={team?.managers} />
      </Flex>
      <NavigationButtons
        isLoading={loading}
        isDisabled={team?.newManagers?.length === 0 || team?.managers?.length === team?.newManagers?.length}
        onContinueClick={() => {
          handleRemoveManagers()
        }}
        onBackClick={() => {
          handleSetTeamValues({ newManagers: undefined })
          navigateToStep('edit-existing-team--select-options')
        }}
      />
    </Flex>
  )
}
