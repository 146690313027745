import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleTeam } from 'src/context-admin/useHandleTeam'
import { NavigationButtons } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/NavigationButtons'
import { StepTitle } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTitle'
import { StepTransition } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTransition'
import { User } from 'src/types/User'
import { UserList } from '../../commonComponents/UserList'

export function EditExistingTeamAddNewManager() {
  const [t] = useTranslation()
  const [selectedManagers, setSelectedManagers] = useState<User[]>([])
  const { navigateToStep, handleSetTeamValues, team, usersList, getUsersList } = useHandleTeam()

  useEffect(() => {
    getUsersList({ variables: { areaId: 0 } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleSetTeamValues({
      newManagers: selectedManagers,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedManagers])

  return (
    <StepTransition>
      <Flex flexDir='column'>
        <StepTitle
          title={t('addingNewManagers')}
          subtitle={t('searchAndSelectTheManagersYouWant', { teamName: team?.name })}
        />
        <Flex w='100%' mt='24px'>
          <UserList
            data={usersList.filter(
              (u) =>
                !team?.managers?.map((manager) => manager.id).includes(Number(u.id)) &&
                !team?.users?.map((manager) => manager.id).includes(Number(u.id)),
            )}
            type='select-users'
            selectText={t('addAsManager')}
            onSelectUsers={setSelectedManagers}
          />
        </Flex>
        <NavigationButtons
          onContinueClick={() => navigateToStep('edit-existing-team--confirm-new-manager')}
          onBackClick={() => navigateToStep('edit-existing-team--select-options')}
          isContinueDisabled={selectedManagers.length <= 0}
        />
      </Flex>
    </StepTransition>
  )
}
