import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useUser } from 'src/context/userContext'
import { getMyTeams } from 'src/pages-admin/PlaylistDashboard/utils'
import {
  ColabCustomPlaylistResponse,
  Collaborator,
  PlaylistDashInfo,
  SkillsDashInfo,
} from '../pages-admin/PlaylistDashboard/Types'
import { useTeams } from './useTeams'

interface Value {
  collaborators: Collaborator[]
  generateCollaboratorsList: (newCollaboratorList: Collaborator[], order?: string) => void
  updateCollaboratorsList: (newCollaboratorList: Collaborator[], order?: string) => void
  isEngagementInfoOpen: boolean
  toggleEngagementInfo: () => void
  showPlaylistDrawer: boolean
  togglePlaylistDrawer: () => void
  playlistDrawer: ColabCustomPlaylistResponse
  updatePlaylistDrawer: (data: ColabCustomPlaylistResponse) => void
  area: number
  handleAreaChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  funnelStep: string
  funnelOrder: string
  skillsData: SkillsDashInfo
  skill: string | null
  status: string
  handleSkill: (skillId: string | null) => void
  setFunnelStep: React.Dispatch<React.SetStateAction<string>>
  setSkillsData: (skillsData: SkillsDashInfo) => void
  handleFunnelStep: (e: React.ChangeEvent<HTMLSelectElement>) => void
  handleOrder: (e: React.ChangeEvent<HTMLSelectElement>) => void
  setSkill: (skillId: string | null) => void
  setStatus: React.Dispatch<React.SetStateAction<string>>
  handleStatus: (e: React.ChangeEvent<HTMLSelectElement>) => void
  team: string
  handleSelectTeam: (value: string) => void
  resetFilters: () => void
  handleUpdateFirstTimeLoading: (value: boolean) => void
  isFirstTimeLoading: boolean
  collaboratorsCount: number | null
  updateCollaboratorsCount: (value: number) => void
  engagementData: PlaylistDashInfo
  updateEngagementData: (data: PlaylistDashInfo) => void
  tabs: string[]
  activeTab: string
  setActiveTab: React.Dispatch<React.SetStateAction<string>>
}

type ProviderProps = {
  children: React.ReactNode[] | React.ReactNode
}

type SortTypeByProgress = 'INCREASING_PROGRESS' | 'DESCENDING_COMPLETED'

const PlaylistDashboardContext = createContext({} as Value)

const tabs = ['Engajamento', 'Habilidades']

export function PlaylistDashboardProvider({ children }: ProviderProps) {
  const [collaborators, setCollaborators] = useState<Collaborator[]>([])
  const [isEngagementInfoOpen, setIsEngagementInfoOpen] = useState(true)
  const [showPlaylistDrawer, setShowPlaylistDrawer] = useState(false)
  const [area, setArea] = useState<number>(0)
  const [team, setTeam] = useState<string>('')
  const [funnelStep, setFunnelStep] = useState('')
  const [funnelOrder, setFunnelOrder] = useState('')
  const [skill, setSkill] = useState<string | null>(null)
  const [status, setStatus] = useState('')
  const [playlistDrawer, setPlaylistDrawer] = useState({} as ColabCustomPlaylistResponse)
  const [skillsData, setSkillsData] = useState({} as SkillsDashInfo)
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true)
  const [collaboratorsCount, setCollaboratorsCount] = useState<number | null>(null)
  const [engagementData, setEngagementData] = useState({} as PlaylistDashInfo)
  const [activeTab, setActiveTab] = useState('Engajamento')

  const { user } = useUser()
  const { teams } = useTeams()

  const setDefaultTeamForManagerUsers = (shouldResetTeam = false) => {
    if (user && teams) {
      if (shouldResetTeam) setTeam('')
      if (user?.roles?.includes('ADMIN')) return

      const myTeams = getMyTeams(teams, user)
      if (myTeams?.length > 0) {
        setTeam(myTeams[0]?.id)
      }
    }
  }

  useEffect(() => {
    setDefaultTeamForManagerUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, teams, activeTab])

  const sortCollaboratorsByProgress = useCallback((list: Collaborator[], type: SortTypeByProgress) => {
    if (!list || list.length <= 0) return []
    if (!type || (type !== 'INCREASING_PROGRESS' && type !== 'DESCENDING_COMPLETED')) return list

    const listToSort = [...list]

    return listToSort?.sort((a, b) => {
      if (a.hasCreatedPlaylist && !b.hasCreatedPlaylist) return -1
      if (!a.hasCreatedPlaylist && b.hasCreatedPlaylist) return 1
      return 0
    })
  }, [])

  const generateCollaboratorsList = useCallback(
    (newList: Collaborator[], order?: string) => {
      if (!order) {
        setCollaborators(newList)
        return
      }

      const sortedList = sortCollaboratorsByProgress(newList, order as SortTypeByProgress)
      setCollaborators(sortedList)
    },
    [sortCollaboratorsByProgress],
  )

  const updateCollaboratorsList = useCallback(
    (newList: Collaborator[], order?: string) => {
      if (!order) {
        setCollaborators((prevState) => [...prevState, ...newList])
        return
      }

      setCollaborators((prevState) => {
        const listToSort = [...prevState, ...newList]
        return sortCollaboratorsByProgress(listToSort, order as SortTypeByProgress)
      })
    },
    [sortCollaboratorsByProgress],
  )

  const toggleEngagementInfo = () => {
    setIsEngagementInfoOpen((prevState) => !prevState)
  }

  const togglePlaylistDrawer = () => {
    setShowPlaylistDrawer((prevState) => !prevState)
  }

  const updatePlaylistDrawer = useCallback((data) => {
    setPlaylistDrawer(data)
  }, [])

  const handleAreaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    // resetFilters()
    setArea(+value)
  }

  const handleSelectTeam = (value: string) => {
    // resetFilters()
    setTeam(value)
  }

  const handleFunnelStep = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    // resetFilters()
    setFunnelStep(value)
  }

  const handleOrder = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    setFunnelOrder(value)
  }

  const handleSkill = (skillId: string | null) => {
    // resetFilters()
    setSkill(skillId)
    setStatus('')
  }

  const handleStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    setStatus(value)
  }

  const resetFilters = () => {
    setSkill(null)
    setCollaborators([])
    setFunnelStep('')
    setStatus('')
    setFunnelOrder('')
    setDefaultTeamForManagerUsers(true)
    setIsFirstTimeLoading(true)
    setArea(0)
  }

  const handleUpdateFirstTimeLoading = (value: boolean) => {
    setIsFirstTimeLoading(value)
  }

  const updateCollaboratorsCount = (value: number) => {
    setCollaboratorsCount(value)
  }

  const updateEngagementData = (data: PlaylistDashInfo) => {
    setEngagementData(data)
  }

  return (
    <PlaylistDashboardContext.Provider
      value={{
        collaborators,
        generateCollaboratorsList,
        updateCollaboratorsList,
        isEngagementInfoOpen,
        toggleEngagementInfo,
        showPlaylistDrawer,
        togglePlaylistDrawer,
        playlistDrawer,
        updatePlaylistDrawer,
        area,
        handleAreaChange,
        funnelStep,
        funnelOrder,
        handleFunnelStep,
        handleOrder,
        setFunnelStep,
        skillsData,
        setSkillsData,
        skill,
        status,
        handleSkill,
        setSkill,
        setStatus,
        handleStatus,
        team,
        handleSelectTeam,
        resetFilters,
        handleUpdateFirstTimeLoading,
        isFirstTimeLoading,
        collaboratorsCount,
        updateCollaboratorsCount,
        updateEngagementData,
        engagementData,
        tabs,
        activeTab,
        setActiveTab,
      }}
    >
      {children}
    </PlaylistDashboardContext.Provider>
  )
}

export const usePlaylistDashboard = () => useContext(PlaylistDashboardContext)
