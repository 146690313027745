import { createContext, useCallback, useContext, useState } from 'react'
import Content from 'src/modules/cms/domain/Content'

export interface AddContentModuleInfo {
  title: string
  id: number
}

interface Value {
  isAddContentDrawerOpen: boolean
  addContentKnowMoreDrawer?: Content
  setAddContentKnowMoreDrawer: (item: Content | undefined) => void
  recommendedAddContentsIds: string[]
  setRecommendedAddContentsIds: (ids: string[]) => void
  toggleAddContentDrawer: () => void
  addContentModuleInfo?: AddContentModuleInfo
  setAddContentModuleInfo: (add: AddContentModuleInfo | undefined) => void
  newContents: Content[]
  setNewContents: (items: Content[]) => void
}

interface Props {
  children: React.ReactNode[] | React.ReactNode
}

const AddContentDrawerContext = createContext({} as Value)

export function AddContentDrawerProvider({ children }: Props) {
  const [isAddContentDrawerOpen, setIsAddContentDrawer] = useState<boolean>(false)
  const [addContentKnowMoreDrawer, setAddContentKnowMoreDrawer] = useState<Content | undefined>()
  const [recommendedAddContentsIds, setRecommendedAddContentsIds] = useState<string[]>([])
  const [addContentModuleInfo, setAddContentModuleInfo] = useState<AddContentModuleInfo | undefined>()
  const [newContents, setNewContents] = useState<Content[]>([])

  const toggleAddContentDrawer = useCallback(() => {
    setIsAddContentDrawer((prevState) => !prevState)
  }, [])

  return (
    <AddContentDrawerContext.Provider
      value={{
        isAddContentDrawerOpen,
        addContentKnowMoreDrawer,
        setAddContentKnowMoreDrawer,
        recommendedAddContentsIds,
        setRecommendedAddContentsIds,
        toggleAddContentDrawer,
        addContentModuleInfo,
        setAddContentModuleInfo,
        newContents,
        setNewContents,
      }}
    >
      {children}
    </AddContentDrawerContext.Provider>
  )
}

export const useAddContentDrawer = () => useContext(AddContentDrawerContext)
