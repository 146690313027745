import { useLazyQuery } from '@apollo/client'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Button, Flex, Grid, Image, Link, Stack, Text } from '@chakra-ui/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { TryRequestAgain } from 'src/components/TryRequestAgain'
import GET_USER_CREDENTIALS from 'src/graphql/queries/GET_USER_CREDENTIALS'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { useWindowSize } from 'src/hooks/useWindowSize'
import '../../services/i18n/i18n'
import EmailField from './EmailField'
import MyCredentialsSkeleton from './MyCredentialsSkeleton'
import PasswordField from './PasswordField'

interface UserCredentials {
  login: string
  password: string
  partner: {
    squareLogo: string
    accessLink: string
    slug: string
    name: string
  }
}

interface ReturnUserCredentials {
  GetUserCredentials: UserCredentials[]
}

const CredentialsTable: React.FC = () => {
  const [userCredentials, setUserCredentials] = useState<ReturnUserCredentials | undefined>(undefined)

  const [getMyCredentials, { error, loading }] = useLazyQuery<ReturnUserCredentials>(GET_USER_CREDENTIALS, {
    onCompleted: (data) => {
      setUserCredentials(data)
    },
  })
  const [t] = useTranslation()

  const { track, EventsImplementation } = useMixpanel()

  const { width } = useWindowSize()
  const isMobile = useMemo(() => width && width < 1100, [width])

  const handleCopyEvent = (): void => {
    track(EventsImplementation.COPY_CREDENTIAL(userCredentials?.GetUserCredentials[0]?.partner?.slug as string))
  }

  const renderLogin = userCredentials?.GetUserCredentials.map((credentials) => {
    return (
      <EmailField
        email={credentials.login}
        logo={credentials.partner.squareLogo}
        partnerName={credentials.partner.name}
      />
    )
  })
  const renderPassword = userCredentials?.GetUserCredentials.map((credentials) => {
    return <PasswordField password={credentials.password} />
  })
  const renderRedirectLink = userCredentials?.GetUserCredentials.map((credentials) => {
    return (
      <Link isExternal={true} href={credentials.partner.accessLink}>
        <Image w='24px' h='24px' src='/assets/images/external-link.svg' />
      </Link>
    )
  })

  const renderMobileContent = useCallback(() => {
    return (
      <Flex flexDir='column' flex='1' w='100%' h='100%'>
        <Text fontFamily='Poppins' fontWeight='bold' fontSize='1rem' color='gray.300' marginBottom='12px'>
          {t('credentials.Partners').toUpperCase()}
        </Text>
        <Flex flexDir='column' gap='12px' paddingBottom='100px' w='100%'>
          {userCredentials?.GetUserCredentials?.map((credential, index) => (
            <Flex
              key={index}
              flexDir='column'
              bg='gray.10'
              borderRadius='24px'
              border='1px solid #EFEFEF'
              w='100%'
              padding='12px'
            >
              <Flex>
                <Image
                  w='24px'
                  h='24px'
                  borderRadius='6px'
                  objectFit='cover'
                  bg='#EFEFEF'
                  src={credential?.partner?.squareLogo}
                  marginRight='15px'
                />
                <Text fontFamily='Poppins' fontWeight='bold' fontSize='1rem' color='gray.300' marginRight='15px'>
                  {credential?.partner?.name}
                </Text>
              </Flex>
              <Flex flex='1' flexDir='column'>
                <EmailField email={credential?.login} />
                <PasswordField password={credential?.password} />
              </Flex>
              <Link isExternal={true} href={credential?.partner?.accessLink} w='100%'>
                <Button variant='outline' marginTop='8px' d='flex' gap='12px' w='100%'>
                  <ExternalLinkIcon />
                  <Text fontSize='0.875rem'>{t('credentials.Direct Access').toUpperCase()}</Text>
                </Button>
              </Link>
            </Flex>
          ))}
        </Flex>
      </Flex>
    )
  }, [userCredentials?.GetUserCredentials, t])

  useEffect(() => {
    getMyCredentials()
  }, [getMyCredentials])

  if (loading) return <MyCredentialsSkeleton isMobile={!!isMobile} />

  return (
    <>
      <Helmet>
        <title> {t('common.My Credentials')} | Education Journey</title>
      </Helmet>

      {!loading && (error || !userCredentials) && (
        <Flex>
          <TryRequestAgain
            errorMessage={t('tryRequestAgain.credentials')}
            functionToExecute={() => getMyCredentials()}
            imageHeight={300}
          />
        </Flex>
      )}

      {!loading && !error && userCredentials && (
        <>
          {isMobile ? (
            <>{renderMobileContent()}</>
          ) : (
            <Grid templateColumns='2fr 1.5fr 0.5fr' h='100%'>
              <Stack flexDirection='column' paddingRight='20px' paddingTop='26px'>
                <Text fontFamily='Poppins' fontWeight='bold' fontSize='12px' color='gray.300' marginBottom='12px'>
                  {t('credentials.Partners').toUpperCase()}
                </Text>
                <Stack flexDirection='column' spacing={12} onClick={handleCopyEvent}>
                  {renderLogin}
                </Stack>
              </Stack>
              <Stack flexDirection='column' padding='26px 53px 0px 60px' bg='gray.25'>
                <Text fontFamily='Poppins' fontWeight='bold' fontSize='12px' color='gray.300' marginBottom='18px'>
                  {t('credentials.Passwords').toUpperCase()}
                </Text>
                <Stack flexDirection='column' spacing={12} onClick={handleCopyEvent}>
                  {renderPassword}
                </Stack>
              </Stack>
              <Stack flexDirection='column' paddingTop='26px' alignItems='center'>
                <Text fontFamily='Poppins' fontWeight='bold' fontSize='12px' color='gray.300' marginBottom='22px'>
                  {t('credentials.Direct Access').toUpperCase()}
                </Text>
                <Stack flexDirection='column' spacing={16}>
                  {renderRedirectLink}
                </Stack>
              </Stack>
            </Grid>
          )}
        </>
      )}
    </>
  )
}

export default CredentialsTable
