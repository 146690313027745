import Content from 'src/modules/cms/domain/Content'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { PlaylistPermission } from 'src/modules/cms/use_cases/ListPlaylistsPermissionsUseCase'
import { CmsCompany, CmsFiltersType, CmsPartner } from 'src/types/Cms'
import { create } from 'zustand'
import { CmsSkill } from '../../../modules/cms/domain/Skill'
import { ITab } from '../types/ITabs'

type PlaylistPrivacy = 'public' | 'private'

interface CorporateUniversityStore {
  activeTab: ITab
  updateActiveTab: (tab: ITab) => void

  cmsCourses: Content[]
  updateCmsCourses: (courses: Content[]) => void
  incrementCmsCourses: (courses: Content[]) => void

  totalCourses: number
  updateTotalCourses: (total: number) => void

  cmsPlaylists: NewPlaylist[]
  updateCmsPlaylists: (playlists: NewPlaylist[]) => void
  incrementCmsPlaylists: (playlists: NewPlaylist[]) => void

  totalPlaylists: number
  updateTotalPlaylists: (total: number) => void

  cmsPrivatePlaylists: NewPlaylist[]
  updateCmsPrivatePlaylists: (playlists: NewPlaylist[]) => void

  totalPrivatePlaylists: number
  updateTotalPrivatePlaylists: (total: number) => void

  playlistPermissions: PlaylistPermission[]
  updatePlaylistPermissions: (permission: PlaylistPermission[]) => void

  selectedPlaylistPrivacy: PlaylistPrivacy
  updateSelectedPlaylistPrivacy: (privacy: PlaylistPrivacy) => void

  cmsSkills: CmsSkill[]
  updateCmsSkills: (skills: CmsSkill[]) => void
  incrementCmsSkills: (skills: CmsSkill[]) => void

  totalSkills: number
  updateTotalSkills: (total: number) => void

  selectedContent: Content
  updateSelectedContent: (content?: Content) => void

  selectedPlaylist?: NewPlaylist
  updateSelectedPlaylist: (playlist?: NewPlaylist) => void

  isFirstTimeRequest: boolean
  updateIsFirstTimeRequest: (isFirstTimeRequest: boolean) => void

  limitPerPage: number
  updateLimitPerPage: (limit: number) => void

  skip: number
  updateSkip: (skip: number) => void

  searchValue: string
  updateSearchValue: (value: string) => void

  partnerList: CmsPartner[]
  updatePartnerList: (partnerList: CmsPartner[]) => void

  companyList: CmsCompany[]
  updateCompanyList: (companyList: CmsCompany[]) => void

  filters: { order: string; skill: string; partnerId: string; status: string; companies: number[]; languages: string[] }
  applyFilters: (type: CmsFiltersType, value?: string | number[] | string[]) => void
}

export const useCorporateUniversityStore = create<CorporateUniversityStore>((set) => ({
  activeTab: 'conteudos',
  updateActiveTab: (tab) => set(() => ({ activeTab: tab })),

  cmsCourses: [],
  updateCmsCourses: (courses) => set(() => ({ cmsCourses: courses })),
  incrementCmsCourses: (courses: Content[]) => set((current) => ({ cmsCourses: [...current.cmsCourses, ...courses] })),

  totalCourses: 0,
  updateTotalCourses: (total) => set(() => ({ totalCourses: total })),

  cmsPlaylists: [],
  updateCmsPlaylists: (playlists) => set(() => ({ cmsPlaylists: playlists })),
  incrementCmsPlaylists: (playlists) => set((current) => ({ cmsPlaylists: [...current.cmsPlaylists, ...playlists] })),

  totalPlaylists: 0,
  updateTotalPlaylists: (total) => set(() => ({ totalPlaylists: total })),

  cmsPrivatePlaylists: [],
  updateCmsPrivatePlaylists: (playlists) => set(() => ({ cmsPrivatePlaylists: playlists })),

  totalPrivatePlaylists: 0,
  updateTotalPrivatePlaylists: (total) => set(() => ({ totalPrivatePlaylists: total })),

  playlistPermissions: [],
  updatePlaylistPermissions: (item) => set(() => ({ playlistPermissions: item })),

  selectedPlaylistPrivacy: 'public',
  updateSelectedPlaylistPrivacy: (privacy) => set(() => ({ selectedPlaylistPrivacy: privacy })),

  cmsSkills: [],
  updateCmsSkills: (skills) => set(() => ({ cmsSkills: skills })),
  incrementCmsSkills: (skills) => set((current) => ({ cmsSkills: [...current.cmsSkills, ...skills] })),

  totalSkills: 0,
  updateTotalSkills: (total) => set(() => ({ totalSkills: total })),

  selectedContent: {} as Content,
  updateSelectedContent: (content) => set(() => ({ selectedContent: content })),

  selectedPlaylist: undefined,
  updateSelectedPlaylist: (playlist) => set(() => ({ selectedPlaylist: playlist })),

  isFirstTimeRequest: true,
  updateIsFirstTimeRequest: (isFirstTimeRequest) => set(() => ({ isFirstTimeRequest })),

  limitPerPage: 25,
  updateLimitPerPage: (limit) => set(() => ({ limitPerPage: limit })),

  skip: 0,
  updateSkip: (skip) => set(() => ({ skip })),

  searchValue: '',
  updateSearchValue: (value) => set(() => ({ searchValue: value })),

  partnerList: [],
  updatePartnerList: (partnerList) => set(() => ({ partnerList })),

  companyList: [],
  updateCompanyList: (companyList) => set(() => ({ companyList })),

  filters: { order: '', skill: '', partnerId: '', status: '', companies: [], languages: [] },
  applyFilters: (type, value) => set((state) => ({ filters: { ...state.filters, [type]: value } })),
}))
