import { PlaylistRepository } from '../repositories/PlaylistRepository'
import { ICreatePlaylist } from '../../../pages-admin/Cms/types/IPlaylist'

interface UpdatePlaylistUseCaseInput extends ICreatePlaylist {
  id: string
  private: boolean
}

export type UpdatePlaylistUseCaseOutput = boolean

export class UpdatePlaylistUseCase {
  constructor(private playlistRepository: PlaylistRepository) {}

  async execute(input: UpdatePlaylistUseCaseInput): Promise<UpdatePlaylistUseCaseOutput> {
    const content: UpdatePlaylistUseCaseInput = {
      id: input.id,
      title: input.title,
      type: input.type,
      status: input.status,
      allowedCompanyIds: Array.from(new Set([...input.allowedCompanyIds, 2])),
      modules: input.modules,
      imageUrl: input?.imageUrl ?? 'https://i.ibb.co/fS8fCDS/dummy-300x300-009136-ffffff-education-journey.png',
      cardImageUrl: input?.cardImageUrl ?? 'https://i.ibb.co/fS8fCDS/dummy-300x300-009136-ffffff-education-journey.png',
      description: input.description,
      color: input.color,
      certificateImageUrl: input.certificateImageUrl,
      certificateTemplateUrl: input.certificateTemplateUrl,
      learningPoints: [],
      private: input.private
    }

    const hasUpdated = await this.playlistRepository.update(content)
    return hasUpdated
  }
}
