import { Flex } from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import SideFilterCategoryItem from 'src/components/SideFilter/components/SideFilterCategoryItem'
import useExplore from 'src/hooks/useExplore'
import { Category } from 'src/types/Category'

export interface Response {
  categories: Category
}

const SideFilter: React.FC = () => {
  const { getAllSideList: getAllCategories } = useExplore()

  const [selectedCategorySubcategories, setSelectedCategorySubcategories] = useState<null | any[]>([])
  const shallowCategories = getAllCategories()

  const handleCategoryClick = useCallback(
    (id: string) => {
      const choosenCategoryIndex = shallowCategories.findIndex((c) => c.id === id)
      const subcategories = shallowCategories[choosenCategoryIndex].subCategories.map((item) => item)
      setSelectedCategorySubcategories(
        subcategories.flatMap((item) => (item.children.length === 0 ? item : item.children)),
      )
    },
    // eslint-disable-next-line
    [selectedCategorySubcategories, shallowCategories],
  )

  return (
    <Flex
      flexDir={'column'}
      justifyContent='start'
      w='35%'
      maxWidth={'300px'}
      top='12%'
      height='calc(100vh - 96px)'
      overflowY='auto'
      css={{
        '&::-webkit-scrollbar': {
          width: '5px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'rgba(0, 0, 0, 0.4)',
          borderRadius: '10px',
        },
      }}
    >
      {shallowCategories.map((category, index) => {
        return (
          <SideFilterCategoryItem
            key={index}
            handleCategoryClick={handleCategoryClick}
            name={category.name}
            id={category.id}
            selectedCategorySubcategories={selectedCategorySubcategories as any[]}
          />
        )
      })}
    </Flex>
  )
}

export default SideFilter
