import { gql } from '@apollo/client'

const REMOVE_ADMIN_ROLE = gql`
  mutation removeAdminRole($id: Int!) {
    removeAdminRole(id: $id) {
      id
      name
    }
  }
`

export default REMOVE_ADMIN_ROLE
