import { Flex, Select } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { useCorporateUniversityStore } from 'src/pages-admin/Cms/hooks/useCorporateUniversityStore'

export function SkillsFilter() {
  const [t] = useTranslation()
  const { filters, applyFilters } = useCorporateUniversityStore()
  const { allSkills, isLoadingSkills, getAllCmsSkills } = useCustomPlaylistQuiz()

  useEffect(() => {
    getAllCmsSkills({ variables: { limit: 500, skip: 0 } })

    return () => {
      applyFilters('skill', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex data-testid='cms-skill-filter'>
      <Select
        value={filters.skill}
        bgColor='#F7F9FA'
        border='none'
        _hover={{ bgColor: '#F7F9FA' }}
        _focus={{ bgColor: '#F7F9FA' }}
        fontWeight={600}
        fontSize='.875rem'
        minW='127px'
        height='22px'
        onChange={(e) => applyFilters('skill', e.target.value)}
        className={`select-default`}
      >
        <option value=''>{t('skills')}</option>
        {isLoadingSkills && (
          <option value='loading' disabled>
            {t('loading')}
          </option>
        )}
        {allSkills?.map((skill) => (
          <option key={skill?.id} value={skill?.id}>
            {skill?.name}
          </option>
        ))}
      </Select>
    </Flex>
  )
}
