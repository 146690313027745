import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import ExploreSkeleton from 'src/components/Explore/components/ExploreSkeleton'
import { ISkillTree, SkillChildren, useExploreContext } from 'src/context/exploreContext'
import useExplore from 'src/hooks/useExplore'
import { useWindowSize } from 'src/hooks/useWindowSize'
import { Category } from 'src/types/Category'
import CategoryItem from './components/CategoryItem'
import SkillLevel from './components/SkillLevel'
import Subcategory from './components/Subcategory'

export interface Response {
  categories: Category
}

interface ExploreProps {
  visibilityHandler: React.Dispatch<React.SetStateAction<boolean>>
}

export type ISubCategory = {
  name: string
  id: string
  children: SkillChildren[]
}

export type ICategory = {
  id: string
  name: string
  subCategories: ISubCategory[]
}

const Explore: React.FC<ExploreProps> = ({ visibilityHandler }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const windowSize = useWindowSize()
  const { getAll: getAllCategories } = useExplore()
  const { skillTreeData, setSkillTreeData } = useExploreContext()
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState<number>(0)
  const shallowCategories = getAllCategories()

  const handleCategoryClick = useCallback(
    (id: string) => {
      const choosenCategoryIndex = shallowCategories.findIndex((item) => item.id === id)

      setCurrentCategoryIndex(choosenCategoryIndex)

      const subcategories = shallowCategories[choosenCategoryIndex].subCategories.map((item) => item)
      setSkillTreeData((prevState) => ({
        ...prevState,
        subCategoryList: subcategories,
        rootId: id,
        subCategoryId: '',
        skillList: [],
      }))
    },
    // eslint-disable-next-line
    [shallowCategories],
  )

  if (!windowSize.height) return <ExploreSkeleton />

  const maxHeight = windowSize.height - 100

  return (
    <>
      <Box
        position='fixed'
        w='95%'
        h='95%'
        onClick={() => {
          if (!location.pathname.includes(skillTreeData.skillId)) setSkillTreeData({} as ISkillTree)
          visibilityHandler(false)
        }}
      />
      <Box
        h='600px'
        maxH={`${maxHeight}px`}
        borderRadius='30px'
        bg='white'
        border='2px solid rgba(229, 229, 229, 1)'
        boxShadow='0px 1px 26px 0px rgba(0, 0, 0, 0.16)'
        padding='20px'
        d='flex'
        transition={'all 0.3s ease-in-out'}
        position='absolute'
        overflowX='clip'
        left='5%'
      >
        <Flex flexDir='column'>
          <Text color='gray.800' fontWeight={'700'} fontSize='18px' fontFamily='Poppins'>
            {t('common.exploreCategories')}
          </Text>
          <Flex maxHeight='100%' paddingBottom='16px'>
            <Box
              marginTop='16px'
              d='flex'
              alignItems='center'
              flexDirection='column'
              h='500px'
              maxHeight='100%'
              pos='relative'
            >
              {shallowCategories?.map((category, index) => {
                return <CategoryItem key={index} handleCategoryClick={handleCategoryClick} {...category} />
              })}
            </Box>

            {skillTreeData.subCategoryList && skillTreeData.subCategoryList?.length !== 0 && (
              <Box
                marginTop='16px'
                paddingLeft='15px'
                borderLeft='1px solid #D9D9D9'
                marginLeft='10px'
                maxHeight={'100%'}
                minW='350px'
                overflow='scroll'
                overflowX='hidden'
                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'gray.800',
                    borderRadius: '24px',
                  },
                }}
              >
                {skillTreeData?.subCategoryList?.map(
                  (item: { id: string; name: string; children: SkillChildren[] }, index: number) => {
                    return (
                      <Subcategory
                        key={index}
                        subCategoryId={item.id}
                        name={item.name}
                        skills={item.children}
                        rootId={shallowCategories[currentCategoryIndex].id}
                        visibilityHandler={visibilityHandler}
                      />
                    )
                  },
                )}
              </Box>
            )}

            {skillTreeData.skillList && skillTreeData.skillList?.length !== 0 && (
              <Box
                marginTop='16px'
                paddingLeft='15px'
                borderLeft='1px solid #D9D9D9'
                marginLeft='10px'
                maxHeight={'100%'}
                minW='350px'
                overflow='scroll'
                overflowX='hidden'
                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'gray.800',
                    borderRadius: '24px',
                  },
                }}
              >
                {skillTreeData.skillList?.map((item: { id: string; name: string }, index: number) => {
                  return (
                    <SkillLevel
                      key={index}
                      visibilityHandler={visibilityHandler}
                      skillId={item.id}
                      skillName={item.name}
                      rootId={shallowCategories[currentCategoryIndex].id}
                    />
                  )
                })}
              </Box>
            )}
          </Flex>
        </Flex>
      </Box>
    </>
  )
}

export default Explore
