import { Text } from '@chakra-ui/react'

export function renderRequiredLabel(text: string) {
  return (
    <>
      {text}
      <Text as='span' color='red'>
        *
      </Text>
    </>
  )
}
