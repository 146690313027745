import { getAccessToken } from "src/modules/iam/apis/store";
import { cmsClient } from "src/services/http/cms-client";
import { IInternalizationRepository, InternationalizationRepository } from "../repository/InternationalizationRepository";

export class GetTranslationByLanguageUseCase {
  constructor(private repository: IInternalizationRepository) { }

  async execute(language: string, text: string): Promise<string> {
    const translatedText = await this.repository.getTranslationByLanguage(language, text)
    return translatedText ?? ''
  }
}

export function makeGetTranslationByLanguage() {
  const token = getAccessToken()
  const repo = new InternationalizationRepository(token, cmsClient)
  return new GetTranslationByLanguageUseCase(repo)
}