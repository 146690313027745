/* eslint-disable no-extra-boolean-cast */
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Input,
  Select,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { FormikProps } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { debounce } from 'src/helpers-admin/debounce'
import { Team } from 'src/modules/dashboard/entities/Team'
import { makeListTeams } from 'src/modules/dashboard/factory/makeListTeams'
import { ListTeamsInput } from 'src/modules/dashboard/use-case/ListTeamsUseCase'
import { IContentData } from 'src/pages-admin/Cms/hooks/useRegisterContentStore'
import { useRegisterNewContent } from '../../hooks/useRegisterNewContent'

const LocalTh = ({ children }: any) => (
  <Th
    letterSpacing='initial'
    color='gray.800'
    py='16px'
    fontWeight={700}
    textTransform='capitalize'
    fontFamily='Mulish'
    fontSize='1.125rem'
  >
    {children}
  </Th>
)

type Filters = {
  name?: string
  orderBy?: string
}

interface SelectCompaniesDrawerProps {
  isOpen: boolean
  onClose: () => void
  form: FormikProps<IContentData>
  setSelectedTeams: React.Dispatch<React.SetStateAction<Team[]>>
  selectedTeams: Team[]
}

export function SelectSpecificUsersPlaylistRestriction({
  isOpen,
  onClose,
  form,
  selectedTeams,
  setSelectedTeams,
}: SelectCompaniesDrawerProps) {
  const [teams, setTeams] = useState<Team[]>([])
  const [isLoadingTeams, setIsLoadingTeams] = useState(false)
  const [isFisrtGetTeams, setIsFisrtGetTeams] = useState(false)
  const [filters, setFilters] = useState<Filters>({
    name: undefined,
    orderBy: undefined,
  })

  const [t] = useTranslation()
  const {
    updateSelectedTeamsToRestrict,
    updateSelectedPermission,
    selectedTeamsToRestrict,
  } = useRegisterNewContent()

  const getTeams = async (params?: ListTeamsInput) => {
    try {
      setIsLoadingTeams(true)
      const listTeams = makeListTeams()
      const apiTeams = await listTeams.execute(params)
      setTeams(apiTeams)

      if (!isFisrtGetTeams) {
        const selectedTeams = apiTeams.filter((team) => selectedTeamsToRestrict.some((item) => team.id === item.id))
        updateSelectedTeamsToRestrict(selectedTeams)
        setSelectedTeams(selectedTeams)
        setIsFisrtGetTeams(true)
      }
    } catch (err) {
      console.error('error on getTeams', err)
    } finally {
      setIsLoadingTeams(false)
    }
  }

  const handleCloseDrawer = () => {
    const allowedCompanyIds = form.values.allowedCompanyIds ?? []
    if (allowedCompanyIds.length === 0 || allowedCompanyIds[0] === -1) {
      updateSelectedPermission('allUsers')
    }

    onClose()
  }

  const handleSelectTeam = (team: Team) => {
    const selectedTeam = selectedTeams.find((item) => item.id === team.id)
    if (!selectedTeam) {
      setSelectedTeams((prevState) => [...prevState, team])
      return
    }
    setSelectedTeams((prevState) => prevState.filter((item) => item.id !== team.id))
  }

  const handleSave = () => {
    updateSelectedTeamsToRestrict(selectedTeams)
    onClose()
  }

  const handleSearchForTeams = (value?: string) => {
    setFilters((prev) => ({ ...prev, name: !value ? undefined : value }))
  }

  const searchForTeamsDebounced = debounce(handleSearchForTeams, 750)

  const resetFilters = () => {
    setFilters({ name: undefined, orderBy: undefined })
  }

  useEffect(() => {
    return () => {
      setIsLoadingTeams(false)
      setSelectedTeams([])
      resetFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getTeams(filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const teamsWithOutSelecteds = useMemo(() => {
    return teams.filter((team) => selectedTeams.findIndex((t) => t.id === team.id) === -1)
  }, [selectedTeams, teams])

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={handleCloseDrawer} size='xl' closeOnEsc={false}>
      <DrawerOverlay />
      <DrawerContent overflowY='auto'>
        <Box padding={{ base: '40px' }} h='100%'>
          <DrawerHeader fontWeight={700} fontSize='1.25rem'>
            {t('filter')} - {t('teams')}
          </DrawerHeader>

          <DrawerBody>
            <Text fontWeight={600} fontSize='1.125rem'>
              {t('selectTheTeamsThatWillHaveAccessToThisPlaylist')}
            </Text>

            <Flex flexDir='column' mt='16px' gap='8px'>
              <Text fontWeight={700} fontSize='1rem'>
                {t('filters')}
              </Text>
              <Flex gap='8px'>
                <Select
                  defaultValue={''}
                  height='36px'
                  borderColor='gray.800'
                  maxW='140px'
                  onChange={(e) =>
                    setFilters((prev) => ({ ...prev, orderBy: !e.target.value ? undefined : e.target.value }))
                  }
                >
                  <option value={''}>Ordenar</option>
                  <option value='NAME_ASC'>{t('nameFromAtoZ')}</option>
                  <option value='NAME_DESC'>{t('nameFromZtoA')}</option>
                  <option value='USERS_ASC'>{t('lessUsers')}</option>
                  <option value='USERS_DESC'>{t('moreUsers')}</option>
                </Select>
                <Input
                  height='36px'
                  p='0px 12px'
                  px='0px'
                  m='0'
                  placeholder={t('searchForTeams')}
                  onChange={(e) => searchForTeamsDebounced(e.target.value)}
                />
              </Flex>
            </Flex>

            <TableContainer
              shadow='0px 1px 10px 0px rgba(0, 0, 0, 0.1)'
              borderRadius='16px'
              mt='16px'
              maxH={{ base: '334px', lg: '384px' }}
              overflowY='auto'
            >
              <Table variant='simple'>
                <Thead>
                  <Tr bg='#F7F9FA'>
                    <LocalTh>
                      <Checkbox defaultChecked isDisabled colorScheme='blackAlpha' borderRadius='8px' />
                    </LocalTh>
                    <LocalTh>{t('name')}</LocalTh>
                    {/* <LocalTh>{t('company')}</LocalTh> */}
                    <LocalTh>{t('users')}</LocalTh>
                  </Tr>
                </Thead>
                <Tbody>
                  {isLoadingTeams && (
                    <>
                      {Array.from({ length: 4 }).map((_, i) => (
                        <Tr key={i} w='100%'>
                          <Td p='0'>
                            <Skeleton w='100%' h='53px' borderRadius={0} />
                          </Td>
                          <Td p='0'>
                            <Skeleton w='100%' h='53px' borderRadius={0} />
                          </Td>
                          {/* <Td p='0'>
                            <Skeleton w='100%' h='53px' borderRadius={0} />
                          </Td> */}
                          <Td p='0'>
                            <Skeleton w='100%' h='53px' borderRadius={0} />
                          </Td>
                        </Tr>
                      ))}
                    </>
                  )}
                  {!isLoadingTeams &&
                    [...selectedTeams, ...teamsWithOutSelecteds]?.map((team) => (
                      <Tr key={team?.id}>
                        <Td py='16px'>
                          <Checkbox
                            colorScheme='green'
                            isChecked={selectedTeams?.findIndex((t) => team.id === t.id) !== -1}
                            onChange={() => handleSelectTeam(team)}
                          />
                        </Td>
                        <Td py='16px' fontSize='1.125rem' fontFamily='Mulish' color='#767F89'>
                          {team?.name}
                        </Td>
                        {/* <Td py='16px' fontSize='1.125rem' fontFamily='Mulish' color='#767F89'>
                          Lorem ipsum Dolor
                        </Td> */}
                        <Td py='16px' fontSize='1.125rem' fontFamily='Mulish' color='#767F89'>
                          {team?.members?.length}
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>

            <Flex justify='space-between' mt='56px'>
              <Button variant='link' textDecoration='underline' onClick={handleCloseDrawer}>
                {t('back')}
              </Button>
              <Button variant='startCourseDark' onClick={handleSave}>
                {t('save')}
              </Button>
            </Flex>
          </DrawerBody>
        </Box>
      </DrawerContent>
    </Drawer>
  )
}
