import { LazyQueryExecFunction, OperationVariables, useLazyQuery } from '@apollo/client'
import { useDisclosure } from '@chakra-ui/react'
import _ from 'lodash'
import { createContext, useContext, useEffect, useState } from 'react'
import LIST_USERS from 'src/graphql/queries/LIST_USERS'
import { makeListTeams } from 'src/modules/dashboard/factory/makeListTeams'
import { ListTeamsOutput } from 'src/modules/dashboard/use-case/ListTeamsUseCase'
import { availableSteps } from 'src/pages-admin/Home/components/Teams/components/HandleTeamDrawer/availableSteps'
import { AvailableStepsType, StepWithElement } from 'src/types/HandleTeamDrawer'
import { User } from 'src/types/User'

export type Team = {
  id?: string
  name?: string
  managers?: User[]
  newManagers?: User[]
  users?: User[]
  newUsers?: User[]
}

type PageInfo = {
  count: number
  hasNextPage: boolean
  hasPreviousPage: boolean
  next: string
  total: number
}

type ListUsersQuery = {
  listUsers: {
    data: User[]
    paginateInfo: PageInfo
  }
}

type Filters = {
  area?: number
  userName?: string
  teamName?: string
  order?: string
}

interface HandleTeamContextProps {
  // navigation with steps
  availableSteps: StepWithElement
  activeStep: AvailableStepsType
  navigateToStep: (stepId: AvailableStepsType) => void
  goToFirstStep: () => void
  // Disclosure drawer
  isHandleTeamDrawerOpen: boolean
  onCloseHandleTeamDrawer: () => void
  onOpenHandleTeamDrawer: () => void
  // area
  team?: Team
  teams: ListTeamsOutput
  handleSetTeamValues: (team?: Team) => void
  getTeams: (page?: number) => void
  loadingTeams: boolean
  // final screen
  finalMessage: string
  handleFinalMessage: (message: string) => void
  // users
  usersList: User[]
  loadingUsers: boolean
  pageInfo?: PageInfo
  getUsersList: LazyQueryExecFunction<ListUsersQuery, OperationVariables>
  fetchMoreUsers: () => Promise<void>
  increaseUsersList: (users: User[]) => void
  definesUsersList: (users?: User[]) => void
  // users filters
  filters: Filters
  definesUserNameFilter: (userName: string) => void
  definesTeamNameFilter: (userName: string) => void
  definesAreaFilter: (area: number) => void
  definesOrderFilter: (order: string) => void
}

export const teamWithEmptyValues: Team = {
  id: undefined,
  managers: undefined,
  newManagers: undefined,
  name: undefined,
  users: undefined,
  newUsers: undefined,
}

const HandleTeamContext = createContext({} as HandleTeamContextProps)

export const HandleTeamProvider: React.FC = ({ children }) => {
  // team
  const listTeams = makeListTeams()
  const [loadingTeams, setLoadingTeams] = useState(false)
  const [teams, setTeams] = useState<ListTeamsOutput>([])
  const [activeStep, setActiveStep] = useState<AvailableStepsType>('add-new-team')
  const [team, setTeam] = useState<Team>(teamWithEmptyValues)
  const [finalMessage, setFinalMessage] = useState<string>('')
  // user
  const [usersList, setUsersList] = useState<User[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo | undefined>(undefined)
  const [filters, setFilters] = useState<Filters>({ area: 0, userName: '' })

  const {
    isOpen: isHandleTeamDrawerOpen,
    onClose: onCloseHandleTeamDrawer,
    onOpen: onOpenHandleTeamDrawer,
  } = useDisclosure()

  const increaseUsersList = (users: User[]) => {
    setUsersList((oldUsers) => {
      const uniqueUsers = _.uniqBy([...oldUsers, ...users], 'id')
      return uniqueUsers
    })
  }

  const definesUsersList = (users?: User[]) => {
    if (!users || users?.length <= 0) return []
    const uniqueUsers = _.uniqBy(users, 'id')
    setUsersList(uniqueUsers ?? [])
  }

  const [getUsersList, { refetch: refetchUsers, loading: loadingUsers }] = useLazyQuery<ListUsersQuery>(LIST_USERS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: 25,
      // eslint-disable-next-line no-extra-boolean-cast
      areaId: !!filters.area ? filters.area : 0,
      userName: filters.userName,
      ...(filters.userName ? { name: filters.userName } : { name: '' }),
    },
    onCompleted: (data) => {
      definesUsersList(data.listUsers.data)
      setPageInfo(data.listUsers.paginateInfo)
    },
  })

  const navigateToStep = (stepId: AvailableStepsType) => {
    setActiveStep(stepId)
  }

  const goToFirstStep = () => {
    setActiveStep('add-new-team')
    setTeam(teamWithEmptyValues)
    setFinalMessage('')
    setFilters({ area: 0, userName: '' })
    setPageInfo({ count: 0, hasNextPage: false, hasPreviousPage: false, next: '', total: 0 })
    setUsersList([])
    getTeams()
  }

  const handleSetTeamValues = (newTeamValues?: Team) => {
    setTeam((oldAreaValues) => ({ ...oldAreaValues, ...newTeamValues }))
  }

  const handleFinalMessage = (message: string) => {
    setFinalMessage(message)
  }

  const fetchMoreUsers = async () => {
    if (!pageInfo?.next) return

    const response = await refetchUsers({
      limit: 25,
      cursor: Number(pageInfo?.next),
    })

    increaseUsersList(response.data.listUsers.data)
    setPageInfo(response.data.listUsers.paginateInfo)
  }

  const definesUserNameFilter = (userName: string) => {
    setFilters((oldFilters) => ({ ...oldFilters, userName }))
  }

  const definesTeamNameFilter = (teamName: string) => {
    setFilters((oldFilters) => ({ ...oldFilters, teamName }))
  }

  const definesAreaFilter = (area: number) => {
    setFilters((oldFilters) => ({ ...oldFilters, area }))
  }

  const definesOrderFilter = (order: string) => {
    setFilters((oldFilters) => ({ ...oldFilters, order }))
  }

  const getTeams = async () => {
    try {
      setLoadingTeams(true)
      const teams = await listTeams.execute()
      setTeams(teams)
    } catch (err) {
      console.error('Ocorreu um erro ao buscar as equipes')
    } finally {
      setLoadingTeams(false)
    }
  }

  useEffect(() => {
    getTeams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    refetchUsers({
      limit: 25,
      // eslint-disable-next-line no-extra-boolean-cast
      areaId: !!filters.area ? filters.area : 0,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.area])

  return (
    <HandleTeamContext.Provider
      value={{
        // steps
        availableSteps,
        navigateToStep,
        goToFirstStep,
        activeStep,
        // drawer
        isHandleTeamDrawerOpen,
        onCloseHandleTeamDrawer,
        onOpenHandleTeamDrawer,
        // team
        team,
        teams,
        handleSetTeamValues,
        getTeams,
        loadingTeams,
        // final screen
        finalMessage,
        handleFinalMessage,
        // users
        usersList,
        loadingUsers,
        pageInfo,
        getUsersList,
        fetchMoreUsers,
        increaseUsersList,
        definesUsersList,
        // users filters
        filters,
        definesUserNameFilter,
        definesTeamNameFilter,
        definesAreaFilter,
        definesOrderFilter,
      }}
    >
      {children}
    </HandleTeamContext.Provider>
  )
}

export const useHandleTeam = () => {
  const context = useContext(HandleTeamContext)
  if (!context) throw new Error('useHandleTeam must be used within a HandleTeamProvider')
  return context
}
