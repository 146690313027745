import { Button, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import { OptionTypeBase } from 'react-select'
import { CompanySelection } from '../shared/CompanySelection'
import { TabTitle } from '../shared/TabTitle'
import { ConfirmModal } from './ConfirmModal'

const CONFIRMATION_TEXT = 'Quero desativar essa empresa'

export function CompanyDeactivation() {
  const [selectedCompany, setSelectedCompany] = useState<OptionTypeBase | null>(null)
  const { onOpen, onClose, isOpen } = useDisclosure()

  return (
    <>
      <Flex flexDir='column' w='100%'>
        <Flex flexDir='column' gap='4px' mb='32px'>
          <TabTitle label='Desativar Empresa' showMarginBotton={false} />
          <Text color='#313E4C' fontSize='0.875rem'>
            Você deve escolher uma empresa, no seletor abaixo, para ser desativada.
          </Text>
          <Text color='#313E4C' fontSize='0.875rem'>
            Uma tela de confirmação será exibida antes de desativar a empresa.
          </Text>
        </Flex>
        <Flex flexDir='column'>
          <CompanySelection
            name='desativar-empresa'
            label='Empresa'
            placeholder='Selecione uma empresa'
            selectedCompany={selectedCompany}
            handleSelectCompany={setSelectedCompany}
          />
          <Flex mt='32px' gap='16px'>
            <Button
              variant='outline'
              w='fit-content'
              h='44px'
              fontSize='0.875rem'
              padding='0px 16px'
              isDisabled={!selectedCompany}
              onClick={() => setSelectedCompany(null)}
            >
              Limpar seleção
            </Button>
            <Button
              type='submit'
              variant='startCourseDark'
              h='44px'
              fontSize='0.875rem'
              padding='8px 16px'
              isDisabled={!selectedCompany}
              onClick={onOpen}
            >
              Prosseguir
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <ConfirmModal isOpen={isOpen} onClose={onClose} selectedCompany={selectedCompany} />
    </>
  )
}
