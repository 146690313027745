import { Box, Flex, Icon, Image, Text } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { MenuPartnerLeft } from '../../theme/Icons'

export interface Item {
  title: string
  url: string
  image: string
}

export interface Props {
  items: Item[] | undefined
  onClick?: () => void
}

// `/partner/${partner.slug}`

const MenuList: React.FC<Props> = ({ items, onClick = () => undefined }: Props) => {
  const location = useLocation()
  const { EventsImplementation, track } = useMixpanel()

  return (
    <Flex flexDirection='column'>
      {items?.map((item: any, index: number) => {
        const active = location.pathname === item.url
        return (
          <Box marginBottom='10px' key={item.url} onClick={() => (onClick ? onClick() : undefined)}>
            <Link to={item.url}>
              <Flex position='relative'>
                <Box
                  position='absolute'
                  background='gray.70'
                  w='2px'
                  h={index !== 0 ? '36px' : '0px'}
                  left='0'
                  top='-33px'
                />
                <Icon as={MenuPartnerLeft} position='absolute' top='2px' />
                <Flex
                  onClick={() => {
                    track(EventsImplementation.SIDEBAR_REDIRECT(location.pathname, item.url))
                  }}
                  background={active ? 'gray.90' : 'transparent'}
                  borderRadius='9px'
                  padding='6px 9px'
                  color={active ? 'gray.800' : 'bluishGray.400'}
                  fontWeight={active ? '600' : '500'}
                  transition='background 0.3s'
                  width='100%'
                  _hover={{
                    background: 'gray.90',
                  }}
                  marginLeft='15px'
                >
                  <Image w='20px' src={item.image} />
                  <Text marginLeft='5px' fontSize='14px' fontFamily='Poppins' minW='fit-content'>
                    {item.title}
                  </Text>
                </Flex>
              </Flex>
            </Link>
          </Box>
        )
      })}
    </Flex>
  )
}

export default MenuList
