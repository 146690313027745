import { UserRepository } from '../repository/UserRepository'

interface UpdateUserPreferencesInput {
  languages: string[]
  subtitles: string[]
}

type UpdateUserPreferencesOutput = any

export class UpdateUserPreferencesUseCase {
  constructor(private userRepository: UserRepository) {}

  async execute(args: UpdateUserPreferencesInput): Promise<UpdateUserPreferencesOutput> {
    const hasCreatedUserPreferences = await this.userRepository.updateUserPreferences(args)
    return hasCreatedUserPreferences
  }
}
