import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'
import Button from './Button'
import Heading from './Heading'
import Input from './Input'
import Select from './Select'
import Tag from './Tag'

// 2. Update the breakpoints as key-value pairs
const breakpoints = createBreakpoints({
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1440',
})

export default extendTheme({
  colors: {
    primaryColor: '#008023',
    primaryColors: {
      10: '#FEFFFF',
      20: '#F5FBF6',
      30: '#EAF2F5',
      40: '#E7F5ED',
      45: '#E5F4EB',
      50: '#deffe9',
      100: '#b1ffc7',
      200: '#80ffa3',
      300: '#4fff7f',
      340: '#13CE66',
      350: '#00E88F',
      400: '#009028',
      500: '#15e643',
      550: '#0BB44C',
      600: '#08b333',
      650: '#009639',
      700: '#008023',
      750: '#048B3F',
      780: '#046A38',
      800: '#0D6B38',
      900: '#001b02',
    },
    breakpoints,
    secondaryColor: '#EAE002',
    secondaryColors: {
      50: '#ffffdb',
      100: '#fefbad',
      200: '#fef97e',
      300: '#fdf64c',
      400: '#FCDB3A',
      500: '#e4da02',
      600: '#EACA2F',
      700: '#7e7900',
      800: '#4c4900',
      900: '#1a1800',
    },
    tertiaryColor: '#1a202c',
    tertiaryColors: {
      50: '#edf1fc',
      100: '#cfd5e3',
      200: '#b0bacd',
      300: '#919eb9',
      400: '#7282a5',
      500: '#58698b',
      600: '#44526d',
      700: '#303a4e',
      800: '#1c2330',
      900: '#050c15',
    },
    backgrounds: '#FFFFFFF',
    backgroundColors: {
      50: '#F7F9FD',
    },
    quaternaryColor: '#009028',
    basic: '#0066FF',
    intermediate: '#009028',
    advanced: '#7F11D6',
    executive: '#FF9900',
    success: '#21A453',
    error: '#D44333',
    errorLight: '#F8E7E5',
    warning: '#FFC043',
    white: '#FFFFFF',
    blue: {
      400: '#2F80ED',
    },
    yellow: {
      400: '#FFC82C',
    },
    gray: {
      10: '#FEFEFE',
      20: '#fbfbfb',
      25: '#FAFAFA',
      29: '#F9F9F9',
      30: '#F6F6F6',
      40: '#F5F5F5',
      43: '#f4f4f4',
      45: '#f3f3f3',
      50: '#f2f2f2',
      55: '#EFEFEF',
      60: '#EDEDED',
      65: '#EAEAEA',
      70: '#E5E5E5',
      75: '#E9E9E9',
      80: '#eeeeee',
      90: '#ECEDED',
      100: '#d9d9d9',
      150: '#D7D7D7',
      180: '#C7C7C7',
      190: '#C4C4C4',
      200: '#bfbfbf',
      250: '#acacac',
      260: '#AAAAAA',
      290: '#ABABAB',
      300: '#a6a6a6',
      350: '#959595',
      400: '#8c8c8c',
      500: '#737373',
      550: '#666666',
      580: '#626262',
      600: '#595959',
      640: '#484848',
      650: '#464646',
      690: '#444444',
      700: '#404040',
      750: '#373737',
      760: '#2D3748',
      800: '#313E4C',
      900: '#0d0d0d',
    },
    bluishGray: {
      45: '#F6F9FA',
      50: '#EBECEE',
      100: '#E1E3E4',
      200: '#C1C3C6',
      300: '#898890',
      400: '#6C757F',
      500: '#767F89',
    },
    red: {
      200: '#FF5757',
      300: '#FF4949',
    },
    orange: {
      500: '#cf8c0f',
      600: '#b67b0d',
    },
  },
  fonts: {
    body: 'Mulish, sans-serif',
    heading: 'Mulish, sans-serif',
    mono: 'Mulish, sans-serif',
  },
  fontSizes: {
    '2xl': '1.8rem',
    xl: '1.5rem',
    '2lg': '1.3rem',
    lg: '1.1rem',
    md: '1rem',
    sm: '0.9rem',
    xs: '0.8rem',
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 600,
    extrabold: 800,
    black: 900,
  },
  components: {
    Button: Button,
    Input: Input,
    Select: Select,
    Tag: Tag,
    Heading: Heading,
  },
})
