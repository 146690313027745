import { Box, Flex, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useIntersectionObserver } from 'react-intersection-observer-hook'
import { useHistory, useLocation } from 'react-router-dom'
import { useUser } from 'src/context/userContext'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import NewPlaylist from 'src/modules/cms/domain/Playlist'

interface Props {
  playlist: NewPlaylist
  recommended?: boolean
  isPersonalized?: boolean
}

const ExplorablePlaylistCard: React.FC<Props> = ({ playlist, recommended, isPersonalized = false }: Props) => {
  const [t] = useTranslation()
  const history = useHistory()
  const { user } = useUser()
  const location = useLocation()
  const { EventsImplementation, track } = useMixpanel()
  const [ref, { entry }] = useIntersectionObserver({ threshold: 0.0005 })
  const isVisible = entry && entry.isIntersecting

  const sanitizePlaylistName = (name: string) => {
    return name.replace(/Trilha/gi, '').replace('-', ' ')
  }

  const routeToPlaylist = () => {
    const url = playlist.type === 'CUSTOM' ? `/customPlaylist` : `/playlist/${playlist.id}`

    history.push(url)
  }

  const playListTitle = useMemo(() => {
    return playlist.translations?.find((translation) => translation.language === user?.language)?.title ?? playlist.title
  }, [user, playlist])

  const playListDescription = useMemo(() => {
    return playlist.translations?.find((translation) => translation.language === user?.language)?.description ?? playlist.description
  }, [user, playlist])

  return (
    <Flex
      onClick={() => {
        routeToPlaylist()
        track(
          EventsImplementation.ACCESS_PLAYLIST(
            location.pathname,
            playlist.title,
            recommended as boolean,
            isPersonalized,
          ),
        )
      }}
      ref={ref}
      w='274px'
      h='378px'
      alignItems='center'
      border='2px solid #E5E5E5'
      borderRadius='0 30px 30px 30px'
      position='relative'
      transition='all 0.15s'
      background='white'
      _hover={{
        boxShadow: '-1px 6px 31px -3px rgba(0,0,0,0.5)',
        transform: 'scale(1.05)',
        border: 'none',
        zIndex: 4,
      }}
      overflow='clip'
      flexDirection='column'
      cursor='pointer'
    >
      {isVisible && (
        <>
          <Box
            w='120%'
            h='60%'
            bgImage={
              playlist?.imageUrl
                ? `url("${playlist.imageUrl}")`
                : 'https://i.ibb.co/fS8fCDS/dummy-300x300-009136-ffffff-education-journey.png'
            }
            backgroundSize='cover'
            bgRepeat='no-repeat'
            bgPosition='center'
            d='flex'
            alignItems='center'
            justifyContent='center'
          />
          <Flex flexDirection='column' w='100%' marginBottom='10px' h='120px' justifyContent='space-evenly'>
            <Text
              fontFamily='Poppins'
              fontWeight='800'
              fontSize='18px'
              lineHeight='22px'
              textAlign='center'
              noOfLines={2}
              paddingX='9px'
              h='50px'
              d='flex'
              alignItems='center'
              justifyContent='center'
            >
              {sanitizePlaylistName(playListTitle)}
            </Text>

            <Text
              fontFamily='Poppins'
              fontWeight='400'
              fontSize='16px'
              lineHeight='20px'
              textAlign='center'
              h='45px'
              w='100%'
              noOfLines={2}
              marginBottom='5px'
              paddingX='15px'
            >
              {playListDescription}
            </Text>
            {playlist?.moduleList?.items && playlist?.moduleList?.items?.length > 0 ? (
              <Text
                fontFamily='Poppins'
                fontSize='12px'
                textAlign='center'
                lineHeight='18px'
                fontWeight='600'
                textTransform='uppercase'
                color='gray.800'
              >
                {playlist?.moduleList?.items?.reduce((sum, item) => sum + item.contentList!.total!, 0)}{' '}
                {t('playlist.playlistCard.contents')}
              </Text>
            ) : null}
          </Flex>

          {(isPersonalized || recommended) && (
            <Flex w='100%' alignItems='center' justifyContent='center' position='absolute' bottom='0px'>
              <Box
                borderRadius='30px 30px 0px 0px'
                bg={playlist.color}
                fontSize='11px'
                fontWeight='700'
                color={isPersonalized ? 'black' : 'white'}
                h='26px'
                w='85%'
                textAlign='center'
                d='flex'
                alignItems='center'
                justifyContent='center'
              >
                {isPersonalized
                  ? t('playlists.exclusiveToYou')
                  : `${t('playlist.playlistCard.recommendedBy')} ${user?.company?.name}`}
              </Box>
            </Flex>
          )}
        </>
      )}
    </Flex>
  )
}

export default ExplorablePlaylistCard
