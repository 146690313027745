import { Flex, Text, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleTeam } from 'src/context-admin/useHandleTeam'
import { makeSwapTeamManager } from 'src/modules/dashboard/factory/makeSwapTeamManager'
import { InputWithLabel } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/InputWithLabel'
import { NavigationButtons } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/NavigationButtons'
import { StepTitle } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTitle'
import { UserList } from '../../commonComponents/UserList'
import { CurrentManagerCard } from '../EditExistingTeamChangeManager/CurrentManagerCard'
import { ManagerToRemoveCard } from './ManagerToRemoveCard'

export function EditExistingTeamChangeManagerConfirm() {
  const [t] = useTranslation()
  const toast = useToast()
  const { team, navigateToStep, handleFinalMessage } = useHandleTeam()
  const swapTeamManager = makeSwapTeamManager()
  const [loading, setLoading] = useState(false)

  const newManager = team?.newManagers?.[0]
  const oldManager = team?.newManagers?.[1]

  const handleChangeManager = async () => {
    setLoading(true)
    try {
      const response = await swapTeamManager.execute({
        teamId: team?.id ?? '',
        newManagerId: Number(newManager?.id!),
        prevManagerId: Number(oldManager?.id!),
      })
      if (!response?.id) {
        throw new Error()
      }
      handleFinalMessage(t('managersUpdated', { teamName: team?.name ?? t('invalidName') }))
      navigateToStep('success-screen')
    } catch (err: any) {
      toast({
        title: t('errorChangingTeamManager'),
        description: String(err?.message),
        status: 'error',
        isClosable: true,
        duration: 7000,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Flex flexDir='column' gap='24px'>
      <StepTitle title={t('confirmationOfAssignments')} />
      <InputWithLabel isReadOnly label={t('team')} value={team?.name ?? t('invalidName')} />
      <Flex flexDir='column' gap='12px'>
        <Text fontSize='1rem' color='#313e4c' fontWeight='700'>
          {t('newTeamManager')}
        </Text>
        <CurrentManagerCard user={newManager} />
      </Flex>

      <Flex flexDir='column' gap='12px'>
        <Text fontSize='1rem' color='#313e4c' fontWeight='700'>
          {t('selectTheManagerToBeRemoved', { teamName: team?.name ?? t('invalidName') })}
        </Text>
        <ManagerToRemoveCard user={oldManager} />
      </Flex>

      <Flex flexDir='column' gap='12px'>
        <Text fontSize='1rem' color='#313e4c' fontWeight='700'>
          {t('teamMembers')} {team?.users && team?.users?.length > 0 && `(${team?.users?.length})`}
        </Text>
        <UserList
          data={team?.users?.filter((u) => u.id !== newManager?.id)}
          withFilter={false}
          withSearch={false}
          selectText=''
        />
      </Flex>

      <NavigationButtons
        isLoading={loading}
        onContinueClick={handleChangeManager}
        onBackClick={() => {
          navigateToStep('edit-existing-team--change-manager')
        }}
      />
    </Flex>
  )
}
