import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import NewPlaylist from 'src/modules/cms/domain/Playlist'

interface Props {
  playlist: NewPlaylist
}

const LearningPointsSection: React.FC<Props> = ({ playlist }: Props) => {
  const [t] = useTranslation()
  playlist = playlist!

  return (
    <Flex
      marginX={{ base: '0px', lg: '72px' }}
      paddingX={{ base: '32px', lg: '0px' }}
      marginY={{ base: '32px', lg: '41px' }}
      alignItems='center'
      flexDirection='column'
    >
      <Heading size='sm' textAlign='center' marginBottom='19px'>
        {t('playlist.What you will learn on this trail')}
      </Heading>

      <Flex
        alignItems='center'
        paddingX={{ base: '32px', lg: '0px' }}
        direction={{ base: 'column', md: 'row' }}
        gap={{ base: '24px', md: '0px' }}
      >
        {playlist.learningPointList?.items?.map((item, index) => {
          return (
            <>
              {index ? (
                <Box
                  display={{ base: 'none', md: 'flex' }}
                  marginLeft='26px'
                  marginRight='26px'
                  opacity='0.3'
                  width='2px'
                  background='black'
                  transform='rotate(180deg)'
                  height='calc(100% + 30px)'
                />
              ) : null}
              <Flex flexDirection='column' alignItems='center' flex='1'>
                <Image src={item.iconUrl} h='38px' />
                <Text textTransform='uppercase' textAlign='center' fontWeight='700' fontSize='14px'>
                  {item.title}
                </Text>
                <Text textAlign='center' fontSize='14px'>
                  {item.description}
                </Text>
              </Flex>
            </>
          )
        })}
      </Flex>
    </Flex>
  )
}

export default LearningPointsSection
