import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useImportedContents } from 'src/pages-admin/Cms/hooks/useImportedContents'
import { EditContent } from 'src/theme/Icons'

export function RejectImportedContentsModal() {
  const {
    selectedImportedContent,
    loadingSaveImportedContents,
    isRejectImportedContentsModalOpen,
    onCloseRejectImportedContentsModalOpen,
    executeActionOnImportedContents,
  } = useImportedContents()

  return (
    <Modal isOpen={isRejectImportedContentsModalOpen} onClose={onCloseRejectImportedContentsModalOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirme a rejeição do conteúdo</ModalHeader>
        <ModalCloseButton />
        <ModalBody display='flex' flexDir='column' gap='32px'>
          <Flex flexDir='column' gap='8px'>
            <Text>Você está prestes a marcar o conteúdo o seguinte conteúdo como 'Rejeitado':</Text>
            <Text>
              <strong>"{selectedImportedContent?.title}"</strong>
            </Text>
          </Flex>

          <Flex flexDir='column' gap='8px'>
            <Text>Tem certeza que deseja continuar?</Text>
            <Text>
              Essa ação <strong>É REVERSÍVEL</strong> e você pode modificar o status do conteúdo a qualquer momento
              clicando no ícone <EditContent width='18px' height='18px' /> na tabela de conteúdos.
            </Text>
          </Flex>
        </ModalBody>
        <ModalFooter display='flex' gap='16px'>
          <Button
            variant='startCourseDark'
            isDisabled={loadingSaveImportedContents}
            onClick={onCloseRejectImportedContentsModalOpen}
          >
            Cancelar
          </Button>
          <Button
            isLoading={loadingSaveImportedContents}
            variant='ghost'
            h='48px'
            isDisabled={!selectedImportedContent}
            onClick={() => executeActionOnImportedContents(selectedImportedContent!, 'reject')}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
