import { PlaylistRepository } from "src/modules/cms/repositories/PlaylistRepository";
import { getAccessToken } from 'src/modules/iam/apis/store';
import httpClient from 'src/services/http/http-client';
import { SavePlaylistSurveyUseCase } from '../use_cases/SavePlaylistSurveyUseCase';

export function makeSavePlaylistSurvey() {
  const token = getAccessToken()
  const playlistRepository = new PlaylistRepository(httpClient, token)
  return new SavePlaylistSurveyUseCase(playlistRepository)
}
