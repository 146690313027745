export function extractIdFromTrailerUrl(url?: string) {
  if (!url) {
    return ''
  }

  const vimeoRegex = /vimeo\.com\/(\d+)/
  const youtubeRegexes = [
    /youtu\.be\/([\w-]+)/,
    /youtube\.com\/(?:embed|v|watch\?v=)([\w-]+)/,
    /^https?:\/\/(?:www\.)?youtube\.com\/(?:embed\/|watch\?v=)?([\w-]+)$/,
  ]

  const regexArray = [vimeoRegex, ...youtubeRegexes]
  const regexMatch = regexArray.reduce((acc: RegExpExecArray | null, item) => acc! || url.match(item), null)

  return regexMatch ? regexMatch[1] : ''
}
