import { UseCase } from 'src/types/UseCase'
import { Team } from '../entities/Team'
import { TeamRepository } from '../repository/TeamRepository'

export type RemoveTeamMembersInput = {
  teamId: string
  membersIds: number[]
}

export type RemoveTeamMembersOutput = Team

export class RemoveTeamMembersUseCase implements UseCase<RemoveTeamMembersInput, RemoveTeamMembersOutput> {
  constructor(private repository: TeamRepository) {}

  async execute(args: RemoveTeamMembersInput): Promise<RemoveTeamMembersOutput> {
    const team = await this.repository.removeTeamMembers(args)
    return team
  }
}
