import { TFunction } from 'react-i18next'
import { IoHeadset, IoPlayCircle, IoReader } from 'react-icons/io5'
import { IconType } from 'react-icons/lib'

export type ContentData = {
  type: string
  icon: IconType | undefined
  durationLearningText: string
  startButton: string
  evaluateButton: string
  resumeButton: string
  markAsComplete: string
  markAsNotComplete: string
}

type Return = {
  [x: string]: ContentData
}

export const getContentData = (type: string, t: TFunction) => {
  const data: Return = {
    COURSE: {
      type: t('common.card.course'),
      icon: IoPlayCircle,
      durationLearningText: t('common.card.course'),
      startButton: t('common.card.start'),
      evaluateButton: t('common.card.evaluateCourse'),
      resumeButton: t('common.card.resumeCourse'),
      markAsComplete: t('common.card.markAsCompleteCourse'),
      markAsNotComplete: t('common.card.markAsNotCompleteCourse'),
    },
    VIDEO: {
      type: t('common.card.video'),
      icon: IoPlayCircle,
      durationLearningText: t('common.card.video'),
      startButton: t('common.card.startVideo'),
      evaluateButton: t('common.card.evaluateVideo'),
      resumeButton: t('common.card.startVideo'),
      markAsComplete: t('common.card.markAsCompleteVideo'),
      markAsNotComplete: t('common.card.markAsNotCompleteVideo'),
    },
    ARTICLE: {
      type: t('common.card.article'),
      icon: IoReader,
      durationLearningText: t('common.card.lecture'),
      startButton: t('common.card.startArticle'),
      evaluateButton: t('common.card.evaluateArticle'),
      resumeButton: t('common.card.startArticle'),
      markAsComplete: t('common.card.markAsCompleteArticle'),
      markAsNotComplete: t('common.card.markAsNotCompleteArticle'),
    },
    Course: { // deletar no final do refactor
      type: t('common.card.course'),
      icon: IoPlayCircle,
      durationLearningText: t('common.card.course'),
      startButton: t('common.card.start'),
      evaluateButton: t('common.card.evaluateCourse'),
      resumeButton: t('common.card.resumeCourse'),
      markAsComplete: t('common.card.markAsCompleteCourse'),
      markAsNotComplete: t('common.card.markAsNotCompleteCourse'),
    },
    OldCourse: { // deletar no final do refactor
      type: t('common.card.course'),
      icon: IoPlayCircle,
      durationLearningText: t('common.card.course'),
      startButton: t('common.card.start'),
      evaluateButton: t('common.card.evaluateCourse'),
      resumeButton: t('common.card.resumeCourse'),
      markAsComplete: t('common.card.markAsCompleteCourse'),
      markAsNotComplete: t('common.card.markAsNotCompleteCourse'),
    },
    CourseDto: { // deletar no final do refactor
      type: t('common.card.course'),
      icon: IoPlayCircle,
      durationLearningText: t('common.card.course'),
      startButton: t('common.card.start'),
      evaluateButton: t('common.card.evaluateCourse'),
      resumeButton: t('common.card.resumeCourse'),
      markAsComplete: t('common.card.markAsCompleteCourse'),
      markAsNotComplete: t('common.card.markAsNotCompleteCourse'),
    },
    Article: { // deletar no final do refactor
      type: t('common.card.article'),
      icon: IoReader,
      durationLearningText: t('common.card.lecture'),
      startButton: t('common.card.startArticle'),
      evaluateButton: t('common.card.evaluateArticle'),
      resumeButton: t('common.card.startArticle'),
      markAsComplete: t('common.card.markAsCompleteArticle'),
      markAsNotComplete: t('common.card.markAsNotCompleteArticle'),
    },
    ArticleDto: { // deletar no final do refactor
      type: t('common.card.article'),
      icon: IoReader,
      durationLearningText: t('common.card.lecture'),
      startButton: t('common.card.startArticle'),
      evaluateButton: t('common.card.evaluateArticle'),
      resumeButton: t('common.card.startArticle'),
      markAsComplete: t('common.card.markAsCompleteArticle'),
      markAsNotComplete: t('common.card.markAsNotCompleteArticle'),
    },
    Video: { // deletar no final do refactor
      type: t('common.card.video'),
      icon: IoPlayCircle,
      durationLearningText: t('common.card.video'),
      startButton: t('common.card.startVideo'),
      evaluateButton: t('common.card.evaluateVideo'),
      resumeButton: t('common.card.startVideo'),
      markAsComplete: t('common.card.markAsCompleteVideo'),
      markAsNotComplete: t('common.card.markAsNotCompleteVideo'),
    },
    VideoDto: { // deletar no final do refactor
      type: t('common.card.video'),
      icon: IoPlayCircle,
      durationLearningText: t('common.card.video'),
      startButton: t('common.card.startVideo'),
      evaluateButton: t('common.card.evaluateVideo'),
      resumeButton: t('common.card.startVideo'),
      markAsComplete: t('common.card.markAsCompleteVideo'),
      markAsNotComplete: t('common.card.markAsNotCompleteVideo'),
    },
    Podcast: {
      type: t('common.card.podcast'),
      icon: IoHeadset,
      durationLearningText: t('common.card.audio'),
      startButton: t('common.card.startPodcast'),
      evaluateButton: t('common.card.evaluatePodcast'),
      resumeButton: t('common.card.startPodcast'),
      markAsComplete: t('common.card.markAsCompletePodcast'),
      markAsNotComplete: t('common.card.markAsNotCompletePodcast'),
    },
  }

  return data[type]
}
