import { Flex, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleTeam } from 'src/context-admin/useHandleTeam'
import { makeAddTeamManagers } from 'src/modules/dashboard/factory/makeAddTeamManagers'
import { NavigationButtons } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/NavigationButtons'
import { StepTitle } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTitle'
import { StepTransition } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTransition'
import { User } from 'src/types/User'
import { UserList } from '../../commonComponents/UserList'
import { ManagerList } from '../EditExistingTeamRemoveManager/ManagerList'

export function EditExistingTeamConfirmNewManager() {
  const [t] = useTranslation()
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const { navigateToStep, team, handleFinalMessage } = useHandleTeam()
  const addTeamManagers = makeAddTeamManagers()

  const sortAreaUsersByNameAsc = (users: User[]) => users.sort((a, b) => (a?.name ?? '').localeCompare(b?.name ?? ''))

  const handleSubmitNewArea = async () => {
    setLoading(true)
    try {
      const response = await addTeamManagers.execute({
        teamId: team?.id ?? '',
        managersIds: team?.newManagers?.map(item => +item.id) ?? [],
      })
      if (!response?.id) {
        throw new Error()
      }
      handleFinalMessage(t('managersUpdated', { teamName: team?.name ?? t('invalidTeam') }))
      navigateToStep('success-screen')
    } catch (err: any) {
      toast({
        title: t('errorAddingNewManagers'),
        description: String(err?.message),
        status: 'error',
        isClosable: true,
        duration: 7000,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <StepTransition>
      <Flex flexDir='column'>
        <StepTitle
          title={t('reviewTheManagersOf', { teamName: team?.name })}
          subtitle={t('checkAndConfirmNewMembersChange', { teamName: team?.name })}
        />
        <Flex flexDir='column' mt='24px' gap='24px'>
          <Flex flexDir='column' gap='4px'>
            <ManagerList users={sortAreaUsersByNameAsc(team?.managers ?? [])} shouldRenderButtons={false} />
            <UserList
              type='remove-users'
              data={sortAreaUsersByNameAsc(team?.newManagers ?? [])}
              alreadySelectedUsers={sortAreaUsersByNameAsc(team?.newManagers ?? [])}
              selectedText={t('cancel')}
              selectedTextColor='gray.800'
              withFilter={false}
              withSearch={false}
            />
          </Flex>
        </Flex>
        <NavigationButtons
          isLoading={loading}
          isContinueDisabled={team?.newManagers?.length === 0}
          onBackClick={() => navigateToStep('edit-existing-team--add-new-manager')}
          onContinueClick={handleSubmitNewArea}
        />
      </Flex>
    </StepTransition>
  )
}
