import { Box, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { Module } from 'src/modules/cms/domain/Playlist'
import { DeletePlaylist } from './DeletePlaylist'
import EditSkills from './EditSkills'
import ModulesList from './ModulesList'

const Modules: React.FC = () => {
  const [t] = useTranslation()
  const { playlist } = useCustomPlaylist()

  const [emptyModules, setEmptyModules] = useState<Module[]>([])

  useEffect(() => {
    if (playlist) {
      const emptyModules =
        playlist.moduleList?.items?.filter((module) => module?.contentList?.items?.length! === 0) ?? []
      setEmptyModules(emptyModules)
    }
  }, [playlist])

  if (!playlist) return null

  return (
    <Flex flexDirection='column' paddingX={{ base: '20px', lg: '72px' }}>
      {emptyModules.length > 0 && (
        <Flex key={module.id} flexDir='column' fontSize='12px' gap='4px'>
          <Text color='bluishGray.500'>
            {t('noContentFoundFor')} {emptyModules.length} {emptyModules.length > 1 ? t('skills') : t('skill')}:
          </Text>
          <Flex>
            {emptyModules?.map((module, index, arr) => (
              <Text key={module.id} color='bluishGray.500'>
                {index > 0 && ','} {module.title}
                {index === arr.length - 1 && ';'}
              </Text>
            ))}
          </Flex>
        </Flex>
      )}
      <Box border='1px solid #EFEFEF' marginTop='40px' marginBottom='32px' />
      <ModulesList playlist={playlist} />
      <EditSkills />
      <DeletePlaylist />
    </Flex>
  )
}

export default Modules
