import { Button, Icon, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import EvaluateIcon from 'src/components/EvaluateIcon'
import { getContentData } from 'src/helpers/contentInfo'
import { Size } from 'src/hooks/useWindowSize'
import { ContentType } from 'src/modules/cms/domain/LearningItem'

interface Props {
  contentType: string
  showMainIconButton: boolean
  isContentOnProgress: boolean
  isContentDone: boolean
  removeCourseWordInButtons: boolean
  cardContentSize: Size
  handleStartContent: (e: React.MouseEvent<HTMLElement>) => void
  handleEvaluateClick: () => void
}

export const ContentMainButton: React.FC<Props> = ({
  contentType,
  isContentOnProgress,
  isContentDone,
  removeCourseWordInButtons,
  showMainIconButton,
  handleStartContent,
  handleEvaluateClick,
  cardContentSize,
}: Props) => {
  const { t } = useTranslation()
  const removeWord = cardContentSize.width! < 1340
  const contentData = getContentData(contentType, t)
  const isCourse = [ContentType.COURSE, 'OldCourse', 'CourseDto'].includes(contentType)

  const getText = () => {
    if (isContentOnProgress && isCourse) {
      return removeWord ? t('common.card.resume') : contentData.resumeButton
    }

    return contentData.startButton
  }

  return (
    <>
      {!isContentDone && (
        <Button
          variant='startCourseDark'
          onClick={handleStartContent}
          minWidth={{ base: 'unset' }}
          flexGrow={{ base: '1', lg: '0' }}
          w='auto'
          h='48px'
          padding={{ base: '0px 8px', lg: '24px 17px' }}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          {showMainIconButton && <Icon w='30px' h='30px' color='white' marginRight='8px' as={contentData?.icon} />}
          <Text fontSize={{ base: '.75rem', md: '1rem' }} color='white'>
            {getText()}
          </Text>
        </Button>
      )}
      {isContentDone && (
        <EvaluateIcon
          text={contentData?.evaluateButton}
          variant='startCourseDark'
          iconFilled
          showText
          showReducedText={removeCourseWordInButtons}
          onClick={handleEvaluateClick}
          w='auto'
          minWidth={{ base: 'unset' }}
          flexGrow={{ base: '1', lg: '0' }}
          display='flex'
          alignItems='center'
          justifyContent='flex-start'
        />
      )}
    </>
  )
}
