import { Flex, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useUser } from 'src/context/userContext'
import { usePartners } from 'src/modules/cms/hooks/usePartners'
import { useImportedContents } from '../../hooks/useImportedContents'
import { ImportDateFilter } from './components/Filters/ImportDate'
import { LanguageFilter } from './components/Filters/Language'
import { OrderFilter } from './components/Filters/Order'
import { StatusFilter } from './components/Filters/Status'
import { TypeFilter } from './components/Filters/Type'
import { Pagination } from './components/Pagination'
import { SearchImportedContents } from './components/Search'
import { ImportedContentSuccessModal } from './components/SuccessModal'
import { ImportedContentsTable } from './components/Table'
import { TableSkeleton } from './components/Table/Skeleton'
import { useImportedContentsForm } from './hooks/useImportedContentsForm'

export function ImportedContentsTab() {
  const {
    loadingContents,
    importedContents,
    updateSelectedContent,
    listImportedContents,
    updateSkip,
    handlePreviousPage,
    handleNextPage,
    resetAllFilters,
    paginationInfo,
    updateLimitPerPage,
    onCloseEditImportedContentsModal,
  } = useImportedContents()
  const { user } = useUser()
  const { finalMessage, isSuccessModalOpen, onCloseSuccessModal } = useImportedContentsForm()
  const { partnersList } = usePartners()
  const history = useHistory()
  const [t] = useTranslation()

  const isUserBackoffice = user?.roles?.includes('BACKOFFICE')

  const closeSuccessModal = async () => {
    onCloseSuccessModal()
    onCloseEditImportedContentsModal()
    await listImportedContents()
  }

  useEffect(() => {
    if (!isUserBackoffice) {
      history.push('/home')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserBackoffice])

  useEffect(() => {
    let isMounted = true
    if (isMounted && partnersList.length > 0) {
      updateSelectedContent(undefined)
      listImportedContents()
    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnersList])

  useEffect(() => {
    return () => {
      resetAllFilters()
      updateSkip(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Flex flexDir='column' flex={1} paddingBottom='40px' data-testid='cms-imported-contents-wrapper'>
        <Text color='#313E4C' fontSize='1.25rem' fontWeight={600} fontFamily='Poppins'>
          {t('importedContentManagement')}
        </Text>

        <SearchImportedContents
          searchSectionTitle={t('admin.corporateUniversity.contents.searchSectionTitle')}
          searchPlaceholder={t('admin.corporateUniversity.contents.searchForContent')}
        />

        <Flex w='100%' flexDir='column' mt='24px'>
          <Text color='bluishGray.500'> {t('admin.filter.Filters')}</Text>

          <Flex gap='6px' mt='6px' data-testid='cms-filters-wrapper' alignItems='center'>
            <OrderFilter />
            {/* <AvailabilityFilter /> */}
            <TypeFilter />
            <StatusFilter showRejectedStatus={false} />
            <LanguageFilter />
            {/* <PartnerFilter /> */}
            <ImportDateFilter />
          </Flex>
        </Flex>

        <Flex mt='32px' flexDir='column' gap='24px'>
          {loadingContents ? (
            <TableSkeleton />
          ) : (
            <>
              <ImportedContentsTable contents={importedContents} />
              <Pagination
                limitPerPage={paginationInfo.limit}
                skip={paginationInfo.skip}
                updateLimitPerPage={updateLimitPerPage}
                totalContentsLength={paginationInfo.total}
                handlePreviousPage={handlePreviousPage}
                handleNextPage={handleNextPage}
              />
            </>
          )}
        </Flex>
      </Flex>

      {isSuccessModalOpen && (
        <ImportedContentSuccessModal isOpen={isSuccessModalOpen} onClose={closeSuccessModal} message={finalMessage} />
      )}
    </>
  )
}
