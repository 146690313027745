import { Flex, FormControl, Input, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleTeam } from 'src/context-admin/useHandleTeam'
import { NavigationButtons } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/NavigationButtons'
import { StepTitle } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTitle'
import { StepTransition } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTransition'

export function AddNewTeam() {
  const [t] = useTranslation()
  const [teamName, setTeamName] = useState('')
  const { navigateToStep, handleSetTeamValues, onCloseHandleTeamDrawer } = useHandleTeam()

  return (
    <StepTransition>
      <Flex flexDir='column'>
        <Flex flexDir='column' gap='24px'>
          <StepTitle title={t('newTeam')} subtitle={t('youAreCreatingANewTeam')} />
          <FormControl display='flex' flexDir='column' gap='4px'>
            <Text fontWeight='bold' fontSize='1rem'>
              {t('newTeamName')}
            </Text>
            <Input
              borderRadius='12px'
              height='36px'
              w='100%'
              px='16px'
              m='0'
              borderWidth='2px'
              borderColor='#E5E5E5'
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
            />
          </FormControl>
        </Flex>
        <NavigationButtons
          onContinueClick={() => {
            handleSetTeamValues({ name: teamName })
            navigateToStep('add-new-team--select-managers')
          }}
          onBackClick={onCloseHandleTeamDrawer}
          isDisabled={teamName.length <= 1}
        />
      </Flex>
    </StepTransition>
  )
}
