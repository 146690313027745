import { Mixpanel } from 'src/services/mixpanel/Mixpanel'
import { QuizContentTypes } from 'src/types/Quiz'
import { User } from './../../types/User'
export interface MixpanelEvent {
  eventName: string
  properties: Record<string, unknown>
  callbackToBeExecuted?: () => void
  beforeTracking?: () => void
}

export const MixpanelEvents = {
  ACCESS_LEARNING_ITEM: 'Access Learning Item',
  MARK_AS_DONE: 'Mark as Done',
  MARK_AS_UNDONE: 'Mark as Undone',
  START_PLAYLIST: 'Start Playlist',
  SHARE_CERTIFICATE_ON_LINKEDIN: 'Share Certificate on Linkedin',
  DOWNLOAD_CERTIFICATE: 'Download Certificate',
  REQUEST_CERTIFICATE: 'Request Certificate',
  OPEN_LEARNING_ITEM_MODAL: 'Open Learning Item Modal',
  ONBOARDING_INSTALL_EXTENSION: 'Onboarding - Install Extension',
  CARD_MODAL_WATCH_FULL_TRAILER: 'Card Modal - Watch full trailer',
  CARD_MODAL_UNMUTE_TRAILER: 'Card Modal - Unmute trailer',
  CARD_MODAL_CLICK_SHARE_BUTTON: 'Card Modal - Click Share Button',
  CARD_MODAL_SHARE: 'Card Modal - Share',
  SEARCH: 'Search',
  COPY_CREDENTIAL: 'Copy Credential',
  ACCESS_PLAYLIST: 'Access Playlist',
  ACCESS_PARTNER: 'Access Partner',
  SIDEBAR_REDIRECT: 'Sidebar - Redirect',
  OPEN_PARTNER_OPTIONS: 'Open Partner Options',
  MENU_ADD_EXTENSION: 'Menu - Add Extension',
  LOGOUT: 'Logout',
  SWIPE: 'Swipe',
  FORGOT_PASSWORD: 'Forgot Password',
  SIGNUP: 'User - Sign Up',
  SHARE: 'Share',
  OPEN_EXPLORE: 'Explorer - Open Popover',
  ACCESS_CATEGORY: 'Explorer - Access Category',
  USED_PAGINATION: 'Used Pagination',
  PROFILE_MENU_SIDEBAR_REDIRECT: 'Profile Sidebar - Redirect',
  PROFILE_CHANGE_LANGUAGE: 'Profile - Change Language',
  PROFILE_CHANGE_CAREER_MOMENT: 'Profile - Change career moment',
  PROFILE_ACCORDION: 'Profile - Accordion',
  PROFILE_CHANGE_QUIZ: 'Profile - Change Quiz',
  ONBOARDING_REGISTRATION_FINISHED: 'Onboarding - Finished Registration',
  LOGIN: 'Login',
  ADD_FAVORITE_LEARNING_ITEM: 'Add Favorite Learning Item',
  DELETE_FAVORITE_LEARNING_ITEM: 'Delete Favorite Learning Item',
  SIDEBAR_COLAPSE: 'Sidebar - Colapse',
  RATE_LEARNING_ITEM: 'Clicked on Rate - Learning Item',
  PERSONALIZED_PLAYLIST_CREATED: 'Personalized Playlist Created',
  PERSONALIZED_PLAYLIST_SKILL_CONTEXT: 'Personalized Playlist Quiz - Skill Context',
  REMOVE_LEARNING_ITEM: 'Personalized Playlist - Click to Remove Learning Item',
  REMOVE_LEARNING_ITEM_CONFIRMATION: 'Personalized Playlist - Remove Learning Item Confirmation',

  QUIZ_ACCESS_QUIZ: 'Quiz - Access Quiz',
  QUIZ_FINISH_QUIZ: 'Quiz - Finish Quiz',
  QUIZ_NEXT_STEP: 'Quiz - Next Step',
  QUIZ_DO_IT_LATER: 'Quiz - Do It Later',
  QUIZ_RETURN: 'Quiz - Return',
  MODAL_ACCESS_TAB: 'Modal - Access Tab',
  PLAYLIST_ACCESS_TAB: 'Playlist - Access Tab',
  OPEN_SHARE_OPTIONS: 'Open share options',
  ACCORDION_PERSONAL_PLAYLIST: 'Accordion - Personal Playlist',
  PERSONALIZED_PLAYLIST_QUIZ_ADD_SKILL: 'Personalized Playlist Quiz - Add Skill',
  PERSONALIZED_PLAYLIST_QUIZ_REMOVE_SKILL: 'Personalized Playlist Quiz - Remove Skill',
  PERSONALIZED_PLAYLIST_QUIZ_NEXT_STEP: 'Personalized Playlist Quiz - Next Step',
  PERSONALIZED_PLAYLIST_QUIZ_FORMAT_ORDER: 'Personalized Playlist Quiz - Format Ordering',
  PERSONALIZED_PLAYLIST_CLICK_TO_CHANGE_LEARNING_ITEM: 'Personalized Playlist - Click to Change Learning Item',
  PERSONALIZED_PLAYLIST_CHANGE_LEARNING_ITEM_CONFIRMATION: 'Personalized Playlist - Change Learning Item Confirmation',
  OPEN_LEARNING_ITEM_DRAWER: 'Open Learning Item Drawer',
  CHANGE_TAB_LEARNING_ITEM_DRAWER: 'Change Tab Learning Item Drawer',

  CLICKED_ON_RATE_PLAYLIST: 'Clicked on Rate - Playlist',

  CANCEL_SUBSCRIPTION: 'Cancel Subscription',
  DEACTIVATE_SUBSCRIPTION: 'Deactivate Subscription',
  PLAYLISTS_SHOW_MORE: 'Playlists - Show More',
  ONBOARDING_PAYMENT: 'Onboarding - Payment',
  ONBOARDING_FINISHED: 'Onboarding - Finished Onboarding',
  OPEN_CREDENTIALS_MODAL: 'Open Credentials Modal',
  ACCESS_SUBCATEGORY: 'Explorer - Access Subcategory',
  PROFILE_MENU_REDIRECT: 'Profile Menu - Redirect',
  PLAYLIST_EDIT_SKILLS: 'Personalized Playlist - Click to edit playlist',
  PLAYLIST_EDIT_SKILLS_CONFIRMATION: 'Personalized Playlist - Edit playlist confirmation',
  ADD_LEARNING_ITEM: 'Personalized Playlist - Click to AddLearning Item',
  ADD_LEARNING_ITEM_CONFIRMATION: 'Personalized Playlist - Add Learning Item Confirmation',

  CHANGED_USER_VIEW: 'Changed user view',

  COURSE_DASH_FILTER: 'Admin course dashboard - Apply filter',
  PLAYLIST_DASH_FILTER: 'Admin personalized playlist dashboard - apply filter',
  DOWNLOAD_PLAYLIST_DASH: 'Admin personalized playlist dashboard - Download database',
  OPEN_PLAYLIST_DETAILS: 'Admin playlist dashboard - Open individual playlist details',
  EDIT_PERMISSIONS: 'Admin - Clicked to edit permissions',
  TEAM_CHOSEN: 'Admin - Team chosen',
  EDIT_PERMISSIONS_CONFIMATION: 'Admin - permission edit confirmation',
  CLICKED_TO_EDIT_TEAM: 'Admin - Clicked to edit team',
  TEAM_EDIT_TYPE_SELECTION: 'Admin - Team Edit type selection',
  TEAM_EDIT_CONFIRMATION: 'Admin - Team Edit confirmation',
  EDIT_TEAM: 'Admin - Edit team Add/Remove user',
}

interface EventsImplementationType {
  ACCESS_LEARNING_ITEM: (
    line: string | null,
    favorited: boolean,
    learningItemType: string,
    learningItemName: string,
    learningItemId: string,
    partnerName: string,
    locationWhereAccessed: string,
    componentWhereAccessed: string,
    toGoBeyond?: boolean,
  ) => MixpanelEvent
  MARK_AS_DONE: (learningItemName: string, accessedWhere: string, componentWhereAccessed: string) => MixpanelEvent
  MARK_AS_UNDONE: (learningItemName: string, accessedWhere: string, componentWhereAccessed: string) => MixpanelEvent
  START_PLAYLIST: (title: string, recommended: boolean) => MixpanelEvent
  REQUEST_CERTIFICATE: (playlistTitle: string) => MixpanelEvent
  DOWNLOAD_CERTIFICATE: (playlistTitle: string) => MixpanelEvent
  SHARE_CERTIFICATE_ON_LINKEDIN: (playlistTitle: string) => MixpanelEvent
  OPEN_LEARNING_ITEM_MODAL: (
    openedWhere: string,
    learningItemName: string,
    partnerName: string,
    learningItemCategory: string,
    learningItemType: string,
  ) => MixpanelEvent
  ONBOARDING_INSTALL_EXTENSION: () => MixpanelEvent
  CARD_MODAL_WATCH_FULL_TRAILER: () => MixpanelEvent
  CARD_MODAL_UNMUTE_TRAILER: () => MixpanelEvent
  CARD_MODAL_CLICK_SHARE_BUTTON: (where: string, on: string) => MixpanelEvent
  CARD_MODAL_SHARE: (to: string, where: string, on: string, learningItemType: string) => MixpanelEvent
  SEARCH: (query: string) => MixpanelEvent
  CANCEL_SUBSCRIPTION: () => MixpanelEvent
  COPY_CREDENTIAL: (partnerName: string) => MixpanelEvent
  ACCESS_PLAYLIST: (where: string, playlistName: string, recommended: boolean, personalized?: boolean) => MixpanelEvent
  ACCESS_PARTNER: (partnerName: string, withExtension: boolean) => MixpanelEvent
  SIDEBAR_REDIRECT: (from: string, to: string) => MixpanelEvent
  SIDEBAR_COLAPSE: () => MixpanelEvent
  OPEN_PARTNER_OPTIONS: () => MixpanelEvent
  MENU_ADD_EXTENSION: () => MixpanelEvent
  LOGOUT: () => MixpanelEvent
  DEACTIVATE_SUBSCRIPTION: () => MixpanelEvent
  SWIPE: (where: string, lineName: string) => MixpanelEvent
  PLAYLISTS_SHOW_MORE: () => MixpanelEvent
  LOGIN: (email: string) => MixpanelEvent
  FORGOT_PASSWORD: () => MixpanelEvent
  ONBOARDING_PAYMENT: (paid: boolean, user: User) => MixpanelEvent
  ONBOARDING_FINISHED: (finishedOnboarding: boolean) => MixpanelEvent
  ONBOARDING_REGISTRATION_FINISHED: (User: User, areaId: string | number, name: string) => MixpanelEvent
  OPEN_CREDENTIALS_MODAL: (partnerName: string) => MixpanelEvent
  SIGNUP: (signedUp: boolean) => MixpanelEvent
  SHARE: (to: string, where: string, on: string, learningItemType: string) => MixpanelEvent
  OPEN_EXPLORE: (where: string) => MixpanelEvent
  ACCESS_CATEGORY: (from: string, category: string, on: string) => MixpanelEvent
  ACCESS_SUBCATEGORY: (from: string, category: string, on: string) => MixpanelEvent
  USED_PAGINATION: (where: string, page: number) => MixpanelEvent
  PROFILE_MENU_REDIRECT: (to: string) => MixpanelEvent
  PROFILE_MENU_SIDEBAR_REDIRECT: (from: string, to: string) => MixpanelEvent
  PROFILE_CHANGE_LANGUAGE: () => MixpanelEvent
  PROFILE_CHANGE_CAREER_MOMENT: () => MixpanelEvent
  PROFILE_ACCORDION: (page: string) => MixpanelEvent
  PROFILE_CHANGE_QUIZ: (competence: boolean, ability: boolean) => MixpanelEvent
  ADD_FAVORITE_LEARNING_ITEM: (
    where: string,
    learningItemName: string,
    partner: string | undefined,
    on: string,
    learningItemType: string,
  ) => MixpanelEvent
  DELETE_FAVORITE_LEARNING_ITEM: (
    where: string,
    learningItemName: string,
    partner: string | undefined,
    on: string,
    learningItemType: string,
  ) => MixpanelEvent
  RATE_LEARNING_ITEM: (
    where: string,
    learningItemName: string,
    learningItemType: string,
    partner: string | undefined,
  ) => MixpanelEvent
  PERSONALIZED_PLAYLIST_SKILL_CONTEXT: (skill: string, context: string) => MixpanelEvent
  PERSONALIZED_PLAYLIST_CREATED: () => MixpanelEvent
  REMOVE_LEARNING_ITEM: (learningItemName: string) => MixpanelEvent
  REMOVE_LEARNING_ITEM_CONFIRMATION: (learningItemName: string, isLastLearningItem: boolean) => MixpanelEvent
  QUIZ_ACCESS_QUIZ: (where: string) => MixpanelEvent
  QUIZ_FINISH_QUIZ: () => MixpanelEvent
  QUIZ_NEXT_STEP: (step: string) => MixpanelEvent
  QUIZ_DO_IT_LATER: (step: string) => MixpanelEvent
  QUIZ_RETURN: (from: string) => MixpanelEvent
  CLICKED_ON_RATE_PLAYLIST: (playlistName: string) => MixpanelEvent
  MODAL_ACCESS_TAB: (from: string, to: string) => MixpanelEvent
  PLAYLIST_ACCESS_TAB: (from: string, to: string) => MixpanelEvent
  OPEN_SHARE_OPTIONS: (where: string, sharedOn: string) => MixpanelEvent
  ACCORDION_PERSONAL_PLAYLIST: () => MixpanelEvent
  PERSONALIZED_PLAYLIST_QUIZ_ADD_SKILL: (skillName: string) => MixpanelEvent
  PERSONALIZED_PLAYLIST_QUIZ_REMOVE_SKILL: (skillName: string) => MixpanelEvent
  PERSONALIZED_PLAYLIST_QUIZ_NEXT_STEP: (currentStep: string) => MixpanelEvent
  PERSONALIZED_PLAYLIST_QUIZ_FORMAT_ORDER: (contentTypes: QuizContentTypes[]) => MixpanelEvent
  PERSONALIZED_PLAYLIST_CLICK_TO_CHANGE_LEARNING_ITEM: (learningItemName: string) => MixpanelEvent
  PERSONALIZED_PLAYLIST_CHANGE_LEARNING_ITEM_CONFIRMATION: (
    oldLearningItemName: string,
    newLearningItemName: string,
  ) => MixpanelEvent
  OPEN_LEARNING_ITEM_DRAWER: (learningItemName: string) => MixpanelEvent

  CHANGE_TAB_LEARNING_ITEM_DRAWER: (learningItemName: string, from: string, to: string) => MixpanelEvent
  PLAYLIST_EDIT_SKILLS: () => MixpanelEvent
  PLAYLIST_EDIT_SKILLS_CONFIRMATION: (skillsRemoved: string, skillsAdded: string) => MixpanelEvent
  ADD_LEARNING_ITEM: (skill: string) => MixpanelEvent
  ADD_LEARNING_ITEM_CONFIRMATION: (
    skill: string,
    newLearningItems: string,
    newLearningItemsLength: number,
  ) => MixpanelEvent

  COURSE_DASH_FILTER: (tab: string, filter: string) => MixpanelEvent
  PLAYLIST_DASH_FILTER: (tab: string, filter: string) => MixpanelEvent
  DOWNLOAD_PLAYLIST_DASH: (dataType: string) => MixpanelEvent
  OPEN_PLAYLIST_DETAILS: (
    isFilterApplied: boolean,
    filters: string,
    progress: number,
    amountOfSkills: number,
  ) => MixpanelEvent
  EDIT_PERMISSIONS: (userPermissions: string) => MixpanelEvent
  TEAM_CHOSEN: (isNewTeam: boolean) => MixpanelEvent
  EDIT_PERMISSIONS_CONFIMATION: (permissionType: string) => MixpanelEvent
  CHANGED_USER_VIEW: (from: string, to: string) => MixpanelEvent
  CLICKED_TO_EDIT_TEAM: (role: string) => MixpanelEvent
  TEAM_EDIT_TYPE_SELECTION: (types: string) => MixpanelEvent
  TEAM_EDIT_CONFIRMATION: () => MixpanelEvent
  EDIT_TEAM: (typeOfEdition: string, numberOfUsers: number) => MixpanelEvent
}

export const EventsImplementation: EventsImplementationType = {
  ACCESS_LEARNING_ITEM: (
    line: string | null,
    favorited: boolean,
    learningItemType: string,
    learningItemName: string,
    learningItemId: string,
    partnerName: string,
    locationWhereAccessed: string,
    componentWhereAccessed: string,
    toGoBeyond = false,
  ): MixpanelEvent => {
    const isExtensionActive = Boolean(!document.getElementById('extensionInstallButton'))

    return {
      eventName: MixpanelEvents.ACCESS_LEARNING_ITEM,
      properties: {
        Favorite: favorited,
        'Accessed Learning Item Id': learningItemId,
        'Accessed Learning Item Name': learningItemName,
        'Accessed Where': locationWhereAccessed,
        'Accessed on': componentWhereAccessed,
        'To Go Beyond': toGoBeyond,
        'Learning Item Type': learningItemType,
        ...(partnerName && { 'Accessed Course Partner': partnerName }),
        ...(line && { Line: line }),
        withExtension: isExtensionActive,
      },
    }
  },
  MARK_AS_DONE: (learningItemName, accessedWhere, componentWhereAccessed): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.MARK_AS_DONE,
      properties: {
        'Learning Item Name': learningItemName,
        'Accessed Where': accessedWhere,
        Accessed: componentWhereAccessed,
      },
    }
  },
  MARK_AS_UNDONE: (learningItemName, accessedWhere, componentWhereAccessed): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.MARK_AS_UNDONE,
      properties: {
        'Learning Item Name': learningItemName,
        'Accessed Where': accessedWhere,
        Accessed: componentWhereAccessed,
      },
    }
  },
  START_PLAYLIST: (title: string, recommended: boolean): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.START_PLAYLIST,
      properties: {
        'Playlist Name': title,
        Recommended: recommended,
      },
    }
  },
  REQUEST_CERTIFICATE: (playlistTitle: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.REQUEST_CERTIFICATE,
      properties: { 'Playlist Name': playlistTitle },
    }
  },
  DOWNLOAD_CERTIFICATE: (playlistTitle: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.DOWNLOAD_CERTIFICATE,
      properties: { 'Playlist Name': playlistTitle },
    }
  },
  SHARE_CERTIFICATE_ON_LINKEDIN: (playlistTitle: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.SHARE_CERTIFICATE_ON_LINKEDIN,
      properties: { 'Playlist Name': playlistTitle },
    }
  },
  OPEN_LEARNING_ITEM_MODAL: (
    openedWhere: string,
    learningItemName: string,
    partnerName: string,
    learningItemCategory: string,
    learningItemType: string,
  ): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.OPEN_LEARNING_ITEM_MODAL,
      properties: {
        'Open Where': openedWhere,
        'Learning Item Name': learningItemName,
        'Learning Item Type': learningItemType,
        ...(partnerName && { Partner: partnerName }),
        Category: learningItemCategory,
      },
    }
  },
  ONBOARDING_INSTALL_EXTENSION: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.ONBOARDING_INSTALL_EXTENSION,
      properties: {},
    }
  },
  CARD_MODAL_WATCH_FULL_TRAILER: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.CARD_MODAL_WATCH_FULL_TRAILER,
      properties: {},
    }
  },
  CARD_MODAL_UNMUTE_TRAILER: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.CARD_MODAL_UNMUTE_TRAILER,
      properties: {},
    }
  },
  CARD_MODAL_CLICK_SHARE_BUTTON: (where: string, on: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.CARD_MODAL_CLICK_SHARE_BUTTON,
      properties: {
        where,
        'Shared on': on,
      },
    }
  },
  CARD_MODAL_SHARE: (to: string, where: string, on: string, learningItemType: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.CARD_MODAL_CLICK_SHARE_BUTTON,
      properties: {
        to,
        where,
        'Shared on': on,
        'Learning Item Type': learningItemType,
      },
    }
  },
  SEARCH: (query: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.SEARCH,
      properties: {
        query,
      },
    }
  },
  CANCEL_SUBSCRIPTION: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.CANCEL_SUBSCRIPTION,
      properties: {},
      callbackToBeExecuted: () =>
        Mixpanel.people.set({
          Active: false,
        }),
    }
  },
  COPY_CREDENTIAL: (partnerName: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.COPY_CREDENTIAL,
      properties: { partner: partnerName },
    }
  },
  ACCESS_PLAYLIST: (where: string, playlistName: string, recommended = false, personalized = false): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.ACCESS_PLAYLIST,
      properties: {
        where,
        playlistName,
        recommended,
        'Personalized playlist': personalized,
      },
    }
  },
  ACCESS_PARTNER: (partnerName: string, withExtension: boolean): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.ACCESS_PARTNER,
      properties: { partner: partnerName, withExtension },
    }
  },
  SIDEBAR_REDIRECT: (from: string, to: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.SIDEBAR_REDIRECT,
      properties: {
        from,
        to,
      },
    }
  },
  SIDEBAR_COLAPSE: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.SIDEBAR_COLAPSE,
      properties: {},
    }
  },
  OPEN_PARTNER_OPTIONS: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.OPEN_PARTNER_OPTIONS,
      properties: {},
    }
  },
  MENU_ADD_EXTENSION: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.MENU_ADD_EXTENSION,
      properties: {},
    }
  },
  LOGOUT: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.LOGOUT,
      properties: {},
    }
  },
  DEACTIVATE_SUBSCRIPTION: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.DEACTIVATE_SUBSCRIPTION,
      properties: {},
      callbackToBeExecuted: () =>
        Mixpanel.people.set({
          Active: false,
        }),
    }
  },
  SWIPE: (where: string, lineName: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.SWIPE,
      properties: { where, 'Line name': lineName },
    }
  },
  PLAYLISTS_SHOW_MORE: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PLAYLISTS_SHOW_MORE,
      properties: {},
    }
  },
  LOGIN: (email: string): MixpanelEvent => {
    return {
      beforeTracking: () => {
        Mixpanel.identify(email)
        Mixpanel.people.set({
          Active: true,
        })
      },
      eventName: MixpanelEvents.LOGIN,
      properties: {},
    }
  },
  FORGOT_PASSWORD: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.FORGOT_PASSWORD,
      properties: {},
    }
  },
  ONBOARDING_PAYMENT: (paid: boolean, user: User): MixpanelEvent => {
    return {
      beforeTracking: () => {
        Mixpanel.identify(user?.email!)
      },
      eventName: MixpanelEvents.ONBOARDING_PAYMENT,
      properties: {
        Paid: paid,
      },
      callbackToBeExecuted: () => {
        Mixpanel.people.set({
          Active: paid,
        })
      },
    }
  },
  ONBOARDING_FINISHED: (finishedOnboarding: boolean): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.ONBOARDING_FINISHED,
      properties: {
        'Finished Onboarding': finishedOnboarding,
      },
    }
  },
  ONBOARDING_REGISTRATION_FINISHED: (user: User, areaId: string | number, name: string): MixpanelEvent => {
    const userArea = user?.company?.areas!.filter((area: { id: number | undefined }) => +areaId === area.id)[0]?.name

    return {
      beforeTracking: () => {
        Mixpanel.identify(user?.email!)
        Mixpanel.people.set({
          distinct_id: user?.email,
          name: name,
          email: user?.email,
          Area: userArea,
          'Company Name': user?.company?.name,
        })
      },
      eventName: MixpanelEvents.ONBOARDING_REGISTRATION_FINISHED,
      properties: {},
    }
  },
  OPEN_CREDENTIALS_MODAL: (partnerName: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.OPEN_CREDENTIALS_MODAL,
      properties: { partner: partnerName },
    }
  },
  SIGNUP: (signedUp: boolean): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.SIGNUP,
      properties: {
        'Signed Up': signedUp,
      },
    }
  },
  SHARE: (to: string, where: string, on: string, learningItemType: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.SHARE,
      properties: {
        to,
        where,
        'Shared on': on,
        'Learning Item Type': learningItemType,
      },
    }
  },
  OPEN_EXPLORE: (where: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.OPEN_EXPLORE,
      properties: { where },
    }
  },
  ACCESS_CATEGORY: (from: string, category: string, on: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.ACCESS_CATEGORY,
      properties: {
        from,
        'Accessed On': on,
        'Accessed Category': category,
      },
    }
  },
  ACCESS_SUBCATEGORY: (from: string, category: string, on: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.ACCESS_SUBCATEGORY,
      properties: {
        from,
        'Accessed On': on,
        'Accessed Subcategory': category,
      },
    }
  },
  USED_PAGINATION: (where: string, page: number): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.USED_PAGINATION,
      properties: {
        where,
        page,
      },
    }
  },
  PROFILE_MENU_REDIRECT: (to: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PROFILE_MENU_REDIRECT,
      properties: { to },
    }
  },
  PROFILE_MENU_SIDEBAR_REDIRECT: (from: string, to: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PROFILE_MENU_SIDEBAR_REDIRECT,
      properties: { to, from },
    }
  },
  PROFILE_CHANGE_LANGUAGE: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PROFILE_CHANGE_LANGUAGE,
      properties: {},
    }
  },
  PROFILE_CHANGE_CAREER_MOMENT: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PROFILE_CHANGE_CAREER_MOMENT,
      properties: {},
    }
  },
  PROFILE_ACCORDION: (page: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PROFILE_ACCORDION,
      properties: { page },
    }
  },
  PROFILE_CHANGE_QUIZ: (competences, ability): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PROFILE_CHANGE_QUIZ,
      properties: {
        competences,
        ability,
      },
    }
  },
  ADD_FAVORITE_LEARNING_ITEM: (where, learningItemName, partner, on, learningItemType): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.ADD_FAVORITE_LEARNING_ITEM,
      properties: {
        Where: where,
        On: on,
        ...(partner && { 'Course Partner': partner }),
        'Learning Item Name': learningItemName,
        'Learning Item Type': learningItemType,
      },
    }
  },
  DELETE_FAVORITE_LEARNING_ITEM: (where, learningItemName, partner, on, learningItemType): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.DELETE_FAVORITE_LEARNING_ITEM,
      properties: {
        Where: where,
        On: on,
        ...(partner && { 'Course Partner': partner }),
        'Learning Item Name': learningItemName,
        'Learning Item Type': learningItemType,
      },
    }
  },
  RATE_LEARNING_ITEM: (where, learningItemName, learningItemType, partner): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.RATE_LEARNING_ITEM,
      properties: {
        Where: where,
        'Learning Item Name': learningItemName,
        'Learning Item Type': learningItemType,
        ...(partner && { 'Course Partner': partner }),
      },
    }
  },
  PERSONALIZED_PLAYLIST_SKILL_CONTEXT: (skill, context): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PERSONALIZED_PLAYLIST_SKILL_CONTEXT,
      properties: {
        'Skill name': skill,
        Input: context,
      },
    }
  },
  PERSONALIZED_PLAYLIST_CREATED: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PERSONALIZED_PLAYLIST_CREATED,
      properties: {},
    }
  },
  REMOVE_LEARNING_ITEM: (learningItemName): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.REMOVE_LEARNING_ITEM,
      properties: {
        'Learning Item Name': learningItemName,
      },
    }
  },
  REMOVE_LEARNING_ITEM_CONFIRMATION: (learningItemName, isLastLearningItem): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.REMOVE_LEARNING_ITEM_CONFIRMATION,
      properties: {
        'Learning Item Name': learningItemName,
        'Last Learning Item in Module': isLastLearningItem,
      },
    }
  },
  QUIZ_ACCESS_QUIZ: (where): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.QUIZ_ACCESS_QUIZ,
      properties: {
        where,
      },
    }
  },
  QUIZ_FINISH_QUIZ: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.QUIZ_FINISH_QUIZ,
      properties: {},
    }
  },
  QUIZ_NEXT_STEP: (step): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.QUIZ_NEXT_STEP,
      properties: {
        step,
      },
    }
  },
  QUIZ_DO_IT_LATER: (step: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.QUIZ_DO_IT_LATER,
      properties: {
        step,
      },
    }
  },
  QUIZ_RETURN: (from: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.QUIZ_RETURN,
      properties: {
        from,
      },
    }
  },
  CLICKED_ON_RATE_PLAYLIST: (playlistName: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.CLICKED_ON_RATE_PLAYLIST,
      properties: {
        playlistName,
      },
    }
  },
  MODAL_ACCESS_TAB: (from: string, to: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.MODAL_ACCESS_TAB,
      properties: {
        from,
        to,
      },
    }
  },
  PLAYLIST_ACCESS_TAB: (from: string, to: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PLAYLIST_ACCESS_TAB,
      properties: {
        from,
        to,
      },
    }
  },
  OPEN_SHARE_OPTIONS: (where: string, sharedOn: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.OPEN_SHARE_OPTIONS,
      properties: {
        where,
        sharedOn,
      },
    }
  },
  ACCORDION_PERSONAL_PLAYLIST: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.ACCORDION_PERSONAL_PLAYLIST,
      properties: {},
    }
  },
  PERSONALIZED_PLAYLIST_QUIZ_ADD_SKILL: (skillName: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PERSONALIZED_PLAYLIST_QUIZ_ADD_SKILL,
      properties: {
        skillName,
      },
    }
  },
  PERSONALIZED_PLAYLIST_QUIZ_REMOVE_SKILL: (skillName: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PERSONALIZED_PLAYLIST_QUIZ_REMOVE_SKILL,
      properties: {
        skillName,
      },
    }
  },
  PERSONALIZED_PLAYLIST_QUIZ_NEXT_STEP: (currentStep: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PERSONALIZED_PLAYLIST_QUIZ_NEXT_STEP,
      properties: {
        currentStep,
      },
    }
  },
  PERSONALIZED_PLAYLIST_QUIZ_FORMAT_ORDER: (contentTypes): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PERSONALIZED_PLAYLIST_QUIZ_FORMAT_ORDER,
      properties: {
        First: contentTypes[0].description,
        Second: contentTypes[1].description,
        Third: contentTypes[2].description,
        Fourth: contentTypes[3].description,
        Fifth: contentTypes[4].description,
        Sixth: null,
        Seventh: null,
        Eighth: null,
      },
    }
  },
  PERSONALIZED_PLAYLIST_CLICK_TO_CHANGE_LEARNING_ITEM: (learningItemName: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PERSONALIZED_PLAYLIST_CLICK_TO_CHANGE_LEARNING_ITEM,
      properties: {
        learningItemName,
      },
    }
  },
  PERSONALIZED_PLAYLIST_CHANGE_LEARNING_ITEM_CONFIRMATION: (
    oldLearningItemName: string,
    newLearningItemName: string,
  ): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PERSONALIZED_PLAYLIST_CHANGE_LEARNING_ITEM_CONFIRMATION,
      properties: {
        oldLearningItemName,
        newLearningItemName,
      },
    }
  },
  OPEN_LEARNING_ITEM_DRAWER: (learningItemName: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.OPEN_LEARNING_ITEM_DRAWER,
      properties: {
        learningItemName,
      },
    }
  },
  CHANGE_TAB_LEARNING_ITEM_DRAWER: (learningItemName: string, from: string, to: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.CHANGE_TAB_LEARNING_ITEM_DRAWER,
      properties: {
        learningItemName,
        from,
        to,
      },
    }
  },
  PLAYLIST_EDIT_SKILLS: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PLAYLIST_EDIT_SKILLS,
      properties: {},
    }
  },
  PLAYLIST_EDIT_SKILLS_CONFIRMATION: (skillsRemoved, skillsAdded): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PLAYLIST_EDIT_SKILLS_CONFIRMATION,
      properties: {
        'Skills removed': skillsRemoved,
        'Skills added': skillsAdded,
      },
    }
  },
  ADD_LEARNING_ITEM: (skill): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.ADD_LEARNING_ITEM,
      properties: {
        'Skill name': skill,
      },
    }
  },
  ADD_LEARNING_ITEM_CONFIRMATION: (skill, newLearningItems, newLearningItemsLength): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.ADD_LEARNING_ITEM_CONFIRMATION,
      properties: {
        'Skill name': skill,
        'New added learning items names': newLearningItems,
        'Number of added learning items': newLearningItemsLength,
      },
    }
  },
  CHANGED_USER_VIEW: (from: string, to: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.CHANGED_USER_VIEW,
      properties: {
        From: from,
        To: to,
      },
    }
  },
  COURSE_DASH_FILTER: (tab, filter): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.COURSE_DASH_FILTER,
      properties: {
        'Tab name': tab,
        'Filter name': filter,
      },
    }
  },
  PLAYLIST_DASH_FILTER: (tab, filter): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.PLAYLIST_DASH_FILTER,
      properties: {
        'Tab name': tab,
        'Filter name': filter,
      },
    }
  },
  DOWNLOAD_PLAYLIST_DASH: (dataType): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.DOWNLOAD_PLAYLIST_DASH,
      properties: {
        'Database type': dataType,
      },
    }
  },
  OPEN_PLAYLIST_DETAILS: (isFilterApplied, filters, progress, amountOfSkills): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.OPEN_PLAYLIST_DETAILS,
      properties: {
        'Filter is applied': isFilterApplied,
        'Applied filter name': filters,
        'Curent Progress': progress,
        'Curent Number of skills': amountOfSkills,
      },
    }
  },
  EDIT_PERMISSIONS: (userPermissions): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.EDIT_PERMISSIONS,
      properties: {
        'Curent user permission': userPermissions,
      },
    }
  },
  TEAM_CHOSEN: (isNewTeam): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.TEAM_CHOSEN,
      properties: {
        'Team creatation': isNewTeam,
      },
    }
  },
  EDIT_PERMISSIONS_CONFIMATION: (permissionType): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.EDIT_PERMISSIONS_CONFIMATION,
      properties: {
        'Type of edition': permissionType,
      },
    }
  },
  CLICKED_TO_EDIT_TEAM: (role: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.CLICKED_TO_EDIT_TEAM,
      properties: {
        'Curent user permission': role,
      },
    }
  },
  TEAM_EDIT_TYPE_SELECTION: (types: string): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.TEAM_EDIT_TYPE_SELECTION,
      properties: {
        'Team edit type': types,
      },
    }
  },
  TEAM_EDIT_CONFIRMATION: (): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.TEAM_EDIT_CONFIRMATION,
      properties: {},
    }
  },

  EDIT_TEAM: (typeOfEdition: string, numberOfUsers: number): MixpanelEvent => {
    return {
      eventName: MixpanelEvents.EDIT_TEAM,
      properties: {
        'Type of edition': typeOfEdition,
        'Number of users changed': numberOfUsers,
      },
    }
  },
}
