import { ApolloQueryResult, useQuery } from '@apollo/client'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { User } from 'src/types/User'
import LIST_TEAMS from 'src/graphql/queries/LIST_TEAMS'
import { Team } from 'src/types/Team'

export type ReturnTeam = {
  listTeams: Team[]
}

interface ContextValues {
  toggleTeamsDrawer: boolean
  handleToggleTeamsDrawer: () => void
  successText: string
  setSuccessText: (value: string) => void
  selectedCollab: User
  setSelectedCollab: (colab: User) => void
  selectedTeam: Team
  setSelectedTeam: (team: Team) => void
  teams: Team[]
  setTeams: (teams: Team[]) => void
  addCollabs: any[]
  handleCollabsAdd: (collab: any) => void
  removeCollabs: any[]
  handleCollabsRemove: (collab: any) => void
  newManager: any
  handleNewManager: (manager: any) => void
  selectedOptions: string[]
  handleSelectedOptions: (option: any) => void
  getNextOption: () => string
  refetchTeams: () => Promise<ApolloQueryResult<ReturnTeam>>
}

type ProviderProps = {
  children: React.ReactNode[] | React.ReactNode
}

const TeamsContext = createContext({} as ContextValues)

export function TeamsProvider({ children }: ProviderProps) {
  const [toggleTeamsDrawer, setToggleTeamsDrawer] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [successText, setSuccessText] = useState('')

  const [selectedCollab, setSelectedCollab] = useState<User>({} as User)
  const [selectedTeam, setSelectedTeam] = useState<Team>({} as Team)
  const [teams, setTeams] = useState<Team[]>([])

  const [addCollabs, setAddCollabs] = useState<any[]>([])
  const [removeCollabs, setRemoveCollabs] = useState<any[]>([])
  const [newManager, setNewManager] = useState<any>({})

  const { refetch: refetchTeams } = useQuery<ReturnTeam>(LIST_TEAMS, {
    onCompleted: (data) => {
      setTeams(data.listTeams)
    },
  })

  useEffect(() => resetState(), [toggleTeamsDrawer])

  const resetState = () => {
    setAddCollabs([])
    setRemoveCollabs([])
    setNewManager({})
  }

  const handleToggleTeamsDrawer = useCallback(() => {
    setToggleTeamsDrawer((prevState) => !prevState)
  }, [])

  const handleCollabsAdd = useCallback(
    (collab: any) => {
      const isSelected = addCollabs.some((item) => item.id === collab.id)

      if (isSelected) {
        setAddCollabs((prevState) => prevState.filter((item) => item.id !== collab.id))
      } else {
        setAddCollabs((prevState) => [...prevState, collab])
      }
    },
    [addCollabs],
  )

  const handleCollabsRemove = useCallback(
    (collab: any) => {
      const isSelected = removeCollabs.some((item) => item.id === collab.id)

      if (isSelected) {
        setRemoveCollabs((prevState) => prevState.filter((item) => item.id !== collab.id))
      } else {
        setRemoveCollabs((prevState) => [...prevState, collab])
      }
    },
    [removeCollabs],
  )

  const handleNewManager = useCallback(
    (manager: any) => {
      if (newManager.id) {
        const isSelected = newManager.id === manager.id
        if (isSelected) setNewManager({})
        else setNewManager(manager)
      } else {
        setNewManager(manager)
      }
    },
    [newManager],
  )

  const handleSelectedOptions = useCallback(
    (option: any) => {
      const isSelected = selectedOptions.indexOf(option) !== -1

      if (option === 'DELETE_TEAM' && !isSelected) {
        setSelectedOptions(['DELETE_TEAM'])
        return
      }

      if (selectedOptions.indexOf('DELETE_TEAM') !== -1 && option !== 'DELETE_TEAM') {
        setSelectedOptions((prevState) => prevState.filter((item) => item !== 'DELETE_TEAM'))
      }

      if (isSelected) {
        setSelectedOptions((prevState) => prevState.filter((item) => item !== option))
      } else {
        setSelectedOptions((prevState) => [...prevState, option])
      }
    },
    [selectedOptions],
  )

  const getNextOption = () => {
    const order = ['CHANGE_MANAGER', 'ADD_COLLABS', 'REMOVE_COLLABS']

    if (selectedOptions.length === 1) return selectedOptions[0]

    if (selectedOptions.indexOf(order[0]) !== -1) return order[0]
    if (selectedOptions.indexOf(order[1]) !== -1) return order[1]
    if (selectedOptions.indexOf(order[2]) !== -1) return order[2]

    return ''
  }

  return (
    <TeamsContext.Provider
      value={{
        toggleTeamsDrawer,
        handleToggleTeamsDrawer,
        successText,
        setSuccessText,
        selectedCollab,
        setSelectedCollab,
        selectedTeam,
        setSelectedTeam,
        teams,
        setTeams,
        addCollabs,
        handleCollabsAdd,
        removeCollabs,
        handleCollabsRemove,
        newManager,
        handleNewManager,
        selectedOptions,
        handleSelectedOptions,
        getNextOption,
        refetchTeams,
      }}
    >
      {children}
    </TeamsContext.Provider>
  )
}

export const useTeams = () => useContext(TeamsContext)
