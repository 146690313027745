import { Flex, Skeleton, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { User } from 'src/types/User'
import { UserListCard } from '../UserListCard'

interface UserListViewProps {
  data?: User[]
  loading?: boolean
}

export function UserListView({ data, loading }: UserListViewProps) {
  const [t] = useTranslation()

  return (
    <Flex flexDir='column' gap='8px'>
      <Flex gap='6px'>
        <Text fontSize='1rem' fontWeight='700'>
          {t('areaMembers')}
        </Text>
        {!!data && data?.length > 0 && !loading && <Text fontSize='1rem'>({data?.length ?? 0} colaboradores)</Text>}
      </Flex>
      <Flex flexDir='column' gap='16px' flex='1' maxH='30vh' overflowY='auto'>
        {/* {data?.map((user, index) => (
          <UserListCard key={index} data={user} />
        ))} */}
        {loading && (
          <>
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeleton
                key={index}
                px='16px'
                py='8px'
                minH='36px'
                bg='#F7F9FA'
                borderRadius='12px'
                boxShadow='0px 1px 6px 0px rgba(0, 0, 0, 0.10)'
              />
            ))}
          </>
        )}
        {!loading && data?.map((user, index) => <UserListCard key={user?.id ?? index} data={user} />)}
      </Flex>
    </Flex>
  )
}
