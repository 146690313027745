import { Box, Image, Input, InputGroup, Stack, Text } from '@chakra-ui/react'
import '../../services/i18n/i18n'
import CopyButton from './CopyButton'

interface Props {
  logo?: string
  email?: string
  partnerName?: string
}

const EmailField: React.FC<Props> = ({ email, logo, partnerName }: Props) => {
  return (
    <Stack flexDirection='row' alignItems='center' mt='8px'>
      {logo && <Image w='32px' h='32px' borderRadius='6px' bg='#EFEFEF' src={logo} marginRight='15px' />}

      {partnerName && (
        <Box w='150px' marginTop='0 !important'>
          <Text fontFamily='Poppins' fontWeight='bold' fontSize='0.75rem' color='gray.300' marginRight='15px'>
            {partnerName}
          </Text>
        </Box>
      )}

      <InputGroup w='100%' bg='none' alignItems='center' margin='0 !important'>
        <Input
          fontFamily='Poppins'
          fontWeight='bold'
          fontSize={{ base: '0.875rem', lg: '1rem' }}
          padding='0px'
          variant='unstyled'
          type='text'
          value={email}
        />
        <CopyButton value={email ?? ''} />
      </InputGroup>
    </Stack>
  )
}

export default EmailField
