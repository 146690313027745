import { ContentRepository } from 'src/modules/cms/repositories/ContentRepository'
import { ContentList } from 'src/types/Cms'

export type ListLastAccessedContentsUseCaseOutput = ContentList

export type ListLastAccessedByUserInputs = {
  ids: string[]
  limit: number
  skip: number
}

export class ListLastAccessedContentsUseCase {
  constructor(private contentRepository: ContentRepository) {}

  async execute(input: ListLastAccessedByUserInputs): Promise<ListLastAccessedContentsUseCaseOutput> {
    const params = { ...input }

    const contentList = await this.contentRepository.listLastAccessedByUser(params)
    return contentList
  }
}
