import {
  Flex,
  Heading,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from 'src/context/userContext'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { getPlaylistPartnerLogos, getPlaylistPartnerNames, getPlaylistTags } from '../../../../pages/PlaylistPage/utils'

interface Props {
  playlist: NewPlaylist
}

const DescriptionSection: React.FC<Props> = ({ playlist }: Props) => {
  const [t] = useTranslation()
  const { user } = useUser()
  playlist = playlist!

  const { playlistPartnerSquareLogos } = getPlaylistPartnerLogos(playlist)
  const playlistPartnerNames = getPlaylistPartnerNames(playlist)

  const tags = useMemo(() => {
    return getPlaylistTags(playlist, user?.language)
  }, [user, playlist])

  const playListTitle = useMemo(() => {
    return playlist.translations?.find((translation) => translation.language === user?.language)?.title ?? playlist.title
  }, [user, playlist])

  const playListDescription = useMemo(() => {
    return playlist.translations?.find((translation) => translation.language === user?.language)?.description ?? playlist.description
  }, [user, playlist])

  return (
    <Flex
      marginLeft={{ base: '0px', lg: '72px' }}
      marginRight={{ base: '0px', lg: '72px' }}
      marginBottom={{ base: '24px', lg: '39px' }}
      padding={{ base: '32px 32px 0px', lg: '0px' }}
      direction={{ base: 'column', lg: 'row' }}
      alignItems='center'
    >
      <Flex flex='1' direction={{ base: 'column', lg: 'row' }}>
        <Image
          src={playlist?.imageUrl ?? 'https://i.ibb.co/fS8fCDS/dummy-300x300-009136-ffffff-education-journey.png'}
          marginRight='28px'
          w='203px'
        />
        <Flex flexDirection='column' gap={{ base: '8px', lg: '0px' }} maxW={{ base: 'unset', md: '700px' }}>
          <Heading as='h5' size='md' marginBottom='2px'>
            {playListTitle}
          </Heading>
          <Text>{playListDescription}</Text>
          <Flex marginTop={{ base: '0px', lg: '17px' }} flexWrap={{ base: 'wrap', lg: 'unset' }} gap={{ base: '10px' }}>
            {tags.map((tag: string) => (
              <Tag
                borderRadius='18px'
                background='gray.65'
                padding='5px 11px'
                textTransform='capitalize'
                width='fit-content'
                color='gray.690'
                fontWeight='600'
                fontSize='13px'
              >
                <TagLabel textTransform='capitalize'>{tag}</TagLabel>
              </Tag>
            ))}
          </Flex>
        </Flex>
      </Flex>

      {playlistPartnerSquareLogos.length > 0 ? (
        <Flex
          minW='fit-content'
          flexDirection='column'
          alignItems='flex-end'
          marginLeft={{ base: '0px', lg: '14px' }}
          marginTop={{ base: '16px', lg: '0px' }}
        >
          <Text minW='fit-content' marginBottom='4px'>
            {t('playlist.Offered By')}
          </Text>
          <Flex>
            {playlistPartnerSquareLogos.map((squareLogo: string, index: number) => (
              <Popover trigger='hover' placement='top'>
                <PopoverContent
                  backgroundColor='gray.800'
                  borderRadius='20px'
                  width={'fit-content'}
                  border='none'
                  zIndex={'popover'}
                >
                  <PopoverArrow backgroundColor='gray.800' />
                  <PopoverBody textAlign={'center'}>
                    <Text fontSize='14px' color='white' fontWeight={700}>
                      {playlistPartnerNames[index]}
                    </Text>
                  </PopoverBody>
                </PopoverContent>

                <PopoverTrigger>
                  <Image w='23px' marginLeft='3px' src={squareLogo} />
                </PopoverTrigger>
              </Popover>
            ))}
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  )
}

export default DescriptionSection
