import { createContext, useContext, useEffect, useState } from 'react'

interface Slug {
  category?: string
  subcategory?: string
  skills?: {
    name: string
    id: string
  }[]
}

export type SkillChildren = {
  name: string
  id: string
}

export type ISkillTree = {
  rootId: string
  skillId: string
  subCategoryId: string
  subCategoryList: any[]
  skillList: SkillChildren[]
}

export interface ExploreContextValue {
  activeSlug: Slug
  setActiveSlug: (slugObject: Slug) => void
  skillTreeData: ISkillTree
  setSkillTreeData: React.Dispatch<React.SetStateAction<ISkillTree>>
}

interface ProviderProps {
  children: React.ReactNode[] | React.ReactNode
}

interface AllowedRouteParams {
  category: string
  subcategory: string
}

export const ExploreContext = createContext<ExploreContextValue>({} as ExploreContextValue)

export function ExploreContextProvider({ children }: ProviderProps) {
  const [activeSlug, setActiveSlugState] = useState<Slug>({} as Slug)
  const [skillTreeData, setSkillTreeData] = useState<ISkillTree>({} as ISkillTree)

  const setActiveSlug = (slugObject: Slug) => {
    setActiveSlugState(slugObject)
  }

  const parseRawUrlToParams = (rawUrl: string): AllowedRouteParams => {
    const [, category, subcategory] = rawUrl.split('/') || []

    return { category: category, subcategory: subcategory }
  }

  useEffect(() => {
    setActiveSlugState(parseRawUrlToParams(window?.location?.pathname))
  }, [])

  return (
    <ExploreContext.Provider value={{ skillTreeData, activeSlug, setActiveSlug, setSkillTreeData }}>
      {children}
    </ExploreContext.Provider>
  )
}

export const useExploreContext = () => useContext(ExploreContext)
