import { gql } from "@apollo/client";

export const GET_PARTNER = gql`
  query GetPartner($slug: String!) {
    GetPartner(slug: $slug) {
      name
      slug
      accessLink
      logo
      squareLogo
    }
    GetUserCredentials(partnerSlug: $slug) {
      login
      password
    }
  }
`

export default GET_PARTNER