import { useLazyQuery } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { usePermissions } from 'src/context-admin/usePermissions'
import LIST_USERS from 'src/graphql/queries/LIST_USERS'
import { debounce } from 'src/helpers-admin/debounce'
import { ReturnDashInfo } from 'src/pages-admin/Home/home.types'
import { User } from 'src/types/User'
import { useAllocateSkillStore } from '../../hooks/useAllocateSkillStore'

export function useAllocateSkill() {
  const { pageInfo, updateCollabsList, updatePageInfo, incrementCollabsList } = usePermissions()
  const { selectedCollabs, updateSelectedCollabs, setSelectedCollabs } = useAllocateSkillStore()
  const [loadingRefetch, setLoadingRefetch] = useState(false)
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true)
  const [searchValue, setSearchValue] = useState('')

  const [getUsersList, { refetch, loading }] = useLazyQuery<ReturnDashInfo>(LIST_USERS, {
    variables: { limit: 50, ...(searchValue ? { name: searchValue } : {}) },
    onCompleted: (data) => {
      if (isFirstTimeLoading) {
        updateCollabsList(data.listUsers.data)
        updatePageInfo(data.listUsers.paginateInfo)

        setIsFirstTimeLoading(false)
      }
    },
  })

  const fetchMore = async () => {
    if (!pageInfo?.next) return

    setLoadingRefetch(true)

    const response = await refetch({
      limit: 50,
      cursor: Number(pageInfo?.next),
      ...(searchValue ? { name: searchValue } : {}),
    })

    if (response?.data && response.data?.listUsers) {
      incrementCollabsList(response.data.listUsers.data)
      updatePageInfo(response.data.listUsers.paginateInfo)
    }

    setLoadingRefetch(false)
  }

  const handleSelectCollabs = useCallback(
    (collab: User) => {
      const isSelected = selectedCollabs.some((item) => item.id === collab.id)

      if (isSelected) {
        setSelectedCollabs(selectedCollabs.filter((item) => item.id !== collab.id))
      } else {
        updateSelectedCollabs(collab)
      }
    },
    [selectedCollabs],
  )

  function handleSearch(value: string) {
    setIsFirstTimeLoading(true)
    setSearchValue(value)
  }

  const searchDebounce = debounce(handleSearch, 500)

  useEffect(() => {
    async function getList() {
      const response = await getUsersList()

      if (response && response.data && response.data?.listUsers) {
        updateCollabsList(response.data.listUsers.data)
        updatePageInfo(response.data.listUsers.paginateInfo)
      }
    }

    getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUsersList])

  return {
    getUsersList,
    fetchMore,
    loading,
    loadingRefetch,
    handleSelectCollabs,
    searchDebounce,
  }
}
