import { createContext, ReactNode, useContext, useState } from 'react'
import { Team } from 'src/modules/dashboard/entities/Team'
import { makeAddTeamManagers } from 'src/modules/dashboard/factory/makeAddTeamManagers'
import { useHandleTeam } from './useHandleTeam'

interface TeamManagementContext {
  teams: Team[]
  selectedTeam?: Team
  handleSelectTeam: (team?: Team) => void
  includesMemberToArea: (collabId: number) => Promise<void>
}

const NewTeamManagement = createContext({} as TeamManagementContext)

export function NewTeamManagementProvider({ children }: { children: ReactNode }) {
  const { teams } = useHandleTeam()
  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>(undefined)

  const handleSelectTeam = (team?: Team) => {
    setSelectedTeam(team)
  }

  const includesMemberToArea = async (collabId: number) => {
    try {
      if (!selectedTeam) throw new Error('Você precisa selecionar um time.')
      const addManagerToTeam = makeAddTeamManagers()
      await addManagerToTeam.execute({ teamId: selectedTeam.id, managersIds: [collabId] })
    } catch (err: any) {
      throw new Error(err?.message)
    }
  }

  return (
    <NewTeamManagement.Provider value={{ teams, handleSelectTeam, selectedTeam, includesMemberToArea }}>
      {children}
    </NewTeamManagement.Provider>
  )
}

export function useNewTeamManagement() {
  const context = useContext(NewTeamManagement)
  return context
}
