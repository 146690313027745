import { CompanyRepository } from '../repositories/Company'

export type Input = {
  partnerId: string
  token: string
}

export class ValidatePartnerTokenUseCase {
  constructor(private repository: CompanyRepository) {}

  async execute({ partnerId, token }: Input) {
    const responseBody = await this.repository.validatePartnerToken(partnerId, token)
    return responseBody
  }
}
