import SUBMIT_EMPLOYEES from 'src/graphql/mutations/SUBMIT_EMPLOYEES'
import { Company } from 'src/modules/cms/domain/Company'
import { ListEmployees } from 'src/pages-admin/Home/components/ModalUpdateEmployees/components/AddEmployees'
import { FormValues } from 'src/pages-admin/Home/components/ModalUpdateEmployees/types/Form'
import { apolloClient } from 'src/services/apollo/ApolloClient'
import { IHttpClient } from 'src/services/http/http-client'
import ApiError, { NetWorkError } from 'src/shared/ApiError'
import { logger } from 'src/shared/Logger'
import { PaginateApiResponse } from 'src/shared/PaginateApiResponse'
import { User } from 'src/types/User'
import { UPDATE_USER_PREFERENCES } from '../graphql/mutations/UPDATE_USER_PREFERENCES'
import { CertificateTypeEnum } from '../use-case/GetUserCertificatesUseCase'
import { ListUsersInput, ListUsersOutput } from '../use-case/ListUsersUseCase'

type UpdateUserInformationInput = {
  companyId: number
  users: { email: string; area: string; subsidiary: string }[]
}

type UpdateUserInformationOutput = {
  errors?: { email: string; code: string; reason: string }[]
}

type ImportUser = {
  errors?: { email: string; code: string; reason: string }[]
  users?: { id: string }[]
}

export interface UserToEdit extends User {
  customAttributes?: FormValues
}

export interface SaveUserEdition {
  userToEdit: User
  fieldsEditted: FormValues
}

export interface SaveUserEditionMassive {
  companyId: number
  users: FormValues[]
}

export interface ReturnSaveUserEditionMassive {
  errors: { email: string; code: string; reason: string }[]
  wasSaved: boolean
}

export interface GetUserToEditResponse {
  error: string | null
  body: UserToEdit | null
  message: string | null
  raw: any
}

interface IUserRepository {
  getUserInfo(): Promise<User | null>
  getUserById(id: number): Promise<User>
  getUserCertificates(
    page?: number,
    limit?: number,
    playlistId?: string,
    type?: CertificateTypeEnum,
  ): Promise<PaginateApiResponse<any>>
  list(args: ListUsersInput): Promise<ListUsersOutput>
  updateUserInformation(args: UpdateUserInformationInput): Promise<UpdateUserInformationOutput>
  updateUserPreferences(args: { userId: number; languages: string[]; subtitles: string[] }): Promise<boolean>
  importUsers(users: any): Promise<any>
  getUserToEdit(id: number): Promise<GetUserToEditResponse>
  saveUserEdition(params: SaveUserEdition): Promise<boolean>
  saveUserEditionMassive(params: SaveUserEditionMassive): Promise<ReturnSaveUserEditionMassive>
}

export class UserRepository implements IUserRepository {
  constructor(private token: string, private httpClient: IHttpClient) {}

  async getUserInfo(): Promise<User | null> {
    try {
      const response = await this.httpClient.request<User>({
        method: 'GET',
        url: '/v1/iam/user',
        headers: {
          Authorization: this.token,
        },
      })
      return response.body
    } catch {
      return null
    }
  }

  async getUserCompany(): Promise<Company | null> {
    try {
      const response = await this.httpClient.request<Company>({
        method: 'GET',
        url: '/v1/company',
        headers: {
          Authorization: this.token,
        },
      })
      return response.body
    } catch {
      return null
    }
  }

  async getUserById(id: number): Promise<User> {
    try {
      const response = await this.httpClient.request<User>({
        url: `/v1/iam/user/${id}`,
        method: 'GET',
        headers: { Authorization: this.token },
      })
      return response.body
    } catch {
      throw new Error('Ocorreu um erro ao buscar os dados do usuário.')
    }
  }

  async getUserCertificates(page = 1, limit = 10, playlistId?: string, type?: CertificateTypeEnum): Promise<any> {
    const skip = (page - 1) * limit
    try {
      const response = await this.httpClient.request<PaginateApiResponse<any>>({
        method: 'GET',
        url: `/v1/iam/certificates`,
        params: {
          limit,
          skip,
          ...(playlistId ? { playlist_id: playlistId } : {}),
          ...(type ? { type } : {}),
        },
        headers: { Authorization: this.token },
      })
      return response.body
    } catch {
      throw new Error('Ocorreu um erro ao buscar a lista de certificados do usuário.')
    }
  }

  async list(args: ListUsersInput): Promise<ListUsersOutput> {
    try {
      const response = await this.httpClient.request({
        method: 'GET',
        url: `/v1/iam/users`,
        params: {
          limit: args.limit,
          ...(args.cursor ? { cursor: args.cursor } : {}),
          ...(args.areaId ? { areaId: args.areaId } : {}),
          ...(args.teamId ? { teamId: args.teamId } : {}),
          ...(args.name ? { nameContains: args.name } : {}),
          ...(args.permission ? { permission: args.permission } : {}),
          ...(args.orderByName ? { orderByName: args.orderByName } : {}),
        },
        headers: { Authorization: this.token },
      })

      return {
        data: response.body,
        paginateInfo: response.paginateInfo,
      }
    } catch {
      throw new Error('Ocorreu um erro ao buscar a lista de usuários.')
    }
  }

  async updateUserInformation(args: UpdateUserInformationInput): Promise<UpdateUserInformationOutput> {
    const { companyId, users } = args

    try {
      const response = await this.httpClient.request({
        method: 'PATCH',
        url: `/v1/iam/users`,
        data: {
          companyId,
          users,
        },
        headers: { Authorization: this.token },
      })

      return response.body
    } catch (err: any) {
      return {
        errors: [{ code: err?.response?.data?.error?.code, email: '', reason: '' }],
      }
    }
  }

  async updateUserPreferences(args: { languages: string[]; subtitles: string[] }) {
    const { languages, subtitles } = args

    const promise = apolloClient
      .query({
        query: UPDATE_USER_PREFERENCES,
        variables: {
          userPreferences: {
            audio_languages: languages,
            subtitle_languages: subtitles,
          },
        },
      })
      .then(async ({ errors }) => {
        if (errors?.length) {
          const code = errors[0].message
          const message = ''
          throw new ApiError(message, code)
        }

        return true
      })
      .catch((error) => {
        logger.error('create user preferences request failed', { error })
        if (error instanceof ApiError) throw error
        throw new NetWorkError()
      })

    return promise
  }

  async importUsers(users: ListEmployees): Promise<ImportUser> {
    const response = await apolloClient.mutate({
      mutation: SUBMIT_EMPLOYEES,
      variables: {
        users,
      },
    })

    return response.data.importUsers
  }

  async getUserToEdit(id: number): Promise<GetUserToEditResponse> {
    try {
      const response = await this.httpClient.request<UserToEdit>({
        method: 'GET',
        url: `/v1/iam/user/${id}`,
        headers: {
          Authorization: `${this.token}`,
        },
      })

      return {
        error: null,
        body: response.body,
        message: null,
        raw: response,
      }
    } catch (err: any) {
      return {
        error: err?.response?.data?.error?.code,
        body: null,
        message: err?.response?.data?.error?.message,
        raw: err,
      }
    }
  }

  async saveUserEdition(params: SaveUserEdition): Promise<boolean> {
    try {
      const response = await this.httpClient.request({
        method: 'PATCH',
        url: `/v1/iam/modal/users`,
        data: [params.userToEdit, params.fieldsEditted],
        headers: {
          Authorization: `${this.token}`,
        },
      })
      if (![200, 201, 203, 204].includes(response.statusCode)) return false
      return true
    } catch (err) {
      console.log(err)
      return false
    }
  }

  async saveUserEditionMassive(params: SaveUserEditionMassive): Promise<ReturnSaveUserEditionMassive> {
    const data: SaveUserEditionMassive = {
      companyId: params.companyId,
      users: params.users,
    }

    try {
      const response = await this.httpClient.request<any>({
        method: 'PATCH',
        url: 'v1/iam/users',
        data,
        headers: {
          Authorization: `${this.token}`,
        },
      })
      return {
        wasSaved: true,
        errors: response?.body?.errors ?? [],
      }
    } catch (err: any) {
      console.error(err)
      return {
        wasSaved: false,
        errors: err?.response?.data?.errors ?? [],
      }
    }
  }
}
