import { Button, Flex, Icon, Skeleton, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import FavoriteIcon from 'src/components/FavoriteIcon'
import { useEvaluate } from 'src/context/EvaluateContext'
import { useLearningItemModal } from 'src/context/LearningItemModalContext'
import { useUser } from 'src/context/userContext'
import { getContentData } from 'src/helpers/contentInfo'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { useConfetti } from 'src/hooks/useConfetti'
import useRegisterAccess from 'src/hooks/useRegisterAccess'
import Content from 'src/modules/cms/domain/Content'
import { ContentType } from 'src/modules/cms/domain/LearningItem'
import { makeGetContentUrl } from 'src/modules/cms/factories/makeGetContentUrl'
import { makeMarkContent } from 'src/modules/cms/factories/makeMarkContent'
import { ContentAction } from 'src/modules/cms/use_cases/MarkContentUseCase'
import { EvaluateIconFilledSvg, Icons8CircleCloseIcon } from 'src/theme/Icons'
import { LearningItemProgress } from 'src/types/LearningItemProgress'
import ShareButton from './ShareButton'

type Props = {
  onModalClose: () => void
  setLearningItem?: (item: Content) => void
  initialLearningItem?: Content
  content: Content
}

const LearningItemBody: React.FC<Props> = ({ onModalClose, initialLearningItem, content, setLearningItem }) => {
  const [t] = useTranslation()
  const register = useRegisterAccess()
  const history = useHistory()
  const location = useLocation()
  const { getUser } = useUser()
  const { showConfetti } = useConfetti()
  const { openEvaluation, updateCourseInfo } = useEvaluate()
  const { updateContent } = useLearningItemModal()
  const { EventsImplementation, track } = useMixpanel()
  const toast = useToast()
  const markContent = makeMarkContent()
  const contentData = getContentData(content?.type ?? 'Course', t)
  const isLearningItemDone =
    content.progress === LearningItemProgress.DONE || initialLearningItem?.progress === LearningItemProgress.DONE

  const handleStartLearningItem = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    register(content.id)
    const type = content.type

    if (isLearningItemDone) {
      onModalClose()
      updateCourseInfo(content)
      openEvaluation({ step: 1, source: 'playlistLearningItemFinished' })

      track(EventsImplementation.RATE_LEARNING_ITEM(location.pathname, content.title, type, content?.partner?.name))
    } else {
      const contentCopy = { ...content }
      contentCopy.progress = LearningItemProgress.DOING
      updateContent(contentCopy)
      setLearningItem?.(contentCopy)

      track(
        EventsImplementation.ACCESS_LEARNING_ITEM(
          null,
          content?.favorited!,
          type,
          content.title,
          content.id,
          content?.partner?.name!,
          location.pathname,
          'Modal',
        ),
      )

      const isVideo = type === ContentType.VIDEO || type === ContentType.VIDEODTO

      if (isVideo && content?.allowsEmbedding) {
        history.push(`/learningItem/${content.id}`)
      } else {
        const getUrl = makeGetContentUrl()
        const url = await getUrl.execute({ id: content.id })

        if (!url) {
          toast({
            title: t('errorWhileLoadingContent'),
            isClosable: true,
            duration: 5000,
            status: 'error',
          })

          return
        }

        window.open(url, '_blank')
      }
    }
  }

  const onMarkAsDone = () => {
    const contentCopy = { ...content }
    contentCopy.progress = LearningItemProgress.DONE
    showConfetti()
    setLearningItem?.(contentCopy)
    updateContent(contentCopy)
    track(EventsImplementation.MARK_AS_DONE(content.title, location.pathname, 'Modal'))

    updateCourseInfo(contentCopy)
    openEvaluation({ step: 1, source: 'playlistLearningItemFinished' })
  }

  const onMarkAsUndone = async () => {
    try {
      const contentCopy = { ...content }
      contentCopy.progress = LearningItemProgress.DOING
      await markContent.execute({ id: content.id, action: ContentAction.UNDONE })
      getUser()
      setLearningItem?.(contentCopy)
      updateContent(contentCopy)

      track(EventsImplementation.MARK_AS_UNDONE(content.title, location.pathname, 'Modal'))
    } catch (error) {
      const contentCopy = { ...content }
      contentCopy.progress = LearningItemProgress.DONE
      setLearningItem?.(contentCopy)
      updateContent(contentCopy)
    }
  }

  const handleMarkAsDone = async () => {
    await markContent.execute({ id: content.id, action: ContentAction.DONE })
    getUser()
    onMarkAsDone()
  }

  if (!content && !contentData) {
    return (
      <Flex flexDirection='column' gap='12px'>
        <Skeleton w='180px' h='44px' borderRadius='16px' />

        <Flex gap='8px'>
          <Skeleton w='200px' h='44px' borderRadius='16px' />
          <Skeleton w='48px' h='44px' borderRadius='16px' />
          <Skeleton w='48px' h='44px' borderRadius='16px' />
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex flexDirection='column' gap='12px'>
      <Button
        variant='startCourseDark'
        h='44px'
        maxW={{ base: 'unset', md: '180px' }}
        padding='0 17px'
        onClick={handleStartLearningItem}
      >
        <Icon
          w={{ base: '24px', sm: '30px' }}
          h={{ base: '24px', sm: '30px' }}
          color='white'
          marginRight='8px'
          as={isLearningItemDone ? EvaluateIconFilledSvg : contentData?.icon}
        />
        {isLearningItemDone ? t('evaluate.evaluate') : contentData?.startButton}
      </Button>

      <Flex gap='8px' flexWrap={{ base: 'wrap', lg: 'unset' }} w='100%'>
        <Button
          variant='startCourse'
          border='2px solid #E5E5E5'
          h='44px'
          w='100%'
          maxW={{ base: 'unset', md: '285px' }}
          onClick={() => (isLearningItemDone ? onMarkAsUndone() : handleMarkAsDone())}
          whiteSpace='normal'
        >
          <Icon
            w={{ base: '24px', sm: '30px' }}
            h={{ base: '24px', sm: '30px' }}
            marginRight='8px'
            as={isLearningItemDone ? Icons8CircleCloseIcon : contentData?.icon}
          />
          {isLearningItemDone ? contentData?.markAsNotComplete : contentData?.markAsComplete}
        </Button>

        <Flex gap='8px' align='center' justify='flex-start'>
          <ShareButton content={content} />
          {initialLearningItem && (
            <FavoriteIcon content={initialLearningItem} onFavorite={setLearningItem} height='44px' />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default LearningItemBody
