import { Button, Flex, Heading, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiErrorCircle } from 'react-icons/bi'
import { useSwapContentDrawer } from 'src/context/CustomPlaylist/SwapContentDrawerContext'
import Content from 'src/modules/cms/domain/Content'
import { renderCorrectContentInfoByLanguage } from 'src/modules/internationalization/helpers/renderCorrectContentInfoByLanguage'
import { ContentImage } from '../../../HorizontalLearningItemCard/components/ContentImage'
import { HeaderInfo } from '../../../HorizontalLearningItemCard/components/HeaderInfo'
interface Props {
  contents: Content[]
}

const StepOne: React.FC<Props> = ({ contents }: Props) => {
  const [t] = useTranslation()
  const { swapContentDrawerInfo, setKnowMoreSwapDrawer, setContentToSwap, setSwapDrawerStep } = useSwapContentDrawer()

  return (
    <Flex flexDirection='column'>
      <Heading size='xs' fontWeight='600' marginBottom='32px'>
        {t('home.Custom Playlist.Choose a content to replace')} "{swapContentDrawerInfo!.content.title}"
      </Heading>

      <Flex flexDirection='column'>
        {contents?.length <= 0 && (
          <Flex
            px='16px'
            py='12px'
            bg='red.100'
            borderRadius='8px'
            align='center'
            justify='center'
            gap='24px'
            borderWidth='2px'
            borderColor='red.200'
          >
            <Icon as={BiErrorCircle} w='40px' h='40px' color='red.500' />
            <Text fontSize='1rem' color='gray.900' fontWeight='bold'>
              {t('noSimmilarContent', { contentName: swapContentDrawerInfo!.content.title })}
            </Text>
          </Flex>
        )}
        {contents?.map((item) => {
          const moduleTitle = swapContentDrawerInfo?.moduleTitle

          return (
            <Flex
              h='185px'
              border='2px solid #E5E5E5'
              marginBottom='16px'
              borderRadius='30px'
              flex='1'
              flexDir={{ base: 'column', lg: 'row' }}
              maxWidth='100%'
              cursor='pointer'
              onClick={() => setKnowMoreSwapDrawer(item)}
              position='relative'
              data-cy={`${item.type}_${item.id}`}
            >
              <ContentImage content={item} />
              <Flex
                flexDirection='column'
                flex='1'
                padding={{ base: '8px 16px', md: '8px 24px 8px 40px' }}
                w={{ base: '100%', lg: 'calc(100% - 297px)' }}
                justifyContent='center'
              >
                <HeaderInfo content={item} moduleName={moduleTitle} showProficiency={false} />

                <Heading
                  fontSize={{ base: '1rem', lg: '1.5rem' }}
                  textOverflow='ellipsis'
                  overflow='hidden'
                  whiteSpace='nowrap'
                >
                  {renderCorrectContentInfoByLanguage(item, 'title')}
                </Heading>

                <Flex marginTop='16px' flexDir={{ base: 'column', md: 'row' }} gap={{ base: '12px' }}>
                  <Button
                    flexGrow={{ base: '1', lg: '0' }}
                    w={{ base: 'auto' }}
                    h='48px'
                    variant='startCourse'
                    border='2px solid #E5E5E5'
                    onClick={() => setKnowMoreSwapDrawer(item)}
                  >
                    <Text fontSize={{ base: '.75rem', md: '1rem' }}>{t('playlist.Know More')}</Text>
                  </Button>

                  <Button
                    flexGrow={{ base: '1', lg: '0' }}
                    w={{ base: 'auto' }}
                    variant='startCourseDark'
                    onClick={(e) => {
                      e.stopPropagation()
                      setSwapDrawerStep(1)
                      setContentToSwap(item)
                    }}
                  >
                    <Text fontSize={{ base: '.75rem', md: '1rem' }} color='white'>
                      {t('home.Custom Playlist.Choose this content')}
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          )
        })
        }
      </Flex>
    </Flex>
  )
}

export default StepOne
