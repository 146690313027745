import { Box, Flex, Heading, Icon, Text } from '@chakra-ui/react'
import { IoChevronDown, IoChevronUp } from 'react-icons/io5'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'

interface AccordionProps {
  title: string
  rightText?: string
  isOpen?: boolean
  onOpenClick?: (isOpen: boolean) => void
  children: React.ReactNode
}

const Accordion: React.FC<AccordionProps> = ({ title, rightText, isOpen, onOpenClick, children }: AccordionProps) => {
  const { EventsImplementation, track } = useMixpanel()

  const handleClick = () => {
    if (onOpenClick) {
      onOpenClick(!isOpen)
      track(EventsImplementation.PROFILE_ACCORDION(window.location.href))
    }
  }

  return (
    <Flex flexDir='column' marginBottom='36px' w='100%'>
      <Flex justifyContent='space-between' cursor='pointer' onClick={handleClick}>
        <Heading fontSize={{ base: '1rem', lg: '1.25rem' }} marginRight={{ base: '8px', lg: '0px' }}>
          {title}
        </Heading>
        <Flex>
          <Text
            fontFamily='Poppins'
            fontSize={{ base: '1rem', lg: '1.25rem' }}
            color='#767F89'
            marginRight={{ base: '0px', lg: '16px' }}
            whiteSpace={{ base: 'nowrap', lg: 'unset' }}
          >
            {rightText}
          </Text>
          <Icon as={isOpen ? IoChevronUp : IoChevronDown} w='24px' h='24px' />
        </Flex>
      </Flex>
      {isOpen && children}
      <Box w='100%' h='1px' backgroundColor='#E5E5E5' marginTop='36px' />
    </Flex>
  )
}

export default Accordion
