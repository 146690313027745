import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleTeam } from 'src/context-admin/useHandleTeam'
import { NavigationButtons } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/NavigationButtons'
import { StepTitle } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTitle'
import { StepTransition } from 'src/pages-admin/Home/components/DrawerHandleArea/commonComponents/StepTransition'
import { User } from 'src/types/User'
import { UserList } from '../../commonComponents/UserList'

export function EditExistingTeamAddNewUsers() {
  const [t] = useTranslation()
  const { team, navigateToStep, handleSetTeamValues, usersList, getUsersList } = useHandleTeam()
  const [selectedUsers, setSelectedUsers] = useState<User[]>([])

  useEffect(() => {
    getUsersList({ variables: { areaId: 0 } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleSetTeamValues({
      newUsers: selectedUsers,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers])

  return (
    <StepTransition>
      <Flex flexDir='column'>
        <StepTitle
          title={t('addingNewMembers')}
          subtitle={t('browseAndSelectTheCollaborators', { areaName: team?.name })}
        />
        <Flex w='100%' mt='24px'>
          <UserList
            data={usersList.filter(
              (u) =>
                !team?.managers?.map((manager) => manager.id).includes(Number(u.id)) &&
                !team?.users?.map((manager) => manager.id).includes(Number(u.id)),
            )}
            type='select-users'
            onSelectUsers={setSelectedUsers}
          />
        </Flex>
        <NavigationButtons
          onContinueClick={() => navigateToStep('edit-existing-team--add-new-users-confirm')}
          onBackClick={() => navigateToStep('edit-existing-team--select-options')}
          isContinueDisabled={selectedUsers.length <= 0}
        />
      </Flex>
    </StepTransition>
  )
}
