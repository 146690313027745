import { Button, Flex, Icon } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { DownloadModelIcon } from 'src/theme/Icons'
import { User } from 'src/types/User'
import { DragArea } from '../../commonComponents/DragArea'
import { NavigationButtons } from '../../commonComponents/NavigationButtons'
import { StepTitle } from '../../commonComponents/StepTitle'
import { StepTransition } from '../../commonComponents/StepTransition'

export function EditExistingAreaRemoveUsersFile() {
  const [t] = useTranslation()
  const { navigateToStep, handleSetAreaValues, area, usersList } = useHandleArea()

  const handleLoadUsersFromCsv = (usersFromCsv: User[]) => {
    const emailListToRemove = usersFromCsv.map((u) => u.email)
    const usersListToRemove = usersList.filter((u) => emailListToRemove.includes(u.email))
    handleSetAreaValues({ newUsers: usersListToRemove })
  }

  return (
    <StepTransition>
      <Flex flexDir='column'>
        <StepTitle
          title={t('useSpreadsheetToRemoveMembersFromTheArea', { areaName: area?.name ?? t('invalidArea') })}
          subtitle={t('updateAreaMembersMoreQuickly')}
        />
        <Flex my='32px'>
          <Button
            as='a'
            href='/assets/files/remove-employees.csv'
            display='flex'
            alignItems='center'
            gap='4px'
            variant='startCourseDark'
            fontSize='1rem'
            fontWeight='600'
          >
            <Icon as={DownloadModelIcon} w='24px' h='24px' />
            {t('downloadTemplate')}
          </Button>
        </Flex>
        <DragArea type='remove-users' onLoadUsersFromCsv={handleLoadUsersFromCsv} />
        <NavigationButtons
          onContinueClick={() => navigateToStep('edit-existing-area--confirm-remove-users')}
          onBackClick={() => {
            handleSetAreaValues({ newUsers: [] })
            navigateToStep('edit-existing-area--remove-users')
          }}
          isContinueDisabled={area?.newUsers ? area?.newUsers?.length <= 0 : true}
        />
      </Flex>
    </StepTransition>
  )
}
