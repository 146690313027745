import { UseCase } from 'src/types/UseCase'
import { Team } from '../entities/Team'
import { TeamRepository } from '../repository/TeamRepository'

export type AddTeamMembersInput = {
  teamId: string
  teamMembersIds: number[]
}

export type AddTeamMembersOutput = Team

export class AddTeamMembersUseCase implements UseCase<AddTeamMembersInput, AddTeamMembersOutput> {
  constructor(private repository: TeamRepository) {}

  async execute(args: AddTeamMembersInput): Promise<AddTeamMembersOutput> {
    const team = await this.repository.addTeamMembers(args)
    return team
  }
}
