import { FileForm } from './FileForm'
import { FileFormPreview } from './FileFormPreview'
import { LabeledCheckbox } from './LabeledCheckbox'
import { SectionTitle } from './SectionTitle'
import { TextInput } from './TextInput'

/**
 * Use composition pattern to export all components from CertificateImageSelection.
 * @param SectionTitle The section title.
 * @param TextInput The text input.
 * @param FileForm The file form.
 * @param FileFormPreview The file form preview.
 * @param LabeledCheckbox The 'include this image' labeled checkbox.
 * @example <CertificateImageSelection.SectionTitle sectionTitle={text} />
 * @example <CertificateImageSelection.FileForm fileAndError={fileAndError} onFileChange={setFileAndError} inputFilelabel={text} isDisabled={boolean} />
 */
export const CertificateImageSelection = {
  SectionTitle,
  TextInput,
  FileForm,
  FileFormPreview,
  LabeledCheckbox,
}
