import { Box, HStack, Icon, IconButton, Image, Stack, Text, useToast } from '@chakra-ui/react'
import _ from 'lodash'
import { useCallback, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { IoCheckmark, IoTrash } from 'react-icons/io5'
import { User } from 'src/types/User'
const csv = require('csvtojson/v2')

const maxSize = 20

type JsonUsersFromCsv = User[]

interface DragAreaProps {
  type?: 'add-users' | 'remove-users' | 'update-area' | 'add-new-area-members'
  onLoadUsersFromCsv: (usersFromCsv: any[]) => void
}

export function DragArea({ onLoadUsersFromCsv, type = 'add-users' }: DragAreaProps) {
  const [file, setFile] = useState<File | null>(null)
  const errors = []

  const toast = useToast()
  const { t } = useTranslation()

  const onDropRemoveUsers = (acceptedFiles: any, fileRejections: FileRejection[]) => {
    /** don't go into the function if there are no
     * accepted file or if there are any rejection files
     */
    if (!acceptedFiles.length || fileRejections.length) return

    // get and read file
    const newFile = acceptedFiles[0]
    const reader = new FileReader()

    // load file
    reader.addEventListener('load', (e) => {
      let CSVData = e!.target!.result // result from the read, stringified

      csv({ headers: ['email'] })
        .fromString(CSVData)
        .then((jsonObj: JsonUsersFromCsv) => {
          const usersListWithUniqueEmail = _.uniqBy(jsonObj, 'email')
          onLoadUsersFromCsv(usersListWithUniqueEmail)
          setFile(newFile)
        })
    })

    // execute listener load
    reader.readAsText(newFile, 'utf-8')
  }

  const onDrop = (acceptedFiles: any, fileRejections: FileRejection[]) => {
    /** don't go into the function if there are no
     * accepted file or if there are any rejection files
     */
    if (!acceptedFiles.length || fileRejections.length) return

    // get and read file
    const newFile = acceptedFiles[0]
    const reader = new FileReader()

    // load file
    reader.addEventListener('load', (e) => {
      let CSVData = e!.target!.result // result from the read, stringified

      csv({ headers: type === 'add-users' ? ['companyIdentifier', 'name', 'email', 'languageCode'] : ['Email', 'Nome'] })
        .fromString(CSVData)
        .then((jsonObj: JsonUsersFromCsv) => {
          const usersListWithUniqueEmail = _.uniqBy(jsonObj, type === 'add-users' ? 'email' : 'Email')
          onLoadUsersFromCsv(usersListWithUniqueEmail)
          setFile(newFile)
        })
    })

    // execute listener load
    reader.readAsText(newFile, 'utf-8')
  }

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      if (!fileRejections) return

      fileRejections[0].errors.map((error) => {
        switch (error.code) {
          case 'file-invalid-type':
            toast({
              title: t('common.dragArea.fileErrors.fileInvalidType'),
              status: 'error',
              isClosable: true,
            })
            break
          case 'file-too-large':
            toast({
              title: t('common.dragArea.fileErrors.fileTooLarge'),
              status: 'error',
              isClosable: true,
            })
            break
          case 'too-many-files':
            toast({
              title: t('common.dragArea.fileErrors.tooManyFiles'),
              status: 'error',
              isClosable: true,
            })
            break
          default:
            toast({
              title: t('common.dragArea.fileErrors.somethingWentWrongWithYourFile'),
              isClosable: true,
            })
        }
        return null
      })
    },
    [toast, t],
  )

  const deleteFile = () => {
    setFile(null)
    onLoadUsersFromCsv([])
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: type === 'add-users' || type === 'add-new-area-members' ? onDrop : onDropRemoveUsers,
    onDropRejected,
    accept: [
      '.csv',
      'text/csv',
      'application/vnd.ms-excel',
      'application/csv',
      'text/x-csv',
      'application/x-csv',
      'text/comma-separated-values',
      'text/x-comma-separated-values',
    ],
    multiple: false,
    maxSize: Math.pow(maxSize, 7), // 20MB
  })
  return (
    <Stack
      backgroundColor='gray.50'
      border='5px dashed'
      cursor='pointer'
      h='270px'
      w='100%'
      borderColor='gray.100'
      borderRadius='15px'
      paddingY='10px'
      alignItems='center'
      spacing={4}
      opacity={isDragActive ? 0.6 : 1}
      transition='0.2s all ease'
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Image src={'/assets/images/add-employees.svg'} />
      {!isDragActive ? (
        <>
          {file && !errors.length ? (
            <Box background='white' boxShadow='0 3px 6px rgba(0,0,0,0.1)' borderRadius='8px' padding='10px 15px'>
              <HStack spacing={4}>
                <Stack spacing={0}>
                  <Text fontSize='12px' color='success'>
                    <Icon as={IoCheckmark} fontSize='lg' />
                    {t('common.dragArea.fileValidated')}
                  </Text>
                  <Text fontWeight='extrabold'>{file?.name}</Text>
                </Stack>
                <IconButton icon={<IoTrash />} aria-label='Delete File' onClick={deleteFile} />
              </HStack>
            </Box>
          ) : (
            <>
              <Text fontSize='14px' fontFamily='Mulish' fontWeight='regular'>
              {t('common.dragArea.chooseAndDragACSV')}
              </Text>
              <Text fontSize='14px' fontFamily='Mulish' fontWeight='regular'>
              {t('common.dragArea.maximunFileSize')} <strong>{maxSize}MB</strong>
              </Text>
            </>
          )}
        </>
      ) : (
        <Text>{t('onboard.employees.Drop the file')}</Text>
      )}
    </Stack>
  )
}
