import { useToast } from "@chakra-ui/react"
import { makeCreateCompany } from "../factories/makeCreateCompany"
import { makeUploadCompanyLogo } from "../factories/makeUploadCompanyLogo"

export type CompanyForm = {
  name: string
  tradeName: string
  cnpj: string
  adminEmail: string
  adminName: string
  logo: File | string
}

export const useCompany = () => {
  const toast = useToast()

  const uploadCompanyLogo = async (logo: File) => {
    const uploadCompanyLogo = makeUploadCompanyLogo()
    let companyLogoUrl: string | undefined = undefined

    if (logo) {
      try {
        const logoUrl = await uploadCompanyLogo.execute(logo)
        if (logoUrl) companyLogoUrl = logoUrl
      } catch (err: any) {
        toast({
          title: 'Erro ao enviar a logo da empresa',
          description:
            'Ocorreu um erro ao tentar salvar as informações da logo da empresa. Por favor, tente novamente!',
          status: 'error',
          duration: 6000,
        })
        throw new Error()
      }
    }

    return companyLogoUrl
  }

  const registerCompany = async (params: CompanyForm) => {
    const createCompany = makeCreateCompany()

    try {
      let uploadFileResponse = '' as string | undefined

      if (typeof params.logo !== 'string') {
        uploadFileResponse = await uploadCompanyLogo(params.logo)
      }

      await createCompany.execute({ ...params, logoUrl: uploadFileResponse })
      toast({
        title: 'Empresa criada com sucesso!',
        status: 'success',
        duration: 6000,
      })
    } catch (err) {
      toast({
        title: 'Ocorreu um erro ao criar a empresa. Tente novamente',
        status: 'error',
        duration: 6000,
      })
    }
  }

  return {
    registerCompany
  }
}
