import { Button, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface RegisternewContentProps {
  title: string
  onClick: () => void
  isAllocate?: boolean
}

export function RegisterNewContent({title, onClick, isAllocate = false}: RegisternewContentProps) {
  const [t] = useTranslation()

  return (
    <Flex
      alignItems='center'
      justify='space-between'
      w='100%'
      h='auto'
      boxShadow='0px 1px 10px rgba(0, 0, 0, 0.1)'
      padding='18px 24px'
      borderRadius='16px'
    >
      <Text color='#313E4C' fontSize='1.125rem' fontWeight={600} fontFamily='Poppins'>
        {title ?? ''}
      </Text>
      <Button
        variant='unstyled'
        bg='gray.800'
        color='white'
        fontSize='.75rem'
        padding='6px 12px'
        onClick={() => (onClick ? onClick() : null)}
        data-testid='cms-access-register-page'
      >
        {isAllocate ? t('admin.corporateUniversity.allocate') : t('admin.corporateUniversity.register')}
      </Button>
    </Flex>
  )
}
