import { IHttpClient } from 'src/services/http/http-client'
import { Company } from '../domain/Company'

interface ICompanyRepository {
  getCompanyInfo(): Promise<any>
  validatePartnerToken(partnerId: string, token: string): Promise<any>
}

export class CompanyRepository implements ICompanyRepository {
  constructor(private token: string, private httpClient: IHttpClient) {}

  async getCompanyInfo(): Promise<Company> {
    try {
      const response = await this.httpClient.request<Company>({
        url: `/v1/company`,
        method: 'GET',
        headers: {
          Authorization: this.token,
        },
      })

      return response.body
    } catch {
      throw new Error('Ocorreu um erro ao buscar as informações da empresa.')
    }
  }

  async validatePartnerToken(partnerId: string, token: string): Promise<any> {
    try {
      const response = await this.httpClient.request({
        url: `/v1/company/partner/${partnerId}/token`,
        method: 'POST',
        data: {
          token,
        },
        headers: {
          Authorization: this.token,
        },
      })

      return response.body
    } catch (err: any) {
      return {
        error: err.response.data.error.code,
      }
    }
  }
}
