import { Box, Button, Flex, Stack, TabPanel, TabPanels, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useUser } from 'src/context/userContext'
import { generateAddDescriptionLanguagesText } from '../utils'
import AddEmployees from './AddEmployees'
import { AddSingleEmployee } from './AddSingleEmployee'
import { EditSingleEmployee } from './EditSingleEmployee'
import RemoveEmployees from './RemoveEmployees'

interface Props {
  onClose: () => void
  isOpenAddEmployee: boolean
  onOpenAddEmployee: () => void
  onCloseAddEmployee: () => void
  onOpenEditEmployee: () => void
  onCloseEditEmployee: () => void
  isOpenEditEmployee: boolean
}

const TabPanelsComponent: React.FC<Props> = ({
  onClose,
  isOpenAddEmployee,
  onCloseAddEmployee,
  onOpenAddEmployee,
  onOpenEditEmployee,
  onCloseEditEmployee,
  isOpenEditEmployee,
}: Props) => {
  const [t] = useTranslation()
  const { user } = useUser()

  return (
    <TabPanels>
      <TabPanel>
        {!isOpenAddEmployee ? (
          <Stack>
            <Flex flexDirection='column'>
              <Text fontWeight={700} fontSize='1.125rem'>
                {t('manualAddition')}
              </Text>
              <Text fontFamily='Mulish' fontSize='1rem'>
                {t('selectAndFillTheInformationForEachUserToBeAdded')}
              </Text>
              <Flex mt='12px'>
                <Button
                  variant='startCourseDark'
                  height='32px'
                  py='0'
                  px='16px'
                  fontWeight='700'
                  fontSize='0.875rem'
                  fontFamily='Poppins'
                  onClick={onOpenAddEmployee}
                >
                  {t('continueWithManualAddition')}
                </Button>
              </Flex>
            </Flex>
            <Box>
              <Box pb='16px' mb='8px' borderBottomWidth={1} borderBottomColor='gray.300'></Box>
            </Box>
            <Text fontWeight={700} fontSize='1.125rem'>
              {t('bulkAddition')}
            </Text>
            <Text fontFamily='Mulish' fontWeight='semibold' fontSize='14px' paddingBottom='10px'>
              {t('importASpreadsheetWithInformationForAllUsers')}
            </Text>
            <Text fontFamily='Mulish' fontWeight='semibold' fontSize='14px' paddingBottom='10px'>
              {t('theAvailableCodesForLanguagesAre')}:<br /> {generateAddDescriptionLanguagesText(user)}
            </Text>
            <Text fontFamily='Mulish' fontWeight='semibold' fontSize='14px' paddingBottom='10px'>
              {t('theTeamsMustBeSeparatedBySemicolon')}
              <br /> {t('themTeamsMustBeSeparatedBySemicolonExample')}
            </Text>
            <AddEmployees onClose={onClose} />
          </Stack>
        ) : (
          <AddSingleEmployee onClose={onCloseAddEmployee} />
        )}
      </TabPanel>
      <TabPanel>
        {!isOpenEditEmployee ? (
          <Stack>
            <Flex flexDirection='column'>
              <Text fontWeight={700} fontSize='1.125rem'>
                {t('manualEdit')}
              </Text>
              <Text fontFamily='Mulish' fontSize='1rem'>
                {t('fillTheInfoForEditUser')}
              </Text>
              <Flex mt='12px'>
                <Button
                  variant='startCourseDark'
                  height='32px'
                  py='0'
                  px='16px'
                  fontWeight='700'
                  fontSize='0.875rem'
                  fontFamily='Poppins'
                  onClick={onOpenEditEmployee}
                >
                  {t('continueWithManualEdit')}
                </Button>
              </Flex>
            </Flex>
            <Box>
              <Box pb='16px' mb='8px' borderBottomWidth={1} borderBottomColor='gray.300'></Box>
            </Box>
            <Text fontWeight={700} fontSize='1.125rem'>
              {t('massiveEdit')}
            </Text>
            <Text fontFamily='Mulish' fontWeight='semibold' fontSize='14px' paddingBottom='10px'>
              {t('importASpreadsheetWithInformationForAllUsersToBeEdited')}
            </Text>
            <Text fontFamily='Mulish' fontWeight='semibold' fontSize='14px' paddingBottom='10px'>
              {t('theAvailableCodesForLanguagesAre')}:<br /> {generateAddDescriptionLanguagesText(user)}
            </Text>
            <Text fontFamily='Mulish' fontWeight='semibold' fontSize='14px' paddingBottom='10px'>
              {t('theTeamsMustBeSeparatedBySemicolon')}
              <br /> {t('themTeamsMustBeSeparatedBySemicolonExample')}
            </Text>
            <AddEmployees isEdit onClose={onClose} />
          </Stack>
        ) : (
          <EditSingleEmployee onClose={onCloseEditEmployee} />
        )}
      </TabPanel>
      <TabPanel>
        <Stack>
          <Text fontFamily='Mulish' fontWeight='semibold' fontSize='14px' paddingBottom='10px'>
            {t('admin.home.updateEmployees.removeDescription')}{' '}
          </Text>
          <RemoveEmployees onClose={onClose} />
        </Stack>
      </TabPanel>
    </TabPanels>
  )
}

export default TabPanelsComponent
