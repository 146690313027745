import { Button, Flex, Spinner, Text, Tooltip } from '@chakra-ui/react'
import { FormikProps } from 'formik'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCorporateUniversityStore } from 'src/pages-admin/Cms/hooks/useCorporateUniversityStore'
import { useRegisterNewContent } from '../../hooks/useRegisterNewContent'
import { useRegisterPlaylistStore } from '../../hooks/useRegisterPlaylistStore'
import { ICreatePlaylist } from '../../types/IPlaylist'
import { registerPlaylistInitialValues } from '../../utils/registerPlaylistInitialValues'

type Props = {
  form: FormikProps<ICreatePlaylist>
  isLoading: boolean
}

export function PlaylistFormActionButtons({ form, isLoading }: Props) {
  const [t] = useTranslation()
  const { selectedPlaylist, updatePlaylistPermissions } = useCorporateUniversityStore()
  const {
    updateRegisterPlaylistModalState,
    updateSavedSelectedContents,
    updateSelectedContents,
    savedSelectedContents,
  } = useRegisterPlaylistStore()
  const { updateSelectedTeamsToRestrict } = useRegisterNewContent()

  const isInvalid = useMemo(
    () => Object.keys(form.errors).length !== 0 || savedSelectedContents?.length <= 0,
    [savedSelectedContents, form.errors],
  )

  const isEditMode = useMemo(() => Object.keys(selectedPlaylist ?? {}).length > 0, [selectedPlaylist])

  const shouldDisableActionButtons = isEditMode
    ? savedSelectedContents.length === 0 || isLoading
    : isInvalid || isLoading

  const handleGoBack = useCallback(() => {
    updateRegisterPlaylistModalState(false)
    updateSavedSelectedContents([])
    updateSelectedContents([])
    updateSelectedTeamsToRestrict([])
    updatePlaylistPermissions([])
  }, [updateRegisterPlaylistModalState, updateSavedSelectedContents, updateSelectedContents, updatePlaylistPermissions, updateSelectedTeamsToRestrict])

  const handleSaveDraft = () => {
    form.setFieldValue('status', 'DRAFT')
  }

  const handlePublish = () => {
    form.setFieldValue('status', 'PUBLISHED')
  }

  useEffect(() => {
    const validate = async () => {
      await form.validateForm(registerPlaylistInitialValues(selectedPlaylist))
    }

    validate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlaylist])

  return (
    <Flex align='center' justify='space-between' pb='91px'>
      <Text
        color='#767F89'
        fontSize='1rem'
        textDecoration='underline'
        fontFamily='Poppins'
        fontWeight='600'
        cursor='pointer'
        onClick={handleGoBack}
      >
        {t('back')}
      </Text>
      <Flex gap='16px'>
        <Tooltip
          hasArrow
          placement='top'
          fontSize='12px'
          label={t('verifyTheStrictedFields')}
          isDisabled={!shouldDisableActionButtons}
          shouldWrapChildren
        >
          <Button
            type='submit'
            variant='outline'
            h='44px'
            fontSize='1rem'
            padding='8px 16px'
            isDisabled={shouldDisableActionButtons}
            onClick={handleSaveDraft}
            _hover={{ _disabled: { bg: '#3F3D561F' } }}
          >
            {isLoading ? <Spinner /> : t('saveAsDraft')}
          </Button>
        </Tooltip>
        <Tooltip
          hasArrow
          placement='top'
          fontSize='12px'
          label={t('verifyTheStrictedFields')}
          isDisabled={!shouldDisableActionButtons}
          shouldWrapChildren
        >
          <Button
            type='submit'
            variant='unstyled'
            h='44px'
            bg='gray.800'
            color='white'
            fontSize='1rem'
            padding='8px 16px'
            onClick={handlePublish}
            isDisabled={shouldDisableActionButtons}
            _hover={{ _disabled: { bg: '#3F3D561F' } }}
          >
            {isLoading ? <Spinner /> : t('publish')}
          </Button>
        </Tooltip>
      </Flex>
    </Flex>
  )
}
