import { Box, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

type Props = {
  step: number
}

const StepHeader: React.FC<Props> = ({ step }) => {
  const [t] = useTranslation()

  return (
    <Box margin='-17px 0 17px 0'>
      <Text color='gray.260' fontSize='12px'>
        {t('evaluate.part')} {step} {t('evaluate.of')} 2
      </Text>
    </Box>
  )
}

export default StepHeader
