import { Icon } from '@chakra-ui/icons'
import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text, Tooltip } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoChevronDown, IoChevronUp } from 'react-icons/io5'
import { useLocation } from 'react-router-dom'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import Partner from 'src/modules/cms/domain/Partner'
import { PartnershipIcon } from '../../theme/Icons'
import MenuPartners from './SideMenuList'

export interface PartnerLinkProps {
  href?: string
  onClick?: () => void
  active?: boolean
  isMenuOpen: boolean
  isPartnersOpen: boolean
  userPartners: Partner[] | undefined
}

const PartnerLink: React.FC<PartnerLinkProps> = ({
  href,
  onClick,
  active,
  isMenuOpen,
  isPartnersOpen,
  userPartners,
}: PartnerLinkProps) => {
  const [disabledTooltip, setDisabledTooltip] = useState(false)
  const location = useLocation()
  const { EventsImplementation, track } = useMixpanel()
  const [t] = useTranslation()

  active = active || href === location.pathname

  const disableTooltip = () => {
    setDisabledTooltip(true)

    setTimeout(() => setDisabledTooltip(false), 1500)
  }

  if (isMenuOpen)
    return (
      <Box onClick={onClick} cursor='pointer'>
        <Flex
          onClick={() => {
            track(EventsImplementation.OPEN_PARTNER_OPTIONS())
          }}
          alignItems='center'
          userSelect='none'
          position='relative'
        >
          <Box
            visibility={active ? 'visible' : 'hidden'}
            height='45px'
            marginRight='30px'
            width='7px'
            borderRadius='0px 5px 5px 0px'
            bg='primaryColors.400'
            position='absolute'
            left='0'
          ></Box>
          <Flex
            background={active ? `bluishGray.100` : 'transparent'}
            paddingRight='16px'
            marginLeft='17px'
            borderRadius='16px'
            alignItems='center'
            width='100%'
            transition='background 0.8s'
            _hover={{
              background: '#E1E3E4 radial-gradient(circle, transparent 1%, #E1E3E4 1%) center/15000%',
            }}
          >
            <Button variant='menuLink' background={active ? `bluishGray.100` : 'transparent'}>
              <Icon as={PartnershipIcon} w='20px' h='20px' color={active ? 'primaryColors.400' : 'gray.260'} />
            </Button>
            <Flex justifyContent='center' alignItems='center'>
              <Text fontFamily='Poppins'>{t('common.Partners')}</Text>
              {isPartnersOpen ? (
                <Icon as={IoChevronUp} marginLeft='57px' />
              ) : (
                <Icon as={IoChevronDown} marginLeft='57px' />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Box>
    )

  return (
    <Flex
      alignItems='center'
      onClick={() => {
        track(EventsImplementation.OPEN_PARTNER_OPTIONS())
      }}
      userSelect='none'
    >
      <Box
        visibility={active ? 'visible' : 'hidden'}
        height='45px'
        marginRight='30px'
        width='7px'
        borderRadius='0px 5px 5px 0px'
        bg='primaryColors.400'
        position='absolute'
        left='0'
      ></Box>
      <Flex flex='1' justifyContent='center'>
        <Menu>
          <Tooltip
            label={t('common.Partners')}
            placement='right'
            backgroundColor='primaryColors.400'
            padding='8px 18px'
            color='white'
            fontWeight='700'
            fontSize='14px'
            borderRadius='20px'
            isDisabled={disabledTooltip}
          >
            <MenuButton
              background={active ? `bluishGray.100` : 'transparent'}
              backgroundPosition='center'
              transition='background 0.6s'
              padding='12px'
              borderRadius='16px'
              w='45px'
              h='45px'
              color='rgba(229, 229, 229, 0.6)'
              _hover={{
                background: '#E1E3E4 radial-gradient(circle, transparent 1%, #E1E3E4 1%) center/15000%',
              }}
              focus={{
                outline: 'none',
                boxShadow: 'unset',
              }}
              _active={{
                backgroundColor: 'gray.60',
                backgroundSize: '100%',
                transition: 'background 0s',
              }}
              onClick={disableTooltip}
            >
              <Flex>
                <Icon as={PartnershipIcon} w='20px' h='20px' color={active ? 'primaryColors.400' : 'gray.260'} />
              </Flex>
            </MenuButton>
          </Tooltip>
          <MenuList
            borderRadius='15px'
            border='1px solid #EAF2F5'
            backgroundColor='gray.20'
            width='100%'
            padding='22px 35px'
            boxShadow='0px 2px 8px rgba(117, 131, 142, 0.04), 0px 16px 24px rgba(52, 60, 68, 0.12)'
            overflow='auto'
            maxHeight='300px'
            css={{
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                background: 'transparent',
                borderRadius: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'rgba(0, 0, 0, 0.4)',
                borderRadius: '10px',
              },
            }}
          >
            <MenuItem _hover={{ background: 'transparent' }} _focus={{ background: 'transparent' }} padding='unset'>
              <MenuPartners
                items={userPartners?.map((partner) => {
                  return { title: partner.name, url: `/partner/${partner.slug}/${partner.id}`, image: partner.squareLogoUrl }
                })}
              />
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  )
}

export default PartnerLink
