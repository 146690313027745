import { Td, Thead, Tr } from "@chakra-ui/react";

type Props = {
  columns: string[];
};

const TableColumns: React.FC<Props> = ({ columns }) => {
  return (
    <Thead bgColor='#F7F9FA'>
      <Tr>
        {columns.map((item) => (
          <Td
            key={item}
            fontSize="16px"
            fontWeight="semibold"
            fontFamily="Poppins"
            textAlign="left"
          >
            {item}
          </Td>
        ))}
      </Tr>
    </Thead>
  );
};

export default TableColumns;
