import { Article } from 'src/modules/cms/domain/Article'
import { Course } from 'src/modules/cms/domain/Course'
import { Video } from 'src/modules/cms/domain/Video'

export enum ContentType {
  VIDEO = 'VIDEO',
  ARTICLE = 'ARTICLE',
  COURSE = 'COURSE',
  VIDEODTO = 'VideoDto',
}

export enum LearningItemTypes {
  VIDEO = 'VIDEO',
  ARTICLE = 'ARTICLE',
  COURSE = 'COURSE',
}

export type LearningItem = Course & Video & Article
