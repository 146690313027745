import { UseCase } from 'src/types/UseCase'
import { Team } from '../entities/Team'
import { TeamRepository } from '../repository/TeamRepository'

export type SwapTeamManagerInput = {
  teamId: string
  prevManagerId: number
  newManagerId: number
}

export type SwapTeamManagerOutput = Team

export class SwapTeamManagerUseCase implements UseCase<SwapTeamManagerInput, SwapTeamManagerOutput> {
  constructor(private repository: TeamRepository) {}

  async execute(args: SwapTeamManagerInput): Promise<SwapTeamManagerOutput> {
    const team = await this.repository.swapTeamManager(args)
    return team
  }
}
