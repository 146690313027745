import { Icon, IconProps } from "@chakra-ui/react";

export const DashboardIcon = (props: IconProps) => (
  <Icon viewBox="0 0 19 19" {...props}>
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="16.8203"
        height="16.8203"
        rx="2.91406"
        stroke="white"
        strokeWidth="1.5"
      />
      <line
        x1="0.914062"
        y1="6.57812"
        x2="17.4023"
        y2="6.57812"
        stroke="white"
        strokeWidth="1.5"
      />
      <line
        x1="7.16406"
        y1="6.49609"
        x2="7.16406"
        y2="17.4883"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  </Icon>
);

export const AnalyticsIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 22" {...props}>
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.875 13.979H1.375C1.16789 13.979 1 14.1404 1 14.3396V20.83C1 21.0291 1.16789 21.1905 1.375 21.1905H2.875C3.08211 21.1905 3.25 21.0291 3.25 20.83V14.3396C3.25 14.1404 3.08211 13.979 2.875 13.979Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.375 9.65356H11.875C11.6679 9.65356 11.5 9.815 11.5 10.0141V20.8314C11.5 21.0306 11.6679 21.192 11.875 21.192H13.375C13.5821 21.192 13.75 21.0306 13.75 20.8314V10.0141C13.75 9.815 13.5821 9.65356 13.375 9.65356Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.625 4.60425H17.125C16.9179 4.60425 16.75 4.76568 16.75 4.96482V20.8302C16.75 21.0293 16.9179 21.1908 17.125 21.1908H18.625C18.8321 21.1908 19 21.0293 19 20.8302V4.96482C19 4.76568 18.8321 4.60425 18.625 4.60425Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.125 1H6.625C6.41789 1 6.25 1.16144 6.25 1.36058V20.8317C6.25 21.0309 6.41789 21.1923 6.625 21.1923H8.125C8.33211 21.1923 8.5 21.0309 8.5 20.8317V1.36058C8.5 1.16144 8.33211 1 8.125 1Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);

export const HelpIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5769 1H3.42308C2.781 1.00192 2.16574 1.27059 1.71172 1.74731C1.2577 2.22403 1.00183 2.87005 1 3.54423V12.2673C1.00183 12.9415 1.2577 13.5875 1.71172 14.0642C2.16574 14.541 2.781 14.8096 3.42308 14.8115H5.15385V18.4462L9.20904 14.896C9.27131 14.8414 9.34987 14.8115 9.43101 14.8115H16.5769C17.219 14.8096 17.8343 14.541 18.2883 14.0642C18.7423 13.5875 18.9982 12.9415 19 12.2673V3.54423C18.9982 2.87005 18.7423 2.22403 18.2883 1.74731C17.8343 1.27059 17.219 1.00192 16.5769 1V1Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);

export const MenuPartnerLeft = (props: IconProps) => (
  <Icon viewBox="0 0 16 15" {...props}>
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 13H7C4.23858 13 2 10.7614 2 8V0H0V8C0 11.866 3.13401 15 7 15H16V13Z"
        fill="#E5E5E5"
      />
    </svg>
  </Icon>
);

export const ChangeIcon = (props: IconProps) => (
  <Icon viewBox="0 0 22 18" {...props}>
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7795 11.0203L18.7792 11.02C18.5439 10.7847 18.1317 10.7847 17.8964 11.02C17.6611 11.2552 17.6611 11.6675 17.8964 11.9028L19.3393 13.3457L15.8083 13.3456C15.1171 13.3456 14.4696 13.0525 14.0313 12.5308L14.0313 12.5308L14.0303 12.5296L10.9687 8.99703L14.0088 5.46463L14.0088 5.46463L14.0095 5.46372C14.4475 4.94238 15.1166 4.64897 15.7865 4.64897H19.3175L17.8745 6.09192C17.6392 6.32719 17.6392 6.73944 17.8745 6.9747C18.0043 7.10446 18.1615 7.1567 18.3159 7.1567C18.4704 7.1567 18.6275 7.10446 18.7573 6.9747L21.2649 4.46712C21.5001 4.23193 21.5001 3.81987 21.2651 3.58456C21.265 3.58449 21.2649 3.58442 21.2649 3.58434L18.7795 1.07677L18.7792 1.07645C18.5439 0.841183 18.1317 0.841182 17.8964 1.07645C17.6611 1.31172 17.6611 1.72396 17.8964 1.95923L19.3393 3.40218H15.8083C14.7544 3.40218 13.7672 3.85087 13.0722 4.65801L10.1603 8.03761L7.22717 4.65801C7.22714 4.65798 7.22711 4.65795 7.22708 4.65791C6.53209 3.85084 5.54494 3.40218 4.49109 3.40218H1.17674C0.837984 3.40218 0.553359 3.68683 0.553359 4.02556C0.553359 4.36429 0.838002 4.64893 1.17674 4.64893H4.49125C5.1824 4.64893 5.82995 4.94198 6.26824 5.46368L6.26824 5.46369L6.26924 5.46485L9.33066 8.99745L6.29058 12.5299L6.28982 12.5308C5.85182 13.0521 5.18273 13.3455 4.51282 13.3455H1.1767C0.837945 13.3455 0.55332 13.6302 0.55332 13.9689C0.55332 14.3076 0.837963 14.5923 1.1767 14.5923H4.49121C5.5451 14.5923 6.53229 14.1436 7.22728 13.3364L10.1389 9.95731L13.0504 13.3364C13.7454 14.1436 14.7326 14.5923 15.7865 14.5923H19.3174L17.8745 16.0352C17.6392 16.2705 17.6392 16.6827 17.8745 16.918C18.0042 17.0478 18.1614 17.1 18.3159 17.1C18.4703 17.1 18.6275 17.0478 18.7573 16.918L21.2648 14.4104C21.3946 14.2807 21.4468 14.1235 21.4468 13.969C21.4468 13.8116 21.3709 13.6337 21.2651 13.5279C21.265 13.5278 21.2649 13.5277 21.2648 13.5276L18.7795 11.0203Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
    </svg>
  </Icon>
);

export const SliderChevron = (props: IconProps) => (
  <Icon viewBox='0 0 17 31' {...props}>
    <svg width='17' height='31' viewBox='0 0 17 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.07605 2L15.1105 15.5L2.07605 29'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </Icon>
)