import {
  Button,
  ButtonProps,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { EvaluateIconFilledSvg, EvaluateIconSvg } from '../../theme/Icons'

interface EvaluateIconProps extends ButtonProps {
  onClick: () => void
  variant?: string
  showText?: boolean
  showReducedText?: boolean
  isPlaylist?: boolean
  iconFilled?: boolean
  text?: string
}

const EvaluateIcon: React.FC<EvaluateIconProps> = ({
  variant = 'iconButtonClear',
  onClick,
  showText = false,
  showReducedText = false,
  isPlaylist = false,
  iconFilled = false,
  text,
  ...rest
}: EvaluateIconProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Popover trigger='hover' placement='top'>
        <PopoverContent
          borderColor='#27323D'
          backgroundColor='#27323D'
          borderRadius='20px'
          width='fit-content'
          border='none'
          zIndex='popover'
        >
          <PopoverArrow backgroundColor='#27323D' />
          <PopoverBody>
            <Text fontSize={{ base: '.875rem', md: '1rem' }} color='white' fontWeight={700}>
              {isPlaylist ? t('evaluate.evaluatePlaylist') : t('evaluate.evaluate')}
            </Text>
          </PopoverBody>
        </PopoverContent>

        <PopoverTrigger>
          <Button
            onClick={(e) => {
              e.stopPropagation()
              onClick()
            }}
            variant={variant}
            w={showText ? 'unset' : '48px'}
            paddingLeft={showText ? '14px' : 'initial'}
            paddingRight={showText ? '14px' : 'initial'}
            {...rest}
          >
            <Icon w='26px' h='26px' as={iconFilled ? EvaluateIconFilledSvg : EvaluateIconSvg} />
            {showText ? (
              <Text marginLeft='10px' fontSize={{ base: '.75rem', md: '1rem' }}>
                {showReducedText
                  ? t('evaluate.evaluate')
                  : isPlaylist
                  ? t('evaluate.evaluatePlaylist')
                  : text
                  ? text
                  : t('evaluate.evaluate')}
              </Text>
            ) : null}
          </Button>
        </PopoverTrigger>
      </Popover>
    </>
  )
}

export default EvaluateIcon
