import { Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  IconProps,
  Link as LinkChakra,
  Tooltip,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { Link, useLocation } from "react-router-dom";

export interface MenuLinkProps {
  href?: string;
  icon: IconType | ((props: IconProps) => JSX.Element);
  children?: React.ReactNode;
  onClick?: () => void;
  active?: boolean;
  isExternal?: boolean;
  isMenuOpen: boolean;
  label?: string;
}

const MenuLink: React.FC<MenuLinkProps> = ({
  href,
  icon,
  children,
  onClick,
  active,
  isExternal,
  isMenuOpen,
  label,
}: MenuLinkProps) => {
  const location = useLocation();
  active = active || href === location.pathname;

  const iconButton = (
    <Button
      variant="menuLink"
      w="46px"
      h="46px"
      borderRadius="8px"
      background={
        active && !isMenuOpen ? `rgba(255, 255, 255, 0.19)` : "transparent"
      }
      _hover={
        !isMenuOpen
          ? {
              background: "rgba(255, 255, 255, 0.19)",
            }
          : {}
      }
      _focus={{
        outline: "none",
        boxShadow: "unset",
      }}
    >
      <Icon as={icon} w="20px" h="20px" color="white" />
    </Button>
  );

  const component = (
    <Flex alignItems="center" userSelect="none" position="relative">
      <Box
        visibility={active && isMenuOpen ? "visible" : "hidden"}
        height="25px"
        marginRight="30px"
        w="5px"
        bg="white"
        ml="2px"
        position="absolute"
        left="0"
        borderRadius="12px"
      ></Box>
      <Flex
        background={
          active && isMenuOpen ? `rgba(255, 255, 255, 0.19)` : "transparent"
        }
        paddingRight="16px"
        onClick={() => {}}
        marginLeft="17px"
        borderRadius="8px"
        alignItems="center"
        width="100%"
        transition="background 0.8s"
        _hover={
          isMenuOpen
            ? {
                background: "rgba(255, 255, 255, 0.19)",
              }
            : {}
        }
      >
        {isMenuOpen ? (
          iconButton
        ) : (
          <Tooltip
            label={label}
            placement="right"
            backgroundColor="#313E4C"
            padding="8px 18px"
            color="white"
            fontWeight="700"
            fontSize="14px"
            borderRadius="20px"
          >
            {iconButton}
          </Tooltip>
        )}
        {children}
      </Flex>
    </Flex>
  );

  if (onClick)
    return (
      <Box onClick={onClick} width="100%" cursor="pointer">
        {component}
      </Box>
    );

  if (isExternal)
    return (
      <LinkChakra
        href={href || ""}
        isExternal={isExternal || false}
        textDecoration="none"
        _hover={{ textDecoration: "none" }}
        _focus={{
          outline: "none",
          boxShadow: "unset",
        }}
      >
        {component}
      </LinkChakra>
    );

  return <Link to={href || ""}>{component}</Link>;
};

export default MenuLink;
