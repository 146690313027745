import { Table, VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import TableColumns from 'src/pages-admin/Dashboard/components/TableColumns'
import { User } from 'src/types/User'
import EmployeeRow from '../../EmployeeRow'

interface Props {
  employees: User[] | undefined
}

const EmployeesTable = ({ employees }: Props) => {
  const [t] = useTranslation()

  return (
    <VStack boxShadow='0 4px 20px rgba(0,0,0,.08)' w='100%' bg='#FFFFFF' borderRadius='10px'>
      <Table>
        <TableColumns
          columns={[
            '',
            t('admin.home.employeeSection.employee'),
            t('admin.home.employeeSection.area'),
            t('admin.home.employeeSection.permission'),
            t('admin.home.employeeSection.language'),
            '',
          ]}
        />

        {employees?.map((user) => {
          return <EmployeeRow key={`${user.id}`} user={user} />
        })}
      </Table>
    </VStack>
  )
}

export default EmployeesTable
