import { getAccessToken } from "src/modules/iam/apis/store";
import httpClient from "src/services/http/http-client";
import { AreaRepository } from "../repository/AreaRepository";
import { DeleteExistingAreaArea } from "../use-case/DeleteExistingAreaUseCase";

export function makeDeleteExistingArea() {
  const token = getAccessToken()
  
  const areaRepository = new AreaRepository(token, httpClient)
  return new DeleteExistingAreaArea(areaRepository)
}