import { PlaylistRepository } from 'src/modules/cms/repositories/PlaylistRepository'
import { getAccessToken } from 'src/modules/iam/apis/store'
import { cmsClient } from 'src/services/http/cms-client'
import { ListPlaylistsPermissionsUseCase } from '../use_cases/ListPlaylistsPermissionsUseCase'

export function makeListPlaylistsPermissions() {
  const token = getAccessToken()
  const playlistRepository = new PlaylistRepository(cmsClient, token)
  return new ListPlaylistsPermissionsUseCase(playlistRepository)
}
