import { PlaylistRepository } from '../repositories/PlaylistRepository'
import { ISurvey } from '../types/ISurvey'

type GetPlaylistSurveyUseCaseInput = {
  surveyCode: string
}

export type GetPlaylistSurveyUseCaseOutput = ISurvey[]

export class GetPlaylistSurveyUseCase {
  constructor(private playlistRepository: PlaylistRepository) {}

  async execute(input: GetPlaylistSurveyUseCaseInput): Promise<GetPlaylistSurveyUseCaseOutput> {
    const survey = await this.playlistRepository.getPlaylistSurvey(input.surveyCode)
    return survey
  }
}
