import { Module } from 'src/modules/cms/domain/Playlist'
import { Module as OldModule } from 'src/types/CustomPlaylist'
import { LearningItemProgress } from 'src/types/LearningItemProgress'

export const getRecommendedLearningItemsIds = (module: OldModule) => {
  const learningItemsIds = module.learningItems.flatMap((item) => item.substitutes)
  const newArrayIds = Array.from(new Set(learningItemsIds))
  return newArrayIds
}

export const getModuleProgressPercentage = (module: Module) => {
  let total = module.contentList?.items?.length ?? 0
  let done = 0

  module.contentList?.items?.forEach((content) => {
    if(content.progress === LearningItemProgress.DONE) done += 1
  })

  return Math.round((done/total)*100)
}

export const getModuleTitle = (modules: Module[], language?: string) => {
  if (modules.length === 0) return '-'

  const moduleTitle = modules?.[0]?.title ?? '-'

  const hasSkills = (modules?.[0]?.contentList?.items?.[0]?.skills ?? [])?.length > 0

  const hasSkillsWithModuleTitle = modules?.[0]?.contentList?.items?.[0]?.skills?.some(
    (skill) => skill.name === moduleTitle
  )

  if (hasSkills && hasSkillsWithModuleTitle) {
    const skill = modules?.[0]?.contentList?.items?.[0]?.skills?.find((skill) => skill.name === moduleTitle)
    return ((language === 'es' ? skill?.translations?.[0]?.name : skill?.name)) ?? '-'
  }

  return moduleTitle
}