import { CertificateDto, CertificateTypeEnum } from "src/modules/iam/use-case/GetUserCertificatesUseCase";
import { orderBy, uniqBy, uniqueId } from 'lodash'
import NewPlaylist from "../domain/Playlist";

export function getUserCertificatesListByType(certificates: CertificateDto[]) {
  const orderedCertificates = orderBy(certificates, (cert) => cert?.createdAt, 'desc')
  const moduleCertificates = uniqBy(
    orderedCertificates.filter((item) => item.type === 'custom_playlist_module'),
    (e) => e?.moduleId,
  )
  const playlistCertificates = uniqBy(
    orderedCertificates.filter((item) => item.type !== 'custom_playlist_module'),
    (e) => e?.playlistId,
  )
  return {
    orderedCertificates,
    moduleCertificates,
    playlistCertificates,
  }
}

export function getPlaylistIdsFromCertificates(certificates: CertificateDto[]) {
  const playlistIdsFromCertificates = uniqBy(certificates?.map((item) => item?.playlistId) ?? [], (e) => e)
  return playlistIdsFromCertificates
}

const generateEmptyPlaylistTitle = (type: CertificateTypeEnum) => {
  if (type === CertificateTypeEnum.CustomPlaylistModule) return 'Trilha Personalizada'
  if (type === CertificateTypeEnum.CustomPlaylist) return 'Trilha Personalizada Concluída'
  return 'Trilha Temática Concluída'
}

const generateEmptyPlaylistModuleList = (certificate: CertificateDto) => {
  return {
    items: [
      {
        id: certificate.moduleId ?? uniqueId('module-'),
        title: 'Concluído',
        order: 1,
        contentList: {
          items: [],
          total: 0,
        },
      },
    ],
    total: 1,
  } as NewPlaylist['moduleList']
}

export function createEmptyPlaylistWithDefaultCertificate(certificate: CertificateDto) {
  const title = generateEmptyPlaylistTitle(certificate.type)
  const moduleList = generateEmptyPlaylistModuleList(certificate)
  const playlistType = CertificateTypeEnum.FeaturedPlaylist ? 'FEATURED' : 'CUSTOM'
  const imageUrl = certificate.type === CertificateTypeEnum.FeaturedPlaylist
    ? 'https://storage.googleapis.com/ej-assets/default_featured_playlist_img.png'
    : 'https://storage.googleapis.com/ej-assets/default_custom_playlist_img.png' 

  const emptyPlaylist: NewPlaylist = {
    id: uniqueId(`${certificate.type}`),
    certificate: certificate,
    title,
    description: 'Essa trilha foi removida do sistema, mas seu certificado ainda está disponível',
    imageUrl,
    cardImageUrl: imageUrl,
    moduleList,
    issuesCertificate: true,
    type: playlistType,
    status: 'ACTIVE',
  }
  return emptyPlaylist
}
