import { ComponentStyleConfig } from '@chakra-ui/theme'

const TagStyle: ComponentStyleConfig = {
  variants: {
    tag: {
      field: {
        borderRadius: '18px',
        backgroud: 'gray.65',
        padding: '5px 11px',
        marginLeft: '5px',
        textTransform: 'capitalize',
        w: 'fit-content',
        marginBottom: '6px',
        color: 'red',
      },
    },
    proficiencyLevel: {
      borderRadius: '18px',
      backgroud: 'gray.65',
      padding: '5px 11px',
      marginLeft: '5px',
      textTransform: 'capitalize',
      w: 'fit-content',
      marginBottom: '6px',
      color: 'red',
    },
  },
  defaultProps: {
    //@ts-ignore
    variant: 'tag',
  },
}

export default TagStyle
