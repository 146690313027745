import { CertificateImageFile } from '../tabs/Certificate'

export const ACCEPT_FORMATS = ['png', 'jpg', 'jpeg']
export const MAX_FILE_SIZE = 2 * 1024 * 1024 // mb -> 1 * 1024 * 1024

interface ValidateCertificateFile {
  file?: File
  callback: React.Dispatch<React.SetStateAction<CertificateImageFile>>
  maxWidth?: number
  maxHeight: number
}

export function validateCertificateFile({ file, callback, maxWidth = 150, maxHeight = 50 }: ValidateCertificateFile) {
  callback({
    file: file,
    error: undefined,
  })

  if (!file)
    return callback({
      file: undefined,
      error: { isError: true, message: `Você deve selecionar um arquivo.` },
    })

  if (file.size > MAX_FILE_SIZE)
    return callback({
      file: undefined,
      error: { isError: true, message: `O arquivo excede o tamanho máximo de ${MAX_FILE_SIZE / 1024 / 1024}mb.` },
    })

  if (!ACCEPT_FORMATS.includes(file.type.split('/')[1]))
    return callback({
      file: undefined,
      error: { isError: true, message: `O formato do arquivo deve ser compatível com: ${ACCEPT_FORMATS.join(', ')}` },
    })

  const image = new Image()
  image.src = URL.createObjectURL(file)

  image.onload = function () {
    if (image.width > (maxWidth) || image.height > (maxHeight)) {
      return callback({
        file: undefined,
        error: {
          isError: true,
          message: `A imagem excede as dimensões máximas de ${maxWidth} x ${maxHeight}px.`,
        },
      })
    }
  }
}
