import { Button, Flex, Heading } from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HorizontalLearningItemCard } from 'src/components/HorizontalLearningItemCard'
import Tabs from 'src/components/Tabs'
import { useAddContentDrawer } from 'src/context/CustomPlaylist/AddContentDrawerContext'
import Content from 'src/modules/cms/domain/Content'
import { ContentType } from 'src/modules/cms/domain/LearningItem'
import { TabInfo } from 'src/types/TabInfo'
import { getArticlesList, getCoursesList, getVideosList } from './addLearningItemsDrawer.service'

interface Props {
  contents: Content[]
  initialContentType: ContentType
  handleStep: (page: number) => void
}

const SelectLearningItems: React.FC<Props> = ({ contents, handleStep, initialContentType }) => {
  const [t] = useTranslation()
  const { setAddContentKnowMoreDrawer, addContentModuleInfo, newContents } = useAddContentDrawer()

  const [tabInfo, setTabInfo] = useState<TabInfo>({ index: 0, value: initialContentType! })

  const courses = getCoursesList(contents)
  const videos = getVideosList(contents)
  const articles = getArticlesList(contents)

  const filteredContents = useMemo(() => {
    return contents.filter((item) => item.type === tabInfo.value).slice(0, 9)
  }, [contents, tabInfo])

  const isCoursesEmpty = !courses.length
  const isVideosEmpty = !videos.length
  const isArticlesEmpty = !articles.length

  const tabs = [
    ...(!isCoursesEmpty ? [{ name: t('common.card.courses'), value: ContentType.COURSE }] : []),
    ...(!isVideosEmpty ? [{ name: t('common.card.videos'), value: ContentType.VIDEO }] : []),
    ...(!isArticlesEmpty ? [{ name: t('common.card.articles'), value: ContentType.ARTICLE }] : []),
  ]

  const handleTabClick = (value: string, index: number) => {
    if (tabInfo.index === index) return
    setTabInfo({ index, value })
  }

  const handleAddContent = () => {
    handleStep(1)
  }

  const handleKnowMoreClick = (item: Content) => {
    setAddContentKnowMoreDrawer(item)
  }

  return (
    <Flex direction='column' height='100%'>
      <Heading size='xs' fontWeight='600' marginBottom='24px' color='bluishGray.500'>
        {t('home.Custom Playlist.Select the content you want to add to your playlist')}
      </Heading>

      <Tabs tabs={tabs} tabIndex={tabInfo.index} onTabClick={handleTabClick} />

      <Flex flex={1} direction='column' mt='24px' gap='16px' maxH='calc(100% - 230px)' overflowY='auto' pr='16px'>
        {filteredContents.map((item, index) => (
          <HorizontalLearningItemCard
            key={Math.random()}
            content={item}
            showImage
            showMarkButtons={false}
            showCustomPlaylistButtons={false}
            showProficiency={false}
            moduleName={addContentModuleInfo?.title}
            showAddItemButtons
            onKnowMoreClick={handleKnowMoreClick}
            data-testid={`add-learning-item-select-learning-item-${index}`}
          />
        ))}
      </Flex>

      <Flex justify={{ base: 'center', lg: 'flex-end' }} mt='32px'>
        <Button
          variant='startCourseDark'
          height='48px'
          padding='12px 24px'
          onClick={handleAddContent}
          isDisabled={newContents.length === 0}
          flexGrow={{ base: '1', lg: '0' }}
          data-testid='select-learning-item-add-contents'
        >
          {t('home.Custom Playlist.Add content')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default SelectLearningItems
