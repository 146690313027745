import { Avatar, Badge, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Text } from '@chakra-ui/react'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { useUser } from 'src/context/userContext'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { menus } from 'src/pages/Account/utils'
import useExtension from '../../hooks/useExtension'
import ProfileMenuSkeleton from './ProfileMenuSkeleton'

type tplotOptions = {
  [key: string]: string
}

const roleColor: tplotOptions = {
  COLAB: '#2F80ED',
  ADMIN: '#313E4C',
  MANAGER: '#009639',
  BACKOFFICE: '#313E4C',
}

const ProfileMenu: React.FC = () => {
  const [t] = useTranslation()
  const { role, logout, setRole, user } = useUser()
  const { runExtension } = useExtension()
  const { EventsImplementation, track } = useMixpanel()
  const history = useHistory()

  const roleTitle: tplotOptions = {
    COLAB: t('student'),
    ADMIN: t('adminRole'),
    MANAGER: t('manager'),
    BACKOFFICE: 'Backoffice',
  }

  const getUserHigherRole = () => {
    const userRoles = user?.roles || []
    if (userRoles.includes('BACKOFFICE')) return 'BACKOFFICE'
    if (userRoles.includes('ADMIN')) return 'ADMIN'
    if (userRoles.includes('MANAGER')) return 'MANAGER'
    return 'COLAB'
  }

  const trackLogout = useCallback(() => {
    track(EventsImplementation.LOGOUT())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email])

  if (!user) return <ProfileMenuSkeleton />

  const handleLogout = () => {
    trackLogout()
    logout()
    runExtension()
  }

  const handleChangeUserView = (view: string, newView: string) => {
    track(EventsImplementation.CHANGED_USER_VIEW(view, newView))
    setRole(newView)
    if (newView === 'ADMIN' || newView === 'MANAGER') history.push('/admin/home')
    else history.push('/home')
  }

  return (
    <Menu>
      <MenuButton data-cy='profileMenuBtn'>
        <Avatar size='sm' w='48px' h='48px' name={user?.name} fontWeight='extrabold' />
      </MenuButton>

      <MenuList
        maxWidth='220px'
        borderRadius='15px'
        top='5px'
        border='1px solid #EAF2F5'
        backgroundColor='gray.20'
        width='100%'
        boxShadow='0px 2px 8px rgba(117, 131, 142, 0.04), 0px 16px 24px rgba(52, 60, 68, 0.12)'
      >
        <Text data-cy='username' fontWeight='700' mt='27px' padding='0 24px'>
          {user?.name}
        </Text>
        <Text fontSize='12px' padding='0 24px'>
          {user?.company?.name}
        </Text>
        {getUserHigherRole() === 'BACKOFFICE' ? (
          <Badge
            color='white'
            fontSize='12px'
            fontWeight='700'
            mb='6px'
            w='100%'
            textAlign='center'
            backgroundColor={'#009639'}
          >
            {roleTitle[getUserHigherRole()]}
          </Badge>
        ) : (
          <Text padding='0 24px' color={roleColor[getUserHigherRole()]} fontSize='10px' fontWeight='700' mb='16px'>
            {roleTitle[getUserHigherRole()]}
          </Text>
        )}

        {user?.roles?.indexOf('MANAGER') !== -1 && user?.roles?.indexOf('ADMIN') === -1 && role === 'COLAB' && (
          <>
            <MenuDivider marginY='0' />
            <MenuItem padding={0} onClick={() => handleChangeUserView('COLAB', 'MANAGER')}>
              <Text
                pl='24px'
                fontSize='12px'
                color='#087E45'
                fontWeight='700'
                textDecoration='underline'
                marginY='16px'
              >
                {t('admin.common.Login as a Manager')}
              </Text>
            </MenuItem>
          </>
        )}
        {user?.roles?.indexOf('ADMIN') !== -1 && role === 'COLAB' && (
          <>
            <MenuDivider marginY='0' />
            <MenuItem padding='0' onClick={() => handleChangeUserView('COLAB', 'ADMIN')}>
              <Text
                pl='24px'
                fontSize='12px'
                color='#313E4C'
                fontWeight='700'
                textDecoration='underline'
                marginY='16px'
              >
                {t('admin.common.Login as an Admin')}
              </Text>
            </MenuItem>
          </>
        )}

        {(role === 'ADMIN' || role === 'MANAGER') && (
          <>
            <MenuDivider marginY='0' />
            <MenuItem padding={0} onClick={() => handleChangeUserView(role, 'COLAB')}>
              <Text
                pl='24px'
                fontSize='12px'
                color='rgb(47, 128, 237)'
                fontWeight='700'
                textDecoration='underline'
                marginY='16px'
              >
                {t('admin.common.Login as a Student')}
              </Text>
            </MenuItem>
          </>
        )}

        <MenuDivider marginY='0' />

        {role === 'COLAB'
          ? menus(t).map((item) => (
              <React.Fragment key={item.name}>
                <MenuItem
                  color='gray.800'
                  padding='16px 24px'
                  fontWeight={400}
                  fontSize='14px'
                  as={Link}
                  to={item.path}
                  transition='background 0.8s'
                  _focus={{
                    backgroundColor: 'bluishGray.100',
                    boxShadow: 'unset',
                    textDecoration: 'none',
                  }}
                >
                  {item.name}
                </MenuItem>
                <MenuDivider marginY='0' />
              </React.Fragment>
            ))
          : null}

        <MenuItem
          data-cy='logout'
          onClick={handleLogout}
          color='gray.800'
          padding='16px 24px'
          fontWeight={400}
          fontSize='14px'
          transition='background 0.8s'
          _focus={{
            backgroundColor: 'bluishGray.100',
            boxShadow: 'unset',
          }}
        >
          {t('common.Logout')}
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default ProfileMenu
