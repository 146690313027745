import { Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useCorporateUniversityStore } from '../../hooks/useCorporateUniversityStore'

interface Props {
  openDrawer?: () => void
}

export function CmsSkillTable({ openDrawer }: Props) {
  const { cmsSkills } = useCorporateUniversityStore()
  const [t] = useTranslation()

  return (
    <TableContainer
      d='flex'
      position='relative'
      w='100%'
      borderRadius='16px'
      boxShadow='0px 1px 10px rgba(0, 0, 0, 0.1)'
      data-testid='cms-table-wrapper'
    >
      <Table variant='unstyled' size='lg'>
        <Thead bg='#F7F9FA'>
          <Tr padding='16px 34px' w='100%'>
            <Th w='30%' fontWeight={700} textTransform='none' fontSize='1.125rem'>
              {t('admin.corporateUniversity.skills.tableSkillTitle')}
            </Th>
            {/* <Th fontWeight={700} textTransform='none' fontSize='1.125rem'>
              {t('admin.corporateUniversity.skills.tableSkillAllocateTitle')}
            </Th>
            <Th fontWeight={700} textTransform='none' fontSize='1.125rem'>
              {t('admin.corporateUniversity.skills.tableAllocationDateTitle')}
            </Th> */}
            <Th fontWeight={700} textTransform='none' fontSize='1.125rem'>
              {t('admin.corporateUniversity.skills.tableUpdatedAtTitle')}
            </Th>
            <Th fontWeight={700} textTransform='none' fontSize='1.125rem'>
              {t('admin.corporateUniversity.skills.tableCreatedAtTitle')}
            </Th>
            <Th w='80px' />
          </Tr>
        </Thead>

        <Tbody>
          {!cmsSkills ||
            (cmsSkills.length <= 0 && (
              <Flex padding='20px'>
                <Text color='bluishGray.500'>{t('noSkillsFound')}.</Text>
              </Flex>
            ))}

          {cmsSkills?.length > 0 &&
            cmsSkills?.map((item, index) => (
              <Tr
                key={item.id}
                w='100%'
                borderBottom='1px solid #EFEFEF'
                color='bluishGray.500'
                _hover={{ backgroundColor: 'gray.50' }}
                transition='all 300ms ease'
              >
                <Td maxW='200px' overflowX='hidden' textOverflow='ellipsis'>
                  {item.name}
                </Td>
                <Td>{new Date(Number(item.updatedAt)).toLocaleDateString('pt-BR')}</Td>
                <Td>{new Date(Number(item.createdAt)).toLocaleDateString('pt-BR')}</Td>
                {/* <Td>
                  <Flex align='center' gap='24px' position='relative'>
                    <Tooltip
                      hasArrow
                      shouldWrapChildren
                      bg='gray.800'
                      color='gray.50'
                      p='8px'
                      placement='top'
                      borderRadius='4px'
                      boxShadow='0px 4px 6px rgba(0, 0, 0, 0.15);'
                      label={t('admin.corporateUniversity.tableEditButton')}
                    >
                      <EditContent
                        width='24px'
                        height='24px'
                        cursor='pointer'
                        // onClick={() => handleEditSkill(skill)} // criar function para editar skill
                        data-testid={`cms-edit-skill-button-${index}`}
                      />
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      shouldWrapChildren
                      bg='gray.800'
                      color='gray.50'
                      p='8px'
                      placement='top'
                      borderRadius='4px'
                      boxShadow='0px 4px 6px rgba(0, 0, 0, 0.15);'
                      label={t('admin.corporateUniversity.tableDeleteButton')}
                    >
                      <DeleteContent
                        width='24px'
                        height='24px'
                        // onClick={() => handleRemoveSkill(playlist)} // criar function para excluir skill
                        cursor='pointer'
                        data-testid={`cms-remove-skill-button-${index}`}
                      />
                    </Tooltip>
                  </Flex>
                </Td> */}
              </Tr>
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
