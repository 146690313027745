import { IHttpClient } from 'src/services/http/http-client'
import { Company } from '../domain/Company'
import { UpdateCertificateUseCaseInput } from '../use_cases/UpdateCompanyCertificateUseCase'

export interface CertificateUploadResponse {
  signatureUrl: string
  signatureResponsible: string
  logoUrl: string
}

interface ICertificateRepository {
  updateCertificate(params: UpdateCertificateUseCaseInput): Promise<Company>
  uploadSignature(image: File, responsible: string): Promise<CertificateUploadResponse | undefined>
  uploadLogo(image: File): Promise<CertificateUploadResponse | undefined>
}

export class CertificateRepository implements ICertificateRepository {
  constructor(private token: string, private httpClient: IHttpClient) {}

  async updateCertificate(params: UpdateCertificateUseCaseInput): Promise<Company> {
    const {
      customPlaylistCertificateEnabled,
      featuredPlaylistCertificateEnabled,
      playlistModuleCertificateEnabled,
      shouldIncludeSignature,
      shouldIncludeLogo,
    } = params

    try {
      const response = await this.httpClient.request<Company>({
        url: `/v1/company/certificate/config`,
        method: 'PATCH',
        data: {
          customPlaylistCertificateEnabled,
          featuredPlaylistCertificateEnabled,
          playlistModuleCertificateEnabled,
          shouldIncludeSignature,
          shouldIncludeLogo,
        },
        headers: {
          Authorization: this.token,
        },
      })
      return response.body
    } catch (err) {
      throw new Error('Ocorreu um erro ao atualizar o certificado.')
    }
  }

  async uploadSignature(image: File, responsible: string): Promise<CertificateUploadResponse | undefined> {
    try {
      if (!image) throw new Error('A imagem da assinatura é obrigatória')
      if (!responsible) throw new Error('O nome do responsável pela assinatura é obrigatório.')

      const formData = new FormData()
      formData.append('file', image)
      formData.append('responsible', responsible)

      const response = await this.httpClient.request<CertificateUploadResponse>({
        url: `/v1/company/certificate/signature`,
        method: 'PUT',
        data: formData,
        headers: {
          Authorization: this.token,
        },
      })
      return response.body
    } catch (err) {
      throw new Error('Ocorreu um erro ao enviar a assinatura para o certificado.')
    }
  }

  async uploadLogo(image: File): Promise<CertificateUploadResponse | undefined> {
    try {
      if (!image) throw new Error('A logo da empresa é obrigatória.')

      const formData = new FormData()
      formData.append('file', image)

      const response = await this.httpClient.request<CertificateUploadResponse>({
        url: `/v1/company/certificate/logo`,
        method: 'PUT',
        data: formData,
        headers: {
          Authorization: this.token,
        },
      })
      return response.body
    } catch (err) {
      throw new Error('Ocorreu um erro ao enviar a assinatura para o certificado.')
    }
  }
}
