import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useCertificate } from '../../hooks/useCertificate'
import { SectionTitle } from '../CertificateImageSelection/SectionTitle'
import { CertificateType } from './CertificateType'

export function CertificateTypeSection() {
  const [t] = useTranslation()
  const { shouldIncludeTheseCertificateTypes } = useCertificate()

  return (
    <Flex flexDir='column' gap='4px'>
      <SectionTitle sectionTitle={t('certificateEmission')} />

      <Text fontSize='1.125rem' fontWeight='600' fontFamily='Mulish'>
        {t('selectWhichMomentoWillBePossibleToEmitCertificates')}.
      </Text>

      <Flex flexDir='column' gap='16px' bgColor='#F7F9FA' p='24px' borderRadius='16px' mt='16px'>
        <CertificateType
          label={t('customPlaylistConclusion')}
          type='customPlaylist'
          isChecked={shouldIncludeTheseCertificateTypes.customPlaylist}
        />
        <CertificateType
          label={t('customPlaylistModuleConclusion')}
          type='customPlaylistModule'
          isChecked={shouldIncludeTheseCertificateTypes.customPlaylistModule}
        />
        <CertificateType
          label={t('featuredPlaylistConclusion')}
          type='featuredPlaylist'
          isChecked={shouldIncludeTheseCertificateTypes.featuredPlaylist}
        />
      </Flex>
    </Flex>
  )
}
