import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { useUser } from 'src/context/userContext'
import { DeleteCustomPlaylistModalTrigger } from './Trigger'

interface DeleteCustomPlaylistModalProps {
  isOpen: boolean
  onClose: () => void
  withTrigger?: boolean
  onOpen?: () => void
  label?: string
}

export function DeleteCustomPlaylistModal({
  isOpen,
  onClose,
  withTrigger = false,
  onOpen = () => null,
  label = 'clicando aqui',
}: DeleteCustomPlaylistModalProps) {
  const [t] = useTranslation()
  const [loadingRemovePlaylist, setLoadingRemovePlaylist] = useState(false)
  const [isConfirmTextCorrect, setIsConfirmTextCorrect] = useState(false)

  const { deleteCustomPlaylist, updateQuiz } = useCustomPlaylistQuiz()
  const { user, getUser } = useUser()

  const toast = useToast()

  const handleConfirmRemovePlaylist = async () => {
    if (!user?.customPlaylistId) return
    setLoadingRemovePlaylist(true)
    const wasDeleted = await deleteCustomPlaylist()
    if (wasDeleted.success) {
      await getUser()
      toast({
        title: `${t('playlistRemoveSuccessfully')} 🎉`,
        description: t('yourCustomPlaylistWasRemovedSuccessfully'),
        status: 'success',
        duration: 8000,
        isClosable: true,
      })
      updateQuiz('selectedSkills', [])
      updateQuiz('skills', [])
      updateQuiz('skillsSnapshot', [])
      onClose()
    } else {
      toast({
        title: `${t('errorWhileDeletingYourCustomPlaylist')} 😢`,
        description: t('anErrorOccurredWhileDeletingYourCustomPlaylist'),
        status: 'error',
        duration: 8000,
        isClosable: true,
      })
    }
    setLoadingRemovePlaylist(false)
  }

  return (
    <>
      {withTrigger && <DeleteCustomPlaylistModalTrigger onOpen={onOpen} label={label} />}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg='rgba(0, 0, 0, 0.7)' />
        <ModalContent>
          <ModalHeader>{t('playlistDeletion')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              {t('byConfirmingTheDeletionOfYourCustomPlaylist')}{' '}
              <strong style={{ textTransform: 'uppercase' }}>{t('allYourProgress')}</strong>{' '}
              {t('allYourProgressWillBeLostForever')}.
            </Text>

            <Flex mt='32px'>
              <FormControl>
                <FormLabel>{t('toConfirmThisActionTypeTheFollowingPhrase')}:</FormLabel>
                <FormLabel fontWeight='700'>{t('iWantToDeleteMyCustomPlaylist')}</FormLabel>
                <Input
                  onChange={(e) => {
                    const inputText = e.target.value.toLowerCase().trim()
                    const textToVerify = String(t('iWantToDeleteMyCustomPlaylist')).toLowerCase().trim()
                    if (inputText !== textToVerify && isConfirmTextCorrect) {
                      setIsConfirmTextCorrect(false)
                    }

                    if (inputText === textToVerify) {
                      setIsConfirmTextCorrect(true)
                    }
                  }}
                />
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button variant='startCourseDark' mr={3} onClick={onClose} isDisabled={loadingRemovePlaylist}>
              {t('cancel')}
            </Button>
            <Button
              display='flex'
              alignItems='center'
              justifyContent='center'
              variant='unstyled'
              h='48px'
              px='16px !important'
              _hover={{ color: 'red.700', bg: 'red.100' }}
              onClick={handleConfirmRemovePlaylist}
              isLoading={loadingRemovePlaylist}
              isDisabled={loadingRemovePlaylist || !isConfirmTextCorrect}
            >
              {t('confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
