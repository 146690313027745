import { getAccessToken } from "src/modules/iam/apis/store";
import httpClient from "src/services/http/http-client";
import { AreaRepository } from "../repository/AreaRepository";
import { AddNewUsersToArea } from "../use-case/AddNewUsersToAreaUseCase";

export function makeAddNewUsersToArea() {
  const token = getAccessToken()

  const areaRepository = new AreaRepository(token, httpClient)
  return new AddNewUsersToArea(areaRepository)
}