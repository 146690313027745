import { UseCase } from "src/types/UseCase";
import { AreaRepository, ChangeManagerFromExistingAreaInput } from "../repository/AreaRepository";

type ChangeManagerFromExistingAreaOutput = boolean

export class ChangeManagerFromExistingArea implements UseCase<ChangeManagerFromExistingAreaInput, ChangeManagerFromExistingAreaOutput>{
  constructor(private areaRepository: AreaRepository){}

  async execute(args: ChangeManagerFromExistingAreaInput): Promise<ChangeManagerFromExistingAreaOutput> {
      const wasChanged = await this.areaRepository.changeManagerFromExistingArea({ 
        areaId: args?.areaId,
        newManagerId: args?.newManagerId
      })
      return wasChanged
    }
}