import { useMutation, useQuery } from '@apollo/client'
import { createContext, useCallback, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import QuizModal from 'src/components/QuizModal'
import SAVE_QUIZ_RESULTS from 'src/graphql/mutations/SAVE_QUIZ_RESULTS'
import GET_USER_QUIZ from 'src/graphql/queries/GET_USER_QUIZ'
import { QuizResults } from 'src/types/QuizResults'

interface QuizState {
  step: number
  quizConcluded: boolean
}

interface Value {
  shouldBeVisible: boolean
  shouldDisplayHighlight: boolean
  trigger: () => void
  openModal: () => void
  onConcluded: () => void
  endRecomendationHighLight: () => void
  onHalfConcluded: (step: number) => void
  quizState: QuizState
  quizResults: QuizResults
  updateQuiz: (question: string, value: any) => void
  updateAndSendMutationQuiz: (question: string, value: any) => void
  updateStep: (step: number) => void
  sendMutationQuiz: () => void
  refetchHome: () => void
  refetchedHome: boolean
  isOnboarding: boolean
  toggleOnboarding: () => void
  updateQuizSource: (source: string) => void
}

interface ProviderProps {
  children: React.ReactNode[] | React.ReactNode
}

interface ReturnGetUserQuiz {
  GetUserQuiz: QuizResults
}

const QuizContext = createContext({} as Value)

export function QuizProvider({ children }: ProviderProps) {
  const [shouldBeVisible, setShouldBeVisible] = useState(false)
  const [shouldDisplayHighlight, setShouldDisplayHighlight] = useState(false)
  const [quizSource, setQuizSource] = useState('')
  const [quizState, setQuizState] = useState<QuizState>({ step: 0, quizConcluded: false })
  const [refetchedHome, setRefetchHome] = useState(false)
  const [saveQuizResults] = useMutation(SAVE_QUIZ_RESULTS)

  const [quizResults, setQuizResults] = useState<QuizResults>({ languages: ['pt-BR'] })
  const [isOnboarding, setIsOnboarding] = useState<boolean>(false)

  useQuery<ReturnGetUserQuiz>(GET_USER_QUIZ, {
    onCompleted: (data: ReturnGetUserQuiz) => {
      if (data.GetUserQuiz) {
        const GetUserQuiz = { ...data.GetUserQuiz }

        if (GetUserQuiz.skillsStartingPoint) {
          if (GetUserQuiz.skillsStartingPoint[0] === '') delete GetUserQuiz.skillsStartingPoint
        }

        if (GetUserQuiz.skillsEndingPoint) {
          if (GetUserQuiz.skillsEndingPoint[0] === '') delete GetUserQuiz.skillsEndingPoint
        }

        setQuizResults({ ...GetUserQuiz, deadline: undefined })

        if (GetUserQuiz.careerStage) setQuizState({ step: 2, quizConcluded: false })
        if (GetUserQuiz.competences) setQuizState({ step: 3, quizConcluded: false })
        if (GetUserQuiz.themes) setQuizState({ step: 4, quizConcluded: false })
        if (GetUserQuiz.themes && !GetUserQuiz.skillsStartingPoint) setQuizState({ step: 4, quizConcluded: false })
        if (GetUserQuiz.skillsStartingPoint) setQuizState({ step: 6, quizConcluded: false })
        if (GetUserQuiz.skillsEndingPoint && !GetUserQuiz.deadline) setQuizState({ step: 6, quizConcluded: false })
        if (GetUserQuiz.dedicationTime) setQuizState({ step: 0, quizConcluded: true })
      }
    },
  })

  const history = useHistory()

  function trigger() {
    quizSource !== 'account' && history.push('/home')
    openModal()
  }

  function openModal() {
    setShouldBeVisible(!shouldBeVisible)
  }

  function onConcluded() {
    refetchHome()
    setQuizState({ ...quizState, quizConcluded: true })
  }

  function onHalfConcluded(step: number) {
    if (step > 2) refetchHome()
  }

  function endRecomendationHighLight() {
    setShouldDisplayHighlight(false)
  }

  function toggleOnboarding() {
    setIsOnboarding(!isOnboarding)
  }

  const updateQuizSource = useCallback((source: string) => {
    setQuizSource(source)
  }, [])

  const updateQuiz = useCallback((question: string, value: any) => {
    setQuizResults((prevState: any) => ({ ...prevState, [question]: value }))
  }, [])

  const updateAndSendMutationQuiz = useCallback((question: string, value: any) => {
    const newQuizResults = { ...quizResults, [question]: value }
    updateQuiz(question, value)
    sendMutationQuiz(newQuizResults)
    //eslint-disable-next-line
  }, [])

  const sendMutationQuiz = (newQuizResults?: QuizResults) => {
    const mutationQuizResults = newQuizResults || { ...quizResults }

    Object.keys(mutationQuizResults).forEach((key: string) => {
      // @ts-ignore
      if (mutationQuizResults[key] === null) delete mutationQuizResults[key]
    })

    saveQuizResults({ variables: { ...mutationQuizResults } })
    updateQuiz('updatedAt', Date.now().toString())
  }

  const refetchHome = () => {
    setRefetchHome(true)
    setShouldDisplayHighlight(true)
  }

  const updateStep = (step: number) => {
    setQuizState({ ...quizState, step })
  }

  return (
    <QuizContext.Provider
      value={{
        trigger,
        openModal,
        shouldBeVisible,
        onConcluded,
        shouldDisplayHighlight,
        onHalfConcluded,
        quizState,
        endRecomendationHighLight,
        quizResults,
        updateQuiz,
        sendMutationQuiz,
        updateStep,
        refetchHome,
        refetchedHome,
        isOnboarding,
        toggleOnboarding,
        updateAndSendMutationQuiz,
        updateQuizSource,
      }}
    >
      {children}
      {shouldBeVisible && <QuizModal onClose={trigger} isOpen={shouldBeVisible} isOnboarding={isOnboarding} />}
    </QuizContext.Provider>
  )
}

export const useQuiz = () => useContext(QuizContext)
