/* import "../../../i18n"; */
import { useMutation } from '@apollo/client'
import { Icon } from '@chakra-ui/icons'
import { Image } from '@chakra-ui/image'
import { Box, Flex, HStack, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/layout'
import { Alert, Button, IconButton, useToast } from '@chakra-ui/react'
import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { IoCheckmark, IoDownloadOutline, IoTrash } from 'react-icons/io5'
import { useHomeAccess } from 'src/context-admin/Home/AdminHomeAccessContext'
import REMOVE_EMPLOYEES from 'src/graphql/mutations/REMOVE_EMPLOYEES'
import { useValidateRemoveEmployees } from 'src/pages-admin/Home/utils'
const csv = require('csvtojson/v2')

const ERRORS_SHOWN = 20

export interface Employee {
  email: string
  companyIdentifier: string
}

interface Props {
  onClose: () => void
}

const RemoveEmployees: React.FC<Props> = ({ onClose }: Props) => {
  const [t] = useTranslation()
  const { fetchUsers, first } = useHomeAccess()
  const toast = useToast()
  const [listEmployees, setListEmployees] = useState<Employee[]>([])
  const [file, setFile] = useState<File | null>(null)
  const errors = useValidateRemoveEmployees(listEmployees)
  const [submitList, { loading }] = useMutation(REMOVE_EMPLOYEES, {
    onCompleted() {
      fetchUsers({ limit: first })
      onClose()
      toast({
        title: t('onboard.employees.Employees successfully removed!'),
        status: 'success',
        isClosable: true,
      })
    },
    onError() {
      toast({
        title: t('common.dragArea.fileErrors.somethingWentWrong'),
        status: 'error',
        isClosable: true,
      })
    },
  })

  /** Success */
  const onDrop = useCallback(
    (acceptedFiles, fileRejections: FileRejection[]) => {
      /** don't go into the function if there are no
       * accepted file or if there are any rejection files
       */
      if (!acceptedFiles.length || fileRejections.length) return

      // get and read file
      const file = acceptedFiles[0]
      const reader = new FileReader()

      // load file
      reader.addEventListener('load', (e) => {
        let CSVData = e!.target!.result // result from the read, stringified

        csv({ headers: ['email'] })
          .fromString(CSVData)
          .then((jsonObj: Employee[]) => {
            const employeesListWithUniqueEmails = _.uniqBy(jsonObj, 'email')
            setListEmployees(employeesListWithUniqueEmails as Employee[])
            setFile(file)
          })
      })

      // execute listener load
      reader.readAsBinaryString(file)
    },
    [], //eslint-disable-line
  )

  /** Error */
  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      if (!fileRejections) return

      fileRejections[0].errors.map((error) => {
        switch (error.code) {
          case 'file-invalid-type':
            toast({
              title: t('common.dragArea.fileErrors.fileInvalidType'),
              status: 'error',
              isClosable: true,
            })
            break
          case 'file-too-large':
            toast({
              title: t('common.dragArea.fileErrors.fileTooLarge'),
              status: 'error',
              isClosable: true,
            })
            break
          case 'too-many-files':
            toast({
              title: t('common.dragArea.fileErrors.tooManyFiles'),
              status: 'error',
              isClosable: true,
            })
            break
          default:
            toast({
              title: t('common.dragArea.fileErrors.somethingWentWrongWithYourFile'),
              status: 'error',
              isClosable: true,
            })
        }
        return null
      })
    },
    [toast, t],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: [
      '.csv',
      'text/csv',
      'application/vnd.ms-excel',
      'application/csv',
      'text/x-csv',
      'application/x-csv',
      'text/comma-separated-values',
      'text/x-comma-separated-values',
    ],
    multiple: false,
    maxSize: Math.pow(10, 7), // 10MB
  })

  const deleteFile = () => {
    setFile(null)
    setListEmployees([])
  }

  const submitEmployees = async () => {
    if (!listEmployees.length) return

    submitList({
      variables: {
        users: listEmployees,
      },
    })
  }

  return (
    <Stack spacing={4}>
      {/** Download CSV Template */}
      <Flex justifyContent='center'>
        <Button
          as='a'
          leftIcon={<IoDownloadOutline />}
          bg='tertiaryColor'
          colorScheme='tertiaryColors'
          color='white'
          fontWeight='black'
          fontFamily='Poppins'
          size='sm'
          href='/assets/files/remove-employees.csv'
          marginBottom='10px'
          download
        >
          {t('donwloadModel')}
        </Button>
      </Flex>
      {/** Drag Zone */}
      <Stack
        backgroundColor='gray.50'
        border='5px dashed'
        h='270px'
        cursor='pointer'
        w='100%'
        borderColor='gray.100'
        borderRadius='15px'
        paddingY='10px'
        alignItems='center'
        spacing={4}
        opacity={isDragActive ? 0.6 : 1}
        transition='0.2s all ease'
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Image src={'/assets/images/add-employees.svg'} />
        {!isDragActive ? (
          <>
            {file && !errors.length ? (
              <Box background='white' boxShadow='0 3px 6px rgba(0,0,0,0.1)' borderRadius='8px' padding='10px 15px'>
                <HStack spacing={4}>
                  <Stack spacing={0}>
                    <Text fontSize='12px' color='success'>
                      <Icon as={IoCheckmark} fontSize='lg' />
                      {t('common.dragArea.fileValidated')}
                    </Text>
                    <Text fontWeight='extrabold'>{file?.name}</Text>
                  </Stack>
                  <IconButton icon={<IoTrash />} aria-label='Delete File' onClick={deleteFile} />
                </HStack>
              </Box>
            ) : (
              <>
                <Text fontSize='14px' fontFamily='Mulish' fontWeight='regular'>
                  {t('common.dragArea.chooseAndDragACSV')}
                </Text>
                <Text fontSize='14px' fontFamily='Mulish' fontWeight='regular'>
                  {t('common.dragArea.maximunFileSize')} <strong>10MB</strong>
                </Text>
              </>
            )}
          </>
        ) : (
          <Text>{t('onboard.employees.Drop the file')}</Text>
        )}
      </Stack>

      {/** Erros in file */}
      {errors.length && (
        <Alert status='error'>
          <UnorderedList>
            {errors.map((error, index) => {
              if (index <= ERRORS_SHOWN - 1) return <ListItem fontSize='sm'>{error}</ListItem>
              return undefined
            })}
            {errors.length > ERRORS_SHOWN && (
              <ListItem fontSize='sm'>
                {t('onboard.employees.More errors', {
                  nErrorsShown: ERRORS_SHOWN,
                  nErrorsHidden: errors.length - ERRORS_SHOWN,
                })}
              </ListItem>
            )}
          </UnorderedList>
        </Alert>
      )}

      <HStack justifyContent='flex-end'>
        <Button onClick={onClose} variant='ghost'>
          {t('admin.home.updateEmployees.cancel')}
        </Button>
        <Button
          bg='#009028'
          fontSize='16px'
          fontFamily='Poppins'
          fontWeight='bold'
          colorScheme='primaryColors'
          disabled={!!errors.length || !listEmployees.length}
          size='lg'
          isLoading={loading}
          onClick={() => {
            submitEmployees()
          }}
        >
          {t('admin.home.updateEmployees.remove')}
        </Button>
      </HStack>
    </Stack>
  )
}

export default RemoveEmployees
