import { Flex, Img, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleArea } from 'src/context-admin/useHandleArea'
import { StepTransition } from '../../commonComponents/StepTransition'

const TIME_TO_CLOSE = 5

export function SuccessStep() {
  const [t] = useTranslation()
  const [timer, setTimer] = useState(TIME_TO_CLOSE)
  const { onCloseHandleAreaDrawer, goToFirstStep, finalMessage } = useHandleArea()

  useEffect(() => {
    let count = TIME_TO_CLOSE
    const interval = setInterval(() => {
      if (count <= 1) {
        clearInterval(interval)
        onCloseHandleAreaDrawer()
        goToFirstStep()
      } else {
        count -= 1
        setTimer(count)
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StepTransition>
      <Flex flexDir='column' align='center' justify='center' gap='48px' pt='48px'>
        <Img src='/assets/images/ballon.png' alt='Success ballon image' />
        <Text fontWeight='600' fontSize='1.25rem' letterSpacing='-0.6px' fontFamily='Poppins' textAlign='center'>
          {finalMessage ?? ''}
        </Text>
      </Flex>
      <Text textAlign='center' fontSize='0.75rem' color='bluishGray.300' mt='24px'>
        {t('thisScreenWillAutomaticallyCloseIn')} {timer} {timer <= 1 ? 'segundo' : 'segundos'}...
      </Text>
    </StepTransition>
  )
}
