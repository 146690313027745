import { Button, Flex, Icon, useBoolean, useOutsideClick } from '@chakra-ui/react'
import React from 'react'
import { IoLogoLinkedin, IoLogoTwitter, IoLogoWhatsapp, IoShareSocialOutline } from 'react-icons/io5'
import { useLocation } from 'react-router-dom'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import useRegisterUserEvent from 'src/hooks/useRegisterUserEvent'
import { Course } from 'src/modules/cms/domain/Course'
import NewPlaylist from 'src/modules/cms/domain/Playlist'

interface Props {
  playlist?: NewPlaylist
  course?: Course
  iconColor?: string
  borderColor?: string
  borderHover?: string
  backgroundColor?: string
}

const ShareButton: React.FC<Props> = ({
  playlist,
  course,
  borderColor,
  borderHover,
  iconColor,
  backgroundColor,
}: Props) => {
  const [toggle, setToggle] = useBoolean()
  const ref = React.useRef(null)
  const location = useLocation()
  const registerUserEvent = useRegisterUserEvent()
  const { EventsImplementation, track } = useMixpanel()

  useOutsideClick({
    ref,
    handler: setToggle.off,
  })
  const componentTheme = {
    background: {
      light: 'white',
      dark: 'gray.800',
    },
    borderColor: { light: 'gray.100', dark: 'gray.300' },
    borderHover: { light: 'gray.300', dark: 'white' },
    iconColor: { light: 'gray.800', dark: 'gray.100' },
  }

  return (
    <Flex ref={ref} flexDirection='column' position='relative' bottom='0px' height='50px' alignItems='center'>
      <Flex
        justify='space-around'
        width={toggle ? '180px' : '0px'}
        height={toggle ? '50px' : '0px'}
        marginLeft='10px'
        borderRadius='20px'
        position='absolute'
        transition='all 0.2s linear'
        top={toggle ? '-55px' : '0px'}
        alignItems='center'
      >
        <Flex
          _hover={{
            borderColor: borderHover ? borderHover : 'gray.300',
          }}
          bg={backgroundColor ? backgroundColor : 'white'}
          cursor='pointer'
          color={borderColor ? borderColor : 'gray.100'}
          border={toggle ? '2px solid' : '0px'}
          padding={toggle ? 2.5 : 0}
          borderRadius='15px'
          onClick={() => {
            track(EventsImplementation.SHARE('Linkedin', location.pathname, 'Playlist Page', course?.__typename!))
            window.open(
              'https://www.linkedin.com/shareArticle?url=' +
                encodeURIComponent('https://app.education-journey.com/home'),
              '_blank',
            )
          }}
        >
          <Icon
            w={toggle ? '24px' : '0px'}
            h={toggle ? '24px' : '0px'}
            transition='all 0.2s linear'
            as={IoLogoLinkedin}
            color={iconColor ? iconColor : 'gray.800'}
          />
        </Flex>
        <Flex
          _hover={{
            borderColor: borderHover ? borderHover : 'gray.300',
          }}
          bg={backgroundColor ? backgroundColor : 'white'}
          cursor='pointer'
          color={borderColor ? borderColor : 'gray.100'}
          border={toggle ? '2px solid' : '0px'}
          padding={toggle ? 2.5 : 0}
          borderRadius='15px'
          onClick={() => {
            track(EventsImplementation.SHARE('Twitter', location.pathname, 'Playlist Page', course?.__typename!))

            if (course) {
              if (course.attributes) registerUserEvent('share-course', course.attributes)
              window.open(
                'https://twitter.com/intent/tweet?text=' +
                  encodeURIComponent(
                    `O futuro me espera! Estou estudando "${course.title}" pela Education Journey https://app.education-journey.com/home #lifelonglearning`,
                  ),
                '_blank',
              )
            } else if (playlist) {
              window.open(
                'https://twitter.com/intent/tweet?text=' +
                  encodeURIComponent(
                    `O futuro me espera! Estou estudando "${playlist.title}" pela Education Journey https://app.education-journey.com/home #lifelonglearning`,
                  ),
                '_blank',
              )
            } else {
              window.open(
                'https://twitter.com/intent/tweet?text=' +
                  encodeURIComponent(
                    `O futuro me espera! Estou estudando pela Education Journey https://app.education-journey.com/home #lifelonglearning`,
                  ),
                '_blank',
              )
            }
          }}
        >
          <Icon
            w={toggle ? '24px' : '0px'}
            h={toggle ? '24px' : '0px'}
            transition='all 0.2s linear'
            as={IoLogoTwitter}
            color={iconColor ? iconColor : 'gray.800'}
          />
        </Flex>
        <Flex
          _hover={{
            borderColor: borderHover ? borderHover : 'gray.300',
          }}
          bg={backgroundColor ? backgroundColor : 'white'}
          cursor='pointer'
          color={borderColor ? borderColor : 'gray.100'}
          border={toggle ? '2px solid' : '0px'}
          padding={toggle ? 2.5 : 0}
          borderRadius='15px'
          onClick={() => {
            track(EventsImplementation.SHARE('Whatsapp', location.pathname, 'Playlist Page', course?.__typename!))

            if (course) {
              if (course.attributes) registerUserEvent('share-course', course.attributes)
              window.open(
                'https://wa.me/?text=' +
                  encodeURIComponent(
                    `Não podemos parar de evoluir! Comecei a estudar "${course.title}" pela Education Journey https://app.education-journey.com/home`,
                  ),
                '_blank',
              )
            } else if (playlist) {
              window.open(
                'https://wa.me/?text=' +
                  encodeURIComponent(
                    `Não podemos parar de evoluir! Comecei a estudar "${playlist.title}" pela Education Journey https://app.education-journey.com/home`,
                  ),
                '_blank',
              )
            } else {
              window.open(
                'https://wa.me/?text=' +
                  encodeURIComponent(
                    `Não podemos parar de evoluir! Comecei a estudar com a Education Journey https://app.education-journey.com/home`,
                  ),
                '_blank',
              )
            }
          }}
        >
          <Icon
            w={toggle ? '24px' : '0px'}
            h={toggle ? '24px' : '0px'}
            transition='all 0.2s linear'
            as={IoLogoWhatsapp}
            color={iconColor ? iconColor : 'gray.800'}
          />
        </Flex>
      </Flex>

      <Button
        variant='icon'
        backgroundColor={backgroundColor ? backgroundColor : 'white'}
        borderColor={borderColor ? borderColor : 'gray.100'}
        color={componentTheme.iconColor}
        marginLeft='11px'
        zIndex={1}
        onClick={() => {
          setToggle.toggle()
        }}
        transition='all 0.2s linear'
        _hover={{
          background: 'gray.100 radial-gradient(circle, transparent 1%, gray.400 1%) center/15000%',
        }}
        _active={{
          backgroundColor: 'gray.100',
          backgroundSize: '100%',
          transition: 'background 0s',
        }}
      >
        <Icon w='24px' h='24px' as={IoShareSocialOutline} color={iconColor ? iconColor : 'gray.800'} />
      </Button>
    </Flex>
  )
}

export default ShareButton
