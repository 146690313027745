import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import Partner from 'src/modules/cms/domain/Partner'
import { makeGetPartnerById } from '../factories/makeGetPartnerById'
import { makeListPartners } from '../factories/makeListPartners'

interface PartnersContextProps {
  partnersList: Partner[]
  getPartnersList: () => Promise<void>
  getPartnerById: (id: string) => Promise<Partner | undefined>
  loadingPartners: boolean
  totalPartnersLength: number
}

const PartnersContext = createContext<PartnersContextProps>({} as PartnersContextProps)

export function PartnersContextProvider({ children }: { children: ReactNode }) {
  const [loadingPartners, setLoadingPartners] = useState(false)
  const [partnersList, setPartnersList] = useState<Partner[]>([])
  const [totalPartnersLength, setTotalPartners] = useState(0)

  const getPartnersList = async () => {
    try {
      setLoadingPartners(true)
      const listPartners = makeListPartners()
      const partners = await listPartners.execute()
      setPartnersList(partners.items)
      setTotalPartners(partners.total)
    } catch {
      console.error('error listing partners')
    } finally {
      setLoadingPartners(false)
    }
  }

  const getPartnerById = async (id: string) => {
    try {
      const listPartnerById = makeGetPartnerById()
      const partner = await listPartnerById.execute({ id })
      return partner
    } catch {
      return undefined
    }
  }

  useEffect(() => {
    getPartnersList()
  }, [])

  return (
    <PartnersContext.Provider
      value={{ partnersList, getPartnersList, loadingPartners, totalPartnersLength, getPartnerById }}
    >
      {children}
    </PartnersContext.Provider>
  )
}

export function usePartners() {
  const context = useContext(PartnersContext)
  if (!context) throw new Error('usePartners must be used within a PartnersContextProvider')
  return context
}
