import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { ScrollingCarousel } from '../../components/ScrollingCarousel'
import { useTranslation } from 'react-i18next'
import { RecentlyAccessedCourseCard } from 'src/components/RecentlyAccessedCourseCard'
import { RecentlyAccessedPlaylistCard } from 'src/components/RecentlyAccessedPlaylistCard'
import { useUser } from 'src/context/userContext'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import Content from 'src/modules/cms/domain/Content'
import { SliderChevron } from '../../theme/Icons'

interface Props {
  contents: Content[],
  hasPagination?: boolean,
  nextPagination?: () => void
}

const RecentlyAccessed: React.FC<Props> = ({ contents, hasPagination, nextPagination }: Props) => {
  const { t } = useTranslation()
  const { EventsImplementation, track } = useMixpanel()
  const { user } = useUser()

  const generateElementsList = () => {
    const list = []

    if (user?.hasCompletedCustomPlaylistQuiz)
      list.push(
        <Flex key={'custom-playlist-item'} marginRight='20px' marginLeft={{ base: '0px' }}>
          <RecentlyAccessedPlaylistCard
            title={t('customPlaylist')}
            image='https://i.imgur.com/NLFXRsG.png'
            url='/customPlaylist'
          />
        </Flex>,
      )

    contents.map((item, index) =>
      list.push(
        <Flex
          key={`${item.id}-${index}`}
          marginRight='20px'
          marginLeft={{ base: '0px', md: `${!index && !user?.hasCompletedCustomPlaylistQuiz ? '39px' : ''}` }}
        >
          <RecentlyAccessedCourseCard key={item.id.toString()} content={item} />
        </Flex>,
      ),
    )

    return list
  }

  if (!contents || !contents.length) return null
  return (
    <Flex w='100%' alignItems='center' justifyContent='center' marginTop='15px' marginBottom='52px'>
      <Flex w='100%' paddingLeft={{ base: '16px', md: '33px', lg: '72px' }} flexDirection='column'>
        <Text fontWeight='600' fontFamily='Poppins' fontSize='20px' marginBottom='19px'>
          {t('common.alreadyStarted')}
        </Text>
        <ScrollingCarousel
          hasPagination={hasPagination}
          nextPagination={nextPagination}
          leftIcon={
            <Flex
              position='absolute'
              height='100%'
              alignItems='center'
              width={{ base: '52px', md: '80px' }}
              left='-1px'
              onClick={() => {
                track(EventsImplementation.SWIPE(window.location.pathname, 'Recently Accessed'))
              }}
              zIndex='4'
              cursor='pointer'
              background='linear-gradient(90deg, #ffffffd4 34.77%, rgba(255, 255, 255, 0) 93.38%)'
            >
              <Icon as={SliderChevron} transform='matrix(-1, 0, 0, 1, 0, 0)' w='13px' h='27px' />
            </Flex>
          }
          rightIcon={
            <Flex
              position='absolute'
              height='100%'
              alignItems='center'
              width={{ base: '52px', md: '80px' }}
              onClick={() => {
                track(EventsImplementation.SWIPE(window.location.pathname, 'Recently Accessed'))
              }}
              zIndex='4'
              transform='matrix(-1, 0, 0, 1, 0, 0)'
              right='0'
              cursor='pointer'
              background='linear-gradient(90deg, #ffffffd4 34.77%, rgba(255, 255, 255, 0) 93.38%)'
            >
              <Box marginLeft='16px'>
                <Icon as={SliderChevron} transform='matrix(-1, 0, 0, 1, 0, 0)' w='13px' h='27px' />
              </Box>
            </Flex>
          }
        >
          {generateElementsList()}
        </ScrollingCarousel>
      </Flex>
    </Flex>
  )
}

export default RecentlyAccessed
