import { Flex, Skeleton } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { makeGetCompany } from 'src/modules/cms/factories/makeGetCompany'
import { CertificateCompanyLogoSelection } from '../components/CertificateCompanyLogoSelection'
import { CertificatePreview } from '../components/CertificatePreview'
import { CertificateSaveChangesButton } from '../components/CertificateSaveChangesButton'
import { CertificateSignatureSelection } from '../components/CertificateSignatureSelection'
import { CertificateTypeSection } from '../components/CertificateTypeSection'
import { CertificateTypeTabs } from '../components/CertificateTypeTabs'
import { useCertificate } from '../hooks/useCertificate'

export interface CertificateImageFile {
  file?: File | string
  error?: {
    isError: boolean
    message: string
  }
}

export function CertificateTab() {
  const { setCertificateInitialValues } = useCertificate()
  const companyInfo = makeGetCompany()
  const [isLoading, setIsLoading] = useState(true)

  const handleGetCompanyInfo = async () => {
    try {
      const company = await companyInfo.execute()
      setCertificateInitialValues(company)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetCompanyInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex
      flexDir={{ base: 'column', xl: 'row' }}
      flex={1}
      paddingBottom='40px'
      data-testid='cms-certificates-wrapper'
      gap='61px'
    >
      <Flex flexDir='column' gap='24px' flex={1} maxW='1100px'>
        {!isLoading ? (
          <>
            <CertificateTypeSection />
            <CertificateSignatureSelection />
            <CertificateCompanyLogoSelection />
          </>
        ) : (
          <Flex flexDir='column' gap='4px'>
            <Skeleton w='40%' h='30px' />
            <Skeleton w='70%' h='30px' />
            <Skeleton mt='16px' w='100%' h='152px' />

            <Skeleton mt='24px' w='50%' h='30px' />
            <Skeleton mt='24px' w='30%' h='24px' />
            <Skeleton mt='8px' w='100%' h='48px' />

            <Skeleton mt='24px' w='30%' h='24px' />
            <Skeleton mt='8px' w='100%' h='48px' />
          </Flex>
        )}
      </Flex>

      <Flex flexDir='column' gap='16px' align='flex-start'>
        <CertificateTypeTabs />
        <CertificatePreview />
        <CertificateSaveChangesButton />
      </Flex>
    </Flex>
  )
}
