import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoChevronDown } from 'react-icons/io5'
import { checkIsAbilitySelected } from 'src/components/QuizModal/utils'

interface OtherSkillsProps {
  onSelectSkill: (skill: string) => void
  options: string[]
  selectedSkills: string[]
}

interface OptionObject {
  text: string
  skill: string
}

const OtherSkills: React.FC<OtherSkillsProps> = ({ onSelectSkill, options, selectedSkills }) => {
  const [t] = useTranslation()
  const ref = useRef(null)
  const [seeOptions, setSeeOptions] = useState(false)

  // @ts-ignore
  const optionsText = options.map((option: string) => t(`quiz.skills.${option}`))
  const optionsObject: OptionObject[] = options.map((option: string, index: number) => {
    return {
      text: optionsText[index].toString(),
      skill: option,
    }
  })

  optionsObject.sort((a: OptionObject, b: OptionObject) => {
    const textA = a.text.toUpperCase()
    const textB = b.text.toUpperCase()
    return textA < textB ? -1 : textA > textB ? 1 : 0
  })

  const handleClickOutside = (event: any) => {
    // @ts-ignore
    if (ref.current && !ref.current.contains(event.target)) {
      setSeeOptions(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const AdditionalSkills = () => {
    return (
      <Flex
        border='2px solid'
        borderColor='gray.70'
        boxShadow={'0px 1px 6px rgba(0, 0, 0, 0.1);'}
        borderRadius='16px'
        h='auto'
        w='200px'
        flexDirection='column'
        align='center'
        mt='8px'
        position='absolute'
        top='45px'
        backgroundColor='white'
        zIndex={3}
        maxHeight='200px'
        overflowY='auto'
        sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            borderRadius: '15px',
            backgroundColor: 'gray.60',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'gray.800',
            height: '20px',
            borderRadius: '100px',
          },
          '&::-webkit-track': {
            h: '90%',
          },
        }}
      >
        {optionsObject.map((skill: OptionObject, index: number) => {
          const isSkillSelected = checkIsAbilitySelected(skill.skill, selectedSkills)
          return (
            <Box
              paddingY='10px'
              paddingLeft='8px'
              userSelect={'none'}
              cursor='pointer'
              scrollSnapAlign={'start'}
              borderTop={index === 0 ? 'none' : '1px solid'}
              borderColor='gray.180'
              w='90%'
              backgroundColor={isSkillSelected ? 'primaryColors.45' : 'transparent'}
              onClick={() => onSelectSkill(skill.skill)}
              _hover={{
                bg: 'primaryColors.45',
                w: '100%',
                paddingLeft: '8%',
              }}
              textTransform='capitalize'
            >
              {skill.text}
            </Box>
          )
        })}
      </Flex>
    )
  }

  return (
    <Flex flexDirection={'column'} justifyContent={'center'} position='relative' ref={ref}>
      <Flex
        border='2px solid'
        borderColor='gray.70'
        borderRadius='18px'
        maxW='200px'
        justifyContent={'center'}
        color='gray.800'
        padding='5px 15px'
        marginRight='12px'
        fontWeight='700'
        marginTop='9px'
        maxH='34px'
        userSelect='none'
        cursor={'pointer'}
        transition={'all .09s ease-in-out'}
        alignItems='center'
        onClick={() => setSeeOptions((prev) => !prev)}
      >
        <Text>{t('quiz.stepFour.otherSkills')}</Text>
        <Icon as={IoChevronDown} marginLeft='10px' transform={seeOptions ? 'rotate(180deg)' : 'none'} />
      </Flex>
      {seeOptions && <AdditionalSkills />}
    </Flex>
  )
}

export default OtherSkills
