/* eslint-disable react-hooks/rules-of-hooks */
import { useUser } from 'src/context/userContext'
import Content from 'src/modules/cms/domain/Content'

type Prop = 'title' | 'description'

export function renderCorrectContentInfoByLanguage(content: Content, prop: Prop) {
  const { user } = useUser()
  const language = user?.language ?? 'pt-BR'

  let translatedInfo = content?.translations?.find((t) => t.language === language && t.content_id === content.id)?.[
    prop
  ]
  return translatedInfo ?? content?.[prop]
}
