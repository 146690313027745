import { Flex, HStack, Skeleton, Stack, VStack } from '@chakra-ui/react'
import React from 'react'

export const PlaylistsSectionSkeleton = () => {
  const PlaylistsArray = new Array(14).fill(
    <Skeleton w='274.47px' h='378.26px' borderRadius='30px' marginRight='20px' marginBottom='15px' />,
  )

  return (
    <Flex width='100%' flexDirection='column'>
      <HStack>
        <Skeleton
          h='24px'
          w='200px'
          margin={{ base: '50px 20px 30px', lg: '50px 0px 30px 40px' }}
          borderRadius={'16px'}
        />
      </HStack>

      <Flex flexWrap={'wrap'} alignItems={'center'} paddingLeft={{ base: '20px', lg: '1.75%' }}>
        {PlaylistsArray.map((item) => {
          return item
        })}
      </Flex>
    </Flex>
  )
}

export const PlaylistsSkeleton: React.FC = () => {
  const PlaylistsArray = new Array(14).fill(
    <Skeleton w='274.47px' h='378.26px' borderRadius='30px' marginRight='20px' marginBottom='15px' />,
  )

  return (
    <Stack w='full' data-cy='playlistsSkeleton'>
      <Flex justifyContent='flex-start'>
        <VStack alignItems='flex-start' margin={{ base: '50px 20px 0px', lg: '50px 0px 0px 40px' }} w='100%'>
          <Skeleton w='200px' h='40px' marginBottom='30px' borderRadius={'16px'} />
          <Skeleton h='24px' w='100%' maxW='420px' margin='70px 0px 30px 50px' borderRadius={'16px'} />
          <Skeleton h='24px' w='100%' maxW='410px' margin='70px 0px 30px 50px' borderRadius={'16px'} />
          <Skeleton h='24px' w='100%' maxW='400px' margin='70px 0px 30px 50px' borderRadius={'16px'} />
        </VStack>
      </Flex>

      <Flex width='100%' flexDirection='column'>
        <HStack>
          <Skeleton
            h='24px'
            w='200px'
            margin={{ base: '50px 20px 30px', lg: '50px 0px 30px 40px' }}
            borderRadius={'16px'}
          />
        </HStack>

        <Flex flexWrap={'wrap'} alignItems={'center'} paddingLeft={{ base: '20px', lg: '1.75%' }}>
          {PlaylistsArray.map((item) => {
            return <React.Fragment key={Math.random()}>{item}</React.Fragment>
          })}
        </Flex>
      </Flex>
    </Stack>
  )
}

export default PlaylistsSkeleton
