import { Flex, Icon, Tooltip } from '@chakra-ui/react'
import { FaCircleInfo } from 'react-icons/fa6'

interface InfoIconProps {
  message?: string
}

export function InfoIcon({ message }: InfoIconProps) {
  return (
    <Tooltip
      isDisabled={!message}
      label={message}
      aria-label={message}
      placement='right'
      borderRadius='8px'
      bg='gray.800'
      fontSize='0.75rem'
      color='gray.50'
    >
      <Flex align='center' justify='center' w='auto' h='auto'>
        <Icon as={FaCircleInfo} color='gray.400' />
      </Flex>
    </Tooltip>
  )
}
