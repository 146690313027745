interface Props {
  errors: Errors[]
  toast: any
  t: any
  totalUsersEditted: number
}

type Errors = {
  email: string
  code: string
  reason: string
}

const defaultToastProps = {
  duration: 20000,
  isClosable: true,
}

const availableErrors = [
  'INVALID_EMAIL',
  'LANGUAGE_NOT_FOUND',
  'USER_ALREADY_EXISTS',
  'UNKNOWN_ERROR',
  'USER_NOT_FOUND',
  'NOT_AUTHORIZED',
  'COMPANY_SUBSIDIARY_NOT_FOUND',
  'COMPANY_NOT_FOUND',
  'AREA_NOT_FOUND',
]

const errorsToShowAsInfoToast = ['COMPANY_SUBSIDIARY_NOT_FOUND']

export function handleSubmitToEditErrors({ errors, t, toast, totalUsersEditted }: Props) {
  if (errors.length <= 0) return
  const totalErrorsToShowDefaultToast = 5
  if (errors.length > totalErrorsToShowDefaultToast) {
    const allUsersHasError = totalUsersEditted === errors.length
    toast({
      title: allUsersHasError
        ? t('noUsersHaveBeenEdited')
        : t('moreThanFewUsersWasNotEditedByCsvErrors', { totalErrorsToShowDefaultToast }),
      status: allUsersHasError ? 'error' : 'info',
      description: t('verifyInsertedInfoInCsvAndTryAgain'),
      ...defaultToastProps,
    })
    return
  }

  errors.forEach((error) => {
    const showAsInfoToast = errorsToShowAsInfoToast.includes(error?.code)
    toast({
      title: t(showAsInfoToast ? 'theUserWasNotEntireEdited' : 'theUserWasNotEdited', {
        email: error.email,
        details: availableErrors.includes(error?.code) ? t(error.code) : t('UNKNOWN_ERROR'),
      }),
      status: showAsInfoToast ? 'info' : 'error',
      ...defaultToastProps,
    })
  })

  return
}
