import { getAccessToken } from "src/modules/iam/apis/store";
import httpClient from "src/services/http/http-client";
import { AreaRepository } from "../repository/AreaRepository";
import { RemoveUsersFromExistingArea } from "../use-case/RemoveUsersFromExistingAreaUseCase";

export function makeRemoveUsersFromExistingArea() {
  const token = getAccessToken()

  const areaRepository = new AreaRepository(token, httpClient)
  return new RemoveUsersFromExistingArea(areaRepository)
}