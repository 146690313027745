import { Button, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCertificate } from '../../hooks/useCertificate'

export function CertificateSaveChangesButton() {
  const [t] = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const { saveCertificateChanges, companyLogo, signature } = useCertificate()
  const toast = useToast()

  const shouldDisableSaveChangesButton = () => {
    if (signature.shouldIncludeSignature) {
      if (!signature.signatureImage) return true
      if (!signature.responsableName) return true
      if (!signature.responsableName || signature?.responsableName?.length <= 0) return true
    }
    if (companyLogo.shouldIncludeLogo && !companyLogo.logoImage) return true
    return false
  }

  const handleSaveCertificateChanges = async () => {
    try {
      setIsLoading(true)
      await saveCertificateChanges()
      toast({
        title: 'Informações salvas!',
        description: 'As informações do certificado foram salvas com sucesso!',
        status: 'success',
        duration: 6000,
      })
    } catch (err) {
      console.error('Ocorreu um erro ao tentar salvar as informações do certificado.', err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Button
      variant='startCourseDark'
      h='36px'
      py='0'
      px='16px'
      fontFamily='Poppins'
      fontSize='1rem'
      fontWeight='600'
      ml={{ base: '0px', xl: 'auto' }}
      isLoading={isLoading}
      isDisabled={shouldDisableSaveChangesButton()}
      onClick={handleSaveCertificateChanges}
    >
      {t('saveChanges')}
    </Button>
  )
}
