import { Button, Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface TryRequestAgainProps {
  errorMessage?: string
  functionToExecute: () => void
  imageHeight?: number
}

export function TryRequestAgain({ errorMessage, functionToExecute, imageHeight = 150 }: TryRequestAgainProps) {
  const [t] = useTranslation()

  return (
    <Flex flexDir='column' align='center' w='100%' justify='center' py='12px' px={{ base: '20px', md: '0px' }}>
      <Image src='/assets/images/warning_img.svg' h={{ base: `${imageHeight - 50}px`, sm: `${imageHeight}px` }} />
      <Text my='16px' fontWeight='bold' fontSize='1.125rem' textAlign='center' maxW='300px'>
        {errorMessage ? errorMessage : t('tryRequestAgain.defaultError')}
      </Text>
      <Button onClick={functionToExecute}>{t('tryRequestAgain.tryAgain')}</Button>
    </Flex>
  )
}
