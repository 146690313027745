import { createContext, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from 'src/context/userContext'
import { AvailableTabsDashboardReportsType } from 'src/types/DashboardReports'
import { makeGenerateReportUrl } from '../factory/makeGenerateReportUrl'
import { makeGetReport } from '../factory/makeGetReport'

interface IDashboardReportsContext {
  activeTab: AvailableTabsDashboardReportsType
  changeActiveTab: (id: AvailableTabsDashboardReportsType) => void
  fetchReport: (tabId: string) => Promise<void>
  generateReportUrl: (params: {
    type: string,
    date?: string
    area?: number[]
    users?: number[]
    subsidiaries?: number[]
    playlistId?: string
  }) => Promise<void>
  reportUrl: string
  isLoading: boolean
  iframeLoading: boolean
  setIframeLoading: (value: boolean) => void
}

export const useDashboardReportsTabs = () => {
  const [t] = useTranslation()
  const dashboardReportTabs: { id: AvailableTabsDashboardReportsType; label: string }[] = [
    { id: 'summary', label: t('resume') },
    { id: 'register', label: t('register') },
    { id: 'custom', label: t('customPlaylists') },
    { id: 'featured', label: t('featuredPlaylists') },
    { id: 'contents', label: t('contents') },
    { id: 'resume', label: t('admin.reports.history') },
  ]
  return dashboardReportTabs
}

const DashboardReportsContext = createContext({} as IDashboardReportsContext)

export function DashboardReportsProvider({ children }: { children: React.ReactNode }) {
  const dashboardReportTabs = useDashboardReportsTabs()
  const [activeTab, setActiveTab] = useState(dashboardReportTabs[0].id)
  const [reportUrl, setReportUrl] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [iframeLoading, setIframeLoading] = useState(false)
  const { user } = useUser()

  const changeActiveTab = (id: AvailableTabsDashboardReportsType) => {
    setReportUrl('')
    setIsLoading(false)
    setIframeLoading(false)
    setActiveTab(id)
  }

  const fetchReport = async (tabId: string) => {
    const getReport = makeGetReport()
    setIsLoading(true)
    const reportUrl = await getReport.execute({ tabId, userLanguage: user?.language || 'pt-BR' })
    setReportUrl(reportUrl)
    setIsLoading(false)
  }

  const generateReportUrl = async (params: {
    type: string,
    date?: string
    area?: number[]
    users?: number[]
    subsidiaries?: number[]
    playlistId?: string
  }) => {
    const generateReportUrl = makeGenerateReportUrl()

    setIsLoading(true)
    const reportUrl = await generateReportUrl.execute({
      type: params.type,
      date: params.date || undefined,
      area: params.area?.length ? params.area : undefined,
      users: params.users?.length ? params.users : undefined,
      subsidiaries: params.subsidiaries?.length ? params.subsidiaries : undefined,
      playlistId: params.playlistId || undefined,
    })

    setReportUrl(reportUrl)
    setIsLoading(false)
    setIframeLoading(true)
  }

  return (
    <DashboardReportsContext.Provider value={{ activeTab, changeActiveTab, fetchReport, reportUrl, isLoading, generateReportUrl, iframeLoading, setIframeLoading }}>
      {children}
    </DashboardReportsContext.Provider>
  )
}

export function useDashboardReports() {
  const context = useContext(DashboardReportsContext)
  if (!context) throw new Error('useDashboardReports must be used inside the DashboardReportsProvider')
  return context
}
