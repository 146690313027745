import { Flex, Icon, Text } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { useUser } from 'src/context/userContext'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import ExplorablePlaylistCard from '../../components/ExplorablePlaylistCard'
import { ScrollingCarousel } from '../../components/ScrollingCarousel'
import { SliderChevron } from '../../theme/Icons'
import { isRecommended, removeDraftPlaylists, sortPlaylists } from './utils'

interface Props {
  playlists: NewPlaylist[]
  label?: string
  hasPagination?: boolean,
  nextPagination?: () => void
}

const PlaylistSection: React.FC<Props> = ({ playlists, label, hasPagination, nextPagination }: Props) => {
  const { user } = useUser()
  const location = useLocation()
  const { EventsImplementation, track } = useMixpanel()

  const validPlaylists = removeDraftPlaylists(playlists)
  const sortedPlaylists = sortPlaylists(user?.companyId ?? user?.company?.id ?? -1, validPlaylists)

  if (sortedPlaylists.length === 0) {
    return null
  }

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      position='relative'
      paddingLeft={{ base: '16px', md: '72px' }}
      paddingRight='0px'
      textAlign='left'
      w='100%'
      marginBottom='50px'
    >
      <Text
        marginBottom={{ base: '0px', md: '-20px' }}
        fontFamily='Poppins'
        fontSize='20px'
        fontWeight='600'
        alignSelf='start'
      >
        {label}
      </Text>
      <ScrollingCarousel
        className='scrolling-carousel-playlists'
        defaultSlideAmount={600}
        hasPagination={hasPagination}
        nextPagination={nextPagination}
        leftIcon={
          <Flex
            position='absolute'
            height='100%'
            alignItems='center'
            width={{ base: '52px', md: '80px' }}
            zIndex='4'
            left='-1px'
            onClick={() => {
              track(EventsImplementation.SWIPE(location.pathname, 'Playlists'))
            }}
            cursor='pointer'
            background='linear-gradient(90deg, #ffffffd4 34.77%, rgba(255, 255, 255, 0) 93.38%)'
          >
            <Icon as={SliderChevron} transform='matrix(-1, 0, 0, 1, 0, 0)' w='13px' h='27px' />
          </Flex>
        }
        rightIcon={
          <Flex
            position='absolute'
            height='100%'
            alignItems='center'
            width='80px'
            zIndex='4'
            transform='matrix(-1, 0, 0, 1, 0, 0)'
            right='0px'
            onClick={() => {
              track(EventsImplementation.SWIPE(location.pathname, 'Playlists'))
            }}
            cursor='pointer'
            background='linear-gradient(90deg, #ffffffd4 34.77%, rgba(255, 255, 255, 0) 93.38%)'
          >
            <Icon as={SliderChevron} transform='matrix(-1, 0, 0, 1, 0, 0)' w='13px' h='27px' marginRight='12px' />
          </Flex>
        }
      >
        {sortedPlaylists.map((playlist, index) => (
          <Flex
            key={`${playlist.id};${label};${index}`}
            marginRight='20px'
            paddingBottom='45px'
            paddingTop='35px'
            paddingRight={sortedPlaylists.length === index + 1 ? '30px' : ''}
            marginLeft={{ base: '0px', md: !index ? '39px' : '' }}
          >
            <ExplorablePlaylistCard
              playlist={playlist}
              isPersonalized={playlist.type === 'CUSTOM_PLAYLIST'}
              recommended={isRecommended(user?.companyId ?? user?.company?.id ?? -1, playlist)}
            />
          </Flex>
        ))
        }
      </ScrollingCarousel>
    </Flex>
  )
}

export default PlaylistSection