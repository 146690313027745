import { getAccessToken } from 'src/modules/iam/apis/store'
import httpClient from 'src/services/http/http-client'
import { AllocateCollabSkillsUseCaseOutput } from '../use_cases/AllocateCollabSkillsUseCase'

interface ICollabSkillRepository {
  allocate(collabIds: number[], skillIds: string[]): Promise<AllocateCollabSkillsUseCaseOutput>
}

export class CollabSkillRepository implements ICollabSkillRepository {
  async allocate(collabIds: number[], skillIds: string[]): Promise<AllocateCollabSkillsUseCaseOutput> {
    const token = getAccessToken()

    const response = await httpClient.request<AllocateCollabSkillsUseCaseOutput>({
      url: `/v1/iam/alocate-skills`,
      method: 'POST',
      data: {
        skillIds,
        userIds: collabIds,
      },
      headers: {
        Authorization: token,
      },
    })

    return response.body
  }
}
