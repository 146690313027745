import { useQuery } from '@apollo/client'
import {
  Button,
  Flex,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  useClipboard,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoOpenOutline } from 'react-icons/io5'
import { useParams } from 'react-router-dom'
import GET_PARTNER from 'src/graphql/queries/GET_PARTNER'
import { Partner } from 'src/types/Partner'
import { UserCredentials } from '../../redirects/ActivatePartner'


interface ReturnGetPartner {
  GetPartner: Partner
  GetUserCredentials: UserCredentials
}

const UserCredentialsPage: React.FC = () => {
  const [t] = useTranslation()
  const { partnerSlug } = useParams<{ partnerSlug: string }>()
  const [credentials, setCredentials] = useState<UserCredentials | undefined>(undefined)
  const [partner, setPartner] = useState<Partner | undefined>(undefined)
  const { loading: partnerLoading } = useQuery<ReturnGetPartner>(GET_PARTNER, {
    variables: { slug: partnerSlug },
    onCompleted: (data) => {
      setPartner(data.GetPartner)
      setCredentials(data.GetUserCredentials)
    },
  })
  const [loginValue, setLoginValue] = useState(credentials?.login)
  const [passwordValue, setPasswordValue] = useState(credentials?.password)
  const { hasCopied: hasCopiedLogin, onCopy: onCopyLogin } = useClipboard(loginValue!)
  const { hasCopied: hasCopiedPassword, onCopy: onCopyPassword } = useClipboard(passwordValue!)

  useEffect(() => {
    if (credentials?.login && credentials?.password) {
      setLoginValue(credentials.login)
      setPasswordValue(credentials.password)
    }
  }, [credentials])

  if (partnerLoading || !partner) return null
  return (
    <Stack w='100%' height='100%' flexDirection='column' justifyContent='center' alignItems='center'>
      <Stack w='60%' flexDirection='column' justifyContent='center' bg='white' alignItems='center' spacing='5'>
        <Image src={partner?.squareLogo} width='100px' />
        <Text fontFamily='Mulish' fontSize='16px' fontWeight='regular' w='320px' textAlign='center'>
          {t('credentials.description')}
        </Text>
        <Flex w={['90%', '80%', '70%', '90%', '70%']} flexDirection='column'>
          <Text
            color='gray.600'
            fontFamily='Mulish'
            fontWeight='regular'
            fontSize='16px'
            marginLeft='14px'
            marginBottom='6px'
          >
            {t('credentials.Login')}
          </Text>
          <InputGroup>
            <Input value={loginValue} h='55px' isReadOnly placeholder={credentials?.login} />
            <InputRightElement width='120px'>
              <Button
                h='36px'
                fontSize={['14px', '14px', '14px', '16px', '16px']}
                w={['70%', '70%', '70%', '90%', '90%']}
                onClick={onCopyLogin}
                marginLeft={['20px', '20px', '20px', '0px', '0px']}
              >
                {hasCopiedLogin ? t('activatePartner.Copied') : t('activatePartner.Copy')}
              </Button>
            </InputRightElement>
          </InputGroup>
        </Flex>
        <Flex w={['90%', '80%', '70%', '90%', '70%']} flexDirection='column' marginTop='-20px'>
          <Text
            color='gray.600'
            fontFamily='Mulish'
            fontWeight='regular'
            fontSize='16px'
            marginLeft='14px'
            marginBottom='6px'
          >
            {t('credentials.Password')}
          </Text>
          <InputGroup>
            <Input value={passwordValue} h='55px' isReadOnly placeholder={credentials?.password} />
            <InputRightElement width='120px'>
              <Button
                h='36px'
                fontSize={['14px', '14px', '14px', '16px', '16px']}
                w={['70%', '70%', '70%', '90%', '90%']}
                onClick={onCopyPassword}
                marginLeft={['20px', '20px', '20px', '0px', '0px']}
              >
                {hasCopiedPassword ? t('activatePartner.Copied') : t('activatePartner.Copy')}
              </Button>
            </InputRightElement>
          </InputGroup>
        </Flex>
        <Link
          fontFamily='Mulish'
          fontWeight='bold'
          fontSize='16px'
          color='white'
          isExternal={true}
          bg='gray.800'
          href={partner?.accessLink}
          textDecoration='none'
          display='flex'
          justifyContent='center'
          alignItems='center'
          w={['90%', '80%', '70%', '90%', '70%']}
          marginTop='30px'
          paddingY={['4px', '4px', '12px', '12px', '12px']}
          borderRadius='40px'
          _hover={{ textDecoration: 'none' }}
        >
          {t('credentials.Access partner')}

          <Icon fontSize='22px' marginLeft='10px' as={IoOpenOutline} />
        </Link>
      </Stack>
    </Stack>
  )
}

export default UserCredentialsPage
