import { Box, Flex, Skeleton, Text } from '@chakra-ui/react'
import React from 'react'
import { useCourseModal } from 'src/context/CourseModalContext'
import DescriptionPage from './DescriptionPage'
import InstructorsPage from './InstructorsPage'

interface CoursePageItemProps {
  selected: boolean
  text: string
  onClick: () => void
}

export const CoursePageItem: React.FC<CoursePageItemProps> = ({ selected, text, onClick }: CoursePageItemProps) => {
  return (
    <Box
      onClick={onClick}
      cursor='pointer'
      borderBottom={selected ? '2px solid #009639' : ''}
      bottom='-1px'
      width='fit-content'
    >
      <Text color={selected ? 'black' : 'gray.260'} fontWeight={selected ? '700' : ''} padding='8px'>
        {text}
      </Text>
    </Box>
  )
}

const CourseModalBody: React.FC = () => {
  const { course, loading, bodyPageOpened } = useCourseModal()

  if (loading || !course)
    return (
      <Flex flexDirection='column' margin='44px 29px 0px' marginBottom='40px'>
        <Skeleton h='27px' w='170px' borderRadius='8px' marginBottom='20px' />
        <Skeleton h='27px' w='50%' borderRadius='8px' marginBottom='6px' />
        <Skeleton h='27px' w='96%' borderRadius='8px' marginBottom='6px' />
        <Skeleton h='27px' w='80%' borderRadius='8px' marginBottom='6px' />
        <Skeleton h='27px' w='85%' borderRadius='8px' marginBottom='6px' />
        <Skeleton h='27px' w='75%' borderRadius='8px' marginBottom='6px' />
        <Skeleton h='27px' w='90%' borderRadius='8px' marginBottom='6px' />
        <Skeleton h='27px' w='75%' borderRadius='8px' marginBottom='6px' />
        <Skeleton h='27px' w='30%' borderRadius='8px' />
      </Flex>
    )

  // const pagesName = [
  //   t('home.Course Modal.Description'),
  //   t('home.Course Modal.Content'),
  //   t('home.Course Modal.Instructors'),
  //   t('home.Course Modal.Recommended'),
  // ]

  const pages = [
    <DescriptionPage content={course} />,
    // <ContentPage course={course} />,
    <InstructorsPage content={course} />,
    // <RecommendedPage course={course} />,
  ]

  return (
    <Box>
      <Flex
        margin={{ base: '24px 20px 0px', lg: '29px 29px 0px' }}
        borderBottom='1px solid #313E4C'
        position='relative'
        overflowX='auto'
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {/* {pagesName.map((name: string, index: number) => {
          const hideContentPage = index === 1 && !course.modules?.length
          const hideInstructorsPage = index === 2 && !course.instructors?.length

          if (hideContentPage || hideInstructorsPage) return null

          return (
            <CoursePageItem
              key={name}
              onClick={() => {
                track(EventsImplementation.MODAL_ACCESS_TAB(bodyPageOpened.toString(), index.toString()))
                setBodyPageOpened(index)
              }}
              text={name}
              selected={bodyPageOpened === index}
            />
          )
        })} */}
      </Flex>

      <Box margin='17px 0px 0px' marginBottom='40px'>
        {pages[bodyPageOpened]}
      </Box>
    </Box>
  )
}

export default CourseModalBody
