import { getAccessToken } from "src/modules/iam/apis/store";
import { AreaRepository } from "../repository/AreaRepository";
import { CreateNewAreaUseCase } from "../use-case/CreateNewAreaUseCase";
import httpClient from 'src/services/http/http-client'

export function makeCreateNewArea() {
  const token = getAccessToken()

  const areaRepository = new AreaRepository(token, httpClient)
  return new CreateNewAreaUseCase(areaRepository)
}